import React from 'react';
import ArchiveItem from "./ArchiveItem";

const ArchiveList = ({getT, entries, onDownloadPdf, statusOptions}) => {
    if (!getT || !statusOptions) return false
    return (
        <div className="card">
            <div className="card-body p-0">
                <div className="table-responsive">
                    <table className="table m-0 table-striped table-hover orders-table-main">
                        <thead>
                        <tr>
                            <th colSpan={2}>{getT("ID")}</th>

                            <th colSpan={3}>{getT("Название")}</th>
                            <th colSpan={3}>{getT("Клиент")}</th>
                            <th colSpan={3}>{getT("Менеджер")}</th>
                            <th colSpan={2}>{getT("Статус")}</th>

                            <th colSpan={2}>{getT("Действия")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {entries ? entries?.map((item) => <ArchiveItem getT={getT} statusOptions={statusOptions}
                                                                       onDownloadPdf={onDownloadPdf} item={item}
                                                                       key={item?.id}/>) :
                            <>
                                <tr>
                                    <td className={"text-center"} colSpan={15}>
                                        {getT("")}
                                    </td>
                                </tr>
                            </>}
                        <tr>
                            <td className={"text-center"} colSpan={15}>
                                {getT("Воспользуйтесь фильтрами для поиска необходимого заказа")}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ArchiveList;