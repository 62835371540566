import React from 'react';
import {useForm} from "react-hook-form";
import GeneralStatusesSelector from "../../../components/WholeSale/Forms/GeneralStatusesSelector";
/**
 * OrderSupplyFilter. Фильтр для страницы заказов.
 *
 * @param {object} filter  - фильтр
 * @param {array || null} orders_statuses  - список стутусов
 * @param {array || null} connectionFirms  -  список подключенных фирм
 * @param {boolean} isMainFirmRequestType  -  Тип заказов
 *    false - отображаются заказы для поставщиков (свои заказы).
 *    true - отображаются заказы от клиентов (партнёров)
 * @param {function} getT  - перевод
 * @param {function} onSend  - Функция для получения заказов с учётом фильтра.
 * @returns  {JSX.Element}  -
 */
const OrderSupplyFilter = ({filter, getT,isMainFirmRequestType, onSend, orders_statuses = [], connectionFirms = []}) => {
    const {handleSubmit, register, watch, setValue} = useForm({
        values: {
            status: filter?.status,
            search_by_name: filter?.search_by_name,
            supplier: filter?.supplier ?? null,
            date_from: filter.date_from,
            date_to: filter.date_to,
            main_firm_request: filter.main_firm_request
        }
    });
    const onChangeStatus = (e) => {
        let value = e.target.value;
        setValue("status", value)
    }
    return (
        <form onSubmit={handleSubmit(onSend)} className={" order-supply-form"}>
            <div className="form-group row align-items-end" style={{rowGap: "15px"}}>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label htmlFor="exampleInputEmail1">{getT("Название заказа")}</label>
                    <input type="text" className="form-control"{...register("search_by_name")}/>

                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label htmlFor="currency">{isMainFirmRequestType ?getT("Партнёры") :getT("Поставщик")}</label>
                    <select id="supplier" className="form-control"
                            {...register('supplier', {
                                setValueAs: (v) => v
                            })}>
                        <option value={''}>---------</option>
                        {connectionFirms?.map(e => {
                            let id = e?.firm_main_id ?? e?.firm_partner_id;
                            let name = e?.firm_name;
                            return <option value={id}>{name} {e?.count ? `(${e.count})`: ""}</option>
                        })}
                    </select>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-lg-2">
                    <GeneralStatusesSelector getT={getT} value={watch("status")} onChange={onChangeStatus}
                                             orders_statuses={orders_statuses} className={"mb-0"}
                    />
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-2">
                    <label htmlFor="exampleInputEmail1">{getT("Дата от")}</label>
                    <input type="date" className="form-control"{...register("date_from")}/>
                </div>
                <div className="col-12 col-sm-6 col-md-3 col-lg-2">
                    <label htmlFor="exampleInputEmail1">{getT("Дата до")}</label>
                    <input type="date" className="form-control"{...register("date_to")}/>
                </div>

                <div className="col-12 col-md-4 col-lg-12 align-items-end">
                    <div className="d-flex justify-content-center justify-content-md-end">
                        <button className={"btn btn-primary"}>{getT("Фильтровать")}</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default OrderSupplyFilter;