import {dispatcherErrorThunk} from "../common_thunk";
import {setRatesAC, toggleRatesCreateModalAC} from "../../actions/SalaryProject";
import {RatesAPI} from "../../../api/Salary/RatesAPI";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {createFormData} from "../../../utils/helpers";

/**
 * Получить все rates по умолчанию
 * @param {function} dispatch
 */
async function dispatcherGetRates(dispatch) {
    const response = await RatesAPI.get();
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    dispatch(setRatesAC(response))
}

export const getSalaryRatesThunk = () => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await dispatcherGetRates(dispatch);
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
/**
 * Создать план для типа улслуги.
 * @param {object}   api_data - api_data
 * @param {function|null}   reset_form - reset_form
 * [{"key":"service","value":"11","type":"text"},
 * {"key":"price_bad","value":"100","type":"text"},
 * {"key":"price_good","value":"150","type":"text"},
 * {"key":"price_super","value":"250","type":"text"},
 * {"key":"user","value":"177","type":"text"},
 * {"key":"currency","value":"2","type":"text"},
 *
 */
export const addSalaryRatesThunk = (api_data, reset_form = null) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const form_data = createFormData(api_data);
        const response = await RatesAPI.add(form_data)
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        await dispatcherGetRates(dispatch);
        if (reset_form) reset_form()
        dispatch(toggleRatesCreateModalAC({isOpen: false}));
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}