import {instances, URI_API_EXTTUNELL} from "../Salary/setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const ws_orders_supply_list = 'ws_orders_supply_list'
const ws_orders_supply_set = 'ws_orders_supply_set'
const ws_orders_supply_delete = 'ws_orders_supply_delete'

/** Api
 * @method @{getOrdersSupplyList}  - Список заказов поставщику
 * @method @{setOrderGood}  - Создание/редактирование заказа поставщику
 *
 * @returns {} -
 */

export const WholesaleOrdersSupplyAPI = {
    getOrdersSupplyList(form_data) {
        return instances.post(URI_API_EXTTUNELL + '/', form_data, {
            headers: {
                action: ws_orders_supply_list,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    setOrdersSupply(form_data) {
        return instances.post(URI_API_EXTTUNELL + '/', form_data, {
            headers: {
                action: ws_orders_supply_set,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    deleteOrderSupply(form_data){
        return instances.post(URI_API_EXTTUNELL + '/', form_data, {
            headers: {
                action: ws_orders_supply_delete,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },


}