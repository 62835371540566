import React, {useCallback, useEffect, useState} from 'react';
import Select from "react-select";
import {WholesaleOrdersSupplyAPI} from "../../../../api/Wholesale/WholesaleOrdersSupplyAPI";
import {createFormData} from "../../../../utils/helpers";

/**
 * Динамически получить заказы поставщиков и отфильтровать их по выбранной фирме из формы, если фирма указана.
 *Получать заказы можем только в статусе 1 offset=50,
 * @param {object || null} value  - текущее значение
 * @param {number || null} wholeSaleId  - id поставщика для фильтрации
 * @param {function} onChange  -
 * @param {function} getT  -
 * @returns  {}  -
 */
const DinamicOrdersSupplySelect = ({value, onChange, wholeSaleId, getT}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [debouncedValue, setDebouncedValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    let [options, setOptions] = useState([]);
    let optionWithoutOrderSupply = {
        value: -1,
        label: getT("Без заказа поставщику"),
        firm_main: {id: null, name: ''}
    }
    // Функция для получения данных из API с учетом фильтрации
    const fetchOptions = async (inputValue) => {
        try {
            setIsLoading(true)
            // Замените URL на свой API, учитывая значение фильтра
            let formData = createFormData({
                offset: 0, search_by_name: searchTerm,
                status: 1,
            })
            const response = await WholesaleOrdersSupplyAPI.getOrdersSupplyList(formData);
            let options = response?.data?.data?.map((item) => ({
                value: Number(item.ws_orders_supply_id),
                label: `${item.name} | ${item?.firm_main?.name ?? '-'}`,
                firm_main: item?.firm_main
            }))
            setOptions([optionWithoutOrderSupply, ...options])
            setIsLoading(false)

            // Преобразуем данные в формат { value, label }
        } catch (error) {
            console.error(error)
            alert('Ошибка при получении данных');
            setIsLoading(false)
            setOptions([])
        }
    };

    useEffect(() => {
        // Таймер для задержки на 5 секунд
        const timer = setTimeout(() => {
            setDebouncedValue(searchTerm);
        }, 3000);

        // Очистка таймера, если inputValue меняется до завершения задержки
        return () => clearTimeout(timer);
    }, [searchTerm]);

    // Отправка запроса при изменении debouncedValue
    useEffect(async () => {
        await fetchOptions(debouncedValue);
    }, [debouncedValue]);

    // Функция для обработки изменения выбранного значения
    const handleChange = useCallback((selectedOption) => {
        onChange(selectedOption);
    }, [onChange]);

    const onInputChange = async (value) => {
        setSearchTerm(value);
    }
    console.log('wholeSaleId', wholeSaleId)
    return (
        <div className="form-group">
            <label htmlFor="exampleInputEmail1">{getT("Заказ поставщику")}</label>
            <Select
                options={options?.filter(item => {
                    if (!Number(wholeSaleId)) return true;
                    return Number(wholeSaleId) === Number(item?.firm_main?.id)
                })}
                onInputChange={onInputChange}
                inputValue={searchTerm}
                value={value ?? optionWithoutOrderSupply}
                onChange={handleChange}
                isLoading={isLoading}
                isClearable
            />
        </div>
    );
};

export default DinamicOrdersSupplySelect;