import React from 'react';
import {statusOrdersHelpers} from "../../../../utils/statusOrders";
import {LS_FIRM} from "../../../../utils/constants";
/**
 * OrdersSupplyTable. Отобразить список заказов
 *
 * @param {array} orders  - список заказов
 * @param {function} getT  - перевод
 * @param {boolean} isMainFirmRequestType  -  Тип заказов
 *    false - отображаются заказы для поставщиков (свои заказы).
 *    true - отображаются заказы от клиентов (партнёров)
 * @param {function} handleOrderSupplyEdit  - ф-ция  для открытия модального окна редактирования заказа.
 * @param {function} handleOrderSupplyDelete  - ф-ция  для удаления заказа поставщику.
 *  - Удаление возможно только если статус заказа равен 1 и тип заказа `isMainFirmRequestType` равен `false`
 * @returns  {JSX.Element}  -
 */
const OrdersSupplyTable = ({
                               orders,
                               getT,
                               isMainFirmRequestType,
                               handleOrderSupplyEdit,
                               handleOrderSupplyDelete,

                           }) => {
    let hasOrders = !!(Array.isArray(orders) && orders?.length);
    return (
        hasOrders ? <table className="table table-striped">
                <thead>
                <th scope="col">{getT("Название")}</th>
                <th scope="col">{getT("Статус")}</th>
                <th scope="col">{isMainFirmRequestType ? getT("Партнёр") : getT("Поставщик")}</th>
                <th scope="col">{getT("Дата")}</th>
                <th scope="col">{getT("Действие")}</th>
                </thead>
                <tbody>

                {orders?.map(item => {
                    let status = Number(item?.status?.id);
                    let badgeStatus = statusOrdersHelpers.getClassNameByStatusId(status)
                    let isEditStatus = (status === 1);
                    return (<tr>
                        <td data-label={getT("Название")}>{item?.name}</td>
                        <td data-label={getT("Статус")}><span className={badgeStatus}>{item?.status?.name}</span></td>
                        <td data-label={isMainFirmRequestType ? getT("Партнёр") : getT("Поставщик")}>
                            {isMainFirmRequestType ? item.firm_partner.name : item?.firm_main?.name}</td>
                        <td data-label={getT("Дата")}>{item?.date}</td>
                        <td data-label={getT("Действие")}>
                            {/*{(isFirmMainInSystem && isEditStatus) && <>*/}
                            {/*    <i className="fas fa-edit text-primary cursor-pointer mr-2"*/}
                            {/*        // onClick={() => handleOrderSupplyEditWholesaler(item)}*/}
                            {/*    >*/}
                            {/*    </i> edit only ws*/}
                            {/*</>*/}
                            {/*}*/}
                            {(!isMainFirmRequestType && isEditStatus) &&
                                <i className="fas fa-edit text-primary cursor-pointer mr-2"
                                   onClick={() => handleOrderSupplyEdit(item)}></i>
                            }
                            {(!isMainFirmRequestType && isEditStatus) &&
                                <i className="fa fa-trash text-danger cursor-pointer"
                                   onClick={() => handleOrderSupplyDelete(item.ws_orders_supply_id)}></i>
                            }
                        </td>
                    </tr>)
                })}
                </tbody>
            </table>
            :
            <p>{getT("Данный отсутствуют")}</p>
    );
};

export default OrdersSupplyTable;