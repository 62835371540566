import {setStatisticXncOperations, statisticsIsLoadingAC} from "../actions/StatisticXncOperationsAction";
import {dispatcherErrorThunk} from "./common_thunk";
import {SettingAPI} from "../../api/SettingsAPI";
import {SettingCompanyUpdateHtmlModalAC, SettingsCompanySetEntryDataAC} from "../actions/SettingsCompanyAction";
import {toggleLoaderStatusAC} from "../reducers/MainReducer";


const dispatchedSettingsCompany = async (firm_id = null, dispatch) => {
    const response = await SettingAPI.getAllByFirm(firm_id)
    if (!!response?.error) {
        dispatch(dispatcherErrorThunk(response))
        return
    }
    dispatch(SettingsCompanySetEntryDataAC(response?.data))
}
export const getSettingsCompanyThunk = (firm_id = null) => async (dispatch) => {
    try {

        dispatch(toggleLoaderStatusAC(true));
        await dispatchedSettingsCompany(firm_id, dispatch);
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {

        dispatch(dispatcherErrorThunk(e))
    }
}
export const updateSettingCompanyThunk = (api_data) => async (dispatch) => {
    try {

        dispatch(toggleLoaderStatusAC(true));
        const response = await SettingAPI.updateSetting(api_data);
        if (!!response?.error) {
            dispatch(dispatcherErrorThunk(response))
            return
        }
        await dispatchedSettingsCompany(null, dispatch);
        dispatch(SettingCompanyUpdateHtmlModalAC({isOpen: false, setting: null}))

        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {

        dispatch(dispatcherErrorThunk(e))
    }
}
export const deleteSettingCompanyThunk = (setting_id) => async (dispatch) => {
    try {

        dispatch(toggleLoaderStatusAC(true));
        const response = await SettingAPI.deleteSetting(setting_id);
        if (!!response?.error) {
            dispatch(dispatcherErrorThunk(response))
            return
        }
        await dispatchedSettingsCompany(null, dispatch);
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {

        dispatch(dispatcherErrorThunk(e))
    }
}
