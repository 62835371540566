import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import Modal from "../MyModal/Modal";
import {groupedOrderServiceReadMark} from "../../../utils/helpers";
import {
    deleteGroupedProductionMrkToDetailThunk,
    putGroupedProductionMrkToDetailThunk
} from "../../../store/thunks/salary_project/ready_mark_thunk";
import Button from "../../ui/Button";
import {
    createEntryPutAndDeleteService,
    createFieldsForGroupListChecked,
    updateItemServiceType,
    updateServiceType
} from "../../../pages/SalaryProjectReadyMark/read_mark_util";
import {LS_USER_ID, LS_WORKER_ID} from "../../../utils/constants";

const SalaryReadMarkGoodsServiceModal = ({getT, selected_orders, shift_form_values, onClose, is_ready_order}) => {
    const dispatch = useDispatch();
    const [fields, setFields] = useState({});

    useEffect(() => {
        let select_orders_service_ids = selected_orders?.map(item => item?.service)?.flat()
        let order_service = groupedOrderServiceReadMark(select_orders_service_ids)
        let result_fields = {};
        if (!order_service) {
            setFields(result_fields);
            return;
        }
        result_fields = createFieldsForGroupListChecked(order_service, select_orders_service_ids);

        setFields(result_fields)
    }, [selected_orders]);

    //** обновление типа услуги. вкл.выкл*/
    const handlerChangeServiceType = (services_item) => (e) => {
        let checked = e.target.checked;
        let key = `service_${services_item.serv_type}`
        let result_service_type = updateServiceType(fields[key], checked)
        setFields((prevState) => {
            let access_order = prevState[key]?.access_order
            return ({
                ...prevState,
                [key]: {
                    ...result_service_type,
                    access_order: [...new Set(access_order)]
                }
            })
        })
    }
    //** обновление  услуги. вкл.выкл*/
    const handlerChangeItemServiceType = (services_item, children_serv_type) => (e) => {
        let checked = e.target.checked;
        let key = `service_${services_item.serv_type}`
        let result_service_type = updateItemServiceType(fields[key], children_serv_type, checked)
        setFields((prevState) => {
            let access_order = prevState[key]?.access_order
            return ({
                ...prevState,
                [key]: {
                    ...result_service_type,
                    access_order: [...new Set(access_order)]
                }
            })
        })
    }



    const handlerSendServiceMark = () => {
        let {put, remove} = createEntryPutAndDeleteService(fields);
        //::TODO дописать логику работник или пользователь
        let api = {
            shift: shift_form_values?.shift?.salary_shift_id,
            worker_group: shift_form_values?.worker_group,
            user: localStorage.getItem(LS_USER_ID),
            equipment_worker: localStorage.getItem(LS_WORKER_ID),
            part_for_service_add: [],
            services_entry: []
        }
        if (Array.isArray(put) && put?.length) {
            dispatch(putGroupedProductionMrkToDetailThunk({...api, services_entry: put}))
        }
        if (Array.isArray(remove) && remove?.length) {
            dispatch(deleteGroupedProductionMrkToDetailThunk({...api, services_entry: remove}))
        }
    }

    return (
        <Modal title={getT("Групповая отметка услуг")} open={true} onClose={onClose}>
            <ul className="list-group ">
                {groupedOrderServiceReadMark(selected_orders?.map(item => item?.service)?.flat())?.map(service => {
                    let field_service = fields[`service_${service?.serv_type}`];
                    return <li className="list-group-item py-1 px-2 my-1 border-0">

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox"
                                   checked={field_service?.isChecked}
                                   id={`service_root_${service?.serv_type}`}
                                   onChange={handlerChangeServiceType(service)}
                            />
                            <label className="form-check-label" htmlFor={`service_root_${service?.serv_type}`}>
                                {service?.name_service_type}
                                <i className='fas fa-info-circle cursor-pointer  text-primary ml-1'
                                   title={service?.description_service_type}></i>
                            </label>
                        </div>
                        {service?.children?.map(child => {
                            let field_current = field_service?.children?.find(({id}) => Number(id) === Number(child?.services))
                            return <div className="form-check ml-2">
                                <input className="form-check-input" type="checkbox"
                                       checked={field_current?.isChecked}
                                       id={`service_child_${child?.services}`}
                                       onChange={handlerChangeItemServiceType(service, Number(child?.services))}/>
                                <label className="form-check-label" htmlFor={`service_child_${child?.services}`}>
                                    {child?.name_service} <i
                                    className='fas fa-info-circle cursor-pointer text-primary ml-1'
                                    title={child?.description_service}></i>
                                </label>
                            </div>
                        })}

                    </li>
                })}
            </ul>
                <div className="text-center mt-2">
                    <Button onClick={handlerSendServiceMark}>{getT("Отправить")}</Button>
                </div>
        </Modal>
    );
};

export default SalaryReadMarkGoodsServiceModal;