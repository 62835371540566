import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {OrderAPI} from "../../../api/Salary/OrderAPI";
import {dispatcherErrorThunk} from "../common_thunk";
import {searchOrdersToggleAllAC} from "../../actions/SalaryProject";
import {groupedOrderReadMark} from "../../../utils/helpers";

export const getSearchOrdersByFilterThunk = (filter) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response_orders = await OrderAPI.getList(filter);
        if (!!response_orders?.error) {
            dispatch(dispatcherErrorThunk(response_orders));
            dispatch(searchOrdersToggleAllAC({
                orders: null,
                filter: filter,
            }))
            return
        }
        let grouped_orders = Object.values(groupedOrderReadMark(response_orders?.data))
        dispatch(searchOrdersToggleAllAC({
            orders: grouped_orders,
            filter: filter,
        }))
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}