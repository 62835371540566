import React from 'react';

const ButtonPrint = ({
                         onClick, getT,
                            isFileIcon = true,
                         typeActionChildren, titleChildren
                     }) => {
    return (
        <a
            className="btn btn-app bg-info"
            onClick={() => onClick()}
        >
            {typeActionChildren ? <span className="badge bg-danger">{typeActionChildren}</span> : ''}
            {isFileIcon ? <i className="far fa-file-alt"></i>: ''}
            <span className="txt">{titleChildren}</span>
        </a>
    );
};

export default ButtonPrint;