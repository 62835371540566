import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {withLang} from "../../../hoc/withLang";

const Test = ({ clients, UdClient, getT }) => {
  const phoneStr = clients.map((e) =>
    new String(e.phone).replace(/[^\d]+/g, "")
  );
  // console.log(phoneStr);
  return (
    <>
      {clients &&
        clients.map((e) => {
          return (
            <tr key={uuid()}>
              <td data-label={getT("ID")}>{e.id}</td>
              <td data-label={getT("Имя")}>{e.name}</td>
              <td data-label={getT("Телефон")}>{e.phone}</td>
              <td data-label={getT("Email")}>{e.email}</td>
              <td data-label={getT("Статус")}>
                {Number(e.active) === 1 ? (
                  <span className="badge bg-success" style={{textTransform:"lowercase"}}>{getT("Активный")}</span>
                ) : (
                  <span className="badge bg-danger">{getT("не активный")}</span>
                )}
              </td>
              <td data-label={getT("Действие")}>
                <div className={"d-flex align-center clients_stroke_styles"}>
                  <Link to={"/clients/detail/" + e.id}>
                    <i className="far fa-eye"> </i>
                  </Link>
                  <i
                    className="fas fa-key"
                    onClick={() => UdClient(e.id, { pass: 1 })}
                    title={getT("Сменить пароль")}
                  ></i>
                </div>
              </td>
            </tr>
          );
        })}
    </>
  );
};

export default withLang(Test);
