import React from 'react';
import {useFieldArray, useForm} from "react-hook-form";
import Button from "../../../../ui/Button";

const BonusesFinesCreateForm = ({currency, read_mark, getT, handlerSubmitForm}) => {
    let day = new Date()
    let today = day.toISOString().split('T')[0];
    const INITIAL_FILED_ITEM = {
        orders_goods: '',
        worker: '',
        date_when_event_take_place: today,
        summ: 0, currency: '',
        comment: ''
    }

    const {register, control, handleSubmit, formState: {errors}, setError, reset, watch} = useForm({
        defaultValues: {
            error_percentage: null,
            // Set your initial values for the array here
            entry: [
                {
                    ...INITIAL_FILED_ITEM
                }
            ],
        },
    });
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'entry', // name of the field array
    });
    const onSubmit = (data) => {
        let time = new Date().toLocaleTimeString();
        let update_data = data?.entry?.map(item=> {
            return{
                ...item, date_when_event_take_place: `${item.date_when_event_take_place} ${time}`
            }
        })
        handlerSubmitForm(update_data)

    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2 text-right">

            </div>
            {fields.map((field, index) => {
                let is_display_remove = fields?.length !== 1;

                let order_goods_watch = watch(`entry.${index}.orders_goods`);
                let worker_watch = !!watch(`entry.${index}.worker`);


                let read_mark_worker = read_mark?.find(item => Number(item?.orders_goods) === Number(order_goods_watch))?.worker_group_list ?? null;

                return <div key={field.id} className="row align-items-center item-filed-bonuses-fines">
                    <div className="col-12 col-lg-6">
                        <div className={"form-group"}>
                            <label className="form-check-label" htmlFor="inlineCheckbox1">{getT("Услуга")}</label>
                            <select className={"form-control"}  {...register(`entry.${index}.orders_goods`)}
                                    defaultValue={field.orders_goods} required={true}>
                                <option value="">---- ------</option>
                                {read_mark?.map(item => {
                                    return <option
                                        value={item?.orders_goods}>[{item?.order}] {item?.service_name} / {getT("кол-во услуги")} {item.count}</option>
                                })}
                            </select>

                        </div>
                    </div>

                    {(!!order_goods_watch && Array.isArray(read_mark_worker) && read_mark_worker?.length) &&
                        <div className="col-12 col-lg-6">
                            <div className={"form-group"}>
                                <label className="form-check-label" htmlFor="inlineCheckbox1">{getT("Работник")}</label>
                                <select className={"form-control"}  {...register(`entry.${index}.worker`)}
                                        defaultValue={field.worker} required={true}>
                                    <option value="">---- ------</option>
                                    {read_mark_worker?.map(item => {
                                        return <option
                                            disabled={!!fields?.find(e => Number(e?.worker) === Number(item?.worker))}
                                            value={item?.worker}> {item?.name} - {item?.percentage}%</option>
                                    })}
                                </select>

                            </div>
                        </div>
                    }
                    {!!order_goods_watch && worker_watch && <>
                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="form-check-label" >{getT("Дата применения")}</label>
                                <input {...register(`entry.${index}.date_when_event_take_place`, {
                                    value: field.date_when_event_take_place
                                })} className="form-control" type={"date"} required={true}
                                       min={today.split('T')[0]}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="form-group">
                                <label className="form-check-label"  >{getT("Сумма")}</label>
                                <input {...register(`entry.${index}.summ`, {
                                    value: field.summ
                                })} className="form-control" type={"number"} required={true} min={1}/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className={"form-group"}>
                                <label className="form-check-label" >{getT("Валюта")}</label>
                                <select className={"form-control"}  {...register(`entry.${index}.currency`)}
                                        defaultValue={field.currency} required={true}>
                                    <option value="">---- ------</option>
                                    {currency?.map(item => {
                                        return <option
                                            value={item?.currency_id}> {item?.code}</option>
                                    })}
                                </select>

                            </div>
                        </div>
                        <div className="col-12">
                            <div className={"form-group"}>
                                <label className="form-check-label"
                                       htmlFor="inlineCheckbox1">{getT("Комментарий")}</label>
                                <textarea className={"form-control"}  {...register(`entry.${index}.comment`)}
                                          defaultValue={field.comment} required={true}></textarea>

                            </div>
                        </div>
                    </>
                    }

                    <div className="remove-icon">


                        {is_display_remove &&
                            <i className={'fa fa-trash text-danger cursor-pointer'}
                               onClick={() => remove(index)}></i>
                        }
                    </div>
                </div>
            })}
            <div className="">
                <input className={'d-none'} type="text" {...register("error_percentage")}/>
                {errors.error_percentage?.message &&
                    <p className={"text-danger"}>{errors.error_percentage.message}</p>}
            </div>
            <div className=" mt-3 d-flex justify-content-center">
                <Button type={"button"} className={"btn-outline-success mx-2"}
                        onClick={() => append({...INITIAL_FILED_ITEM})}>{getT("Добавить")}</Button>
                <Button className={"btn-primary mx-2"}>{getT("Отправить")}</Button>
            </div>

        </form>
    )
        ;
};

export default BonusesFinesCreateForm;