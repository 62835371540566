import {instances, URI_API_EXTTUNELL} from "../Salary/setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const ws_orders_goods_get = 'ws_orders_goods_get'
const ws_orders_goods_set = 'ws_orders_goods_set'
const ws_orders_goods_delete = 'ws_orders_goods_delete'
const ws_goods_information_get = 'ws_goods_information_get'

/** Api
 * @method @{getOrdersGoods}  - существующие связи, также будет информация о тех товарах, которые заказаны, но на них не установлены цены либо связь не актуальна.
 * @method @{setOrderGood}  - создать/обновить связь заказа товара (клиента - партнера || партнера - оптовика)
 * @method @{deleteOrderGood}  - удалить связь заказа товара (клиента - партнера || партнера - оптовика)
 * @method @{deleteOrderGood}  - удалить связь заказа товара (клиента - партнера || партнера - оптовика)
 *
 * @method @{getGoodsInformationByOrderId}  - получает данные по товару, где видно информацию по товару, где его можно купить, как быстро его доставят, какое минимальное количество на закупку.
 * @returns {object} -
 */

export const WholesaleOrdersGoodsAPI = {
    getOrdersGoods(form_data) {
        return instances.post(URI_API_EXTTUNELL + '/', form_data, {
            headers: {
                action: ws_orders_goods_get,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    setOrderGood(form_data) {
        return instances.post(URI_API_EXTTUNELL + '/', form_data, {
            headers: {
                action: ws_orders_goods_set,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    deleteOrderGood(form_data) {
        return instances.post(URI_API_EXTTUNELL + '/', form_data, {
            headers: {
                action: ws_orders_goods_delete,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getGoodsInformationById(form_data) {
        return instances.post(URI_API_EXTTUNELL + '/', form_data, {
            headers: {
                action: ws_goods_information_get,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },

}