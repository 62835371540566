import React, {useMemo, useState} from 'react';
import {
    getStaticsXncOperationsMaterialBandConnection,
    getStaticsXncOperationsMaterialBandConnectionModal
} from "../../../store/selectors/statistics_xnc_operations_selector";
import {useDispatch, useSelector} from "react-redux";
import Modal from "../MyModal/Modal";
import {
    statisticXncOperationMaterialBandConnectionCreateModalAC
} from "../../../store/actions/StatisticXncOperationsAction";
import {
    INITIAL_STATE_STATISTICS_XNC_OPERATION_MATERIAL_BAND_CONNECTED_CREATE_MODAL
} from "../../../store/reducers/StatisticReducer";
import {
    createStatisticXncOperationsMaterialBandConnectCreateThunk,
    fetchingSearchMaterialBandThunk
} from "../../../store/thunks/statistic/statistic_xnc_operations_thunk";
import Select from "react-select";
import Button from "../../ui/Button";

const StatisticsXncOperationsMaterialBandConnectedCreateModal = ({getT}) => {
    const modal_state = useSelector(getStaticsXncOperationsMaterialBandConnectionModal);
    const statistics_data_state = useSelector(getStaticsXncOperationsMaterialBandConnection);
    const dispatch = useDispatch();
    const [search_term_material, setSearchTermMaterial] = useState('')
    const [choice_material, setSChoiceMaterial] = useState({value: null, label: null})
    const [search_term_band, setSearchTermBand] = useState('')
    const [choice_band, setChoiceBand] = useState({value: null, label: null})

    const [isLoadingBand, setIsLoadingBand] = useState(false)
    const [isLoadingMaterial, setIsLoadingMaterial] = useState(false)

    const handlerClose = () => {
        dispatch(statisticXncOperationMaterialBandConnectionCreateModalAC(INITIAL_STATE_STATISTICS_XNC_OPERATION_MATERIAL_BAND_CONNECTED_CREATE_MODAL))
    }
    const fetchingMaterialBand = async (search_term, type) => {
        let data = {
            type: type,
            text: search_term,
            from: 0
        }
        await dispatch(fetchingSearchMaterialBandThunk(data))
    }
    const handlerSearchMaterial = async (val) => {
        setSearchTermMaterial(val)
        if (val?.length >= 4) {
            setIsLoadingMaterial(true)
            await fetchingMaterialBand(val, 'part')
            setIsLoadingMaterial(false)
        }
    }
    const handlerSearchBand = async (val) => {
        setSearchTermBand(val)
        if (val?.length >= 4) {
            setIsLoadingBand(true)
            await fetchingMaterialBand(val, 'band')
            setIsLoadingBand(false)

        }
    }
    const material_options = useMemo(() => {
        if (!modal_state?.isOpen || !modal_state?.material) return []
        return modal_state?.material?.filter(item => Number(item?.z) >= 10)
            ?.map(e => ({
                label: `[${e?.id}] ${e?.name}. ${e?.x}x${e?.y}x${e?.z}`, value: e?.id
            })) ?? []
    }, [modal_state?.material])

    const band_options = useMemo(() => {
        if (!modal_state?.isOpen || !modal_state?.band) return []
        if (!Array.isArray(modal_state?.material) || !Array.isArray(modal_state?.band)) return []
        let find_material = modal_state?.material?.find(m => Number(m?.id) === Number(choice_material?.value));
        if (!find_material) return []
        let min_y = Number(find_material?.z) + 5;

        return modal_state?.band
                ?.filter(item => Number(item?.y) >= min_y)
                ?.map(e => ({
                    label: `[${e?.id}] ${e?.name}. ${e?.x}x${e?.y}x${e?.z}`, value: e?.id
                }))
            ?? []
    }, [modal_state, choice_material])
    const handlerSetOption = (key) => (item) => {
        if (key === "material") {
            setSChoiceMaterial(item)
            return;
        }
        if (key === "band") {
            setChoiceBand(item)
            return;
        }
        return;
    }
    const already_connection = useMemo(() => {
        if (!choice_material?.value || !choice_band?.value || !statistics_data_state?.connected) return null
        let find_connection = statistics_data_state?.connected?.find(item => (Number(item?.material_id) == Number(choice_material?.value)) && (Number(item?.band_id) === Number(choice_band?.value)))
        return find_connection
    }, [statistics_data_state, choice_material, choice_band]);
    const handlerCreate = ()=>{
        dispatch(createStatisticXncOperationsMaterialBandConnectCreateThunk({material_id: choice_material?.value, band_id: choice_band?.value}, modal_state?.params))
    }
    if (!modal_state?.isOpen) return null

    return (
        <Modal title={getT("Создать")} onClose={handlerClose} open={modal_state.isOpen}>
            <div className="form-group">
                <label htmlFor="exampleInputEmail1">{getT("Материал")}</label>
                <Select
                    options={material_options}
                    onInputChange={handlerSearchMaterial}
                    inputValue={search_term_material}
                    onChange={handlerSetOption("material")}
                    value={choice_material}
                    isLoading={isLoadingMaterial}
                    isClearable
                />
            </div>

            {choice_material?.value &&
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{getT("Кромка")}</label>
                    <Select
                        options={band_options}
                        onInputChange={handlerSearchBand}
                        inputValue={search_term_band}
                        value={choice_band}
                        onChange={handlerSetOption("band")}
                        isLoading={isLoadingBand}
                        isClearable
                    />
                </div>}

            {!!(choice_material?.value && choice_band?.value) && <>
                {already_connection ?
                    <div className="alert alert-warning mt-3" role="alert">
                        <p className={"text-center m-0"}>
                            {getT("Связь материала и кромки уже существует")}
                        </p>
                    </div> :
                    <div className="d-flex justify-content-center mt-3">
                        <Button onClick={handlerCreate}>{getT("Создать связь")}</Button>
                    </div>
                }
            </>}
        </Modal>
    );
};

export default StatisticsXncOperationsMaterialBandConnectedCreateModal;