import React from 'react';

const ClearData = ({getT, clearData}) => {
    return (
        <i
            style={{marginRight: "10px", cursor:'pointer', color: 'red', fontSize: '13px'}}
            onClick={()=> clearData()}
            className="fas fa-ban "
        >
            {getT("Сбросить")}
        </i>
    );
};

export default ClearData;