import {instances, URI_API_EXTTUNELL} from "../Salary/setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const URL = '/searchmaterial'
export const SearchMaterialAPI = {
    search(data = null) {
        return instances.post(URL + '/', data).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getById(data = null) {
        return instances.post('/materialsn', data, {
            headers: {
                'Point-Action': 'filter',
                // 'Language': 'ru'
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
}