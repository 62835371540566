export const WS_ORDERS_GOODS_GET = "WS_ORDERS_GOODS/GET";
export const WS_ORDERS_GOODS_SET_MODAL = "WS_ORDERS_GOODS/SET_MODAL";

export const WS_ORDERS_GOODS_GET_INFORMATION_MODAL = "WS_ORDERS_GOODS/GET_INFORMATION_MODAL";
/** wsGetOrdersGoodsAction. set orders goods
 * @param {object || null}  payload-
 * @returns {object} -
 */

export const wsGetOrdersGoodsAction = (payload)=>{
    return{
        type: WS_ORDERS_GOODS_GET,
        payload: payload
    }
}
/**
 * wsGetGoodsInformationModalAC. данные по товару
 *
 * @param {object || null} availabilityInfo - информация по приобретению товара и список поставщиков
 * @param {object || null} currentGoods - товар
 * @param {number || null} currentGoods.ws_orders_goods_id*  -
 * @param {string || null} currentGoods.title*  -
 * @param {number || null} currentGoods.id*  -
 * @param {boolean} isOpen  -
 * @param {number || null} orderId  -
 * @param {object || null} exist  -существующея потребность
 * @returns  {object}  -
 */
export const wsGetGoodsInformationModalAC = ({availabilityInfo, currentGoods, isOpen, orderId, exist})=>{
    return{
        type: WS_ORDERS_GOODS_GET_INFORMATION_MODAL,
        payload: {
            availabilityInfo: availabilityInfo, currentGoods: currentGoods, isOpen: isOpen, orderId: orderId,exist: exist
        }
    }
}
/**
 * .
 *
 * @param {boolean} isOpen  -
 * @param {object || null} currentGoods - товар
 * @param {object || null}  filter - текущий предустановленный фильтр
 * @returns  {}  -
 */
export const wsOrderGoodsSetModalAC =({isOpen, currentGoods, filter})=>{
    return{
        type: WS_ORDERS_GOODS_SET_MODAL,
        payload:{isOpen: isOpen, currentGoods: currentGoods, filter: filter}
    }

}