import getT from "../../getT"
export default function ({xnc, type_name=false}){
  return (
     ` <tr>
        <th></th>
          <th>${getT('количество деталей с урезкой')}</th>
                <th>${getT('количество урезок')}</th>
                <th>${getT('длина урезок')}</th>
        <th></th>
      </tr>
       <tr>
        <th> ${type_name? type_name : getT(xnc?.type_name)}</th>
       <td>${+Number(xnc?.part_cut_to_count ?? '').toFixed(2)}</td>
        <td>${+Number(xnc?.cut_to_count ?? '').toFixed(2)}</td>
        <td>${+Number(xnc?.cut_to_cut_count ?? '').toFixed(2)}</td>
        <td></td>
      </tr>
  `
  );
}