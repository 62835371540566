import getT from "../getT";
import {formatNumbersThousands} from "../helpers";
import {getCalculateAmountAndVat, getCalculateVat} from "../../utils/helpers";

export default function ({packing, currency, isVisibleBins, isVisiblePart,production_vat_display_short, production_vat}) {
    if (!Array.isArray(packing) || !packing?.length) return <p></p>
    const tables = packing?.map((packing, root_index) => {
        const total_vat =!!Number(production_vat) ? getCalculateVat(packing?.fullSumm, Number(production_vat)) : 0;
        const amount_total_vat =!!Number(production_vat) ? getCalculateAmountAndVat(packing?.fullSumm, total_vat) : 0;
        return (`     <div class='section-table-pack section-gap'>
      <h2>${getT('Коробки')}</h2>
                    <table style="  page-break-inside: auto;">
                        <tr>
                            <th>${getT('Коробки')}</th>
                            <th colspan="3">${getT('Размер')}</th>
                            <th colspan="2">${getT('Количество')}</th>
                            <th colspan="2">${getT('Вес')}</th>
                            <th rowspan="2">${getT('Стоимость')}</th>
                        </tr>
                        <tr>
                            <th>${getT('Номер')}</th>
                            <th>${getT('Высота')}</th>
                            <th>${getT('Ширина')}</th>
                            <th>${getT('Глубина')}</th>
                            <th>${getT('Слои')}</th>
                            <th>${getT('Детали')}</th>
                            <th>${getT('Брутто')}</th>
                            <th>${getT('Нетто')}</th>
                        </tr>
                        ${packing?.packs?.map((pack, index) => {
            return PackItem({pack, index, isVisiblePart, isVisibleBins})
        })?.join('')}
                    <tr class='tr-bg-green'>
                        <td colspan="9" style="text-align:right;" class='amount'>
                            ${getT('Стоимость')} : ${formatNumbersThousands(packing?.fullSumm)} 
${!!Number(production_vat) ?
            production_vat_display_short ?
                `<span style="font-size: 14px">
          ${currency} / ${amount_total_vat}  ${currency} ${getT("с ндс")}
        </span>`
                :
                `+ ${total_vat} ${getT("ндс")} = ${amount_total_vat} ${currency}`
            : currency}

                        </td>
                    </tr>
                </table>
                </div>`)
    })?.join('')
    return `${tables}`


}

const PackItem = ({pack, index, isVisiblePart, isVisibleBins}) => {
    let {
        packWidth = 0,
        packHeight = 0,
        packDepth = 0,
        packLayers = 0,
        packParts = 0,
        packWeight = 0,
        innerPackWeight = 0,
        fullSumm = 0,
        bins = []
    } = pack;
    const sqBins = (Number(packHeight) * Number(packWidth) )/ 1000000;
    return `
    <tr class='tr-bg-pack'>
            <td>${index + 1}</td>
            <td>${packHeight}</td>
            <td>${packWidth}</td>
            <td>${packDepth}</td>
            <td>${packLayers}</td>
            <td>${packParts}</td>
            <td>${formatNumbersThousands(packWeight)}</td>
            <td>${formatNumbersThousands(innerPackWeight)}</td>
            <td>${formatNumbersThousands(fullSumm)}</td>
        </tr>
        
        ${isVisibleBins ? Array.isArray(bins) && bins?.length && bins?.map((bind, bind_index) => {
        return Bind({bind, bind_index, isVisiblePart, sqBins})
    })?.join('') : ''}
    
    `
}
const Bind = ({bind, bind_index, isVisiblePart, sqBins}) => {
    let {binSq = 0, binBlankSq = 0} = bind
    let sqDetails = bind?.parts?.reduce((acc, item)=>{
        return ((Number(item.w) * Number(item.h)) / 1000000) + acc
    }, 0);
    let binSqResult = (Number(sqDetails) / Number(sqBins))

    return `
      <tr className={'tr-collapse'}>
                <th colspan="9" style="text-align:left;">${getT('Слой')} ${bind_index+1}</th>
      </tr>
                <tr>
                    <th rowspan="2">${getT('Номер')}</th>
                    <th rowspan="2">${getT('Деталей')}</th>
                    <th rowspan="2">${getT('Вес')}</th>
                    <th colspan="2">${getT('Толщина')}</th>
                    <th rowspan="2">${getT('% Заполнения')}</th>
                </tr>
                <tr>
                    <th>${getT('мин')}</th>
                    <th>${getT('макс')}</th>
                </tr>
                <tr>
                    <td>${bind_index + 1}</td>
                    <td>${bind?.binParts ?? '-'}</td>
                    <td>${formatNumbersThousands(bind?.binWeight) ?? '-'}</td>
                    <td>${bind?.binMinZ ?? '-'}</td>
                    <td>${bind?.binMaxZ ?? '-'}</td>
            <td>${binSqResult !== 0 ? formatNumbersThousands(binSqResult) : binBlankSq}</td>
                </tr>
                
                ${isVisiblePart ? Parts({bind, bind_index}) : ''}
    `
}
const Parts = ({bind, bind_index}) => {
    if (!bind.hasOwnProperty('parts') || !Array.isArray(bind?.parts)) return '';
    let {parts = []} = bind;

    return `
    
      <tr className={'tr-bg-parts'}>
                <th class='tr-bg-parts' colspan="9" style="text-align:left">${getT('Детали')} (${bind?.binParts})
                  
                </th>
            </tr>
                <tr>
                    <th rowspan='2'>${getT('Детали')}</th>
                    <th colspan='3'>${getT('Размер')}</th>
                    <th colspan='1' rowspan='2'>id</th>
                    <th colspan='1' rowspan='2'>${getT('Название')}</th>
                    <th rowspan='2' colspan='3'></th>
                </tr>
                <tr>
                    <th>${getT('Высота')}</th>
                    <th>${getT('Ширина')}</th>
                    <th>${getT('Глубина')}</th>
                </tr>
                ${parts?.map((part, index) => {
        let {id_ifurn, name = '', w = 0, h = 0, z = 0} = part;
        return (
            ` <tr>
                            <td>${index + 1}</td>
                            <td>${h}</td>
                            <td>${w}</td>
                            <td>${z}</td>
                            <td>${id_ifurn}</td>
                            <td>${name}</td>
                        </tr>`
        )
    })?.join('')}
    `
}