import md5 from "md5";
import Translate from "../languages/translation.json";

export default function(key) {
    let keyTrim = key.trim();
    let mdKey = md5(keyTrim);

    let lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "ua";

    if (Translate[0][mdKey] && Translate[0][mdKey][lang]) {
        return Translate[0][mdKey][lang];
    } else {
        return key;
    }

}