import React from 'react';

/** styles btn https://getbootstrap.com/docs/4.6/components/buttons/
 */
const Button = ({
                    className = 'btn-primary', onClick = () => {
    },  title = 'Отправить', children=null, ...reset
                }) => {
    return (
        <button className={`btn ${className}`} onClick={onClick} {...reset}>
            {children}
        </button>
    );
};

export default Button;