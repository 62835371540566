export const SETTINGS_COMPANY_SET_ENTRY_DATA = "SETTINGS_COMPANY/SET_ENTRY_DATA";
export const SETTINGS_COMPANY_UPDATE_HTML_MODAL = "SETTINGS_COMPANY/UPDATE_HTML_MODAL";

export const SettingsCompanySetEntryDataAC = (payload)=>{
    return {
        type: SETTINGS_COMPANY_SET_ENTRY_DATA,
        payload: payload
    }
}
export const SettingCompanyUpdateHtmlModalAC = (payload)=>{
    return {
        type: SETTINGS_COMPANY_UPDATE_HTML_MODAL,
        payload: payload
    }
}