import React from "react";
import {connect} from "react-redux";
import {
  changeOrdersFilterFormValueAC,
  createNewOrderThunk,
  getOrdersFilteredDataThunk,
  onClearCanceledTunck,
  setClientsDataThunk,
  setOrdersDataThunk,
  toggleOrderChangeStatusAC,
  updateOrderDataThunk,
  updateSettingsThunk
} from "../../store/reducers/MainReducer";
import OrdersOutstanding from "./OrderOutstanding";

class OrdersOutstandingContainer extends React.Component {
  componentDidMount() {
    const initialFilters = {
      // type: "outstanding",
      limit: 1,
      status: 1111
    };

    // TODO: rewrite setFilters to accept objects
    Object.entries(initialFilters).forEach(([key, value]) =>
      this.props.setFilters(key, value)
    );

    this.props.setOrders(initialFilters);
    this.props.setClients(false);
    this.props.setManagers();
  }
  componentWillUnmount(){
    // this.props.checkAuntificationUser()
  }

  render() {
    return (
      <OrdersOutstanding
        orders={
          this.props.archive
            ? this.props.orders_archive
            : this.props.orders_actual
        }
        title={"orders_outstanding"}
        setFilters={this.props.setFilters}
        applyFiltersAndFetch={this.props.applyFiltersAndFetch}
        toggleChangeStatus={this.props.toggleChangeStatus}
        updateOrder={this.props.updateOrder}
        form={this.props.filterForm}
        status={this.props.orderStatus}
        clients={this.props.clients}
        changedStatus={this.props.changedStatus}
        createOrder={this.props.createOrder}
        userRoles={this.props.user.role}
        managers={this.props.managers}
        calc={this.props.calc}
        onClear={this.props.clearDt}
      />
    );
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    setOrders: (filters) => dispatch(setOrdersDataThunk(filters)),
    applyFiltersAndFetch: (input, value, filters) =>
      dispatch(getOrdersFilteredDataThunk(input, value, filters)),
    setClients: (withLoader) => dispatch(setClientsDataThunk(withLoader)),
    toggleChangeStatus: (id) => dispatch(toggleOrderChangeStatusAC(id)),
    // setManagers: () => dispatch(getManagersDataThunk()),
    setManagers: () => dispatch(updateSettingsThunk()),
    updateOrder: (order_id, data) =>
      dispatch(updateOrderDataThunk(order_id, data, "1111")),
    createOrder: (client_id) => dispatch(createNewOrderThunk(client_id)),
    setFilters: (input, value) =>
      dispatch(changeOrdersFilterFormValueAC(input, value)),
    clearDt:(params) => dispatch(onClearCanceledTunck(params))
  };
};

let mapStateToProps = (state) => {
  return {
    orders_actual: state.main.orders.actual,
    orders_archive: state.main.orders.archive,
    filterForm: state.main.ordersForm,
    orderStatus: state.main.orderStatus,
    managers: state.main.managers,
    clients: state.main.clients,
    changedStatus: state.main.ordersChangeStatus,
    user: state.auth.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersOutstandingContainer);
