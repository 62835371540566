import React, {useCallback, useEffect, useState} from "react";
import useClickOutside from "../../../hook/useClickOutside";

const FieldType = {
    number: 'dig',
    text: 'str',
    bool: 'bool',
    html: 'html'
}

export const Field = ({cb, value, type, id = '', disabled}) => {
    const [inputValue, setInputValue] = useState(value);

    const {ref, isVisible, setVisible} = useClickOutside()
    // Обработчик изменения значения
    const handleChange = useCallback((e) => {
        let is_boll = type === FieldType.bool;
        const newValue = type === FieldType.bool ? Number(e.target.checked) : e.target.value;
        if (is_boll) {
            cb(newValue)
            return
        }
        setInputValue(newValue);
    }, [type, cb]);
    const handlerSend = () => {
        cb(inputValue)
        setVisible(false)
    }

    useEffect(() => {
        if (inputValue !== value) {
            setInputValue(value)
        }
    }, [value])

    // Опции для полей ввода
    let inputProps = {};
    switch (type) {
        case FieldType.number:
            inputProps = {
                type: "number",
                is_edit: true
                // value: value
            };
            break;
        case FieldType.text:
            inputProps = {
                type: "text",
                is_edit: true

                // value: value,
            };
            break;
        case FieldType.bool:
            inputProps = {
                type: "checkbox",
                // value: value
            };
            break;

        default:
            inputProps = {
                type: "text",
                is_edit: true

            };
            break;
    }
    return (
        <div ref={ref}>
            {type === FieldType.bool ? (
                <div className="form-check">
                    <input
                        disabled={disabled}
                        className="form-check-input"
                        id={`field_type_${id}`}
                        onChange={handleChange}
                        type={"checkbox"}
                        checked={!!Number(inputValue)}

                    />
                    <label htmlFor={`field_type_${id}`}>
                    </label>
                </div>
            ) : (
                <div>
                    {isVisible ?
                        <div className={"form-group d-flex align-items-center justify-content-center"}
                             style={{gap: '10px'}}>
                            <input
                                disabled={disabled}
                                className="form-control "
                                onChange={handleChange}
                                type={inputProps.type}
                                value={inputValue}
                                style={{width: "max-content", maxWidth: "100%"}}
                            />
                            <button onClick={handlerSend} className={"btn btn-success"}>
                                <i className="fa fa-paper-plane"></i>
                            </button>
                            {/*<i className="fa fa-times text-secondary text-sm"  onClick={handlerSend}></i>*/}
                        </div>
                        :
                        <div className="d-flex justify-content-center align-items-center" style={{gap: "10px"}}>
                            <div className={""} style={{maxWidth:"90%"}}>{value}</div>
                            <i className="fas fa-edit cursor-pointer text-primary text-sm"
                               onClick={() => setVisible(true)}></i>
                        </div>
                    }
                </div>

            )}
        </div>
    );
}