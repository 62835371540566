import React, { useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import toast from "react-hot-toast";

const ACCESS_FILED_KEY = [
    "ua", "ru", "ro", "en", "pl", "de", "sk", "lv", "lt", "et", "he"
];
const ACCESS_FILED_KEY_TITLE = {
    "ua": "UKR", "ru":"RUS" , "ro": "ROM", "en":"ENG", "pl":"POL", "de":"GER", "sk":"SLO", "lv":"LAT", "lt":"LIT", "et":"EST", "he":"HEB"


}
// Определяем модули и форматы для ReactQuill
const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, false] }], // Заголовки
        [{ 'size': ['small', 'medium', 'large', 'huge'] }], // Размер шрифта
         // Размер шрифта
        ['bold', 'italic', 'underline', 'strike', 'blockquote'], // Форматирование
        [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Списки
        ['link'], // Ссылки
        [{ 'align': [] }], // Выравнивание
        ['clean'], // Очистка форматирования
    ],
};

const formats = [
    'header', 'size', 'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent', 'link', 'align', 'code-block',
];

const FormUpdateHtml = ({ getT, initial_values, handlerSend }) => {
    const { register, handleSubmit, setValue} = useForm();
    const quillRefs = useRef({}); // Используем рефы для каждого редактора

    const cleanInitialValue = (value) => {
        // Если значение не содержит теги, возвращаем его как есть
        return value ? value : ''; // Возвращаем пустую строку если значение пустое
    };

    useEffect(() => {
        if (initial_values && typeof initial_values === "object") {
            ACCESS_FILED_KEY.forEach(key => {
                let value = initial_values?.[key] ?? "";
                setValue(key, cleanInitialValue(value));
            });
        }
    }, [initial_values, setValue]);
    if(!initial_values) return null

    const handleChange = (html, key) => {
        const editor = quillRefs.current[key]?.getEditor();
        const text = editor?.getText().trim() || '';

        // Обновляем значение только при изменении контента
        if (text) {
            setValue(key, html);
        } else {
            setValue(key, '');
        }
    };

    const onSubmit = (data) => {
        const formErrors = {};
        let hasError = false;

        Object.keys(data).forEach(key => {
            const content = data[key].trim();
            if (content === '') {
                formErrors[key] = "This field cannot be empty";
                hasError = true;
            }
        });

        if (hasError) {
            // Выводим ошибки
            toast.error(`${Object?.keys(formErrors)?.map(key=> ACCESS_FILED_KEY_TITLE?.[key] ?? key)} - ${getT("Поле обязательное к заполнению")}`)
            return;
        }

        // Передаем данные обработчику
        handlerSend(data);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group row">
                {ACCESS_FILED_KEY.map(key => (

                    <div key={key} className="col-12 col-md-6 col-lg-6 col-xl-6 my-2">
                        <label>{ACCESS_FILED_KEY_TITLE?.[key] ?? key}</label>
                        <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            value={cleanInitialValue(initial_values[key])}
                            onChange={(value) => handleChange(value, key)}
                            ref={(el) => quillRefs.current[key] = el}
                        />
                    </div>
                ))}
            </div>
            <div className="d-flex justify-content-center mt-4">
                <button className="btn btn-primary" type="submit">{getT("Отправить")}</button>
            </div>
        </form>
    );
};

export default FormUpdateHtml;
