import React, {useEffect} from 'react';
import {useForm} from "react-hook-form";
import WholeSaleFieldMap from "./WholeSaleFieldMap";
import "./main.css"
import DinamicOrdersSupplySelect from "./DinamicOrdersSupplySelect";


/**
 * Создание или обновляение потребности товара которых нет у фирмы Партнёра
 *
 * @param {array|| null} wholesale - розширенный список оптовиков(поставщиков)
 * @param {array|| null} orders_supply - список заказов для поставщика
 * @param {number|| null} ws_orders_goods_id - id  потребности товара
 * @param {number|| null} orders_goods_id - id  связи между заказом и  товаром
 * @param {number|| null} order_main_firm - id  фирмы поставщиков
 * @param {number|| null} order_supply - id  заказа для поставщика
 * @param {number|| null} order_supply_unlink - открыть заказ поставщику - 1, закрыть -0.
 * Показать если order_supply уже был добавлен или добавили при заполнении формы
 * @param {function} getT
 * @param {function} handlerSubmit -
 * @returns  {jsx}  -
 */

const WSOrdersGoodsSetForm = ({
                                  getT,
                                  wholesale = null,
                                  ws_orders_goods_id = null,
                                  order_supply = null,
                                  order_supply_unlink,
                                  orders_goods_id = null,
                                  order_main_firm = null,
                                  handlerSubmit
                              }) => {
    /** isIncludeFilterWholeSale. Флаг, определяющий необходимость фильтрации заказа поставщику по массиву wholesale */
    let isIncludeFilterWholeSale = !!(Array.isArray(wholesale) && wholesale?.length)

    const {register, handleSubmit, setValue, watch} = useForm({
        values: {
            // status: 1,
            wholesale_id: isIncludeFilterWholeSale ? (order_supply?.firm_main?.id ?? null) : null,
            order_goods: ws_orders_goods_id ? null : orders_goods_id,
            order_supply: order_supply,
            order_supply_unlink: order_supply_unlink ?? null,
            order_main_firm: order_main_firm ?? null,
            ws_orders_goods_id: ws_orders_goods_id ?? null
        }
    });
    let watchWholeSaleId = watch("wholesale_id");
    let watchOrderSupply = watch("order_supply");

    /** Сбрасываем значение Заказ поставщику если фирма поставщика не равняется фирме поставщика заказа*/
    useEffect(() => {
        const orderSupplyFirmMainId = watchOrderSupply?.firm_main?.id ?? null;
        const shouldResetOrderSupply = (
            Number(watchWholeSaleId) !== Number(orderSupplyFirmMainId) &&
            // (orderSupplyFirmMainId) &&
            watchWholeSaleId
        );

        if (shouldResetOrderSupply) {
            setValue("order_supply", null);
        }
    }, [watchWholeSaleId, watchOrderSupply, setValue]);


    /** переключаем фирмы поставщиков */
    const onChangeRadioWS = (e) => {
        let value = e.target.value;
        setValue("wholesale_id", Number(value))
    }
    /** Устанавливаем значение "Заказ поставщика", если order_main_firm пустой и нет списка поставщиков
     Устанавливаем order_main_firm  из выбранного заказа selectedOption.firm_main.id  */
    const onChangeOrderSupply = (selectedOption) => {
        setValue("order_supply", selectedOption);

    }
    /**
     * что бы отвязать потребность от заказа поставщику нужно установить order_supply_unlink=1.
     если  data.order_supply = null || data.order_supply.value = -1 && ранее был установлен order_supply
     */
    const onSubmit = (data) => {
        let {order_supply_unlink, wholesale_id, ...reset} = data;
        let orderSupply = data.order_supply;
        let idSupply = orderSupply ? Number(orderSupply?.value) : -1;
        let isActiveOrderSupplyUnlink = (idSupply === -1) && !!(Number(order_supply?.value))
        let order_supply_id = idSupply >= 1 ? idSupply : null;
        let order_unlink = isActiveOrderSupplyUnlink ? 1 : null;
        handlerSubmit({...reset, order_supply_unlink: order_unlink, order_supply: order_supply_id});

    }
    let titleForm = !!Number(ws_orders_goods_id) ? "Обновить потребность" : "Создать потребность";

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h5 className={"text-center order-goods-set-form-title"}>{getT(titleForm)}</h5>
            {(isIncludeFilterWholeSale) && <div className={"form-group"}>
                <label htmlFor="">{getT("Поставщик")}</label>
                <div className="form-check ws-field-map">
                    <input className="form-check-input" type="radio" id={"wsWithout"}
                           checked={Number(watchWholeSaleId) === 0}
                           name="order_main_firm" value={0} onChange={onChangeRadioWS}/>
                    <label className="form-check-label" htmlFor="wsWithout">{getT("Без поставщика")}</label>
                </div>
                {wholesale?.map(item => {
                    return <WholeSaleFieldMap value={watchWholeSaleId} onChange={onChangeRadioWS} getT={getT}
                                              item={item}/>
                })}       </div>}
            <DinamicOrdersSupplySelect wholeSaleId={watchWholeSaleId} getT={getT} onChange={onChangeOrderSupply}
                                       value={watchOrderSupply}/>

            <div className="d-flex justify-content-center">
                <button type={"submit"} className={"btn btn-primary"}>{getT("Отправить")}</button>
            </div>
        </form>
    );
};

export default WSOrdersGoodsSetForm;