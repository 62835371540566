import {instances, URI_API_EXTTUNELL} from "../Salary/setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const ws_get_whole_sale_firm = 'ws_get_whole_sale_firm'
const ws_get_material_type_by_firm = 'ws_get_material_type_by_firm'

export const WholesaleAdditionalAPI={
    getFirmsList() {
        return instances.post(URI_API_EXTTUNELL + '/', null, {
            headers: {
                action: ws_get_whole_sale_firm,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getMaterialTypeListByFirm(form_data){
        return instances.post(URI_API_EXTTUNELL + '/', form_data, {
            headers: {
                action: ws_get_material_type_by_firm,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }
}