import React, {useState} from "react";
import PageTitle from "../PageTitle/PageTitle.jsx";
import InputMask from "react-input-mask";
import {withLang} from "../../hoc/withLang";
import {maskPhoneCountry} from "../../constants";
import SelectPhone from "../SelectPhone";
import CreateProjectModal from "../Modals/CreateProjectModal/CreateProjectModal";
import ApiService from "../../api/api";
import skectchUpImg from "../../image/sketch_up.png"
import {helpArticleGetByIdToggleModalThunk} from "../../store/thunks/help-article-thunk";
import {useDispatch} from "react-redux";
import IconQuestion from "../../Icons/IconQuestion";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../constants/help_article_constants";
const apiService = new ApiService()
const OrderAdd = (props) => {
    const dispatch = useDispatch();
    // console.log(props.code)
    const [codeCountry, setCodeCountry] = React.useState('+38');
    const [phone, setPhone] = React.useState(props.form.client_search_tel.phone ?? '');
    const setting_rusHide = props.settings_project?.['production.rus_hide'] || ''
    let setting_project_make_start_title = props.settings_project?.['production.project_make_start_name'] || 0;
    let title_project = !!Number(setting_project_make_start_title) ? 'name'?.slice(0, 50) : 'new order'

    const [createModal, setCreateModal] = useState(false)
    const handlerCloseCreateModal = () => {
        setCreateModal(false)
    }
    const handlerSuccessCreateProject = (title_project) => {
        if (props.hasOwnProperty("settings_projects") &&
            (props.settings_projects) && props.settings_projects["production.old_interface_on"] == "1" && props.form.client
        ) {
            props.createOrder(props.form.client, title_project);

        } else if (props.form.client && props.form.isAccessToCreate) {
            props.createNewgoOrder(props.form.client, title_project);
        }
        setPhone('')
        setCreateModal(false)
    }
    const handlerHelpArticle = (id)=>(e)=>{
        e.preventDefault();
        e.stopPropagation();
        dispatch(helpArticleGetByIdToggleModalThunk(id))
    }
    return (
        <div className="content-wrapper">
            <PageTitle title={"order_add"}/>
            <section className="content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">{props.getT("Основная информация")}</h3>
                        <div className="card-tools">
                            <button
                                type="button"
                                className="btn btn-tool"
                                data-card-widget="collapse"
                                title="Collapse"
                            >
                                <i className="fas fa-minus"> </i>
                            </button>
                        </div>
                    </div>
                    <form className={"" + (props.form.validated ? "was-validated" : "")}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>{props.getT("Клиент")}:</label>
                                        <h3>{props.form.client_name}</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-end">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-search"></i>
                        </span>
                                            </div>
                                            <input
                                                type={"text"}
                                                className="form-control"
                                                placeholder={props.getT("поиск по имени")}
                                                value={props.form.client_search}
                                                onChange={(event) => {
                                                    props.changeValue("client_search", event.target.value);
                                                    props.changeAccessToCreateOrder(false);
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fas fa-search"></i>
                        </span>
                                            </div>
                                            <>
                                                {props.settings_project && Number(props.settings_project['react.phone_code_one']) !== 9999 && props.settings_project['react.phone_code_one'] !== ''
                                                    ?
                                                    <input style={{maxWidth: '80px'}}
                                                           className={'form-control'} type="text"
                                                           value={`+${props.settings_project['react.phone_code_one']}`}
                                                           disabled={true}/>
                                                    :
                                                    <SelectPhone rusHide={!Number(setting_rusHide)} value={codeCountry}
                                                                 setValue={(code) => {
                                                                     setCodeCountry(code);
                                                                     props.changeValue(
                                                                         "client_search_tel",
                                                                         {
                                                                             code: code,
                                                                             phone: phone ?? ""
                                                                         }
                                                                     )
                                                                 }}
                                                    />
                                                }</>
                                            <InputMask
                                                mask={maskPhoneCountry[`${codeCountry} `]}
                                                type={'text'}
                                                className="form-control"
                                                placeholder={props.getT("поиск по телефону")}
                                                // value={props.form.client_search_tel.phone ?? ""}
                                                value={phone ?? ""}
                                                onChange={(event) => {
                                                    let trg = event.target.value;
                                                    let numbers = parseInt(trg.replace(/\D/g, '')) || 0;
                                                    setPhone(trg);
                                                    if (String(numbers).length >= 6) {
                                                        props.changeValue(
                                                            "client_search_tel",
                                                            {
                                                                code: props.settings_project && Number(props.settings_project['react.phone_code_one']) !== 9999 ? `+${Number(props.settings_project['react.phone_code_one'])}` : codeCountry,
                                                                phone: event.target.value
                                                            }
                                                            // event.target.value
                                                        )
                                                        props.changeAccessToCreateOrder(false);
                                                    }
                                                }}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className={"order_add_container"}>
                                        <table className={"table table-striped"}>
                                            <tbody>
                                            {props.form.clients &&
                                                props.form.clients.map((e) => {
                                                    return (
                                                        <tr>
                                                            <td
                                                                onClick={() => {
                                                                    props.setLeftovers(e.id);
                                                                    // console.log('choice client', e.id)
                                                                    props.changeValue("client", e.id)
                                                                }}
                                                            >
                                                                {e.name}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer d-flex justify-content-end flex-wrap" style={{gap: '10px'}}>
                            {props.form.client &&
                                <>
                                    <a href={`${apiService._baseIfurn}/dxf`} className={'btn border border-primary py-0'}><img src={skectchUpImg} alt="" width={100} height={50}/>
                                        <IconQuestion style={{top: '0px'}}
                                                      onClick={handlerHelpArticle(HELP_ARTICLE_CONSTANTS_IDS.import_from_sketch_up)}
                                        />
                                    </a>

                                    <button
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setCreateModal(true)
                                        }}
                                        type="submit"
                                        className="btn btn-primary float-right "
                                    >
                                        {props.getT('Создать заказ')}

                                    </button>
                                </>}
                        </div>
                        {props.form.leftovers.length > 0 &&
                            <div className="row">
                                <div className="col-12">
                                    <h3>{props.getT("Остатки клиента на складе")}</h3>
                                </div>
                                <div className="col-12">
                                    <table>
                                        <thead>
                                        <tr>
                                            <td>{props.getT("Код")}</td>
                                            <td>{props.getT("Название")}</td>
                                            <td>{props.getT("Размер (мм)")}</td>
                                            <td>{props.getT("Кол-во")}</td>
                                            <td>{props.getT("Дата")}</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {props.form.leftovers.map(l => {
                                            return (<tr>
                                                <td>{l.hasOwnProperty('code') && l.code}</td>
                                                <td>{l.hasOwnProperty('name') && l.name}</td>
                                                <td> {l.x} <strong>x </strong>
                                                    {l.y}
                                                </td>
                                                <td>{l.hasOwnProperty('count') && l.count}</td>
                                                <td>{l.hasOwnProperty('date_in') && l.date_in}</td>
                                            </tr>)
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {props.form.leftovers.length == 0 && props.form.isAccessToCreate &&
                            <h1 style={{textAlign: "center"}}>{props.getT("Остатков клиента на складе нет")}</h1>}
                    </form>
                </div>
            </section>
            {createModal &&
                <CreateProjectModal getT={props.getT} isOpen={createModal} handlerClose={handlerCloseCreateModal}
                                    handlerSuccess={handlerSuccessCreateProject}
                                    initial_value_title={props.form.title}
                />}
        </div>
    );
};

export default withLang(OrderAdd);


// {(props.hasOwnProperty("settings_projects")) && (props.settings_projects) && props.settings_projects["production.old_interface_on"] == "1" ? (
//     <div className="card-footer">
//         {props.form.client && (
//             <button
//                 style={{margin: " 0 10px"}}
//                 onClick={(event) => {
//                     event.preventDefault();
//                     props.createOrder(props.form.client, props.form.title);
//                 }}
//                 type="submit"
//                 className="btn btn-primary float-right"
//             >
//                 {props.getT("Добавить в старом интерфейсе")}
//             </button>
//         )}
//         {props.form.client && props.form.isAccessToCreate && (
//             <button
//                 onClick={(event) => {
//                     event.preventDefault();
//                     props.createNewgoOrder(props.form.client);
//                     setPhone('');
//
//                 }}
//                 type="submit"
//                 className="btn btn-primary float-right "
//             >
//                 {props.getT("Добавить в новом интерфейсе")}
//             </button>
//         )}
//     </div>
// ) : (<div className="card-footer">
//         {props.form.client && props.form.isAccessToCreate && (
//             <button
//                 onClick={(event) => {
//                     event.preventDefault();
//                     props.createNewgoOrder(props.form.client, props.form.title);
//                     setPhone('');
//                 }}
//                 type="submit"
//                 className="btn btn-primary float-right "
//             >
//                 {props.getT('Создать заказ')}
//             </button>
//         )}
//     </div>
// )}