import {createFormData} from "../../../utils/helpers";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {dispatcherErrorThunk} from "../common_thunk";
import {AdditionalDocumentAPI} from "../../../api/Salary/AdditionalDocuments";
import {
    setAdditionDocumentAdditionalDocumentBonusesFinesCreateModalAC,
    setAdditionDocumentAdditionalDocumentBonusFineUpdateModalAC,
    setAdditionDocumentReadyMarkByShiftOpenAC
} from "../../actions/SalaryProject";
import {getGeneralCurrenciesThunk} from "./general_thunk";


async function getAdditionDocumentByShiftDispatched(shift_id, dispatch) {
    const form_data = createFormData({shift: shift_id});
    const response = await AdditionalDocumentAPI.get(form_data);
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    dispatch(setAdditionDocumentReadyMarkByShiftOpenAC({documents: response?.data?.data}))
}

/**
 * Получить все документы и отметки готовности по смене.
 *
 * @param {number} shift_id -
 */
export const getAdditionDocumentByShift = (shift_id) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await getAdditionDocumentByShiftDispatched(shift_id, dispatch);
        dispatch(getGeneralCurrenciesThunk())
        const form_data = createFormData({shift: shift_id})
        const response_ready = await AdditionalDocumentAPI.getServiceReadyMarks(form_data);
        if (!!response_ready?.error) {
            dispatch(dispatcherErrorThunk(response_ready))
            return
        }
        dispatch(setAdditionDocumentReadyMarkByShiftOpenAC({ready_mark: response_ready?.data}))
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

/**
 * Создание документа.
 *
 * @param {object} api_data - object
 * @param {number} api_data.user - id пользователя в системе
 * @param {string} api_data.comment- комментарий
 * @param {number} api_data.shift - id смены
 * @param {date} api_data.date_for_apply_in_salary - дата начисления
 * @param {function||null} cb -
 */
export const createAdditionalDocument = (api_data, cb = null) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));

        let form_data = createFormData(api_data)
        const response_add = await AdditionalDocumentAPI.add(form_data)
        if (!!response_add?.error) {
            dispatch(dispatcherErrorThunk(response_add))
            return
        }
        await getAdditionDocumentByShiftDispatched(api_data.shift, dispatch);
        dispatch(toggleLoaderStatusAC(false));
        if (cb) cb()
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))

    }
}
/**
 * Обновление документа.
 *
 * @param {object} api_data - object
 * @param {number} api_data.user - id пользователя в системе
 * @param {string} api_data.comment- комментарий
 * @param {number} api_data.salary_additional_document_id - id смены
 * @param {date} api_data.date_for_apply_in_salary - дата начисления
 * @param {function||null} cb -
 */
export const updateAdditionalDocument = (api_data, cb = null) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        let {shift_id, ...reset} = api_data
        let form_data = createFormData(reset)
        const response_add = await AdditionalDocumentAPI.update(form_data)
        if (!!response_add?.error) {
            dispatch(dispatcherErrorThunk(response_add))
            return
        }
        await getAdditionDocumentByShiftDispatched(shift_id, dispatch);
        dispatch(toggleLoaderStatusAC(false));
        if (cb) cb()
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))

    }
}
/**
 * Добавить штраф/бонус в документ смены.
 *
 * @param {object} api_data - object
 * @param {number} shift_id -
 */
export const putAdditionalDocumentBonusesFinesThunk = (api_data, shift_id) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response_add = await AdditionalDocumentAPI.putAdditionalDocumentListChanges(api_data)
        if (!!response_add?.error) {
            dispatch(dispatcherErrorThunk(response_add))
            return
        }
        await getAdditionDocumentByShiftDispatched(shift_id, dispatch);
        dispatch(setAdditionDocumentAdditionalDocumentBonusesFinesCreateModalAC({
            isOpen: false,
            additional_document: null,
            type_action: null, shift_id: null
        }))
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))

    }
}
/**
 * Удалить бонус в документ смены.
 *
 * @param {object} api_data - object
 * @param {number} shift_id -
 */
export const deleteAdditionalDocumentBonusThunk = (api_data, shift_id) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        let form_data = createFormData(api_data)
        const response_add = await AdditionalDocumentAPI.deleteAdditionalDocumentBonus(form_data)
        if (!!response_add?.error) {
            dispatch(dispatcherErrorThunk(response_add))
            return
        }
        await getAdditionDocumentByShiftDispatched(shift_id, dispatch);
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))

    }
}
/**
 * Удалить штраф в документ смены.
 *
 * @param {object} api_data - object
 * @param {number} shift_id -
 */
export const deleteAdditionalDocumentFinesThunk = (api_data, shift_id) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        let form_data = createFormData(api_data)
        const response_add = await AdditionalDocumentAPI.deleteAdditionalDocumentFine(form_data)
        if (!!response_add?.error) {
            dispatch(dispatcherErrorThunk(response_add))
            return
        }
        await getAdditionDocumentByShiftDispatched(shift_id, dispatch);
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))

    }
}

/**
 * Обновить бонус в документ смены.
 *
 * @param {object} api_data - object
 * @param {number} shift_id -
 */
export const updateAdditionalDocumentBonusThunk = (api_data, shift_id) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        let form_data = createFormData(api_data)
        const response_add = await AdditionalDocumentAPI.updateAdditionalDocumentBonus(form_data)
        if (!!response_add?.error) {
            dispatch(dispatcherErrorThunk(response_add))
            return
        }
        await getAdditionDocumentByShiftDispatched(shift_id, dispatch);
        dispatch(setAdditionDocumentAdditionalDocumentBonusFineUpdateModalAC({
            isOpen: false,
            data: null,
            type_action: null, shift_id: null
        }))
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))

    }
}
/**
 * Обновить штраф в документ смены.
 *
 * @param {object} api_data - object
 * @param {number} shift_id -
 */
export const updateAdditionalDocumentFineThunk = (api_data, shift_id) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        let form_data = createFormData(api_data)
        const response_add = await AdditionalDocumentAPI.updateAdditionalDocumentFine(form_data)
        if (!!response_add?.error) {
            dispatch(dispatcherErrorThunk(response_add))
            return
        }
        await getAdditionDocumentByShiftDispatched(shift_id, dispatch);
        dispatch(setAdditionDocumentAdditionalDocumentBonusFineUpdateModalAC({
            isOpen: false,
            data: null,
            type_action: null, shift_id: null
        }))
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))

    }
}