import React from 'react';
import {connect} from "react-redux";
import ClientsDetail from "./ClientsDetail";
import './Clients.css';
import {
    changeClientDetailDataAC,
    getClientDataThunk,
    getClientOrdersDataThunk,
    toggleClientDetailEditAC,
    updateClientDataThunk,
    updateOrderDataThunk
} from "../../store/reducers/MainReducer";

class ClientsDetailContainer extends React.Component {

    constructor(props) {
        super(props);
        this.id = this.props.uri.match.params.id;
        this.editClient = this.editClient.bind(this);
    }

    componentDidMount() {
        this.props.getClient(this.id);
        this.props.getClientOrders(this.id);
    }

    componentWillUnmount() {
        // this.props.checkAuntificationUser()
    }

    editClient() {
        this.props.editClient(this.id, this.props.client)
    }

    render() {
        return <ClientsDetail client={this.props.client} updateOrder={this.props.updateOrder} orders={this.props.orders}
                              isEdit={this.props.isEdit} toggleEdit={this.props.toggleEdit}
                              changeValue={this.props.changeValue} editClient={this.editClient}/>;
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getClient: (id) => dispatch(getClientDataThunk(id)),
        getClientOrders: (id) => dispatch(getClientOrdersDataThunk(id)),
        toggleEdit: (status) => dispatch(toggleClientDetailEditAC(status)),
        changeValue: (input, value) => dispatch(changeClientDetailDataAC(input, value)),
        editClient: (client_id, data) => dispatch(updateClientDataThunk(client_id, data)),
        updateOrder: (order_id, data) => dispatch(updateOrderDataThunk(order_id, data, 'actual'))
    }
}

let mapStateToProps = (state) => {
    return {
        client: state.main.clientsDetail,
        orders: state.main.clientsDetailOrders,
        isEdit: state.main.clientsDetailEdit
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsDetailContainer);