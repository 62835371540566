import React, {useState} from 'react';
import {LS_LANG} from "../../../../utils/constants";

const WholeSaleFieldMap = ({item, getT, onChange, value}) => {
    let lang = localStorage.getItem(LS_LANG)
    let [isVisibleFullInfo, setVisibleFullInfo] = useState(false);
    let price_personal_proposal = item?.price_personal_proposal;
    let firmId = Number(item?.firm_id)
    let checked = Number(value) === firmId
    return (
        <>
            <div className="form-check ws-field-map">
                <input className="form-check-input" id={`ws_${firmId}`} checked={checked} type="radio"
                       name="order_main_firm" value={firmId}
                       onChange={onChange}/>
                <label className="form-check-label" htmlFor={`ws_${firmId}`}>
                    {item?.firm_name} {price_personal_proposal?.firm_price}
                    {price_personal_proposal?.firm_currency_name?.[lang]}
                </label>

                <i className={`action-full-info cursor-pointer fas fa-angle-${isVisibleFullInfo ? 'up' : 'down'}`}
                   onClick={() => setVisibleFullInfo(!isVisibleFullInfo)}></i>
            </div>
            {isVisibleFullInfo && <ul className="">
                <li>{getT("Код фирмы")}: <span>{item?.code_firm}</span></li>
                <li>{getT("Исходная цена товара")}: <span>{price_personal_proposal?.source_price}
                    {price_personal_proposal?.source_currency_name?.[lang]}
                </span></li>
                <li>{getT("Персональная цена фирмы на товар")}: <span>{price_personal_proposal?.firm_price}
                    {price_personal_proposal?.firm_currency_name?.[lang]}
                </span></li>
                <li>{getT("Рекомендованная цена для всех")}: <span>{item?.price_recommended_in_general_for_everybody} {item?.currency_name}</span>
                </li>
                <li>{getT("Количество дней, доставки от поставщика")}: <span>{item?.delivery_days_from_supplier} </span>
                </li>
                <li>{getT("Наличии товара на складе")}: <span>{item?.stock} </span></li>
                <li>{getT("Минимальное количество товара для заказа у поставщика в общем случае")}: <span>{item?.minimal_quantity_for_order_from_supplier_in_general} </span>
                </li>
                <li>{getT("Минимальное количество товара для индивидуального заказа у поставщика")}: <span>{item?.minimal_quantity_for_order_from_supplier} </span>
                </li>
            </ul>}
        </>
    );
};

export default WholeSaleFieldMap;