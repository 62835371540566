import React, {useState} from 'react';
import {useDetectClickOutside} from "react-detect-click-outside";
import DocumentBonuses from "./DocumentBonuses";
import DocumentFines from "./DocumentFines";

const ItemDocument = ({document, getT, handlerCreateBonus, handlerCreateFines, handlerUpdateAdditionalDocument, handlerDeleteFine, handlerDeleteBonus, handlerUpdateBonus, handlerUpdateFine}) => {
    const [isShow, setIsShow] = useState(false);
    // const ref = useDetectClickOutside({onTriggered: () => setIsShow(false)})
    return (
        // <ul className={"list-group-shift-plane"}>
            <li className={`list-item ${isShow && 'border-0 '}`} >
                <div className={'d-flex align-items-center justify-content-between'}>
                    <span className="title">№{document?.salary_additional_document_id}. {document?.comment}</span>
                    <span className="shift-action">
                        <i className={`cursor-pointer fas fa-angle-${isShow ? 'up' : 'down'}`}
                           onClick={() => setIsShow(!isShow)}></i>
                            <i className="fas fa-edit text-success cursor-pointer" onClick={handlerUpdateAdditionalDocument}></i>
                    </span>
                </div>
                {isShow &&
                    <ul className="list-group mt-2">
                        <li className="list-group-item">
                            <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                <span>{getT("Автор")}:</span> <span
                                className={'text-dark'}>{document?.user}</span>
                            </p>
                            <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                <span>{getT("Дата создания")}:</span> <span
                                className={'text-dark'}>{document?.date}</span>
                            </p>
                            <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                <span>{getT("Дата обновления")}:</span> <span
                                className={'text-dark'}>{document?.d_update}</span>
                            </p>
                            <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                <span>{getT("Дата применения бонусов/штрафов")}:</span> <span
                                className={'text-dark'}>{document?.date_for_apply_in_salary}</span>
                            </p>
                           <DocumentBonuses bonuses={document?.bonuses} getT={getT} handlerCreate={handlerCreateBonus} handlerDeleteBonus={handlerDeleteBonus} handlerUpdate={handlerUpdateBonus}/>
                            <DocumentFines handlerCreate={handlerCreateFines} getT={getT} fines={document?.fines} handlerDeleteFine={handlerDeleteFine} handlerUpdate={handlerUpdateFine}/>

                        </li>
                    </ul>
                }
            </li>
        // </ul>
    );
};

export default ItemDocument;