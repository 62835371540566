import React from 'react';
import {FiledInput} from "./Field";
import Select from "react-select";
import {gaEvents} from "../../gaEvents";

const ArchiveFilter = ({formData, getT, onHandlerChange, statusOptions, managerOption, clientsOption, onResetForm}) => {
    if (!formData || !getT || !onHandlerChange || !statusOptions || !managerOption || !clientsOption || !onResetForm) return false;
    let client = clientsOption?.map((e) => {
        return {
            label: e.name + " " + "(" + e.phone + ")",
            value: Number(e.id)
        };
    });
    const clientListOption = [{label: getT('Все'), value: null}, ...client]
    return (
        <div className="card">
            <div className="card-body">
                <form action="">
                    <div className="row">
                        <div className="col-sm-2">
                            <FiledInput gaEventAction={'form_order_archive'} name={'id'} type={'number'} value={formData.id} label={`${getT('ID заказа')}:`}
                                        onHandlerChange={(e) => onHandlerChange({
                                            key: 'id',
                                            value: Number(e.target.value) ? Number(e.target.value) : null
                                        })}/>
                        </div>
                        <div className="col-sm-2">
                            <FiledInput gaEventAction={'form_order_archive'} name={'name'} type={'text'} value={formData.name}
                                        label={`${getT('Название заказа')}:`}
                                        onHandlerChange={(e) => onHandlerChange({key: 'name', value: e.target.value})}/>
                        </div>
                        <div className="col-sm-2">
                            <FiledInput gaEventAction={'form_order_archive'} name={'code'} type={'text'} value={formData.code} label={`${getT('Код')}:`}
                                        onHandlerChange={(e) => onHandlerChange({
                                            key: 'code',
                                            value:e.target.value
                                        })}/>
                        </div>
                        <div className="col-sm-2">
                            <FiledInput gaEventAction={'form_order_archive'} name={'code1c'} type={'text'} value={formData.code1c} label={`${getT('Код')} 1с:`}
                                        onHandlerChange={(e) => onHandlerChange({
                                            key: 'code1c',
                                            value: Number(e.target.value) ? Number(e.target.value) : null
                                        })}/>
                        </div>

                        <div className="col-sm-2">
                            <div className="form-group">
                                <label htmlFor="">{getT('Статус')}: </label>
                                <select
                                    className="form-control"
                                    defaultValue={formData.status}
                                    onChange={(e) =>{
                                        gaEvents.customEvent('form_order_archive', {
                                            field_focus: 'Статус'
                                        })
                                        onHandlerChange({key: 'status', value: Number(e.target.value)})
                                    }}
                                >
                                    <option value={""}>{getT("Все")}</option>
                                    {statusOptions?.filter(item => Number(item.id) === 1 || Number(item.id) === 8)?.map(e => {
                                        return <option key={e.id} value={Number(e.id)}>
                                            {getT(e.name)}
                                        </option>
                                    })}
                                </select>

                            </div>
                        </div>
                        <div className="col-sm-2">
                            <div className="form-group">
                                <label htmlFor="">{getT('Менеджер')}: </label>
                                <select
                                    className="form-control"
                                    defaultValue={formData.user}
                                    onChange={(e) => {
                                        gaEvents.customEvent('form_order_archive', {
                                            field_focus: 'Менеджер'
                                        })
                                        onHandlerChange({key: 'user', value: e.target.value})
                                    }}
                                >
                                    <option value={""}>{getT("Все")}</option>
                                    {managerOption?.filter(item => Number(item.id) === 1 || Number(item.id) === 8)?.map(e => {
                                        return <option key={e.id} value={Number(e.id)}>
                                            {getT(e.name)}
                                        </option>
                                    })}
                                </select>

                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label>{getT("Клиент")}</label>
                                <Select
                                    options={
                                        clientsOption
                                            ? clientListOption
                                            : ""
                                    }

                                    setValue={formData.client}
                                    onChange={(selectedOption) => {
                                        gaEvents.customEvent('form_order_archive', {
                                            field_focus: 'Клиент'
                                        })
                                        onHandlerChange({key: 'client', value: selectedOption.value})
                                    }}
                                    placeholder={getT("Введите имя или номер телефона")}
                                />
                            </div>
                        </div>
                    </div>
                    {/*<div className=""><span className="btn btn-danger" onClick={onResetForm}>Очистить</span></div>*/}

                </form>
            </div>
        </div>
    );
};

export default ArchiveFilter;