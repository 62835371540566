import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getGeneralGoodsService, getScannerReadyMark} from "../../../store/selectors/salary_project_selector";
import Modal from "../MyModal/Modal";
import {scannerReadMarkToggleAllAC} from "../../../store/actions/SalaryProject";
import {scannerConfirmReadyMarkPutOrDeleteMarkThunk} from "../../../store/thunks/salary_project/ready_mark_thunk";

const SalaryScannerConfirmModal = ({getT}) => {
    const dispatch = useDispatch();
    const general_goods_service = useSelector(getGeneralGoodsService);
    const scanner_read_mark = useSelector(getScannerReadyMark);
    if (!scanner_read_mark?.confirm_modal?.isOpen) return null;
    const handlerCloseModal = () => {
        dispatch(scannerReadMarkToggleAllAC({
            confirm_modal: {
                isOpen: false, information: null, data_for_api: null
            },
            start_scanner: true

        }))
    }
    const handlerConfirmSend = () => {
        dispatch(scannerConfirmReadyMarkPutOrDeleteMarkThunk(scanner_read_mark.confirm_modal.data_for_api))
    }
    let type_add_or_delete_mark = !!Number(scanner_read_mark?.filter?.type_add_or_delete_mark)
    let type_package_or_service = !!Number(scanner_read_mark?.filter?.type_package_or_service)

    const service_includes = general_goods_service?.data?.filter(e => scanner_read_mark?.filter?.service_type.find(i => Number(i) === Number(e.goods_service_id)))

    return (
        <Modal title={getT("Подтвердить действие")} onClose={handlerCloseModal} open={scanner_read_mark.confirm_modal.isOpen}>
            <div className="text-center my-2 pb-2">
                <h5 className={"text-center"}>{getT("Вы уверены, что хотите")} {type_add_or_delete_mark ? <strong>{getT("установить")}</strong> : <strong>{getT("снять")}</strong>} {getT("отметку")} {"? "}  </h5>
                    <>{type_package_or_service ?
                        // <u className={"mt-2"}>

                        service_includes?.map(e => {
                            return <h5 className={" my-1 text-success"}>{e?.name}</h5>
                        }) :
                        <h5 className={" my-1 text-success"}> {getT("Упаковки")} </h5>
                    }</>

                <h5 className={"mb-0"}>{getT("Заказ")} № {scanner_read_mark?.confirm_modal?.information?.order}</h5>
                <h5>
                    {getT("Деталь")} {scanner_read_mark?.confirm_modal?.information?.part_info_from_link?.id}, {getT("уникальный код детали")} {scanner_read_mark?.confirm_modal?.information?.part_info_from_link?.unique}
                </h5>
            </div>
            <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-success"
                        onClick={handlerConfirmSend}>{getT("Подтвердить")}</button>
                <button type="button" className="btn btn-secondary"
                        onClick={handlerCloseModal}>{getT("Отмена")}</button>
            </div>
        </Modal>
    );
};

export default SalaryScannerConfirmModal;