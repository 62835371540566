import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getWSOrdersSupplySetModal} from "../../../../../store/selectors/wholesalary_orders_supply_selector";
import Modal from "../../../../../components/Modals/MyModal/Modal";
import {wsSetOrderSupplyModalAction} from "../../../../../store/actions/WholesaleOrdersSupplyActions";
import {getWSConnectionWholesaleFirms} from "../../../../../store/selectors/wholesalary_selector";
import OrderSupplyForm from "./OrderSupplyForm";
import {wsSetOrdersSupplyThunk} from "../../../../../store/thunks/wholesale/order_supply/orders_supply_thunk";

const OrderSupplySetModal = ({getT}) => {
    const dispatch = useDispatch();
    const connectionFirms = useSelector(getWSConnectionWholesaleFirms);
    const orderSupplyModal = useSelector(getWSOrdersSupplySetModal);
    if (!orderSupplyModal?.isOpen) return null
    const onClose = () => {
        dispatch(wsSetOrderSupplyModalAction({isOpen: null, orderCurrent: null}));
    }
    const setNewOrderSupply= (data)=>{
        dispatch(wsSetOrdersSupplyThunk( data))
    }
    let titleModal = orderSupplyModal?.orderCurrent ? "Редактировать заказ" : "Создать заказ"
    return (
        <Modal title={titleModal} onClose={onClose} open={orderSupplyModal?.isOpen}>
            <OrderSupplyForm getT={getT} wholesaleFirms={connectionFirms} initial_values={orderSupplyModal?.orderCurrent} onSend={setNewOrderSupply}/>
        </Modal>
    );
};

export default OrderSupplySetModal;