import ReactGA from "react-ga4";


const loginIn = () => ReactGA.event('login', {method: "standard"});
const signUp = () => ReactGA.event('sign_up', {method: "standard"});


const pageView = () => {
    let location = window.location.pathname + window.location.search;
    console.log('pageview', location)
    // ReactGA.send({hitType: "pageview", page:location});
}

const fileDownload = ({
                          file_name='',
                          file_extension='', link_url='', link_text=''
                      }) => {
    ReactGA.event("file_download", {
        file_name: file_name,
        file_extension: file_extension,
        link_url:link_url, link_text:link_text
    })
}

const customEvent = (action = "klik_form", params = {}) => {
    ReactGA.event(action, params)
    // ReactGA.event(action, {
    //     category: "your category",
    //     action: "your action",
    //     label: "your label", // optional
    //     value: 99, // optional, must be a number
    //     nonInteraction: true, // optional, true/false
    //     transport: "xhr", // optional, beacon/xhr/image
    // });
}
const gaEvents = {
    pageView: pageView,
    fileDownload: fileDownload,
    customEvent: customEvent,
    loginIn, signUp
}
export {gaEvents}


// events
// click_link: 'open order detail',
// label_gl: 'Этикетки GL'
//  cutting_card: 'Карты раскроя',
//  full_report_pdf_xnc: 'Сводный отчет ЧПУ',
//   full_report_pdf: 'Полный отчет',
//  btn_print_dosc: 'Печать пакета документов',
// btn_print_docs: 'Печать документов',
// gibsaw_ftp: 'Download Программы и .project',
// get_ifp_project: 'Download Скачать IFP project',
// material_list_html: 'Накладная на сырье',
//     renew_cards_crm: 'Обновить раскрой API',
//     ifp_xnc: 'Чертежи ЧПУ операций',
//     btn_order_blank: 'Сводный отчет ЧПУ',
//     order_blank_short_without_html: 'Бланк заказа производство',
//     order_blank_short_html: 'Бланк заказа',
//     btn_open_service: 'Открыть в редакторе',
// input_set_discount: 'discount material',
// order_change_status: 'change status'
// btn_set_problem: 'Сообщить о проблеме'
// field_focus: 'Название заказа'
// btn_programm_make: 'Генерация програм'
// production_task_btn: 'Сумма просроченные'
// order_change_manager: 'manager'
// production_task_tabs: 'Доступны для производства'
// gaEvents.customEvent('form_order_search', {
//     field_focus: 'ID заказа'
// })
// gaEvents.customEvent('search', {
//     search_term: value
// })