import React from "react";
import ProgrammMake from "./ProgrammMake";
import {connect} from "react-redux";
import './ProgrammMake.css';
import {toggleViewProgrammModalAC} from "../../../store/reducers/MainReducer";

class ProgrammMakeContainer extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return this.props.isOpen ? <ProgrammMake
                                               order={this.props.order}
                                               toggleViewProgramm={this.props.toggleViewProgramm}
        /> : '';
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        toggleViewProgramm: (id, order) => dispatch(toggleViewProgrammModalAC(id, order)),
    }
}

let mapStateToProps = (state) => {
    return {
        isOpen: state.main.viewProgramm.isOpen,
        order: state.main.viewProgramm.order,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgrammMakeContainer);