import React from "react";
import {connect} from "react-redux";
import ReportSale from "./ReportSale";
import {
    changeReportsSaleFilterValueThunk,
    getMainSettingsProject,
    getUsersDataThunk,
    setCurrencyDataThunk,
    setReportsDataSaleThunk,
    toggleReportsSaleActiveDataAC
} from "../../store/reducers/MainReducer";
import ReportClients from "./ReportsClients";
import ReportsStatus from "./ReportsStatus";
import ReportsTotals from "./ReportsTotals";
import LeftToMake from "./LeftToMake";

class ReportsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.type = this.props.uri.match.params.type;
        this.getCurrentDates = this.getCurrentDates.bind(this);
        this.getNextDate = this.getNextDate.bind(this);
        this.getCurrencyName = this.getCurrencyName.bind(this);
    }

    componentDidMount() {
        this.getCurrentDates();
        this.props.getReports(this.type, {
            date_from: this.currentDate,
            date_to: this.nextDate,
            currency: 2,
            manager: ""
        });
        this.props.setCurrency();

        this.props.setUsers();

        this.props.settingsProjects();
    }


    componentWillUnmount() {
        // this.props.checkAuntificationUser()
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.uri.match.params.type !== this.type) {
            this.getCurrentDates();
            // nextProps.changeValue(
            //   "date_from",
            //   this.currentDate,
            //   this.type,
            //   nextProps.form
            // );
            // nextProps.changeValue(
            //   "date_to",
            //   this.nextDate,
            //   this.type,
            //   nextProps.form
            // );

            this.type = this.props.uri.match.params.type;

            this.props.getReports(this.type, {
                date_from: this.currentDate,
                date_to: this.nextDate,
                currency: 2,
                manager: ""
            });
        }
    }

    getCurrencyName() {
        return this.props.currency && this.props.currency.length > 0
            ? this.props.currency.filter(
                (e) => Number(e.currency_id) === Number(this.props.form.currency)
            )[0]?.name?.[this.props.lang]
            : "";
    }

    formatSum(sum) {
        let format = new Intl.NumberFormat("ru-RU")
            .format(Number(sum).toFixed(2))
            .replace(",", ".");

        return format && sum > 0 ? format : "0";
    }

    getCurrentDates() {
        const d = new Date();
        const curr_date = d.getDate();
        const curr_month =
            String(d.getMonth() + 1).length === 1
                ? "0" + (d.getMonth() + 1)
                : d.getMonth() + 1;
        const curr_year = d.getFullYear();
        this.currentDate = curr_year + "-" + curr_month + "-" + curr_date;
        this.nextDate = curr_year + "-" + curr_month + "-" + (curr_date + 1 <= 9 ? `0${curr_date + 1}` : (curr_date + 1));
        this.manager = "";
    }

    getNextDate(data) {
        const d = new Date(data);
        const curr_date = d.getDate();
        const curr_month =
            String(d.getMonth() + 1).length === 1
                ? "0" + (d.getMonth() + 1)
                : d.getMonth() + 1;
        const curr_year = d.getFullYear();
        // this.setState({
        //     nextDate: curr_year + "-" + curr_month + "-" + (curr_date + 1 <= 9 ? `0${curr_date + 1}` : (curr_date + 1))
        // })
        this.nextDate = curr_year + "-" + curr_month + "-" + (curr_date + 1 <= 9 ? `0${curr_date + 1}` : (curr_date + 1));
        return curr_year + "-" + curr_month + "-" + (curr_date + 1 <= 9 ? `0${curr_date + 1}` : (curr_date + 1));
    }

    render() {
        switch (this.type) {
            case "sale":
                return (
                    <ReportSale
                        data={this.props.sale}
                        saleOpen={this.props.saleOpen}
                        toggleActive={this.props.toggleActive}
                        changeValue={this.props.changeValue}
                        form={this.props.form}
                        type={this.type}
                        currency={this.props.currency}
                        nextDate={this.nextDate}
                        currentDate={this.currentDate}
                        formatSum={this.formatSum}
                        paymountStatus={this.props.paymountStatus}
                        lang={this.props.lang}
                        getNextDate={this.getNextDate}
                    />
                );
            case "clients":
                return (
                    <ReportClients
                        data={this.props.sale}
                        saleOpen={this.props.saleOpen}
                        toggleActive={this.props.toggleActive}
                        changeValue={this.props.changeValue}
                        form={this.props.form}
                        type={this.type}
                        currency={this.props.currency}
                        nextDate={this.nextDate}
                        currentDate={this.currentDate}
                        getCurrencyName={this.getCurrencyName}
                        formatSum={this.formatSum}
                        users={this.props.users}
                        paymountStatus={this.props.paymountStatus}
                        lang={this.props.lang}
                        getNextDate={this.getNextDate}
                    />
                );
            case "status":
                return (
                    <ReportsStatus
                        data={this.props.sale}
                        saleOpen={this.props.saleOpen}
                        toggleActive={this.props.toggleActive}
                        changeValue={this.props.changeValue}
                        form={this.props.form}
                        type={this.type}
                        currency={this.props.currency}
                        nextDate={this.nextDate}
                        currentDate={this.currentDate}
                        getCurrencyName={this.getCurrencyName}
                        formatSum={this.formatSum}
                        lang={this.props.lang}
                        getNextDate={this.getNextDate}
                    />
                );
            case "totals":
                return (
                    <ReportsTotals
                        data={this.props.sale}
                        saleOpen={this.props.saleOpen}
                        toggleActive={this.props.toggleActive}
                        changeValue={this.props.changeValue}
                        form={this.props.form}
                        type={this.type}
                        currency={this.props.currency}
                        nextDate={this.nextDate}
                        currentDate={this.currentDate}
                        getCurrencyName={this.getCurrencyName}
                        formatSum={this.formatSum}
                        lang={this.props.lang}
                        getNextDate={this.getNextDate}
                    />
                );
            case "leftMake": {
                return (
                    <LeftToMake
                        data={this.props.sale}
                        saleOpen={this.props.saleOpen}
                        toggleActive={this.props.toggleActive}
                        changeValue={this.props.changeValue}
                        form={this.props.form}
                        type={this.type}
                        currency={this.props.currency}
                        nextDate={this.nextDate}
                        currentDate={this.currentDate}
                        getCurrencyName={this.getCurrencyName}
                        formatSum={this.formatSum}
                        lang={this.props.lang}
                        getNextDate={this.getNextDate}
                    />
                );
            }
            default:
                return <ReportSale data={this.props.sale}/>;
        }
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getReports: (type, dates) => dispatch(setReportsDataSaleThunk(type, dates)),
        toggleActive: (id) => dispatch(toggleReportsSaleActiveDataAC(id)),
        changeValue: (input, value, type, dates) =>
            dispatch(changeReportsSaleFilterValueThunk(input, value, type, dates)),
        setCurrency: () => dispatch(setCurrencyDataThunk()),
        setUsers: () => dispatch(getUsersDataThunk()),
        settingsProjects: () => dispatch(getMainSettingsProject()),
    };
};

let mapStateToProps = (state) => {
    return {
        sale: state.main.reports.sale,
        saleOpen: state.main.reports.saleOpen,
        form: state.main.reports.saleForm,
        currency: state.main.currency,
        paymountStatus: state.main.paymount_status,
        users: state.main.users,
        lang: state.translation.local
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsContainer);
