import React from 'react';
import {useSelector} from "react-redux";
import {getIsAccessModuleSalaryByUserRoleAndHasWorker} from "../../store/selectors/auth_selector";
import InsufficientAccessRights from "./component/InsufficientAccessRights";
import {LS_IS_DISPLAY_MODULE_SALORY, SALARY_PROJECT_ACCESS_ROLE} from "../../utils/constants";

const ProtectedSalary = ({children}) => {
    const is_has_protected_user = useSelector(getIsAccessModuleSalaryByUserRoleAndHasWorker(SALARY_PROJECT_ACCESS_ROLE));
    const is_display_module = JSON.parse(localStorage.getItem(LS_IS_DISPLAY_MODULE_SALORY));
    return (
        <>
            {(is_has_protected_user && !!Number(is_display_module)) ? children : <InsufficientAccessRights/>}
        </>
    );
};

export default ProtectedSalary;