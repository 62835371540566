import React from 'react';

/**
 * данные по товару.
 *
 * @param {object || null}  availabilityInfo-
 * @param {getT}  -
 * @returns  {jsx}  -
 */
const AvailabilityInformation = ({getT, availabilityInfo = null}) => {
    if (!availabilityInfo) {
        return <p>{getT("Данные по товару отсутствуют")}</p>
    }
    let delivery = availabilityInfo?.WS_DeliveryTime_ByFirm ?? {}
    let goodsStock = availabilityInfo?.WS_GoodsStockSourceRetail ?? {}
    let retail_price = goodsStock?.retail_price_ws_ByFirm?.data ?? {}
    let dropSheet = goodsStock?.retail_price_ws_ByFirm_DROP_sheet ?? {}
    let dropM = goodsStock?.retail_price_ws_ByFirm_DROP_m2 ?? {}
    let minimalQuantity = availabilityInfo?.WS_Minimal_Quantity ?? {}
    return (
        <div>
            <ul className={"list-group list-group-flush"}>
                {!!delivery?.data &&  <li className={"d-flex list-group-item align-items-center px-0"} style={{gap: "10px"}}>
                    <span>{getT("Количество дней доставки")}: </span>
                    <span className={"text-black font-weight-bold"}>{delivery?.data}</span>
                </li>}
                {!!retail_price?.price && <li className={"d-flex list-group-item align-items-center px-0"} style={{gap: "10px"}}>
                    <span className={" text-black"}>{getT("Розничная цена")}: </span>
                    <span className={"text-black font-weight-bold"}>{retail_price?.price} {" "} {retail_price?.currency_name}</span>
                </li>}
                <li className={"d-flex list-group-item align-items-center px-0"} style={{gap: "10px"}}>
                    <span>{getT("Продажа в листах")}: </span>
                    <span
                        className={!!Number(dropSheet?.dropM) ? "text-success" : "text-danger"}>{!!Number(dropSheet?.data) ? '✅' : '✖'} </span>
                </li>
                <li className={"d-flex list-group-item align-items-center px-0"} style={{gap: "10px"}}>
                    <span>{getT("Продажа  в m2 или штуках")}: </span>
                    <span
                        className={!!Number(dropM?.data) ? "text-success" : "text-danger"}> {!!Number(dropM?.data) ? '✅' : '✖'}
                    </span>
                </li>
                <li className={"d-flex list-group-item align-items-center px-0"} style={{gap: "10px"}}>
                    <span>{getT("Минимальное количество при продаже листами или штуками")}: </span>
                    <span className={"text-black font-weight-bold"}> {minimalQuantity?.data}</span>
                </li>
            </ul>
        </div>
    );
};

export default AvailabilityInformation;