import {instances, URI_API_EXTTUNELL} from "./setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";


const ADD_WORKER_GROUP = "add_worker_group"
export const WorkerGroupAPI = {
    add_update(api_data) {
        return instances.post(URI_API_EXTTUNELL, api_data, {
            headers: {
                action: ADD_WORKER_GROUP
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
}