import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import {ACTION_TYPE_ORDER_GROUP_TAB} from "./ProjectTask/constans";
import Tab from "./Tab/Tab";
import {withLang} from "../../hoc/withLang";
import FilterTask from "./ProjectTask/components/FilterTask";
import ProjectTask from "./ProjectTask/components/ProjectTask";
import ProjectOrder from "./ProjectOrder/ProjectOrder";
import ProjectOrderHeader from "./ProjectOrder/components/ProjectOrderHeader";
import ProductionProjectModal from "./ProjectOrder/components/Modal/ProductionProjectModal";
import {getProductionsStatusThunk} from "../../store/reducers/MainReducer";

const initialTab = {
    [ACTION_TYPE_ORDER_GROUP_TAB.task]: true,
    [ACTION_TYPE_ORDER_GROUP_TAB.material]: false,
}

const GroupPage = ({getT}) => {
    const dispatch = useDispatch();

    const [tab, setTab] = useState(ACTION_TYPE_ORDER_GROUP_TAB.task);
    const [modalProduction, setModalProduction] = useState(false)
    const productionModalHandler = (isOpen=false)=>{
        if(isOpen) {
            dispatch(getProductionsStatusThunk(false));
        }
        setModalProduction(isOpen)
    }
    return (
        <div className={'content-wrapper'}>
            <div className="content-header">
                <div className="col-12">
                    <h3 className="">{getT("Производственные задания")}</h3>
                </div>

            {tab === ACTION_TYPE_ORDER_GROUP_TAB.task ? <FilterTask getT={getT} key={'filter'}/>
                : tab === ACTION_TYPE_ORDER_GROUP_TAB.material ? <ProjectOrderHeader getT={getT} key={'materials-header'} productionModalHandler={productionModalHandler}/> :
                    ''
            }
            </div>
            <Tab getT={getT} key={'group-tab'} isActiveTabType={tab} onChangeTab={(type) => setTab((prev)=>{
                return type
            })}/>
            <div>
                {tab === ACTION_TYPE_ORDER_GROUP_TAB.task ? <ProjectTask getT={getT} key={'project-task'}/>
                    : tab === ACTION_TYPE_ORDER_GROUP_TAB.material ?
                        <ProjectOrder getT={getT} key={'project-order'}/>
                        :
                        ''
                }
            </div>

            {tab === ACTION_TYPE_ORDER_GROUP_TAB.material && modalProduction ? <ProductionProjectModal getT={getT} onClose={productionModalHandler} isOpen={modalProduction}/> : ''}

        </div>
    );
};

export default withLang(GroupPage);