import React from 'react';
import InputField from "./ui/InputField";
import {useSelector} from "react-redux";
import Loader from "../../Loader/Loader";

const FilterOrders = ({getT, form, changeFilterValue, children}) => {
    const fetchingOrders = useSelector(state => state.main.fetchingOrders)
    const onChangeHandler = (key) => (value) => {
        changeFilterValue(
            key,
            value,
            form
        )
    }
    return (
        <>
            {fetchingOrders.isPending && <Loader/>}
        <div className={`${fetchingOrders.isPending ? 'disabled form-field-none' : ' '}`} onSubmit={(e)=>{
            e.preventDefault();

        }}>
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label>{getT("Код 1С")}</label>
                            <InputField onChangeHandler={onChangeHandler('code1c')} initialValue={form.code1c} type={'text'}  disabled={fetchingOrders.isPending}className={`${fetchingOrders.isPending ? 'disabled form-field-none form-control' : 'form-control '}`}/>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label>{getT("ID заказа")}:</label>
                            <InputField onChangeHandler={onChangeHandler('id')} initialValue={form.id} type={'number'} disabled={fetchingOrders.isPending} className={`${fetchingOrders.isPending ? 'disabled form-field-none form-control' : ' form-control'}`}/>
                        </div>
                    </div>
                    <div className="col-sm-2">
                        <div className="form-group">
                            <label>{getT("Название заказа")}:</label>
                            <InputField onChangeHandler={onChangeHandler('name')} initialValue={form.name} type={'text'} disabled={fetchingOrders.isPending} className={`${fetchingOrders.isPending ? 'disabled form-field-none form-control' : ' form-control'}`}/>
                        </div>
                    </div>
                    {children}
                </div>


            </div>
        </div>
            </>
    );
};

export default FilterOrders;