import getT from "./getT";
import table_of_pack from "./packaging/table_of_pack";
import table_of_part from "./packaging/table_of_part";
import data_of_project from "./packaging/data_of_project";

export class PackagingHtml {
    constructor() {
    }

    generateContentHtml({order, setting, currency, manager_name, unit, production_vat, production_vat_display_short}) {
        if (!Array.isArray(order?.packing) || !order?.packing?.length) {
            return `<h3>${getT('Отсутствуют упаковки в проекте')}</h3>`
        }
        return `
<style>
        .panel-print{
        z-index: 9;
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
            text-align: right;
            margin: 20px 0px;
        }
        .panel-print button{
            background: none;
            border: none;
            outline: none;
        }
        .panel-print form{
        margin-bottom: 0px;
        }
        .mail-void {
    position: relative;
}
.mail-void:hover form{
    display: flex;
}

.mail-void form {
    position: absolute;
    background: white;
    box-shadow: 0 0 12px #f2f2f2;
    right: 0;
    padding: 10px;
    min-width: 420px;
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    border: 1px solid #f2f2f2;
}

.mail-void form div {
    display: grid;
    grid-template-columns: 100px 1fr;
    width: 100%;
    grid-gap: 15px;
    margin-bottom: 8px;
    align-items: center;
}

.mail-void form div label {
    font-size: 14px;
    color: #222;
    font-weight: 500;
    margin-bottom: 0px;;
}

.mail-void form button {
    background: #3586FF;
    width: 100%;
    padding: 8px 5px;
    color: white;
}

.mail-void form input {
    padding: 6px;
}
       @media print {
    /* здесь будут стили для печати */
     .panel-print {
       display: none !important;
    }
}
    </style>
       <div class="container">
        ${data_of_project({order, currency, manager_name, unit, production_vat:production_vat, production_vat_display_short:production_vat_display_short})}
       ${!!setting.packs ? table_of_pack({
            packing: order?.packing,
            isVisibleBins: setting.packs_bins,
            isVisiblePart: setting.packs_part,
            currency: currency,
            production_vat: production_vat,
            production_vat_display_short: production_vat_display_short
        }) : ''}
       ${!!setting?.part ? table_of_part({packing: order?.packing, isVisiblePosition: setting?.part_position}) : ''}
   </div>
    `
    }

}