import React from "react";
import PageTitle from "../PageTitle/PageTitle";
import {withLang} from "../../hoc/withLang";
import {gaEvents} from "../../gaEvents";

const ReportsStatus = (props) => {
    const getCurrencyName = () => {
        return props.currency && props.currency.length > 0
            ? props.currency.filter(
                (e) => Number(e.id) === Number(props.form.currency)
            )[0].name
            : "";
    };

    // const getStatusData = () => {
    //     if(props.data && props.data.client) {
    //         const status = ['very bad', 'bad', 'good', 'very good'];
    //         let data = {};
    //
    //         status.forEach(s => {
    //
    //             if(props.data.client && Array.isArray(props.data.client)) {
    //                 if(s === 'very bad'){
    //                     data["very_bad"] = props.data.client.filter(e => e.sale_status_id === s);
    //                 }
    //                 if(s === 'bad'){
    //                     data[s] = props.data.client.filter(e => e.sale_status_id === s);
    //                 }
    //                 if(s === 'good'){
    //                     data[s] = props.data.client.filter(e => e.sale_status_id === s);
    //                 }
    //                 if(s === 'very good'){
    //                     data["very_good"] = props.data.client.filter(e => e.sale_status_id === s);
    //                 }
    //             }
    //             // data[s] = props.data.client.filter(e => e.sale_status_id === s);
    //         });
    //         return data;
    //     } else {
    //         return {};
    //     }
    //
    // }
    // let allData = getStatusData();

    // console.log(allData);

    return (
        <>
            <div className="content-wrapper">
                <PageTitle title={"reports_status"}/>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <form>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label>{props.getT("Начиная с ")}:</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={props.form.date_from}
                                                    onChange={(event) => {
                                                        props.changeValue(
                                                            "date_from",
                                                            event.target.value,
                                                            props.type,
                                                            props.form
                                                        )
                                                        const nexDate = props.getNextDate(event.target.value)
                                                        props.changeValue(
                                                            "date_to",
                                                            nexDate,
                                                            props.type,
                                                            props.form
                                                        )
                                                    }
                                                    }
                                                    max={props.currentDate}
                                                    onFocus={()=>{
                                                        gaEvents.customEvent('form_order_reports_status', {
                                                            field_focus: 'Дата Начиная с'
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <label>{props.getT("До")}:</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                placeholder=""
                                                value={props.form.date_to}
                                                onChange={(event) =>
                                                    props.changeValue(
                                                        "date_to",
                                                        event.target.value,
                                                        props.type,
                                                        props.form
                                                    )
                                                }
                                                min={props.form.date_to}
                                                onFocus={()=>{
                                                    gaEvents.customEvent('form_order_reports_status', {
                                                        field_focus: 'Дата до'
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-sm-2">
                                            <label>{props.getT("Валюта")}:</label>
                                            <select
                                                className="custom-select"
                                                value={props.form.currency}
                                                onChange={(event) =>{
                                                    gaEvents.customEvent('form_order_reports_status', {
                                                        field_focus: 'Валюта'
                                                    })
                                                    props.changeValue(
                                                        "currency",
                                                        event.target.value,
                                                        props.type,
                                                        props.form
                                                    )
                                                }}
                                            >
                                                {props.currency &&
                                                    props.currency.map((e) => {
                                                        return <option value={e.id}>{e.name}</option>;
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <h4>{props.getT("Итоги текущего периода")}</h4>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                <th>{props.getT("Сума товаров")}</th>
                                <th>{props.getT("Сума услуг")}</th>
                                <th>{props.getT("Общая сума")}</th>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        {props.data.client
                                            ? props.formatSum(props.data.client.all_goods_summ)
                                            : "0"}{" "}
                                        {props.getCurrencyName()}
                                    </td>
                                    <td>
                                        {props.data.client
                                            ? props.formatSum(props.data.client.all_service_summ)
                                            : "0"}{" "}
                                        {props.getCurrencyName()}
                                    </td>
                                    <td>
                                        {props.data.client
                                            ? props.formatSum(props.data.client.all_summ)
                                            : "0"}{" "}
                                        {props.getCurrencyName()}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            {props.data.client && props.data.client.old ? (
                                <>
                                    <div className="card-header">
                                        <h4>{props.getT("Итоги предведущого периода")}</h4>
                                    </div>
                                    <table className="table table-striped">
                                        <thead>
                                        <th>{props.getT("Сума товаров")}</th>
                                        <th>{props.getT("Сума услуг")}</th>
                                        <th>{props.getT("Общая сума")}</th>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                {props.data.client.old
                                                    ? props.formatSum(
                                                        props.data.client.old.all_goods_summ
                                                    )
                                                    : "0"}{" "}
                                                {props.getCurrencyName()}
                                            </td>
                                            <td>
                                                {props.data.client.old
                                                    ? props.formatSum(
                                                        props.data.client.old.all_service_summ
                                                    )
                                                    : "0"}{" "}
                                                {props.getCurrencyName()}
                                            </td>
                                            <td>
                                                {props.data.client.old
                                                    ? props.formatSum(props.data.client.old.all_summ)
                                                    : "0"}{" "}
                                                {props.getCurrencyName()}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </>
                            ) : (
                                ""
                            )}
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <h4>{props.getT("Клиенты")}</h4>
                            </div>
                            <table className="table table-striped">
                                <thead>
                                <th>{props.getT("Имя")}</th>
                                <th>{props.getT("Статус")}</th>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>

                        {/*<div className="card">*/}
                        {/*    <div className="card-header">*/}
                        {/*        <h4>Very Bad</h4>*/}
                        {/*    </div>*/}
                        {/*    <table className="table table-striped">*/}
                        {/*        <thead>*/}
                        {/*        <th>ID</th>*/}
                        {/*        <th>Имя</th>*/}
                        {/*        <th>Сумма</th>*/}
                        {/*        <th> </th>*/}
                        {/*        </thead>*/}
                        {/*        <tbody>*/}
                        {/*        { allData.very_bad && allData.very_bad.map(e => {*/}
                        {/*            return <tr>*/}
                        {/*                <td>{e.old.id ? e.old.id : ''}</td>*/}
                        {/*                <td>{e.old.name}</td>*/}
                        {/*                <td>{props.formatSum(e.goods_client_summ)} {props.getCurrencyName()}</td>*/}
                        {/*            </tr>*/}
                        {/*        }) }*/}
                        {/*        </tbody>*/}
                        {/*    </table>*/}
                        {/*</div>*/}

                        {/*<div className="card">*/}
                        {/*    <div className="card-header">*/}
                        {/*        <h4>Bad</h4>*/}
                        {/*    </div>*/}
                        {/*    <table className="table table-striped">*/}
                        {/*        <thead>*/}
                        {/*        <th>ID</th>*/}
                        {/*        <th>Имя</th>*/}
                        {/*        <th>Сумма</th>*/}
                        {/*        <th> </th>*/}
                        {/*        </thead>*/}
                        {/*        <tbody>*/}
                        {/*        { allData.bad && allData.bad.map(e => {*/}
                        {/*            return <tr>*/}
                        {/*                <td>{e.old.id}</td>*/}
                        {/*                <td>{e.old.name}</td>*/}
                        {/*                <td>{props.formatSum(e.old.goods_client_summ)} {props.getCurrencyName()}</td>*/}
                        {/*            </tr>*/}
                        {/*        }) }*/}
                        {/*        </tbody>*/}
                        {/*    </table>*/}
                        {/*</div>*/}

                        {/*<div className="card">*/}
                        {/*    <div className="card-header">*/}
                        {/*        <h4>Good</h4>*/}
                        {/*    </div>*/}
                        {/*    <table className="table table-striped">*/}
                        {/*        <thead>*/}
                        {/*        <th>ID</th>*/}
                        {/*        <th>Имя</th>*/}
                        {/*        <th>Сумма</th>*/}
                        {/*        <th> </th>*/}
                        {/*        </thead>*/}
                        {/*        <tbody>*/}
                        {/*        { allData.good && allData.good.map(e => {*/}
                        {/*            return <tr>*/}
                        {/*                <td>{e.old.id}</td>*/}
                        {/*                <td>{e.old.name}</td>*/}
                        {/*                <td>{props.formatSum(e.old.goods_client_summ)} {props.getCurrencyName()}</td>*/}
                        {/*            </tr>*/}
                        {/*        }) }*/}
                        {/*        </tbody>*/}
                        {/*    </table>*/}
                        {/*</div>*/}

                        {/*<div className="card">*/}
                        {/*    <div className="card-header">*/}
                        {/*        <h4>Very Good</h4>*/}
                        {/*    </div>*/}
                        {/*    <table className="table table-striped">*/}
                        {/*        <thead>*/}
                        {/*        <th>ID</th>*/}
                        {/*        <th>Имя</th>*/}
                        {/*        <th>Сумма</th>*/}
                        {/*        <th> </th>*/}
                        {/*        </thead>*/}
                        {/*        <tbody>*/}
                        {/*        { allData.very_good && allData.very_good.map(e => {*/}
                        {/*            return <tr>*/}
                        {/*                <td>{e.old && e.old.id ? Number(e.old.id) < 0 : ' '}</td>*/}
                        {/*                <td>{e.old && e.old.name}</td>*/}
                        {/*                <td>{props.formatSum(e.goods_client_summ)} {props.getCurrencyName()}</td>*/}
                        {/*            </tr>*/}
                        {/*        }) }*/}
                        {/*        </tbody>*/}
                        {/*    </table>*/}
                        {/*</div>*/}
                    </div>
                </section>
            </div>
        </>
    );
};

export default withLang(ReportsStatus);
