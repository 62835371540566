import React from 'react';
import OrderGoods from "./OrderGoods";
import OrderService from "./OrderService";
import {groupedOrderServiceReadMark} from "../../../utils/helpers";
import Button from "../../../components/ui/Button";
import {useDispatch} from "react-redux";
import {getPartsInformationForProductionMarksByOrder} from "../../../store/thunks/salary_project/ready_mark_thunk";
/**
 * Карточка заказ.
 *
 * @param {object} order  -заказ
 * @param {function} getT - перевод
 * @param {object} shift_form_values - смена и id бригады
 * @param {object} shift_form_values.shift - смена
 * @param {object} shift_form_values.worker_group -id бригады
 * @param {object} filter - активный фильтр
 * @param {function} handlerGetPartOfOrderProductionMark - получить данные по детали и открыть модальное окно
 * @returns  {jsx}  -
 */
const OrderItem = ({order, getT, shift_form_values, filter}) => {
    // const dispatch = useDispatch();
    // // Работа с одним заказом или заданием на производстве получит информацию по деталям
    // const handlerGetPartForOrderProduction = (event)=>{
    //     dispatch(getPartsInformationForProductionMarksByOrder(order.id, ))
    // }
    return (
        <div key={`order_item_${order?.id}`}>
            {!!(order?.goods?.length) && <OrderGoods getT={getT} order_goods={order?.goods}/>}
            {!!(order?.service?.length) && <OrderService  getT={getT}
                                                     order_service={groupedOrderServiceReadMark(order?.service)}
                                                     shift_form_values={shift_form_values} order_id={order?.id}/>}
            {/*{!!order?.id && <Button onClick={handlerGetPartForOrderProduction}>{getT("Отметка по детально")}</Button>}*/}
        </div>
    );
};

export default OrderItem;