import React, {useState} from 'react';
import Modal from "../MyModal/Modal";
import {withLang} from "../../../hoc/withLang";
import {useDispatch} from "react-redux";
import Button from "../../ui/Button";

const CreateProjectModal = ({getT, isOpen, handlerSuccess, handlerClose, initial_value_title}) => {
    let dispatch = useDispatch();
   const [title_input, setInputTitle] = useState(initial_value_title)
    return (
        <Modal onClose={handlerClose} open={isOpen} title={getT("Создать проект")} size_modal_class={"modal-md"}>

            <div className="form-group">
                <lable>{getT("Название проекта")}</lable>
                <div className="input-group mb-3">

                    <input
                        type={"text"}
                        className="form-control"
                        placeholder=""
                        value={title_input}
                        onChange={(event) =>
                            setInputTitle(
                                event.target.value
                            )
                        }
                    />
                </div>
            </div>
            <div className="modal-footer">
                <div className="d-flex justify-content-center" style={{gap: '12px'}}>
                    <Button type={'button'} onClick={()=>handlerSuccess(title_input)}>{getT("Создать")}</Button>
                    <Button className={"btn-danger"} type={'button'} onClick={handlerClose}>{getT("Отменить")}</Button>
                </div>
            </div>
        </Modal>
    );
};

export default CreateProjectModal;