import {instances, URI_API_EXTTUNELL} from "./setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const ORDER_LIST_GET = 'order_list_get';
const ORDER_LIST_FOR_SALARY_BY_WORKING_GROUP = 'order_list_for_salory_by_working_group';

/**
 * Базовые справочники
 * @method getList  - список заказов или задание на производство
 */
export const OrderAPI = {
    getList(params) {
        return instances.post(URI_API_EXTTUNELL, params, {
            headers: {
                action: ORDER_LIST_FOR_SALARY_BY_WORKING_GROUP
            },
            // params: params
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },


}