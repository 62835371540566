import React from 'react';
import {statusOrdersHelpers} from "../../../../utils/statusOrders";

const ExistItemRow = ({getT, item, onDeleteWSByGoodsId, openUpdateModalForWSGoodsId}) => {
    let badgeStatus = statusOrdersHelpers.getClassNameByStatusId(item?.status?.id);
    let orderSupply = !(Array.isArray(item?.order_supply?.status) && Array(item?.order_supply?.status_info)) ? item?.order_supply : null;
    let badgeOrderSupplyStatus = statusOrdersHelpers.getClassNameByStatusId(orderSupply?.status_info?.id_status)
    return (
        <tr>
            <td data-label={'# ' + getT("Заказа")}>
                <span className="text-black" title={'# ' + getT("Заказа")}>{item?.order}</span> | <span
                title={getT("Связь заказа с товаром")} className={"text-secondary"}>  {item?.order_goods} </span>
            </td>
            {/*<td data-label={getT("Связь заказа с товаром")}>{item?.order_goods}</td>*/}
            <td data-label={getT("Статус")}><span className={badgeStatus}>{item?.status?.name}</span></td>
            <td data-label={getT("Товар")}>
                {!!Number(item?.goods.part) && <small className={"text-cyan"}>{getT("Плитный материал")} </small>}
                {!!Number(item?.goods.band) && <small className={"text-primary"}>{getT("Кромка")} </small>}
                {!!Number(item?.goods.tovar) && <small className={"text-fuchsia"}>{getT("Товар")} </small>}
                <div className=""> [{item.goods?.goods_id}] {item.goods?.name} </div>
            </td>
            <td data-label={getT("Заказ поставщику")}>
                {!!orderSupply && <>
                    {orderSupply?.status?.name} | {orderSupply?.firm_main?.name_firm}
                </>}

                {/*<span className={badgeOrderSupplyStatus}>{orderSupply?.status_info?.name_status}</span>*/}
            </td>


            <td data-label={getT("Количество")}>
                {item?.count} {item?.units?.name}
            </td>

            <td data-label={getT("Действие")}>
                <i className="fas fa-edit text-primary cursor-pointer mr-2" aria-hidden="true"
                   onClick={() => openUpdateModalForWSGoodsId(item)}></i>
                <i className="fa fa-trash text-danger cursor-pointer " aria-hidden="true"
                   onClick={onDeleteWSByGoodsId}></i>
            </td>
        </tr>
    );
};

export default ExistItemRow;