import React, {useReducer} from 'react';
import Modal from "../../../../Modals/MyModal/Modal";
import ChoiceOrders from "./ChoiceOrders";
import {useDispatch, useSelector} from "react-redux";
import {productionTaskMaterial, productionTaskType} from "../../../ProjectTask/selectors";
import {isShallowEqual} from "../../../../../utils/helpers";
import ActionSelect from "./ActionSelect";
import {
    createProductionTaskThunk,
    fetchingProjectProductionByIdMaterials, toggleLoaderStatusAC
} from "../../../../../store/reducers/MainReducer";
import AmountService from "./AmountService";
function flatDeep(arr, d = 1) {
    return d > 0
        ? arr.reduce(
            (acc, val) =>
                acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val),
            []
        )
        : arr.slice();
}


const T_ACTIONS = 'ACTIONS';
const T_TASK = 'TASK';
const T_NAME = 'NAME';
const initialStateModal = {
    action: '',
    name: '',
    task: '',
    manager: localStorage.getItem("i_furn_user_id")
}
const actionTC = {
    setAction: (payload)=>{
        return {
            type: T_ACTIONS,
            payload: payload
        }
    }, setTask: (payload)=>{
        return {
            type: T_TASK,
            payload: payload
        }
    },setName: (payload)=>{
        return {
            type: T_NAME,
            payload: payload
        }
    },

}

function reducer (state, action){
    switch (action.type) {
        case T_ACTIONS:{
            return {
                ...state, action: action.payload
            }
        }
        case T_TASK:{
            return {
                ...state, task: action.payload
            }
        }
        case T_NAME:{
            return {
                ...state, name: action.payload
            }
        }
        default:{
            return state
        }
    }
}
const actionTypes = [
    { action: "create", value: "Создать новое производственное задание" },
    { action: "add-orders", value: "Добавить к выбранному" }
];
const ProductionProjectModal = ({isOpen, onClose, getT}) => {

    const rDispatch = useDispatch();
    const [state, dispatch] = useReducer(reducer, initialStateModal);
    const productionTasksMaterial = useSelector(productionTaskMaterial)
    const productionTypes = useSelector(productionTaskType, (prev, next)=>isShallowEqual(prev, next))


    const choiceOrders = productionTasksMaterial?.reduce(
        (results, item) => [
            ...results,
            ...item?.orders?.map((order) => ({
                id: order.id,
                isChecked: order.isChecked,
                name: order.name,
                calc: order.calc
            }))
        ],
        []
    );
    const checkedOrders = choiceOrders?.filter(order=> {
        return order?.isChecked
    })
    const createTask = ()=>{
        rDispatch(createProductionTaskThunk({...state, manager: localStorage.getItem('i_furn_user_id'), orders: checkedOrders?.map(order=> order?.id)}, ()=> {
setTimeout(function (){ let select = document.querySelector('#material_production');
    if(select?.value) {
        rDispatch(fetchingProjectProductionByIdMaterials({id: select?.value}));
    }
    onClose();}, 10);

        }));
    }

    // Amount Service
    const actualOrders = checkedOrders?.map(order=>{
        if(Array.isArray(order?.calc?.material)) {
            return {
                ...order,
                calc: {
                    ...order.calc,
                    material: order?.calc?.material?.filter(item => item.type === "serv" && Number(item.ready_serv) !== 1)
                }
            }
        }
        return  order;
    });
    const sortedDataCheck = actualOrders.map((elem) => {
        return elem.calc.material;
    });


    const convertInArrayCheck = flatDeep(sortedDataCheck, Infinity);
    return (
        <Modal onClose={() => onClose(false)} open={isOpen}>
            {Array.isArray(checkedOrders) && checkedOrders?.length ?
                 <table>
                <thead>
                <tr>
                    <th>{getT("ID")}</th>
                    <th></th>
                    <th></th>
                    <th>{getT("Название")}</th>
                </tr>
                </thead>
                <tbody>

                <ChoiceOrders orders={checkedOrders}/>
                {Array.isArray(checkedOrders) && checkedOrders?.length &&
                    <>
                <ActionSelect
                    label={<label>{getT("Действие")}:</label>}
                    onChange={(e) =>{
                     dispatch(actionTC.setAction(e.target.value))
                    }
                    }
                    childrenOption={
                        actionTypes.map(el => <option value={el.action}>{getT(el?.value)}</option>)
                    }
                />
                        {state.action === "add-orders" &&
                            <ActionSelect
                                label={<label>{getT("Доступные производственные задания")}:</label>}
                                onChange={(event) => dispatch(actionTC.setTask(event?.target?.value))}
                                optionDefaultTitle={getT('Выберите доступное производственные задания')}
                                childrenOption={
                                    productionTypes.map( (el) =>
                                        el.status === "1" && (
                                            <option key={el.id} value={el.id}>
                                                {el.name}
                                            </option>
                                        ))
                                }
                            />

                        }
                        {state.action === "create" && (
                            <td colSpan="2">
                                <div className="form-group">
                                    <label>{getT("Имя производственного задания")}:</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name="prodName"
                                        value={state.name}
                                        onChange={(e) =>{
                                           dispatch(actionTC.setName(e.target.value))
                                        }}
                                    />
                                </div>
                            </td>
                        )}
                        <tr>
                            <td colSpan="4">
                                <p className="">{state.action === "add-orders" }</p>
                                <button
                                    className={`btn btn-primary ${!state.action || (state.action === "create" && state.name === "") || (state.action === "add-orders" && state.task === "") ? 'disabled' : ''}`}
                                    // disabled={(state.action === "create" && state.name === "") || (state.action === "add-orders" && state.task === "")}
                                    onClick={createTask}
                                >
                                    {getT("Создать")}
                                </button>
                            </td>
                        </tr>
                </>
                }
                </tbody>
                <tfoot>
                <tr>
                    <td colSpan="4">
                        <h4>{getT("Сумма по услугам")}:</h4>
                    </td>
                </tr>

                <AmountService getT={getT} amountList={[
                    {
                        title: getT('Порезка'),
                        result: convertInArrayCheck.filter((el) => {
                            return el?.type_serv_name_ru?.includes("Порезка")
                        })?.reduce((n, { count }) => n + count, 0)?.toFixed(2),
                        unit: getT("м.пог.")
                    },
                    {
                        title: getT('Кромкование прямолинейное'),
                        result: convertInArrayCheck.filter((el) => {
                            return el?.type_serv_name_ru?.includes("Кромкование прямолинейное")
                        })?.reduce((n, { count }) => n + count, 0)?.toFixed(2),
                        unit: getT("м.пог.")
                    },
                    {
                        title: getT('Сверление'),
                        result: convertInArrayCheck.filter((el) => {
                            return el?.type_serv_name_ru?.includes("Сверление")
                        })?.reduce((n, { count }) => n + count, 0)?.toFixed(2),
                        unit: getT("шт.")
                    },
                    {
                        title: getT('Сшивка'),
                        result: convertInArrayCheck.filter((el) => {
                            return el?.type_serv_name_ru?.includes("Сшивка")
                        })?.reduce((n, { count }) => n + count, 0)?.toFixed(2),
                        unit: getT("шт.")
                    },

                ]}/>
                <AmountService getT={getT} amountList={[
                    {
                        title: getT('Фрезерование'),
                        result: convertInArrayCheck.filter((el) => {
                            return el?.type_serv_name_ru?.includes("Фрезерование")
                        })?.reduce((n, { count }) => n + count, 0)?.toFixed(2),
                        unit: getT("м2")
                    },
                    {
                        title: getT('Кромкование криволинейное'),
                        result: convertInArrayCheck.filter((el) => {
                            return el?.type_serv_name_ru?.includes("Кромкование криволинейное")
                        })?.reduce((n, { count }) => n + count, 0)?.toFixed(2),
                        unit: getT("м.пог.")
                    },
                    {
                        title: getT('Пазование'),
                        result: convertInArrayCheck.filter((el) => {
                            return el?.type_serv_name_ru?.includes("Пазование")
                        })?.reduce((n, { count }) => n + count, 0)?.toFixed(2),
                        unit: getT("шт.")
                    },
                    {
                        title: getT('Урезка'),
                        result: convertInArrayCheck.filter((el) => {
                            return el?.type_serv_name_ru?.includes("Урезка")
                        })?.reduce((n, { count }) => n + count, 0)?.toFixed(2),
                        unit: getT("шт.")
                    },

                ]}/>
                </tfoot>
            </table>
                :
                <div style={{padding:'40px 0px'}}>
                    <h3>{getT('список пустой')}!</h3>

                </div>
            }
        </Modal>
    );
};


    export default ProductionProjectModal;