import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getAdditionalDocumentBonusFineUpdateModal,
    getGeneralCurrencies
} from "../../../store/selectors/salary_project_selector";
import {SALARY_PROJECT_BONUSES_FINES_ACTION} from "../../../utils/constants";
import {
    putAdditionalDocumentBonusesFinesThunk, updateAdditionalDocumentBonusThunk,
    updateAdditionalDocumentFineThunk
} from "../../../store/thunks/salary_project/addition_document_thunk";
import {
    setAdditionDocumentAdditionalDocumentBonusesFinesCreateModalAC,
    setAdditionDocumentAdditionalDocumentBonusFineUpdateModalAC
} from "../../../store/actions/SalaryProject";
import BonusesFinesCreateForm from "./components/BonusesFines/BonusesFinesCreateForm";
import Modal from "../MyModal/Modal";
import BonusFineUpdateForm from "./components/BonusesFines/BonusFineUpdateForm";

const SalaryUpdateBonusesFinesToWorkerByDocumentModal = ({getT, read_mark}) => {
    const dispatch = useDispatch();
    const currencies = useSelector(getGeneralCurrencies)
    const bonuses_fines_update = useSelector(getAdditionalDocumentBonusFineUpdateModal);
    if (!bonuses_fines_update?.isOpen) return null;
    const handlerSubmitForm = (values) => {
        let is_type_bonus = bonuses_fines_update.type_action === SALARY_PROJECT_BONUSES_FINES_ACTION.bonuses
        let shift_id = bonuses_fines_update?.shift_id;
        if (is_type_bonus) {
            dispatch(updateAdditionalDocumentBonusThunk({
                ...values,
                salary_additional_bonus_id:  bonuses_fines_update?.data?.salary_additional_bonus_id
            }, shift_id))

        } else {
            dispatch(updateAdditionalDocumentFineThunk({
                ...values,
                salary_additional_fine_id: bonuses_fines_update?.data?.salary_additional_fine_id
            }, shift_id))
        }


    }
    const handlerCloseModal = () => {
        dispatch(setAdditionDocumentAdditionalDocumentBonusFineUpdateModalAC({
            isOpen: false,
            data: null,
            type_action: null, shift_id: null
        }))
    }
    let title = bonuses_fines_update?.type_action === SALARY_PROJECT_BONUSES_FINES_ACTION.bonuses ? getT("Обновить бонус") : getT("Обновить штраф")

    return (
        <Modal open={bonuses_fines_update.isOpen} onClose={handlerCloseModal} title={title}>
            <BonusFineUpdateForm getT={getT} currency={currencies?.data} read_mark={read_mark}
                                    handlerSubmitForm={handlerSubmitForm} initial_form={bonuses_fines_update.data}/>
        </Modal>
    );
};

export default SalaryUpdateBonusesFinesToWorkerByDocumentModal;