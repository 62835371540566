import React from "react";

import PageTitle from "../PageTitle/PageTitle";
import {withLang} from "../../hoc/withLang";

const ReportClients = (props) => {

    return (
        <div className="content-wrapper">
            <PageTitle title={"reports_clients"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <form>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label>{props.getT("Начиная с")}:</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                placeholder=""
                                                value={props.form.date_from}
                                                onChange={(event) => {
                                                    props.changeValue(
                                                        "date_from",
                                                        event.target.value,
                                                        props.type,
                                                        props.form
                                                    )
                                                    const nexDate = props.getNextDate(event.target.value)
                                                    props.changeValue(
                                                        "date_to",
                                                        nexDate,
                                                        props.type,
                                                        props.form
                                                    )
                                                }}
                                                max={props.currentDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <label>{props.getT("До")}:</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            placeholder=""
                                            value={props.form.date_to}
                                            onChange={(event) =>
                                                props.changeValue(
                                                    "date_to",
                                                    event.target.value,
                                                    props.type,
                                                    props.form
                                                )
                                            }
                                            min={props.getNextDate(props.form.date_from)}
                                        />
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label>{props.getT("Менеджер")}:</label>
                                            <select
                                                onChange={(event) =>
                                                    props.changeValue(
                                                        "manager",
                                                        event.target.value,
                                                        props.type,
                                                        props.form
                                                    )
                                                }
                                                className="form-control"
                                                value={props.form.manager}
                                            >
                                                <option value=""></option>
                                                ;
                                                {props.users.map((user) => {
                                                    return (
                                                        <option key={user.id} value={user.id}>
                                                            {user.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <label>{props.getT("Валюта")}:</label>
                                        <select
                                            className="custom-select"
                                            value={props.form.currency}
                                            onChange={(event) =>
                                                props.changeValue(
                                                    "currency",
                                                    event.target.value,
                                                    props.type,
                                                    props.form
                                                )
                                            }
                                        >
                                            {props.currency &&
                                                props.currency.map((e) => {
                                                    return <option value={e.currency_id}>{e.name[props.lang]}</option>;
                                                })}
                                        </select>
                                    </div>
                                    {props.paymountStatus && Object.entries(props.paymountStatus).length > 0 &&
                                        <div className="col-sm-2">
                                            <label>{props.getT("Статус оплаты")}:</label>
                                            <select style={{width: '100%'}}
                                                    className={'custom-select'}
                                                    name="" id=""
                                                // value={props.form.paymount}
                                                    onChange={(event) => {
                                                        const data = JSON.parse(event.target.value)
                                                        props.changeValue(
                                                            "paymount",
                                                            data.code,
                                                            props.type,
                                                            props.form
                                                        )
                                                    }}>
                                                <option value="" selected={true}
                                                        disabled={true}>{props.getT('Выберите')}</option>
                                                {props.paymountStatus && Object.entries(props.paymountStatus).map((p, key) => {
                                                    return <option key={key}
                                                                   selected={p[0] === props.form.paymount}
                                                                   value={JSON.stringify({
                                                                       text: p[1],
                                                                       code: p[0]
                                                                   })}>{p[1]}</option>
                                                })}
                                            </select>
                                        </div>}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4>{props.getT("Менеджеры")}</h4>
                        </div>
                        <table className="table table-striped">
                            <thead>
                            <th>{props.getT("ID")}</th>
                            <th>{props.getT("Имя")}</th>
                            <th>{props.getT("Сумма")}</th>
                            <th></th>
                            </thead>
                            <tbody>
                            {props.data &&
                                props.data.man &&
                                props.data.man.map((e) => (
                                    <MainReportItem
                                        getT={props.getT}
                                        e={e}
                                        currency={props.currency}
                                        currentCurrency={props.form.currency}
                                        getCurrencyName={props.getCurrencyName}
                                        formatSum={props.formatSum}
                                    />
                                ))}
                            <tr>
                                <td colSpan={5} className={"text-right"}>
                                    {/*<h4><small>Итого:</small> {Number(props.data.summ_mat).toFixed(2)} {props.getCurrencyName()}</h4>*/}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4 className={"text-right"}>
                                <small>{props.getT("Итого за период")}:</small>{" "}
                                {props.data &&
                                    props.data.totals &&
                                    props.formatSum(props.data.totals.all_summ)}{" "}
                                {props.getCurrencyName()}
                            </h4>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

class MainReportItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    render() {
        return this.state.isOpen ? (
            <>
                <tr>
                    <td>{this.props.e.id}</td>
                    <td>{this.props.e.name}</td>
                    <td>
                        {this.props.formatSum(this.props.e.goods_user_summ)}{" "}
                        {this.props.getCurrencyName()}
                    </td>
                    <td>
                        <i
                            className="fas fa-angle-down"
                            onClick={() => this.setState({isOpen: !this.state.isOpen})}
                        >
                            {" "}
                        </i>
                    </td>
                </tr>
                {Number(this.props.e.goods_user_summ) > 0 ? (
                    <tr>
                        <td colSpan={5}>
                            <table className={"table table-bordered"}>
                                <thead>
                                <tr>
                                    <th>{this.props.getT("ID Клиента")}</th>
                                    <th>{this.props.getT("Название")}</th>
                                    <th>{this.props.getT("Сумма")}</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.props.e.sale &&
                                    this.props.e.sale.map((o) => (
                                        <OrderClientItem
                                            getT={this.props.getT}
                                            client={o}
                                            currency={this.props.currency}
                                            currentCurrency={this.props.currentCurrency}
                                            getCurrencyName={this.props.getCurrencyName}
                                            formatSum={this.props.formatSum}
                                        />
                                    ))}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                ) : (
                    ""
                )}
            </>
        ) : (
            <tr>
                <td>{this.props.e.id}</td>
                <td>{this.props.e.name}</td>
                <td>
                    {this.props.formatSum(this.props.e.goods_user_summ)}{" "}
                    {this.props.getCurrencyName()}
                </td>
                <td>
                    <i
                        className="fas fa-angle-down"
                        onClick={() => this.setState({isOpen: !this.state.isOpen})}
                    >
                        {" "}
                    </i>
                </td>
            </tr>
        );
    }
}

class OrderClientItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    render() {
        return this.state.isOpen ? (
            <>
                <tr>
                    <td>{this.props.client.client_id}</td>
                    <td>{this.props.client.name}</td>
                    <td>
                        {this.props.formatSum(this.props.client.goods_orders_summ)}{" "}
                        {this.props.getCurrencyName()}
                    </td>
                    <td>
                        <i
                            className="fas fa-angle-down"
                            onClick={() => this.setState({isOpen: !this.state.isOpen})}
                        >
                            {" "}
                        </i>
                    </td>
                </tr>
                <tr>
                    <td colSpan={5}>
                        <table className={"table table-bordered"}>
                            <thead>
                            <tr>
                                <th>{this.props.getT("ID")}</th>
                                <th>{this.props.getT("Cума товаров")}</th>
                                <th>{this.props.getT("Cума услуг")}</th>
                                <th>{this.props.getT("Cума заказа")}</th>
                                <th></th>
                            </tr>
                            </thead>
                            {this.props.client.orders &&
                                this.props.client.orders.map((p) => (
                                    <ClientOrderItem
                                        getT={this.props.getT}
                                        order={p}
                                        currency={this.props.currency}
                                        currentCurrency={this.props.currentCurrency}
                                        getCurrencyName={this.props.getCurrencyName}
                                        formatSum={this.props.formatSum}
                                    />
                                ))}
                        </table>
                    </td>
                </tr>
            </>
        ) : (
            <tr>
                <td>{this.props.client.client_id}</td>
                <td>{this.props.client.name}</td>
                <td>
                    {this.props.formatSum(this.props.client.goods_orders_summ)}{" "}
                    {this.props.getCurrencyName()}
                </td>
                <td>
                    <i
                        className="fas fa-angle-down"
                        onClick={() => this.setState({isOpen: !this.state.isOpen})}
                    >
                        {" "}
                    </i>
                </td>
            </tr>
        );
    }
}

class ClientOrderItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    render() {
        return this.state.isOpen ? (
            <>
                <tr>
                    <td>{this.props.order.order_id}</td>
                    <td>
                        {this.props.order.this_order_goods_summ &&
                            this.props.formatSum(this.props.order.this_order_goods_summ)}{" "}
                        {this.props.getCurrencyName()}
                    </td>
                    <td>
                        {this.props.order.this_order_service_summ &&
                            this.props.formatSum(
                                this.props.order.this_order_service_summ
                            )}{" "}
                        {this.props.getCurrencyName()}
                    </td>
                    <td>
                        {this.props.order.this_order_summ &&
                            this.props.formatSum(this.props.order.this_order_summ)}{" "}
                        {this.props.getCurrencyName()}
                    </td>
                    <td>
                        <i
                            className="fas fa-angle-down"
                            onClick={() => this.setState({isOpen: !this.state.isOpen})}
                        >
                            {" "}
                        </i>
                    </td>
                </tr>
                <tr>
                    <td colSpan={5}>
                        <table className={"table table-bordered"}>
                            <thead>
                            <tr>
                                <th>{this.props.getT("ID")}</th>
                                <th>{this.props.getT("Название")}</th>
                                <th>{this.props.getT("Кол-во")}</th>
                                <th>{this.props.getT("Цена")}</th>
                                <th>{this.props.getT("Сумма")}</th>
                            </tr>
                            </thead>
                            <div className="card-header">
                                <h4>{this.props.getT("Товары")}</h4>
                            </div>

                            {this.props.order.goods &&
                                this.props.order.goods.map((g) => {
                                    return (
                                        <tbody>
                                        <tr>
                                            <td>{g.goods_id}</td>
                                            <td>{g.name}</td>
                                            <td>{Number(g.count).toFixed(2)}</td>
                                            <td>
                                                {this.props.formatSum(g.price)}{" "}
                                                {this.props.getCurrencyName()}
                                            </td>
                                            <td>
                                                {this.props.formatSum(g.goods_summ)}{" "}
                                                {this.props.getCurrencyName()}
                                            </td>
                                        </tr>
                                        </tbody>
                                    );
                                })}
                            <div className="card-header">
                                <h4>{this.props.getT("Услуги")}</h4>
                            </div>
                            {this.props.order.service &&
                                this.props.order.service.map((s) => {
                                    return (
                                        <tbody>
                                        <tr>
                                            <td>{s.goods_service_id}</td>
                                            <td>{s.name}</td>
                                            <td>{Number(s.count).toFixed(2)}</td>
                                            <td>
                                                {this.props.formatSum(s.service_summ)}{" "}
                                                {this.props.getCurrencyName()}
                                            </td>
                                            <td>
                                                {this.props.formatSum(s.service_summ)}{" "}
                                                {this.props.getCurrencyName()}
                                            </td>
                                        </tr>
                                        </tbody>
                                    );
                                })}
                        </table>
                    </td>
                </tr>
            </>
        ) : (
            <tr>
                <td>{this.props.order.order_id}</td>
                <td>
                    {this.props.order.this_order_goods_summ &&
                        this.props.formatSum(this.props.order.this_order_goods_summ)}{" "}
                    {this.props.getCurrencyName()}
                </td>
                <td>
                    {this.props.order.this_order_service_summ &&
                        this.props.formatSum(this.props.order.this_order_service_summ)}{" "}
                    {this.props.getCurrencyName()}
                </td>
                <td>
                    {this.props.order.this_order_summ &&
                        this.props.formatSum(this.props.order.this_order_summ)}{" "}
                    {this.props.getCurrencyName()}
                </td>
                <td>
                    <i
                        className="fas fa-angle-down"
                        onClick={() => this.setState({isOpen: !this.state.isOpen})}
                    >
                        {" "}
                    </i>
                </td>
            </tr>
        );
    }
}

export default withLang(ReportClients);
