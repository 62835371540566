import React from 'react';

const ChoiceOrders = ({orders}) => {
    if (!Array.isArray(orders) && orders?.length) return <></>
    return (
        <>
            {orders?.map(order => (
                <tr>
                    <td>{order.id}</td>
                    <td></td>
                    <td></td>
                    <td>{order.name}</td>
                </tr>
            ))}
        </>
    );
};

export default ChoiceOrders;