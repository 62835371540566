import React, {useEffect, useState} from "react";
import PageTitle from "../PageTitle/PageTitle";
import Chart from "react-google-charts";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {gaEvents} from "../../gaEvents";

const Dashboard = (props) => {
    const currentManager = props.managers.filter(
        (el) => el.id === localStorage.getItem("i_furn_user_id")
    );

    const status = useSelector((state) => state.auth.user.role);
    const roles = status.map((el) => el.name);
    const canEdit = !roles.includes("CEO");

    let goodsSum =
        props.statistics.goods &&
        props.statistics.goods.reduce(function (acc, obj) {
            return acc + obj.sale;
        }, 0);
    let goodsSumSingle =
        props.single.goods &&
        props.single.goods.reduce(function (acc, obj) {
            return acc + obj.sale;
        }, 0);

    let servSum =
        props.statistics.serv &&
        props.statistics.serv.reduce(function (acc, obj) {
            return acc + obj.sale;
        }, 0);
    let servSumSingle =
        props.single.serv &&
        props.single.serv.reduce(function (acc, obj) {
            return acc + obj.sale;
        }, 0);

    let newClientServiceSum =
        props.new_clients_service &&
        props.new_clients_service.reduce(function (acc, obj) {
            return acc + obj[1];
        }, 0);
    let newClientGoodsSum =
        props.new_clients_goods &&
        props.new_clients_goods.reduce(function (acc, obj) {
            return acc + obj[1];
        }, 0);
    return (
        <div className="content-wrapper">
            {!canEdit ? (
                <>
                    <PageTitle title={"dashboard"}/>
                    <div className="container-fluid">
                        {props.terms && <div className="row">
                            <div className="col-12"><h4>{props.getT("Ориентировочные сроки производства")}</h4></div>
                            <div className="col-12 ">
                                <table>
                                    <thead>
                                    <tr>
                                        <th className="text-center">{props.getT("Услуга")}</th>
                                        <th className="text-center">{props.getT("Дата")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.terms && Object.entries(props.terms).map(([key, value]) => {
                                        return (<tr>
                                                <td className="text-center">{key?.toString()} </td>
                                                <td className="text-center"> {value?.toString()}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>}

                    </div>
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="form-group">
                                <label>{props.getT("Статус")} :</label>
                                <select
                                    value={props.form.status}
                                    onChange={(event) =>{
                                        gaEvents.customEvent('form_dashboard', {
                                            field_focus: 'Статус'
                                        })
                                        props.changeFilterValue(
                                            "status",
                                            event.target.value,
                                            props.form
                                        )
                                    }}
                                    className="form-control"
                                >
                                    {props.status.map((status) => {
                                        return (
                                            <option key={status.id} value={status.id}>
                                                {props.getT(status.name)}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="form-group">
                                <label>{props.getT("Месяц")}: </label>
                                <select
                                    value={props.form.date_prev}
                                    onChange={(event) =>{
                                        gaEvents.customEvent('form_dashboard', {
                                            field_focus: 'Месяц'
                                        })
                                        props.changeFilterValue(
                                            "date_prev",
                                            event.target.value,
                                            props.form
                                        )}
                                    }
                                    className="form-control"
                                >
                                    {props.dates.map((date) => {
                                        return (
                                            <option key={date.id} value={date.val}>
                                                {props.getT(date.name)}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        {props.form.date_prev === "0" && (
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label>{props.getT("День")}:</label>
                                    <input
                                        onChange={(event) =>
                                            props.changeFilterValue(
                                                "day_num",
                                                Number(event.target.value) >
                                                Number(
                                                    new Date().toISOString().split("T")[0].split("-")[2]
                                                )
                                                    ? Number(
                                                    new Date()
                                                        .toISOString()
                                                        .split("T")[0]
                                                        .split("-")[2]
                                                    )
                                                    : Number(event.target.value),
                                                props.form
                                            )
                                        }
                                        onFocus={()=>{
                                            gaEvents.customEvent('form_dashboard', {
                                                field_focus: 'День'
                                            })
                                        }}
                                        type="number"
                                        className="form-control"
                                        max={new Date().toISOString().split("T")[0].split("-")[2]}
                                        value={props.form.day_num}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="col-sm-3">
                            <div className="form-group">
                                <label>{props.getT("Менеджер")}:</label>
                                <select
                                    value={props.form.manager}
                                    onChange={(event) =>{
                                        gaEvents.customEvent('form_dashboard', {
                                            field_focus: 'Менеджер'
                                        })
                                        props.changeFilterValue(
                                            "manager",
                                            event.target.value,
                                            props.form
                                        )}
                                    }
                                    className="form-control"
                                >
                                    <option value={"all"}>{props.getT("Все")}</option>
                                    {props.managers &&
                                    props.managers.map((manager) => {
                                        return (
                                            <option key={manager.id} value={manager.id}>
                                                {manager.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4 col-12">
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <h3>
                                                {props.statistics.orders ? props.statistics.orders : ""}
                                            </h3>
                                            <p>{props.getT("Заказов (за текущий месяц)")}</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-bag"></i>
                                        </div>
                                        <Link to={"/orders/actual"} className="small-box-footer" >
                                            {props.getT("Больше информации")} <i className="fas fa-arrow-circle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <h3>
                                                {props.statistics.all_summ
                                                    ? props.statistics.all_summ
                                                        .toFixed(2)
                                                        .toString()
                                                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                                                    : ""}
                                                {/*uah*/}
                                            </h3>
                                            <p>{props.getT("Общая сумма (текущий месяц)")}</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-stats-bars"></i>
                                        </div>
                                        <a href="/orders/actual" className="small-box-footer">
                                            {props.getT("Больше информации")} <i className="fas fa-arrow-circle-right"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="small-box bg-warning">
                                        <div className="inner">
                                            <h3>{props.counters.clients}</h3>
                                            <p>{props.getT("Клиентов")}</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-person-add"></i>
                                        </div>
                                        <a href="/clients" className="small-box-footer">
                                            {props.getT("Больше информации")} <i className="fas fa-arrow-circle-right"></i>
                                        </a>
                                    </div>
                                </div>
                                {/* <div className="col-lg-3 col-6">*/}
                                {/*<div className="small-box bg-danger">*/}
                                {/*<div className="inner">*/}
                                {/*<h3>{props.counters.managers}</h3>*/}
                                {/*<p>Менеджеров</p>*/}
                                {/*</div>*/}
                                {/*<div className="icon">*/}
                                {/*<i className="ion ion-pie-graph"></i>*/}
                                {/*</div>*/}
                                {/*<a href="/clients" className="small-box-footer">More info <i*/}
                                {/*className="fas fa-arrow-circle-right"></i></a>*/}
                                {/*</div>*/}
                                {/*</div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {(props?.goods?.length) ? (
                                        <Chart
                                            width={"100%"}
                                            height={"450px"}
                                            chartType="LineChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[[props.getT("День"), props.getT("Сумма")], ...props.goods]}
                                            options={{
                                                title: props.getT("График продажи товаров"),
                                                hAxis: {
                                                    title: props.getT(`Общая сумма по товарам в текущем месяце`) + ":" + goodsSum.toFixed(2),
                                                    titleTextStyle: {color: "#333"}
                                                },
                                                vAxis: {minValue: 1},
                                                // For the legend to fit, we make the chart area smaller
                                                chartArea: {width: "70%", height: "70%"}
                                                // lineWidth: 25
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                    {(props?.serv?.length) ? (
                                        <Chart
                                            width={"100%"}
                                            height={"450px"}
                                            chartType="LineChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[[props.getT("День"), props.getT("Сумма")], ...props.serv]}
                                            options={{
                                                title: props.getT("График продажи услуг"),
                                                hAxis: {
                                                    title: props.getT(`Общая сумма по услугам в текущем месяце`) + ":" + servSum.toFixed(2),
                                                    titleTextStyle: {color: "#333"}
                                                },
                                                vAxis: {minValue: 1},
                                                // For the legend to fit, we make the chart area smaller
                                                chartArea: {width: "70%", height: "70%"}
                                                // lineWidth: 25
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col-md-12">
                                    <h3 style={{display: "flex", justifyContent: "center"}}>
                                        {props.getT("Таблица типов услуг")}
                                    </h3>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th scope="col">{props.getT("Услуга")}</th>
                                            <th scope="col">{props.getT("Количество")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {props.statistics.service_type &&
                                        props.statistics.service_type
                                            .sort((a, b) =>
                                                a.translate[props.lang] > b.translate[props.lang]
                                                    ? 1
                                                    : b.translate[props.lang] > a.translate[props.lang]
                                                    ? -1
                                                    : 0
                                            )
                                            .map((el, idx) => {
                                                return (
                                                    <tr>
                                                        <td data-label={props.getT("Название")}>{el.translate[props.lang]}</td>

                                                        <td data-label={props.getT("Количество")}>
                                                            {el.sale.toFixed(2)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                    <br/>
                                    <br/>

                                    <h3 style={{display: "flex", justifyContent: "center"}}>
                                        {props.getT("Таблица услуг")}
                                    </h3>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th scope="col">{props.getT("Услуга")}</th>

                                            <th scope="col">{props.getT("Количество")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {props.statistics.service_sale &&
                                        props.statistics.service_sale
                                            .sort((a, b) =>
                                                a.translate[props.lang] > b.translate[props.lang]
                                                    ? 1
                                                    : b.translate[props.lang] > a.translate[props.lang]
                                                    ? -1
                                                    : 0
                                            )
                                            .map((el, idx) => {
                                                return (
                                                    <tr>
                                                        <td data-label={props.getT("Название")}>{el.translate[props.lang]}</td>

                                                        <td data-label={props.getT("Количество")}>
                                                            {el.sale.toFixed(2)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-md-4">
                                    {props.ordersType ? (
                                        <Chart
                                            width={"100%"}
                                            height={"450px"}
                                            chartType="PieChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[[props.getT("Тип"), props.getT("Кол-во заказов")], ...props.ordersType]}
                                            options={{
                                                title: props.getT("Статистика заказов"),
                                                hAxis: {
                                                    title: props.getT("Март")+"2021",
                                                    titleTextStyle: {color: "#333"}
                                                },
                                                vAxis: {minValue: 1},
                                                // For the legend to fit, we make the chart area smaller
                                                chartArea: {width: "90%", height: "70%"}
                                                // lineWidth: 25
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-4 mt-4"><h2>{props.getT("Новые клиенты")}</h2></div>
                                <div className="col-12">
                                    {props.new_clients?.data?.length > 0 ?(
                                        <table>
                                            <tr>
                                                <td className="text-left">
                                                    {props.new_clients?.data && props.new_clients.data.map((item, index) => {
                                                        return item.name + ', '
                                                    })}
                                                </td>
                                            </tr>
                                        </table>) : ""
                                    }
                                </div>
                                {/*{props.statistics.new_clients &&<>*/}
                                    <div className="col-lg-4 col-12">
                                        <div className="small-box bg-info">
                                            <div className="inner">
                                                <h3>
                                                    {props.statistics?.new_clients?.sale?.orders ? props.statistics.new_clients.sale.orders: 0}
                                                </h3>
                                                <p>{props.getT("Заказов (за текущий месяц)")}</p>
                                            </div>
                                            <div className="icon">
                                                <i className="ion ion-bag"></i>
                                            </div>
                                            <Link to={"/orders/actual"} className="small-box-footer">
                                                {props.getT("Больше информации")} <i className="fas fa-arrow-circle-right"></i>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-12">
                                        <div className="small-box bg-success">
                                            <div className="inner">
                                                <h3>
                                                    {props.statistics?.new_clients?.sale.all_summ
                                                        ? props.statistics.new_clients.sale.all_summ
                                                            .toFixed(2)
                                                            .toString()
                                                            .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                                                        : 0}
                                                    {/*uah*/}
                                                </h3>
                                                <p>{props.getT("Общая сумма (текущий месяц)")}</p>
                                            </div>
                                            <div className="icon">
                                                <i className="ion ion-stats-bars"></i>
                                            </div>
                                            <a href="/orders/actual" className="small-box-footer">
                                                {props.getT("Больше информации")} <i className="fas fa-arrow-circle-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-12">
                                        <div className="small-box bg-warning">
                                            <div className="inner">
                                                <h3>{props.new_clients?.count ?? 0}</h3>
                                                <p>{props.getT("Клиентов")}</p>
                                            </div>
                                            <div className="icon">
                                                <i className="ion ion-person-add"></i>
                                            </div>
                                            <a href="/clients" className="small-box-footer">
                                                {props.getT("Больше информации")} <i className="fas fa-arrow-circle-right"></i>
                                            </a>
                                        </div>
                                    </div>
                                {/*</>*/}
                                {/*}*/}
                                <div className="col-md-12">
                                    {props.new_clients_goods?.length > 0 ? (
                                        <Chart
                                            width={"100%"}
                                            height={"450px"}
                                            chartType="LineChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[[props.getT("День"), props.getT("Сумма")], ...props.new_clients_goods]}
                                            options={{
                                                title: props.getT("График продажи товаров"),
                                                hAxis: {
                                                    title: props.getT(`Общая сумма по товарам в текущем месяце`)+ ":" +newClientGoodsSum.toFixed(2),
                                                    titleTextStyle: {color: "#333"}
                                                },
                                                vAxis: {minValue: 1},
                                                // For the legend to fit, we make the chart area smaller
                                                chartArea: {width: "70%", height: "70%"}
                                                // lineWidth: 25
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                    {props.new_clients_service?.length > 0  ? (
                                        <Chart
                                            width={"100%"}
                                            height={"450px"}
                                            chartType="LineChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[[props.getT("День"), props.getT("Сумма")], ...props.new_clients_service]}
                                            options={{
                                                title: props.getT("График продажи услуг"),
                                                hAxis: {
                                                    title: props.getT(`Общая сумма по услугам в текущем месяце`) +" : "+newClientServiceSum.toFixed(2),
                                                    titleTextStyle: {color: "#333"}
                                                },
                                                vAxis: {minValue: 1},
                                                // For the legend to fit, we make the chart area smaller
                                                chartArea: {width: "70%", height: "70%"}
                                                // lineWidth: 25
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                {props.statistics.new_clients &&
                                <div className="col-md-12">
                                    <h3 style={{display: "flex", justifyContent: "center"}}>
                                        {props.getT("Таблица типов услуг")}
                                    </h3>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th scope="col">{props.getT("Услуга")}</th>
                                            <th scope="col">{props.getT("Количество")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {props.statistics.new_clients.sale.length >=1 &&
                                        props.statistics.new_clients.sale.service_type
                                            .sort((a, b) =>
                                                a.translate[props.lang] > b.translate[props.lang]
                                                    ? 1
                                                    : b.translate[props.lang] > a.translate[props.lang]
                                                    ? -1
                                                    : 0
                                            )
                                            .map((el, idx) => {
                                                return (
                                                    <tr>
                                                        <td data-label={props.getT("Название")}>{el.translate[props.lang]}</td>

                                                        <td data-label={props.getT("Количество")}>
                                                            {el.sale.toFixed(2)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                    <br/>
                                    <br/>

                                    <h3 style={{display: "flex", justifyContent: "center"}}>
                                        {props.getT("Таблица услуг")}
                                    </h3>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th scope="col">{props.getT("Услуга")}</th>

                                            <th scope="col">{props.getT("Количество")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {props.statistics.new_clients.sale.length >=1 &&
                                        props.statistics.new_clients.sale.service_sale
                                            .sort((a, b) =>
                                                a.translate[props.lang] > b.translate[props.lang]
                                                    ? 1
                                                    : b.translate[props.lang] > a.translate[props.lang]
                                                    ? -1
                                                    : 0
                                            )
                                            .map((el, idx) => {
                                                return (
                                                    <tr>
                                                        <td data-label={props.getT("Название")}>{el.translate[props.lang]}</td>

                                                        <td data-label={props.getT("Количество")}>
                                                            {el.sale.toFixed(2)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                }
                                <div className="col-md-4">

                                    {props.new_clients_orders?.length > 0 ? (
                                        <Chart
                                            width={"100%"}
                                            height={"450px"}
                                            chartType="PieChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                [props.getT("Тип"), props.getT("Кол-во заказов")],
                                                ...props.new_clients_orders
                                            ]}
                                            options={{
                                                title: props.getT("Статистика заказов "),
                                                hAxis: {
                                                    title: props.getT("Март ")+ " 2021",
                                                    titleTextStyle: {color: "#333"}
                                                },
                                                vAxis: {minValue: 1},
                                                // For the legend to fit, we make the chart area smaller
                                                chartArea: {width: "90%", height: "70%"}
                                                // lineWidth: 25
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            ) : (
                <>
                    <PageTitle title={"dashboard"}/>
                    <div className="container-fluid">
                        {props.terms && <div className="row">
                            <div className="col-12"><h4>{props.getT("Ориентировочные сроки производства")}</h4></div>
                            <div className="col-12 ">
                                <table>
                                    <thead>
                                    <tr>
                                        <th className="text-center">{props.getT("Услуга")}</th>
                                        <th className="text-center">{props.getT("Дата")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.terms && Object.entries(props.terms).map(([key, value]) => {
                                        return (<tr>
                                                <td className="text-center">{key?.toString()} </td>
                                                <td className="text-center"> {value?.toString()}</td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                        <div className="row">
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label>{props.getT("Статус")}:</label>
                                    <select
                                        value={props.formSingle.status}
                                        onChange={(event) =>
                                            props.changeSingle(
                                                "status",
                                                event.target.value,
                                                props.formSingle
                                            )
                                        }
                                        className="form-control"
                                    >
                                        {props.status.map((status) => {
                                            return (
                                                <option key={status.id} value={status.id}>
                                                    {props.getT(status.name)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="form-group">
                                    <label>{props.getT("Месяц")}:</label>
                                    <select
                                        value={props.formSingle.date_prev}
                                        onChange={(event) =>
                                            props.changeSingle(
                                                "date_prev",
                                                event.target.value,
                                                props.formSingle
                                            )
                                        }
                                        className="form-control"
                                    >
                                        {props.dates.map((date) => {
                                            return (
                                                <option key={date.id} value={date.val}>
                                                    {props.getT(date.name)}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            {props.formSingle.date_prev === "0" && (
                                <div className="col-sm-2">
                                    <div className="form-group">
                                        <label>{props.getT("День")}:</label>
                                        <input
                                            onChange={(event) =>
                                                props.changeSingle(
                                                    "day_num",
                                                    Number(event.target.value) >
                                                    Number(
                                                        new Date().toISOString().split("T")[0].split("-")[2]
                                                    )
                                                        ? Number(
                                                        new Date()
                                                            .toISOString()
                                                            .split("T")[0]
                                                            .split("-")[2]
                                                        )
                                                        : Number(event.target.value),
                                                    props.formSingle
                                                )
                                            }
                                            type="number"
                                            className="form-control"
                                            max={new Date().toISOString().split("T")[0].split("-")[2]}
                                            value={props.formSingle.day_num}
                                        />
                                    </div>
                                </div>
                            )}

                        <div className="col-sm-2">
                            <div className="form-group">
                                <label>{props.getT("Менеджер")}:</label>
                                <select className="form-control">
                                    <option>{currentManager[0]?.name}</option>
                                </select>
                            </div>
                        </div>
                        </div>
                    </div>

                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4 col-12">
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <h3>{props.single.orders ? props.single.orders : 0}</h3>
                                            <p>{props.getT("Заказов (за текущий месяц)")}</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-bag"></i>
                                        </div>
                                        <Link to={"/orders/actual"} className="small-box-footer">
                                            {props.getT("Больше информации")} <i className="fas fa-arrow-circle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <h3>
                                                {props.single.all_summ
                                                    ? props.single.all_summ
                                                        .toFixed(2)
                                                        .toString()
                                                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                                                    : ""}
                                                {/*uah*/}
                                            </h3>
                                            <p>{props.getT("Общая сумма (текущий месяц)")}</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-stats-bars"></i>
                                        </div>
                                        <a href="/orders/actual" className="small-box-footer">
                                            {props.getT("Больше информации")} <i className="fas fa-arrow-circle-right"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="small-box bg-warning">
                                        <div className="inner">
                                            <h3>{props.counters.clients}</h3>
                                            <p>{props.getT("Клиентов")}</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-person-add"></i>
                                        </div>
                                        <a href="/clients" className="small-box-footer">
                                            {props.getT("Больше информации")} <i className="fas fa-arrow-circle-right"></i>
                                        </a>
                                    </div>
                                </div>
                                {/* <div className="col-lg-3 col-6">*/}
                                {/*<div className="small-box bg-danger">*/}
                                {/*<div className="inner">*/}
                                {/*<h3>{props.counters.managers}</h3>*/}
                                {/*<p>Менеджеров</p>*/}
                                {/*</div>*/}
                                {/*<div className="icon">*/}
                                {/*<i className="ion ion-pie-graph"></i>*/}
                                {/*</div>*/}
                                {/*<a href="/clients" className="small-box-footer">More info <i*/}
                                {/*className="fas fa-arrow-circle-right"></i></a>*/}
                                {/*</div>*/}
                                {/*</div> */}
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {props.goodsSingle ? (
                                        <Chart
                                            width={"100%"}
                                            height={"450px"}
                                            chartType="LineChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[[props.getT("День"), props.getT("Сумма")], ...props.goodsSingle]}
                                            options={{
                                                title: props.getT("График продажи товаров"),
                                                hAxis: {
                                                    title: props.getT(`Общая сумма по товарам в текущем месяце`) +":"+ goodsSumSingle.toFixed(2),
                                                    titleTextStyle: {color: "#333"}
                                                },
                                                vAxis: {minValue: 1},
                                                // For the legend to fit, we make the chart area smaller
                                                chartArea: {width: "70%", height: "70%"}
                                                // lineWidth: 25
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                    {props.servSingle ? (
                                        <Chart
                                            width={"100%"}
                                            height={"450px"}
                                            chartType="LineChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[[props.getT("День"), props.getT("Сумма")], ...props.servSingle]}
                                            options={{
                                                title: props.getT("График продажи услуг"),
                                                hAxis: {
                                                    title: props.getT(`Общая сумма по услугам в текущем месяце`)+ ": "+ servSumSingle.toFixed(2),
                                                    titleTextStyle: {color: "#333"}
                                                },
                                                vAxis: {minValue: 1},
                                                // For the legend to fit, we make the chart area smaller
                                                chartArea: {width: "70%", height: "70%"}
                                                // lineWidth: 25
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col-md-12">
                                    <h3 style={{display: "flex", justifyContent: "center"}}>
                                        {props.getT("Таблица типов услуг")}
                                    </h3>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th scope="col">{props.getT("Услуга")}</th>
                                            <th scope="col">{props.getT("Количество")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {props.single.service_type &&
                                        props.single.service_type
                                            .sort((a, b) =>
                                                a.translate[props.lang] > b.translate[props.lang]
                                                    ? 1
                                                    : b.translate[props.lang] > a.translate[props.lang]
                                                    ? -1
                                                    : 0
                                            )
                                            .map((el, idx) => {
                                                return (
                                                    <tr>
                                                        <td data-label={props.getT("Название")}>{el.translate[props.lang]}</td>

                                                        <td data-label={props.getT("Количество")}>
                                                            {el.sale.toFixed(2)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                    <br/>
                                    <br/>

                                    <h3 style={{display: "flex", justifyContent: "center"}}>
                                        {props.getT(" Таблица услуг")}
                                    </h3>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th scope="col">{props.getT("Услуга")}</th>

                                            <th scope="col">{props.getT("Количество")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {props.single.service_sale &&
                                        props.single.service_sale
                                            .sort((a, b) =>
                                                a.translate[props.lang] > b.translate[props.lang]
                                                    ? 1
                                                    : b.translate[props.lang] > a.translate[props.lang]
                                                    ? -1
                                                    : 0
                                            )
                                            .map((el, idx) => {
                                                return (
                                                    <tr>
                                                        <td data-label={props.getT("Название")}>{el.translate[props.lang]}</td>

                                                        <td data-label={props.getT("Количество")}>
                                                            {el.sale.toFixed(2)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-md-4">
                                    {props.ordersTypeSingle ? (
                                        <Chart
                                            width={"100%"}
                                            height={"450px"}
                                            chartType="PieChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                [props.getT("Тип"), props.getT("Кол-во заказов")],
                                                ...props.ordersTypeSingle
                                            ]}
                                            options={{
                                                title: props.getT("Статистика заказов "),
                                                hAxis: {
                                                    title: props.getT("Март ") +"2021",
                                                    titleTextStyle: {color: "#333"}
                                                },
                                                vAxis: {minValue: 1},
                                                // For the legend to fit, we make the chart area smaller
                                                chartArea: {width: "90%", height: "70%"}
                                                // lineWidth: 25
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mb-4 mt-4"><h2>{props.getT("Новые клиенты")}</h2></div>
                                <div className="col-12">
                                    {props.new_clients?.data?.length > 0 ?(
                                        <table>
                                            <tr>
                                                <td className="text-left">
                                                    {props.new_clients?.data && props.new_clients.data.map((item, index) => {
                                                        return item.name + ', '
                                                    })}
                                                </td>
                                            </tr>
                                        </table>) : ""
                                    }
                                </div>
                                {/*{props.statistics.new_clients &&<>*/}
                                <div className="col-lg-4 col-12">
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <h3>
                                                {props.statistics?.new_clients?.sale?.orders ? props.statistics.new_clients.sale.orders: 0}
                                            </h3>
                                            <p>{props.getT("Заказов (за текущий месяц)")}</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-bag"></i>
                                        </div>
                                        <Link to={"/orders/actual"} className="small-box-footer">
                                            {props.getT("Больше информации")} <i className="fas fa-arrow-circle-right"></i>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <h3>
                                                {props.statistics?.new_clients?.sale.all_summ
                                                    ? props.statistics.new_clients.sale.all_summ
                                                        .toFixed(2)
                                                        .toString()
                                                        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")
                                                    : 0}
                                                {/*uah*/}
                                            </h3>
                                            <p>{props.getT("Общая сумма (текущий месяц)")}</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-stats-bars"></i>
                                        </div>
                                        <a href="/orders/actual" className="small-box-footer">
                                            {props.getT("Больше информации")} <i className="fas fa-arrow-circle-right"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-12">
                                    <div className="small-box bg-warning">
                                        <div className="inner">
                                            <h3>{props.new_clients?.count ?? 0}</h3>
                                            <p>{props.getT("Клиентов")}</p>
                                        </div>
                                        <div className="icon">
                                            <i className="ion ion-person-add"></i>
                                        </div>
                                        <a href="/clients" className="small-box-footer">
                                            {props.getT("Больше информации")} <i className="fas fa-arrow-circle-right"></i>
                                        </a>
                                    </div>
                                </div>
                                {/*</>*/}
                                {/*}*/}
                                {/* <div className="col-lg-3 col-6">*/}
                                {/*<div className="small-box bg-danger">*/}
                                {/*<div className="inner">*/}
                                {/*<h3>{props.counters.managers}</h3>*/}
                                {/*<p>Менеджеров</p>*/}
                                {/*</div>*/}
                                {/*<div className="icon">*/}
                                {/*<i className="ion ion-pie-graph"></i>*/}
                                {/*</div>*/}
                                {/*<a href="/clients" className="small-box-footer">More info <i*/}
                                {/*className="fas fa-arrow-circle-right"></i></a>*/}
                                {/*</div>*/}
                                {/*</div> */}



                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {props.new_clients_goods?.length > 0 ? (
                                        <Chart
                                            width={"100%"}
                                            height={"450px"}
                                            chartType="LineChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[[props.getT("День"), props.getT("Сумма")], ...props.new_clients_goods]}
                                            options={{
                                                title: props.getT("График продажи товаров"),
                                                hAxis: {
                                                    title: props.getT(`Общая сумма по товарам в текущем месяце`) + ":" + newClientGoodsSum.toFixed(2),
                                                    titleTextStyle: {color: "#333"}
                                                },
                                                vAxis: {minValue: 1},
                                                // For the legend to fit, we make the chart area smaller
                                                chartArea: {width: "70%", height: "70%"}
                                                // lineWidth: 25
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                    {props.new_clients_service?.length > 0  ? (
                                        <Chart
                                            width={"100%"}
                                            height={"450px"}
                                            chartType="LineChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[[props.getT("День"), props.getT("Сумма")], ...props.new_clients_service]}
                                            options={{
                                                title: props.getT("График продажи услуг"),
                                                hAxis: {
                                                    title: props.getT(`Общая сумма по услугам в текущем месяце`)+ ":" + newClientServiceSum.toFixed(2),
                                                    titleTextStyle: {color: "#333"}
                                                },
                                                vAxis: {minValue: 1},
                                                // For the legend to fit, we make the chart area smaller
                                                chartArea: {width: "70%", height: "70%"}
                                                // lineWidth: 25
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                                {props.statistics.new_clients &&
                                <div className="col-md-12">
                                    <h3 style={{display: "flex", justifyContent: "center"}}>
                                        {props.getT("Таблица типов услуг")}
                                    </h3>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th scope="col">{props.getT("Услуга")}</th>
                                            <th scope="col">{props.getT("Количество")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {props.statistics.new_clients &&
                                        props.statistics.new_clients.hasOwnProperty('sale') &&
                                             props.statistics.new_clients.sale.hasOwnProperty('service_type') && props.statistics.new_clients.sale.service_type
                                            .sort((a, b) =>
                                                a.translate[props.lang] > b.translate[props.lang]
                                                    ? 1
                                                    : b.translate[props.lang] > a.translate[props.lang]
                                                    ? -1
                                                    : 0
                                            )
                                            .map((el, idx) => {
                                                return (
                                                    <tr>
                                                        <td data-label={props.getT("Название")}>{el.translate[props.lang]}</td>

                                                        <td data-label={props.getT("Количество")}>
                                                            {el.sale.toFixed(2)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>

                                    <br/>
                                    <br/>

                                    <h3 style={{display: "flex", justifyContent: "center"}}>
                                        {props.getT(" Таблица услуг")}
                                    </h3>
                                    <table>
                                        <thead>
                                        <tr>
                                            <th scope="col">{props.getT("Услуга")}</th>

                                            <th scope="col">{props.getT("Количество")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        { props.statistics.new_clients.hasOwnProperty('sale') &&
                                        props.statistics.new_clients.sale.hasOwnProperty('service_sale') && props.statistics.new_clients.sale.service_sale
                                            .sort((a, b) =>
                                                a.translate[props.lang] > b.translate[props.lang]
                                                    ? 1
                                                    : b.translate[props.lang] > a.translate[props.lang]
                                                    ? -1
                                                    : 0
                                            )
                                            .map((el, idx) => {
                                                return (
                                                    <tr>
                                                        <td data-label={props.getT("Название")}>{el.translate[props.lang]}</td>

                                                        <td data-label={props.getT("Количество")}>
                                                            {el.sale.toFixed(2)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                }
                                <div className="col-md-4">
                                    {props.new_clients_orders?.length > 0 ? (
                                        <Chart
                                            width={"100%"}
                                            height={"450px"}
                                            chartType="PieChart"
                                            loader={<div>Loading Chart</div>}
                                            data={[
                                                [props.getT("Тип"), props.getT("Кол-во заказов")],
                                                ...props.new_clients_orders
                                            ]}
                                            options={{
                                                title: props.getT("Статистика заказов "),
                                                hAxis: {
                                                    title: props.getT("Март")+" 2021",
                                                    titleTextStyle: {color: "#333"}
                                                },
                                                vAxis: {minValue: 1},
                                                // For the legend to fit, we make the chart area smaller
                                                chartArea: {width: "90%", height: "70%"}
                                                // lineWidth: 25
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )}
        </div>
    );
};

export default Dashboard;
