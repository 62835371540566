import React, {useEffect, useState} from 'react';
import Button from "../../../../ui/Button";
import ItemPart from "./ItemPart";
import {LS_USER_ID, LS_WORKER_ID} from "../../../../../utils/constants";

const PackingPartForm = ({order, getT, handlerSubmitForm}) => {
    const [select_value, setSelectValue] = useState({
        material: null,
    })
    const [form_value, setFormValue] = useState({
        all: false,
        part: []
    });
    useEffect(() => {
        if (!form_value?.part?.length) return
        let part = part_group(select_value);
        let prev_form_part = form_value.part;
        if (JSON.stringify(part) !== JSON.stringify(prev_form_part)) {
            setFormValue(prev => ({...prev, part: part}))
        }
    }, [order])

    const part_group = (select_value) => {
        try {
            if (!select_value?.material) return []
            let part = order.part;
            let pack_information = order?.pack_information ?? [];
            const group_cutting_of_material = part?.reduce((acc, item) => {
                let part_count = order?.count_part?.find(e => Number(e?.part_id) === Number(item?.id))
                let find_material = Number(item?.material) === Number(select_value.material);
                let find_part_ready = pack_information?.find(_item => Number(_item?.part_id) === Number(item?.id)) || {};
                let uniq_no_include = find_part_ready?.marks?.map(e => Number(e?.unique_num)) ?? [];

                if (find_material) {
                    let marks = Array.from({length: part_count?.count}, (v, i) => i)?.map((count, index) => {
                        let id = index + 1;
                        let part_mark = find_part_ready?.marks?.[index];
                        let initial_unique_num =  uniq_no_include?.includes(index) ?  Math.max(...uniq_no_include) + 1: index;
                        let result = Number(initial_unique_num) === 0 ? 1 : Number(initial_unique_num);
                        uniq_no_include.push(result)
                        let part_result = {
                            id: id,
                            part_id: item.id,
                            unique_num: result,
                            initial_unique_num: result,
                            is_checked: false,
                            initial_checked: false,
                        }
                        if (part_mark) {
                            part_result['initial_unique_num'] = part_mark.unique_num;
                            part_result['unique_num'] = part_mark.unique_num;
                            part_result['is_checked'] = true;
                            part_result['initial_checked'] = true;

                        }
                        return part_result
                    })
                    let part_result = {
                        part_id: item?.id,
                        count_all: part_count?.count,
                        is_checked: marks.every(item => item?.is_checked),
                        marks: marks?.sort((a, b) => Number(a?.unique_num) - Number(b?.unique_num))
                    }
                    acc.push(part_result)

                }
                return acc
            }, [])?.sort((a, b) => Number(a?.part_id) - Number(b?.part_id));
            return group_cutting_of_material
        } catch (e) {
            console.log(e.message)
            return []
        }
    }

    const handlerChangeSelect = (key) => (e) => {
        let val = e.target.value;
        let select = {material: val};

        setSelectValue(prev => (select))
        let create_fields = part_group(select);
        let is_all_checked_parts = create_fields?.every(part => part.is_checked) || false
        setFormValue({all: is_all_checked_parts, part: create_fields})

    }
    const handlerChangeUniq = (mark_item) => (event) => {
        let value = event.target.value;
        let update = [...form_value.part]?.map(item => {
            if (Number(item.part_id) === Number(mark_item?.part_id)) {
                let marks = item?.marks?.map(i => {
                    if (Number(mark_item.id) === Number(i.id)) {
                        return {
                            ...i, unique_num: value
                        }
                    }
                    return i

                })
                return {
                    ...item, is_checked: marks?.every(i => i.is_checked),
                    marks: marks
                }
            }
            return item
        })
        setFormValue(prev => ({
            ...prev, part: update
        }))
    }
    const handlerChoiceRoot = (part) => (event) => {
        let value = event.target.checked;

        let update = [...form_value.part]?.map(item => {
            if (Number(item.part_id) === Number(part?.part_id)) {
                return {
                    ...item, is_checked: value, marks: item?.marks?.map(i => ({
                        ...i, is_checked: value
                    }))
                }
            }
            return item
        })
        setFormValue(prev => ({
            ...prev, part: update
        }))

    }
    const handlerChoiceUniqItem = (mark_item) => (event) => {
        let value = event.target.checked;

        let update = [...form_value.part]?.map(item => {
            if (Number(item.part_id) === Number(mark_item?.part_id)) {
                let marks = item?.marks?.map(i => {
                    if (Number(mark_item.id) === Number(i.id)) {
                        return {
                            ...i, is_checked: value
                        }
                    }
                    return i

                })
                return {
                    ...item, is_checked: marks?.every(i => i.is_checked),
                    marks: marks
                }
            }
            return item
        })
        setFormValue(prev => ({
            ...prev, part: update
        }))
    }
    const handlerSubmit = () => {
        let payload = {}
        console.log(form_value)

        if (form_value.all) {
            payload['put'] = {
                // service_add: choice_service.service_id,
                // service_type_add: choice_service.service_type_add, entry: []
            }
        } else {
            let result = form_value?.part?.reduce((acc, part) => {
                let put_mark = part?.marks?.filter(item => (item?.initial_checked !== item.is_checked || item?.initial_unique_num !== item?.unique_num) && item.is_checked)
                    ?.map(i => ({
                        // "id": order.order,
                        "part_id": i.part_id,
                        "unique": i.unique_num,
                        // "user": localStorage.getItem(LS_USER_ID),
                        // "equipment_worker": localStorage.getItem(LS_WORKER_ID)
                    }))

                let delete_mark = part?.marks?.filter(item => (item?.initial_checked !== item.is_checked || item?.initial_unique_num !== item?.unique_num) && !item.is_checked)
                    ?.map(i => ({
                        // "id": order.order,
                        "part_id": i.part_id,
                        "unique": i.unique_num,

                    }))
                return {
                    put: [...acc.put, ...put_mark],
                    delete: [...acc.delete, ...delete_mark],
                }
            }, {put: [], delete: []})


            if (Array.isArray(result.put) && result.put?.length) {
                payload['put'] = [
                    {
                        "id": order.order,
                        part: result.put,
                        "user": localStorage.getItem(LS_USER_ID),
                        "equipment_worker": localStorage.getItem(LS_WORKER_ID)
                    }
                ]

            }
            if (Array.isArray(result.delete) && result.delete?.length) {
                payload['remove'] =
                    [
                        {
                            "id": order.order,
                            part: result.delete,
                            "user": localStorage.getItem(LS_USER_ID),
                            "equipment_worker": localStorage.getItem(LS_WORKER_ID)
                        }
                    ]


            }
        }
        handlerSubmitForm(payload)
    }
    return (
        <div>
            <div className={"form-group"}>
                <label>{getT("Материал")}</label>
                <select name="" id="" className={'form-control'} value={select_value.material}
                        onChange={handlerChangeSelect('material')}>
                    <option value={''}> ---------</option>
                    {order?.material?.map(item => {
                        return <option value={item.id}>{item.name} </option>
                    })}
                </select>
            </div>

            <div className="cutting-wrap">
                <div className={'cutting-item p-0'}>
                    {!!form_value?.part?.length ? <div className="part-list">

                            {form_value?.part?.map(part => {

                                return <ItemPart part={part} getT={getT} handlerChangeUniq={handlerChangeUniq}
                                                 key={part.part_id}
                                                 handlerChoiceRoot={handlerChoiceRoot}
                                                 handlerChoiceUniqItem={handlerChoiceUniqItem}
                                />
                            })}
                        </div>
                        : <p className={'text-center text-black-50'}>{getT("Детали отсутствуют")}</p>}
                </div>

                {!!form_value?.part?.length && <div className="d-flex justify-content-center">
                    <Button onClick={handlerSubmit}>{getT("Отправить")}</Button>
                </div>
                }
            </div>
        </div>

    );
};

export default PackingPartForm;