import React, {useEffect} from 'react';
import PageTitle from "../PageTitle/PageTitle";
import {OrderItem} from "./Orders";
import Select from 'react-select'
import {Link} from "react-router-dom";
import {withLang} from "../../hoc/withLang";
import {useSelector} from "react-redux";
import {gaEvents} from "../../gaEvents";


const OrdersProblem = (props) => {
    const renderOrders = () => {
        if(props.orders.length > 0) {
            return props.orders.map(e => {
                let problem = e.timeline && e.timeline.filter(e => Number(e.status) === 9)[0];
                return <tr>
                        <th>{e.code}</th>
                        <td>{e.id}</td>
                        <td>{e.name}</td>
                        <td><Link to={'/clients/detail/' + e.client.id}>{e.client.name_main}</Link></td>
                        <td>{e.manager.name}</td>
                        <td>{problem && problem.reason && problem.reason !== 'NULL' ? problem.reason : props.getT('Не указано')}</td>
                        <td>
                            <i className="fas fa-thumbs-up text-success" title={props.getT('Проблема решена')} onClick={() => {
                                gaEvents.customEvent('klik_form', {
                                    order_change_status: 'change status'
                                })
                                props.updateOrder(e.id, {status: 4}, 9)
                            }}></i>
                        </td>
                    </tr>
                })
        } else {
            return <tr>
                <td colSpan={9}>
                    <p className={'text-center'}>{props.getT("Заказы еще не созданы")}</p>
                </td>
            </tr>
        }
    }

    return(
        <div className="content-wrapper">
            <PageTitle title={'orders_problems'}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive">
                                <table className="table m-0 table-striped table-hover  orders-table-main">
                                    <thead>
                                    <tr>
                                        <th>{props.getT("Код заказа")}</th>
                                        <th>{props.getT("Тех. ID")}</th>
                                        <th>{props.getT("Название")}</th>
                                        <th>{props.getT("Клиент")}</th>
                                        <th>{props.getT("Менеджер")}</th>
                                        <th>{props.getT("Проблема")}</th>
                                        <th>{props.getT("Действия")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {renderOrders()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}

export default withLang(OrdersProblem);
