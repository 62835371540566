import React from 'react';
import {LS_USER_ID, LS_WORKER_ID} from "../../utils/constants";
import InsufficientAccessRights from "./component/InsufficientAccessRights";

/**
 * ProtectedOnlyUser. проверка на доступность только пользователю, работнику доступ запрещен
 *
 * @returns  {jsx}  -
 */
const ProtectedOnlyUser = ({children}) => {
    const is_user = localStorage.getItem(LS_USER_ID);
    const is_worker = localStorage.getItem(LS_WORKER_ID);
    return (
        <>
            {!!Number(is_user) && !Number(is_worker)  ? children : <InsufficientAccessRights/>}
        </>
    );
};

export default ProtectedOnlyUser;