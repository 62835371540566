import React, {useCallback, useEffect, useMemo} from 'react';
import ContainerContent from "../../components/ui/ContainerContent";
import PageTitle from "../../components/PageTitle/PageTitle";
import {useDispatch, useSelector} from "react-redux";
import {getGeneralGoodsServiceType, getSearchOrders} from "../../store/selectors/salary_project_selector";
import {getGeneralGoodsServiceTypeThunk} from "../../store/thunks/salary_project/general_thunk";
import "./main.css"
import {getClientsList, getOrderStatus} from "../../store/selectors/main_selector";
import {generateOrderDetailDataSheetPrintThunk} from "../../store/thunks/order_detail_thunk";
import {getSearchOrdersByFilterThunk} from "../../store/thunks/salary_project/search_orders_thunk";
import {SEARCH_ORDER_INITIAL_VALUES} from "../../store/reducers/SalaryProjectReducer";
import OrderSearchFilter from "./component/OrderSearchFilter";
import SearchOrders from "./component/SearchOrders";

//** страница отметки улсуг по смене*/
const SalaryProjectSearchOrder = ({getT,}) => {
    const dispatch = useDispatch();
    const search_orders = useSelector(getSearchOrders);
    const order_status_state = useSelector(getOrderStatus);
    const clients_state = useSelector(getClientsList);
    const general_goods_service_type = useSelector(getGeneralGoodsServiceType);
    useEffect(() => {
        dispatch(getGeneralGoodsServiceTypeThunk());
        dispatch(getSearchOrdersByFilterThunk({...SEARCH_ORDER_INITIAL_VALUES.filter}))
    }, [dispatch]);


    const goods_service_type = useMemo(() => {
        if (!general_goods_service_type) return [];
        return general_goods_service_type?.data
    }, [general_goods_service_type, search_orders])


    //** получить заказы по фильтру*/
    const handlerGetOrders = useCallback((filter, working_group = null) => {

        dispatch(getSearchOrdersByFilterThunk({...filter}))
    }, [dispatch, search_orders]);


    //open modal checked service
    const handlerSheetForm = (order_id) => e => {
        dispatch(generateOrderDetailDataSheetPrintThunk({order_id: order_id}))
    }


    return (
        <ContainerContent>
            <PageTitle title={"search_orders"}/>
            <div className="container-fluid ">
                <div className="row">


                    <div className="col-12">
                        <OrderSearchFilter getT={getT} order_status={order_status_state} clients={clients_state}
                                           goods_service_data={goods_service_type}
                                           filter={search_orders?.filter} handlerGetOrders={handlerGetOrders}/>
                    </div>
                    <div className="col-12 col-xl-12">
                        <SearchOrders  getT={getT}
                                       search_orders={search_orders} handlerGetOrders={handlerGetOrders}
                                      handlerSheetForm={handlerSheetForm}/>

                    </div>

                </div>
            </div>
        </ContainerContent>
    );
};

export default SalaryProjectSearchOrder;