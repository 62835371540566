import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch} from "react-redux";
import {readyMarkPartForOrderChoiceServiceModalAC} from "../../../../../store/actions/SalaryProject";

/**
 * Селект выбора типа услги.
 *
 * @param {function} getT  -перевод
 * @param {array} service_finalized  - типы услуг в заказе  отмечены
 * @param {array} service  -  услуг в заказе
 * @param {array} service_type  - типы услуг в заказе
 * @param {object} form_value  - значение выбраной услуги и типы услг
 * @param {object} form_value.service_type_add  - значение выбраной  тип услги
 * @param {object} form_value.service_id  - значение выбраной услуги
 * @param {object} form_value.service_id  - значение выбраной услуги

 * @returns  {jsx}  -
 */
const SelectTypeService = ({ service_type, service_finalized, service, form_value, getT}) => {
    const dispatch = useDispatch();

    const select_service_type = useMemo(() => {
        return service_type.map(item => {
            let is_finalized = service_finalized?.some(fin => Number(fin?.service_id) === Number(item?.goods_service_type_id) && !!Number(fin.finalized))
            return {
                ...item, is_finalized: is_finalized
            }
        })
    }, [service_type, service_finalized]);
    const select_service = useMemo(() => {
        return service.filter(item => Number(form_value.service_type_add) === Number(item?.service_type))
    }, [service, form_value.service_type_add]);
    const is_choice_service_type_complete = useMemo(()=>{
        if(!service_finalized) return false
        return service_finalized?.find(ready=> Number(ready?.service_id) === Number(form_value.service_type_add) && !!Number(ready.finalized))
    }, [service_finalized, form_value.service_type_add])
    const handlerChangeServiceTypeValue = (e) => {
        let value = e.target.value;
        dispatch(readyMarkPartForOrderChoiceServiceModalAC({
            service_id: '',
            service_type_add: value
        }))
    }
    const handlerChangeServiceValue = (e) => {
        let value = e.target.value;
        dispatch(readyMarkPartForOrderChoiceServiceModalAC({
            service_id: value,
            service_type_add: form_value.service_type_add
        }))
    }

    return (
        <>
            <div className={"form-group"}>
                <label>{getT("Тип услуги")}</label>
                <select name="" id="" className={`form-control ${is_choice_service_type_complete ? 'text-green' : ' '}`} value={form_value.service_type_add}
                        onChange={handlerChangeServiceTypeValue}>
                    <option value={''}> ---------</option>
                    {select_service_type?.map(item => {
                        return <option value={item.goods_service_type_id} className={`${item.is_finalized ? 'text-green' : ''}`}>{item?.name} {item.is_finalized && `(${getT("отмечено")})`}</option>
                    })}
                </select>
            </div>
            {!!select_service?.length && <div className={"form-group"}>
                <label>{getT("Услуга")}</label>
                <select name="" id="" className={'form-control'} value={form_value.service_id}
                        onChange={handlerChangeServiceValue}>
                    <option value={''}> ---------</option>
                    {select_service?.map(item => {
                        return <option value={item.service_id}>{item?.name}</option>
                    })}
                </select>
            </div>}
        </>
    );
};

export default SelectTypeService;