import {instances, URI_API_EXTTUNELL} from "../Salary/setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const ws_client_connection_set = 'ws_client_connection_set'
const ws_client_connection_delete = 'ws_client_connection_delete'
const ws_client_connection_get = 'ws_client_connection_get'

export const WholesaleClientConnectionAPI = {
    set_connect(data = {}) {
        return instances.post(URI_API_EXTTUNELL + '/', data, {
            headers: {
                action: ws_client_connection_set,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    delete_connect(form_data = {}) {
        return instances.post(URI_API_EXTTUNELL + '/', form_data, {
            headers: {
                action: ws_client_connection_delete,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    get_all (){
        return instances.post(URI_API_EXTTUNELL + '/', null, {
            headers: {
                action: ws_client_connection_get,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }
}