function NoImage(props) {
  return (
    <svg id="Capa_1" enableBackground="new 0 0 450.824 450.824" height="34" viewBox="0 0 450.824 450.824" width="34"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="m390.345 116.833c5.502 5.502 5.502 14.422 0 19.924s-14.422 5.502-19.924 0-5.502-14.422 0-19.924 14.422-5.502 19.924 0"/>
      <path
        d="m408.559 56.354h-72.849c-5.82-16.4-21.49-28.176-39.857-28.176h-140.882c-18.102 0-33.492 11.491-39.506 27.523l22.668 22.668c1.603-2.287 2.75-4.919 2.75-7.926 0-7.773 6.315-14.088 14.088-14.088h140.882c7.773 0 14.088 6.315 14.088 14.088 0 7.787 6.301 14.088 14.088 14.088h84.529c7.773 0 14.088 6.315 14.088 14.088v253.588c0 3.003-1.151 5.634-2.755 7.921l20.082 20.082c6.669-7.474 10.85-17.22 10.85-28.003v-253.589c0-23.306-18.958-42.264-42.264-42.264z"/>
      <path
        d="m329.161 269.397c5.756-13.524 8.956-28.385 8.956-43.985 0-62.145-50.561-112.706-112.706-112.706-15.6 0-30.46 3.2-43.985 8.956l22.371 22.371c6.941-1.849 14.097-3.151 21.614-3.151 46.612 0 84.529 37.917 84.529 84.529 0 7.517-1.302 14.673-3.151 21.614.001.001 22.372 22.372 22.372 22.372z"/>
      <path
        d="m0 19.923 36.966 36.966c-20.785 2.638-36.966 20.238-36.966 41.73v253.588c0 23.306 18.959 42.265 42.265 42.265h332.285l56.353 56.353 19.922-19.922-430.903-430.903zm42.265 346.373c-7.773 0-14.088-6.315-14.088-14.088v-253.589c0-7.773 6.315-14.088 14.088-14.088h22.343l72.397 72.397c-14.876 19.038-24.298 42.507-24.298 68.486 0 62.145 50.561 112.706 112.706 112.706 25.982 0 49.51-9.357 68.558-24.226l52.403 52.403zm114.738-189.369 116.971 116.971c-13.772 9.882-30.35 16.045-48.562 16.045-46.612 0-84.529-37.917-84.529-84.529-.001-18.208 6.235-34.722 16.12-48.487z"/>
    </svg>
    // <svg
    //   width={32}
    //   height={32}
    //   viewBox="0 0 32 32"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <path d="m390.345 116.833c5.502 5.502 5.502 14.422 0 19.924s-14.422 5.502-19.924 0-5.502-14.422 0-19.924 14.422-5.502 19.924 0"/><path d="m408.559 56.354h-72.849c-5.82-16.4-21.49-28.176-39.857-28.176h-140.882c-18.102 0-33.492 11.491-39.506 27.523l22.668 22.668c1.603-2.287 2.75-4.919 2.75-7.926 0-7.773 6.315-14.088 14.088-14.088h140.882c7.773 0 14.088 6.315 14.088 14.088 0 7.787 6.301 14.088 14.088 14.088h84.529c7.773 0 14.088 6.315 14.088 14.088v253.588c0 3.003-1.151 5.634-2.755 7.921l20.082 20.082c6.669-7.474 10.85-17.22 10.85-28.003v-253.589c0-23.306-18.958-42.264-42.264-42.264z"/><path d="m329.161 269.397c5.756-13.524 8.956-28.385 8.956-43.985 0-62.145-50.561-112.706-112.706-112.706-15.6 0-30.46 3.2-43.985 8.956l22.371 22.371c6.941-1.849 14.097-3.151 21.614-3.151 46.612 0 84.529 37.917 84.529 84.529 0 7.517-1.302 14.673-3.151 21.614.001.001 22.372 22.372 22.372 22.372z"/><path d="m0 19.923 36.966 36.966c-20.785 2.638-36.966 20.238-36.966 41.73v253.588c0 23.306 18.959 42.265 42.265 42.265h332.285l56.353 56.353 19.922-19.922-430.903-430.903zm42.265 346.373c-7.773 0-14.088-6.315-14.088-14.088v-253.589c0-7.773 6.315-14.088 14.088-14.088h22.343l72.397 72.397c-14.876 19.038-24.298 42.507-24.298 68.486 0 62.145 50.561 112.706 112.706 112.706 25.982 0 49.51-9.357 68.558-24.226l52.403 52.403zm114.738-189.369 116.971 116.971c-13.772 9.882-30.35 16.045-48.562 16.045-46.612 0-84.529-37.917-84.529-84.529-.001-18.208 6.235-34.722 16.12-48.487z"/>
    // </svg>
  );
}

export default NoImage;
