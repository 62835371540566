import React, {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getWSOrdersSupply} from "../../store/selectors/wholesalary_orders_supply_selector";
import {
    wsChangeTypeMainFirmRequestOrdersSupply,
    wsDeleteOrderSupplyById,
    wsGetOrdersSupplyThunk
} from "../../store/thunks/wholesale/order_supply/orders_supply_thunk";
import PageTitle from "../../components/PageTitle/PageTitle";
import ContainerContent from "../../components/ui/ContainerContent";
import OrderSupplySetModal from "./components/Modal/OrderSupplySetModal";
import {getWSConnectionClientThunk} from "../../store/thunks/wholesale/connection_client_thunk";
import {wsSetOrderSupplyModalAction} from "../../store/actions/WholesaleOrdersSupplyActions";
import OrdersSupplyTable from "./components/OrdersSupply/OrdersSupplyTable";
import {getGeneralOrdersStatuses} from "../../store/selectors/salary_project_selector";
import OrderSupplyFilter from "./components/OrderSupplyFilter";
import {getGeneralOrdersStatusesThunk} from "../../store/thunks/salary_project/general_thunk";
import Pagination from "../../components/Pagination/Pagination";
import {getWSConnectionPartnerFirms, getWSConnectionWholesaleFirms} from "../../store/selectors/wholesalary_selector";
import OrderSupplyTab from "./components/OrderSupplyTab";


/**
 * Компонент страницы "Заказы".
 * Отображает список заказов для поставщика или от клиентов (партнёров фирмы) в зависимости от фильтра.
 *
 * @var {boolean} isMainFirmRequestType =  store.orderSupply.filter.main_firm_request
 * @var {number} store.orderSupply.filter.main_firm_request - Определяет тип заказов для отображения:
 *   0 - получить заказы, сделанные фирмой для своих поставщиков.
 *   1 - получить заказы, полученные фирмой от своих клиентов (партнёров).
 *
 * @param {function} getT - Функция для локализации текста.
 *
 * @returns {JSX.Element} - Отрендеренный компонент страницы заказов.
 */
const WsOrdersSupplyPage = ({getT}) => {
    const dispatch = useDispatch();
    const ordersSupply = useSelector(getWSOrdersSupply);
    const orders_statuses = useSelector(getGeneralOrdersStatuses);
    const connectionWholesale = useSelector(getWSConnectionWholesaleFirms);
    const connectionPartner = useSelector(getWSConnectionPartnerFirms);


    useEffect(() => {
        dispatch(getGeneralOrdersStatusesThunk());
        dispatch(getWSConnectionClientThunk());
        dispatch(wsGetOrdersSupplyThunk({status: 1, offset: 0, main_firm_request: 0}))
    }, []);

    /** Определяет, являются ли заказы текущего типа (от клиентов = 1) (от поставщиков = 0)*/
    const isMainFirmRequestType = useMemo(() => {
        return !!Number(ordersSupply?.filter?.main_firm_request);

    }, [ordersSupply])
    /**
     * @var {number}  getTypeOrderSupply - 0 - мои заказы для поставщика 1- заказы Покупателя (где покупатель фирма Партнёр)
     */
    const connectionFirms = useMemo(() => {
        let getTypeOrderSupply = Number(ordersSupply?.filter?.main_firm_request);
        let orders_for_current_firm_as_partner = ordersSupply.orders_for_current_firm_as_partner;
        let orders_for_current_firm_as_main_firm = ordersSupply.orders_for_current_firm_as_main_firm;
        if (getTypeOrderSupply === 0) {
            return Array.isArray(orders_for_current_firm_as_partner) ? orders_for_current_firm_as_partner : null
        }
        if (getTypeOrderSupply === 1) {
            return Array.isArray(orders_for_current_firm_as_main_firm) ? orders_for_current_firm_as_main_firm : null
        }
        return null;
    }, [ordersSupply])
    const onCreateOrderSupplyModal = ()=>{
        dispatch(wsSetOrderSupplyModalAction({isOpen: true, orderCurrent: null}))
    }
    const onUpdateOrderSupplyModal = (orderCurrent)=>{
        dispatch(wsSetOrderSupplyModalAction({isOpen: true, orderCurrent: orderCurrent}))
    }
    const onOpenCreateAndUpdateOrderSupplyModal = (orderCurrent = null) => {
        dispatch(wsSetOrderSupplyModalAction({isOpen: true, orderCurrent: orderCurrent}))
    }
    const onOrderSupplyDeleteById = (ws_orders_supply_id) => {
        dispatch(wsDeleteOrderSupplyById(ws_orders_supply_id));
    }
    const onSendGetOrdersSupplyByFilter = (data) => {
        let offset = ordersSupply?.filter?.offset;
        dispatch(wsGetOrdersSupplyThunk({...data, offset: offset}))
    }
    const handlerChangePage = (page) => {
        let filter = ordersSupply?.filter;
        let offset = (Number(page) - 1) * Number(filter?.limit);
        dispatch(wsGetOrdersSupplyThunk({
            ...filter,
            offset: offset
        }))
    }
    const handleChangeMainFirmRequest = useCallback((main_firm_request) => {
        dispatch(wsChangeTypeMainFirmRequestOrdersSupply(main_firm_request));
    }, [ordersSupply]);
    const currentPage = useMemo(() => {
        let offset = !!Number(ordersSupply.offset) ? Number(ordersSupply.offset) : 50;
        return (Number(ordersSupply?.filter?.limit) / offset);
    }, [ordersSupply])
    return (
        <ContainerContent>
            <PageTitle title={"ws_orders_supply"}/>

            <div className="d-flex justify-content-end px-3 mb-3">
                {!isMainFirmRequestType && <button className={"btn btn-success"}
                                                   onClick={onCreateOrderSupplyModal}>{getT("Создать заказ")}</button>
                }
            </div>
            <div className="card  py-1 px-3">
                <OrderSupplyFilter connectionFirms={connectionFirms}
                                   isMainFirmRequestType={isMainFirmRequestType}
                                   orders_statuses={orders_statuses} getT={getT} filter={ordersSupply?.filter}
                                   onSend={onSendGetOrdersSupplyByFilter}/>
            </div>

            <OrderSupplyTab getT={getT} handleChangeMainFirmRequest={handleChangeMainFirmRequest}
                            isMainFirmRequestType={isMainFirmRequestType}/>
            <div className="card py-3 px-3">
                <OrdersSupplyTable getT={getT} orders={ordersSupply?.data}
                                   isMainFirmRequestType={isMainFirmRequestType}
                                   handleOrderSupplyEdit={onUpdateOrderSupplyModal}
                                   handleOrderSupplyDelete={onOrderSupplyDeleteById}

                />
            </div>
            <Pagination page={currentPage} pageSize={Number(ordersSupply?.filter?.limit)}
                        count={Number(ordersSupply?.filter?.total)}
                        changeHandler={handlerChangePage}/>

            <OrderSupplySetModal/>
        </ContainerContent>
    );
};

export default WsOrdersSupplyPage;