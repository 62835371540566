import header from "./ShortOrderForm/header"
import short_order_view from "./ShortOrderForm/short_order_view";
import footer from "./ShortOrderForm/footer";
import packing_view from "./ShortOrderForm/packing_view";
import ready_service_view from "./ShortOrderForm/ready_service_view";
import processing_edge_calculate from "./ProcessingEdge/processing_edge_calculate";
import processing_paint_calculate from "./ProcessingEdge/processing_paint_calculate";

export class ShortOrderHtml {
    constructor() {
    }

    generateContentHtml({order, full = false, logo, order_mark = null, is_pack = false, is_service = false}) {
        console.log(order, 'order')
        return `
       <div class="container">
      ${header({order, logo})}
     ${short_order_view({order, full: full, order_mark: order_mark, is_pack: is_pack, is_service: is_service})}
${is_pack ? packing_view({order_mark: order_mark}) : ''}    
${is_service ? ready_service_view({order_mark: order_mark}) : ''}     
${full && !Array.isArray(order?.calculate) ? processing_edge_calculate({order}) : ''}     
 ${full && !Array.isArray(order?.calculate) ? processing_paint_calculate({order}) : ''}    
${footer({order})}
   </div>
    `
    }
}