import getT from "../../getT";
import { calculateEdge, calculateSide, getSidesAllOperation, isCurveSide } from "../../helpers";

const searchPartIdOnLetter = (edging, letter) => {

  return edging.filter(item => {
    for (const [side, value] of Object.entries(item)) {
      if (!!side) {
        for (const [key, value] of Object.entries(item[side])) {
          if (value.hasOwnProperty(letter) || key === letter) {
            return item.part_id;
          }
        }
      }
    }
  })
};

export default function({
                          order, cutting, isVisible
                        }) {
  if (!isVisible) return ``;
  const lang = localStorage.getItem("lang");
  const { cards, edging, edging_total } = cutting;
  if (!Array.isArray(edging_total) || !Array.isArray(edging)) {
    console.info("not array", edging_total, edging);
    return ``;
  }
  if (edging_total.length === 0 || edging.length === 0) {
    console.info("array length === 0", edging_total, edging);
    return ``;
  }

  return `
   <div class="" style="margin-bottom: 50px">
            <h4 class="">${getT("Кромки")}</h4>
<!--            style="page-break-inside: avoid;"-->
            <table class="table table-bordered" >
            
                   <tbody>
                   <tr class="table-active">
                        <th scope="col">${getT("Обозначение")}</th>
                        <th scope="col">${getT("id")}</th>
                        <th scope="col">${getT("Название")}</th>
                        <th scope="col">${getT("Количество")}</th>
                        <th></th>
                        <th></th>
                        <th></th>
                </tr>
     ${EdgeDetailItem({ edging_total,edging, cards, lang, part: order.part })}
               </tbody>
                </table>

        </div>`
}

function EdgeDetailItem({ edging_total, cards, lang, part, edging }) {
  const tbody = edging_total.map(edge => {
    return `<tr class="table-warning">
            <th scope="row">${edge.letter} </th>
            <td>${edge.goods_id}</td>
            <td>${edge.hasOwnProperty("name") &&  edge.name.hasOwnProperty(lang) ? edge.name[lang] : edge?.name ? edge.name : ""}</td>
            <td>${edge.count} ${!!edge.count ? "m" : ""}</td>
         
            ${DetailPartTable({ part, edging, letter: edge.letter, edge, lang })}
        </tr>  
       
`});


  // return ;

  return tbody?.join("")


}

function DetailPartTable({ part, edge, letter, lang, edging }) {
  const partsEdge = searchPartIdOnLetter(edging, letter);
  return `
<tr>
<td  colspan="12" style="padding: 0px">
            <tr class=''>
                <th scope="col" colspan="1">id</th>
                <th scope="col">${getT('Количество')}</th>
                <th scope="col">${getT('Название')}</th>
                <th scope="col">${getT('Расход кромки')}</th>
                <th scope="col">x</th>
                <th scope="col">y</th>
                <th scope="col">z</th>
                
            </tr>
            
            ${Array.isArray(partsEdge) && part.length >=1 && partsEdge.map((detail, key) => {
                const partFind = part.filter((item) => Number(item.id) === Number(detail.part_id))[0];
                return DetailPartItem({part: partFind, edge: detail, letter})
            })?.join("")}
  </td>
</tr>
  `
}

function DetailPartItem({part, edge, letter}){
    if(!part) return ''
return`
            <tr>
                <th scope="row"><b>${part.id}</b></th>
                 <td>${part?.count ?? ''}</td>
                <td>${part?.name ?? ''}</td>
                <td>
${calculateEdge({edge:edge, letter, operation: 'edge', isCurrentType: false})}m 

</td>
                <td>${part?.x} </td>
                <td>${part?.y}</td>
                <td>${part?.z}</td>
               
               
            </tr>
            ${DetailPartOpen({sidesEdge:edge, edge:edge, part, letter})}
`}
function DetailPartOpen({sidesEdge, edge, part, letter}){
  let countEdge = calculateEdge({edge, letter, operation:'edge', isCurrentType:false})
const sides = getSidesAllOperation({edge:sidesEdge, operation:'edge', letter})
  return `
    <tr>
    <td colspan="12" style="padding:0px">
            <table class="table"  style="margin:0px">
                    <th align="left" scope="col">
                        ${getT("Деталь")} (id=${part.id}) 
                     <span style={"text-transform:lowercase"}>
                      [${sides.t ? (getT("Верхняя") + ',' ): ''}
                       ${sides.b ? (getT("Нижняя") +',') : ''} 
                       ${sides.l ? (getT("Левая") + ','): ''}
                       ${sides.r ? getT("Правая") : ''}]
                    </span>
                     (  ${part.x} * ${part.y }* ${part.z})  ${part.count} ${getT("шт")} ${getT(" расход ")} ${countEdge} m
                    </th>
                
                ${EdgeConsumption({title: getT(`Расход кромки при кромковании`), edge, type: 'edge', letter})}
                ${EdgeConsumption({title: getT(`Расход кромки при кромковании фрезерного реза`), edge, type: 'frez_edge', letter})}
                ${EdgeConsumption({title: getT(`Расход кромки при кромковании торца`), edge, type: 'srez_edge', letter})}
               
                ${edge.hasOwnProperty('edge_part_double') && edge.edge_part_double.hasOwnProperty(letter) ?
                      `  <tr>
                            <th align='left'>${getT('Кромкования задней стороны сшивки')} ${edge.edge_part_double[letter]} m</th>
                            <th></th>
                        </tr>
                        `
                 : ''
                }
            </table>
            </td>
    </tr>
  `
}

function EdgeConsumption({title, edge, letter, type}){
  const count = calculateEdge({edge, operation: type, letter, isCurrentType: true});
  return `
   <tr>
        <th align='left' scope="row">
            ${title} ${count} m
        </th>
            ${Number(count > 0) ?
    `
<tr>
<td colspan="12" style="padding: 0px">
      <table class="table  table-striped" style="margin: 0px"> 

        <tr>
          <td>${getT("Правая")}</td>
          <td>${calculateSide({edgeSide: edge, letter, operation: type, isCurrentType: true, side:'r' })} m</td>
        </tr>
        <tr>
          <td>${getT('Левая')}</td>
          <td>${calculateSide({edgeSide: edge, letter, operation: type, isCurrentType: true, side:'l' })} m</td>

        </tr>
        <tr>
          <td>${getT('Верхняя')}</td>
          <td>${calculateSide({edgeSide: edge, letter, operation: type, isCurrentType: true, side:'t' })} m</td>

        </tr>
        <tr>
          <td>${getT('Нижняя')}</td>
          <td>${calculateSide({edgeSide: edge, letter, operation: type, isCurrentType: true, side:'b' })} m</td>

        </tr>
      </table>
</td>
</tr>
   `
    : ''}    
   </tr>
   `
}