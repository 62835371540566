function isObject(value) {
    return (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
    );
}
function getGrid (grid){

    if(Number(grid) === 8){
        return `.item{
  flex: 0 0 calc(50% - 10px);
}`
    }
    if(Number(grid) === 32){
        return `.item{
  flex: 0 0 calc(25% - 10px);
}`
    }
    if(Number(grid) === 1){
        return `.item{page-break-after: always; width: 100%;}`
    }
    return  ''

}

export default function({ src, type, grid =''}) {
    return `
<style>
.container.cutting_labels_container {
    display: flex;
    flex-wrap: wrap;
}
  ${getGrid(grid)}
</style>
  <div class="container cutting_labels_container">
  ${Array.isArray(src) ?
        src?.map(item => `<div class="item" style="margin: 20px 5px;" ><img src="${type}${item}" alt="" /></div>`)?.join("") :
        isObject(src) ?
            Object.keys(src).map(function(key, index) {
                return `<div class="item" style="margin: 20px 5px;" ><img src="${type}${src[key]}" alt="" /></div>`;
            })?.join("")

            :
            ` <div class="item" > <img src="${type}${src}" alt=""/></div>`
    }
</div>
  
  `;
}