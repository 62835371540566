import React from "react";
import {connect} from "react-redux";
import {
  changeOrderAddFormThunk,
  changeOrderAddFormValueAC,
  createNewGoOrderThunk,
  createNewOrderThunk,
  getMainSettingsProject,
  setAccessToCreateOrderAddFormAC,
  setClientsDataThunk,
  setLeftoversOrderAddForm
} from "../../store/reducers/MainReducer";
import OrderAdd from "./OrderAdd";
import "./OrderAdd.css";

class OrdersContainer extends React.Component {
  componentDidMount() {
    this.props.getSettingsProject();

  }
  componentWillUnmount(){
    // this.props.checkAuntificationUser()
  }

  render() {
    return (
      <OrderAdd
        createNewgoOrder={this.props.createNewgoOrder}
        clients={this.props.clients}
        createOrder={this.props.createOrder}
        form={this.props.form}
        changeValue={this.props.changeValue}
        settings_project = {this.props.settings_project}
        setLeftovers={this.props.setLeftovers}
        changeAccessToCreateOrder={this.props.changeAccessToCreateOrder}
        onChangeTitleProject={this.props.onChangeTitleProject}
        // code={this.props.settings_project &&  Number(this.props.settings_project['react.phone_code_one']) === 9999 ? '+927' : '+38'}
      />
    );
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    createNewgoOrder: (client_id, title) => dispatch(createNewGoOrderThunk(client_id, title)),
    setClients: (filters = {}) => dispatch(setClientsDataThunk(filters)),
    createOrder: (client_id, title) => dispatch(createNewOrderThunk(client_id, title)),
    changeValue: (input, value) =>
      dispatch(changeOrderAddFormThunk(input, value)),
    getSettingsProject: ()=>
        dispatch(getMainSettingsProject()),
    setLeftovers : (clientId)=>dispatch(setLeftoversOrderAddForm(clientId)),
    changeAccessToCreateOrder : (payload)=> dispatch(setAccessToCreateOrderAddFormAC(payload)),
    onChangeTitleProject : (values)=> dispatch(changeOrderAddFormValueAC("title", values))

  };
};

let mapStateToProps = (state) => {
  return {
    clients: state.main.clients,
    form: state.main.orderAddForm,
    settings_project: state.main.settings_project,

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersContainer);
