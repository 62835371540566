


export const productionTaskType = state => state.main.productionTasksTypes ?? [];
export const productionTaskMaterial =state => state.main.productionTasksMaterials ;
export const orderStatus = state => state.main.orderStatus ;
export const filterForm = state => state.main.productionTasksForm ;


// export  const selectorGroup = {
//     productionTaskType, productionTaskMaterial, orderStatus, filterForm
// }