import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getShiftCreateModal} from "../../../store/selectors/salary_project_selector";
import Modal from "../MyModal/Modal";
import {setShiftCreateModalAC} from "../../../store/actions/SalaryProject";
import {useForm} from "react-hook-form";
import {createFormData} from "../../../utils/helpers";
import {createShiftThunk} from "../../../store/thunks/salary_project/shifh-thunk";
import ShiftFormCu from "./components/Shifts/ShiftFormCU";

const ShiftCreateModal = ({getT}) => {
    const shift_modal_state = useSelector(getShiftCreateModal);
    const dispatch = useDispatch();
    const handlerClose = () => dispatch(setShiftCreateModalAC({isOpen: false}));
    const handlerCreate = (data, reset=null) => {

        const form_data = createFormData(data);
        dispatch(createShiftThunk( form_data))
    }
    const {register, handleSubmit, watch, reset} = useForm({});
    if (!shift_modal_state?.isOpen) return null
    return (
        <Modal title={getT("Создать")} size_modal_class={"modal-md"} onClose={handlerClose}
               open={shift_modal_state.isOpen}>
            <ShiftFormCu handlerSubmit={handlerCreate} getT={getT}/>
        </Modal>
    );
};

export default ShiftCreateModal;