import {instances, URI_API_EXTTUNELL} from "../Salary/setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const ws_get_wholesale_partner_properties = 'ws_get_wholesale_partner_properties'
const ws_set_wholesale_partner_properties = 'ws_set_wholesale_partner_properties'

export const WholesalePartnerPropertiesAPI={
    getPartnerProperties() {
        return instances.post(URI_API_EXTTUNELL + '/', null, {
            headers: {
                action: ws_get_wholesale_partner_properties,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    setPartnerProperties(form_data){
        return instances.post(URI_API_EXTTUNELL + '/', form_data, {
            headers: {
                action: ws_set_wholesale_partner_properties,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }
}