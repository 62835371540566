import React, {forwardRef, useImperativeHandle, useState} from 'react';
import Modal from "../MyModal/Modal";
import AdditionalDocumentForm from "./components/AdditionalDocument/AdditionalDocumentForm";
import {useDispatch} from "react-redux";
import {createAdditionalDocument} from "../../../store/thunks/salary_project/addition_document_thunk";

const SalaryAdditionalDocumentCreate = forwardRef(({getT, refModal}) => {
    const dispatch = useDispatch()
    const [modal_dt, setModalDt] = useState({isOpen:false, shit_id: null});
    useImperativeHandle(refModal, () => {
        return {
            open(shift_id) {
                setModalDt({isOpen: true, shit_id: shift_id});
            }
        };
    }, []);
    const handlerCloseModal = ()=>{
        setModalDt({isOpen: false, shit_id: null})
    }
    const handlerSubmitForm = (values)=>{
        dispatch(createAdditionalDocument({...values, shift: modal_dt.shit_id}, handlerCloseModal))
    }
    return (
        <Modal open={modal_dt.isOpen} onClose={handlerCloseModal} title={getT("Создать документ")}>
            <AdditionalDocumentForm getT={getT} handlerSubmitForm={handlerSubmitForm} initial_form_value={null}/>
        </Modal>
    );
});

export default SalaryAdditionalDocumentCreate;