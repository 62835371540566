import React from "react";
import Modal from "../MyModal/Modal";

const LabelsPrintCount = ({isOpen, onClose, onHandlerSubmit, grid, getT, onChange}) => {
    if(!isOpen) return <></>
    return (
        <Modal onClose={onClose} open={isOpen} title={getT("Этикетки печать")} size_modal_class={"modal-md"}>
            <div className="pl-3 pr-3  w-100">
                <div className="" style={{width: '100%'}}>
                    <div className="form-field">
                        <label className={'label'}>{getT('Выберите количество на листе')}</label>
                        <ul className="filter-switch">
                            <li>
                                <input type="radio" id="def" name="grid" value="1" checked={Number(grid) === 1} onChange={(e)=>onChange({grid: 1})}/>
                                <label htmlFor="def">1</label>
                            </li>
                            <li>
                                <input type="radio" id="small" name="grid" value="8" checked={Number(grid) === 8} onChange={(e)=>onChange({grid: 8})}/>
                                <label htmlFor="small">8</label>
                            </li>
                            <li>
                                <input type="radio" id="big" name="grid" value="32" checked={Number(grid) === 32} onChange={(e)=>onChange({grid: 32})}/>
                                <label htmlFor="big">32</label>
                            </li>
                        </ul>

                    </div>

                    <button onClick={()=> onHandlerSubmit()} className={"btn-primary w-100 pt-2 pb-2"}  style={{margin: '15px 0px 0px 0px'}}>{getT('Отправить')}</button>
                </div>
            </div>

        </Modal>
    );
};

export default LabelsPrintCount;