import React, {useState} from 'react';

const AccordionMaterialService = ({lang, data = [], title = '', getT, reversePrice = false}) => {
    const [isOpen, setOpen] = useState(false);

    return (
        <>
            {
                Array.isArray(data) && data?.length >= 1 ?
                    <>
                        <tr>
                            <td colSpan={'8'} scope={'colgroup'}
                                style={{border: 'none', cursor: 'pointer'}}
                                onClick={() => setOpen(!isOpen)}>
                                {title}
                                <i className={`fas fa-angle-left`}
                                   style={{
                                       marginLeft: '10px',
                                       transform: isOpen ? ' rotate(90deg)' : 'rotate(-90deg)'
                                   }}
                                ></i>
                            </td>
                        </tr>

                        {isOpen &&
                            <tr>
                                <td colSpan={'8'} style={{border: 'none', padding: '0px'}}>
                                    <table width={'100%'} style={{border: 'none', padding: '0px'}}>
                                        <thead>
                                        <th>{getT('Название')}</th>
                                        <th>{getT('Стоимость')}</th>
                                        <th>{getT('Кол-во')}</th>
                                        <th>{getT('Сумма')}</th>
                                        </thead>
                                        <tbody>
                                        {data && data.map(d => {
                                            return <tr>
                                                <td data-label={getT('Название')}>{d?.name}</td>
                                                <td data-label={getT('Стоимость')}>{reversePrice ? d?.cost?.toFixed(2) : d?.price?.toFixed(2)}
                                                    {/*UAH*/}
                                                </td>
                                                <td data-label={getT('Кол-во')}>{d?.count}</td>
                                                <td data-label={getT('Сумма')}>{reversePrice ? d?.price?.toFixed(2) : d?.cost?.toFixed(2)}
                                                    {/*UAH*/}
                                                </td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        }
                    </>
                    :
                    <tr>
                        <td colSpan={'8'}>{title} {getT('отсутствуют')}</td>
                    </tr>
            }
        </>
    );
};

export default AccordionMaterialService;