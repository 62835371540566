import React from "react";
import {withLang} from "../../../hoc/withLang";

const SetProblem = (props) => {
  return (
    <div className={"modal fade show"} id="modal-lg">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{props.getT("Укажите причину/проблему")}</h4>
            <button
              type="button"
              className="close"
              onClick={() => props.toggleSetProblem(false, null)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <textarea
                cols="30"
                rows="10"
                className={"form-control"}
                value={props.reason}
                minLength={5}
                onChange={(event) => props.changeReason(event.target.value)}
              />
              {props.reason && Number(props.reason.length) < 5 ? (
                <p className={"text-danger"}>
                  {" "}
                  {props.getT(" Введите не менее 5 символов описания проблемы")}{" "}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="modal-footer justify-content-between">
            <button
              className="btn btn-success"
              onClick={() => props.sendProblem(props.order, props.reason)}
            >
              {props.getT("Отправить")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLang(SetProblem);
