export const AUTH_SET_WORKER = "AUTH/SET_WORKER";
export const SET_USER_AUTH = "SET_USER_AUTH";
// payment_additional
export const SET_AUTH_PAYMENT_ADDITIONAL = "SET/AUTH_PAYMENT_ADDITIONAL";
export const CHANGE_AUTH_FORM_VALUE = "CHANGE_AUTH_FORM_VALUE";

export const setAuthWorkerAC = (payload) => {
    return {
        type: AUTH_SET_WORKER,
        payload: payload
    }
}
export const setAuthPaymentAdditionalAC = (payload) => {
    return {
        type: SET_AUTH_PAYMENT_ADDITIONAL,
        payload: payload
    }
}