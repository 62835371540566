import React, {useCallback, useEffect, useState} from 'react';
import useDebounce from "../../../../hook/useDebounce";

const InputField = ({initialValue, onChangeHandler, ...reset}) => {
    const [value, setValue] = useState(`${initialValue ?? ''}`);
    const [print, setPrint] = useState(initialValue);
    const printDebounce = useDebounce(value, 3000);
    useEffect(() => {
        if (onChangeHandler && printDebounce !== print) {
            onChangeHandler(value)
        }
    }, [printDebounce])

    useEffect(()=>{
        if(initialValue !== print){
            setValue(initialValue);
            setPrint(initialValue);
        }
    }, [initialValue, print])
    const handleChange = useCallback((event) => {
        let value = event.target.value

        setValue(value);
    }, []);
    const handleBlur = useCallback(() => {
        // In case there's any remaining value to be dispatched after debouncing
        if (onChangeHandler && value !== initialValue) {
            onChangeHandler(value)
        }
    }, [value, onChangeHandler, initialValue]);
    return (
        <input
            className="form-control"
            placeholder=""
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            {...reset}
        />
    );
};

export default InputField;