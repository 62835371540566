export const maskPhoneCountry = {
    "+38 ": "(999) 999-99-99",
    "+972 ": "99-999-99-99",
    "+373 ": "9999-9999",
    "+40 ": "-99-999-9999",
    "+359": "(999) 999-999",
    "+48 ": "(999) 999-999",
    "+36 ": "(999) 999-999",
    "+7 ": "(999) 999-99-99",

    "+49 ": "(999) 999-99-99",
    "+421 ": "(999) 999-999",
    "+371 ": "9999-9999",
    "+370 ": "(999) 99-9999",
    "+372 ": "9999-9999",
    "+43 ": "999-999-9999"
}
