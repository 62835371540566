import React from "react";
import {connect} from "react-redux";
import {
  changeOrdersFilterFormValueAC,
  createNewOrderThunk,
  getMainSettingsProject,
  getManagersDataThunk,
  getOrdersFilteredDataThunk,
  onClearCanceledTunck,
  setClientsDataThunk,
  setOrdersDataThunk,
  toggleOrderChangeStatusAC,
  updateOrderDataThunk
} from "../../store/reducers/MainReducer";
import OrdersShipped from "./OrdersShipped";

class OrdersShippedContainer extends React.Component {
  componentDidMount() {
    this.props.setOrders({ limit: 1, status: 7 });
    this.props.setClients(false);
    this.props.setManagers();
    this.props.updateSettingsAC()
  }
  componentWillUnmount(){
    // this.props.checkAuntificationUser()
  }
  render() {
    return (
      <OrdersShipped
        orders={
          this.props.archive
            ? this.props.orders_archive
            : this.props.orders_actual
        }
        title={"orders_shipped"}
        changeFilterValue={this.props.changeFilterValue}
        toggleChangeStatus={this.props.toggleChangeStatus}
        updateOrder={this.props.updateOrder}
        form={this.props.filterForm}
        status={this.props.orderStatus}
        clients={this.props.clients}
        changedStatus={this.props.changedStatus}
        createOrder={this.props.createOrder}
        userRoles={this.props.user.role}
        onClear={this.props.clearDt}
      />
    );
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    setOrders: (filters = null) => dispatch(setOrdersDataThunk(filters)),
    changeFilterValue: (input, value, filters) =>
      dispatch(getOrdersFilteredDataThunk(input, value, filters)),
    setClients: (withLoader) => dispatch(setClientsDataThunk(withLoader)),
    toggleChangeStatus: (id) => dispatch(toggleOrderChangeStatusAC(id)),
    setManagers: () => dispatch(getManagersDataThunk()),
    updateOrder: (order_id, data) =>
      dispatch(updateOrderDataThunk(order_id, data, 7)),
    createOrder: (client_id) => dispatch(createNewOrderThunk(client_id)),
    setNullFilter: (input, value) =>
      dispatch(changeOrdersFilterFormValueAC(input, value)),
    clearDt: (params)=> dispatch(onClearCanceledTunck(params)),
    updateSettingsAC: () => dispatch(getMainSettingsProject()),
  };
};

let mapStateToProps = (state) => {
  return {
    orders_actual: state.main.orders.actual,
    orders_archive: state.main.orders.archive,
    filterForm: state.main.ordersForm,
    orderStatus: state.main.orderStatus,
    managers: state.main.managers,
    clients: state.main.clients,
    changedStatus: state.main.ordersChangeStatus,
    user: state.auth.user
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrdersShippedContainer);
