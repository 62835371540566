import React from "react";
import SetProblem from "./SetProblem";
import {connect} from "react-redux";
import './SetProblem.css';
import {
    changeProblemModalReasonAC,
    setProblemModalUpdateThunk,
    toggleSetProblemModalAC
} from "../../../store/reducers/MainReducer";

class SetProblemContainer extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return this.props.isOpen ? <SetProblem reason={this.props.reason}
                                               order={this.props.order}
                                               changeReason={this.props.changeReason}
                                               toggleSetProblem={this.props.toggleSetProblem}
                                               sendProblem={this.props.sendProblem}
        /> : '';
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeReason: (reason) => dispatch(changeProblemModalReasonAC(reason)),
        toggleSetProblem: (status, order) => dispatch(toggleSetProblemModalAC(status, order)),
        sendProblem: (order_id, reason) => dispatch(setProblemModalUpdateThunk(order_id, reason))
    }
}

let mapStateToProps = (state) => {
    return {
        isOpen: state.main.setProblem.isOpen,
        reason: state.main.setProblem.reason,
        order: state.main.setProblem.order,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SetProblemContainer);