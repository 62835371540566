import React from 'react';
import {connect} from "react-redux";
import ClientsAdd from "./ClientsAdd";
import './Clients.css';
import {
    changeClientsFormValueAC,
    getMainSettingsProject,
    submitClientFormThunk
} from "../../store/reducers/MainReducer";

class ClientsAddContainer extends React.Component {
    componentDidMount() {
        this.props.getSettingsProject();
        if(this.props.settings &&  Number(this.props.settings['react.phone_code_one']) !== 9999){
            this.props.changeValue('phone_code', `+${this.props.settings['react.phone_code_one']}`)
        }
    }
    componentWillUnmount(){
        // this.props.checkAuntificationUser()
    }
    render() {
        return <ClientsAdd changeValue={this.props.changeValue} form={this.props.form} submitForm={this.props.submitForm} settings={this.props.settings}/>;
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        changeValue: (input, value) => dispatch(changeClientsFormValueAC(input, value)),
        submitForm: (form) => dispatch(submitClientFormThunk(form)),
        getSettingsProject: ()=> dispatch(getMainSettingsProject())
    }
}

let mapStateToProps = (state) => {
    return {
        form: state.main.clientsForm,
        settings: state.main.settings_project
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsAddContainer)