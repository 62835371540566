export const getIsAccessAuthUserRoleById = (access_role = []) => (state) => {
    let roles = state?.auth?.user?.role || null;
    if (!roles) return false
    return roles?.some(role => access_role.includes(Number(role?.id)));
}
export const getIsAccessAuthRoleByName = (access_role = []) => (state) => {
    let roles = state?.auth?.user?.role || null;
    if (!roles) return false
    return roles?.some(role => access_role?.find(name => name?.toUpperCase()?.trim() === role?.name?.toUpperCase()?.trim()));
}
export const getIsAccessModuleSalaryByUserRoleAndHasWorker = (access_role = []) => (state) => {
// export const getIsAccessAuthRoleById =(access_role=[])=> (state)=> {
    let worker = state?.auth?.worker || null;
    let roles = state?.auth?.user?.role || null;
    if (worker) return true;
    if (!roles) return false
    return roles?.some(role => access_role.includes(Number(role?.id)));
}

export const getWorkerData = (state) => {
    return state?.auth?.worker
}
export const getAuthPaymentAdditional = (state)=> state?.auth?.payment_additional;