import React from "react";
import PageTitle from "../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import {withLang} from "../../hoc/withLang";
import {gaEvents} from "../../gaEvents";

const ReportSale = (props) => {
    const getCurrencyName = () => {
        return props.currency && props.currency.length > 0
            ? props.currency.filter(
            (e) => Number(e.currency_id) === Number(props.form.currency)
        )?.[0]?.name?.[props.lang] || ""
            : "";
    };

    return (
        <div className="content-wrapper">
            <PageTitle title={"reports_sale"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <form>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label>{props.getT("Начиная с ")}:</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                placeholder=""
                                                value={props.form.date_from}
                                                onChange={(event) => {
                                                    props.changeValue(
                                                        "date_from",
                                                        event.target.value,
                                                        props.type,
                                                        props.form
                                                    )
                                                    const nexDate = props.getNextDate(event.target.value)
                                                    props.changeValue(
                                                        "date_to",
                                                        nexDate,
                                                        props.type,
                                                        props.form
                                                    )
                                                }

                                                }
                                                max={props.currentDate}
                                                onFocus={()=>{
                                                    gaEvents.customEvent('form_order_reports_sale', {
                                                        field_focus: 'Дата Начиная с'
                                                    })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <label>{props.getT("До")}:</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            placeholder=""
                                            value={props.form.date_to}
                                            onChange={(event) =>
                                                props.changeValue(
                                                    "date_to",
                                                    event.target.value,
                                                    props.type,
                                                    props.form
                                                )
                                            }
                                            min={props.getNextDate(props.form.date_from)}
                                            onFocus={()=>{
                                                gaEvents.customEvent('form_order_reports_sale', {
                                                    field_focus: 'Дата до'
                                                })
                                            }}
                                        />
                                    </div>

                                    <div className="col-sm-2">
                                        <label>{props.getT("Валюта")}:</label>
                                        <select
                                            className="custom-select"
                                            value={props.form.currency}
                                            onChange={(event) => {
                                                gaEvents.customEvent('form_order_reports_sale', {
                                                    field_focus: 'Валюта'
                                                })
                                                props.changeValue(
                                                    "currency",
                                                    event.target.value,
                                                    props.type,
                                                    props.form
                                                )
                                            }
                                            }
                                        >
                                            {props.currency &&
                                                props.currency.map((e) => {
                                                    return <option
                                                        value={e.currency_id}>{e.name[props.lang]}</option>;
                                                })}
                                        </select>
                                    </div>
                                    {props.paymountStatus && Object.entries(props.paymountStatus).length > 0 &&
                                        <div className="col-sm-2">
                                            <label>{props.getT("Статус оплаты")}:</label>
                                            <select style={{width: '100%'}}
                                                    className={'custom-select'}
                                                    name="" id=""
                                                // value={props.form.paymount}
                                                    onChange={(event) => {
                                                        gaEvents.customEvent('form_order_reports_sale', {
                                                            field_focus: 'Статус оплаты'
                                                        })
                                                        const data = JSON.parse(event.target.value)
                                                        props.changeValue(
                                                            "paymount",
                                                            data.code,
                                                            props.type,
                                                            props.form
                                                        )
                                                    }}>
                                                <option value="" selected={true}
                                                        disabled={true}>{props.getT('Выберите')}</option>
                                                {props.paymountStatus && Object.entries(props.paymountStatus).map((p, key) => {
                                                    return <option key={key}
                                                                   selected={p[0] === props.form.paymount}
                                                                   value={JSON.stringify({
                                                                       text: p[1],
                                                                       code: p[0]
                                                                   })}>{p[1]}</option>
                                                })}
                                            </select>
                                        </div>}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4>{props.getT("Материалы")}</h4>
                        </div>
                        <table className="table table-striped">
                            <thead>
                            <th>{props.getT("Название")}</th>
                            <th>{props.getT("Количество")}</th>
                            <th>{props.getT("Цена")}</th>
                            <th>{props.getT("Сумма")}</th>
                            <th></th>
                            </thead>
                            <tbody>
                            {props.data &&
                                props.data.materials &&
                                props.data.materials.map((e) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>{e.name}</td>
                                                <td>{e.count.toFixed(2)}</td>
                                                <td>
                                                    {e.price.toFixed(2)} {getCurrencyName()}
                                                </td>
                                                <td>
                                                    {props.formatSum(e.summ)} {getCurrencyName()}
                                                </td>
                                                <td>
                                                    <i
                                                        className="fas fa-angle-down"
                                                        onClick={() => props.toggleActive(e.id)}
                                                    >
                                                        {" "}
                                                    </i>
                                                </td>
                                            </tr>
                                            {Number(props.saleOpen) === Number(e.id) ? (
                                                <tr>
                                                    <td colSpan={5}>
                                                        <table className={"table table-bordered"}>
                                                            <thead>
                                                            <tr>
                                                                <th>{props.getT("ID")}</th>
                                                                <th>{props.getT("Код заказа")}</th>
                                                                <th>{props.getT("Клиент")}</th>
                                                                <th>{props.getT("Количество")}</th>
                                                                <th>{props.getT("Цена")}</th>
                                                                <th>{props.getT("Сумма")}</th>
                                                                <th></th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {e.order.map((o) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{o.id}</td>
                                                                        <td>{o.code}</td>
                                                                        <td>{o.client}</td>
                                                                        <td>{o.count.toFixed(2)}</td>
                                                                        <td>
                                                                            {o.price.toFixed(2)}{" "}
                                                                            {getCurrencyName()}
                                                                        </td>
                                                                        <td>
                                                                            {props.formatSum(o.summ)}{" "}
                                                                            {getCurrencyName()}
                                                                        </td>
                                                                        <td>
                                                                            <Link
                                                                                to={`/orders/detail/${o.id}`}
                                                                                target={"_blank"}
                                                                            >
                                                                                <i className="far fa-eye"> </i>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    );
                                })}
                            <tr>
                                <td colSpan={5} className={"text-right"}>
                                    <h4>
                                        <small>{props.getT("Итого")}:</small>{" "}
                                        {props.formatSum(props.data.summ_mat)} {getCurrencyName()}
                                    </h4>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h4>{props.getT("Услуги")}</h4>
                        </div>
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th>{props.getT("Название")}</th>
                                <th>{props.getT("Количество")}</th>
                                <th>{props.getT("Цена")}</th>
                                <th>{props.getT("Сумма")}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {props.data &&
                                props.data.services &&
                                props.data.services.map((e) => {
                                    return (
                                        <>
                                            <tr>
                                                <td>{e.service_name}</td>
                                                <td>{e.count.toFixed(2)}</td>
                                                <td>
                                                    {e.price.toFixed(2)} {getCurrencyName()}
                                                </td>
                                                <td>
                                                    {props.formatSum(e.summ)} {getCurrencyName()}
                                                </td>
                                                <td>
                                                    <i
                                                        className="fas fa-angle-down"
                                                        onClick={() => props.toggleActive(e.id)}
                                                    >
                                                        {" "}
                                                    </i>
                                                </td>
                                            </tr>
                                            {Number(props.saleOpen) === Number(e.id) ? (
                                                <tr>
                                                    <td colSpan={5}>
                                                        <table className={"table table-bordered"}>
                                                            <thead>
                                                            <tr>
                                                                <th>{props.getT("ID")}</th>
                                                                <th>{props.getT("Код заказа")}</th>
                                                                <th>{props.getT("Клиент")}</th>
                                                                <th>{props.getT("Количество")}</th>
                                                                <th>{props.getT("Цена")}</th>
                                                                <th>{props.getT("Сумма")}</th>
                                                                <th></th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {e.order.map((o) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{o.id}</td>
                                                                        <td>{o.code}</td>
                                                                        <td>{o.client}</td>
                                                                        <td>{o.count.toFixed(2)}</td>
                                                                        <td>
                                                                            {o.price.toFixed(2)}{" "}
                                                                            {getCurrencyName()}
                                                                        </td>
                                                                        <td>
                                                                            {props.formatSum(o.summ)}{" "}
                                                                            {getCurrencyName()}
                                                                        </td>
                                                                        <td>
                                                                            <Link
                                                                                to={`/orders/detail/${o.id}`}
                                                                                target={"_blank"}
                                                                            >
                                                                                <i className="far fa-eye"> </i>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            ) : (
                                                ""
                                            )}
                                        </>
                                    );
                                })}
                            <tr>
                                <td colSpan={5} className={"text-right"}>
                                    <h4>
                                        <small>{props.getT("Итого")}:</small>{" "}
                                        {props.formatSum(props.data.summ_serv)}{" "}
                                        {getCurrencyName()}
                                    </h4>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            {/*<h4 className={'text-right'}><small>Итого за период:</small> {Number(props.data.summ).toFixed(2)} {getCurrencyName()}</h4>*/}
                            <h4 className={"text-right"}>
                                <small>{props.getT("Итого за период")}:</small>{" "}
                                {props.formatSum(props.data.summ)} {getCurrencyName()}
                            </h4>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default withLang(ReportSale);
