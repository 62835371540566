import {PlaneDefaultAPI} from "../../../api/Salary/PlaneDefaultAPI";
import {setDefaultPlaneAC, toggleDefaultPlaneCreateModalAC} from "../../actions/SalaryProject";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {dispatcherErrorThunk} from "../common_thunk";
import {createFormData} from "../../../utils/helpers";
import {INITIAL_DEFAULT_PLANE_MODAL_CREATE_FORM} from "../../reducers/SalaryProjectReducer";

/**
 * Получить все планы по умолчанию
 * @param {function} dispatch
 */
async function dispatcherGetPlane(dispatch) {
    const response = await PlaneDefaultAPI.get();
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    dispatch(setDefaultPlaneAC(response))
}

/**
 * Получить план на улсуги по умолчанию.
 */
export const getDefaultPlaneThunk = () => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await dispatcherGetPlane(dispatch);
        dispatch(toggleLoaderStatusAC(false));

    } catch (error) {
        dispatch(dispatcherErrorThunk(error))
    }
}

/**
 * Создать план для типа улслуги.
 * @param {object}   api_data - api_data
 * @param {function|null}   reset_form - reset_form
 * [{"key":"service_type","value":"7","type":"text"},
 * {"key":"count_bad","value":"100","type":"text"},
 * {"key":"count_normal","value":"150","type":"text"},
 * {"key":"count_super","value":"250","type":"text"},
 * {"key":"user","value":"177","type":"text"},

 */
export const addDefaultPlaneThunk = (api_data, reset_form=null)=> async dispatch =>{
    try{
        console.log(api_data)
        dispatch(toggleLoaderStatusAC(true));
        const form_data = createFormData(api_data);
        const response = await PlaneDefaultAPI.add(form_data)
        if (response.hasOwnProperty('error') && response?.error){
            dispatch(dispatcherErrorThunk(response));
            return
        }
        await dispatcherGetPlane(dispatch);
        if(reset_form) reset_form({...INITIAL_DEFAULT_PLANE_MODAL_CREATE_FORM})
        dispatch(toggleDefaultPlaneCreateModalAC({isOpen: false, service_type: null}));
        dispatch(toggleLoaderStatusAC(false));
    }catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}