import React from "react";
import Dashboard from "./Dashboard";
import {connect} from "react-redux";
import "./Dashboard.css";
import {
  getDashboardFilteredDataSingleThunk,
  getDashboardFilteredDataThunk,
  setClientsDataThunk,
  setNewClientsDashboardDataThunk,
  setOrdersDataThunk,
  setStatisticsDataThunk,
  setStatisticsSingleDataThunk,
  setTermsDashboardDataThunk,
  updateSettingsThunk
} from "../../store/reducers/MainReducer";
import {withLang} from "../../hoc/withLang";

class DashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    // this.getOrdersCounts = this.getOrdersCounts.bind(this);
    this.getCountersData = this.getCountersData.bind(this);
    this.getOrdersType = this.getOrdersType.bind(this);
    this.getServCounts = this.getServCounts.bind(this);

    this.getOrdersTypeSingle = this.getOrdersTypeSingle.bind(this);
    this.getServCountsSingle = this.getServCountsSingle.bind(this);
    this.getGoodsCountsSingle = this.getGoodsCountsSingle.bind(this);

    this.getNewClintsSaleOrdersTypeSingle = this.getNewClintsSaleOrdersTypeSingle.bind(this);

    this.getGoodsCounts = this.getGoodsCounts.bind(this);
    this.getNewClintGoodsCountsSingle = this.getNewClintGoodsCountsSingle.bind(this);
    this.getNewClintServiceCountsSingle = this.getNewClintServiceCountsSingle.bind(this);
  }

  // getOrdersCounts() {
  //   const orders_map = {};
  //   const orders_chart = [];
  //   const current_month = new Date().getMonth();
  //   if (this.props.statistics && this.props.statistics.service_sale) {
  //     this.props.statistics.service_sale.forEach((e) => {
  //       const day = new Date(e.translate.d_update).getDate();
  //       const month = new Date(e.translate.d_update).getMonth();

  //       if (month) {
  //         if (orders_map[day]) {
  //           orders_map[day] += 1;
  //         } else {
  //           orders_map[day] = 1;
  //         }
  //       }
  //     });
  //     for (let i = 0; i <= 31; i++) {
  //       if (orders_map[i] > 0) {
  //         let chart_day = String(i).length === 1 ? "0" + i : i;
  //         let chart_month =
  //           String(current_month).length === 1
  //             ? "0" + (current_month + 1)
  //             : current_month + 1;
  //         orders_chart.push([chart_day, orders_map[i]]);
  //       }
  //     }

  //     return orders_chart;
  //   }
  // }

  getServCounts() {
    const serv_chart = [];
    if (this.props.statistics && this.props.statistics.serv) {
      this.props.statistics.serv.forEach((e) => {
        const sum = e.sale.toFixed(2);
        const day = e.day;
        serv_chart.push([day.toString(), Number(sum)]);
      });
      return serv_chart;
    }
  }
  getServCountsSingle() {
    const serv_chart = [];
    if (this.props.single && this.props.single.serv) {
      this.props.single.serv.forEach((e) => {
        const sum = e.sale.toFixed(2);
        const day = e.day;
        serv_chart.push([day.toString(), Number(sum)]);
      });
      return serv_chart;
    }
  }

  getGoodsCounts() {
    const goods_chart = [];
    if (this.props.statistics && this.props.statistics.goods) {
      this.props.statistics.goods.forEach((e) => {
        const sum = e.sale.toFixed(2);
        const day = e.day;
        goods_chart.push([day.toString(), Number(sum)]);
      });
      return goods_chart;
    }
  }

  getGoodsCountsSingle() {
    const goods_chart = [];
    if (this.props.single && this.props.single.goods) {
      this.props.single.goods.forEach((e) => {
        const sum = e.sale.toFixed(2);
        const day = e.day;
        goods_chart.push([day.toString(), Number(sum)]);
      });
      return goods_chart;
    }
  }
  getNewClintGoodsCountsSingle() {
    if(!this.props?.new_clients || this.props?.new_clients.sale.length === 0) return;
    const goods_chart = [];

    let goods = this.props?.new_clients?.sale.date_sale.goods.length >= 1 ? this.props?.new_clients?.sale.date_sale.goods : false;
    if (goods) {
      goods_chart.forEach((e) => {
        const sum = e.sale.toFixed(2);
        const day = e.day;
        goods_chart.push([day.toString(), Number(sum)]);
      });
      return goods_chart;
    }
  }
  getNewClintServiceCountsSingle() {
    if(!this.props?.new_clients || this.props?.new_clients.sale.length === 0) return;
    const service_chart = [];
    let service = this.props?.new_clients?.sale.date_sale.service.length >=1 ? this.props?.new_clients?.sale.date_sale.service : false;
    if (service) {
      service_chart.forEach((e) => {
        const sum = e.sale.toFixed(2);
        const day = e.day;
        service_chart.push([day.toString(), Number(sum)]);
      });
      return service_chart;
    }
  }


  getOrdersType() {
    let orders = {
      with: Number(this.props.statistics.orders_with_xnc),
      without:
        Number(this.props.statistics.orders) -
        Number(this.props.statistics.orders_with_xnc)
    };

    return [
      [this.props.getT("c XNC"), orders.with],
      [this.props.getT("без XNC"), orders.without]
    ];
  }

  getOrdersTypeSingle() {
    let orders = {
      with: Number(this.props.single.orders_with_xnc),
      without:
        Number(this.props.single.orders) -
        Number(this.props.single.orders_with_xnc)
    };

    return [
      [this.props.getT("c XNC"), orders.with],
      [this.props.getT("без XNC"), orders.without]
    ];
  }
  getNewClintsSaleOrdersTypeSingle() {
    if(!this.props.new_clients) return
    let orders = {
      with: Number(this.props.new_clients.sale.orders_with_xnc),
      without:
          Number(this.props.new_clients.sale.orders) -
          Number(this.props.new_clients.sale.orders_with_xnc)
    };

    return [
        [this.props.getT("c XNC"), orders.with],
        [this.props.getT("без XNC"), orders.without]
    ];
  }

  getCountersData() {
    let clients = [];
    return {
      orders: this.props.orders.filter(
        (e) => new Date(e.last_update).getMonth() === new Date().getMonth()
      ).length,
      money:
        this.props.orders && this.props.orders.length > 0
          ? this.props.orders.reduce((total, e) => {
              return new Date(e.last_update).getMonth() ===
                new Date().getMonth()
                ? total + Number(e.confirm.cost)
                : total + 0;
            }, 0)
          : 0,
      clients: this.props.clients && this.props.clients.length,
      managers: 1
    };
  }
  getCountersDataSingle() {
    let clients = [];
    return {
      orders: this.props.orders.filter(
        (e) => new Date(e.last_update).getMonth() === new Date().getMonth()
      ).length,
      money:
        this.props.orders && this.props.orders.length > 0
          ? this.props.orders.reduce((total, e) => {
              return new Date(e.last_update).getMonth() ===
                new Date().getMonth()
                ? total + Number(e.confirm.cost)
                : total + 0;
            }, 0)
          : 0,
      clients: this.props.clients && this.props.clients.length,
      managers: 1
    };
  }

  componentDidMount() {
    // this.props.getOrders();
    this.props.setClients();
    this.props.getStatistic();
    this.props.setManagers();
    this.props.getSingleStatistics();
    this.props.getTerms();
    this.props.getNewClienst();

  }
  // componentDidUpdate(prevProps) {
  //   console.log(prevProps.statistics.all_summ, "prevProps");
  //   if (prevProps.statistics.all_summ != this.props.statistics.all_summ) {

  //     // this.props.getStatistic();
  //     // this.getServCounts();
  //     // this.getGoodsCounts();
  //   }
  // }
  componentWillUnmount(){
    // this.props.checkAuntificationUser()
  }
  render() {
    return (
      <Dashboard
        // orders={this.getOrdersCounts()}
        serv={this.getServCounts()}
        statistics={this.props.statistics}
        goods={this.getGoodsCounts()}
        counters={this.getCountersData()}
        ordersType={this.getOrdersType()}
        servSingle={this.getServCountsSingle()}
        goodsSingle={this.getGoodsCountsSingle()}
        countersSingle={this.getCountersDataSingle()}
        ordersTypeSingle={this.getOrdersTypeSingle()}
        form={this.props.dashboardForm}
        formSingle={this.props.dashboardFormSingle}
        status={this.props.dashboardStatus}
        dates={this.props.dashboardDate}
        changeFilterValue={this.props.changeFilterValue}
        changeSingle={this.props.changeSingle}
        managers={this.props.managers}
        single={this.props.single}
        terms = {this.props.terms}
        new_clients = {this.props.new_clients}
        // new_clients_goods = {false}
        new_clients_goods ={ this.getNewClintGoodsCountsSingle()}
        new_clients_service ={ this.getNewClintServiceCountsSingle()}
        new_clients_orders = {this.getNewClintsSaleOrdersTypeSingle()}
        getT={this.props.getT}
        lang={this.props.lang}
      />
    );
  }
}

let mapDispatchToProps = (dispatch) => {
  return {
    getOrders: () => dispatch(setOrdersDataThunk()),
    setClients: () => dispatch(setClientsDataThunk()),
    setManagers: () => dispatch(updateSettingsThunk()),
    // setManagers: () => dispatch(getManagersDataThunk()),
    getStatistic: () => dispatch(setStatisticsDataThunk()),
    getSingleStatistics: () => dispatch(setStatisticsSingleDataThunk()),
    changeFilterValue: (input, value, filters) =>
      dispatch(getDashboardFilteredDataThunk(input, value, filters)),
    changeSingle: (input, value, filters) =>
      dispatch(getDashboardFilteredDataSingleThunk(input, value, filters)),
    getTerms: ()=> dispatch(setTermsDashboardDataThunk()),
    getNewClienst :()=> dispatch(setNewClientsDashboardDataThunk())
  };
};

let mapStateToProps = (state) => {
  return {
    orders: state.main.orders.actual,
    clients: state.main.clients,
    statistics: state.main.statistics,
    dashboardForm: state.main.dashboardForm,
    dashboardFormSingle: state.main.dashboardFormSingle,
    dashboardStatus: state.main.dashboardStatus,
    dashboardDate: state.main.dashboardDate,
    managers: state.main.managers,
    single: state.main.single,
    terms : state.main.terms,
    new_clients: state.main.new_clients,
    lang : state.translation.local
  };
};
const withDashboardContainerLang = withLang(DashboardContainer)

export default connect(mapStateToProps, mapDispatchToProps)(withDashboardContainerLang)
