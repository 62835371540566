export const MAIN_SHEET_DATA_PRINT_CONFIRM_MODAL = "MAIN/SHEET_DATA_PRINT_CONFIRM_MODAL";

export const MAIN_ORDER_DETAIL_WHOLESALE_GOODS = "MAIN/ORDER_DETAIL_WHOLESALE_GOODS"
export const MAIN_ORDER_DETAIL_WHOLESALE_INFORMATION_BY_GOODS_ID = "MAIN/ORDER_DETAIL_WHOLESALE_INFORMATION_BY_GOODS_ID"

export const sheetDataPrintConfirmModalAC = ({isOpen = false, order = null, full = false}) => {
    return {
        type: MAIN_SHEET_DATA_PRINT_CONFIRM_MODAL,
        payload: {isOpen: isOpen, full: full, order: order}
    }
}
/**
 * orderDetailWholeSaleGoodsAC.  список  потребностей по текущему заказу
 * @param{array|| null} need - возможные
 * @param{array|| null} exist - существующие
 */
export const orderDetailWholeSaleGoodsAC = ({need, exist}) => {
    return {
        type: MAIN_ORDER_DETAIL_WHOLESALE_GOODS,
        payload: {exist: exist ?? null, need: need ?? null}
    }
}
/**
 * orderDetailWholeSaleGoodsAC. Список подробной информации о товаре и его поставщиках.
 * @param{array|| null} payload - возможные
 */
export const orderDetailWholeSaleGoodsInformationByGoodsIdAC = (payload) => {
    return {
        type: MAIN_ORDER_DETAIL_WHOLESALE_INFORMATION_BY_GOODS_ID,
        payload:payload
    }
}