import {LS_LANG} from "../utils/constants";
import {rejectTransform, resolveTransform} from "./utils_errors";
import {instances} from "./Salary/setting_api";
import axios from "axios";


/**
 * Базовые справочники
 * @method get  - список пользователей
 */
export let instances_help_article = axios.create({
    baseURL: `${process.env.REACT_APP_API_HELP_ARTICLE_URL}/api/v1`,
    // baseURL: 'https://test-help.ifurn.pro/api/v1',
    withCredentials: true,

});

export const HelperArticleAPI = {
    get(id) {
        let lang_val = localStorage.getItem(LS_LANG);
        let lang = lang_val === 'ua' ? 'uk' : lang_val;
        let url = `/article/${id}/${lang}`;
        return instances_help_article.get(url).then(res => {
            let data = resolveTransform(res)
            return id ? {
                id: id,
                title: `(№${id}) ${data.title}`,
                description: data.description,
                related_articles: data?.["related articles"] ?? null
            } : data;
        })
            .catch(error => rejectTransform(error))
    },
    sendLetter({text}) {
        return instances.post("/mail", {action: "send_form", text})
    }

}