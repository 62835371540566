import {rejectTransform, resolveTransform} from "../utils_errors";
import {instances, URI_API_EXTTUNELL} from "../Salary/setting_api";

const XNC_OPERATIONS_STATISTIC = 'xnc_operations_statistic';
const XNC_OPERATIONS_STATISTIC_MATERIAL = 'xnc_operations_statistic_material';
const XNC_OPERATIONS_STATISTIC_MATERIAL_BAND = 'xnc_operations_statistic_material_band';

/**
 * Базовые справочники
 * @method get  - список пользователей
 */
export const StatisticAPI = {
    getXncOperation(params = null) {
        return instances.post(URI_API_EXTTUNELL + '/', null, {
            params: params,
            headers: {
                action: XNC_OPERATIONS_STATISTIC,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getXncOperationMaterial(params = null) {
        return instances.post(URI_API_EXTTUNELL + '/', null, {
            params: params,
            headers: {
                action: XNC_OPERATIONS_STATISTIC_MATERIAL,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getXncOperationsMaterialBandConnect(params) {
        return instances.post(URI_API_EXTTUNELL + '/', null, {
            params: params,
            headers: {
                action: XNC_OPERATIONS_STATISTIC_MATERIAL_BAND,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }
}