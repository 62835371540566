import React from 'react';
import {Field} from "./Field";
import {LS_LANG} from "../../../utils/constants";
import ReactHtmlParser from "react-html-parser";

const SettingsCompanyTable = ({
                                  settings,
                                  getT,
                                  handlerChange,
                                  is_access_change,
                                  handlerDelete,
                                  handlerOpenModalEditHtml
                              }) => {
    return (
        <div className={"card px-3 py-3"}>
            <table className={"table table-hover"}>
                <thead>
                <th>{getT("Папка")}</th>
                <th>{getT("Параметры системы")}</th>
                <th>{getT("Значение")}</th>
                <th>{getT("Описание")}</th>
                <th>{getT("Действие")}</th>

                </thead>
                <tbody>
                {Array.isArray(settings) && settings?.map((item, idx) => {
                    let can_be_deleted = !!Number(item?.can_be_deleted);
                    let is_type_html = item.setting_type === "html";
                    return <tr key={idx}>

                        <td>{item?.setting_folder_name}</td>
                        <td>{item?.setting_name}</td>
                        <td>
                            {is_type_html ? <div className={"d-flex align-items-center justify-content-center setting-html-wrap" } style={{gap: "10px"}}>
                                    <div>             {ReactHtmlParser(`${item?.translate?.[localStorage.getItem(LS_LANG)]}`)}</div>

                                    {item?.translate && <i className="fas fa-edit cursor-pointer text-primary text-sm"
                                                           onClick={handlerOpenModalEditHtml(item)}></i>
                                    }

                                </div>
                                :
                                <Field type={item.setting_type}
                                       key={item?.setting_id}
                                       id={item?.setting_id}
                                       value={item?.setting_value}
                                       disabled={is_access_change}
                                       cb={handlerChange(item)}/>
                            }

                        </td>
                        <td>{item?.description}</td>
                        <td>
                            {can_be_deleted && <i className="fa fa-trash cursor-pointer text-danger"
                                                  onClick={handlerDelete(item?.settings_firm_id)}></i>}
                        </td>

                    </tr>
                })}
                </tbody>
            </table>
        </div>
    );
};

export default SettingsCompanyTable;