import React from 'react';

const FIELD_NAME_BY_KEY_MATERIAL = {
    part_count: {
        title: 'Количество деталей',
        unit: "шт",
        is_has_action_modal_filter_data_by_role: true,
    },
    cut_length: {
        title: 'Длина реза',
        unit: "м",
        is_has_action_modal_filter_data_by_role: true,
    },
    filling: {
        title: 'Средний процент заполнения листа деталями',
        unit: "%",
        is_has_action_modal_filter_data_by_role: true,
    },
    part_area_sum: {
        title: 'Площадь деталей',
        unit: "м2",
        is_has_action_modal_filter_data_by_role: true,
    },
    area: {
        title: 'Площадь',
        unit: "м2",
        is_has_action_modal_filter_data_by_role: true
    },
    cut_per_2800_2070_sheet: {
        title: 'Длина реза на лист',
        unit: "м",
        is_has_action_modal_filter_data_by_role: true
    },
    used_good_stock_in_cutting: {
        title: 'Площадь остатков, использованных при раскрое',
        unit: "м2",
        is_has_action_modal_filter_data_by_role: true
    },
    part_rest_sum_ready_orders_PLAN: {
        title: 'Планируемый возврат остатков по выполненным заказам',
        unit: "м2",
        is_has_action_modal_filter_data_by_role: true
    },
    part_rest_sum_PLAN: {
        title: 'Планируемый возврат остатков по всем заказам',
        unit: 'м2',
        is_has_action_modal_filter_data_by_role: true
    },
    rest_back_to_stock_FACT: {
        title: 'Фактический приход остатков на склад',
        unit: 'м2',
        is_has_action_modal_filter_data_by_role: true
    },
    goods_stock_get_from_stock: {
        title: 'Фактически выданные остатки со склада',
        unit: 'м2',
        is_has_action_modal_filter_data_by_role: true
    },
    goods_stock_presented: {
        title: 'Площадь остатков на складе',
        unit: 'м2',
        is_has_action_modal_filter_data_by_role: true
    },
    loses: {
        title: 'Потери материала при раскрое',
        unit: 'м2',
        is_has_action_modal_filter_data_by_role: true
    },
    loses_percentage: {
        title: 'Процент потери материала при раскрое',
        unit: '%',
        is_has_action_modal_filter_data_by_role: true
    },
}
const FIELD_NAME_BY_KEY_CLIENT = {

    material_client_part_count: {
        title: 'Количество деталей',
        unit: "шт",
        is_has_action_modal_filter_data_by_role: true,
    },
    material_client_cut_length: {
        title: 'Длина реза',
        unit: "м",
        is_has_action_modal_filter_data_by_role: true,
    },
    material_client_filling: {
        title: 'Средний процент заполнения листа деталями',
        unit: "%",
        is_has_action_modal_filter_data_by_role: true,
    },
    material_client_part_area_sum: {
        title: 'Площадь деталей',
        unit: "м2",
        is_has_action_modal_filter_data_by_role: true
    },
    material_client_area: {
        title: 'Площадь',
        unit: "м2",
        is_has_action_modal_filter_data_by_role: true
    },
    material_client_cut_per_2800_2070_sheet: {
        title: 'Длина реза на лист',
        unit: "м",
        is_has_action_modal_filter_data_by_role: true
    },
    material_client_loses: {
        title: 'Потери материала при раскрое',
        unit: 'м2',
        is_has_action_modal_filter_data_by_role: true
    },
    material_client_loses_percentage: {
        title: 'Процент потери материала при раскрое',
        unit: '%',
        is_has_action_modal_filter_data_by_role: true
    },
    material_client_names: {
        title: 'Используемые имена',
        unit: null,
        is_has_action_modal_filter_data_by_role: false,
        is_child_object: true
    },

}
const StatisticsFull = ({data, getT, handlerOpenModalSeeMoreStaticsByKey = null, type_material}) => {
    let field_keys = type_material ? FIELD_NAME_BY_KEY_CLIENT : FIELD_NAME_BY_KEY_MATERIAL;

    return (
        <>
            {Object.keys(field_keys)
                ?.map(key => {
                    let is_display_modal_icon = field_keys[key]?.is_has_action_modal_filter_data_by_role && handlerOpenModalSeeMoreStaticsByKey;
                    let is_child_object = field_keys?.[key]?.hasOwnProperty('is_child_object') && field_keys?.[key]?.is_child_object && data?.[key]
                    return <li
                        className="list-group-item d-flex align-items-center justify-content-between px-1 py-2 flex-wrap">
                        <span className={"title"}> {getT(field_keys[key].title)}:</span>
                        {is_child_object ? <span>                          <ul className={"d-flex w-100 flex-column"}>

                                {Object.entries(data?.[key])?.map(([key, val]) => {
                                    return <li className={""}>{key}: {val}</li>
                                })}

                            </ul>
                                {!!(is_display_modal_icon) && <i className="far fa-eye text-primary ml-2 cursor-pointer"
                                                                 onClick={handlerOpenModalSeeMoreStaticsByKey(key)}> </i>}

                            </span>
                            :
                            <span className={"font-weight-bold"}>
                                {data?.[key] ?? '0'} {getT(field_keys?.[key]?.unit ?? '')}
                                {!!(data?.[key] && is_display_modal_icon) && <i className="far fa-eye text-primary ml-2 cursor-pointer"
                                                                 onClick={handlerOpenModalSeeMoreStaticsByKey(key)}> </i>}
                        </span>
                        }
                    </li>
                })}
        </>

    );
}
    ;

    export default StatisticsFull;