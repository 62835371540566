import {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import {useDispatch, useSelector} from "react-redux";
import {SetTogggleCheck, TogggleCheck} from "../store/reducers/MainReducer";

export default function () {
    const [cookies, setCookie, removeCookie] = useCookies(['settings']);
    const settings_store = useSelector(state => state.main.settings);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!cookies.hasOwnProperty('settings') || !Object.keys(cookies?.settings)?.length) {
            setCookie('settings', settings_store)
        }else{
            dispatch(SetTogggleCheck(cookies.settings))
        }
    }, [])
    useEffect(() => {
        if(JSON.stringify(settings_store) !== JSON.stringify(cookies.settings)) {
            // console.log('change setting_store and update cookies')
            setCookie('settings', settings_store)
        }
    }, [settings_store])

    const onChangeHandler = (id) => (event)=>{
       if(id) dispatch(TogggleCheck(id))
    }


    return [settings_store, onChangeHandler]
}