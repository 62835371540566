import React from 'react';
import {productionTaskMaterial} from "../ProjectTask/selectors";
import OrderItem from "./components/OrderItem";
import {useDispatch, useSelector} from "react-redux";
import {isShallowEqual} from "../../../utils/helpers";
import {onToggleOrderGroup} from "../../../store/reducers/MainReducer";


const ProjectOrder = ({getT}) => {
    const productionTasksMaterial = useSelector(productionTaskMaterial, (prev, next)=>isShallowEqual(prev, next))
    const dispatch = useDispatch();
    const onSelectMaterialCheck = (payload) => {
        dispatch(onToggleOrderGroup(payload))}

    return (
        <div className="table-responsive">
            <table className="table m-0 table-striped table-hover">
                <thead>
                <tr>
                    <th colSpan="7"></th>
                </tr>
                <tr>
                    <th></th>
                    <th scope="col">{getT("Код 1С")}</th>
                    <th scope="col">{getT("Тех. ID")}</th>
                    <th scope="col">{getT("Название")}</th>
                    <th scope="col">{getT("Количество")}</th>

                    <th scope="col">{getT("Дата подтверждения")}</th>
                    <th scope="col">{getT("Дата производства ")}</th>

                    <th scope="col">{getT("Действие")}</th>
                </tr>
                </thead>
                <tbody>
                {Array.isArray(productionTasksMaterial)  && productionTasksMaterial?.map((el) => {
                        return (
                            <>
                                <tr key={`material__${el?.goods}`}>
                                    <td
                                        style={{ border: "1px solid black" }}
                                        colSpan="8"
                                    >
                                        {el.name}
                                    </td>
                                </tr>

                                {Array.isArray(el?.orders) && el?.orders?.map((e) => {
                                    const materialCount = e?.calc?.material?.filter(item=> (Number(item.id) === Number(el?.goods)) && (item?.type === "material"))?.map(item=>{
                                        return item?.count
                                    })?.reduce(
                                        (accumulator, currentValue) => accumulator + currentValue,
                                        0
                                    ) ?? ''
                                    return (
                                        <OrderItem key={`order_${e?.id}`} getT={getT} order={e} materialCount={materialCount} selectMaterialCheck={onSelectMaterialCheck}/>
                                    );
                                })}
                            </>
                        );
                    })}
                </tbody>
            </table>

        </div>
    );
};

export default ProjectOrder;