import React from 'react';
import NeedItemRow from "./NeedItemRow";

/**
 *  создать связь
 *
 */
const NeededOrdersTab = ({needed_goods, getT, openCreateModalOrderGoodsId}) => {

    let isHasNeededGoods = !!(Array.isArray(needed_goods) && !!needed_goods?.length);
    return (<div className={"needed-orders-goods-tab"}>
        {isHasNeededGoods ?
            <table className={"table-striped"}>
                <thead>
                <th>{getT("Название")}</th>
                <th>{getT("Статус")}</th>
                <th>{getT("Клиент")}</th>
                <th>{getT("Пользователь")}</th>
                <th>{getT("Товар")}</th>
                <th>{getT("Количество")}</th>
                <th>{getT("Цена")}</th>
                <th>{getT("Действие")}</th>
                </thead>
                {needed_goods?.map(order => {
                    return <NeedItemRow item={order} getT={getT} openCreateModalOrderGoodsId={openCreateModalOrderGoodsId} />
                })}
            </table>
            :
            <p>{getT("Данный отсутствуют")}</p>
        }
    </div>);
};

export default NeededOrdersTab;