import React, {useState} from 'react';

const ItemPart = ({getT, part, handlerChangeUniq, handlerChoiceRoot, handlerChoiceUniqItem}) => {
        const [isOpen, setToggleOpen] = useState(false)
        let is_not_all_checked_count_part = part?.marks?.some(e => e.is_checked)
        return (
            <>
                <div className="part   mb-2 form-control h-100"
                     key={`part${part?.id}`}>
                    <div className="d-flex align-items-center py-2">
                        <div className="form-group mb-0 form-check align-items-center">
                            <input type="checkbox" className="form-check-input" id={`check_${part?.part_id}`}
                                   checked={part.is_checked}
                                   onChange={handlerChoiceRoot(part)}
                                // onChange={handlerValues(part)}

                            />
                            <label className="form-check-label" htmlFor={`check_${part?.part_id}`}>
                                <span
                                    className={`${is_not_all_checked_count_part ? 'text-warning' : ''}`}> [id= {part?.part_id}]</span> -
                            </label>
                        </div>

                        <div className="d-flex align-items-center pl-1" style={{gap: '6px'}}>
                            <span> {getT("Кол-во деталей")}: {part?.count_all}  </span>

                        </div>
                        <i className="ml-auto fas fa-angle-down" onClick={() => setToggleOpen(!isOpen)}></i>
                    </div>
                        {isOpen && (
                            <div className={"d-flex flex-wrap border-top py-3 "} style={{gap: '10px'}}>
                                {part?.marks?.map(item => {
                                return <div className="form-group mb-0 form-check align-items-center"  key={item.unique_num}>
                                    <input type="checkbox" className="form-check-input"
                                           id={`check_${item?.part_id}_uniq_${item.id}`}
                                           checked={item.is_checked}
                                           onChange={handlerChoiceUniqItem(item)}
                                    />
                                    <input className={"form-control text-center"} type="number"
                                           step={1} style={{width: '100px', padding: '2px', height: 'revert'}}
                                           value={item.unique_num}
                                           onChange={handlerChangeUniq(item)}
                                    />
                                </div>
                            })}
                            </div>

                        )}
                </div>
            </>
        );
    }
;

export default ItemPart;