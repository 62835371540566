import {dispatcherErrorThunk} from "../common_thunk";
import {createFormData} from "../../../utils/helpers";
import {WholesaleClientConnectionAPI} from "../../../api/Wholesale/WholesaleClientConnectionAPI";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import ApiService from "../../../api/api";
import {
    wholesaleConnectionClientsAC,
    wholesaleSetCompanyClientListAC,
    wholesaleToggleConnectionRequestCompanyClientModal
} from "../../actions/WholesaleActions";
import getT from "../../../printHtml/getT";
import toast from 'react-hot-toast';

const apiService = new ApiService();

const dispatchedWSGetConnectionClient = async (dispatch) => {

    const response = await WholesaleClientConnectionAPI.get_all();
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    dispatch(wholesaleConnectionClientsAC(response?.data));
}

export const getWSConnectionClientThunk = () => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await dispatchedWSGetConnectionClient(dispatch)
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
/**
 * .
 *
 * @param {object} data - данные для отправки
 * @param {boolean?} is_company_apply - компания подает заявку (да,нет)
 * @returns  {undefined}  -
 */
export const setWSConnectionClientThunk = (data, is_company_apply = false) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await WholesaleClientConnectionAPI.set_connect(data);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        await dispatchedWSGetConnectionClient(dispatch);
        if (is_company_apply) {
            toast.success(getT("Заявка успешно отправлена!"))
        }
        if (!is_company_apply) dispatch(wholesaleToggleConnectionRequestCompanyClientModal({isOpen: false, data: null}))
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
export const deleteWSConnectionClientThunk = (data) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        let formData = createFormData(data);
        const response = await WholesaleClientConnectionAPI.delete_connect(formData);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        await dispatchedWSGetConnectionClient(dispatch);

        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

export const getCompanyClientsByFirmThunk = () => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await apiService.getClients({});
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        dispatch(wholesaleSetCompanyClientListAC(response))
        dispatch(toggleLoaderStatusAC(false));


    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

