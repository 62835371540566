import {LS_FIRM} from "../../utils/constants";

export const getWSConnectionClient = state => state?.wholesale?.connection_clients;
export const getWSPartnerFirmList = state => state?.wholesale?.partner_firm_list;
export const getWSCompanyClientList = state => state?.wholesale?.company_client_list;

export const getWSConnectionRequestCompanyClientModal = state => state?.wholesale?.connection_request_company_client_modal;

/**
 * Получить список активных оптовиков.
 *
 * @returns  {array || null}  -
 */
export const getWSConnectionWholesaleFirms = state => {
    let currentFirmId = Number(localStorage.getItem(LS_FIRM));
    let connection_clients = state?.wholesale?.connection_clients ?? [];
    let wholesale = connection_clients?.filter(item => {
        return (Number(item?.partner_firm) === currentFirmId) && !!Number(item?.active) && !!Number(item?.client)
    });
    return !!(Array.isArray(wholesale) && !!wholesale?.length) ? wholesale : null
}
/**
 * Получить список активных партнёров.
 *
 * @returns  {array || null}  -
 */
export const getWSConnectionPartnerFirms = state => {
    let currentFirmId = Number(localStorage.getItem(LS_FIRM));
    let connection_clients = state?.wholesale?.connection_clients ?? [];
    let wholesale = connection_clients?.filter(item => {
        return (Number(item?.main_firm) === currentFirmId) && !!Number(item?.active) && !!Number(item?.client)
    });
    return !!(Array.isArray(wholesale) && !!wholesale?.length) ? wholesale : null
}
export const getWSCompanyConnectionRequestPage = (state) => {
    return {
        connection_clients: getWSConnectionClient(state),
        partner_firm_list: getWSPartnerFirmList(state),
        company_client_list: getWSCompanyClientList(state),

    }
}
export const getWSOperationsWithProductGroupsFirm = state => state?.wholesale?.operations_with_product_groups_firm;

export const getWSGoodsStockRetailOfFirm = state => state?.wholesale?.goods_stock_retail_of_firm;
export const getWSGoodsStockRetailOfFirmAddModal = state => state?.wholesale?.goods_stock_retail_of_firm_add_modal;
export const getWSGoodsPartnerSetup = state => state?.wholesale?.goods_partner_setup;
export const getWSGoodsPartnerSetupModal = state => state?.wholesale?.goods_partner_setup_modal;

export const getWSGoodsRetailPricePartnerAndSource = state => state?.wholesale?.goods_retail_price_partner_source;

export const getWSGoodsRetailPrice = state => state?.wholesale?.goods_retail_price;
export const getWSGoodsRetailPriceReadModal = state => state?.wholesale?.goods_retail_price_read_modal;
export const getWSGoodsRetailPriceAddModal = state => state?.wholesale?.goods_retail_price_add_modal;
export const getWSGoodsStockRetailPriceReadModal = state => state?.wholesale?.goods_stock_retail_price_read_modal;

export const getWSPartnerProperties = state => state?.wholesale?.partner_properties;
export const getWSPartnerPropertiesAddModal = state => state?.wholesale?.partner_properties_add_modal;

