import React from 'react';

const ShiftFilter = ({getT, handlerFilterChange, shift_filter}) => {

    const handlerChange = (e) => {
        let api_data = {
            offset: shift_filter.offset,
            open_for_edit: e.target.value
        }
        handlerFilterChange(api_data)
    }
    return (
        <div>
            <div className="form-check  form-check-inline">
                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1"
                       value="1" checked={shift_filter?.open_for_edit == 1} onChange={handlerChange}

                />
                <label className="form-check-label" htmlFor="exampleRadios1">
                    {getT("Открытые смены")}
                </label>
            </div>
            <div className="form-check  form-check-inline">
                <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2"
                       value="0"
                       checked={shift_filter?.open_for_edit == 0} onChange={handlerChange}
                />
                <label className="form-check-label" htmlFor="exampleRadios2">
                    {getT("Закрытые смены")}
                </label>
            </div>
        </div>
    );
};

export default ShiftFilter;