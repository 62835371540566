import React from 'react';

function isBeforeToday(inputDate) {
    const currentDateTime = new Date();
    return inputDate.getTime() < currentDateTime.getTime();
}
function formatDate(isoString) {
    const date = new Date(isoString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JS
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}
const Payment = ({payment, getT}) => {
    if (!payment) return null;
    const someDate = new Date(payment);
    return (
        <div className={`payment-block  ${isBeforeToday(someDate) ? 'error' : ''}`}>
            {isBeforeToday(someDate) ? <React.Fragment>
                    <p className={'payment-error'}>{getT('Оплачено до')} {formatDate(payment)}</p>
                    <div className="hint">
                        <p>{getT('Если тариф не оплачен, в системе автоматически могут быть заблокированы многие функции: генерация программ, изменение статусов заказов и многое другое. Просим Вас оплатить использование сервиса.')}</p>
                    </div>
                </React.Fragment>
                : <p>{getT('Оплачено до')} {formatDate(payment)}</p>}
        </div>
    );
};

export default Payment;