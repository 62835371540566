import React, {useMemo, useState} from 'react';
import {useDispatch} from "react-redux";
import {generateOrderDetailDataSheetPrintThunk} from "../../../store/thunks/order_detail_thunk";
import {addSpacesToNumber} from "../../../utils/helpers";

const PayWork = ({pay_work = [], getT}) => {
    const dispatch = useDispatch();
    const [filter, setFilter] = useState({
        shift: 1, service: 0, order: 0
    });

    const handlerChangeFilter = (key) => {
        setFilter(prev => ({
            shift: 0, order: 0, service: 0, [key]: 1
        }))
    }
    const data_filtered = useMemo(() => {
        if (!Array.isArray(pay_work) || !pay_work?.length) return null;

        let result_group = pay_work?.reduce((acc, item) => {
            let team_key = `team_${item.team_name_id}`
            let service_key = `service_${item.service_id}`
            let order_key = `order_${item.order_id}`
            let shift_key = `shift_${item.shift_number}`
            let acc_prev = {...acc};
            let team_prev = acc_prev.hasOwnProperty(team_key) ?
                {
                    service: {
                        ...acc_prev?.[team_key]?.service || {},

                        [service_key]: {
                            name: item?.service_name, service_id: item?.service_id, currency_name: item?.currency_name,
                            payment_amount: (acc_prev?.[team_key]?.service?.[service_key]?.payment_amount || 0) + Number(item.payment),
                            currency: item?.currency_name,
                        }
                    },
                    shift: {
                        ...acc_prev?.[team_key]?.shift || {},
                        [shift_key]: {
                            shift_number: item.shift_number,
                            payment_amount: (acc_prev?.[team_key]?.shift?.[shift_key]?.payment_amount || 0) + Number(item.payment),
                            currency: item?.currency_name,

                        }
                    },
                    order: {
                        ...acc_prev?.[team_key]?.order || {},
                        [order_key]: {
                            order_id: item.order_id,
                            payment_amount: (acc_prev?.[team_key]?.order?.[order_key]?.payment_amount || 0) + Number(item.payment),
                            order_status: item.order_status,
                            currency: item?.currency_name,
                        }
                    },

                } :
                {
                    service: {
                        [service_key]: {
                            name: item?.service_name, service_id: item?.service_id, currency_name: item?.currency_name,
                            payment_amount: Number(item.payment),
                            currency: item?.currency_name,
                        }
                    },
                    shift: {
                        [shift_key]: {
                            shift_number: item.shift_number, payment_amount: Number(item.payment),
                            currency: item?.currency_name,
                        }
                    },
                    order: {
                        [order_key]: {
                            order_id: item.order_id,
                            payment_amount: Number(item.payment),
                            order_status: item.order_status,
                            currency: item?.currency_name,
                        }
                    },


                }
            return {
                ...acc,
                [team_key]: {
                    team_name_id: item.team_name_id,
                    team_name: item?.team_name,
                    currency: item?.currency_name,
                    ...team_prev,
                }
            }

        }, {})

        return result_group

    }, [pay_work])
const handlerSheetDataOpen = (order_id)=> event=>{
        dispatch(generateOrderDetailDataSheetPrintThunk({order_id: order_id}))
}
    const renderType = (object) => {
        let key = !!filter.service ? 'service' : !!filter.order ? 'order' : 'shift';
        let Component = {
            ['service']: ItemService,
            ['order']: ItemOrder,
            ['shift']: ItemShift
        }
        let Item = Component[key]
        return Object.values(object[key])?.map(e => {
            return <Item item={e} getT={getT} handlerSheetDataOpen={handlerSheetDataOpen}/>
        })
    }
    const getAmountPaymentTeam = (item) => {
        let key = !!filter.service ? 'service' : !!filter.order ? 'order' : 'shift';

        return Object.values(item[key])?.reduce((amount, e)=>{
            let summ =  amount + Number(e.payment_amount)
            return  ( Math.round(summ * 100) / 100 )
        }, 0)
    }

    return (
        <div className="col-12 col-lg-10">
            <div className={"card"}>
                <div className="card-header px-2">
                    <div className="d-flex justify-content-between align-items-center flex-row">
                        <h5 className={"card-title font-weight-bold"}>{getT("Выполненная робота")}</h5>
                        <div className="d-flex" style={{gap: '10px'}}>
                            <div className="custom-control custom-radio mb-0">
                                <input type="radio" className="custom-control-input" id="pay_work1"
                                       checked={!!Number(filter.shift)} onChange={() => handlerChangeFilter("shift")}
                                       name={"filter-pay-work"}/>
                                <label className="custom-control-label"
                                       htmlFor="pay_work1">{getT("По смене")}</label>
                            </div>
                            <div className="custom-control custom-radio mb-0">
                                <input type="radio" className="custom-control-input" id="pay_work2"
                                       name={"filter-pay-work"} checked={!!Number(filter.service)}
                                       onChange={() => handlerChangeFilter("service")}
                                />
                                <label className="custom-control-label"
                                       htmlFor="pay_work2">{getT("По услугам")}</label>
                            </div>
                            <div className="custom-control custom-radio mb-0">
                                <input type="radio" className="custom-control-input" id="pay_work3"
                                       name={"filter-pay-work"} checked={!!Number(filter.order)}
                                       onChange={() => handlerChangeFilter("order")}
                                />
                                <label className="custom-control-label"
                                       htmlFor="pay_work3">{getT("По заказам")}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body  px-0 py-0">

                    {(data_filtered && Object.keys(data_filtered)?.length) && <ul className={"list-unstyled px-2 mb-0 py-2"}>

                        {Object.values(data_filtered)?.map((item) => {
                            let list =  renderType(item);
                            let summ = getAmountPaymentTeam(item)
                            return <li className={"list-group-item list-group-item-action px-0 py-0 "}>
                                <li className={"d-flex justify-content-between list-group-item-primary py-2 px-2"} style={{gap: '10px'}}>
                                    <strong>{getT("Бригада")} №{item?.team_name_id} </strong>
                                    <span>{item.team_name}</span>
                                </li>
                                <ul className={"list-unstyled px-2 mb-0 py-2"}>
                                    {list}
                                </ul>
                                <li className={"list-group-item-info py-2 px-2"}>
                                    <strong>{getT("Итого")}</strong> : {summ} {item.currency}</li>

                            </li>


                        })}
                    </ul>}
                </div>

            </div>
        </div>
    );
};


function ItemService({item, getT, handlerSheetDataOpen=null}) {
    let amount = ( Math.round(item?.payment_amount * 100) / 100 )
    return <li className={"d-flex justify-content-between"} style={{gap: '10px'}}>
        <strong>{item?.name}</strong>
        <span>{addSpacesToNumber(amount)}  {item.currency}</span>
    </li>
}

function ItemOrder({item, getT, handlerSheetDataOpen=null}) {

    let amount = ( Math.round(item?.payment_amount * 100) / 100 )
    return <li className={"d-flex justify-content-between my-1"} style={{gap: '10px'}}>
        <strong>№{item?.order_id}</strong>
        <div className="d-flex align-items-center" style={{gap: '10px'}}>
        <span>{amount}  {item.currency}</span>
        {handlerSheetDataOpen && <button className={"btn btn-sm btn-primary"} onClick={handlerSheetDataOpen(item?.order_id)}>{getT("Спецификация")}</button>}
        </div>
    </li>
}

function ItemShift({item, getT, handlerSheetDataOpen=null}) {
    let amount = ( Math.round(item?.payment_amount * 100) / 100 )
    return <li className={"d-flex justify-content-between"} style={{gap: '10px'}}>
        <strong>№{item?.shift_number}</strong>
        <span>{addSpacesToNumber(amount)}  {item.currency}</span>
    </li>
}

export default PayWork;