import React, {useState} from 'react';

const Card = ({title = null, bodyChildren = null, footerChildren = null, isCollapse, classNameCard=''}) => {
    const [isOpen, setIsOpen] = useState(false);
    const handlerIsOpen = () => {
        if (!isCollapse) return;
        setIsOpen(!isOpen)
    }
    const renderContent = () => {
        return <>
            {bodyChildren && <div className={`card-body `}>
                {bodyChildren}
            </div>}
            {footerChildren && <div className="card-footer">
                {footerChildren}
            </div>} </>
    }
    const cNameHeader = `card-header ${isCollapse && !isOpen ? 'border-0' : ' '} `
    return (
        <div className={`card ${isOpen ? 'border-info' : ''} ${classNameCard}`} style={{
            borderWidth: isOpen ? '1px': '0',
            boxShadow: isOpen ? '0 0 0 transparent': '0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2)'
        }}>
            {title && <div className={cNameHeader} onClick={handlerIsOpen}>
                <div className={"card-title"}>{title}</div> {isCollapse && <i className={`fas fa-angle-${isOpen ? 'up' : 'down'}`}></i>}</div>}

            {isCollapse ? isOpen ? renderContent() : null : renderContent()}

        </div>
    );
};

export default Card;