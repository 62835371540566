import React from 'react';
import {useForm} from "react-hook-form";
import {LS_USER_ID} from "../../../../../utils/constants";
import Button from "../../../../ui/Button";

const ShiftFormCu = ({form_values = null, handlerSubmit, getT}) => {
    let day = form_values?.data_start ? new Date(form_values?.data_start) : new Date()
    let today = day.toISOString().split('T')[0];
    let time = `${day.getHours() >= 10 ? day.getHours() : `0${day.getHours()}`}:${day.getMinutes() >= 10 ? day.getMinutes() : `0${day.getMinutes()}`}`
    const {register, handleSubmit, reset} = useForm();
    let defaultValues = {
        date: today,
        time: time,
        koef: form_values?.koef ?? null
    }
    const handlerCreate = (data) => {
        let {date, time, ...reset} = data
        let _api_data = {
            date_start: `${date} ${time}:00`, ...reset
        }
        handlerSubmit(_api_data, reset);
    }

    return (
        <form onSubmit={handleSubmit((data) => {
            handlerCreate(data)
        })}>
            <input {...register("user")} type={"hidden"} value={localStorage.getItem(LS_USER_ID)}/>
            <div className="form-group">
                <label htmlFor="exampleInputEmail1">{getT("Дата начало смены")}</label>
                <input {...register("date", {
                    value: defaultValues.date
                })} className="form-control" type={"date"} required={true}
                       min={today.split('T')[0]}
                />
            </div>
            <div className="form-group">
                <label htmlFor="exampleInputEmail1">{getT("Время начало смены")}</label>
                <input {...register("time", {
                    value: defaultValues.time
                })} className="form-control" type={"time"} min="00:00" max="23:59" pattern="[0-2][0-9]:[0-5][0-9]"
                       required={true}/>
            </div>
            <div className="form-group">
                <label htmlFor="exampleInputEmail1">{getT("Коэффициент")}</label>
                <input {...register("koef", {
                    value: defaultValues.koef
                })} className="form-control" type={"number"} step="0.1" required={true}
                />
            </div>
            <div className="text-center">
                <Button type={"submit"}>
                    {/*<>{form_values ? getT("Обновить") : getT("Создать")}</>*/}
                    {getT("Отправить")}
                </Button>
            </div>
        </form>
    );
};

export default ShiftFormCu;