import "./App.css";
import "./plugins/toastr/toastr.min.css";

import React, {useEffect, useMemo} from "react";
import {Route, Switch, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {LS_USER_ID, LS_WORKER_ID} from "./utils/constants";
import {checkingUserAuthorizationInSystem} from "./store/reducers/AuthReducer";

import LoginContainer from "./components/Login/LoginContainer";
import DashboardContainer from "./components/Dashboard/DashboardContainer";
import OrdersContainer from "./components/Orders/OrdersContainer";
import OrdersDetailContainer from "./components/Orders/OrdersDetailContainer";
import ClientsContainer from "./components/Clients/ClientsContainer";
import ClientsAddContainer from "./components/Clients/ClientsAddContainer";
import ClientsDetailContainer from "./components/Clients/ClientsDetailContainer";
import ProductionTaskContainer from "./components/Production/ProductionTaskContainer";
import OrderAddContainer from "./components/Orders/OrderAddContainer";
import ReportsContainer from "./components/Reports/ReportsContainer";
import OrderSearchContainer from "./components/Orders/OrderSearchContainer";
import OrdersShippedContainer from "./components/Orders/OrdersShippedContainer";
import OrdersCanceledContainer from "./components/Orders/OrdersCanceledContainer";
import OrdersProblemContainer from "./components/Orders/OrdersProblemContainer";
import OrdersOutstandingContainer from "./components/Orders/OrderOutstandingContainer";
import OrdersGroupDetailContainer from "./components/GroupOrders/OrdersGroupDetailContainer";
import GroupPage from "./components/GroupOrders/GroupPage";
import ArchiveProjectPage from "./components/ArchiveProject/ArchiveProjectPage";
import NewsContainer from "./components/News/NewsContainer";
import SalaryProjectDefaultPlanePage from "./pages/SalaryProjectDefaultPlane";
import SalaryProjectRatesPage from "./pages/SalaryProjectRates";
import SalaryProjectShiftPage from "./pages/SalaryProjectShift";
import SalaryShiftOpenPage from "./pages/SalaryShiftOpenPage";
import SalaryProjectReadyMark from "./pages/SalaryProjectReadyMark";
import ProtectedSalary from "./components/Protected/ProtectedSalary";
import SalaryProjectReadinessReport from "./pages/SalaryProjectReadinessReport";
import Layout from "./components/Layout/Layout";
import ProtectedOnlyUser from "./components/Protected/ProtectedOnlyUser";
import EmployeePersonalDataPage from "./pages/EmployeePersonalDataPage";
import {getMainSettingsProject, getNewsThunk} from "./store/reducers/MainReducer";
import SalaryScannerReadMarkPage from "./pages/SalaryScannerReadMarkPage";
import SalaryReportPage from "./pages/SalaryReportPage";
import SalaryProjectSearchOrder from "./pages/SalaryProjectSearchOrder";
import HelpArticleModal from "./components/Modals/HelpArticle/HelpArticleModal";
import StatisticXncOperationsPage from "./pages/StatisticXncOperationsPage";
import StatisticXncOperationsMaterialsPage from "./pages/StatisticXncOperationsMaterialsPage";
import StatisticsInteractiveFormPage from "./pages/StatisticsInteractiveFormPage";
import ConfirmCommonModal from "./components/Modals/ConfirmCommonModal";
import WsCompanyConnectionRequestPage from "./pages/WsCompanyConnectionRequestPage";
import WsCompaniesPage from "./pages/WsCompaniesPage";
import WSProductsFirmPage from "./pages/WSOperationProductGroupFirmPage";
import WSGoodsStockRetailOfFirm from "./pages/WSGoodsStockRetailOfFirm";
import SettingsCompanyPage from "./pages/SettingsCompanyPage";
import WSProductNeedPage from "./pages/WSProductNeedPage";
import WsOrdersSupplyPage from "./pages/WSOrdersSupplyPage";


export default function AppContainer() {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        // Вызываем функцию для проверки авторизации при загрузке приложения
        dispatch(checkingUserAuthorizationInSystem());
        //

        // Добавляем слушатель для перехвата изменений маршрута
        const unlisten = history.listen(() => {
            // Вызываем функцию для проверки авторизации при каждом изменении маршрута
            dispatch(checkingUserAuthorizationInSystem());
        });
        // Очищаем слушатель при размонтировании компонента
        return () => {
            unlisten();
        };
    }, [dispatch, history]);
    useEffect(()=>{
        const handleWheel = (event) => {
            if (document.activeElement.type === 'number') {
                document.activeElement.blur();
            }
        };

        const handleKeyDown = (event) => {
            if (document.activeElement.type === 'number' && (event.key === 'ArrowUp' || event.key === 'ArrowDown')) {
                event.preventDefault();
            }
        };

        window.addEventListener('wheel', handleWheel);
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('wheel', handleWheel);
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [])

    return <>
        <App/>
        <HelpArticleModal/>
        <ConfirmCommonModal/>

    </>
}

const App = () => {
    const dispatch = useDispatch();
    const isAuth = useSelector(state => state.auth.isAuth)
    let is_authorized = useMemo(() => {
        let is_has_id = localStorage.getItem(LS_USER_ID) || localStorage.getItem(LS_WORKER_ID);
        return isAuth && !!Number(is_has_id)
    }, [isAuth])
    useEffect(()=>{
        let is_has_id = localStorage.getItem(LS_USER_ID) || localStorage.getItem(LS_WORKER_ID);
        if(isAuth && !!Number(is_has_id)) {
            dispatch(getNewsThunk(1))
            dispatch(getMainSettingsProject())
        }
    }, [isAuth]);

    if (!is_authorized) {
        return <LoginContainer/>
    }
    return (

        <Layout>
            <Switch>
                <Route exact path={"/"}>
                    {localStorage.getItem(LS_USER_ID) ? <DashboardContainer/> : <NewsContainer/>}
                </Route>
                <Route exact path={"/dashboard"}>
                    <ProtectedOnlyUser><DashboardContainer/></ProtectedOnlyUser>
                </Route>
                <Route exact path={"/login"}>
                    <ProtectedOnlyUser><DashboardContainer/></ProtectedOnlyUser>
                </Route>
                <Route exact path={"/orders/actual"}>
                    <ProtectedOnlyUser> <OrdersContainer archive={0}/></ProtectedOnlyUser>
                </Route>
                <Route exact path={"/orders/search"}>
                    <ProtectedOnlyUser><OrderSearchContainer archive={0}/></ProtectedOnlyUser>
                </Route>
                <Route exact path={"/orders/group"}>
                    <ProtectedOnlyUser> <GroupPage/></ProtectedOnlyUser>
                </Route>

                <Route
                    exact
                    path={"/orders/group/details/:id"}
                    render={(match) => <ProtectedOnlyUser><OrdersGroupDetailContainer uri={match}/></ProtectedOnlyUser>}
                />
                <Route
                    exact
                    path={"/reports/:type"}
                    render={(match) => <ProtectedOnlyUser><ReportsContainer uri={match}/></ProtectedOnlyUser>}
                />
                <Route exact path={"/orders/canceled"}>
                    <ProtectedOnlyUser> <OrdersCanceledContainer archive={0} onlyCanceled={1}/></ProtectedOnlyUser>
                </Route>

                <Route exact path={"/orders/shipped"}>
                    <ProtectedOnlyUser><OrdersShippedContainer archive={0} onlyShipped={1}/></ProtectedOnlyUser>
                </Route>
                <Route exact path={"/orders/production"}>
                    <ProtectedOnlyUser> <ProductionTaskContainer/></ProtectedOnlyUser>
                </Route>
                <Route exact path={"/orders/problem"}>
                    <ProtectedOnlyUser> <OrdersProblemContainer archive={0}/></ProtectedOnlyUser>
                </Route>
                <Route exact path={"/orders/outstanding"}>
                    <ProtectedOnlyUser> <OrdersOutstandingContainer archive={0} onlyShipped={1}/></ProtectedOnlyUser>
                </Route>
                <Route exact path={"/orders/add"}>
                    <ProtectedOnlyUser> <OrderAddContainer/></ProtectedOnlyUser>
                </Route>
                <Route exact path={"/clients/add"}>
                    <ProtectedOnlyUser><ClientsAddContainer archive={1}/></ProtectedOnlyUser>
                </Route>
                <Route
                    exact
                    path={"/orders/detail/:id"}
                    render={(match) => <ProtectedOnlyUser><OrdersDetailContainer uri={match}/></ProtectedOnlyUser>}
                />

                <Route exact path={"/clients"}>
                    <ProtectedOnlyUser> <ClientsContainer/></ProtectedOnlyUser>
                </Route>

                <Route
                    exact
                    path={"/clients/detail/:id"}
                    render={(match) => <ProtectedOnlyUser><ClientsDetailContainer uri={match}/></ProtectedOnlyUser>}
                />
                <Route
                    exact
                    path={'/archive'}
                    render={() => <ProtectedOnlyUser><ArchiveProjectPage/></ProtectedOnlyUser>}
                />

                <Route exact path={'/news'} render={() => <NewsContainer/>}/>
                <Route exact={true} path={'/salary-project/default-plane'}
                       render={() =>
                           <ProtectedSalary><SalaryProjectDefaultPlanePage/></ProtectedSalary>}/>

                <Route exact={true} path={'/salary-project/rates'}
                       render={() => <ProtectedSalary><SalaryProjectRatesPage/></ProtectedSalary>}/>
                <Route exact={true} path={'/salary-project/shift'}
                       render={() => <ProtectedSalary><SalaryProjectShiftPage/></ProtectedSalary>}/>

                <Route exact={true} path={'/salary-project/shift/:id'}
                       render={(match) => <ProtectedSalary>
                           <SalaryShiftOpenPage match={match}/>
                       </ProtectedSalary>}/>
                <Route exact={true} path={'/salary-project/ready-mark'}
                       render={() => <ProtectedSalary> <SalaryProjectReadyMark/></ProtectedSalary>}/>
                <Route exact={true} path={'/salary-project/scanner-ready-mark'}
                       render={() => <ProtectedSalary> <SalaryScannerReadMarkPage/></ProtectedSalary>}/>
                <Route exact={true} path={'/salary-project/readiness-report'}
                       render={() => <ProtectedSalary>
                           <SalaryProjectReadinessReport/></ProtectedSalary>}/>
                <Route exact={true} path={'/salary-project/salary-report'}
                       render={() => <ProtectedSalary><SalaryReportPage/></ProtectedSalary>}/>
                <Route exact={true} path={'/salary-project/orders'}
                       render={() => <ProtectedSalary><SalaryProjectSearchOrder/></ProtectedSalary>}/>

                {localStorage.getItem(LS_WORKER_ID) &&
                    <Route exact={true} path={'/salary-project/personal-data'}
                           render={() =>
                               <ProtectedSalary><EmployeePersonalDataPage/></ProtectedSalary>}/>
                }

                <Route exact={true} path={'/statistic/xnc-operations'} render={() => <StatisticXncOperationsPage/>}/>
                <Route exact={true} path={'/statistic/xnc-operations-materials'} render={() => <StatisticXncOperationsMaterialsPage/>}/>
                <Route exact={true} path={'/material_band_connected'} render={() => <StatisticsInteractiveFormPage/>}/>


                <Route exact={true} path={'/ws-company-connection-request'} render={() => <WsCompanyConnectionRequestPage/>}/>
                <Route exact={true} path={'/ws-companies'} render={() => <WsCompaniesPage/>}/>

                <Route exact={true} path={'/ws-products-company/:main_firm'} component={WSProductsFirmPage}/>
                <Route exact={true} path={'/ws-goods-stock-retail'} component={WSGoodsStockRetailOfFirm}/>
                <Route exact={true} path={'/settings-company'} component={SettingsCompanyPage}/>

                <Route exact={true} path={'/ws-product-needs'} component={WSProductNeedPage}/>
                <Route exact={true} path={'/ws-orders-supply'} component={WsOrdersSupplyPage}/>



            </Switch>
        </Layout>
    )
};



