import {instances} from "./Salary/setting_api";
import {rejectTransform, resolveTransform} from "./utils_errors";
import {LS_FIRM} from "../utils/constants";

const URL = '/settings'
export const SettingAPI = {
    getAllByFirm(firm = null) {
        let firm_id = firm ? firm : localStorage.getItem(LS_FIRM)
        return instances.get(URL + '/firm_get/', null).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    updateSetting(data){
        return instances.post(URL, data).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    deleteSetting(setting_id){
        return instances.delete(URL+'/'+setting_id, null).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }

}