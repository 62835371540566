import {
    getClientDataThunk,
    getManagersDataThunk,
    setAlertMessageThunk,
    setClientsDataThunk,
    toggleLoaderStatusAC, updateSettingsThunk
} from "./MainReducer";
import ApiService from "../../api/api";

const api = new ApiService();
const ARCHIVE_PROJECT_GET_ALL = 'ARCHIVE_PROJECT/GET_ALL'
const ARCHIVE_PROJECT_FILTER_UPDATE = 'ARCHIVE_PROJECT/FILTER_UPDATE'
const ARCHIVE_PROJECT_FILTER_RESET = 'ARCHIVE_PROJECT/FILTER_RESET'

const initialState = {
    filter: {
        id: '',
        code: '',
        code1c: '',
        client: null,
        user: '',
        status: '',
        name: ''
    },
    entries: null
}
export const ArchiveProjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case ARCHIVE_PROJECT_GET_ALL: {
            return {
                ...state,
                entries: action.payload
            }
        }
        case ARCHIVE_PROJECT_FILTER_UPDATE: {
            const {key, value} = action.payload
            return {
                ...state,
                filter: {
                    ...state.filter,
                    [key]: value
                }
            }
        }
        case ARCHIVE_PROJECT_FILTER_RESET: {
            return {
                ...state,
                filter: {...initialState.filter}
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}

export const actionsArchiveProject = {
    getAll: (entries) => ({type: ARCHIVE_PROJECT_GET_ALL, payload: entries}),
    resetFilter: () => ({type: ARCHIVE_PROJECT_FILTER_RESET}),
    updateFilter: ({key, value}) => ({type: ARCHIVE_PROJECT_FILTER_UPDATE, payload: {key, value}})
}

export const setArchiveProjectEntries = () => async dispatch => {

    try {
        const response = await api._archiveProjectGetAll();
        if (response && response.error) {
            dispatch(setAlertMessageThunk(response.error, "error"));
        } else {
            dispatch(actionsArchiveProject.getAll(response));
        }

    } catch (e) {
        console.log(e.message);
        dispatch(toggleLoaderStatusAC(false))
    }
}
export const initialArchiveProject = () => dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true))
        dispatch(setArchiveProjectEntries());
        dispatch(updateSettingsThunk())
        // dispatch(getManagersDataThunk(false));
        dispatch(setClientsDataThunk(false))
        dispatch(toggleLoaderStatusAC(false))
    } catch (e) {
        console.log(e.message);
        dispatch(toggleLoaderStatusAC(false))
    }

}
export const loadArchiveProjectFilter = (formData, field) => async dispatch => {
    dispatch(toggleLoaderStatusAC(true))
    try {
        dispatch(actionsArchiveProject.updateFilter({key: field.key, value: field.value}))
        const response = await api._archiveProjectFilter({
            ...formData,
            [field.key]: field.value
        });

        if (response && response.error) {
            dispatch(setAlertMessageThunk(response.error, "error"));
        }
        if (!response.hasOwnProperty('error')) {
            dispatch(actionsArchiveProject.getAll(response))
        }


    } catch (e) {
        console.log(e.message);
        dispatch(toggleLoaderStatusAC(false))
    }
    dispatch(toggleLoaderStatusAC(false))
}
export const archiveDownloadPdf = (code) => async dispatch => {
    dispatch(toggleLoaderStatusAC(true))
    try {
        const response = await api._archiveProjectDownload(code);
        if (response && response.error) {
            dispatch(setAlertMessageThunk(response.error, "error"));
        }

    } catch (e) {
        console.log(e.message);
        dispatch(toggleLoaderStatusAC(false))
    }
    dispatch(toggleLoaderStatusAC(false))
}

export const resetFilterArchiveProject = () => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true))
        dispatch(actionsArchiveProject.resetFilter())
        dispatch(setArchiveProjectEntries());
        dispatch(toggleLoaderStatusAC(false))
    } catch (e) {
        console.log(e.message);
        dispatch(toggleLoaderStatusAC(false))
    }

}
export const archiveProjectEntries = (state) => state?.archive?.entries ?? initialState.entries;
export const archiveProjectFilter = state => state?.archive?.filter ?? initialState.filter