import React, {useState, useEffect} from "react";
import PageTitle from "../PageTitle/PageTitle";
import {Link} from "react-router-dom";
import "../Orders/ResponsiveTable.css";
import {withLang} from "../../hoc/withLang";
import {useSelector} from "react-redux";
import {gaEvents} from "../../gaEvents";

const LeftToMake = (props) => {

    const lang = useSelector(state => state.translation.local)
    useEffect(() => {
        const unique = props.data.data?.filter(
            (
                (set) => (f) =>
                    !set.has(f.data.serv_type) && set.add(f.data.serv_type)
            )(new Set())
        );

        setFilters(
            unique?.map((el) => {
                return {id: el.service_type, name: el.data.serv_type_info[lang]};
            })
        );
    }, [props.data]);
    const [colapseOverdue, setColapseOverdue] = useState(false);
    const toggleColapseOverdue = () => setColapseOverdue(!colapseOverdue);
    const [colapseOrders, setColapseOrders] = useState(false);
    const toggleColapseOrders = () => setColapseOrders(!colapseOrders);
    const [filters, setFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(filters);

    let outOfDateSumm = 0;
    outOfDateSumm = props.data.data?.filter(
        (el) => el.data.serv_type === selectedFilter && el.type === "out_of_date"
    );
    let orderInTimeSumm;
    orderInTimeSumm = props.data.data?.filter(
        (el) => el.data.serv_type === selectedFilter && el.type === "in_time_order"
    );
    return (
        <div className="content-wrapper">
            <PageTitle title={"reports_leftMake"}/>
            <section className="content">
                <div className="form-group">
                    <label>{props.getT("Типы услуг")}:</label>
                    <select
                        className="form-control"
                        onChange={(e) => {
                            gaEvents.customEvent('form_order_reports_leftMake', {
                                field_focus: 'Типы услуг'
                            })
                            setSelectedFilter(e.target.value)
                        }}

                    >
                        <option value="">{props.getT("Выберите необходимую услугу")}</option>
                        {filters &&
                            filters.map((el) => (
                                <option key={el.id} value={el.id}>
                                    {el.name}
                                </option>
                            ))}
                    </select>
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">
                            {props.getT(`Заказов`)}:   &nbsp;  {props.data.count_orders}
                        </h3>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card">
                        <div className="accordion" id="accordionExample">
                            <div className="card">
                                <div className="card-header" id="headingOne">
                                    <h2 className="mb-0">
                                        <button
                                            className="btn btn-link"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="#collapseOne"
                                            aria-expanded="true"
                                            aria-controls="collapseOne"
                                            onClick={() => {
                                                toggleColapseOverdue();
                                            }}
                                        >
                                            {props.getT("Просроченные заказы")}
                                        </button>
                                    </h2>
                                </div>

                                <div
                                    id="collapseOne"
                                    className={colapseOverdue ? "collapse show" : "collapse hide"}
                                    aria-labelledby="headingOne"
                                    data-parent="#accordionExample"
                                >
                                    <div className="card-body">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th scope="col">{props.getT("ID")}</th>
                                                <th scope="col">{props.getT("Услуга")}</th>
                                                <th scope="col">{props.getT("Клиент")}</th>
                                                <th scope="col">{props.getT("Количество")}</th>
                                                <th scope="col">{props.getT("Количество деталей")}</th>
                                                <th scope="col">{props.getT("Дата готовности")}</th>
                                                <th scope="col">{props.getT("Действие")}</th>
                                            </tr>
                                            </thead>
                                            {props.data.data
                                                ? props.data.data
                                                    .filter(
                                                        (el) =>
                                                            el.type === "out_of_date" &&
                                                            el.data.serv_type === selectedFilter
                                                    )

                                                    .map((e) => {
                                                        return (
                                                            <tbody>
                                                            <tr>
                                                                <td data-label={props.getT("ID")}>{e.data.id}</td>
                                                                <td data-label={props.getT("Услуга")}>{e.data[lang]}</td>
                                                                <td data-label={props.getT("Клиент")}>
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            flexDirection: "column"
                                                                        }}
                                                                    >
                                                                        <span>{e.data.client_name}</span>
                                                                        <span>{e.data.client_email}</span>
                                                                        <span>{e.data.client_phone_main}</span>
                                                                    </div>
                                                                </td>
                                                                <td data-label={props.getT("Количество")}>
                                                                    {Number(e.data.count).toFixed(2)}
                                                                </td>
                                                                <td data-label={props.getT("Количество деталей")}>
                                                                    {e.data.count_detail}
                                                                </td>
                                                                <td data-label={props.getT("Дата готовности")}>
                                                                    {e.data.plan_of_production}
                                                                </td>
                                                                <td data-label={props.getT("Действие")}>
                                                                    <Link
                                                                        target="_blank"
                                                                        to={"/orders/detail/" + e.data.id}
                                                                    >
                                                                        <i className="far fa-eye"> </i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        );
                                                    })
                                                : []}
                                            <tfoot>
                                            <tr>
                                                <td colSpan="7">
                                                    {" "}
                                                    <h3 className="float-right">
                                                        {props.getT("Итого")} : &nbsp;
                                                        {outOfDateSumm &&
                                                            outOfDateSumm
                                                                .reduce(
                                                                    (n, {data}) => n + Number(data.count),
                                                                    0
                                                                )
                                                                .toFixed(2)}
                                                    </h3>
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h2 className="mb-0">
                                        <button
                                            className="btn btn-link collapsed"
                                            type="button"
                                            data-toggle="collapse"
                                            data-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                            onClick={() => toggleColapseOrders()}
                                        >
                                            {props.getT("Заказы в работе")}
                                        </button>
                                    </h2>
                                </div>

                                <div
                                    id="collapseTwo"
                                    className={colapseOrders ? "collapse show" : "collapse hide"}
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordionExample"
                                >
                                    <div className="card-body">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th scope="col">{props.getT("ID")}</th>
                                                <th scope="col">{props.getT("Услуга")}</th>
                                                <th scope="col">{props.getT("Клиент")}</th>
                                                <th scope="col">{props.getT("Количество")}</th>
                                                <th scope="col">{props.getT("Количество деталей")}</th>
                                                <th scope="col">{props.getT("Дата готовности")}</th>
                                                <th scope="col">{props.getT("Действие")}</th>
                                            </tr>
                                            </thead>
                                            {props.data.data
                                                ? props.data.data
                                                    .filter(
                                                        (el) =>
                                                            el.type === "in_time_order" &&
                                                            el.data.serv_type === selectedFilter
                                                    )

                                                    .map((e) => {
                                                        return (
                                                            <tbody>
                                                            <tr>
                                                                <td data-label={props.getT("ID")}>{e.data.id}</td>
                                                                <td data-label={props.getT("Услуга")}>{e.data[lang]}</td>
                                                                <td data-label={props.getT("Клиент")}>
                                                                    {e.data.client_name}
                                                                </td>
                                                                <td data-label={props.getT("Количество")}>
                                                                    {Number(e.data.count).toFixed(2)}
                                                                </td>
                                                                <td data-label={props.getT("Количество деталей")}>
                                                                    {e.data.count_detail}
                                                                </td>
                                                                <td data-label={props.getT("Дата готовности")}>
                                                                    {e.data.plan_of_production}
                                                                </td>
                                                                <td data-label={props.getT("Действие")}>
                                                                    <Link
                                                                        target="_blank"
                                                                        to={"/orders/detail/" + e.data.id}
                                                                    >
                                                                        <i className="far fa-eye"> </i>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        );
                                                    })
                                                : []}
                                            <tfoot>
                                            <tr>
                                                <td colSpan="7">
                                                    {" "}
                                                    <h3 className="float-right">
                                                        {props.getT("Итого")} : &nbsp;
                                                        {orderInTimeSumm &&
                                                            orderInTimeSumm
                                                                .reduce(
                                                                    (n, {data}) => n + Number(data.count),
                                                                    0
                                                                )
                                                                .toFixed(2)}
                                                    </h3>
                                                </td>
                                            </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default withLang(LeftToMake);
