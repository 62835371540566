import {instances, URI_API_EXTTUNELL} from "./setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const GET_PLANE_BY_SHIFT = 'get_plan_by_shift'
const ADD_PLANE_BY_SHIFT = "add_plan_by_shift"
const UPDATE_PLANE_BY_SERVICE_TYPE ="update_plan_by_service_type"
const DELETE_PLAN_BY_SHIFT ="delete_plan_by_shift"
/**
 * Базовые справочники
 * @method getById  - получить смену по id
 *
 * @method add  -создать план для смены
 */
export const ShiftPlaneAPI = {
    getByShift(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: GET_PLANE_BY_SHIFT
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    add(form_data){
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: ADD_PLANE_BY_SHIFT
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    update(form_data){
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: UPDATE_PLANE_BY_SERVICE_TYPE
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    delete(form_data){
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: DELETE_PLAN_BY_SHIFT
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }

}