import React, {forwardRef, useImperativeHandle, useState} from 'react';
import Modal from "../MyModal/Modal";
import {useDispatch} from "react-redux";
import {updateAdditionalDocument} from "../../../store/thunks/salary_project/addition_document_thunk";
import AdditionalDocumentForm from "./components/AdditionalDocument/AdditionalDocumentForm";

const SalaryAdditionalDocumentCreate = forwardRef(({getT, refModal}) => {
    const dispatch = useDispatch()
    const [modal_dt, setModalDt] = useState({isOpen: false, initial_form: null});
    useImperativeHandle(refModal, () => {
        return {
            open(initial_form) {
                setModalDt({isOpen: true, initial_form: initial_form});
            }
        };
    }, []);
    const handlerCloseModal = () => {
        setModalDt({isOpen: false, initial_form: null})
    }
    const handlerSubmitForm = (values) => {
        let shift = modal_dt.initial_form?.shift;
        let salary_additional_document_id = modal_dt.initial_form?.salary_additional_document_id;
        dispatch(updateAdditionalDocument({...values, shift_id: shift,salary_additional_document_id: salary_additional_document_id }, handlerCloseModal))
    }
    return (
        <Modal open={modal_dt.isOpen} onClose={handlerCloseModal} title={getT("Обновить документ")}>
            <AdditionalDocumentForm getT={getT} handlerSubmitForm={handlerSubmitForm}
                                    initial_form_value={modal_dt.initial_form}/>
        </Modal>
    );
});

export default SalaryAdditionalDocumentCreate;