import React, {useMemo} from 'react';
import WSOrdersGoodsSetForm from "../../../../../components/WholeSale/Forms/WSOrderGoods/WSOrdersGoodsSetForm";
import Modal from "../../../../../components/Modals/MyModal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getWSOrderGoodsSetModal} from "../../../../../store/selectors/wholesalary_orders_goods_selector";
import {wsOrderGoodsSetModalAC} from "../../../../../store/actions/WholesaleOrdersGoodsActions";
import {getWSOrdersSupply} from "../../../../../store/selectors/wholesalary_orders_supply_selector";
import {
    wsCreateOrUpdateNeedOrderGoodsModalThunk
} from "../../../../../store/thunks/wholesale/orders_goods/ws_order_goods_thunk";
import "./main.css"

const WSOrderGoodsSetModal = ({getT}) => {
    const dispatch = useDispatch();
    const orderGoodsM = useSelector(getWSOrderGoodsSetModal);
    const ordersSupply = useSelector(getWSOrdersSupply);
    let initialValues = useMemo(() => {
        let currentGoods = orderGoodsM?.currentGoods ?? null
        let payload = {
            orders_goods_id: currentGoods?.orders_goods_id ?? null,
            ws_orders_goods_id: currentGoods?.ws_orders_goods_id ?? null,
            order_supply: null,
            order_supply_unlink: null,
            order_main_firm: null
        }
        let orderSupply = currentGoods?.order_supply ?? null;
        if (orderSupply?.status?.ws_orders_supply_id && !!Number(orderSupply?.status?.ws_orders_supply_id)) {
            payload = {
                ...payload, order_supply: {
                    value: Number(orderSupply?.status?.ws_orders_supply_id),
                    label: `${orderSupply?.status?.name} | ${orderSupply?.firm_main?.name_firm ?? '-'}`,
                    firm_main: {
                        id: orderSupply?.firm_main?.id_firm_main ?? null,
                        name: orderSupply?.firm_main?.name_firm ?? '-',
                    }
                }
            }
        }
        return payload
    }, [orderGoodsM.currentGoods]);

    const onClose = () => {
        dispatch(wsOrderGoodsSetModalAC({isOpen: false, currentGoods: null, filter: null}))
    }
    const handSend = (data) => {
        dispatch(wsCreateOrUpdateNeedOrderGoodsModalThunk({set_api_data: data, filter_get_orders_goods: orderGoodsM.filter}))
    }
    let titleForm = !!Number(initialValues?.ws_orders_goods_id) ? "Обновить потребность" : "Создать потребность";
    return (
        <Modal open={orderGoodsM?.isOpen} onClose={onClose} title={titleForm}>
            <div className="orders-goods-set-wrapper">

                <WSOrdersGoodsSetForm getT={getT} handlerSubmit={handSend}
                                      orders_goods_id={initialValues?.orders_goods_id}
                                      order_supply={initialValues?.order_supply}
                                      ws_orders_goods_id={initialValues?.ws_orders_goods_id}
                                      order_supply_unlink={initialValues?.order_supply_unlink}
                                      order_main_firm={initialValues?.order_main_firm}
                                      wholesale={null}
                                      orders_supply={ordersSupply?.data}
                />
            </div>

        </Modal>
    );
};

export default WSOrderGoodsSetModal;