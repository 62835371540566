import {instances, URI_API_EXTTUNELL} from "./setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const GET_SALORY_REPORT_BY_WORKERS= "get_salory_report_by_workers"

/**
 * ОТЧЕТЫ
 * @method
 */
export const SaloryReportAPI = {
    getReportWorkers(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: GET_SALORY_REPORT_BY_WORKERS
            },
            // params: params
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },


}