import React, {useEffect, useState} from 'react';
import Button from "../../../../../ui/Button";

/**
 * Тип услуги Порезка. Отметка по детально
 *
 * @param {function}  getT -  перевод
 * @param {function}  handlerSubmit - запрос на убрать добавить отметки
 * @param {object}  group_detailing_of_material - группировка деталей по материалу
 * @returns  {jsx}  -
 */
const CuttingDetailing = ({group_detailing_of_material, getT, handlerSubmit, readOnly = false}) => {
    const [values, setValues] = useState(group_detailing_of_material);

    useEffect(() => {
        let is_change_detailing = JSON.stringify(group_detailing_of_material) !== JSON.stringify(values)
        if (is_change_detailing) {
            setValues(group_detailing_of_material)
        }
    }, [group_detailing_of_material]);
    //ф-ция toggle for input checked part
    const handlerValues = (part, material_id) => (e) => {
        let checked = e.target.checked;
        let material_key = `material_${material_id}`
        let part_key = `part_${part.part_id}`;
        let copy_val = {...values};
        let part_current = copy_val[material_key].parts[part_key];
        let is_root = part_current?.count === part?.count;
        let update_part_child = part_current?.child_part?.map((item) => {
            if (is_root) {
                return {...item, is_checked: checked}
            }
            let is_part = Number(item?.count) === Number(part?.count) && Number(part?.index) === Number(item.index);
            if (is_part) {
                return {
                    ...item, is_checked: checked,
                }
            }
            return item
        })

        let part_result = {
            ...part_current,
            child_part: update_part_child,
            is_checked: update_part_child?.every(item => item?.is_checked)

        }
        copy_val[material_key] = {
            ...copy_val[material_key],
            parts: {
                ...copy_val[material_key].parts,
                [part_key]: {
                    ...part_result
                }
            }
        }
        setValues(copy_val)
    }
    //::TODO стоит заглушка отправки qr links
    const handlerSubmitMark = () => {
        if (!values) return
        let data = Object.values(values);
        let put_data = data?.reduce((acc, item) => {
            for (const [key, part] of Object.entries(item.parts)) {
                let part_in_put = part?.child_part?.filter(child => (child?.is_checked !== child?.is_initial_checked) && child.is_checked)
                if (part_in_put?.length) {
                    part_in_put?.forEach(item => {
                        acc.push({
                            part_id: item?.part_id, count: item?.count, service_add: part.service_add,
                            // links: []
                        })
                    })

                }
            }

            return acc
        }, [])
        let delete_data = data?.reduce((acc, item) => {
            for (const [key, part] of Object.entries(item.parts)) {
                let part_in_delete = part?.child_part?.filter(child => !!child.ready_services && !child.is_checked)
                if (part_in_delete?.length) {
                    part_in_delete?.forEach(item => {
                        acc.push(item?.ready_services?.MySQL_record_id)
                    })
                }
            }

            return acc
        }, [])
        let payload = {}
        if (put_data?.length) {
            payload['put'] = {entry: put_data}
        }
        if (delete_data?.length) {
            payload['remove'] = {entry: delete_data}
        }
        handlerSubmit({
            ...payload
        })
    }
    return (
        <div className="cutting-wrap">
            {Object.values(group_detailing_of_material)?.map(item => {
                // return JSON.stringify(item)
                return <div className={'cutting-item'} key={`mat_${item.material?.id}`}>
                    <div className="material "> [{item?.material?.id}] {item?.material?.name}</div>

                    <div className="part-list">
                        <span>{getT("Детали")}:</span>
                        {Object.entries(item.parts).map(([key, part]) => {
                            let checked = values[`material_${item?.material?.id}`]?.parts[key]?.is_checked;
                            return <div className="part mb-2"
                                        key={`mat_${item.material?.id}_part${part?.part_id}`}>
                                <div className="form-group form-check mb-1">
                                    <input type="checkbox" className="form-check-input" id={`check_${part?.part_id}`}
                                           checked={checked} onChange={handlerValues(part, item.material.id)}
                                           readOnly={readOnly}
                                    />
                                    <label className={` form-check-label`} htmlFor={`check_${part?.part_id}`}>
                                        [id= {part?.part_id}] - [{part?.length} x {part?.width}] - Σ {part?.count}
                                    </label>
                                </div>
                                {!!(part?.child_part?.length >= 2) && part?.child_part?.map((part_child) => {
                                    let checked = values[`material_${item?.material?.id}`]?.parts[key]?.child_part?.find(item => item?.count === part_child?.count && item?.index === part_child?.index)?.is_checked;
                                    return <div className="form-group form-check pl-5 my-1">
                                        <input type="checkbox" className="form-check-input"
                                               id={`child_check_${part?.part_id}`}
                                               checked={checked} onChange={handlerValues(part_child, item.material.id)}
                                               disabled={readOnly}
                                        />
                                        <label className={` form-check-label`}
                                               htmlFor={`child_check_${part_child?.part_id}`}>
                                            [id= {part_child?.part_id}] - [{part_child?.length} x {part_child?.width}] -
                                            Σ {part_child?.count}
                                        </label>
                                    </div>
                                })}
                            </div>
                        })}
                    </div>
                </div>
            })}

            {readOnly ? <></> :
                !!values && <div className="d-flex justify-content-center">
                    <Button onClick={handlerSubmitMark}>{getT("Отправить")}</Button>
                </div>
            }
        </div>

    );
};

export default CuttingDetailing;