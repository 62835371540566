import {dispatcherErrorThunk} from "../common_thunk";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {
    wholesaleGoodsCombineRetailPriceFromPartnerSourceSetAC,
    wholesaleGoodsRetailPriceAddModalAC,
    wholesaleGoodsRetailPriceSetAC
} from "../../actions/WholesaleActions";
import {WholesaleGoodsRetailPriceAPI} from "../../../api/Wholesale/WholesaleGoodsRetailPriceAPI";

/**
 * Получить список цен для парнера и списко базовых цен на товары
 * add_all_ws_prices=1 - флаг, который вернёт две коллекции: цены, установленные для партнёров, и цены для товаров.
 * @param {object}  params - include firm_main || firm_partner
 * @param {object|| null}  api_data - only [array]
 * @param {array}  api_data.only -  ids material
 * @param {function}  dispatch -  callback
 */
export const dispatchedGoodsCombineRetailPriceFromPartnerSource = async (params, api_data, dispatch) => {
    const response = await WholesaleGoodsRetailPriceAPI.getCombineRetailPrice(params, {add_all_ws_prices: 1, ...api_data});
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    dispatch(wholesaleGoodsCombineRetailPriceFromPartnerSourceSetAC(response?.data))
}
/**
 * Получить список цен для парнера и списко базовых цен на товары
 * add_all_ws_prices=0 - флаг, который вернёт  цены, установленные для партнёров
 * @param {object}  params - include firm_main || firm_partner
 * @param {object|| null}  api_data - only [array]
 * @param {array}  api_data.only -  ids material
 * @param {function}  dispatch -  callback
 */
export const dispatchedGoodsRetailPriceAllowIds = async (params, api_data={}, dispatch) => {
    const response = await WholesaleGoodsRetailPriceAPI.getCombineRetailPrice(params, {add_all_ws_prices: 0, ...api_data});
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    dispatch(wholesaleGoodsRetailPriceSetAC(response?.data))
}
export const dispatchedGoodsRetailPrice = async (params, dispatch) => {
    const response = await WholesaleGoodsRetailPriceAPI.getRetailPrice(params);
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    dispatch(wholesaleGoodsRetailPriceSetAC(response?.data))
}

export const getWSGoodsRetailPriceByParamsThunk = (params) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await dispatchedGoodsRetailPrice(params, dispatch)
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

export const setWSGoodsRetailPriceThunk = ({api_data_set, params}) => async (dispatch, getState) => {
    try {
        const material_goods = getState().material_search?.goods ?? [];
        dispatch(toggleLoaderStatusAC(true));
        const response = await WholesaleGoodsRetailPriceAPI.setRetailPrice(api_data_set);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        dispatch(wholesaleGoodsRetailPriceAddModalAC({isOpen: false, goods: null}))
        let data_api_for_stocks = material_goods?.map(e => Number(e?.id));
        await dispatchedGoodsRetailPriceAllowIds(params, {only: data_api_for_stocks}, dispatch)

        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
export const deleteWSGoodsRetailPriceThunk = ({api_data_delete, params}) => async (dispatch, getState) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await WholesaleGoodsRetailPriceAPI.deleteRetailPrice(api_data_delete);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        const material_goods = getState().material_search?.goods ?? [];
        let data_api_for_stocks = material_goods?.map(e => Number(e?.id));
        await dispatchedGoodsRetailPriceAllowIds(params, {only: data_api_for_stocks}, dispatch)
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
