import React, {useState} from 'react';

const ItemGoodsStockRetailData = ({
                                      getT,
                                      goods_retail,
                                      handlerDeleteGoodsStockRetail,
                                  }) => {
    const [isOpen, setToggleOpen] = useState(false)
    const handlerToggleOpenMore = () => {
        setToggleOpen(!isOpen)
    }
    return (
        <div className="col-12">
            <div className="d-flex flex-wrap justify-content-between" style={{gap: "20px"}}>
                <h5 onClick={handlerToggleOpenMore} className={"cursor-pointer"}>{getT("Настройка товара")}
                    <button onClick={handlerToggleOpenMore} className={"btn "}>
                        <i className={"fas fa-angle-" + (isOpen ? "up" : "down")}></i>
                    </button>
                </h5>
            </div>
            {isOpen && <table className="table table-sm table-borderless">
                <thead>
                <tr className={"table-light"}>
                    <th scope="col">{getT("Розничный остаток")}</th>
                    <th scope="col">{getT("Оптовый остаток")}</th>
                    <th scope="col">{getT("Минимальный остаток")}</th>
                    <th scope="col">{getT("Розничная цена")}</th>
                    <th scope="col">{getT("Валюта")}</th>
                    <th scope="col">{getT("Кол-во дней доставки")}</th>
                    <th scope="col">{getT("Действие")}</th>
                </tr>
                </thead>
                <tbody>

                <tr key={goods_retail?.ws_goods_stock_source_retail_id} className={"table-info"}>

                    <td data-label={getT("Розничный остаток")}>
                        {goods_retail?.retail_stock}
                    </td>
                    <td data-label={getT("Оптовый остаток")}>
                        {goods_retail?.whole_sale_stock}
                    </td>
                    <td data-label={getT("Минимальный остаток")}>
                        {goods_retail?.minimal_quantity}
                    </td>
                    <td data-label={getT("Розничная цена")}>
                        {goods_retail?.recommended_retail_price}
                    </td>
                    <td data-label={getT("Валюта")}>
                        {goods_retail?.currency_name}
                    </td>
                    <td data-label={getT("Кол-во дней доставки")}>
                        {goods_retail?.default_delivery_days}
                    </td>

                    <td data-label={getT("Действие")}>

                        <button className={"btn btn-danger btn-sm"}
                                onClick={handlerDeleteGoodsStockRetail}>{getT("Удалить товар")}</button>

                    </td>
                </tr>

                </tbody>
            </table>
            }
        </div>
    );
};

export default ItemGoodsStockRetailData;