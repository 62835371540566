import {useEffect, useState} from "react";
/**
 * Custom hook for debouncing any fast changing value.
 *
 * @param {any} value - Value to be debounced.
 * @param {number} delay - Debounce delay in milliseconds.
 * @returns {any} - Debounced value.
 */
function useDebounce(value, delay) {
    // value and delay in ms (1000ms = 1s)
    // debounced values
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            // Update debounced value after delay
            const t = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            // clean up the timeout after value changes
            return () => {
                clearTimeout(t);
            };
        },
        [value, delay] // re-run if value or delay changes
    );
    return debouncedValue;
}

export default useDebounce;