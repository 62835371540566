export const FIELD_NAME_BY_KEY_MATERIAL_STATISTICS_XNC_MATERIAL_OPERATION= {
    part_count: {
        title: 'Количество деталей',
        unit: "шт",
        is_has_action_modal_filter_data_by_role: true,
    },
    cut_length: {
        title: 'Длина реза',
        unit: "м",
        is_has_action_modal_filter_data_by_role: true,
    },
    filling: {
        title: 'Средний процент заполнения листа деталями',
        unit: "%",
        is_has_action_modal_filter_data_by_role: true,
    },
    part_area_sum: {
        title: 'Площадь деталей',
        unit: "м2",
        is_has_action_modal_filter_data_by_role: true,
    },
    area: {
        title: 'Площадь',
        unit: "м2",
        is_has_action_modal_filter_data_by_role: true
    },
    cut_per_2800_2070_sheet: {
        title: 'Длина реза на лист',
        unit: "м",
        is_has_action_modal_filter_data_by_role: true
    },
    used_good_stock_in_cutting: {
        title: 'Площадь остатков, использованных при раскрое',
        unit: "м2",
        is_has_action_modal_filter_data_by_role: true
    },
    part_rest_sum_ready_orders_PLAN: {
        title: 'Планируемый возврат остатков по выполненным заказам',
        unit: "м2",
        is_has_action_modal_filter_data_by_role: true
    },
    part_rest_sum_PLAN: {
        title: 'Планируемый возврат остатков по всем заказам',
        unit: 'м2',
        is_has_action_modal_filter_data_by_role: true
    },
    rest_back_to_stock_FACT: {
        title: 'Фактический приход остатков на склад',
        unit: 'м2',
        is_has_action_modal_filter_data_by_role: true
    },
    goods_stock_get_from_stock: {
        title: 'Фактически выданные остатки со склада',
        unit: 'м2',
        is_has_action_modal_filter_data_by_role: true
    },
    goods_stock_presented: {
        title: 'Площадь остатков на складе',
        unit: 'м2',
        is_has_action_modal_filter_data_by_role: true
    },
    loses: {
        title: 'Потери материала при раскрое',
        unit: 'м2',
        is_has_action_modal_filter_data_by_role: true
    },
    loses_percentage: {
        title: 'Процент потери материала при раскрое',
        unit: '%',
        is_has_action_modal_filter_data_by_role: true
    },
}
export const FIELD_NAME_BY_KEY_CLIENT_STATISTICS_XNC_MATERIAL_OPERATION = {

    material_client_part_count: {
        title: 'Количество деталей',
        unit: "шт",
        is_has_action_modal_filter_data_by_role: true,
    },
    material_client_cut_length: {
        title: 'Длина реза',
        unit: "м",
        is_has_action_modal_filter_data_by_role: true,
    },
    material_client_filling: {
        title: 'Средний процент заполнения листа деталями',
        unit: "%",
        is_has_action_modal_filter_data_by_role: true,
    },
    material_client_part_area_sum: {
        title: 'Площадь деталей',
        unit: "м2",
        is_has_action_modal_filter_data_by_role: true
    },
    material_client_area: {
        title: 'Площадь',
        unit: "м2",
        is_has_action_modal_filter_data_by_role: true
    },
    material_client_cut_per_2800_2070_sheet: {
        title: 'Длина реза на лист',
        unit: "м",
        is_has_action_modal_filter_data_by_role: true
    },
    material_client_loses: {
        title: 'Потери материала при раскрое',
        unit: 'м2',
        is_has_action_modal_filter_data_by_role: true
    },
    material_client_loses_percentage: {
        title: 'Процент потери материала при раскрое',
        unit: '%',
        is_has_action_modal_filter_data_by_role: true
    },
    material_client_names: {
        title: 'Используемые имена',
        unit: null,
        is_has_action_modal_filter_data_by_role: false,
        is_child_object: true
    },

}