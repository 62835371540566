const mediaView = {
    md: 900,
    sm: 500,
    xs: 300
}

const MAX_SIDE_VALUES ={
    x: 800,
    y: 600
}
export const onePixelSize = ({side, wSide}) => {
    // let percent = Number(wBlock / 100);
    // return Number((wSide / percent) / 100);
    return MAX_SIDE_VALUES[side] / wSide
}
export const getCalculatePixel = ({pixel, value}) => {
    let h =  Number( pixel * value)
    // let h =  Number(value / pixel)
    return h;
}


export const getCuttingMediaWidth = (width) => {
    if (width >= 992) return mediaView["md"]
    if (width >= 567 && width <= 991) return mediaView["sm"]
    if (width >= 280 && width <= 566) return mediaView["xs"]
    else {
        return mediaView["md"]
    }
}

export const hasLetterSidesValue = ({obj, operation = '', isCurrentType = false, letter, side = 't'}) => {
    if (!obj) return []
    return Object.entries(obj).map(([k, v]) => {
        if (isCurrentType) {
            if (k === `${operation}` && (Object.keys(v)).length !== 0 && v.hasOwnProperty(side)) {
                const value = Object.entries(v[side]).filter(([k, v]) => k === letter)?.[0] ?? false;
                return (value) ? {
                        [value[0]]: value[1]
                    }
                    :
                    value;
            } else {
                return false
            }
        } else {

            if ((k.includes(`${operation}`) && (Object.keys(v)).length !== 0) && v.hasOwnProperty(side)) {
                const value = Object.entries(v[side]).filter(([k, v]) => k === letter)?.[0] ?? false;
                return (value) ? {
                        [value[0]]: value[1]
                    }
                    :
                    value;
            } else {
                return false
            }
        }
    }, []);

}
export const calculateEdge = ({edge, operation = 'edge', isCurrentType = false, letter}) => {
    if (!edge || !letter) {
        console.error('empty dt edge or letter');
        return 0
    }
    const edgeB = hasLetterSidesValue({obj: edge, operation, isCurrentType, letter, side: 'b'});
    const edgeT = hasLetterSidesValue({obj: edge, operation, isCurrentType, letter, side: 't'});
    const edgeL = hasLetterSidesValue({obj: edge, operation, isCurrentType, letter, side: 'l'});
    const edgeR = hasLetterSidesValue({obj: edge, operation, isCurrentType, letter, side: 'r'});
    const double = edge.hasOwnProperty('edge_part_double') ? (edge.edge_part_double.hasOwnProperty(letter) ? Number(edge.edge_part_double[letter]) : 0) : 0;

    const edgedSides = [...edgeT, ...edgeB, ...edgeR, ...edgeL];

    let values = edgedSides.reduce((prev, current) => {
        let prevValue = prev.hasOwnProperty(letter) ? Number(prev[letter]) : Number(prev);
        let currentValue = current.hasOwnProperty(letter) ? Number(current[letter]) : Number(current);
        return (prevValue + currentValue).toFixed(2)
    }, 0);

    if (isCurrentType) {
        return values ?? 0;
    } else {
        return values ? Number(values) + Number(double) : 0
    }


}

export const getSidesAllOperation = ({edge, operation = 'edge', isCurrentType = true, letter}) => {
    if (!edge || !letter) {
        console.error('empty dt edge or letter');
        return 0
    }
    const edgesB = hasLetterSidesValue({
        obj: edge,
        operation,
        isCurrentType,
        letter,
        side: 'b'
    }).filter(v => v !== false);
    const edgesT = hasLetterSidesValue({
        obj: edge,
        operation,
        isCurrentType,
        letter,
        side: 't'
    }).filter(v => v !== false);
    const edgesL = hasLetterSidesValue({
        obj: edge,
        operation,
        isCurrentType,
        letter,
        side: 'l'
    }).filter(v => v !== false);
    const edgesR = hasLetterSidesValue({
        obj: edge,
        operation,
        isCurrentType,
        letter,
        side: 'r'
    }).filter(v => v !== false);
    return {
        t: edgesT.length >= 1,
        b: edgesB.length >= 1,
        l: edgesL.length >= 1,
        r: edgesR.length >= 1,
    }


}
export const isCurveSide = ({edgeSide, operation = 'edge', isCurrentType = false, letter, side = 't'}) => {
    if (!edgeSide || !letter) {
        console.error('empty dt edgeSide or letter');
        return 0
    }
    if (edgeSide[operation] && edgeSide[operation].hasOwnProperty(side) && edgeSide[operation][side].hasOwnProperty('curve')) {
        if (Number(edgeSide[operation][side]['curve']) === 1) return true
        else return false

    }

    return false
}

export const calculateSide = ({edgeSide, operation = 'edge', isCurrentType = false, letter, side = 't'}) => {

    if (!edgeSide || !letter) {
        console.error('empty dt edgeSide or letter');
        return 0
    }
    const sides = hasLetterSidesValue({obj: edgeSide, operation, isCurrentType, letter, side});

    let values = sides.reduce((prev, current) => {
        let prevValue = prev.hasOwnProperty(letter) ? Number(prev[letter]) : Number(prev);
        let currentValue = current.hasOwnProperty(letter) ? Number(current[letter]) : Number(current);
        return (prevValue + currentValue).toFixed(2)
    }, 0)
    return values ?? 0;
}

export const removeDuplicatesArrayObj = (array, key) => {
    return array.reduce((arr, item) => {
        const removed = arr.filter(i => i[key] !== item[key]);
        return [...removed, item];
    }, []);
};
//edge['b]
export const getEdgeLetter = ({edgeSide, key = 'edge', side = 't'}) => {
    if (edgeSide && edgeSide.hasOwnProperty(key) && edgeSide[key].hasOwnProperty(side)) {
        return Object.keys(edgeSide).length !== 0 && Object.keys(edgeSide[key][side]).length !== 0 ? Object.keys(edgeSide[key][side])[0] : []

    }
    return []
}

export function formatNumbersThousands(value) {
    if (!value) return null;
    //::TODO Округление до 2-х знаков
    return Math.floor(Number(value) * 100) / 100;
}


export function transformTimeAndDate(date_value){
    const date = new Date(date_value); // You can replace this with your Date object or date string

// Get time in HH:mm:ss format
    const time = date.toLocaleTimeString();

// Get date in m.d.y format
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based, so add 1
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    const dateFormatted = `${year}-${month}-${day}`;
    return {
        time, date: dateFormatted
    }
}