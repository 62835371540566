import React, {useMemo} from 'react';
import HeaderContainer from "../Header/HeaderContainer";
import AsideMenuContainer from "../AsideMenu/AsideMenuContainer";
import AlertContainer from "../Alert/AlertContainer";
import FileManager from "../Modals/FileManager";
import {useSelector} from "react-redux";
import Loader from "../Loader/Loader";
import {Toaster} from 'react-hot-toast';

const Layout = ({children}) => {
    const isLoading = useSelector(state => state?.main?.isLoading)
    const sidebarCollapse = useSelector(state => state?.main?.sidebarCollapse)
    const cNameSidebar = useMemo(() => {
        return `position-relative  main_wrapper sidebar-mini ${sidebarCollapse ? " sidebar-collapse" : ""}`
    }, [sidebarCollapse])
    return (
        <div
            className={cNameSidebar}
            style={{minHeight: '100vh'}}
        >

            <HeaderContainer/>
            <AsideMenuContainer/>
            <div className="wrapper">
                {children}
            </div>

            <AlertContainer/>
            <FileManager/>
            <Toaster   position="top-right"
                       reverseOrder={false}
                       gutter={8}
                       containerClassName=""
                       containerStyle={{}}
                       toastOptions={{
                           // Define default options
                           className: '',
                           duration: 6000,
                           style: {
                               background: '#fff',
                               color: 'black',
                           },

                           // Default options for specific types
                           success: {
                               duration: 3000,
                               style: {
                                   background: 'green',
                                   color: "white"

                               },
                           },
                           error: {
                               style: {
                                   background: 'red',
                                   color: "white"
                               },
                           },
                       }}/>
            {isLoading && <Loader/>}
        </div>
    );
};

export default Layout;