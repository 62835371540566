import React from 'react';

const AmountService = ({getT, amountList}) => {
    return (

        <tr>
            {Array.isArray(amountList) && amountList?.map(amount=>{
                return <td>{getT(amount?.title)}: {amount?.result}  {getT(amount?.unit)}</td>
            })}
        </tr>

    );
};

export default AmountService;