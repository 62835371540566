import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getGeneralCurrencies, getSalaryRates} from "../../store/selectors/salary_project_selector";
import {toggleRatesCreateModalAC} from "../../store/actions/SalaryProject";
import ContainerContent from "../../components/ui/ContainerContent";
import PageTitle from "../../components/PageTitle/PageTitle";
import {getSalaryRatesThunk} from "../../store/thunks/salary_project/rates_thunk";
import {getGeneralCurrenciesThunk} from "../../store/thunks/salary_project/general_thunk";
import SalaryRatesCreateModal from "../../components/Modals/SalaryProject/SalaryRatesCreateModal";
import ItemRate from "./component/ItemRate";

const SalaryProjectRatesPage = ({getT}) => {
    const rates_state = useSelector(getSalaryRates);
    const general_currencies_state = useSelector(getGeneralCurrencies);
    const dispatch = useDispatch();
    const [query, setQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        dispatch(getSalaryRatesThunk())
        dispatch(getGeneralCurrenciesThunk())
    }, [dispatch]);

    const onHandlerAddRate = ({service, price_bad = null, price_good = null, price_super = null, currency = null}) => {
        dispatch(toggleRatesCreateModalAC({
            isOpen: true, form_values: {
                service: service,
                price_bad: price_bad,
                price_good: price_good,
                price_super: price_super,
                currency: currency
            }
        }))
    }
    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            // Вместо этого места вы можете выполнить запрос к данным
            // и фильтрацию ваших данных на основе введенного запроса (query)
            if (!query) {
                setFilteredData(rates_state?.data);
                return
            }
            const filtered = rates_state?.data?.filter(item => item.name?.trim().toLowerCase().includes(query.toLowerCase()?.trim()) || Number(item?.goods_service_id) === Number(query) );

            setFilteredData(filtered);
        }, 300); // 300 миллисекунд задержки

        // Очистка таймера при каждом новом вводе
        return () => clearTimeout(delayDebounceFn);
    }, [query, rates_state]);
    return (
        <ContainerContent>
            <>
                <PageTitle title={"salary_rates"}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="row">
                                <div className="col-12 col-md-4 col-xl-3">
                                    <input type="text" className="form-control" placeholder={getT('Поиск')}
                                           value={query}
                                           onChange={(e) => {
                                               setQuery(e.target.value)
                                           }}/>
                                </div>
                            </div>

                        </div>
                        {rates_state?.error ?
                            <div className="col-12">
                                <div className="jumbotron jumbotron-fluid">
                                    <div className="container">
                                        <h1 className="display-4">Information</h1>
                                        <p className="lead">{rates_state?.error}</p>
                                    </div>
                                </div>
                            </div>
                            :
                            filteredData?.map(plane => {
                                let actual_currency = plane?.actual_rate?.error ? -1 : plane?.actual_rate?.currency
                                let currency = general_currencies_state?.data?.find(item => Number(item?.currency_id) === Number(actual_currency))
                                return (<div
                                    className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
                                    <ItemRate rate={plane} getT={getT} currency={currency}
                                              onHandlerAddRate={onHandlerAddRate}/>
                                </div>)
                            })
                        }

                    </div>
                </div>
            </>
            <SalaryRatesCreateModal key={'modal_create-plane'}/>
        </ContainerContent>
    );
};

export default SalaryProjectRatesPage;