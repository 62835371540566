import React from 'react';
import {connect} from "react-redux";
import AsideMenu from "./AsideMenu";
import './AsideMenu.css';
import {
    getMainSettingsProject,
    setOrdersCountsDataThunk,
    toggleActicveMenuAC,
    toggleSideBarTypeAC
} from "../../store/reducers/MainReducer";
import {withLang} from "../../hoc/withLang";

class AsideMenuContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.setOrdersCount()
        this.props.getSetting();
    }


    render() {
        return <AsideMenu user={this.props.user}
                          worker={this.props.worker}
                          menu={this.props.menu}
                          toggleMenu={this.props.toggleMenu}
                          counts={this.props.counts}
                          toggleSidebar={this.props.toggleSidebar}
                          getT={this.props.getT}
                          settings={this.props.settings_project}
                          isVisibleNews={this.props.isVisibleNews}
        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        toggleSidebar: (view) => dispatch(toggleSideBarTypeAC(view)),
        toggleMenu: (menu) => dispatch(toggleActicveMenuAC(menu)),
        setOrdersCount: () => dispatch(setOrdersCountsDataThunk()),
        getSetting: () => dispatch(getMainSettingsProject())
    }
}

let mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        worker: state.auth.worker,
        menu: state.main.menu,
        counts: state.main.ordersProductsCounts,
        settings_project: state.main.settings_project,
        isVisibleNews: !!state.main.news.pages
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withLang(AsideMenuContainer));