import React from 'react';
import ExistItemRow from "./ExistItemRow";

const ExistOrdersGoodsTab = ({orders_goods, getT, onDeleteWSByGoodsId, openUpdateModalForWSGoodsId}) => {

    let isHasRecord = !!(Array.isArray(orders_goods) && orders_goods?.length);
    return (
        <div className={"exist-orders-goods-tab"}>
            {isHasRecord ?
                <table className="table table-striped">
                    <thead>
                    <th># {getT("Заказа")}</th>
                    {/*<th> {getT("Связь заказа с товаром")}</th>*/}
                    <th>{getT("Статус")}</th>
                    <th>{getT("Товар")}</th>
                    <th>{getT("Заказ поставщику")}</th>
                    <th>{getT("Количество")}</th>
                    <th>{getT("Действие")}</th>
                    </thead>
                    <tbody>
                    {orders_goods?.map(order => {
                        return <ExistItemRow getT={getT} item={order} onDeleteWSByGoodsId={onDeleteWSByGoodsId(order?.ws_orders_goods_id)}
                                             openUpdateModalForWSGoodsId={openUpdateModalForWSGoodsId}
                        />
                    })}
                    </tbody>
                </table>
                :
                <p>{getT("Данный отсутствуют")}</p>}

        </div>
    );
};

export default ExistOrdersGoodsTab;