import {LS_USER_ID} from "../../utils/constants";

/**
 * createFieldsForListChecked. группировка услуг по типу
 *
 * @param {array} order_service - спикос услуг и типов услуг
 * @returns  {object}  -
 */
export const createFieldsForListChecked = (order_service) => {
    try {
        return order_service.reduce((acc, obj) => {
            const key = `service_${obj?.serv_type}`;
            if (!acc[key]) {
                let children = obj?.children?.map(item => ({
                    id: Number(item?.services),
                    isChecked: !!Number(item?.service_ready),
                    is_initial_checked: !!Number(item?.service_ready),
                }));
                acc[key] = {
                    isChecked: children?.every(item => item?.isChecked),
                    is_initial_checked: children?.every(item => item?.isChecked),
                    serv_type: obj?.serv_type,
                    children: children,
                };
            }
            return acc;
        }, {});
    } catch (e) {
        return {}
    }
}
/**
 * createFieldsForGroupListChecked. группировка по типу услуг и  фильтрация доступных заказов которые имеют  услугу
 *
 * @param {array} order_service -
 * @param {array}  select_orders_service_ids-
 * @returns  {object}  -
 */
export const createFieldsForGroupListChecked = (order_service, select_orders_service_ids) => {
    try {
        return order_service.reduce((acc, obj) => {
            const key = `service_${obj?.serv_type}`;
            let children = obj?.children?.map(item => {
                let service_includes = select_orders_service_ids?.filter(ser => Number(ser?.services) === Number(item?.services))
                // проверка во всех ли заказах отмечена услуга
                let is_checked = service_includes?.every(serv => !!Number(serv.service_ready));
                // исходя из проверки про отметку фильтруем массыв услуг
                let access_order_for_entry = is_checked ? service_includes?.filter(serv => !!Number(serv?.service_ready)) :
                    service_includes?.filter(serv => !Number(serv?.service_ready))
                let access_order = access_order_for_entry?.map(item => item?.id)

                return {
                    id: Number(item?.services),
                    isChecked: is_checked,
                    is_initial_checked: is_checked,
                    access_order: [...new Set(access_order)]

                }
            });
            let is_checked = children?.every(item => item?.isChecked);
            let service_includes = select_orders_service_ids?.filter(ser => Number(ser?.serv_type) === Number(obj?.serv_type));
            let access_order_for_entry = is_checked ? service_includes?.filter(serv => !!Number(serv?.service_ready)) :
                service_includes?.filter(serv => !Number(serv?.service_ready))
            let access_order = access_order_for_entry?.map(item => item?.id)


            acc = {
                ...acc,
                [key]: {
                    serv_type: obj?.serv_type,
                    isChecked: is_checked,
                    is_initial_checked: is_checked,
                    children: children,
                    access_order: [...new Set(access_order)]

                }
            }
            return acc;
        }, {});

    } catch (e) {
        console.log(e)
        return {}
    }
}
//** обновление типа услуги. вкл.выкл*/
// fields
export const updateServiceType = (services_item, checked) => {


    return {
        ...services_item,
        isChecked: checked,
        children: services_item?.children?.map(item => ({...item, isChecked: checked}))
    }

}

//** обновление  услуги. вкл.выкл*/
// fields
export const updateItemServiceType = (services_item, children_serv_type, checked) => {

    let children_ids = services_item?.children?.map(item => {
        let is_find_service_for_change = Number(item.id) === Number(children_serv_type);
        if (is_find_service_for_change) {
            return {
                ...item, isChecked: checked
            }
        }
        return {
            ...item
        }
    })
    return {
        ...services_item,
        isChecked: children_ids?.every(item => item.isChecked),
        children: children_ids,
    }
}

function createOrderEntriesMark({fields, is_ready_order}) {
    return Object.values(fields)?.map(item => {
        let children = item?.children?.map(child => {
            return {
                ...child,
                isChecked: is_ready_order ? !child.isChecked : child.isChecked
            }
        })?.filter(child => {
            return child.isChecked
        })
        let result = {
            ...item,
            children: children,
            isChecked: item?.children?.every(child => is_ready_order ? !child.isChecked : child.isChecked)
        }
        if (fields.hasOwnProperty('access_order')) result['access_order'] = item.access_order;
        return result
    })?.filter(item => item?.isChecked || item?.children?.length);
}


/**
 * createEntryPutAndDeleteService. создать данные для снятие и добавление отметки к услугам
 *
 * @param {object} fields -
 * @returns  {object}  -
 */
export function createEntryPutAndDeleteService(fields) {
    let values = Object.values(fields)
    let put_data = values?.reduce((acc, item) => {
        let children_put = item?.children?.map(serv => {
            return {
                ...serv, isChecked: serv?.isChecked && (serv?.isChecked !== serv?.is_initial_checked)
            }
        })
        if (children_put?.filter(item => item?.isChecked)?.length) {
            acc.push({
                ...item,
                isChecked: children_put?.every(i => i?.isChecked),
                children: children_put?.filter(item => item?.isChecked)
            })
        }
        return acc
    }, []);
    let remove_data = values?.reduce((acc, item) => {
        let children_put = item?.children?.map(serv => {
            return {
                ...serv, isChecked: !serv?.isChecked && (serv?.isChecked !== serv?.is_initial_checked)
            }
        })
        if (children_put?.filter(item => item?.isChecked)?.length) {
            acc.push({
                ...item,
                isChecked: children_put?.every(i => i?.isChecked),
                children: children_put?.filter(item => item?.isChecked)
            })
        }
        return acc
    }, []);
    return {
        put: put_data,
        remove: remove_data
    }
}