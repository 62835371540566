import React, {useState} from 'react';
import {useDetectClickOutside} from "react-detect-click-outside";
import Button from "../../../../components/ui/Button";

const DocumentBonuses = ({bonuses, handlerCreate, getT, handlerDeleteBonus, handlerUpdate}) => {
    const [isShow, setIsShow] = useState(false);
    const ref = useDetectClickOutside({onTriggered: () => setIsShow(false)})

    return (
        <>
            <p className={"fs-14 d-flex justify-content-between flex-row text-secondary align-items-center"}>
                <span className={"text-primary"}>{getT("Бонусы")}:</span> <span
                className={'text-dark'}>
            <span className="shift-action">
                {!!bonuses?.length && <i className={`cursor-pointer fas fa-angle-${isShow ? 'up' : 'down'}`}
                                         onClick={() => setIsShow(!isShow)}></i>}
                <Button onClick={handlerCreate} className={"btn btn-sm btn-success"}>{getT("Создать")}</Button>
                    </span>
        </span>
            </p>

            {isShow && Array.isArray(bonuses) &&
                <ul className="list-group-shift-plane">
                    {/*<ul className="p-0 m-0 mb-2 list-unstyled">*/}
                    {bonuses?.map(item => {
                        return (
                            <li className="">
                                <p className={"fs-14 d-flex justify-content-between flex-row mb-0"}>
                                    <span>{item?.eqw_name}:</span>
                                    <span className={"d-flex align-items-center"} style={{gap: "3px"}}>

                                  <span className={'text-dark'}> {item?.summ} {item?.cur_name}</span>
                                                                            <i className="fas fa-edit text-success cursor-pointer"
                                                                               onClick={handlerUpdate(item)}></i>

                                         <i className="fa fa-trash text-danger cursor-pointer"
                                            onClick={handlerDeleteBonus(item?.salary_additional_bonus_id)}></i>
                                    </span>

                                </p>
                                <p className={"fs-14 d-flex justify-content-between flex-row m-0"}>{item?.comment}</p>
                            </li>
                        )
                    })}

                </ul>
            }
        </>
    );
};

export default DocumentBonuses;