import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {getAuthPaymentAdditional} from "../../store/selectors/auth_selector";
import {withLang} from "../../hoc/withLang";

const PaymentAdditional = ({getT}) => {
    const [isOpen, setIsOpen] = useState(false)
    const payment_additional = useSelector(getAuthPaymentAdditional);
    if (!payment_additional || !Array.isArray(payment_additional) || !payment_additional?.length) return null
    return (
        <div className={"payment-additional-wrap my-2"}>
            <button className={"btn btn-sm btn-success  w-100 overflow-hidden"}
                    onClick={() => setIsOpen(!isOpen)}>{getT("Дополнительно оплачено")} <i
                className="fas fa-angle-down"></i></button>
            {isOpen && <div className="bg-white" style={{borderRadius: "0px 0px 0.2rem 0.2rem"}}>
                <ul className={'list-unstyled'}>
                    {payment_additional?.map(item => {
                        let cNamePaymentStatus = `${!!Number(item?.payment_status) ? "text-success fa fa-check-circle" : " fa  fa-times text-danger"}`
                        return <li>
                            <span className={"text-dark"}>{item?.name} </span>
                            <span className={"text-nowrap"}>
                                <i className={cNamePaymentStatus} aria-hidden="true" title={item?.payment_till}> </i>
                                <i className="fas fa-info-circle cursor-pointer  text-primary ml-1"
                                   title={item?.description}></i>
                            </span>
                        </li>
                    })}

                </ul>
            </div>}
        </div>
    );
};

export default withLang(PaymentAdditional);