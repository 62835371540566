import getT from "../getT";
import {getCalculateAmountAndVat, getCalculateVat} from "../../utils/helpers";
import {formatNumbersThousands} from "../helpers";
import {CURRENCY_LIST} from "../../utils/constants";

export default function ({order}) {
    let currency_id = order?.currency;
    // let currency = order?.currency;
    let currency= CURRENCY_LIST?.find(e => e.id == currency_id)?.name;
    console.log('currency', currency)

    let calculate = order?.calculate;

    if (Array.isArray(calculate) || !calculate) return ''
    let production_vat = order?.production_constants?.['production.VAT_add_price_if_more_than_0'] || 0;

    let production_vat_display = order?.production_constants?.['production.vat_added_tax_show_needed'] || 1;
    let production_vat_display_short = !Number(production_vat_display) && !!Number(production_vat);

    const getTotal = () => {
        let handlers_edge = calculate?.handlers_edge?.summ;
        let round_edge = calculate?.round_edge?.summ;
        let faska_edge = calculate?.faska_edge?.summ;
        let srez_edge = calculate?.srez_edge?.summ;
        let amount_val = Number(srez_edge) + Number(round_edge) + Number(faska_edge) + Number(handlers_edge);
        let amount = parseFloat(`${amount_val}`.replace(',', '.'))?.toFixed(2)
        return {
            handler: parseFloat(`${handlers_edge}`.replace(',', '.'))?.toFixed(2),
            faska: parseFloat(`${faska_edge}`.replace(',', '.'))?.toFixed(2),
            round: parseFloat(`${round_edge}`.replace(',', '.'))?.toFixed(2),
            srez: parseFloat(`${srez_edge}`.replace(',', '.'))?.toFixed(2),
            amount: amount
        }

    };
    let calculate_hands = calculate?.handlers_edge?.entry ?? [];
    let calculate_faska = calculate?.faska_edge?.entry ?? [];
    let calculate_round = calculate?.round_edge?.entry ?? [];
    let calculate_srez = calculate?.srez_edge?.entry ?? [];
    if (!calculate_srez?.length && !calculate_faska?.length && !calculate_hands?.length && !calculate_round?.length) return ''

    return `
     <div class='section-table-pack section-gap' style="font-size: 0px">
      <table style="  page-break-inside: auto;">
       <caption>${getT('Обработка торцов')}</caption>
      
                <thead>
                <tr>
                    <th>№</th>
                    <th>${getT("Обработка")}</th>
                    <th>${getT("Материал")}</th>
                    <th>${getT("Название")}</th>
                    <th>${getT("Кол-во")}</th>
                    <th>${getT("Цена(ед)")}</th>
                    <th>${getT("Сумма")}</th>
                </tr>
                </thead>
                <tbody>
${renderHands(calculate_hands, getTotal()?.handler, production_vat, production_vat_display_short, currency)}
${renderFaska(calculate_faska, getTotal()?.faska, production_vat, production_vat_display_short, currency)}
${renderRound(calculate_round, getTotal()?.round, production_vat, production_vat_display_short, currency)}
${renderSrez(calculate_srez, getTotal()?.srez, production_vat, production_vat_display_short, currency)}
            ${Total(getTotal().amount, production_vat, production_vat_display_short, currency, "Итого обработки торцов")} 
       
        </tbody>
 <tfoot></tfoot>
</table>
</div>
   
    
    `

}

function Total(total, production_vat_display_short, production_vat, currency, title = "Стоимость") {
    const total_vat = !!Number(production_vat) ? getCalculateVat(total, Number(production_vat)) : 0;
    const amount_total_vat = !!Number(production_vat) ? getCalculateAmountAndVat(total, total_vat) : 0;

    return `
    
     <tr class='tr-bg-green'>
            <td colSpan="9" style="text-align:right;" class='amount'>
               <strong>${getT(title)} : ${formatNumbersThousands(total)}</strong> 
                ${!!Number(production_vat) ?
        production_vat_display_short ?
            `<span style="font-size: 14px">
                ${currency} / ${amount_total_vat} ${currency} ${getT("с НДС")} 
            </span>`
            :
            `+ ${total_vat} ${getT("НДС")} = ${amount_total_vat} ${currency}`
        : currency}
            </td>
        </tr>
    `
}

function renderHands(calculate, total, production_vat, production_vat_display_short, currency) {

    let is_has_calculate = !!(Array.isArray(calculate) && calculate?.length);
    if (!is_has_calculate) return <></>
    let hands = is_has_calculate ?
        calculate?.map((item, index) => {
            return item?.hand?.map((e, index) => {
                return (`<tr>
                <td>${index + 1}</td>
                <td>${getT("Установка ручки")}</td>
                <td>[${item?.material.id}] ${item?.material.name}</td>
                <td>${e.name}</td>
                <td>${e?.count} ${getT("шт")}</td>
                <td>${e?.price}</td>
                <td>${Number(e.cost).toFixed(2)}</td>
            </tr>`)
            })?.join('')

        })?.join('') : '';

    return `${hands}
    ${Total(total, production_vat_display_short, production_vat, currency, "Итого ручек")}`
}

function renderFaska(calculate, total, production_vat, production_vat_display_short, currency) {

    let is_has_calculate = !!(Array.isArray(calculate) && calculate?.length);
    if (!is_has_calculate) return <></>
    let hands = is_has_calculate ?
        calculate?.map((item, index) => {

            return item?.faska?.map((e, index) => {
                return (`<tr>
                <td>${index + 1}</td>
                <td>${getT("Фаска")}</td>
                <td>[${item?.material.id}] ${item?.material.name}</td>
                <td>${e.name}</td>
                <td>${e?.count} ${getT("м.п.")}</td>
                <td>${e?.price}</td>
                <td>${Number(e.cost).toFixed(2)}</td>
            </tr>`)
            })?.join('')

        })?.join('') : '';

    return `
    
     ${hands}
 ${Total(total, production_vat_display_short, production_vat, currency, "Итого фаски")}
       
    `
}

function renderRound(calculate, total, production_vat, production_vat_display_short, currency) {

    let is_has_calculate = !!(Array.isArray(calculate) && calculate?.length);
    if (!is_has_calculate) return <></>
    let hands = is_has_calculate ?
        calculate?.map((item, index) => {

            return item?.round?.map((e, index) => {
                return (`<tr>
                <td>${index + 1}</td>
                <td>${getT("Скругление")}</td>
                <td>[${item?.material.id}] ${item?.material.name}</td>
                <td>${e.name}</td>
                <td>${e?.count} ${getT("м.п.")}</td>
                <td>${e?.price}</td>
                <td>${Number(e.cost).toFixed(2)}</td>
            </tr>`)
            })?.join('')

        })?.join('') : '';

    return `
    
     ${hands}
 ${Total(total, production_vat_display_short, production_vat, currency, "Итого скругления")}
       
    `
}

function renderSrez(calculate, total, production_vat, production_vat_display_short, currency) {

    let is_has_calculate = !!(Array.isArray(calculate) && calculate?.length);
    if (!is_has_calculate) return <></>
    let hands = is_has_calculate ?
        calculate?.map((item, index) => {

            return item?.srez?.map((e, index) => {
                return (`<tr>
                <td>${index + 1}</td>
                <td>${getT("Срез")}</td>
                <td>[${item?.material.id}] ${item?.material.name}</td>
                <td>${e.name}</td>
                <td>${e?.count} ${getT("м.п.")}</td>
                <td>${e?.price}</td>
                <td>${Number(e.cost).toFixed(2)}</td>
            </tr>`)
            })?.join('')

        })?.join('') : '';

    return `
    
     ${hands}
 ${Total(total, production_vat_display_short, production_vat, currency, "Итого среза")}
       
    `
}
