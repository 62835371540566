import {dispatcherErrorThunk} from "../common_thunk";
import {EquipmentWorkerAPI} from "../../../api/Salary/EquipmentWorkerAPI";
import {setEquipmentWorkerGroupAC, toggleShiftWorkerGroupModalAC} from "../../actions/SalaryProject";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {getTranslateMessageAPI} from "../../../utils/helpers";
import {WorkerGroupAPI} from "../../../api/Salary/WorkerGroupAPI";
import {openShiftThunk} from "./shifh-thunk";
import {INITIAL_SHIFT_WORKER_GROUP_FORM} from "../../reducers/SalaryProjectReducer";

async function equipmentWorkerGroupDispatched(dispatch) {
    const response = await EquipmentWorkerAPI.getList();
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    dispatch(setEquipmentWorkerGroupAC(response))
}

/**
 * getEquipmentWorkerGroupThunk.получить список работников
 */
export const getEquipmentWorkerGroupThunk = () => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await equipmentWorkerGroupDispatched(dispatch)
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

/**
 * createUpdateWorkerGroupThunk.создать обновить бригаду
 *
 * @param {object} api_data-
 * @param {number} api_data.shift-id смены
 * @param {array} api_data.workers- список сотрудников
 * @param {function|null} handlerResetForm- очистить форму
 // * @param {number} api_data.workers.id- id сотрудника
 // * @param {number} api_data.workers.percentage- процент
 */
export const createUpdateWorkerGroupThunk = (api_data, handlerResetForm = null) => async (dispatch, getState) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const state = getState().salary_project;
        const shift_open = state.shift_open;
        const find_worker_group = shift_open?.worker_group?.find(item =>
            item?.workers?.every(work_item => api_data?.workers?.find(api_work_item => Number(work_item.worker) === Number(api_work_item.id) && Number(work_item.percentage) === Number(api_work_item.percentage))));
        if (find_worker_group) {
            dispatch(dispatcherErrorThunk({error: getTranslateMessageAPI('Бригада уже существует')}));
            return
        }
        const response = await WorkerGroupAPI.add_update(api_data);
        if (!!response.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        await dispatch((openShiftThunk({shift_id: api_data?.shift})));
        dispatch(toggleShiftWorkerGroupModalAC({
            isOpen: false,
            form_values: INITIAL_SHIFT_WORKER_GROUP_FORM
        }))
        if (handlerResetForm) {
            handlerResetForm()
        }
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        console.log('dispatcherErrorThunk', e)
        dispatch(dispatcherErrorThunk(e))
    }
}

