import {formatNumbersThousands} from "../helpers";
import getT from "../getT";
import {getCalculateAmountAndVat, getCalculateVat} from "../../utils/helpers";

export default function ({order, currency, manager_name, unit, production_vat,production_vat_display_short }){
    let {id: order_id, firm_name, part:parts, packing, client_name, date} = order;
    const dataPackingOfProject = () => {
        let result = {fullSumm: 0, weight: 0}
        if (Array.isArray(packing) && !!packing?.length) {
            let data_result_array = packing?.map((pack,) => {
                return pack?.packs?.reduce((acc, item) => {
                    return {
                        fullSumm: Number(acc.fullSumm) + Number(item.fullSumm),
                        weight: Number(acc.weight) + Number(item?.packWeight ?? 0)
                    }
                }, result)
            });
            let amount = data_result_array?.reduce((acc, item) => ({
                fullSumm: Number(acc.fullSumm) + Number(item.fullSumm),
                weight: Number(acc.weight) + Number(item.weight)
            }), result);
            console.log('amount', amount)
            return amount
        }
        return result
    };
    const dateAndTime = () => {
        let result = {date: null, time: null};
        if (!date) return result;
        let transform_date = new Date(date)
        return {
            date: transform_date?.toLocaleDateString(),
            time: transform_date?.toLocaleTimeString()
        }

    }
    const part_count = () => {
        if (!Array.isArray(parts) || !parts?.length) return 0;
        return parts?.reduce((total, part) => Number(part?.count) + total, 0)
    }
    const firm = firm_name?.[localStorage.getItem('lang')] ?? '-'

    const total_vat = !!Number(production_vat) ? getCalculateVat(dataPackingOfProject()?.fullSumm, Number(production_vat)) : 0;
    const amount_total_vat = !!Number(production_vat) ? getCalculateAmountAndVat(dataPackingOfProject()?.fullSumm, total_vat) : 0;
    return`
    <div class='section-data-project' style="margin-top: 15px">
        <div clsss="item">
            <strong>${getT('Фирма')}: </strong> ${firm}
        </div>
        <div clsss="item">
            <strong>№ ${getT('Заказ')}: </strong> ${order_id}
        </div>
        <div clsss="item">
            <strong>${getT('Дата заказа')}: </strong>${dateAndTime()?.date}
        </div>
        <div clsss="item">
            <strong>${getT('Время')}: </strong>${dateAndTime()?.time}
        </div>
        <div clsss="item">
            <strong>${getT('Клиент')}: </strong>${client_name}
        </div>
        <div clsss="item">
            <strong>${getT('Менеджер')}: </strong>${manager_name}
        </div>
        <div clsss="item">
            <strong>${getT('Количество деталей')}: </strong>${part_count()}
        </div>
        <div clsss="item">
            <strong>${getT('Вес')} </strong>${formatNumbersThousands(dataPackingOfProject()?.weight)} ${getT(unit)}
        </div>
        <div clsss="item">
 
            <strong>${getT('Сумма')} </strong>${formatNumbersThousands(dataPackingOfProject()?.fullSumm)}
${!!Number(production_vat) ?
        production_vat_display_short ?
            `<span style="font-size: 12px">
          ${currency} / ${amount_total_vat} ${currency} ${getT("с ндс")}
        </span>`
            :
            `+ ${total_vat} ${getT("ндс")} = ${amount_total_vat} ${currency}`
        : currency}

        </div>

    </div>
    `
}