import {instances, URI_API_EXTTUNELL} from "../Salary/setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const ws_goods_exception_set = 'ws_goods_exception_set'
const ws_goods_exception_delete = 'ws_goods_exception_delete'
const ws_goods_exception_get = 'ws_goods_exception_get'

export const WholesaleGoodsExceptionsAPI= {
    getException() {
        return instances.post(URI_API_EXTTUNELL + '/', null, {
            headers: {
                action: ws_goods_exception_get,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    deleteException(data){
        return instances.post(URI_API_EXTTUNELL + '/', data, {
            headers: {
                action: ws_goods_exception_delete,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    setException(data){
        return instances.post(URI_API_EXTTUNELL + '/', data, {
            headers: {
                action: ws_goods_exception_set,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }
}