import React from "react";
import {connect} from "react-redux";
import ProductionTask from "./ProductionTask";
import "./ProductionTask.css";

import {
    initialProductionOrdersPage,
    setCheck,
    setProductionActiveTabAC,
    SetTogggleCheck,
    TogggleCheck,
    toggleSetProblemModalAC,
    toggleViewProgrammModalAC,
    updateOrderDataThunk,
    updateProductionStatusThunk,
    updateSettingsThunk,
} from "../../store/reducers/MainReducer";

class ProductionTaskContainer extends React.Component {
    componentDidMount() {
        this.props.initialProductionsPage();
    }

    componentWillUnmount() {
        // this.props.checkAuntificationUser()
    }

    render() {
        return (
            <ProductionTask
                managers={this.props.managers}
                setActiveTab={this.props.setActiveTab}
                activeTab={this.props.activeTab}
                ordersFor={this.props.orders.for}
                ordersIn={[...this.props.orders.in]}
                ordersBad={this.props.orders.bad}
                ordersComplete={this.props.orders.complete}
                ordersForShipping={this.props.orders.forShipping}
                counts={this.props.counts}
                updateOrder={this.props.updateOrder}
                updateProductionStatus={this.props.updateProductionStatus}
                changeCalcType={this.props.changeCalcType}
                toggleSetProblem={this.props.toggleSetProblem}
                toggleViewProgramm={this.props.toggleViewProgramm}
                setCheckAC={this.props.setCheckAC}
                check={this.props.setCheckAC}
                togggleCheck={this.props.togggleCheck}
                settings={this.props.settings}
                productionTypes={this.props.productionTypes}
                clients={this.props.clients}
                setSettingsCheck={this.props.setSettingsCheck}
                lang={this.props.lang}
                titleTabComplete={(!!this.props?.orders.complete?.length && (Number(this.props?.counts?.production_5) !== Number(this.props?.orders.complete?.length)))}
                titleTabShipping={(!!this.props?.orders.forShipping?.length && (Number(this.props?.counts?.production_6) !== Number(this.props?.orders.forShipping?.length)))}
                titleTabOrdersIn={(!!this.props?.orders.in?.length && (Number(this.props?.counts?.production_4) !== Number(this.props?.orders.in?.length)))}

            />
        );
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setCheckAC: (list, isChecked) => dispatch(setCheck(list)),
        setActiveTab: (tab) => dispatch(setProductionActiveTabAC(tab)),
        updateOrder: (order_id, data) =>
            dispatch(updateOrderDataThunk(order_id, data)),
        toggleSetProblem: (status, order) =>
            dispatch(toggleSetProblemModalAC(status, order)),
        toggleViewProgramm: (status, order) =>
            dispatch(toggleViewProgrammModalAC(status, order)),
        updateProductionStatus: (service_id, status) =>
            dispatch(updateProductionStatusThunk(service_id, status)),
        togggleCheck: (id) => dispatch(TogggleCheck(id)),
        setSettingsCheck: (data) => dispatch(SetTogggleCheck(data)),
        initialProductionsPage: () => {
            dispatch(initialProductionOrdersPage());
             dispatch(updateSettingsThunk());
        }
    };
};

let mapStateToProps = (state) => {
    return {
        activeTab: state.main.productionTaskTab,
        orders: state.main.productionTaskOrders,
        counts: state.main.ordersProductsCounts,
        settings: state.main.settings,
        productionTypes: state.main.productionTasksTypes,
        clients: state.main.clients,
        lang: state.translation.local,
        managers: state.main.managers,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductionTaskContainer);
