import {SETTINGS_COMPANY_SET_ENTRY_DATA, SETTINGS_COMPANY_UPDATE_HTML_MODAL} from "../actions/SettingsCompanyAction";

const initialState = {
    settings_data: null,
    setting_update_html_modal: {
        isOpen: false, setting: null
    }
}


const SettingsCompanyReducer = (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_COMPANY_SET_ENTRY_DATA: {
            return {
                ...state,
                settings_data: action.payload
            }
        }
        case SETTINGS_COMPANY_UPDATE_HTML_MODAL: {
            console.log('payload', action.payload)
            return {
                ...state,
                setting_update_html_modal: {
                    ...state?.setting_update_html_modal,
                    ...action.payload
                }
            }
        }
        default: {
            return state
        }
    }
}

export default SettingsCompanyReducer;

