import React, {useState} from 'react';
import ButtonPrint from "../OrderDetailPrint/ButtonPrint";
import {gaEvents} from "../../../gaEvents";
import useClickOutside from "../../../hook/useClickOutside";
import {useDispatch} from "react-redux";
import {fetchingMergeHtmlFiles} from "../../../store/reducers/MainReducer";

const DetailPrintDocuments = ({getT, order}) => {
    const [actionsPrint, setActionsPrint] = useState({
        'order_blank_short_html': false,
        'order_blank_short_without_html': false,
        'material_list_html': false,
        'cutting_card': false,
        'ifp_xnc': false
    });
    const dispatch = useDispatch();
    const {setVisible: setOpenPrint, ref, isVisible: openPrint} = useClickOutside()
    const fetchingMergeHtml = () => {
        dispatch(fetchingMergeHtmlFiles({actions: actionsPrint, order}))
    }
    return (
        <div className={"print_all_documents_main_container"} ref={ref}>
            <ButtonPrint getT={getT}
                         onClick={() => {
                             gaEvents.customEvent('klik_form', {
                                 btn_print_dosc: 'Печать пакета документов',
                             })
                             setOpenPrint(!openPrint)
                         }}
                         typeActionChildren={<>{getT("Print")}</>}
                         isFileIcon={true}
                         titleChildren={<>{getT("Печать пакета")} {getT("документов")}</>}
            />
            <div
                className={
                    "print_all_documents_container " +
                    (openPrint &&
                    openPrint
                        ? "open"
                        : "")
                }
            >
                <div className="form-group">
                    <label htmlFor="">
                        <input
                            type="checkbox"
                            className={"form-control"}
                            onClick={() => setActionsPrint((prev) => {
                                return {
                                    ...prev,
                                    ['order_blank_short_html']: !actionsPrint['order_blank_short_html']
                                }
                            })}
                            checked={actionsPrint['order_blank_short_html']
                            }
                        />
                        <span>{getT("Бланк заказа")}</span>
                    </label>
                </div>
                <div className="form-group">
                    <label htmlFor="">
                        <input
                            type="checkbox"
                            className={"form-control"}
                            onClick={() => setActionsPrint((prev) => {
                                return {
                                    ...prev,
                                    ['order_blank_short_without_html']: !actionsPrint.order_blank_short_without_html
                                }
                            })}

                            checked={actionsPrint.order_blank_short_without_html}

                        />
                        <span>{getT("Бланк заказа (производство)")}</span>
                    </label>
                </div>
                {order?.cutting_card?.hasOwnProperty('cards') ?
                    <div className="form-group">
                        <label htmlFor="">
                            <input
                                type="checkbox"
                                className={"form-control"}
                                onClick={() => setActionsPrint((prev) => {
                                    return {
                                        ...prev,
                                        ['cutting_card']: !actionsPrint.cutting_card
                                    }
                                })}
                                checked={actionsPrint.cutting_card}
                            />
                            <span>{getT("Карты кроя")}</span>
                        </label>
                    </div> : ''}
                <div className="form-group">
                    <label htmlFor="">
                        <input
                            type="checkbox"
                            className={"form-control"}
                            onClick={() => setActionsPrint((prev) => {
                                return {
                                    ...prev,
                                    ['material_list_html']: !actionsPrint.material_list_html
                                }
                            })}
                            checked={actionsPrint.material_list_html}
                        />
                        <span>{getT("Накладная на сырье")}</span>
                    </label>
                </div>


                <div className="form-group">
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            gaEvents.customEvent('klik_form', {
                                btn_print_docs: 'Печать документов',
                            })
                            fetchingMergeHtml()
                        }
                        }
                    >
                        {getT("Печать документов")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DetailPrintDocuments;