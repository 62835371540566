export const HELP_ARTICLE_CONSTANTS_IDS={
    order_list:7,
    tools: 10,
    services: 11,
    firms:12,
    setting_table: 13,
    menu_project: 14,
    calculate_order: 15,
    basic_information: 16,
    cutting_card: 17,
    package: 18,
    material_project:19,
    additional_features: 20,
    input_initial_data: 21,
    adding_part: 22,
    project_import: 23,
    material_good: 24,
    customer_material: 25,
    composite_material: 26,
    modules: 27,
    submenu: 28,
    preservation: 30,
    last_preservation: 31,
    company_logo: 32,
    project_name: 33,
    order_number:34,
    sozranenie: 35,
    upload_project: 36,
    download_project:37,
    open_giblab: 38,
    remaining_customer_material: 39,
    not_whole_remnants_company_material: 40,
    files_ordering: 41,
    hide_parts_without_cnc_operations: 42,
    group_processing_of_part: 43,
    clear_part_notes: 44,
    export_xlsx: 45,
    id_material:46,
    settings_material:47,
    filter_material:48,
    batch_processing: 49,
    add_part_plus:50,
    add_part_short_plus: 51,
    copy_part: 52,
    operation_cnc:53,
    part_drawing:54,
    part_information:55,
    vertical_mirror_copying:56,
    coping_with_horizontal_mirroring:57,
    mirror_vertically:58,
    mirror_horizontally:59,
    rotate_part:60,
    part_rotation:61,
    edge_type:62,
    grooving_patterns:63,
    grooving_patterns_part:64,
    table_app_created_grooving_template:65,
    corner_tabletop: 66,
    tabletop_layout:67,
    modules_header:68,
    groups:69,
    goods:70,
    details:71,
    drilling:72,
    grooving:73,
    stitching:74,
    trimming:75,
    notch:76,
    end_cut_at_and_angle:77,
    corner_processing:78,
    side_processing: 79,
    plane_processing: 80,
    quick_stitching:81,
    drilling_templates: 83,
    tabletop_treatment_templates: 84,
    trimming_the_table_top: 85,
    grooving_template: 86,
    import_from_sketch_up: 87,
    salary_default_plane: 91,
    rates: 92,
    shifts: 93,
    ready_mark:94,
    scanning: 95,
    readiness_report: 96,
    salary_report: 97,
    orders: 98,
}