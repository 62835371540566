import React from 'react';
import Modal from "../../../Modals/MyModal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getWSGoodsStockRetailPriceReadModal} from "../../../../store/selectors/wholesalary_selector";
import {wholesaleGoodsStockRetailPriceReadModalAC} from "../../../../store/actions/WholesaleActions";
/**
 * Базовая настройка товара установленна отповиком. Инфо модально окно
 *
 * @param {function}  getT -
 * @returns  {jsx || null}  -
 */
const WsGoodsStockRetailPriceReadModal = ({getT}) => {
    const dispatch = useDispatch()
    const stockRetailModal = useSelector(getWSGoodsStockRetailPriceReadModal);
    if (!stockRetailModal?.isOpen) return null;
    const onClose = () => {
        dispatch(wholesaleGoodsStockRetailPriceReadModalAC({isOpen: false, goods: null}));
    }

    return (
        <Modal open={stockRetailModal?.isOpen} onClose={onClose} title={getT("Базовая настройка товара оптовиком")}>
            <div className={"text-center"}>
                <ul className="list-group list-group-flush text-left">
                    <li className="list-group-item">
                        <strong>{getT("Товар")}: </strong>
                        [{stockRetailModal?.goods?.goods}] {stockRetailModal?.goods?.name}
                    </li>
                    {!!stockRetailModal?.goods?.retail_stock &&
                        <li className="list-group-item">
                            <strong>{getT("Розничный остаток")}: </strong>
                            {stockRetailModal?.goods?.retail_stock}
                        </li>}
                    {!!stockRetailModal?.goods?.whole_sale_stock &&
                        <li className="list-group-item">
                            <strong>{getT("Оптовый остаток")}: </strong>
                            {stockRetailModal?.goods?.whole_sale_stock}
                        </li>
                    }
                    {!!stockRetailModal?.goods?.minimal_quantity &&
                        <li className="list-group-item">
                            <strong>{getT("Минимальный остаток")}: </strong>
                            {stockRetailModal?.goods?.minimal_quantity}
                        </li>
                    }
                    {!!stockRetailModal?.goods?.recommended_retail_price &&
                        <li className="list-group-item">
                            <strong>{getT("Розничная цена")}: </strong>
                            {stockRetailModal?.goods?.recommended_retail_price}
                        </li>
                    }
                    {!!stockRetailModal?.goods?.currency_name &&
                        <li className="list-group-item">
                            <strong>{getT("Валюта")}: </strong>
                            {stockRetailModal?.goods?.currency_name}
                        </li>
                    }
                    {!!stockRetailModal?.goods?.default_delivery_days &&
                        <li className="list-group-item">
                            <strong>{getT("Срок доставки по умолчанию")}: </strong>
                            {stockRetailModal?.goods?.default_delivery_days}</li>
                    }

                </ul>

            </div>

        </Modal>
    );
};

export default WsGoodsStockRetailPriceReadModal;