import React from 'react';
import {useForm} from "react-hook-form";
import Select from "react-select";
import Button from "../../../components/ui/Button";


const OrderSearchFilter = ({filter, getT, handlerGetOrders, goods_service_data}) => {
    let defaultValues = {
        production_task: filter?.production_task, // d_update: filter?.d_update,
        order_id: filter?.order_id, service_type: filter?.service_type,
    }
    const {register, handleSubmit, setValue, watch, setError, formState: {errors}} = useForm({
        defaultValues: defaultValues
    });
    return (<div className={"card"}>
        <form onSubmit={handleSubmit(data => {
            handlerGetOrders({
                ...data,
                production_task: Number(data.production_task),
                general: filter?.general
            })
        })} className={"card-body read-mark-filter"}>
            <div className="row align-items-center">
                <div className="col-12 col-sm-3 col-md-3 col-lg-3 col-xl-2 my-1">
                    <div className="form-group">
                        <label>{getT("Поиск")}</label>
                        <input type="text" className="form-control"
                               {...register('order_id', {})}/>
                    </div>
                </div>
                <div className="col-12 col-sm-9 col-md-9 col-lg-6 col-xl-5 my-1">
                    <div className="form-group">
                        <label>{getT("Услуги")}</label>
                        <Select
                            placeholder={getT("Поиск")}
                            defaultValue={watch("service_type")}
                            isMulti={true}
                            options={goods_service_data?.map(service => {
                                return {
                                    value: service.goods_service_type_id,
                                    label: service?.name
                                }
                            })}
                            onChange={(selectOptions) => {
                                let values = selectOptions?.map(item => item?.value);
                                setValue("service_type", values)
                            }}
                        />

                    </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 col-xl-auto my-1">
                    <label></label>
                    <div className="form-check ">
                        <input className="form-check-input" type="radio"
                               id="inlineRadio1"  {...register("production_task")}
                               checked={!Number(watch("production_task"))} value={"0"}
                               onChange={() => setValue("production_task", "0")}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio1">{getT("Заказы")}</label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio"
                               id="inlineRadio2"  {...register("production_task")} value={'1'}
                               checked={!!Number(watch("production_task"))}
                               onChange={() => setValue("production_task", "1")}
                        />
                        <label className="form-check-label"
                               htmlFor="inlineRadio1">{getT("Задание на производство")}</label>
                    </div>

                </div>


                <div className="col-auto col-md-auto mx-auto my-1 mx-xl-0 ml-xl-auto">
                    <div className="d-flex flex-column">
                        <label></label>
                        <Button className={"btn-success"} type={"submit"}>{getT("Отфильтровать")}</Button>
                    </div>
                </div>
            </div>
        </form>
    </div>);
};

export default OrderSearchFilter;