import {dispatcherErrorThunk} from "../common_thunk";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {
    wholesaleGoodsRetailPriceSetAC,
    wholesaleGoodsStockRetailOfFirmAddModalAC,
    wholesaleGoodsStockRetailOfFirmDataAC
} from "../../actions/WholesaleActions";
import {WholesaleGoodsStockRetailAPI} from "../../../api/Wholesale/WholesaleGoodsStockRetailAPI";
import {dispatchedMaterialSearchFiltered} from "../material_search/material_search_thunk";
import {WholesaleGoodsRetailPriceAPI} from "../../../api/Wholesale/WholesaleGoodsRetailPriceAPI";
import {LS_FIRM} from "../../../utils/constants";

async function dispatchedGetStockRetail(api_data = null, dispatch) {
    const response = await WholesaleGoodsStockRetailAPI.getStockRetail(api_data);
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        dispatch(wholesaleGoodsStockRetailOfFirmDataAC(null))
        return null
    }
    dispatch(wholesaleGoodsStockRetailOfFirmDataAC(response?.data))

    return response?.data
}

/**
 * Получить список цен для парнера и списко базовых цен на товары
 * add_all_ws_prices=1 - флаг, который вернёт две коллекции: цены, установленные для партнёров, и цены для товаров.
 * @var data_api_for_stocks = array  получить только цены для коткретных товаров по id
 * @var data_api_for_stocks = null - получить все доступные цены по фирме
 * @param {array || null} data_api_for_stocks  - получить список цены
 * @param {array || null} data_api_for_stocks  - получить список цены
 * @returns  {Promise}  -
 */
export const getCombineRetailPricePartnerSource = (data_api_for_stocks, firm_main = localStorage.getItem(LS_FIRM)) => async (dispatch) => {
    let params = {firm_main: firm_main}
    let response_combine_retail_price = await WholesaleGoodsRetailPriceAPI.getCombineRetailPrice(params,
        {
            add_all_ws_prices: 1,
            only: Array.isArray(data_api_for_stocks) && data_api_for_stocks?.length ? data_api_for_stocks : null
        });
    if (response_combine_retail_price.hasOwnProperty('error') && response_combine_retail_price?.error) {
        return Promise.reject(response_combine_retail_price)
    }
    let price_for_partner = response_combine_retail_price?.data?.price_for_partner ?? null;
    let price_source = response_combine_retail_price?.data?.price_source ?? null;
    dispatch(wholesaleGoodsRetailPriceSetAC(price_for_partner));
    dispatch(wholesaleGoodsStockRetailOfFirmDataAC(price_source))
    return Promise.resolve(response_combine_retail_price)
}
/**
 *
 * Запросить по фильтру материалы товаров и получить базовые и партнерские установленные цены для продажи.
 *
 * @param {object}  filter_data -
 */
export const filteredWSGoodsStockRetailOfFirmAndMaterialSearchThunk = (filter_data) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await dispatchedMaterialSearchFiltered(filter_data, dispatch)
        let data_api_for_stocks = response?.goods?.map(e => Number(e?.id));
        await dispatch(getCombineRetailPricePartnerSource(data_api_for_stocks));
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
/**
 * Добавить товар в продажу и установки цен на свой товар для продажи в опт
 *
 * @param {array} api_data_array  -
 */
export const addWSGoodsStockRetailThunk = (api_data_array) => async (dispatch, getState) => {
    try {
        const material_goods = getState().material_search?.goods ?? [];
        dispatch(toggleLoaderStatusAC(true));
        const response = await WholesaleGoodsStockRetailAPI.setStockRetail(api_data_array);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return null
        }
        let data_api_for_stocks = material_goods?.map(e => Number(e?.id));
        await dispatchedGetStockRetail({only: data_api_for_stocks}, dispatch);
        dispatch(wholesaleGoodsStockRetailOfFirmAddModalAC({isOpen: false, goods: null}))
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}


/**
 * deleteWSGoodsStockRetailThunk. Удаление настройки товара продажи в опт, так же удалить все настройки цен для партнеров по этому товару. получить обновленые данные
 * @param {array} api_delete_stock - id настройки овара продажи в опт
 * @param {array | null} goods_retail_price - настройки цен для партнеров
 * @returns  {undefined}  -
 */
export const deleteWSGoodsStockRetailThunk = ({api_delete_stock, goods_retail_price}) => async (dispatch, getState) => {
    try {

        const material_goods = getState().material_search?.goods ?? [];
        dispatch(toggleLoaderStatusAC(true));
        const response = await WholesaleGoodsStockRetailAPI.deleteStockRetail(api_delete_stock);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return null
        }
        let deleteGoodsRetailPriceIds = (Array.isArray(goods_retail_price) && goods_retail_price?.length)
            ? goods_retail_price?.map(e => ({
                id: Number(e?.ws_goods_retail_price_id)
            })) : null;

        if (deleteGoodsRetailPriceIds) {
            const response_retail_price = await WholesaleGoodsRetailPriceAPI.deleteRetailPrice(deleteGoodsRetailPriceIds);
            if (response_retail_price.hasOwnProperty('error') && response_retail_price?.error) {
                dispatch(dispatcherErrorThunk(response_retail_price));
                return null
            }
        }
        let data_api_for_stocks = material_goods?.map(e => Number(e?.id));
        await dispatch(getCombineRetailPricePartnerSource(data_api_for_stocks));
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}