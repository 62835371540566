import React from 'react';

const ActionSelect = ({childrenOption, label, onChange, optionDefaultTitle='--------'}) => {
    // if(!Array.isArray()) return <></>
    return (
        <td colSpan="2">
            <div className="form-group">
                {label}
                <select
                    className="form-control"
                    onChange={onChange}
                >
                    <option value="">{optionDefaultTitle}</option>
                    {childrenOption}
                </select>
            </div>
        </td>

    );
};

export default ActionSelect;