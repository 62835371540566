import React, {useMemo} from 'react';
import Modal from "../../MyModal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getWSOrdersInformationModal} from "../../../../store/selectors/wholesalary_orders_goods_selector";
import {wsGetGoodsInformationModalAC} from "../../../../store/actions/WholesaleOrdersGoodsActions";
import AvailabilityInformation from "./components/AvailabilityInformation";
import WSOrdersGoodsSetForm from "../../../WholeSale/Forms/WSOrderGoods/WSOrdersGoodsSetForm";
import {
    wsCreateOrUpdateExistOrderGoodsInformationThunk
} from "../../../../store/thunks/wholesale/orders_goods/ws_order_goods_thunk";
import {getOrderDetailWholesaleGoods} from "../../../../store/selectors/main_selector";

/**
 *   информация по товару, где его можно купить, как быстро его доставят, какое минимальное количество на закупку.
 *   и создать потребность, или обновить существующую
 *
 */
const WsGoodsInformationModal = ({getT}) => {
    const dispatch = useDispatch();
    const orderGoods = useSelector(getOrderDetailWholesaleGoods);
    const orderInformationModal = useSelector(getWSOrdersInformationModal);
    const initialValue = useMemo(() => {
        let currentGoods = orderInformationModal?.exist ?? null;
        let payload = {
            orders_goods_id: currentGoods?.orders_goods_id ?? null,
            ws_orders_goods_id: currentGoods?.ws_orders_goods_id ?? null,
            order_supply: null,
            order_supply_unlink: null,
            order_main_firm: null
        }
        let orderSupply = currentGoods?.order_supply ?? null;
        if (orderSupply?.status?.ws_orders_supply_id && !!Number(orderSupply?.status?.ws_orders_supply_id)) {
            payload = {
                ...payload, order_supply: {
                    value: Number(orderSupply?.status?.ws_orders_supply_id),
                    label: `${orderSupply?.status?.name} | ${orderSupply?.firm_main?.name_firm ?? '-'}`,
                    firm_main: {
                        id: orderSupply?.firm_main?.id_firm_main ?? null,
                        name: orderSupply?.firm_main?.name_firm ?? '-',
                    }
                }
            }
        }
        return payload
    }, [orderGoods, orderInformationModal])
    if (!orderInformationModal?.isOpen) return null;
    const onClose = () => {
        dispatch(wsGetGoodsInformationModalAC({isOpen: false, currentGoods: null, availabilityInfo: null, exist: null}))
    };
    const wholesale = orderInformationModal?.availabilityInfo?.WS_GoodsStockSourceRetail?.whole_sale?.data ?? null;
    const handlerSubmit = (data) => {
        dispatch(wsCreateOrUpdateExistOrderGoodsInformationThunk({
            set_api_data: data,
            orderId: orderInformationModal?.orderId
        }));
    }
    let titleModal = `[${orderInformationModal?.currentGoods?.id}] ${orderInformationModal?.currentGoods?.title}`;
    return (
        <Modal title={titleModal} open={orderInformationModal.isOpen} onClose={onClose}>
            <AvailabilityInformation availabilityInfo={orderInformationModal?.availabilityInfo} getT={getT}/>
            <div className="mt-4 border-top mb-4"></div>
            {!!(Array.isArray(wholesale) && wholesale?.length) &&
                <WSOrdersGoodsSetForm getT={getT} ws_orders_goods_id={initialValue.ws_orders_goods_id}
                                      order_supply_unlink={null}
                                      order_supply={initialValue.order_supply}
                                      wholesale={wholesale} handlerSubmit={handlerSubmit}
                                      orders_goods_id={orderInformationModal?.currentGoods?.orders_goods_id}/>
            }
        </Modal>
    );
};

export default WsGoodsInformationModal;