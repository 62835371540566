import {createFormData} from "../../../../utils/helpers";
import {dispatcherErrorThunk} from "../../common_thunk";
import {toggleLoaderStatusAC} from "../../../reducers/MainReducer";
import {WholesaleOrdersSupplyAPI} from "../../../../api/Wholesale/WholesaleOrdersSupplyAPI";
import {wsGetOrdersSupplyAction, wsSetOrderSupplyModalAction} from "../../../actions/WholesaleOrdersSupplyActions";

const dispatchedGetOrdersSupply = async (api_data, dispatch) => {
    let dataForGeOrders = {...api_data}
    if (dataForGeOrders.date_from) {
        dataForGeOrders = {...dataForGeOrders, date_from: `${dataForGeOrders.date_from} 00:00:00`}
    }
    if (dataForGeOrders.date_to) {
        dataForGeOrders = {...dataForGeOrders, date_to: `${dataForGeOrders.date_to} 00:00:00`}
    }
    let apiTransform = Object.entries(dataForGeOrders).reduce((acc, [key, value]) => {
        if (value) {
            return {...acc, [key]: value}
        }
        return acc
    }, {})
    const formData = createFormData(apiTransform);
    const response = await WholesaleOrdersSupplyAPI.getOrdersSupplyList(formData)
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    let data = response?.data?.data;

    let filter = {
        total: response?.data?.total,
        offset: response?.data?.offset,
        limit: response?.data?.limit,
        status: api_data?.status,
        search_by_name: api_data?.search_by_name,
        supplier: api_data?.supplier ?? null,
        date_from: api_data?.date_from ?? null,
        date_to: api_data?.date_to ?? null,
        main_firm_request: api_data?.main_firm_request ?? 0

    }
    let dataEntryOrders = data?.orders ?? null;
    let orders_for_current_firm_as_partner = data?.orders_for_current_firm_as_partner ?? null;
    let orders_for_current_firm_as_main_firm = data?.orders_for_current_firm_as_main_firm ?? null;
    dispatch(wsGetOrdersSupplyAction({
        data: dataEntryOrders,
        orders_for_current_firm_as_main_firm: orders_for_current_firm_as_main_firm,
        orders_for_current_firm_as_partner: orders_for_current_firm_as_partner,
        filter: filter
    }))
}
/**
 * Списки заказов.
 *
 */
export const wsGetOrdersSupplyThunk = (api_data) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await dispatchedGetOrdersSupply(api_data, dispatch);
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}

const setOrderSupplyApi = async (api_data) => {
    let orderSupply = Object.keys(api_data)
        .reduce((acc, key) => {
            let v = api_data[key];
            if (!!v) {
                return {...acc, [key]: v}
            }
            return acc
        }, {});
    let formData = createFormData(orderSupply);
    return await WholesaleOrdersSupplyAPI.setOrdersSupply(formData);

}
export const wsSetOrdersSupplyThunk = (order_supply_form) => async (dispatch, getState) => {
    try {
        let filterOrdersPage = getState().wholesale?.orders_supply?.filter;
        let filterDataForApi = {
            search_by_name: filterOrdersPage?.search_by_name,
            supplier: filterOrdersPage?.supplier,
            date_from: filterOrdersPage?.date_from,
            date_to: filterOrdersPage?.date_to,
            status: filterOrdersPage?.status,
            offset: 0,
            main_firm_request: filterOrdersPage?.main_firm_request,
        }
        dispatch(toggleLoaderStatusAC(true));
        const response = await setOrderSupplyApi(order_supply_form);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        dispatch(wsSetOrderSupplyModalAction({isOpen: null, orderCurrent: null}));
        await dispatchedGetOrdersSupply(filterDataForApi, dispatch);
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}

/**
 * Удалять заказ поставщику может только фирма Партнер, когда он в статусе редактировании.
 *
 * @param {number} ws_orders_supply_id - id записи заказа
 */
export const wsDeleteOrderSupplyById = (ws_orders_supply_id) => async (dispatch, getState) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        let filterOrdersPage = getState().wholesale?.orders_supply?.filter;
        let filterDataForApi = {
            search_by_name: filterOrdersPage?.search_by_name,
            supplier: filterOrdersPage?.supplier,
            date_from: filterOrdersPage?.date_from,
            date_to: filterOrdersPage?.date_to,
            status: filterOrdersPage?.status,
            offset: 0,
            main_firm_request: filterOrdersPage?.main_firm_request,
        }
        const formDataForDelete = createFormData({id: ws_orders_supply_id});
        const response = await WholesaleOrdersSupplyAPI.deleteOrderSupply(formDataForDelete);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        await dispatchedGetOrdersSupply(filterDataForApi, dispatch);
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}
/**
 * Получить список заказов для коткретного типа и обнулить фильтр.
 * * @param {number} main_firm_request -  Тип заказов
 *    0 - получить заказы, сделанные фирмой для своих поставщиков.
 *    1 - получить заказы, полученные фирмой от своих клиентов (партнёров).
 */
export const wsChangeTypeMainFirmRequestOrdersSupply = (main_firm_request) => async (dispatch) => {
    try {
        let filterApiData = {
            search_by_name: null,
            supplier: null,
            date_from: null,
            date_to: null,
            total: null,
            offset: 0,
            main_firm_request: main_firm_request
        }
        dispatch(wsGetOrdersSupplyThunk(filterApiData));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));

    }
}