import React, {useCallback} from 'react';
import {orderStatus, productionTaskType} from "../selectors";
import {deleteProductionTaskThunk, getMainSettingsProject} from "../../../../store/reducers/MainReducer";
import TaskItem from "./TaskItem";
import {useDispatch, useSelector} from "react-redux";
import {isShallowEqual} from "../../../../utils/helpers";

const ProjectTask = ({getT}) => {
    const lang = useSelector(state => state.translation.local);
    const productionTypes = useSelector(productionTaskType, (prev, next)=>isShallowEqual(prev, next))
    const status = useSelector(orderStatus, (prev, next)=> isShallowEqual(prev, next));
    const orders_check_enable = useSelector(state => state.main.orders_check_enable)
    const dispatch = useDispatch();

    const getStatus = (value =' ')=>{
        const data = status?.filter(item=> value === item?.name)?.[0] ?? status?.filter(item=> Number(item?.id) === Number(value))?.[0];
        if(data){
            return data
        }
        return {
            color: "danger",
            name: getT('Не указано')
        }

    }
    const onDeleteTask = useCallback((id)=>{
        dispatch(deleteProductionTaskThunk(id));
        dispatch(getMainSettingsProject());
    }, [dispatch]);



    return (
        <div className={''}>
            <div className="table-responsive">
                <table className="table m-0 table-striped table-hover  orders-table-main">
                    <thead>
                    <tr>
                        <th scope="col">{getT("Код 1С")}</th>
                        <th scope="col">{getT("Тех. ID")}</th>
                        <th scope="col">{getT("Название")}</th>
                        <th scope="col">{getT("Статус")}</th>
                        <th scope="col">{getT("Дата")}</th>
                        <th scope="col">{getT("Дата производства")}</th>
                        <th scope="col">{getT("Действия")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {productionTypes.map((el) => {
                        const status = ''
                        return <TaskItem key={`task${el?.id}`} task={el} getT={getT} deleteTask={onDeleteTask} getStatus={getStatus} orders_check_enable={orders_check_enable}/>;
                    })}
                    </tbody>
                </table>

            </div>
        </div>
    );
};

export default ProjectTask;

