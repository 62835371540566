export const getOrderStatus = state => state?.main?.orderStatus;
export const getClientsList = state => state?.main?.clients;
export const getVatAddPriceIfMoreThan = state => {
    let value = state?.main.settings_project?.['production.VAT_add_price_if_more_than_0'] || 0;
    return Number(value)
}
export const getVatAddedTaxShow = state => {
    let value = state?.main.settings_project?.['production.vat_added_tax_show_needed'] || 1;
    return !Number(value)
}

export const getSheetDataPrintConfirmModal = state => state?.main?.sheet_data_print_confirm_modal;
export const getSettingProductions = state => state?.main?.settings_project;
export const getSettingProductionByKey = (key) => state => state?.main?.settings_project?.[key] ;

export const getConfirmCommonModal =  state => state?.main?.confirm_modal_common;
export const getCurrency =  state => state?.main?.currency;

export const getOrderDetailWholesaleGoods =  state => state?.main?.order_detail_wholesale_goods
//**Список подробной информации о товарах и его поставщиках. */
export const getOrderDetailWholesaleGoodsInformation=  state => state?.main?.order_detail_wholesale_goods_information;
// export const getOrderDetailWholesaleExistGoods =  state => state?.main?.order_detail_wholesale_exist_goods;
