import {instances, URI_API_EXTTUNELL} from "./setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const GET_SALORY_ADDITIONAL_DOCUMENT = 'get_salory_additional_document';
const ADD_SALORY_ADDITIONAL_DOCUMENT = 'add_salory_additional_document';
const UPDATE_SALORY_ADDITIONAL_DOCUMENT = 'update_salory_additional_document';
const GET_SALORY_SERVICE_READY_MARKS_BY_SHIFT = 'get_saloty_service_ready_marks_by_shift';
const PUT_ADDITIONAL_DOCUMENT_LIST_CHANGES = 'put_additional_document_list_changes';
const DELETE_SALARY_ADDITIONAL_DOCUMENT_BONUS = 'delete_salary_additional_document_bonus';
const DELETE_SALARY_ADDITIONAL_DOCUMENT_FINE = 'delete_salary_additional_document_fine';
const UPDATE_SALARY_ADDITIONAL_DOCUMENT_BONUS = 'update_salary_additional_document_bonus';
const UPDATE_SALARY_ADDITIONAL_DOCUMENT_FINE = 'update_salary_additional_document_fine';

/**
 * Базовые справочники
 * @method get  - список пользователей
 */
export const AdditionalDocumentAPI = {
    get(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: GET_SALORY_ADDITIONAL_DOCUMENT
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    add(form_data) {

        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: ADD_SALORY_ADDITIONAL_DOCUMENT
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    update(form_data) {

        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: UPDATE_SALORY_ADDITIONAL_DOCUMENT
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getServiceReadyMarks(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: GET_SALORY_SERVICE_READY_MARKS_BY_SHIFT
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    deleteAdditionalDocumentBonus(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: DELETE_SALARY_ADDITIONAL_DOCUMENT_BONUS
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    deleteAdditionalDocumentFine(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: DELETE_SALARY_ADDITIONAL_DOCUMENT_FINE
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    putAdditionalDocumentListChanges(api_data) {
        return instances.post(URI_API_EXTTUNELL, api_data, {
            headers: {
                action: PUT_ADDITIONAL_DOCUMENT_LIST_CHANGES
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    updateAdditionalDocumentBonus(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: UPDATE_SALARY_ADDITIONAL_DOCUMENT_BONUS
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    updateAdditionalDocumentFine(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: UPDATE_SALARY_ADDITIONAL_DOCUMENT_FINE
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },

}