import React from "react";
import {gaEvents} from "../../gaEvents";

export const FiledInput = ({gaEventAction='form_order_dynamic_filed',label, value, name, type = 'text', onHandlerChange}) => {
    return <div className="form-group">
        {label && <label htmlFor="">{label} </label>}
        <input type={type} className={'form-control'} value={value} name={name} onChange={onHandlerChange}
               onFocus={() => {
                   gaEvents.customEvent(gaEventAction, {
                       field_focus: label
                   })
               }
               }
        />
    </div>
}
export const FiledSelect = ({gaEventAction='form_order_dynamic_filed', label, value, name, children, onHandlerChange}) => {

    return <div className="form-group">
        {label && <label htmlFor="">{label} </label>}
        <select
            className="form-control"
            value={value}
            defaultValue={value}
            onChange={(e)=>{
                gaEvents.customEvent('form_order_dynamic_filed', {
                    field_focus: label
                })
                onHandlerChange(e)
            }}

        >
            {children}
        </select>
    </div>
}