import React from 'react';
import Button from "../../../../components/ui/Button";
import {useDispatch} from "react-redux";
import {setShiftPlaneCreateModalAC, setShiftPlaneUpdateModalAC} from "../../../../store/actions/SalaryProject";
import ItemShiftPlane from "./ItemShiftPlane";
import {shiftPlaneDeleteThunk} from "../../../../store/thunks/salary_project/shifh-thunk";

/**
 * ShiftPlane. план на смену
 *
 * @param {function} getT  -
 * @param {object} default_plane  -
 * @param {array} default_plane.data  -
 * @param {object} shift  -
 * @param {boolean} is_available_access_role  - есть ли права доступа к crud shift
 * @returns  {jsx}  -
 */
const ShiftPlane = ({getT, shift,  default_plane, is_available_access_role, is_open_shift}) => {
    const dispatch = useDispatch();
    const handlerCreatePlane = () => {
        dispatch(setShiftPlaneCreateModalAC({
            isOpen: true,
            form_values: {
                shift: shift.salary_shift_id
            }
        }))
    }

    const handlerUpdatePlane = ({service_type, count_plan, comment, salary_shift_plan_id}) => () => {
        dispatch(setShiftPlaneUpdateModalAC({
            isOpen: true,
            form_values: {
                shift: shift.salary_shift_id,
                service_type, count_plan, comment, salary_shift_plan_id

            }
        }))
    }
    const handlerDelete = ({shift, service_type}) => () => {
        dispatch(shiftPlaneDeleteThunk({shift, service_type}))
    }
    return (
        <div className={"card"}>
            <div className="card-header">
                <div className="d-flex justify-content-between align-items-center flex-row">
                    <h5 className={"card-title font-weight-bold"}>{getT("План")}</h5>
                    {is_available_access_role && is_open_shift && <Button className={"btn-success"} onClick={handlerCreatePlane}>{getT("Создать")}</Button>}
                </div>
            </div>
            <div className="card-body">
                {shift?.plans?.length ?
                    <ul className={'list-group-shift-plane'}>
                        {shift?.plans?.map(plane => {
                            let default_plane_find = default_plane?.data?.find(item => Number(item?.goods_service_type_id) === Number(plane?.service_type))
                            return <ItemShiftPlane key={`plane_${plane?.salary_shift_plan_id}`}
                                is_available_access_role={is_available_access_role && is_open_shift}
                                default_plane_find={default_plane_find}
                                plane={plane} getT={getT}
                                handlerUpdatePlane={handlerUpdatePlane({
                                    service_type: plane?.service_type,
                                    salary_shift_plan_id: plane?.salary_shift_plan_id,
                                    count_plan: plane?.count_plan,
                                    comment: plane?.comment
                                })}
                                                   curretn_palane={plane}
                                handlerDelete={handlerDelete({
                                    shift: shift.salary_shift_id,
                                    service_type: plane?.service_type,
                                })}
                            />
                        })}
                    </ul>
                    : <p className="">{getT("")}</p>
                }
            </div>
        </div>
    );
};

export default ShiftPlane;