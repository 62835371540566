import React, {useState} from 'react';

const ItemPaz = ({frez, getT, handlerOpenModalSeeMoreStaticsByKey=null}) => {
    let is_has_template = frez?.hasOwnProperty('frez_template_usage') && Array.isArray(frez.frez_template_usage) && !!frez?.frez_template_usage;
    const [isOpen, setOpen] = useState(!is_has_template)
    return (
        <li className={"px-1 py-2"}>
            <span>{getT("Фрезеровка")}:</span> <span className="font-weight-bold"></span>
            <ul className={"pl-0"}>
                {/*{!!frez?.template &&*/}
                    <li className={"list-group-item  px-1 py-2 border-top-0 border-right-0 border-left-0 d-flex justify-content-between flex-column"}>
                        <div className="d-flex justify-content-between" onClick={() => {
                            if (!is_has_template) return null
                            setOpen(!isOpen)
                        }
                        }>
                            <span>{getT("По шаблонам iFurn.pro")}</span>
                            <span className="font-weight-bold">{frez?.template ?? '0'}
                                {!!(is_has_template && frez?.template ) &&  <i className={`ml-2 fas fa-angle-${isOpen ? 'up' : 'down'}`}></i>}
                                {!!(frez?.template && handlerOpenModalSeeMoreStaticsByKey) &&  <i className="far fa-eye text-primary ml-2 cursor-pointer" onClick={handlerOpenModalSeeMoreStaticsByKey('frez', {type: 'template'})}> </i>}

                            </span>
                        </div>
                        {!!(is_has_template && isOpen && frez?.template)
                            &&
                            <ul>
                                {frez?.frez_template_usage?.map(e => {
                                    return <li>
                                        <span>{e?.name}</span> <span
                                        className="font-weight-bold">{e?.count}
                                    </span>
                                    </li>

                                })
                                }
                            </ul>}
                    </li>
                {/*}*/}
                {/*{!!frez?.import &&*/}
                    <li className={"list-group-item px-1 py-2 border-top-0 border-right-0 border-left-0  d-flex justify-content-between"}>
                        <span>{getT("Импортированные шаблоны")}</span> <span
                        className="font-weight-bold">{frez?.import ?? '0'}
                        {!!(frez?.import && handlerOpenModalSeeMoreStaticsByKey) &&  <i className="far fa-eye text-primary ml-2 cursor-pointer" onClick={handlerOpenModalSeeMoreStaticsByKey('frez', {type: 'import'})}> </i>}

                    </span>
                    </li>
                {/*}*/}

            </ul>
        </li>

    );
};

export default ItemPaz;