import React, {useEffect, useState} from "react";
import PageTitle from "../PageTitle/PageTitle";
import { OrderItem } from "./Orders";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as rdrLocales from "react-date-range/dist/locale";
import { formatDate } from "./OrderOutstanding";
import {withLang} from "../../hoc/withLang";
import {useSelector} from "react-redux";
import {gaEvents} from "../../gaEvents";

const OrdersCanceled = (props) => {
    const lang = useSelector(state => state.translation.local);
  const [visibleClearDate, setVisibleClearDate] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  // console.log(props, "OrdersCanceled");
  const toggleOpenDatePicker = () => {
    setOpenDatePicker(!openDatePicker);
  };

  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  const saveData = () => {
    props.changeFilterValue(
      "date",
      {
        from: formatDate(date[0].startDate),
        to: formatDate(date[0].endDate)
      },
      props.form
    );
    props.changeFilterValue("status", 8, props.form);

    setOpenDatePicker(false);
    setVisibleClearDate(true);
  };

  const clearData = () => {
    props.changeFilterValue(
      "date",
      {
        from: "",
        to: ""
      },
      props.form
    );
    setVisibleClearDate(false);
  };
 const onResetForms = ()=>{
   // debugger;
  props.onClear({ firm:localStorage.getItem('i_furn_user_firm'), archive: 0, status:8, limit: 1});
 }
  const renderOrders = () => {
    if (props.orders && props.orders.length > 0) {
      return props.orders.map((e) => {
        return (
          <OrderItem
            order={e}
            toggleChangeStatus={props.toggleChangeStatus}
            updateOrder={props.updateOrder}
            status={props.status}
            changedStatus={props.changedStatus}
            withStatus={true}
            withClient={true}
            roles={props.userRoles}
            onResetForms={onResetForms}
            getT={props.getT}
            clients={props.clients}
          />
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={9}>
            <p className={"text-center"}>{props.getT("Заказы еще не созданы")}</p>
          </td>
        </tr>
      );
    }
  };

  return (
    <div className="content-wrapper">
      <PageTitle title={props.title} />

      <section className="content">
        <div className="container-fluid">
          <div className="card">
            {/*className={'' + (props.form.validated ? 'was-validated' : '')} onSubmit={(event) => {event.preventDefault(); props.submitForm()} }*/}
            <form>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>{props.getT("Код заказа")}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={props.form.code1c}
                        onChange={(event) => {
                          props.changeFilterValue("status", 8, props.form);
                          props.changeFilterValue(
                            "code1c",
                            event.target.value,
                            props.form
                          );
                        }}
                        onFocus={()=>{
                            gaEvents.customEvent('form_order_canceled', {
                                field_focus: 'Код заказа'
                            })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>{props.getT("ID заказа")}:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder=""
                        value={props.form.id}
                        onChange={(event) => {
                          props.changeFilterValue(
                            "id",
                            event.target.value,
                            props.form
                          );
                          props.changeFilterValue("status", 8, props.form);
                        }}
                        onFocus={()=>{
                            gaEvents.customEvent('form_order_canceled', {
                                field_focus: 'ID заказа'
                            })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      {visibleClearDate && (
                        <i
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          onClick={clearData}
                          class="fas fa-ban "
                        >
                            {props.getT("Сбросить")}
                        </i>
                      )}

                      <label>{props.getT("Дата")}: </label>
                      <input
                        className="form-control"
                        type="text"
                        value={`${props.form?.date?.from || ""} ... ${
                          props.form?.date?.to || ""
                        }`}
                        onClick={toggleOpenDatePicker}
                        onFocus={()=>{
                            gaEvents.customEvent('form_order_canceled', {
                                field_focus: 'Дата'
                            })
                        }}
                      />

                      {openDatePicker && (
                        <div style={{ zIndex: 20, position: "absolute" }}>
                          <DateRange
                            locale={rdrLocales[lang]}
                            editableDateInputs={true}
                            onChange={(item) => setDate([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={date}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            className="btn-container"
                          >
                            <i
                              i
                              style={{ cursor: "pointer" }}
                              onClick={saveData}
                              class="fas fa-check-circle"
                            >
                                {props.getT("Применить")}
                            </i>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="form-group">
                      <label>{props.getT("Клиент")}</label>
                      <select
                        className="form-control"
                        value={props.form.client}
                        defaultValue={props.form.client.status}
                        onChange={(event) => {
                            gaEvents.customEvent('form_order_canceled', {
                                field_focus: 'Клиент'
                            })
                          props.changeFilterValue(
                            "client",
                            event.target.value,
                            props.form
                          );
                          props.changeFilterValue("status", 8, props.form);
                        }}
                      >
                        <option value={""}>{props.getT("Все")}</option>
                        {props.clients.map((e) => (
                          <option value={e.id}>{e.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                    <div className="col-sm-3">
                                    <span className="btn btn-danger"
                                          onClick={()=>{
                                             onResetForms()
                                          }}
                                    >
                                        {props.getT("Очистить")}
                                    </span>
                    </div>
                </div>
              </div>
            </form>
          </div>
          <div className="card">
            {/*<div className="card-header border-transparent">*/}
            {/*<h3 className="card-title">Latest Orders</h3>*/}
            {/*<div className="card-tools">*/}
            {/*<button type="button" className="btn btn-tool" data-card-widget="collapse">*/}
            {/*<i className="fas fa-minus"></i>*/}
            {/*</button>*/}
            {/*<button type="button" className="btn btn-tool" data-card-widget="remove">*/}
            {/*<i className="fas fa-times"></i>*/}
            {/*</button>*/}
            {/*</div>*/}
            {/*</div>*/}
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table m-0 table-striped table-hover  orders-table-main">
                  <thead>
                    <tr>
                      {/*<th>{props.getT("Код 1С")}</th>*/}
                      <th>{props.getT("Тех. ID")}</th>
                      <th>{props.getT("Название")}</th>
                      <th>{props.getT("Клиент")}</th>
                      <th>{props.getT("Менеджер")}</th>
                      {/*<th>Клиент <sup><small>(в заказе)</small></sup></th>*/}
                      <th>{props.getT("Статус")}</th>
                      <th>{props.getT("Дата изменения")}</th>
                      <th>{props.getT("Дата производства")}</th>
                      <th>{props.getT("Действия")}</th>
                    </tr>
                  </thead>
                  <tbody>{renderOrders()}</tbody>
                </table>
              </div>
            </div>
            {/*<div className="card-footer clearfix">*/}
            {/*<ul className="pagination pagination-sm m-0 float-right">*/}
            {/*<li className="page-item"><a className="page-link" href="#">&laquo;</a></li>*/}
            {/*<li className="page-item"><a className="page-link" href="#">1</a></li>*/}
            {/*<li className="page-item"><a className="page-link" href="#">2</a></li>*/}
            {/*<li className="page-item"><a className="page-link" href="#">3</a></li>*/}
            {/*<li className="page-item"><a className="page-link" href="#">&raquo;</a></li>*/}
            {/*</ul>*/}
            {/*</div>*/}
          </div>
        </div>
      </section>
    </div>
  );
};

export default withLang(OrdersCanceled);
