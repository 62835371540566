import React, {useState} from "react";
import {withLang} from "../../hoc/withLang";
import {languagesList} from "../../store/reducers/TranslationReducer";
import {useDetectClickOutside} from "react-detect-click-outside/dist";
import Globe from "../../Icons/Globe";

const Login = (props) => {
    const [isSelect, setIsSelect] = useState(false);
    const refSelect = useDetectClickOutside({onTriggered: () => setIsSelect(false)});

    return (
        <div className="">
            <div className="login-header">
                <div className="select-custom" ref={refSelect}>
                    <div className="select__head"
                         onClick={() => setIsSelect(!isSelect)}>
                        <Globe/>
                        {props.localLang}
                    </div>
                    {isSelect && <ul className="select__body">
                        {languagesList.map((code, index) => {
                            return <li
                                onClick={(e) => props.onChangeLang(code)}
                                key={index} value={code}
                                className={props.localLang === code ? 'disabled' : ''}
                            >
                                {code}
                            </li>
                        })}
                    </ul>}
                </div>
            </div>
            <div className="auth">
                <div className="login-box">
                    <div className="login-logo">
                        <a href="/">
                            <b>iFurn</b>PRO
                        </a>
                    </div>
                    <div className="card">
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">{props.getT("Sign in to start your session")}</p>
                            <form
                                onSubmit={(event) => {
                                    event.preventDefault();
                                    props.getAuth();
                                }}
                            >
                                <div className="input-group mb-3">
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder={props.getT("Email")}
                                        value={props.login}
                                        onChange={(event) =>
                                            props.changeValue("login", event.target.value)
                                        }
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"> </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input
                                        type="password"
                                        className="form-control"
                                        placeholder={props.getT("Password")}
                                        value={props.pass}
                                        onChange={(event) =>
                                            props.changeValue("pass", event.target.value)
                                        }
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock"> </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <div className="icheck-primary">
                                            <input type="checkbox" id="remember"/>
                                            <label htmlFor="remember">{props.getT("Remember Me")}</label>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <button type="submit" className="btn btn-primary btn-block">
                                            {props.getT("Sign In")}
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <div className="social-auth-links text-center mb-3">
                                <p>- {props.getT("OR")} -</p>
                                <p className="mb-1">
                                    <span style={{cursor: "pointer"}}
                                          onClick={() => {
                                              props.onResetPassword({login: props.form.login})
                                          }}>{props.getT("I forgot my password")}</span>
                                </p>
                                {props.form && props.form.isFailure ? (
                                    <p className={"text-danger login_failure_text"}>
                                        {props.getT("Invalid login or password")}
                                    </p>
                                ) : (
                                    ""
                                )}
                                {/*<p className="mb-0">*/}
                                {/*<a href="register.html" className="text-center">Register a new membership</a>*/}
                                {/*</p>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withLang(Login);
