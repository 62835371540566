import React, {useEffect, useMemo, useState} from 'react';
import ContainerContent from "../../components/ui/ContainerContent";
import {useDispatch, useSelector} from "react-redux";
import {
    getStaticsXncOperationsMaterials,
    getStatisticsIsLoading
} from "../../store/selectors/statistics_xnc_operations_selector";
import PageTitle from "../../components/PageTitle/PageTitle";
import {getStatisticXncOperationsMaterials} from "../../store/thunks/statistic/statistic_xnc_operations_thunk";
import StatisticsFull from "./components/Statistics/StatisticsFull";
import {
    toggleStatisticXncOperationMaterialFilteredByKeyModalAC
} from "../../store/actions/StatisticXncOperationsAction";
import StatisticsXncOperationsMaterialByKeyModal
    from "../../components/Modals/StatisticsXncOperationsMaterialByKeyModal";
import Loader from "../../components/Loader/Loader";
import useMonthAndYearDate from "../../hook/useMonthAndYearDate";


const StatisticXncOperationsMaterialsPage = ({getT}) => {
    const dispatch = useDispatch();
    const statistics_material_store = useSelector(getStaticsXncOperationsMaterials);
    const isLoading = useSelector(getStatisticsIsLoading);
    // 0 => material; 1 => material_client
    const [type_material, setTypeMaterial] = useState(0);
    const [form_material_id, setFormMaterialId] = useState('');
    let material_for_select = useMemo(() => {
        let key = type_material ? 'material_client_' : 'material';
        return statistics_material_store?.[key] ?? []
    }, [statistics_material_store, type_material])
    const [date_field, handlerChangeDate, parsForParamsData] = useMonthAndYearDate()

    useEffect(() => {
        // let data_split = date_field?.split('-').map(Number);
        let params = parsForParamsData()
        dispatch(getStatisticXncOperationsMaterials(params))
    }, [dispatch, date_field])

    const handlerChangeTypeMaterial = (val) => e => {
        setTypeMaterial(val);
        setFormMaterialId('')
    }
    const handlerChangeFormMaterialId = (e) => {
        setFormMaterialId(e.target.value)
    }
    const findMaterialById = useMemo(() => {
        let key_material = type_material ? 'material_client_' : 'material';
        let id_material = Number(form_material_id)
        let data = statistics_material_store?.[key_material] ?? null
        if (!data || !id_material) return null;
        return data?.find(e => Number(e?.goods_id) === id_material)
    }, [form_material_id, type_material, statistics_material_store])
    const handlerOpenModalSeeMoreStaticsByKey = (key) => e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(toggleStatisticXncOperationMaterialFilteredByKeyModalAC({isOpen: true, key, type_material}))
    }
    return (
        <>     {isLoading && <Loader/>}
            <ContainerContent>
                <PageTitle title={"statistic_xnc_operations_materials"}/>
                <div className="container-fluid ">
                    <div className="row">
                        <div className="col-12">
                            <div className="card mt-4 px-3 py-2">
                                <div className="form-row  mb-3 align-items-end">
                                    <div className="form-group col-md-6 col-lg-4 col-xl-2">
                                        <label htmlFor="exampleInputEmail1">{getT("Месяц")} / {getT("Год")}</label>
                                        <input type="month" id="month-year" name="month-year" value={date_field}
                                               className="form-control" onChange={handlerChangeDate}/>
                                    </div>
                                    <div className="form-group col-md-6 col-lg-4 col-xl-3">
                                        <label htmlFor="exampleInputPassword1">{getT("Материал")}</label>
                                        <select name="" id="" className={'form-control'} value={form_material_id}
                                                onChange={handlerChangeFormMaterialId}>
                                            <option value={''}>------</option>
                                            {material_for_select?.map(e => {
                                                return <option value={e?.goods_id}>{e?.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6 col-lg-4 col-xl-4">
                                        <div className="custom-control custom-radio ">
                                            <input type="radio" id="customRadioInline1" name="customRadioInline"
                                                   className="custom-control-input" value={type_material}
                                                   checked={!type_material} onChange={handlerChangeTypeMaterial(0)}/>
                                            <label className="custom-control-label"
                                                   htmlFor="customRadioInline1">{getT("Материал из БД")}</label>
                                        </div>
                                        <div className="custom-control custom-radio ">
                                            <input type="radio" id="customRadioInline2" name="customRadioInline"
                                                   className="custom-control-input" value={type_material}
                                                   checked={!!type_material} onChange={handlerChangeTypeMaterial(1)}/>
                                            <label className="custom-control-label"
                                                   htmlFor="customRadioInline2">{getT("Материал клиента")}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-md-6 mb-4">
                            <ul className="list-group statistics-list">
                                <StatisticsFull type_material={type_material} getT={getT}
                                                data={statistics_material_store?.all}
                                                handlerOpenModalSeeMoreStaticsByKey={handlerOpenModalSeeMoreStaticsByKey}/>
                            </ul>
                        </div>
                        {!!Object.keys(findMaterialById ?? {})?.length && <div className="col-12 col-md-6">
                            <ul className="list-group statistics-list">
                                <StatisticsFull type_material={type_material} getT={getT}
                                                data={findMaterialById?.data}/>
                            </ul>
                        </div>}
                    </div>
                </div>
                <StatisticsXncOperationsMaterialByKeyModal/>
            </ContainerContent>
        </>
    );
};

export default StatisticXncOperationsMaterialsPage;