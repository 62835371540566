import {instances, URI_API_EXTTUNELL} from "./setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const GET_TARIFF = 'get_tariff';
const ADD_TARIFF = 'add_tariff';

/**
 * Тарифы на оплату услуг (Rates)
 * Тариф ставится на услугу. Тариф устанавливается отдельно для недовыполнения, нормальных плановых показателей,
 * перевыполнения плана. В зависимости от оценки смены, система будет применять нужный тариф
 * @method get  - получить все тарифы по всем услугам
 * @method add  - type form_data создать тариф на тип услуги
 */
export const RatesAPI = {
    get() {
        return instances.post(URI_API_EXTTUNELL, null, {
            headers: {
                action: GET_TARIFF
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    add(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: ADD_TARIFF
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }
}