const status_color={
    "status_1": "badge bg-primary",
    "status_2": "badge bg-success",
    "status_3": "badge bg-info",
    "status_4": "badge bg-yellow",
    "status_5": "badge bg-success",
    "status_6": "badge bg-yellow",
    "status_7": "badge bg-success",
    "status_8": "badge bg-danger",
    "status_9": "badge bg-danger",
}

export const statusOrdersHelpers = {
    getClassNameByStatusId: (status_id) => {
        let key = `status_${status_id}`
        let badgeCN = status_color?.hasOwnProperty(key) ? status_color[key] :  "badge bg-info";
        return badgeCN
    }
}