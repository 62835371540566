import React, {useEffect, useState} from 'react';
import './Pagination.css';


const Pagination = ({pageSize = 10, count, changeHandler, page = 1}) => {

    const [active, setActive] = useState(page);
    useEffect(() => {
        if (page !== active) {
            setActive(page)
        }
    }, [page])
    // const pageSize = props?.pageSize ??10;
    const setActivePage = (page) => {
        setActive(page);
        changeHandler(page);
    };

    const renderLinks = () => {
        let links = [];
        let countPage = Math.floor(count / pageSize);
        if (countPage <= 0) return null
        if (count % pageSize !== 0) {
            countPage++;
        }
        for (let i = 1; i <= Number(countPage); i++) {
            if (
                i === 1 ||
                i === countPage ||
                (i > (active - 3) && i < (active + 3))

            ) {
                if (i === countPage) links.push(<div className={'page_link_separator'}></div>);
                links.push(<a className={'page-link ' + (Number(active) === Number(i) ? 'active' : '')}
                              onClick={() => setActivePage(i)}> {i} </a>);
                if (i === 1) links.push(<div className={'page_link_separator'}></div>);
            }

        }
        return links;
    }

    return <>
        <div className="pagination_container_main">
            {renderLinks()}
        </div>
    </>
}

export default Pagination;