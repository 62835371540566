import React, {useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getAdditionalDocumentReadyMarkByShiftOpen} from "../../../store/selectors/salary_project_selector";
import ItemDocument from "./AdditionalDocument/ItemDocument";
import Button from "../../../components/ui/Button";
import SalaryAdditionalDocumentCreate from "../../../components/Modals/SalaryProject/SalaryAdditionalDocumentCreate";
import SalaryAdditionalDocumentUpdate from "../../../components/Modals/SalaryProject/SalaryAdditionalDocumentUpdate";
import SalaryCreateBonusesFinesToWorkerByDocumentModal
    from "../../../components/Modals/SalaryProject/SalaryCreateBonusesFinesToWorkerByDocumentModal";
import {
    setAdditionDocumentAdditionalDocumentBonusesFinesCreateModalAC,
    setAdditionDocumentAdditionalDocumentBonusFineUpdateModalAC
} from "../../../store/actions/SalaryProject";
import {SALARY_PROJECT_BONUSES_FINES_ACTION} from "../../../utils/constants";
import {
    deleteAdditionalDocumentBonusThunk,
    deleteAdditionalDocumentFinesThunk
} from "../../../store/thunks/salary_project/addition_document_thunk";
import SalaryUpdateBonusesFinesToWorkerByDocumentModal
    from "../../../components/Modals/SalaryProject/SalaryUpdateBonusesFinesToWorkerByDocumentModal";

const AdditionalDocument = ({getT, shift_id}) => {
    const dispatch = useDispatch();
    let refModalCreate = useRef();
    let refModalUpdate = useRef();


    let additional_doc_state = useSelector(getAdditionalDocumentReadyMarkByShiftOpen);

    const handlerOpenCreateAdditionalDocumentModal = () => {
        refModalCreate.current.open(shift_id);
    }
    const handlerOpenUpdateAdditionalDocumentModal = (initial_document) => () => {
        refModalUpdate.current.open(initial_document);
    }
    const handlerCreateBonus = (additional_document) => () => {
        dispatch(setAdditionDocumentAdditionalDocumentBonusesFinesCreateModalAC({
            additional_document: additional_document,
            shift_id: shift_id,
            type_action: SALARY_PROJECT_BONUSES_FINES_ACTION.bonuses,
            isOpen: true
        }))
    }
    const handlerCreateFines = (additional_document) => () => {
        dispatch(setAdditionDocumentAdditionalDocumentBonusesFinesCreateModalAC({
            additional_document: additional_document,
            shift_id: shift_id,
            type_action: SALARY_PROJECT_BONUSES_FINES_ACTION.fines,
            isOpen: true
        }))
    }
    const handlerDeleteFine = (salary_additional_fine_id) => () => {
        dispatch(deleteAdditionalDocumentFinesThunk({
            salary_additional_fine_id: salary_additional_fine_id
        }, shift_id))
    }
    const handlerDeleteBonus = (salary_additional_bonus_id) => () => {
        dispatch(deleteAdditionalDocumentBonusThunk({
            salary_additional_bonus_id: salary_additional_bonus_id
        }, shift_id))
    }
    const handlerUpdateFine = (item) => () => {
        dispatch(setAdditionDocumentAdditionalDocumentBonusFineUpdateModalAC({
            isOpen: true,
            data: item, shift_id: shift_id, type_action: SALARY_PROJECT_BONUSES_FINES_ACTION.fines
        }))
    }
    const handlerUpdateBonus = (item) => () => {
        dispatch(setAdditionDocumentAdditionalDocumentBonusFineUpdateModalAC({
            isOpen: true,
            data: item, shift_id: shift_id, type_action: SALARY_PROJECT_BONUSES_FINES_ACTION.bonuses
        }))
    }

    return (
        <>
            <div className={'card'}>
                <div className="card-header">
                    <div className="d-flex justify-content-between align-items-center">
                        <h5 className="card-title font-weight-bold">{getT("Документы")}</h5>
                        <Button className={"btn btn-success"}
                                onClick={handlerOpenCreateAdditionalDocumentModal}>{getT("Создать")}</Button>
                    </div>
                </div>
                <div className="card-body">
                    <ul className={"list-group-shift-plane"}>
                        {additional_doc_state?.documents?.map((document) => {
                            return <ItemDocument document={document} getT={getT}
                                                 key={`item_document_${document?.salary_additional_document_id}`}
                                                 handlerCreateBonus={handlerCreateBonus(document?.salary_additional_document_id)}
                                                 handlerCreateFines={handlerCreateFines(document?.salary_additional_document_id)}
                                                 handlerDeleteFine={handlerDeleteFine}
                                                 handlerDeleteBonus={handlerDeleteBonus}
                                                 handlerUpdateAdditionalDocument={handlerOpenUpdateAdditionalDocumentModal(document)}
                                                 handlerUpdateBonus={handlerUpdateBonus}
                                                 handlerUpdateFine={handlerUpdateFine}
                            />
                        })}
                    </ul>
                </div>
            </div>
            <SalaryUpdateBonusesFinesToWorkerByDocumentModal read_mark={additional_doc_state.ready_mark} getT={getT}/>
            <SalaryCreateBonusesFinesToWorkerByDocumentModal read_mark={additional_doc_state.ready_mark} getT={getT}/>
            <SalaryAdditionalDocumentCreate getT={getT} key={'additional_create_modal'} refModal={refModalCreate}/>
            <SalaryAdditionalDocumentUpdate getT={getT} key={'additional_update_modal'} refModal={refModalUpdate}/>
        </>
    );
};

export default AdditionalDocument;