import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {
    wholesalePartnerPropertiesAddModalAC,
    wholesalePartnerPropertiesSetDataAC
} from "../../actions/WholesaleActions";
import {dispatcherErrorThunk} from "../common_thunk";
import {WholesalePartnerPropertiesAPI} from "../../../api/Wholesale/WholesalePartnerPropertiesAPI";
import {createFormData} from "../../../utils/helpers";

export const dispatchedPartnerProperties =  () => async (dispatch) => {

    const response = await WholesalePartnerPropertiesAPI.getPartnerProperties();
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    dispatch(wholesalePartnerPropertiesSetDataAC(response.data))
}

export const getPartnerPropertiesThunk = () => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await dispatch(dispatchedPartnerProperties());
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        console.log('e', e?.message)
        dispatch(dispatcherErrorThunk(e))
    }
}

export const setPartnerPropertiesThunk = (api_data) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const form_data = createFormData(api_data);
        const response = await WholesalePartnerPropertiesAPI.setPartnerProperties(form_data);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        await dispatch(dispatchedPartnerProperties());
        dispatch(wholesalePartnerPropertiesAddModalAC({isOpen: false, property: null, firm_main: null}))

        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        console.log('e', e?.message)
        dispatch(dispatcherErrorThunk(e))
    }
}