import getT from "../getT";
import {LS_LANG} from "../../constants";

export default function ({material, index, material_list}){
    // const total_counts = material_list?.reduce((acc, item)=>Number(item?.count)+ Number(acc), 0);
    let lang = localStorage.getItem('lang');
    let name = material?.name;
    return `
    <table>
     <caption>${getT("Материал")}: [${material?.id}] ${name}</caption>
  <thead>
    <tr>
      <th>#</th>
      <th>${getT("Длина")}</th>
      <th>${getT("Ширина")}</th>
    </tr>
  </thead>
  <tbody>
  ${material_list?.map((item, indx)=>{
      return `
       <tr>
      <td>${indx+1}</td>
      <td>${item?.x ?? '-'}</td>
      <td>${item?.y ?? '-'}</td>
    </tr>
      `
    })?.join('')}
 
  </tbody>
</table>
    `
}