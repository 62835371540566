import React from 'react';
import {withLang} from "../../hoc/withLang";
import {useDispatch, useSelector} from "react-redux";
import {getNewsThunk} from "../../store/reducers/MainReducer";
import NewItem from "./NewItem";
import "./main.css";
import Pagination from "../Pagination/Pagination";


const NewsContainer = ({getT}) => {
    const url_img = process.env.REACT_APP_API_URL_IMG;
    const news_state = useSelector(state => state.main.news);
    const dispatch = useDispatch();
    const handlerChangePage = (page) => {

        dispatch(getNewsThunk(page));
    };
    return (
        <div className="wrapper">
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-12">
                                <h1>{getT("Новости")}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content section-news">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className={"section-news"}>
                                    <h2 className="mb-2"></h2>
                                    <div className="news-wrap">
                                        {news_state.news?.sort((a, b)=> new Date(b.updated_at) - new Date(a.updated_at))?.map(new_item => <NewItem item={new_item} url_img={url_img}
                                                                                  getT={getT}/>)}
                                    </div>
                                    <Pagination count={news_state.pages} changeHandler={handlerChangePage}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default withLang(NewsContainer);