import React from 'react';
import {useForm} from "react-hook-form";
import Button from "../../../components/ui/Button";
import {readyMarToggleAllAC, setShiftFilterAC} from "../../../store/actions/SalaryProject";
import {getShiftThunk} from "../../../store/thunks/salary_project/shifh-thunk";
import {useDispatch} from "react-redux";
import {READY_MARK_INITIAL_VALUES} from "../../../store/reducers/SalaryProjectReducer";
import Pagination from "../../../components/Pagination/Pagination";
import {LS_WORKER_ID} from "../../../utils/constants";
import {Link} from "react-router-dom";

// выбрать смену и бригаду
const ChoiceShift = ({shifts_data, shift_form_values,  handlerSubmitShift, getT}) => {
    //::TODO добавить логику проверка на работника или пользователя для выбора бригады
    const {handleSubmit, control, register, watch, setValue} = useForm({});
    const dispatch = useDispatch();
    let worker_system_id = Number(localStorage.getItem(LS_WORKER_ID))

    const handlerResetShift = () => dispatch(readyMarToggleAllAC({
        ...READY_MARK_INITIAL_VALUES,
    }))
    const isCreated = !shift_form_values?.shift && !shift_form_values.worker_group;
    const isHasShift = shifts_data?.shifts?.length && shifts_data?.shifts?.some(item => typeof item !== 'string')
    const handlerChangePage = (page) => {
        dispatch(setShiftFilterAC({offset: page - 1}));
        getShiftThunk()
    }
    const watchShift = watch('shift');
    const watchWorkerGroup = watch('worker_group');
    return (
        isCreated ?
            <> {isHasShift ?
                <div className="card">
                    <div className="card-header">
                        <div className="card-title">{getT("Выберете смену и бригаду")}</div>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(data => {

                            handlerSubmitShift(data)
                        })}>
                            <input type="hidden" {...register('shift')} required={true}/>
                            <input type="hidden" {...register('worker_group')} required={true}/>
                            <ul className={'list-none list-group-choice-shift'}>
                                {shifts_data?.shifts?.map(shift => {
                                    let worker_group = shift?.worker_group?.filter(item=> {
                                        if(!!worker_system_id){
                                            return  item?.workers?.some(worker=> Number(worker.worker) === Number(worker_system_id) && (Number(worker?.is_working) === 1))
                                        }
                                        return item?.workers?.every(worker=> (Number(worker?.is_working) === 1))
                                    })
                                    return <li className={"list-group-item d-flex align-items-center flex-wrap"}>
                                        <strong>{getT("Смена")} #{shift?.salary_shift_id}</strong>
                                        {watchShift?.salary_shift_id === shift?.salary_shift_id ?
                                            <div className="w-100">

                                                {!!worker_group?.length ?
                                                    <> <div className="text-warning">{getT("Бригады")}</div>
                                                    {worker_group?.map(worker => {
                                                    return <div className="form-check">
                                                        <input className="form-check-input" type="radio"
                                                               name="worker"
                                                               id={`worker_${worker.salary_shift_worker_group_id}`}
                                                               onChange={(e) => {
                                                                   console.log('change', e.target.checked);
                                                                   setValue("worker_group", worker.salary_shift_worker_group_id)}
                                                               }
                                                               checked={watchWorkerGroup === worker.salary_shift_worker_group_id}/>
                                                        <label className="form-check-label fs-14" htmlFor={`worker_${worker.salary_shift_worker_group_id}`}>
                                                            {worker?.name}
                                                        </label>
                                                    </div>
                                                })}
                                                        </>:
                                                    <><Link to={`/salary-project/shift/${shift?.salary_shift_id }`}><span>{getT("Создать бригаду")}</span></Link></>
                                                }
                                            </div>
                                            :
                                            <Button type={'button'} className={'btn-outline-success py-1 ml-3 ml-auto'}
                                                    onClick={() => {
                                                        setValue("shift", shift)
                                                        setValue("worker_group", '')
                                                    }
                                                    }>+</Button>
                                        }
                                    </li>
                                })}
                            </ul>
                            <Pagination pageSize={30} count={shifts_data?.count_all} changeHandler={handlerChangePage}/>
                            <div className="text-center">
                                <Button type={"submit"}>{getT('Создать')}</Button>
                            </div>
                        </form>
                    </div>
                </div>
                :
                <div className={"p-3 mb-2 bg-white text-dark text-center"}>
                    <p>    {getT("У вас нет ни одной смены")}</p>
                    <Link to={'/salary-project/shift'} className={"btn btn-outline-primary "}>{getT('Создать смену')}</Link>
                </div>
            }

            </>
            :
            <div className="alert alert-default-info mb-0" role="alert">
                <div className="d-flex justify-content-between ">
                    <p className={"m-0"}>
                        <strong>{getT("Смена")}</strong> #{shift_form_values?.shift?.salary_shift_id}
                        <br/>
                        <strong> {getT("Бригада")}</strong> : {shift_form_values?.shift?.worker_group?.find(worker => Number(shift_form_values?.worker_group) === Number(worker?.salary_shift_worker_group_id))?.name}

                    </p>
                    <div className="text-right">
                        <Button onClick={handlerResetShift}
                                className={"btn-warning"}>{getT("Изменить")}</Button>
                    </div>

                </div>


            </div>
    );
};

export default ChoiceShift;