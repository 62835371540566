import React from 'react';
import Modal from "../MyModal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getShiftPlaneCreateModal} from "../../../store/selectors/salary_project_selector";
import {setShiftPlaneCreateModalAC} from "../../../store/actions/SalaryProject";
import {INITIAL_SHIFT_PLANE} from "../../../store/reducers/SalaryProjectReducer";
import ShiftPlaneFormCU from "./components/ShiftPlane/ShiftPlaneFormCU";
import {shiftPlaneCreateThunk} from "../../../store/thunks/salary_project/shifh-thunk";

const SalaryShiftPlaneCreateModal = ({getT}) => {
    const modal_state = useSelector(getShiftPlaneCreateModal);
    const dispatch = useDispatch();
    const handlerClose = () => {
        dispatch(setShiftPlaneCreateModalAC({isOpen: false, form_values: INITIAL_SHIFT_PLANE}))
    }
    const onSubmitCreate = (data)=>{
        let {salary_shift_plan_id, ...reset} = data
        dispatch(shiftPlaneCreateThunk(reset))
    }
    return (
        <Modal title={getT("Создать")} open={modal_state.isOpen} onClose={handlerClose} size_modal_class={"modal-md"}>
            <ShiftPlaneFormCU form_values={modal_state.form_values} getT={getT} onSubmitCallback={onSubmitCreate}/>
        </Modal>
    );
};

export default SalaryShiftPlaneCreateModal;