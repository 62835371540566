import React, {useState, useEffect} from "react";
import "../Orders/ResponsiveTable.css";
import PageTitle from "../PageTitle/PageTitle.jsx";

import Pagination from "./Pagination/Pagination";
import Test from "./Pagination/Test";
import {withLang} from "../../hoc/withLang";
import {useSelector} from "react-redux";

const Clients = (props) => {
    const [searchClient, setSearchClient] = useState("");
    const [findClient, setFindClient] = useState([props.clients]);
    const [currentPage, setCurrentPage] = useState(1);
    const [clientsPerPage] = useState(10);
    useEffect(() => {
        filterdata();
    }, [searchClient]);

    const filterdata = () => {
        if (props.clients) {
            const filtered = props.clients?.filter((client) => {
                const phone = client?.phone?.replace(/[^\d]+/g, "");
                if (searchClient.length > 1) {
                    return (
                        client.name.toLowerCase()?.includes(searchClient.toLowerCase()) ||
                        client.phone?.replace(/[^\d]+/g, "")?.includes(searchClient)
                    );
                }
            });

            setFindClient(filtered);
        }
    };

    const indexOfLastClient = currentPage * clientsPerPage;
    const indexOfFirstClient = indexOfLastClient - clientsPerPage;
    const currentClient = findClient.slice(indexOfFirstClient, indexOfLastClient);
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // const renderClients = () => {
    //   return currentClient.map((e) => {
    //     return (
    //       <tr key={uuid()}>
    //         <td data-label="ID">{e.id}</td>
    //         <td data-label="ИМЯ">{e.name}</td>
    //         <td data-label="ТЕЛЕФОН">{e.phone}</td>
    //         <td data-label="EMAIL">{e.email}</td>
    //         <td data-label="СТАТУС">
    //           {Number(e.active) === 1 ? (
    //             <span className="badge bg-success">активный</span>
    //           ) : (
    //             <span className="badge bg-danger">не активный</span>
    //           )}
    //         </td>
    //         <td data-label="ДЕЙСТВИЕ">
    //           <div className={"d-flex align-center clients_stroke_styles"}>
    //             <Link to={"/clients/detail/" + e.id}>
    //               <i className="far fa-eye"> </i>
    //             </Link>
    //             <i
    //               className="fas fa-key"
    //               onClick={() => props.updateClient(e.id, { pass: 1 })}
    //               title={"Сменить пароль"}
    //             ></i>
    //           </div>
    //         </td>
    //       </tr>
    //     );
    //   });
    // };

    return (
        <div className="content-wrapper">
            <PageTitle title={"clients"}/>
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>{props.getT("Поиск Клиента")}: </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={props.getT("Введите имя или телефон")}
                                        value={searchClient}
                                        onChange={(e) => setSearchClient(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table m-0 table-striped table-hover">
                                    <thead>
                                    <tr>
                                        <th scope="col">{props.getT("ID")}</th>
                                        <th scope="col">{props.getT("Имя")}</th>
                                        <th scope="col">{props.getT("Телефон")}</th>
                                        <th scope="col">{props.getT("Email")}</th>
                                        <th scope="col">{props.getT("Статус")}</th>
                                        <th scope="col">{props.getT("Действия")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <Test
                                        clients={currentClient}
                                        UdClient={props.updateClient}
                                    />
                                    {/*<tr>*/}
                                    {/*<td colSpan={6} className={'text-center'}>*/}
                                    {/*Отображены ближайшие 15 записей. Если Вы не нашли необходимого клиента - воспользуйтесь формой фильтру. Фильтры находятся вверху страницы*/}
                                    {/*</td>*/}
                                    {/*</tr>*/}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Pagination
                            clientsPerPage={clientsPerPage}
                            totalClients={findClient.length}
                            paginate={paginate}
                        />
                        {/*<div className="card-footer clearfix">*/}
                        {/*<ul className="pagination pagination-sm m-0 float-right">*/}
                        {/*<li className="page-item"><a className="page-link" href="#">&laquo;</a></li>*/}
                        {/*<li className="page-item"><a className="page-link" href="#">1</a></li>*/}
                        {/*<li className="page-item"><a className="page-link" href="#">2</a></li>*/}
                        {/*<li className="page-item"><a className="page-link" href="#">3</a></li>*/}
                        {/*<li className="page-item"><a className="page-link" href="#">&raquo;</a></li>*/}
                        {/*</ul>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default withLang(Clients);
