import React, {useState} from "react";
import {useDispatch} from "react-redux";
import C_Company from "../../image/1C_Company.svg";
import {uploadOne_C_CodeThunc} from "../../store/reducers/MainReducer";

export const CodeOneC = ({order, orders_check_enable, type=false, getT}) => {
    const status = type ? [1,2,3,4,5,6]: [2, 3, 4, 5, 6]
    const [code, setCode] = useState(order.code1c);
    const dispatch = useDispatch();

    // localStorage.getItem("i_furn_user_firm")
    return (
        <>
            {(code) ? (<div> [{code}] </div>) :
                (((status.includes(Number(order.status_code ?? order?.status))) && Number(orders_check_enable) === 1) && <>
                        <img src={C_Company} alt="" width={30}
                             onClick={(e) => {
                                 dispatch(uploadOne_C_CodeThunc({firm: order.firm, order: order.id}, (code) => {
                                     setCode(code);
                                 }))
                             }}
                        />
                    </>
                )
            }
        </>
    )
}
