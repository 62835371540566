import React, {useEffect} from "react";
import PageTitle from "../PageTitle/PageTitle.jsx";
import { OrderItem } from "../Orders/Orders";
import { v4 as uuid } from "uuid";
import {withLang} from "../../hoc/withLang";
import {useSelector} from "react-redux";

const ClientsDetail = (props) => {
  // const parseData = {
  //   w_b: "Размер детали до кромкования после прифуговки",
  //   saw: "Размер детали после порезки",
  //   part: "Размер детали с кромкой"
  // };

  return (
    <div className="content-wrapper">
      <PageTitle title={"clients_detail"} />
      <section className="content">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">{props.getT("Основная информация")}</h3>
            <div className="card-tools">
              <button
                type="button"
                className="btn btn-tool"
                data-card-widget="collapse"
                title="Collapse"
                onClick={() =>
                  !props.isEdit
                    ? props.toggleEdit(!props.isEdit)
                    : props.editClient()
                }
              >
                <i className="fas fa-edit"> </i>
              </button>
              <button
                type="button"
                className="btn btn-tool"
                data-card-widget="collapse"
                title="Collapse"
              >
                <i className="fas fa-minus"> </i>
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 order-2 order-md-1">
                <div className="row">
                  <div className="col-12 col-sm-3">
                    <div className="info-box bg-light">
                      <div className="info-box-content">
                        <span className="info-box-text text-center text-muted text-wrap ">
                          {props.getT("Режим ввода деталей по умолчанию")}
                        </span>
                        {props.isEdit ? (
                          <div className="form-group">
                            <select
                              style={{ maxWidth: "100%" }}
                              value={props.client.type_input}
                              defaultValue={props.client.type_input}
                              onChange={(event) =>
                                props.changeValue(
                                  "type_input",
                                  event.target.value
                                )
                              }
                            >
                              <option value="part">
                                {props.getT("Размер детали с кромкой")}
                              </option>
                              <option value="saw">
                                {props.getT("Размер детали после порезки")}
                              </option>
                              <option value="w_b">
                                {props.getT("Размер детали до кромкования после прифуговки")}
                              </option>
                            </select>
                          </div>
                        ) : (
                          <>
                            {props.client.type_input === null ||
                            props.client.type_input === "part"
                              ? props.client.type_input === "part" && (
                                  <span className="info-box-number text-center text-muted mb-0">
                                    {props.getT("Размер детали с кромкой")}
                                  </span>
                                )
                              : props.client.type_input && (
                                  <span className="info-box-number text-center text-muted mb-0">
                                    {props.client.type_input === "saw" ? (
                                      <span className="info-box-number text-center text-muted mb-0">
                                        {props.getT("Размер детали после порезки")}
                                      </span>
                                    ) : (
                                      <span className="info-box-number text-center text-muted mb-0">
                                        {props.getT("Размер детали до кромкования после прифуговки")}
                                      </span>
                                    )}
                                  </span>
                                )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-3">
                    <div className="info-box bg-light">
                      <div className="info-box-content">
                        <span className="info-box-text text-center text-muted">
                          {props.getT("Email")}
                        </span>
                        {props.isEdit ? (
                          <div className="form-group">
                            <input
                              className="form-control"
                              type={"email"}
                              value={props.client.email}
                              onChange={(event) =>
                                props.changeValue("email", event.target.value)
                              }
                            />
                          </div>
                        ) : (
                          <span className="info-box-number text-center text-muted mb-0">
                            {props.client.email}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-3">
                    <div className="info-box bg-light">
                      <div className="info-box-content">
                        <span className="info-box-text text-center text-muted">
                          {props.getT("Телефон")}
                        </span>

                        {props.isEdit ? (
                          <div className="form-group">
                            <input
                              className="form-control"
                              type={"tel"}
                              value={props.client.phone}
                              onChange={(event) =>
                                props.changeValue("phone", event.target.value)
                              }
                            />
                          </div>
                        ) : (
                          <span className="info-box-number text-center text-muted mb-0">
                            {props.client.phone}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-3">
                    <div className="info-box bg-light">
                      <div className="info-box-content">
                        <span className="info-box-text text-center text-muted">
                          {props.getT("Дата последнего заказа")}
                        </span>
                        <span className="info-box-number text-center text-muted  mb-0">
                          {props.orders && props.orders.length > 0
                            ? props.orders[0].last_update
                            : props.getT("заказов еще не создано")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-6 col-lg-6 order-1 order-md-2">
                <h3 className="text-primary">
                  {props.isEdit ? (
                    <div className="form-group d-flex align-items-center">
                      <i className="far fa-user"> </i>
                      <input
                        className="form-control ml-1"
                        type={"tel"}
                        value={props.client.name}
                        onChange={(event) =>
                          props.changeValue("name", event.target.value)
                        }
                      />
                    </div>
                  ) : (
                    <span>
                      <i className="far fa-user"> </i> {props.client.name}
                    </span>
                  )}
                </h3>
                <p className="text-muted"></p>
                <br />
                <div className="text-muted">
                  <p className="text-sm">
                    {props.getT("ID в системе")}:
                    <b className="d-block">{props.client.id}</b>
                  </p>
                  <p className="text-sm">
                    {props.getT("Адрес")}:
                    {props.isEdit ? (
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          value={props.client.adress}
                          onChange={(event) =>
                            props.changeValue("adress", event.target.value)
                          }
                        >
                          {props.client.adress}
                        </textarea>
                      </div>
                    ) : (
                      <b className="d-block">
                        {props.client.adress
                          ? props.client.adress
                          : props.getT("Не указано")}
                      </b>
                    )}
                  </p>
                  {/*<p className="text-sm">Клиент приналежит к группе:*/}
                  {/*    {props.isEdit ?*/}
                  {/*        <div className="form-group">*/}
                  {/*            <select className="custom-select" value={props.client.parent} defaultValue={props.client.parent} onChange={(event) => props.changeValue('parent', event.target.value)}>*/}
                  {/*                <option value={''}>не пренадлежит</option>*/}
                  {/*                <option value={1}>Value 1</option>*/}
                  {/*                <option value={2}>Value 2</option>*/}
                  {/*                <option value={3}>Value 3</option>*/}
                  {/*            </select>*/}
                  {/*        </div>*/}
                  {/*        :*/}
                  {/*        <b className="d-block">{props.client.parent ? props.client.parent : 'не пренадлежит'}</b>*/}
                  {/*    }*/}
                  {/*</p>*/}
                  <p className="text-sm">
                    {" "}
                    {props.getT("Активность")}:
                    {props.isEdit ? (
                      <div className="form-group">
                        <select
                          className="custom-select"
                          value={props.client.active}
                          defaultValue={props.client.active}
                          onChange={(event) =>
                            props.changeValue("active", event.target.value)
                          }
                        >
                          <option value={1}>{props.getT("Активный")}</option>
                          <option value={0}>{props.getT("Заблокирован")}</option>
                        </select>
                      </div>
                    ) : props.client.active ? (
                      <i className="fas fa-circle text-success"></i>
                    ) : (
                      <i className="fas fa-circle text-danger"></i>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">{props.getT("Заказы")}</h3>
            <div className="card-tools">
              <button
                type="button"
                className="btn btn-tool"
                data-card-widget="collapse"
                title="Collapse"
              >
                <i className="fas fa-minus"> </i>
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                <div className="table-responsive">
                  <table className="table m-0 table-hover">
                    <thead>
                      <tr>
                        {/*<th>{props.getT("Код")}</th>*/}
                        <th>{props.getT("Тех.ID")}</th>
                        <th>{props.getT("Название")}</th>
                        <th>{props.getT("Менеджер")}</th>
                        <th>{props.getT("Дата")}</th>
                        <th>{props.getT("Дата производства")}</th>
                        <th>{props.getT("Действия")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.orders &&
                        props.orders.map((e) => (
                          <OrderItem
                            key={uuid()}
                            order={e}
                            toggleChangeStatus={() => {}}
                            status={[]}
                            changedStatus={null}
                            withStatus={false}
                            withClient={false}
                            updateOrder={props.updateOrder}
                            getT={props.getT}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withLang(ClientsDetail);
