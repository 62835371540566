import React, {useEffect, useMemo, useState} from 'react';
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    getWsProductGroupsFirmByIdThunk,
    includeExcludeFromTheListOfResaleableGoods
} from "../../store/thunks/wholesale/product_groups_thunk";
import {
    getWSGoodsRetailPrice,
    getWSGoodsStockRetailOfFirm,
    getWSOperationsWithProductGroupsFirm
} from "../../store/selectors/wholesalary_selector";
import TreeMaterialTypeList from "./components/TreeMaterialTypeList";
import {WHOLESALE_MATERIAL_TYPE_STATUS} from "../../constants/whole_sale_constants";
import ContainerContent from "../../components/ui/ContainerContent";
import OperationsTab from "./components/OperationsTab";
import {createActualData, createDataForSendingChangedStatusesOfMaterialsAndGoods} from "../../utils/wholesale_utils";
import {LS_FIRM} from "../../utils/constants";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
    wholesaleGoodsPartnerSetupModalToggleAC,
    wholesaleGoodsRetailPriceReadModalAC, wholesaleGoodsStockRetailPriceReadModalAC
} from "../../store/actions/WholesaleActions";
import {getWSGoodsPartnerSetupThunk} from "../../store/thunks/wholesale/goods_partner_setup_thunk";
import WSGoodsPartnerSetupModal from "./components/Modal/WSGoodsPartnerSetupModal";
import WsGoodsRetailPriceReadModal from "../../components/WholeSale/Modal/WSGoodsRetailPriceReadModal";
import WsGoodsStockRetailPriceReadModal
    from "../../components/WholeSale/Modal/WSGoodsStockRetailPriceReadModal";
import {getCombineRetailPricePartnerSource} from "../../store/thunks/wholesale/goods_stock_retail_of_firm_thunk";
import {toggleLoaderStatusAC} from "../../store/reducers/MainReducer";


const WSOperationProductGroupFirmPage = ({getT, match}) => {
        const dispatch = useDispatch();
        const location = useLocation();
        let firm_id = match?.params?.main_firm;
        let current_firm = Number(localStorage.getItem(LS_FIRM));
        const searchParams = new URLSearchParams(location.search);
        //товары доступны для записи или просмотра
        const is_write_products_list = !!Number(searchParams.get('active'));

        const operation_product_group = useSelector(getWSOperationsWithProductGroupsFirm);

        const [operation_group_tab, setOperationGroupProducts] = useState(WHOLESALE_MATERIAL_TYPE_STATUS.included)
        const type_operation = is_write_products_list ? operation_group_tab : null

        const goods_retail_price = useSelector(getWSGoodsRetailPrice);
        const goods_stock_retail_price = useSelector(getWSGoodsStockRetailOfFirm);

        useEffect(async () => {
            if (!firm_id) return;
            dispatch(toggleLoaderStatusAC(true));
            dispatch(getWsProductGroupsFirmByIdThunk(firm_id));
            dispatch(getWSGoodsPartnerSetupThunk(firm_id));
            await dispatch(getCombineRetailPricePartnerSource(null, firm_id))
            dispatch(toggleLoaderStatusAC(false));
            // dispatch(getCombineRetailPricePartnerSource(null, firm_id))
            // dispatch(getWSGoodsRetailPriceByParamsThunk({firm_main: firm_id, firm_partner: current_firm}));

        }, [firm_id, dispatch]);
        const handlerChangeTypeOperationTab = (type) => e => {
            setOperationGroupProducts(type)
        }

        const updateStatusForMaterial = (item_root) => ({current_material, goods = null, value_checked}) => {
            let data = createActualData({
                value_checked: value_checked,
                type_operation: type_operation,
                current_material: {...current_material},
                goods: goods,
                item_root: {...item_root}
            });
            let exceptions_connect_list = operation_product_group?.exceptions?.exceptions
                ?.filter(e => (Number(e?.firm_partner) === Number(current_firm) && Number(e?.firm_main) === Number(firm_id)))
            let data_for_api = createDataForSendingChangedStatusesOfMaterialsAndGoods({
                firm_main: firm_id,
                root_tree: data, type_operation: type_operation, exceptions_connect_list: exceptions_connect_list
            })
            dispatch(includeExcludeFromTheListOfResaleableGoods({
                firm_id,
                remove_connect: data_for_api?.remove_connect,
                new_connect: data_for_api?.new_connect
            }))

        };

        const handlerOpenGoodsSetupModal = (goods) => (e) => {
            dispatch(wholesaleGoodsPartnerSetupModalToggleAC({isOpen: true, goods: goods, firm_main: firm_id}))
        }
        const handlerOpenGoodsStockRetailPriceModal = (goods) => e => {
            dispatch(wholesaleGoodsStockRetailPriceReadModalAC({isOpen: true, goods: goods}));
        }
        const goods_retail_price_direction = useMemo(() => {
            if (!goods_retail_price || !Array.isArray(goods_retail_price) || !goods_retail_price?.length) return null
            return goods_retail_price?.reduce((acc, retail_price) => {
                acc[retail_price?.goods] = retail_price;
                return acc
            }, {})

        }, [goods_retail_price]);
        const goods_stock_retail_price_direction = useMemo(() => {
            if (!goods_stock_retail_price || !Array.isArray(goods_stock_retail_price) || !goods_stock_retail_price?.length) return null
            return goods_stock_retail_price?.reduce((acc, retail_price) => {
                acc[retail_price?.goods] = retail_price;
                return acc
            }, {})

        }, [goods_stock_retail_price]);

        const handlerOpenGoodsRetailPriceModal = (data) => e => {
            dispatch(wholesaleGoodsRetailPriceReadModalAC({...data, isOpen: true}))
        }
        return (
            <ContainerContent>

                <PageTitle title={"operation_product_group_firm"} addintional_subtitle={`"${location?.state?.firm_name}"`}/>


                <div className="container-fluid card py-3">
                    <div className="row">
                        <div className="col-12">
                            {!!type_operation &&
                                <OperationsTab getT={getT} handlerChangeTypeOperationTab={handlerChangeTypeOperationTab}
                                               current_tab={operation_group_tab}
                                />}
                        </div>
                    </div>
                    <div className={"row"}>
                        {operation_product_group?.material_type_list?.map((item, index) => {
                            return <div className="col-12 col-md-6 col-lg-6 col-xl-4 my-3" key={item?.folder_id}>
                                <div className="card px-2 py-2">
                                    <TreeMaterialTypeList getT={getT} item={item} key={''}
                                                          goods_retail_price_direction={goods_retail_price_direction}
                                                          type_operation={type_operation}
                                                          handlerOpenGoodsRetailPriceModal={handlerOpenGoodsRetailPriceModal}
                                                          updateStatusForMaterial={updateStatusForMaterial({...item})}
                                                          handlerOpenGoodsSetupModal={handlerOpenGoodsSetupModal}
                                                          handlerOpenGoodsStockRetailPriceModal={handlerOpenGoodsStockRetailPriceModal}
                                                          goods_stock_retail_price_direction={goods_stock_retail_price_direction}
                                    />
                                </div>
                            </div>
                        })}
                    </div>
                </div>


                <WSGoodsPartnerSetupModal getT={getT}/>
                <WsGoodsRetailPriceReadModal/>
                <WsGoodsStockRetailPriceReadModal/>
            </ContainerContent>
        );
    }
;

export default WSOperationProductGroupFirmPage;