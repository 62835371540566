import React from "react";
import md5 from 'md5';
import Translate from "../languages/translation"
import ApiService from "../api/api";
import {connect} from "react-redux";
import {actions} from "../store/reducers/TranslationReducer";


export const withLang = (Component) => {
    class LangData extends React.Component {
        constructor(props) {
            super(props);
            this.api = new ApiService();
            this.getTranslate = this.getTranslate.bind(this);
            this.words = [];
        }
        collectAllWord(word) {
            this.words.push(word);
        }
        updateDictionary (){
            if (this.words.length != 0) {
                this.api.sendWordTranslation([...new Set(this.words)]);
            }
        }
        componentDidMount() {
            // this.updateDictionary();
        }
        getTranslate(key, category = null) {
            let keyTrim = key ? key.trim() : '';
            let mdKey = md5(keyTrim);
            let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ua';
            localStorage.setItem('lang', lang);
            // this.collectAllWord(key);
            if (Translate[0][mdKey]) {
                // console.log(Translate[0][mdKey])
                if(Translate[0][mdKey][lang]){
                    return Translate[0][mdKey][lang];
                }else {
                    return key;
                }
            } else {
                if(!this.props.collectionWord.includes(keyTrim)){
                    this.props.setWordCollection(keyTrim);
                }
                // this.updateDictionary();
            }
            return key;
        }
        render() {
            return <Component {...this.props} getT={this.getTranslate}/>
        }
    }

    return connect(mapSP, mapDP)(LangData);
}
const mapSP = (state)=>{
    return{
        collectionWord : state.translation.collectionWord
    }
}
const mapDP = (dispatch) =>{
    return{
        setWordCollection: (word)=> dispatch(actions.addWord(word))
    }
}
