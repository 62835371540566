import getT from "../../getT"

export default function ({xnc, type_name=false}){
  return (
    ` <tr>
        <th></th>
         <th>${getT('количество деталей с выемками')}</th>
                <th>${getT('количество выемок')}</th>
                <th>${getT('площадь выемок')}</th>
        <th></th>
      </tr>
     <tr>
        <th> ${type_name? type_name : ( getT(xnc?.type_name) ?? '')}</th>
        <td>${+Number(xnc?.part_trough_count ?? '').toFixed(2)}</td>
         <td>${+Number(xnc?.trough_count ?? '').toFixed(2)}</td>
        <td>${+Number(xnc?.trough_area_count ?? '').toFixed(2)}</td>
        <td></td>
      </tr>
  `
  );
}