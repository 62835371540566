import React from 'react';
import {withLang} from "../../../hoc/withLang";

const InsufficientAccessRights = ({getT}) => {
    return (
        <div className="jumbotron">
          <div className="h1 text-center">
              <i className="fa fa-lock text-danger" aria-hidden="true"></i>
          </div>
            <h4 className={'text-center'}>{getT("Нет прав или не оплачен тариф")}</h4>
        </div>
    );
};

export default withLang(InsufficientAccessRights)