import {
    STATISTIC_IS_LOADING,
    STATISTIC_SET_XNC_OPERATIONS,
    STATISTIC_SET_XNC_OPERATIONS_MATERIALS,
    STATISTIC_SET_XNC_OPERATIONS_MATERIALS_BANDS_CONNECTION,
    STATISTIC_SET_XNC_OPERATIONS_MATERIALS_BANDS_CONNECTION_CREATE_MODAL,
    STATISTIC_XNC_OPERATIONS_BY_KEY_FILTERED_BY_CLIENT_OR_MANAGER_ROLE,
    STATISTIC_XNC_OPERATIONS_MATERIALS_BY_KEY_FILTERED__BY_MATERIAL
} from "../actions/StatisticXncOperationsAction";


export const INITIAL_STATE_STATISTICS_XNC_OPERATION_MATERIAL_BAND_CONNECTED_CREATE_MODAL = {
    isOpen: false,
    material: null, band: null, params: null
}
const initialState = {
    statistic_xnc_operations: null,
    statistic_xnc_operations_by_key_modal: {
        isOpen: false, opt_key: null, add_opt: null
    },
    statistic_xnc_operations_material: null,
    statistic_xnc_operations_material_by_key_modal: {
        isOpen: false, key: null, type_material: null
    },
    statistic_xnc_operation_material_band_connection: {
        connected: null,
        unconnected: null
    },
    statistic_xnc_operation_material_band_connection_create_modal: {
        ...INITIAL_STATE_STATISTICS_XNC_OPERATION_MATERIAL_BAND_CONNECTED_CREATE_MODAL
    },
    isLoading: true

}

export const StatisticReducer = (state = initialState, action) => {
    switch (action.type) {
        case STATISTIC_IS_LOADING: {
            return {
                ...state, isLoading: action.payload
            }
        }
        case STATISTIC_SET_XNC_OPERATIONS: {
            return {
                ...state, statistic_xnc_operations: action.payload
            }
        }
        case STATISTIC_XNC_OPERATIONS_BY_KEY_FILTERED_BY_CLIENT_OR_MANAGER_ROLE: {
            return {
                ...state, statistic_xnc_operations_by_key_modal: {
                    ...state.statistic_xnc_operations_by_key_modal,
                    ...action.payload
                }
            }
        }
        case STATISTIC_SET_XNC_OPERATIONS_MATERIALS: {
            return {
                ...state, statistic_xnc_operations_material: {
                    ...state.statistic_xnc_operations_material,
                    ...action.payload
                }
            }
        }
        case STATISTIC_XNC_OPERATIONS_MATERIALS_BY_KEY_FILTERED__BY_MATERIAL: {
            return {
                ...state, statistic_xnc_operations_material_by_key_modal: {
                    ...state.statistic_xnc_operations_material_by_key_modal,
                    ...action.payload
                }
            }
        }
        case STATISTIC_SET_XNC_OPERATIONS_MATERIALS_BANDS_CONNECTION: {
            return {
                ...state, statistic_xnc_operation_material_band_connection: {
                    ...state.statistic_xnc_operation_material_band_connection,
                    ...action.payload
                }
            }
        }
        case STATISTIC_SET_XNC_OPERATIONS_MATERIALS_BANDS_CONNECTION_CREATE_MODAL: {
            return {
                ...state, statistic_xnc_operation_material_band_connection_create_modal: {
                    ...state.statistic_xnc_operation_material_band_connection_create_modal,
                    ...action.payload
                }
            }
        }
        default: {
            return state
        }
    }
}
