import React, {useMemo, useState} from 'react';
import {
    getStaticsXncOperations,
    getStaticsXncOperationsByKeModal
} from "../../../store/selectors/statistics_xnc_operations_selector";
import {useDispatch, useSelector} from "react-redux";
import Modal from "../MyModal/Modal";
import {toggleStatisticXncOperationFilteredByKeyModalAC} from "../../../store/actions/StatisticXncOperationsAction";
import {KEYS_NAMING_OPT_STORE} from "../../../constants/statistics_xnc_operations_constant";

const FIELD_NAME_BY_KEY = {
    count_detail: {
        title: 'Количество деталей',
        unit: null,
    },
    lines_count: {
        title: 'Количество строк в заказах',
        unit: null,
    },
    orders_count: {
        title: 'Количество заказов',
        unit: null,
    },
    perimetr: {
        title: 'Периметр всех деталей в заказах',
        unit: 'м',
    },
    square: {
        title: 'Площадь всех деталей в заказах',
        unit: ' м2',
    },
    volume: {
        title: 'Объём всех заказов',
        unit: ' м3',
    },
    bore: {
        title: 'Отверстия',
        unit: null,
        in: "глухие",
        through: "сквозные"
    },
    paz: {
        title: 'Паз',
        unit: null,
        plane: "на плоскости",
        edge: "торцевые"
    },
    cut_to: {
        title: 'Урезка',
        unit: null,
    },
    frez: {
        title: 'Фрезеровка',
        unit: null,
        template: "по шаблонам iFurn.pro",
        import: "импортированные шаблоны"
    },
    hem: {
        title: 'Сшивки',
        unit: null,
    },
    trough: {
        title: 'Выемки',
        unit: null,
        circle: "круг",
        strait: "прямоугольник",
        curve: "скругленный прямоугольник",

    },

}
const StatisticsXncOperationsByKeyModal = ({getT}) => {
    const modal_state = useSelector(getStaticsXncOperationsByKeModal);
    const statistics_data_state = useSelector(getStaticsXncOperations);
    const dispatch = useDispatch();

    const [form_filtered_by_type, setFormFilteredByType] = useState({manager: true, client: false, order: false});
    const handlerChangeFormFilteredByType = (key) => e => {
        setFormFilteredByType(prev => ({
            manager: false, client: false, order: false, [key]: true
        }))
    }
    const data_filter_by_key = useMemo(() => {
        if (!modal_state?.isOpen) return null
        let key_for_filter = Object.keys(form_filtered_by_type)?.find(key => !!form_filtered_by_type[key])
        let key = KEYS_NAMING_OPT_STORE[key_for_filter].opt
        let key_id = KEYS_NAMING_OPT_STORE[key_for_filter].id

        let key_opt_info = KEYS_NAMING_OPT_STORE[key_for_filter]?.opt_key_info
        return statistics_data_state?.[`${key}_data`]
            ?.filter(item => {
                let is_type = item.op === modal_state?.opt_key;

                if (modal_state.add_opt && modal_state?.opt_key === 'bore') {
                    return is_type && item?.type === modal_state.add_opt?.type && Number(item.diam) === Number(modal_state.add_opt?.d)
                }
                if (modal_state.add_opt && modal_state?.opt_key === 'paz') {
                    return is_type && item?.type === modal_state.add_opt?.type && Number(item.w_paz) === Number(modal_state.add_opt?.w_paz)
                }
                if (modal_state?.add_opt && modal_state?.opt_key) {
                    return is_type && item?.type === modal_state.add_opt?.type
                }
                return is_type

            })
            ?.map(item => {
                let key = key_opt_info === 'orders' ? 'orders_data' : key_opt_info;
                let key_id_data = key_opt_info === 'orders' ? 'order_id' : 'id'
                let find_name = statistics_data_state?.[key]?.find(e => Number(e?.[key_id_data]) === Number(item?.[key_id]));
                let name = key_opt_info === 'orders' ? find_name?.order_id : find_name?.name;
                if (key_opt_info === 'orders') {
                    let order_information = statistics_data_state?.['orders_client_user']?.find(order => Number(order?.id) === Number(find_name?.order_id))
                    return {
                        ...item, name_user: name,
                        order_information: order_information
                    }
                }
                return {
                    ...item, name_user: name
                }
            })?.sort((a, b) => b?.count - a?.count)
    }, [form_filtered_by_type, statistics_data_state, modal_state])
    const handlerClose = () => dispatch(toggleStatisticXncOperationFilteredByKeyModalAC({
        isOpen: false,
        opt_key: null
    }));
    const titleModal = useMemo(() => {
        let item = FIELD_NAME_BY_KEY?.[modal_state?.opt_key];
        let add_opt = modal_state?.add_opt;
        let type = getT(item?.[modal_state?.add_opt?.type] ?? '') ?? '';
        let diameter = modal_state?.opt_key === 'bore' ? `${getT("Диаметр")} ${add_opt?.d}` : ''
        let w_paz = modal_state?.opt_key === 'paz' ? `${getT("Ширина паза")} ${add_opt?.w_paz}` : '';
        return `${getT(item?.title)}   ${type}.  ${diameter} ${w_paz}   `
    }, [modal_state])
    if (!modal_state?.isOpen) return null

    return (
        <Modal title={titleModal} onClose={handlerClose}
               open={modal_state.isOpen}>
            <div className="text-center mb-4 ">
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                           onChange={handlerChangeFormFilteredByType('client')} checked={form_filtered_by_type.client}/>
                    <label className="form-check-label" htmlFor="inlineRadio1">{getT("Клиент")}</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                           onChange={handlerChangeFormFilteredByType('manager')}
                           checked={form_filtered_by_type.manager}/>
                    <label className="form-check-label" htmlFor="inlineRadio2">{getT("Менеджер")}</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                           onChange={handlerChangeFormFilteredByType('order')} checked={form_filtered_by_type.order}/>
                    <label className="form-check-label" htmlFor="inlineRadio2">{getT("Заказы")}</label>
                </div>
            </div>
            <ul className="list-group border-0">
                {data_filter_by_key?.map(item => {
                    let title = item?.hasOwnProperty('order_information') ? `${getT("Клиент")}: ${item?.order_information?.client}. \n ${getT("Менеджер")}: ${item?.order_information?.user}. \n ${getT("Дата")}: ${item?.order_information?.date}.` : null;

                    return <li
                        className="list-group-item d-flex align-items-center justify-content-between border-top-0 border-left-0 border-right-0"
                        title={title}>
                        <span className="font-weight-normal">
                            {form_filtered_by_type?.order ?
                                <a target="_blank" href={`/orders/detail/${item?.name_user}`}><i
                                    className="far fa-eye"> №{item?.name_user}</i></a>
                                : item?.name_user
                            }
                            </span>
                        <span
                            className="font-weight-bold ">  {item?.count} {getT(FIELD_NAME_BY_KEY?.[modal_state?.opt_key]?.unit ?? '')}</span>


                    </li>
                })}
            </ul>
        </Modal>
    );
};

export default StatisticsXncOperationsByKeyModal;