import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getDefaultPlane} from "../../store/selectors/salary_project_selector";
import {getDefaultPlaneThunk} from "../../store/thunks/salary_project/default_plane_thunk";
import PageTitle from "../../components/PageTitle/PageTitle";
import ContainerContent from "../../components/ui/ContainerContent";
import ItemPlan from "./component/ItemPlan";
import "./main.css"
import {toggleDefaultPlaneCreateModalAC} from "../../store/actions/SalaryProject";
import DefaultPlaneModal from "../../components/Modals/SalaryProject/DefaultPlaneModal";

const SalaryProjectDefaultPlanePage = ({getT}) => {
    const default_plane_state = useSelector(getDefaultPlane);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDefaultPlaneThunk())
    }, [dispatch]);
    const onHandlerAddPlane = ({service_type, count_bad = null, count_normal = null, count_super = null}) => {
        dispatch(toggleDefaultPlaneCreateModalAC({
            isOpen: true, form_values: {
                service_type: service_type, count_bad: count_bad, count_normal: count_normal, count_super: count_super
            }
        }))
    }
    return (
        <ContainerContent>
            <>
                <PageTitle title={"default_plane"}/>
                <div className="container-fluid">
                    <div className="row">

                        {default_plane_state?.error ?
                            <div className="col-12">
                                <div className="jumbotron jumbotron-fluid">
                                    <div className="container">
                                        <h1 className="display-4">Information</h1>
                                        <p className="lead">{default_plane_state?.error}</p>
                                    </div>
                                </div>
                            </div>
                            :
                            default_plane_state?.data?.map(plane => <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
                                <ItemPlan plane={plane} getT={getT}
                                          onHandlerAddPlane={onHandlerAddPlane}/>
                            </div>)
                        }

                    </div>
                </div>
            </>
            <DefaultPlaneModal key={'create-plane'}/>
        </ContainerContent>
    );
};

export default SalaryProjectDefaultPlanePage;