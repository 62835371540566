import React from 'react';
import Modal from "../MyModal/Modal";
import {useForm} from "react-hook-form";
import Button from "../../ui/Button";
import {useDispatch, useSelector} from "react-redux";
import {getSheetDataPrintConfirmModal} from "../../../store/selectors/main_selector";
import {sheetDataPrintConfirmModalAC} from "../../../store/actions/MainActions";
import {orderDetailDataSheetPrintThunk} from "../../../store/thunks/order_detail_thunk";

const ShortFormOrderConfirmModal = ({getT}) => {
    const dispatch = useDispatch();
    const modal = useSelector(getSheetDataPrintConfirmModal)
    const {register, handleSubmit} = useForm({
        defaultValues: {
            is_pack: false, is_service: false
        }
    })
    if(!modal.isOpen) return  null
    const onSubmit = async (data) => {
        console.log(data)
        dispatch(orderDetailDataSheetPrintThunk({...data}))
    }
    const handlerClose = ()=>{
        dispatch(sheetDataPrintConfirmModalAC({isOpen: false, order: null, full: false}))
    }
    return (
        <Modal open={modal.isOpen} title={getT("Спецификация")} size_modal_class={"modal-md"} onClose={handlerClose}>
            <>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <h5>{getT("Включить данные")}</h5>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox"
                               id="defaultCheck1" {...register("is_pack", {value: "is_pack"})}/>
                        <label className="form-check-label" htmlFor="defaultCheck1">
                            {getT("Упаковки")}
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox"
                               id="defaultCheck2"   {...register("is_service", {value: "is_service"})}/>
                        <label className="form-check-label" htmlFor="defaultCheck2">
                            {getT("Услуги")}
                        </label>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <Button>{getT("Отправить")}</Button>
                    </div>
                </form>
            </>
        </Modal>
    );
};

export default ShortFormOrderConfirmModal;