import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getConfirmCommonModal} from "../../../store/selectors/main_selector";
import {ConfirmCommonModalAC} from "../../../store/reducers/MainReducer";
import Modal from "../MyModal/Modal";

const ConfirmCommonModal = ({getT}) => {
    const dispatch = useDispatch()
    const modal_state = useSelector(getConfirmCommonModal);
    if (!modal_state?.isOpen) return null;
    const handlerClose = () => {
        dispatch(ConfirmCommonModalAC({isOpen: false, onConfirm: null, message: null}))
    }

    const handleConfirm = () => {
        if (modal_state?.onConfirm) modal_state?.onConfirm();
        handlerClose()
    };
    return (
        <Modal title={getT("Подтверждение")} onClose={handlerClose} open={modal_state.isOpen}>
            <h5 className={"text-center mb-4"}>{getT(modal_state?.message)}</h5>
            <div className="modal-footer justify-content-center">
                <button type="button" className="btn btn-success" onClick={handleConfirm}>{getT("Да")}</button>
                <button type="button" className="btn btn-secondary" onClick={handlerClose}>{getT("Нет")}</button>
            </div>
        </Modal>
    );
};

export default ConfirmCommonModal;