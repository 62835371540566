import React from "react";

import Modal from "../Modals/MyModal/Modal";
import { useSelector } from "react-redux";
export function ServicesModal({ open, services, onChange, onSubmit, onClose, getT }) {
  const status = useSelector((state) => state.auth.user.role);
  const roles = status.map((el) => el.name);

  const canEdit = !roles.includes("CEO") && roles.includes("manager");
  return (
    <Modal open={open} onClose={onClose} title={getT("Перечень услуг")}>
          {Array.isArray(services) && services.map((service) => (
            <div key={service.id} className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name={service.name}
                checked={Number(service.status) === 1}
                onChange={(event) => {
                  onChange(
                    services.map((item) =>
                      item.id === service.id
                        ? {
                            ...service,
                            status: event.currentTarget.checked ? 1 : 0
                          }
                        : item
                    )
                  );
                }}
              />
              <label className="form-check-label">{service.name}</label>
            </div>
          ))}

        <div className="modal-footer justify-content-center">
          <button
            disabled={canEdit}
            className="btn btn-primary"
            onClick={() => {
              onSubmit();
            }}
          >
            {getT("Отправить")}
          </button>
        </div>
    </Modal>
  );
}
