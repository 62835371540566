import ApiService from "../../api/api";
import {getNewsThunk, setAlertMessageThunk, toggleLoaderStatusAC} from "./MainReducer";
import {
    AUTH_SET_WORKER,
    CHANGE_AUTH_FORM_VALUE,
    SET_AUTH_PAYMENT_ADDITIONAL,
    SET_USER_AUTH, setAuthPaymentAdditionalAC,
    setAuthWorkerAC
} from "../actions/AuthActions";
import {
    IFURN_PRO_URL, LS_CLIENT_TOKEN, LS_FIRM,
    LS_IS_DISPLAY_MODULE_SALORY,
    LS_USER_ID, LS_USER_TOKEN,
    LS_WORKER_ID,
    PATHNAME_FOR_REDIRECT
} from "../../utils/constants";


const SET_IS_AUTH = "SET_IS_AUTH";

const apiService = new ApiService();
let initialState = {
    user: {
        id: localStorage.getItem("i_furn_user_id"),
        name: null,
        firm: localStorage.getItem("i_furn_user_firm"),
        email: null,
        role: null
    },
    authForm: {
        login: null,
        pass: null,
        isFailure: false
    },
    isAuth: false,
    worker: null,
    payment_additional: null

};

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH_PAYMENT_ADDITIONAL:{
            return {
                ...state,
                payment_additional: action.payload
            }
        }
        case AUTH_SET_WORKER:{
            return {
                ...state,
                isAuth: true,
                worker: action.payload,

            }
        }
        case SET_USER_AUTH:
            return {
                ...state,
                user: {
                    ...state.user,
                    name: action.user.name,
                    firm: action.user.firm,
                    email: action.user.email,
                    role: action.user.role,
                    payment: action.user?.payment,
                    logo: action?.user?.logo
                },
                isAuth: true,
            };
        case CHANGE_AUTH_FORM_VALUE:
            let authForm = {...state.authForm};
            authForm[action.input] = action.value;
            return {
                ...state,
                authForm: authForm
            };
        case SET_IS_AUTH: {
            return {
                ...state,
                isAuth: action.isAuth
            }
        }
        default:
            return state;
    }
};

export const setUserAuthAC = (user) => {
    return {
        type: SET_USER_AUTH,
        user: user
    };
};
export const setIsAuthAC = (isAuth) => {
    return {
        type: SET_IS_AUTH,
        isAuth
    };
};
export const changeAuthFormValueAC = (input, value) => {
    return {
        type: CHANGE_AUTH_FORM_VALUE,
        input: input,
        value: value
    };
};

export const resetPasswordThunk = (data) => dispatch => {
    dispatch(toggleLoaderStatusAC(true));

    apiService._resetRegisterPassword('', data).then(res => {
        if (res.result) {
            dispatch(setAlertMessageThunk(res?.message, "success"));
            dispatch(toggleLoaderStatusAC(false));
        }
        if (res.hasOwnProperty('error_api') && res['error_api'].hasOwnProperty('api_error')) {
            dispatch(toggleLoaderStatusAC(false));
            dispatch(setAlertMessageThunk(res['error_api']['api_error'][0].error, "error"));
            // dispatch(changeAlertStatusAC(true, 'Пароль клиента успешно обновлен и отправлен ему на почту!'));

        } else {

        }
    }).catch(e => {
        dispatch(toggleLoaderStatusAC(false));

    })
}

async function authorizationUserSystemDispatched (auth_response, dispatch){

    let isAuthorized = auth_response.isAuthed && auth_response.token && !auth_response.client_id;
    if (auth_response?.hasOwnProperty('custom_error') && !isAuthorized ) {
        window.location.href = IFURN_PRO_URL;
        return
    }
    let isGetAuthData = auth_response && auth_response.isAuthed && !auth_response.hasClients
    if(!isGetAuthData){
        return
    }
    let auth_data_response = await apiService.getAuthData(auth_response.user_id);
    if(auth_data_response){
        let is_salory = auth_response?.salory ?? 0;
        localStorage.setItem(LS_IS_DISPLAY_MODULE_SALORY, is_salory)
        dispatch(setUserAuthAC({
            ...auth_data_response,
            payment: auth_response?.payment,
            logo: auth_response.logo,
            is_display_module_salory: is_salory,
            payment_additional: auth_data_response?.payment_additional ?? null
        }))
    }
}
/**
 * проверка авторизации пользователя в системе .
 * доступно для пользователя и работника
 */
export const checkingUserAuthorizationInSystem = () => async dispatch => {
    try {
        const query = new URLSearchParams(window.location.search);
        const value = query.get('auth_token');

        if(!!value){
            const redirect_response = await apiService.userVerification('/ping?auth_token=' + value + '&no_redirect=1');
            if(redirect_response === "pong"){
                window.location.href = window.location.origin + window.location.pathname;
            }
            return
        }

        let auth_response = await apiService.userVerification();
        if(auth_response?.hasOwnProperty('custom_error')){
            localStorage.removeItem(LS_USER_TOKEN)
            localStorage.removeItem(LS_CLIENT_TOKEN)
            localStorage.removeItem(LS_USER_ID)
            window.location.href = IFURN_PRO_URL;
            return
        }
        let is_worker = auth_response?.hasOwnProperty('equipment_worker') && !!Number(auth_response?.equipment_worker);
        let is_user = auth_response?.isAuthed && auth_response?.token;
        if(is_user){
            localStorage.removeItem(LS_WORKER_ID)
            await authorizationUserSystemDispatched(auth_response, dispatch);
            dispatch(setAuthPaymentAdditionalAC(auth_response.payment_additional ?? null))

        }
        if(is_worker && auth_response?.hasOwnProperty('data')){
            localStorage.removeItem(LS_USER_TOKEN)
            localStorage.removeItem(LS_CLIENT_TOKEN)
            localStorage.removeItem(LS_USER_ID)
            localStorage.setItem(LS_FIRM, auth_response?.data?.firm);
            localStorage.setItem(LS_WORKER_ID, auth_response?.data?.equipment_worker_id);
            localStorage.setItem(LS_IS_DISPLAY_MODULE_SALORY, 1);
            dispatch(setAuthWorkerAC(auth_response?.data));
            dispatch(setAuthPaymentAdditionalAC(auth_response?.data?.payment_additional ?? null))

        }

    } catch (e) {
        console.log('catch', e)
    }
}


export default AuthReducer;


