import React, {useMemo} from 'react';
import {useForm} from "react-hook-form";

const FIELDS_RADIO = {
    sell_retail_price_wholesale: {
        title: "Продавать товары по розничной цене оптового партнера",
        type: "radio",
        key: "sell_retail_price_wholesale"
    },
    sell_increase_inbound_price_wholesale: {
        title: "Продавать товары по входящей цене оптового партнера с наценкой",
        type: "radio",
        key: "sell_increase_inbound_price_wholesale"
    },
    sell_all_goods_wholesale_at_minimal_price: {
        title: "Продавать все товары оптом по минимальной цене все цены будут равны 0.1",
        type: "radio",
        key: "sell_all_goods_wholesale_at_minimal_price"
    },
}
const FIELDS_BOX = {
    use_recommended_increase_wholesale: {
        title: "Использовать рекомендуемую надбавку оптового партнера",
        type: "checkbox"
    },
    trade_only_own_goods_list: {
        title: "Торговать товарами только из списка собственных товаров",
        type: "checkbox"
    },

}

const PartnerPropertyForm = ({initail_value, getT, handlerSend}) => {
    // Меморизуем объект values, чтобы избежать лишних рендеров
    const values = useMemo(() => ({
        sell_retail_price_wholesale: Number(initail_value?.sell_retail_price_wholesale) ?? 0,
        sell_increase_inbound_price_wholesale: Number(initail_value?.sell_increase_inbound_price_wholesale) ?? 0,
        use_recommended_increase_wholesale: Number(initail_value?.use_recommended_increase_wholesale) ?? 0,
        default_increase_on_inbound_price: Number(initail_value?.default_increase_on_inbound_price) ?? 0,
        trade_only_own_goods_list: Number(initail_value?.trade_only_own_goods_list) ?? 0,
        sell_all_goods_wholesale_at_minimal_price: Number(initail_value?.sell_all_goods_wholesale_at_minimal_price) ?? 1,
    }), [initail_value]); // Зависимость от initail_value
    const {register, setValue, watch, handleSubmit} = useForm({
        values: values
    })

    return (
        <form onSubmit={handleSubmit(handlerSend)}>
            <div className="row">
                <div className="col-12 col-md-6 form-group">
                    <label>{getT("Тип продажи")}:</label>
                    {Object.keys(FIELDS_RADIO)?.map(key_field => {
                        let title = FIELDS_RADIO?.[key_field]?.title ?? ""
                        return <div className="form-check">
                            <input className={"form-check-input"} type="checkbox"
                                   checked={!!Number(watch(key_field))}
                                   {...register(key_field, {
                                       onChange: (e) => {
                                           Object.keys(FIELDS_RADIO)?.forEach(key => {
                                               if (key !== key_field) {
                                                   setValue(key, Number(0))
                                               }
                                           })
                                           setValue(key_field, Number(e.target.checked))
                                       }
                                   })}
                            />
                            <label className={"form-check-label"}>{getT(title)}</label>
                        </div>
                    })}
                </div>
                <div className="col-12 col-md-6 form-group">
                    {Object.keys(FIELDS_BOX)?.map(key_field => {
                        let title = FIELDS_BOX?.[key_field]?.title ?? ""
                        return <div className=" form-check">
                            <input className={"form-check-input"} type="checkbox"
                                   checked={!!Number(watch(key_field))}
                                   {...register(key_field, {
                                       onChange: (e) => {
                                           setValue(key_field, Number(e.target.checked))
                                       }
                                   })}
                            />
                            <label className={"form-check-label"}>{getT(title)}</label>
                        </div>
                    })}
                </div>
                <div className="col-12 col-md-6  ">
                    <div className="form-group">
                        <label>{getT("Наценка по умолчанию на входящую цену")}</label>
                        <input type="number" step={0.01}
                               className={"form-control"}
                               value={watch("default_increase_on_inbound_price")}
                               {...register("default_increase_on_inbound_price", {
                                   required: true,
                                   min: 1,
                                   onChange: (e) => {
                                       setValue("default_increase_on_inbound_price", Number(e.target.value))
                                   }
                               })}
                        />
                    </div>
                </div>

            </div>
            <div className="mt-3 d-flex justify-content-center">
                <button type={"submit"} className={"btn btn-primary"}>{getT("Отправить")}</button>
            </div>
        </form>
    );
};

export default PartnerPropertyForm;