import React from 'react';
import {getAllIdsChildrenByRootFolder} from "../helper-material-selected";

const FolderItem = ({item, formik_values, handlerChange}) => {
    return (
        !!(Array.isArray(item?.children) && !!item?.children?.length) ?
            <div className={'folder-item'} style={{paddingLeft: '15px'}}>
                {item.children.map(chid=>{
                    let is_has_children = chid?.children?.length;
                    let child_value_ids = getAllIdsChildrenByRootFolder(chid)
                    let find_current_id =  formik_values?.some(id=> Number(id) === Number(chid.material_type_id))
                    let filtered_children_ids =formik_values?.filter(id=> child_value_ids.some(child_id=> Number(child_id) === Number(id)));
                    let checked = is_has_children ?
                        filtered_children_ids.length === child_value_ids.length :
                        find_current_id



                    return <div className="folder-children" style={{paddingLeft: '15px'}}>
                        <div className="field"  key={chid.material_type_id}>
                            <label className={'form-input-checkbox-label'} style={{justifyContent: "flex-start"}}
                                   htmlFor={`band_${chid.material_type_id}`}
                            >
                                <input type="checkbox"
                                       id={`band_${chid.material_type_id}`}
                                       className={"form-input-checkbox"}
                                       onChange={(e)=>{
                                           let checked = e.target.checked;
                                           let child_ids = getAllIdsChildrenByRootFolder(chid)
                                           if(child_ids?.length){
                                               child_ids.push(Number(chid.material_type_id))

                                               handlerChange(child_ids, checked)

                                           }else {
                                               handlerChange([Number(chid.material_type_id)], checked)
                                           }
                                       }
                                       }
                                       checked={checked}
                                       value={chid.material_type_id}
                                />
                                <span>{chid?.name}</span>
                            </label>
                        </div>
                        <FolderItem item={chid} handlerChange={handlerChange} formik_values={formik_values}/>
                    </div>
                })}
            </div>
            : null
    );
};

export default FolderItem;

