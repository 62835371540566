import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getShiftPlaneUpdateModal} from "../../../store/selectors/salary_project_selector";
import {setShiftPlaneUpdateModalAC} from "../../../store/actions/SalaryProject";
import {INITIAL_SHIFT_PLANE} from "../../../store/reducers/SalaryProjectReducer";
import Modal from "../MyModal/Modal";
import ShiftPlaneFormCU from "./components/ShiftPlane/ShiftPlaneFormCU";
import {shiftPlaneUpdateThunk} from "../../../store/thunks/salary_project/shifh-thunk";

const SalaryShiftPlaneUpdateModal = ({getT}) => {
    const modal_state = useSelector(getShiftPlaneUpdateModal);
    const dispatch = useDispatch();
    const handlerClose = () => {
        dispatch(setShiftPlaneUpdateModalAC({isOpen: false, form_values: INITIAL_SHIFT_PLANE}))
    }
    const onSubmitUpdate = (data)=>{
        dispatch(shiftPlaneUpdateThunk(data))
    }
    return (
        <Modal title={getT("Обновить")} open={modal_state.isOpen} onClose={handlerClose} size_modal_class={"modal-md"}>
            <ShiftPlaneFormCU form_values={modal_state.form_values} getT={getT} onSubmitCallback={onSubmitUpdate}/>
        </Modal>
    );
};

export default SalaryShiftPlaneUpdateModal;