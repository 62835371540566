import React from 'react';

const ItemPaz = ({title, data, getT, handlerOpenModalSeeMoreStaticsByKey}) => {
    if(!Array.isArray(data) || !data?.length) return  null
    return (
        <div className="mb-3">
    <h6 className={"font-weight-bold"}>{title}</h6>
        <table className="">
            <thead>
            <tr>
                <th style={{padding: '3px'}} scope="col">{getT("Ширина")}</th>
                <th style={{padding: '3px'}} scope="col">{getT("Кол-во")}</th>
                {!!(handlerOpenModalSeeMoreStaticsByKey) &&  <th style={{padding: '3px'}} scope="col">{}</th>}
            </tr>
            </thead>
            <tbody>

            {data?.map(item => {
                return <tr style={{padding: '3px', margin: '0', minHeight: '10px', borderWidth: '1px'}}>
                    <td data-label={getT("Ширина")}>{item?.w_paz}</td>
                    <td data-label={getT("Кол-во")}>{item?.count}</td>
                    {!!(handlerOpenModalSeeMoreStaticsByKey) &&   <td data-label={getT("Кол-во")}>
                      <i className="far fa-eye text-primary ml-2 cursor-pointer" onClick={handlerOpenModalSeeMoreStaticsByKey('paz', {type: item?.type, w_paz: item?.w_paz})}> </i>

                    </td>}
                </tr>
            })}
            </tbody>
        </table>
        </div>

    );
};

export default ItemPaz;