import ApiService from "./api";
import axios from "axios";


class ApiGiblab extends ApiService {

    //TODO: ссылка под замену (АПИ)

    constructor() {
        super();
        this._baseUrl += "/giblab";
    }

    // _baseUrl = 'https://test2-api.ifurn.pro/api/giblab';
    // _baseUrl = 'https://test-api.ifurn.pro/api/giblab';
    // _baseUrl = 'https://api.ifurn.pro/api/giblab';
    // _baseUrl = 'http://api-local.ifurn.pro/api/giblab';

    // _giblabApiLocal = 'https://gibserv.ifurn.pro';
    // _giblabApiMain = 'https://service.giblab.com';

    _giblabApiLocal = "https://newgo.ifurn.pro/api/gl/";
    _giblabApiMain = "https://newgo.ifurn.pro/api/gl2/";
    _withCredentials = "include";

    _checkApiDomain = async () => {
        const domain = (await new Promise((resolve, reject) => {

                let failed = 0;

                axios.get(this._giblabApiLocal).then(resolve, (error) => {
                    failed++;
                    if (failed === 2) {
                        reject(error);
                    }
                    if(error) return reject(error)
                });

                // setTimeout(() => {
                //   axios.get(this._giblabApiMain).then(resolve, (error) => {
                //     failed++;
                //     if (failed === 2) {
                //       reject(error);
                //     }
                //   });
                // }, 1950);

            })
        );
        return domain;
    };

    // _getXml = async (order) => {
    //     const requestXml = await axios.post(this._baseUrl, {
    //         order: order,
    //         action: "react_optimize_back_xml",
    //         base64: "0",
    //         cards_checked: []
    //     });

    //     debugger;
    //     if(requestXml.error_api) {
    //       this._errors = requestXml.error_api;
    //       return false;
    //     }

    //     return requestXml.data.message;
    // }

    _getXml = async (order) => {
        const requestXml = await this._sendData(
            `/`,
            "POST",
            {
                order: order,
                action: "react_optimize_back_xml",
                base64: "0",
                cards_checked: [],
                crm: 1
            },
            "json"
            // {},
            // "json"
        );
        if (requestXml && requestXml.message) {
            return {xml: requestXml.message, red_res: requestXml.red_res};
        } else if (requestXml.error_api) {
            this._errors = requestXml.error_api;
            return false;
        } else {
            this._errors = ''
            return false
        }
    };

    _sendGiblab = async (domain, xml) => {
        return await axios.post(domain.config.url, xml, {
            timeout: 120000,
            headers: {
                "Access-Control-Allow-Orgin": "*",
                "Content-Type": "application/octet-stream; charset=UTF-8",
                action: "C4F6336B"
            }
        }).then(requestGiblab => {
            if (requestGiblab.error_api) {
                this._errors = requestGiblab.error_api;
                return false;
                // return  requestGiblab;
            }
            return requestGiblab.data;
        }).catch(e => {
            if (e.status >= 500) {
                return Promise.resolve({timout: true});
            } else {
                return Promise.resolve({timout: true});
            }
        });
    };

    _Service = async (xml) => {


        return await axios.post(this._giblabApiMain, xml, {
            headers: {
                "Access-Control-Allow-Orgin": "*",
                "Content-Type": "application/octet-stream; charset=UTF-8",
                action: "C4F6336B"
            }
        }).then(requestGiblab => {

            if (requestGiblab.error_api) {
                this._errors = requestGiblab.error_api;
                return false;
            }
            return requestGiblab.data;
        }).catch(e => {
            return Promise.reject(e);
        })


    };
    getCards = async (resultGiblab, order = {}) => {
        const requestCards = await this._sendData(
            `/`,
            "POST",
            {
                action: "file_optimized_from_giblab_cards",
                message: resultGiblab,
                date: [],
                order: order
            },
            {
                "Content-Type": "application/octet-stream; charset=UTF-8"
            },
            "json"
        );

        const cards = requestCards;

        if (cards && cards.response && cards.response.files && cards.response.files[1]) {
            window.open(cards.response.files[1].link);
        } else {
            return false;
        }


    };

    getReport = async (resultGiblab, order = {}) => {
        const report = await this._sendData(
            `/`,
            "POST",
            {
                action: "file_optimized_from_giblab_report",
                message: resultGiblab,
                order: order
            },
            {
                "Content-Type": "application/octet-stream; charset=UTF-8"
            },
            "json"
        );

        if (report && report.response && report.response.files && report.response.files[1]) {
            window.open(report.response.files[1].link);
        } else {
            return false;
        }

    };


    getCalc = async (resultGiblab, order) => {
        const calc = await this._sendData(
            `/`,
            "POST",
            {
                action: "file_optimized_from_giblab_calc",
                message: resultGiblab,
                order: order
            },
            {
                "Content-Type": "application/octet-stream; charset=UTF-8"
            },
            "json"
        );

        if (calc && calc.order && calc.order.order) {
            return calc.order.order;
        } else {
            return false;
        }
    };


    _getErrors() {
        if (!this._errors) {
            return false;
        }
        let errorText = '';
        this._errors.api_error.forEach(e => {
            console.log(e.code)
            console.log(e);
            if (e.code && Number(e.code) === 403) {
                for (var attr in localStorage) {
                    if (attr != 'lang') {
                        // alert(localStorage[attr]);
                        localStorage.removeItem(attr);
                    }
                }
                // localStorage.clear();
                window.location.href = '/login';
            }
            if (typeof e.error === 'string') {
                errorText += e.error + ',';
            } else {
                for (var key in e.error) {
                    errorText += e.error[key] + ',';
                }
            }

        });

        this._errors = null;

        return errorText;
    }


}

export default ApiGiblab;