export const WS_ORDERS_SUPPLY_GET = "WS_ORDERS_SUPPLY/GET";
export const WS_ORDERS_SUPPLY_SET_MODAL = "WS_ORDERS_SUPPLY/SET_MODAL";

/** wsGetOrdersGoodsAction. set orders goods
 * @param {array || null}  data - entries orders
 * @param {array || null}  orders_for_current_firm_as_partner - заказы, сделанные фирмой для своих поставщиков.
 * @param {array || null}  orders_for_current_firm_as_main_firm -  заказы, полученные фирмой от своих клиентов (партнёров).
 * @param {object}  filter - includes fields for pagination and query search (total, offset,limit, status, search_by_name)
 * @returns {object} -
 */

export const wsGetOrdersSupplyAction = ({
                                            data,
                                            orders_for_current_firm_as_partner = null,
                                            orders_for_current_firm_as_main_firm = null,
                                            filter
                                        }) => {
    return {
        type: WS_ORDERS_SUPPLY_GET,
        payload: {
            data: data,
            orders_for_current_firm_as_partner: orders_for_current_firm_as_partner,
            orders_for_current_firm_as_main_firm: orders_for_current_firm_as_main_firm,
            filter: filter
        }
    }
}

/**
 * .
 *
 * @param {boolean} isOpen  -
 * @param {object || null} orderCurrent  - если есть то это входящие данные для редактирования
 * * @returns  {}  -
 */
export const wsSetOrderSupplyModalAction = ({isOpen, orderCurrent}) => {
    return {
        type: WS_ORDERS_SUPPLY_SET_MODAL,
        payload: {isOpen: isOpen, orderCurrent: orderCurrent}
    }
}