import {useState} from "react";

let getInitialMonthAndYear = () => {
    let day = new Date();
    let year = day.getFullYear();
    let month = day.getMonth() + 1;

    return `${year}-${month >= 10 ? month : `0${month}`}`
}
export default function () {

    const [date, setDate] = useState(getInitialMonthAndYear());
    const onHandlerChangeFilterDate = (e) => {
        let day = e.target.value;
        if (!!day) {
            setDate(day)
        } else {
            setDate(getInitialMonthAndYear)
        }
    };
    const parsForParamsData = () => {
        let data_split = date?.split('-').map(Number);
        let params = {
            month: data_split?.[1] ?? new Date().getMonth(),
            year: data_split?.[0] ?? new Date().getFullYear()
        }
        return params
    }


    return [date, onHandlerChangeFilterDate, parsForParamsData]
}