import React from 'react';
import THeader from "./THeader";
import {setShiftUpdateModalAC} from "../../../../store/actions/SalaryProject";
import {useDispatch} from "react-redux";
import {deleteShiftThunk, openedShiftThunk} from "../../../../store/thunks/salary_project/shifh-thunk";
import {LS_USER_ID} from "../../../../utils/constants";
import {createFormData} from "../../../../utils/helpers";
import {Link} from "react-router-dom";

const TableShifts = ({getT, shift_entry = [], is_user_in_system}) => {
    const dispatch = useDispatch()
    const handlerToggleModalUpdateShift = (form_values) => () => {
        dispatch(setShiftUpdateModalAC({isOpen: true, form_values}))
    }
    const handlerDeleteShift = (shift) => () => {
        let api_data = {
            shift: shift, user: localStorage.getItem(LS_USER_ID)
        }
        const form_data = createFormData(api_data)
        dispatch(deleteShiftThunk(form_data))
    }
    const handlerOpenShift = (shift_id)=>()=>{
dispatch(openedShiftThunk(shift_id))
    }
    return (
        <div className="table-max-overlay mx-auto ">
            <table className="table table-striped table-bordered table-layout m-0">
                <THeader getT={getT}/>
                <tbody>
                {shift_entry?.map(shift => {
                    let delete_shift = !shift?.services_ready_list_by_shift?.length && is_user_in_system;
                    let is_open_shift = !!Number(shift?.open_for_edit) ;
                    // let open_shift = !Number(shift?.open_for_edit);
                    return <tr>
                        <td data-label={'#'}>{shift?.salary_shift_id}</td>
                        <td data-label={getT("Дата начала смены")}>{shift?.date_start}</td>
                        <td data-label={getT("Дата окончания смены")}>{shift?.date_end ?? '-'}</td>
                        <td data-label={getT("Коэффициент расчёта смены")}>{shift?.koef}</td>
                        <td data-label={getT("Действия")}>
                            <div className="shift-action">
                                <Link to={`/salary-project/shift/${shift?.salary_shift_id}`}>
                                    <i className="fa fa-eye cursor-pointer text-blue" aria-hidden="true"
                                       title={getT("Открыть")}/>
                                </Link>

                                {(is_open_shift && is_user_in_system )&& <i className="fas fa-edit text-success cursor-pointer"
                                                     aria-hidden="true"
                                                     onClick={handlerToggleModalUpdateShift({
                                                         date_start: shift?.date_start,
                                                         koef: shift?.koef,
                                                         shift: shift?.salary_shift_id
                                                     })}
                                                     title={getT("Редактировать")}></i>}

                                {/*{!delete_shift &&*/}
                                {/*    <i className="fas fa-ban text-warning cursor-pointer" title={getT("Закрыть")}>когда*/}
                                {/*        можна закрыть смену?</i>}*/}
                                {(!is_open_shift &&  is_user_in_system) &&
                                    <i className="fas fa-arrow-alt-circle-left text-fuchsia cursor-pointer" onClick={handlerOpenShift(shift?.salary_shift_id)}
                                       title={getT("Открыть")}></i>}
                                {delete_shift &&
                                    <i className="fa fa-trash text-danger cursor-pointer" title={getT("Удалить")}
                                       onClick={handlerDeleteShift(shift?.salary_shift_id)}
                                       aria-hidden="true"></i>}
                            </div>
                        </td>
                    </tr>
                })
                }
                </tbody>
            </table>
        </div>
    );
};

export default TableShifts;