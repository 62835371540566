import React from 'react';
import {connect} from "react-redux";
import Alert from "./Alert";


class AlertContainer extends React.Component {
    render() {
        return this.props.active ? <Alert message={this.props.message} type={this.props.type_message}/> : '';
    }
}

let mapDispatchToProps = (dispatch) => {
    return {

    }
}

let mapStateToProps = (state) => {
    return {
        active: state.main.alert.active,
        message: state.main.alert.message,
        type_message: state.main.alert.type_message
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);