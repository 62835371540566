import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getShiftUpdateModal} from "../../../store/selectors/salary_project_selector";
import Modal from "../MyModal/Modal";
import {setShiftUpdateModalAC} from "../../../store/actions/SalaryProject";
import {useForm} from "react-hook-form";
import {createFormData} from "../../../utils/helpers";
import {updateShiftThunk} from "../../../store/thunks/salary_project/shifh-thunk";
import ShiftFormCu from "./components/Shifts/ShiftFormCU";

const ShiftUpdateModal = ({getT}) => {
    const shift_modal_state = useSelector(getShiftUpdateModal);
    const dispatch = useDispatch();
    const handlerClose = () => dispatch(setShiftUpdateModalAC({isOpen: false}));
    const handlerCreate = (data, reset = null) => {
        let api_data = {
            ...data, shift: shift_modal_state?.form_values?.shift
        }
        const form_data = createFormData(api_data);
        dispatch(updateShiftThunk(form_data))
    }
    const {register, handleSubmit, watch, reset} = useForm({});
    if (!shift_modal_state?.isOpen) return null
    return (
        <Modal title={getT("Обновить")} size_modal_class={"modal-md"} onClose={handlerClose}
               open={shift_modal_state.isOpen}>
            <ShiftFormCu handlerSubmit={handlerCreate} getT={getT} form_values={shift_modal_state?.form_values}/>
        </Modal>
    );
};

export default ShiftUpdateModal;