import {instances, URI_API_EXTTUNELL} from "./setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const EQUIPMENT_WORKER_LIST = "equipment_worker_list"
const EQUIPMENT_WORKER_GET = "equipment_worker_get"
export const EquipmentWorkerAPI = {
    getList() {
        return instances.post(URI_API_EXTTUNELL, null, {
            headers: {
                action: EQUIPMENT_WORKER_LIST
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getById(form_data){
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: EQUIPMENT_WORKER_GET
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }
}