import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Button from "../../../../ui/Button";
import {putProductionMarkToDetailThunk} from "../../../../../store/thunks/salary_project/ready_mark_thunk";
import {useDispatch} from "react-redux";

/**
 * Тип услуги Порезка. Отображение карты кроя для отметки
 *
 * @param {function}  getT -  перевод
 * @param {object}  order - заказ
 * @param {object}  choice_service - выбранная услуга и тип услуги
 * @param {number | null}  choice_service.service_type_add -  тип услуги
 * @param {number | null}  choice_service.service_id -   услуга
 * @returns  {jsx}  -
 */
const EdgingContainer = ({getT, order, choice_service, readOnly = false}) => {
    const dispatch = useDispatch();
    let {material = [], part = [], part_ready_services_user_info = [], band = []} = order;

    const [select_value, setSelectValue] = useState({
        material: null, edge: null
    })
    const part_group = (select_value) => {
        if (!select_value?.material || !select_value?.edge) return []
        const group_cutting_of_material = part?.reduce((acc, item) => {
            let part_count = order?.count_part?.find(e => Number(e?.part_id) === Number(item?.id))
            let find_material = Number(item?.material) === Number(select_value.material);
            let is_has_edge = item.edge?.some(edge => Number(edge?.goods_id) === Number(select_value?.edge))
            let is_has_service_in_detail = item?.services_in_detail?.find(ser => Number(ser.service_id) === Number(choice_service.service_id)) || false
            if (find_material && is_has_edge && is_has_service_in_detail) {
                let find_part_ready = part_ready_services_user_info?.find(_item => Number(_item.service_id) === Number(choice_service.service_id) && Number(_item?.part_id) === Number(item?.id)) || {};
                acc.push({
                    ...item,
                    ...find_part_ready,
                    id: item.id,
                    is_checked: !!Object.keys(find_part_ready)?.length,
                    record_id: find_part_ready?.MySQL_record_id ?? null,
                    service_add: Number(choice_service.service_id),
                    is_initial_checked: !!Object.keys(find_part_ready)?.length,
                    count: part_count?.count,
                    count_service: is_has_service_in_detail?.count
                    // count: is_has_service_in_detail?.count
                })


            }
            return acc
        }, []);
        return group_cutting_of_material
    }
    const [form_value, setFormValue] = useState({
        all: false,
        part: []
    })

    const handlerChangeSelect = (key) => (e) => {
        let val = e.target.value;
        let select = {...select_value, [key]: val};
        if (key === "material") {
            select = {material: val, edge: ''}
        }
        setSelectValue(prev => ({...select}))
        let create_fields = part_group(select);
        let is_all_checked_parts = create_fields?.every(part => part.is_checked) || false
        setFormValue({all: is_all_checked_parts, part: create_fields})

    }
    const handlerAllParts = (e) => {
        let checked = e.target.checked;

        let parts = form_value.part?.map(item => {
            return {
                ...item, is_checked: checked,
            }
        });
        setFormValue(prev => ({
            ...prev, all: checked, part: parts
        }))
    }
    const handlerValues = (part) => e => {
        let checked = e.target.checked;
        let parts = form_value.part?.map(item => {
            let current = Number(item.id) === Number(part.id);
            if (current) {
                return {
                    ...item, is_checked: checked,
                }
            }
            return item
        });
        let is_all_checked_parts = parts.every(part => part.is_checked)
        console.log({all: is_all_checked_parts, part: parts})
        setFormValue(() => ({all: is_all_checked_parts, part: parts}))
    }
    const handlerSubmitMark = () => {
        let payload = {
            order_id: order.order,
        }
        if (form_value.all) {
            payload['put'] = {
                service_add: choice_service.service_id,
                service_type_add: choice_service.service_type_add, entry: []
            }

        } else {
            let put_data = form_value?.part?.filter(part => (part?.is_initial_checked !== part?.is_checked) && part?.is_checked)?.map(part => {
                return {part_id: part?.id, count: part?.count, service_add: part.service_add,
                    // links: []
                }
            })
            let delete_data = form_value?.part?.filter(part => (part?.is_initial_checked !== part?.is_checked) && !part?.is_checked && part?.MySQL_record_id)
                ?.map(part => {
                    return part?.MySQL_record_id
                });

            if (Array.isArray(put_data) && put_data?.length) {
                payload['put'] = {
                    entry: put_data
                }
            }
            if (Array.isArray(delete_data) && delete_data?.length) {
                payload['remove'] = {
                    entry: delete_data
                }
            }
        }
        console.log('payload', payload)
        dispatch(putProductionMarkToDetailThunk({
            ...payload
        }))
    }
    return (
        <div>
            <div className={"form-group"}>
                <label>{getT("Материал")}</label>
                <select name="" id="" className={'form-control'} value={select_value.material}
                        onChange={handlerChangeSelect('material')}>
                    <option value={''}> ---------</option>
                    {material?.map(item => {
                        return <option value={item.id}>{item.name} </option>
                    })}
                </select>
            </div>
            {!!select_value.material && <div className={"form-group"}>
                <label>{getT("Кромка")}</label>
                <select name="" id="" className={'form-control'} value={select_value.edge}
                        onChange={handlerChangeSelect('edge')}>
                    <option value={''}> ---------</option>
                    {band?.map(item => {
                        return <option value={item.id}>{item?.name}</option>
                    })}
                </select>
            </div>}
            <div className="cutting-wrap">
                <div className={'cutting-item'}>
                    {!!form_value?.part?.length ? <div className="part-list">
                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" id={`all_part`}
                                       checked={form_value.all} onChange={handlerAllParts} readOnly={readOnly}
                                />
                                <label className="form-check-label" htmlFor={`all_part`}>
                                    {getT("Все детали")}:
                                </label>
                            </div>
                            {form_value?.part?.map(part => {
                                return <div className="part pl-2"
                                            key={`part${part?.id}`}>
                                    <div className="form-group form-check">
                                        <input type="checkbox" className="form-check-input" id={`check_${part?.id}`}
                                               checked={part.is_checked} onChange={handlerValues(part)} readOnly={readOnly}
                                        />
                                        <label className="form-check-label" htmlFor={`check_${part?.part_id}`}>
                                            [id= {part?.id}] -    <span> {getT("Кол-во деталей")}: {part?.count}  </span>/
                                            <span > {getT("Кол-во услуг")}: {part?.count_service} </span>
                                        </label>
                                    </div>
                                </div>
                            })}
                        </div>
                        : <p className={'text-center text-black-50'}>{getT("Детали отсутствуют")}</p>}
                </div>

                {readOnly ? <></> : !!form_value?.part?.length && <div className="d-flex justify-content-center">
                    <Button onClick={handlerSubmitMark}>{getT("Отправить")}</Button>
                </div>
                }
            </div>
        </div>
    );
};

export default EdgingContainer;