import getT from "../getT";

export default function ({order_mark}) {
    let data = order_mark?.service?.reduce((acc, serv) => {
        let key_serv = `service_${serv.service_id}`
        let find_all_part = order_mark?.part_ready_services?.reduce((acc_part, part) => {
            let find_service = part?.service_ready?.find(e => Number(e?.service_id) === Number(serv.service_id));
            if (find_service) {
                acc_part.push({part_id: part?.part_id, count_service: find_service?.count, title_serv: serv?.name})
            }
            return acc_part
        }, [])

        return [...acc, ...find_all_part]

    }, [])?.sort((a, b) => Number(a.part_id) - Number(b.part_id))
    if (!data?.length) return ''
    let render_add = data
        ?.map(part => {
            return `
            <tr>
    <td>${part?.part_id}</td>

    <td>${part?.title_serv}</td>
    <td>${part?.count_service}</td>
</tr>`
        })?.join("")


    return `
      <div style="font-size: 0;  page-break-inside: avoid;">
        <table>
          <caption>${getT("Услуги")}</caption>
        <thead>
        <tr>
        <th>${getT("Деталь")}</th>
        <th>${getT("Услуга")}</th>
        <th>${getT("Кол-во услуги")}</th>
</tr>
</thead>
${render_add}
</table>
</div>
    
    `

}


