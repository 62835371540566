import React, {useEffect, useMemo} from 'react';
import Modal from "../MyModal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getGeneralCurrencies, getSalaryRatesCreateModal} from "../../../store/selectors/salary_project_selector";
import {toggleDefaultPlaneCreateModalAC, toggleRatesCreateModalAC} from "../../../store/actions/SalaryProject";
import {useForm} from "react-hook-form";
import Button from "../../ui/Button";
import {withLang} from "../../../hoc/withLang";
import {LS_USER_ID} from "../../../utils/constants";
import {addDefaultPlaneThunk} from "../../../store/thunks/salary_project/default_plane_thunk";
import {addSalaryRatesThunk} from "../../../store/thunks/salary_project/rates_thunk";

const SalaryRatesCreateModal = ({getT}) => {
    const dispatch = useDispatch();
    const rates_modal_state = useSelector(getSalaryRatesCreateModal);
    const general_currencies_state = useSelector(getGeneralCurrencies);
    const handlerClose = () => {
        dispatch(toggleRatesCreateModalAC({isOpen: false}));
        reset();
    }
    const handlerCreate = (data) => {
        dispatch(addSalaryRatesThunk(data, null))
    }
    const {register, handleSubmit, watch, reset} = useForm({});
    const isUpdate = useMemo(() => {
        return rates_modal_state?.form_values?.price_bad && rates_modal_state?.form_values?.price_good && rates_modal_state?.form_values?.price_super && rates_modal_state?.form_values?.currency;

    }, [rates_modal_state?.form_values])
    useEffect(() => {
        reset({...rates_modal_state?.form_values})
    }, [rates_modal_state?.form_values])
    if (!rates_modal_state.isOpen) {
        return null;
    }
    return (
        <Modal open={rates_modal_state.isOpen} onClose={handlerClose} size_modal_class={'modal-md'}
               title={isUpdate ? getT("Обновить") : getT("Создать")}>
            <form onSubmit={handleSubmit((data) => {
                handlerCreate(data)
            })}>
                <input {...register("service")} type={"hidden"} value={rates_modal_state.form_values.service}/>
                <input {...register("user")} type={"hidden"} value={localStorage.getItem(LS_USER_ID)}/>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{getT("Стоимость не выполненного плана")}</label>
                    <input {...register("price_bad", {
                        min: 0,
                        value: rates_modal_state.form_values.price_bad
                    })} className="form-control" type={"number"} required={true} step={0.01}/>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{getT("Стоимость нормального плана")}</label>
                    <input {...register("price_good", {
                        value: rates_modal_state.form_values.price_good
                    })} className="form-control" type={"number"}
                           min={Number(watch("price_bad")) + 0.01} required={true} step={0.01}/>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{getT("Стоимость перевыполнения  плана")}</label>
                    <input {...register("price_super", {
                        required: 'Empty',
                        value: rates_modal_state.form_values.price_super
                    })} className="form-control" type={"number"} min={Number(watch("price_good")) + 0.01}
                           required={true} step={0.01}/>
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{getT("Валюта")}</label>
                    <select {...register("currency", {
                        min: 1, value: rates_modal_state.form_values.currency
                    })} required={true} className="form-control">
                        {general_currencies_state?.data?.map(currency => <option
                            value={currency?.currency_id}>{currency?.name}</option>)}
                    </select>

                </div>

                <div className="text-center">
                    <Button type={"submit"}>
                        {/*<>{isUpdate ? getT("Обновить") : getT("Создать")}</>*/}
                        {getT("Отправить")}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default withLang(SalaryRatesCreateModal)