import React, {useMemo} from 'react';
import OrderFilter from "./OrderFilter";
import {useSelector} from "react-redux";
import {getGeneralGoodsServiceType} from "../../../store/selectors/salary_project_selector";
import {getClientsList, getOrderStatus} from "../../../store/selectors/main_selector";
import Card from "../../../components/ui/Card";
import OrderItem from "./OrderItem";
import Button from "../../../components/ui/Button";
import {LS_WORKER_ID} from "../../../utils/constants";

/**
 * Компонет отметки готовности улсг.
 * Включает отображение бриагады, фильтр, список заказов или заданый на производство
 * @param {object} ready_mark -
 * @param {function} getT -
 * @param {function} handlerGetOrders - получить список заказов
 * @param {function} handlerToggleSelectOrders - добавить убрать заказ из групповой отметки
 * @param {function} handlerGetPartForOrderProduction -Работа с одним заказом или заданием на производстве получит информацию по деталям
 * @returns  {jsx}  -
 */
const Orders = ({ready_mark, getT, handlerGetOrders, handlerToggleSelectOrders, handlerGetPartForOrderProduction, handlerGetPackingPartForOrderProduction, handlerDeletePackingOfOrder}) => {
    const worker_in_system_id = JSON.parse(localStorage.getItem(LS_WORKER_ID));
    const order_status_state = useSelector(getOrderStatus);
    const clients_state = useSelector(getClientsList);
    const general_goods_service_type = useSelector(getGeneralGoodsServiceType);
    //** услуги которые доступны для отметки бригады
    //::TODO дописать проверку на работника для отображения только его услуг
    const goods_service_type = useMemo(() => {
        if (!general_goods_service_type || !ready_mark?.equipment_worker_group_by_shift) return [];
        let availible_goods_type = ready_mark?.equipment_worker_group_by_shift?.map(item => item?.sevice_type_availible)?.flat()
        let uniq_goods_type_ids = [...new Set(availible_goods_type)]?.map(item => Number(item?.service_type));
        return general_goods_service_type?.data?.filter(item => uniq_goods_type_ids.includes(Number(item?.goods_service_type_id)));
    }, [general_goods_service_type, ready_mark])

    const onToggleSelectOrder = (order) => (event) => {
        event.stopPropagation();
        event.preventDefault();
        let is_find_select_orders = ready_mark?.selected_orders?.find(item => Number(item?.id) === Number(order?.id));
        let actual_select_orders = []
        if (is_find_select_orders) {
            actual_select_orders = ready_mark?.selected_orders?.filter(item => Number(item?.id) !== Number(order?.id))
        } else {
            actual_select_orders = [...ready_mark?.selected_orders, order]
        }
        handlerToggleSelectOrders(actual_select_orders)
    }

    return (<div className={"mt-3"}>
            <OrderFilter getT={getT} order_status={order_status_state} clients={clients_state}
                         goods_service_data={goods_service_type}
                         filter={ready_mark?.filter} handlerGetOrders={handlerGetOrders}/>

            <div className="card p-1 p-md-2 pd-lg-3">
                <div className="row read-mark-list-orders ">
                    {ready_mark?.orders?.map(order => {
                        let is_selected_order = !!ready_mark?.selected_orders?.find(item => Number(item?.id) === Number(order?.id));
                        return <div className="col-12 col-lg-6" key={`order_item_${order?.id}`}>
                            <Card title={<div className={"d-flex justify-content-between align-items-center "}>
                                <div className="form-check" onClick={(e) => e.stopPropagation()}>
                                    <input type="checkbox" className="form-check-input" id={`select_order_${order?.id}`}
                                           key={`order_check_${order?.id}_${is_selected_order}`}
                                           onChange={onToggleSelectOrder(order)}
                                           checked={is_selected_order}/>
                                    <label className="form-check-label"
                                           htmlFor={`select_order_${order?.id}`}> {getT("Заказ")} #{order?.id}</label>
                                </div>
                                {!!Number(worker_in_system_id) ? <></>:
                                    <a target={'_blank'} href={`/orders/detail/${order?.id}`} className={"fs-14"}><i
                                        className={'far fa-eye'}/></a>}
                            </div>}
                                  isCollapse={true} bodyChildren={<OrderItem order={order} getT={getT}
                                                                             shift_form_values={ready_mark?.shift_form_values}
                                                                             filter={ready_mark?.filter}
                            />}
                                  footerChildren={
                                      !!order?.id && <div className={"d-flex justify-content-end flex-wrap"} style={{gap: '10px'}}>
                                      <Button className={"btn-danger fs-14"} onClick={handlerDeletePackingOfOrder(order.id)}>{getT("Удалить упаковку")}</Button>
                                      <Button className={"btn-info fs-14"} onClick={handlerGetPackingPartForOrderProduction(order.id)}>{getT("Отметка упаковки")}</Button>
                                          <Button className={"btn-dark fs-14"}
                                                  onClick={handlerGetPartForOrderProduction(order.id)}>{getT("Отметка по детально")}</Button>
                                      </div>
                                  }
                            />

                        </div>
                    })}
                </div>
            </div>
        </div>
    );
};

export default Orders;