import React from 'react';
/**
 * .
 *
 * @param {function}  getT- перевод
 * @param {function}  handleChangeMainFirmRequest- изменить тип получаемых заказов
 * @param {boolean} isMainFirmRequestType - определеный тип заказов
 * @returns  {JSX.Element}  -
 */
const OrderSupplyTab = ({getT, handleChangeMainFirmRequest, isMainFirmRequestType}) => {
    return (
        <ul className="nav nav-tabs justify-content-center">
            <li className="nav-item">
                    <span className={`nav-link  cursor-pointer ${isMainFirmRequestType ? '' : 'active'}`}
                          onClick={() => handleChangeMainFirmRequest(0)}>{getT("Мои")}</span>
            </li>
            <li className="nav-item">
                    <span className={`nav-link  cursor-pointer ${isMainFirmRequestType ? 'active' : ''}`}
                          onClick={() => handleChangeMainFirmRequest(1)}>{getT("Партнёру")}</span>
            </li>
        </ul>
    );
};

export default OrderSupplyTab;