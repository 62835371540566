import React, {useMemo, useState} from 'react';
import {useDetectClickOutside} from "react-detect-click-outside/dist";
import IconArrow from "../../Icons/IconArrow";
import IconDate from "../../Icons/IconDate";
import IconDownload from "../../Icons/IconDownload";
import ReactHtmlParser from 'react-html-parser';
import {checkEndsWithAnyExtensions} from "./helper";

const NewItem = ({item, url_img, getT}) => {
    const [isOpen, setIsOpen] = useState(false);
    const cNameHeader = `card_header ${!isOpen ? 'border-none' : ' '} `;
    const ref = useDetectClickOutside({onTriggered: () => setIsOpen(false)})
    const updated_at = useMemo(() => {
        if (!item.hasOwnProperty('updated_at')) return null
        const dateObj = new Date(item.updated_at);
        const month = dateObj.getUTCMonth() + 1; // months from 1-12
        const day = dateObj.getUTCDate();
        const year = dateObj.getUTCFullYear();
        let month_ = month < 10 ? `0${month}` : month
        return `${day}.${month_}.${year}`;
    }, [item])
    return (
        <div className={"new-item card open"} ref={ref}>
            <div className={cNameHeader} onClick={() => setIsOpen(!isOpen)}>
                <span className="title">
                          <span className={"mb-0"}>{item.name}</span>
                    {updated_at &&
                        <span className="time d-flex items-center"><IconDate style={{marginRight: '3px'}}/>{updated_at}</span>}
                </span>

                <IconArrow className={isOpen ? 'arrow-open' : ''}/>
            </div>
            {isOpen && <div className="card_body">
                <div className="content">
                    {ReactHtmlParser(`${item.text}`)}
                </div>
                {item.hasOwnProperty('files') && !!item.files.length && <div className="new-item-section">
                    <h5>{getT('Файлы')}</h5>
                    <ul>
                        {item.files.map(file => <li style={{margin: '4px 0px'}}><a href={file.url} download={true} target={'_blank'}
                                                       className={"d-flex items-center"}>
                            {checkEndsWithAnyExtensions(file.name) ? <img src={file.url} width={'120px'} height={'70px'}/> : file.name}
                            <IconDownload
                            style={{marginLeft: "5px"}}/></a>
                        </li>)}
                    </ul>
                </div>}
                {item.hasOwnProperty('links') && !!item.links.length && <div className="new-item-section">
                    <h5>{getT('Ссылки')}</h5>
                    <ul>
                        {item.links.map(item => <li><a href={item.link} download={true} target={'_blank'}
                                                       className={"d-flex items-center"}>{item.name} <IconDownload
                            style={{marginLeft: "5px"}}/></a>
                        </li>)}
                    </ul>
                </div>}

                {item.hasOwnProperty('social_links') && !!item.social_links.length && <div className="new-item-section">
                    <h5>{getT('Соц. сети')}</h5>
                    <ul className={'list-none social-links'}>
                        {item.social_links.map(social => {
                            let {social_media, link} = social;
                            return <li className={"item"}>
                                <a href={social_media.link} className="social-media_link" target={'_blank'}>
                                    <img src={`${social_media.logo}`} alt=""/>
                                </a>
                                {social_media.base64_qr &&
                                    <a href={link} target={"_blank"} className="base64_qr_link">
                                        {/*<img src={`data:image/svg+xml;base64,${social_media.base64_qr}`} alt=""/>*/}
                                        <img src={`${social_media.base64_qr}`} alt="" className={"base64"}/>
                                    </a>
                                }
                                {/*<a href={link} target={"_blank"} className="">{item.name}</a>*/}
                            </li>
                        })}
                    </ul>
                </div>}
            </div>
            }

        </div>
    );
};

export default NewItem;