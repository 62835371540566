
export const  KEYS_NAMING_OPT_STORE  = {
    client: {
        opt: 'client',
        opt_key_info: 'client',
        id: 'client'
    },
    manager: {
        opt: 'user',
        opt_key_info: 'users',
        id: 'user'
    },
    order: {
        opt: 'orders',
        opt_key_info: 'orders',
        id: 'order_id'
    }
}

export const FULL_FIELD_NAME_BY_KEY = {
    average_depth_mm: {
        title: 'Средняя толщина детали',
        unit: null,
        is_has_action_modal_filter_data_by_role: false,
    },
    details_on_sheet_average: {
        title: 'Количество деталей на листе',
        unit: null,
        is_has_action_modal_filter_data_by_role: false,
    },
    orders_per_day_average: {
        title: 'Количество заказов в день',
        unit: null,
        is_has_action_modal_filter_data_by_role: false,
    },
    square_of_one_detail: {
        title: 'Средняя площадь детали',
        unit: null,
        is_has_action_modal_filter_data_by_role: false,
    },
    count_detail: {
        title: 'Количество деталей',
        unit: null,
        is_has_action_modal_filter_data_by_role: true
    },
    lines_count: {
        title: 'Количество строк в заказах',
        unit: null,
        is_has_action_modal_filter_data_by_role: true
    },
    orders_count: {
        title: 'Количество заказов',
        unit: null,
        is_has_action_modal_filter_data_by_role: true
    },
    perimetr: {
        title: 'Периметр всех деталей в заказах',
        unit: 'м',
        is_has_action_modal_filter_data_by_role: true
    },
    square: {
        title: 'Площадь всех деталей в заказах',
        unit: ' м2',
        is_has_action_modal_filter_data_by_role: true
    },
    volume: {
        title: 'Объём всех заказов',
        unit: ' м3',
        is_has_action_modal_filter_data_by_role: true
    },
}