import {instances, URI_API_EXTTUNELL} from "../Salary/setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

/**
 * Базовые справочники
 * @method get  - список пользователей
 */
const order_production_update = "order_production_update"
export const CommonOrderAPI = {
    update_setting_in_order(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: order_production_update
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
}