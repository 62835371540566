import React, {useState} from 'react';
import {useDetectClickOutside} from "react-detect-click-outside/dist";
/**
 * ItemShiftPlane. План
 *
 * @param {object} default_plane_find - план
 * @param {function}  getT- перевод
 * @param {function}  handlerDelete- удалить план
 * @param {function}  handlerUpdatePlane- обновить план
 * @param {boolean}  is_available_access_role- естил ли права доступа к crud
 * @returns  {}  -
 */
const ItemShiftPlane = ({
                            default_plane_find,
                            getT,
                            handlerUpdatePlane,
                            handlerDelete,
                            is_available_access_role,curretn_palane
                        }) => {
    const [isShow, setIsShow] = useState(false);
    const ref = useDetectClickOutside({onTriggered: () => setIsShow(false)})
    return (
        <>
            <li className={`list-item ${isShow && 'border-0 '}`} ref={ref}>
                <div className={'d-flex align-items-center justify-content-between'}>
                    <span className="title">{default_plane_find?.name}</span>
                    <span className="shift-action">
                        <i className={`cursor-pointer fas fa-angle-${isShow ? 'up' : 'down'}`}
                           onClick={() => setIsShow(!isShow)}></i>
                        {is_available_access_role && <>
                            <i className="fas fa-edit text-success cursor-pointer" onClick={handlerUpdatePlane}></i>
                            <i className="fa fa-trash text-danger cursor-pointer" onClick={handlerDelete}></i>
                        </>}
                    </span>
                </div>

                {isShow &&
                    <ul className="list-group mt-2">
                        <li className="list-group-item">
                            <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                <strong>{getT("Показатель не выполненного плана")}:</strong> <strong
                                className={'text-danger'}>{default_plane_find?.actual_plan?.count_bad}</strong>
                            </p>
                            <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                <strong>{getT("Показатель нормального плана")}:</strong> <strong
                                className={'text-warning'}>{default_plane_find?.actual_plan?.count_normal}</strong>
                            </p>
                            <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                <strong>{getT("Показатель перевыполненного плана")}:</strong> <strong
                                className={'text-success'}>{default_plane_find?.actual_plan?.count_super}</strong>
                            </p>
                            <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                <strong className={"text-dark"}>{getT("Показатель фактического результата")}:</strong> <strong
                                className={''}>{curretn_palane?.count_fact}</strong>
                            </p>
                            <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                <strong className={"text-dark"}>{getT("Комментарий")}:</strong> <strong
                                className={''}>{curretn_palane?.comment}</strong>
                            </p>
                        </li>
                    </ul>
                }
            </li>
        </>
    );
};

export default ItemShiftPlane;