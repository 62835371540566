import React, {useEffect, useMemo, useState} from 'react';
import PageTitle from "../../components/PageTitle/PageTitle";
import ContainerContent from "../../components/ui/ContainerContent";
import {getWSConnectionClient} from "../../store/selectors/wholesalary_selector";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {openConfirmRequestWsAndGetClientsByFirmModal} from "../../store/thunks/wholesale/ws_common_thunk";
import ClientTable from "./components/ClientTable";
import WsConnectionClientModal from "./components/Modal/WSConnectionClientModal";
import {wholesaleToggleConnectionRequestCompanyClientModal} from "../../store/actions/WholesaleActions";
import {
    deleteWSConnectionClientThunk,
    getWSConnectionClientThunk,
    setWSConnectionClientThunk
} from "../../store/thunks/wholesale/connection_client_thunk";
import {Link} from "react-router-dom";
import {LS_FIRM} from "../../utils/constants";

const TAB_NAMING = {
    wholesale: {
        title: "Оптовики",
        key: "wholesale"
    },
    partner: {
        title: "Партнёры",
        key: "partner"
    }
}
//заявка на подключение фирмы
const WsCompanyConnectionRequestPage = ({getT}) => {
    const dispatch = useDispatch();
    const current_firm_id = Number(localStorage.getItem(LS_FIRM));
    const [tab, setTab] = useState(TAB_NAMING.wholesale?.key)
    const connection_clients = useSelector(getWSConnectionClient, shallowEqual);
    useEffect(() => {
        dispatch(getWSConnectionClientThunk())
    }, []);

    const handlerOpenConfirmRequestModal = (request) => () => {
        dispatch(openConfirmRequestWsAndGetClientsByFirmModal(request))
    }
    const handlerCloseConfirmRequestModal = () => {
        dispatch(wholesaleToggleConnectionRequestCompanyClientModal({isOpen: false, data: null}))
    }
    const handlerCreateConnection = (data) => {
        dispatch(setWSConnectionClientThunk(data))
    }
    const handlerCancelRequestWS = (id) => () => {
        if (!id) return
        dispatch(deleteWSConnectionClientThunk({id: id}))
    }
    const connection_clients_tab_direction = useMemo(() => {
        if (!Array.isArray(connection_clients) || !connection_clients) return null
        return connection_clients?.reduce((acc, item) => {
            let firm_main = Number(item?.main_firm);
            let key = firm_main !== current_firm_id ? TAB_NAMING.wholesale?.key : TAB_NAMING.partner?.key;
            acc[key].push(item);
            return acc;
        }, {[TAB_NAMING.wholesale?.key]: [], [TAB_NAMING.partner?.key]: []})
    }, [connection_clients]);

    return (
        <ContainerContent>

            <PageTitle title={"ws_connection_client"}/>

            <div className="container-fluid ">
                <div className="row">
                    <div className="col-12">
                        <div className="mb-3 d-flex flex-wrap justify-content-end" style={{gap: '15px'}}>
                            <Link to={"/ws-companies"} className="btn btn-outline-info">
                                {getT("Добавить оптовые фирмы")}
                            </Link>

                        </div>
                    </div>
                    <div className="col-12 col-md-12">
                        <div className="card py-4 px-4">
                            <ul className="nav nav-tabs justify-content-center border-bottom-0" id="myTab" role="tablist">
                                {Object.keys(TAB_NAMING)?.map(key => {
                                    let item = TAB_NAMING?.[key];
                                    let is_active = key === tab;
                                    return <li className="nav-item" role="presentation">
                                        <button className={"nav-link" + (is_active ?  " active text-primary " : " ")}
                                                onClick={() => setTab(key)}>
                                            {getT(item?.title)}
                                        </button>
                                    </li>
                                })}


                            </ul>
                            {(Array.isArray(connection_clients) && !!connection_clients?.length) &&
                                <ClientTable connection_clients={connection_clients_tab_direction[tab]}
                                             getT={getT}
                                             handlerOpenConfirmRequestModal={handlerOpenConfirmRequestModal}
                                             handlerCancelRequestWS={handlerCancelRequestWS}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
            <WsConnectionClientModal getT={getT} handlerCreateConnection={handlerCreateConnection}
                                     handlerClose={handlerCloseConfirmRequestModal}/>
        </ContainerContent>
    );
};

export default WsCompanyConnectionRequestPage;