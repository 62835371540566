import React, {useEffect} from 'react';
import {useFieldArray, useForm} from "react-hook-form";
import Button from "../../../../ui/Button";

const BonusFineUpdateForm = ({currency, read_mark, getT, handlerSubmitForm, initial_form}) => {
    let day = new Date(initial_form?.date_when_event_take_place)
    let today = day.toISOString().split('T')[0];
    const INITIAL_FILED_ITEM = {
        orders_goods: initial_form?.order_goods ?? '',
        worker: initial_form?.worker ?? '',
        date_when_event_take_place: today,
        summ: initial_form?.summ, currency: initial_form?.currency,
        comment: initial_form?.comment
    }

    const {register, control, handleSubmit, formState: {errors}, setError, reset, watch, setValue} = useForm({
        defaultValues: {
            ...INITIAL_FILED_ITEM
        },
    });

    let order_goods_watch = watch(`orders_goods`);

    useEffect(() => {
        setValue('worker', '')
    }, [order_goods_watch]);

    const read_mark_worker = read_mark?.find(item => Number(item?.orders_goods) === Number(order_goods_watch))?.worker_group_list ?? null;

    const onSubmit = (data) => {
        let time = new Date().toLocaleTimeString();
        handlerSubmitForm({
            ...data,
            date_when_event_take_place: `${data.date_when_event_take_place} ${time}`
        })

    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-2 text-right">

            </div>

            <div key={''} className="row align-items-center item-filed-bonuses-fines">
                <div className="col-12 col-lg-6">
                    <div className={"form-group"}>
                        <label className="form-check-label" htmlFor="inlineCheckbox1">{getT("Услуга")}</label>
                        <select className={"form-control"}  {...register(`orders_goods`)}
                                defaultValue={INITIAL_FILED_ITEM.orders_goods} required={true}>
                            <option value="">---- ------</option>
                            {read_mark?.map(item => {
                                return <option
                                    value={item?.orders_goods}>[{item?.order}] {item?.service_name}</option>
                            })}
                        </select>

                    </div>
                </div>


                <div className="col-12 col-lg-6">
                    <div className={"form-group"}>
                        <label className="form-check-label" htmlFor="inlineCheckbox1">{getT("Работник")}</label>
                        <select className={"form-control"}  {...register(`worker`)}
                                defaultValue={INITIAL_FILED_ITEM.worker} required={true}>
                            <option value="">---- ------</option>
                            {read_mark_worker?.map(item => {
                                return <option
                                    // disabled={!!fields?.find(e => Number(e?.worker) === Number(item?.worker))}
                                    value={item?.worker}> {item?.name} - {item?.percentage}%</option>
                            })}
                        </select>

                    </div>
                </div>

                <div className="col-12 col-lg-4">
                    <div className="form-group">
                        <label className="form-check-label">{getT("Дата применения")}</label>
                        <input {...register(`date_when_event_take_place`, {
                            value: INITIAL_FILED_ITEM.date_when_event_take_place
                        })} className="form-control" type={"date"} required={true}
                               min={today.split('T')[0]}
                        />
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className="form-group">
                        <label className="form-check-label">{getT("Сумма")}</label>
                        <input {...register(`summ`, {
                            value: INITIAL_FILED_ITEM.summ
                        })} className="form-control" type={"number"} required={true} min={1}/>
                    </div>
                </div>
                <div className="col-12 col-lg-4">
                    <div className={"form-group"}>
                        <label className="form-check-label">{getT("Валюта")}</label>
                        <select className={"form-control"}  {...register(`currency`)}
                                defaultValue={INITIAL_FILED_ITEM.currency} required={true}>
                            <option value="">---- ------</option>
                            {currency?.map(item => {
                                return <option
                                    value={item?.currency_id}> {item?.code}</option>
                            })}
                        </select>

                    </div>
                </div>
                <div className="col-12">
                    <div className={"form-group"}>
                        <label className="form-check-label"
                               htmlFor="inlineCheckbox1">{getT("Комментарий")}</label>
                        <textarea className={"form-control"}  {...register(`comment`)}
                                  defaultValue={INITIAL_FILED_ITEM.comment} required={true}></textarea>

                    </div>
                </div>
            </div>

            <div className=" mt-3 d-flex justify-content-center">

                <Button className={"btn-primary mx-2"}>{getT("Отправить")}</Button>
            </div>

        </form>
    )
        ;
};

export default BonusFineUpdateForm;