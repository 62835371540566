import React from 'react';
import {statusOrdersHelpers} from "../../../../utils/statusOrders";


const NeedItemRow = ({getT, openCreateModalOrderGoodsId, item}) => {
    let badgeStatus = statusOrdersHelpers.getClassNameByStatusId(item?.status?.id)
    return (
        <tr>
            <td data-label={getT("Название")}>
                [{item?.order_id} | {item?.orders_goods_id}] {" "} {item?.order_name}
            </td>
            <td data-label={getT("Статус")}><span className={badgeStatus}>{item?.status?.name}</span></td>
            <td data-label={getT("Клиент")}>{item?.client?.name}</td>
            <td data-label={getT("Пользователь")}>{item?.user?.name}</td>
            <td data-label={getT("Товар")}>

                {!!Number(item?.goods.part) && <small className={"text-cyan"}>{getT("Плитный материал")} </small>}
                {!!Number(item?.goods.band) && <small className={"text-primary"}>{getT("Кромка")} </small>}
                {!!Number(item?.goods.tovar) && <small className={"text-fuchsia"}>{getT("Товар")} </small>}
                <div className=""> [{item.goods?.id}] {item.goods?.name} </div>
            </td>
            <td data-label={getT("Количество")}>
                {item?.count}
            </td>
            <td data-label={getT("Цена")}>
                {item?.price} {item?.currency?.name}
            </td>
            <td data-label={getT("Действие")}>
                <span className=" btn btn-outline-success btn-sm" title={getT("Создание новой потребности")}
                      onClick={() => openCreateModalOrderGoodsId(item)}>
                <i className="fa fa-plus  cursor-pointer"></i>
                </span>
            </td>
        </tr>
    );
};

export default NeedItemRow;