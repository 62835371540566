import getT from "../getT";
import {LS_LANG} from "../../constants";

export default function ({bands}){
    let lang = localStorage.getItem('lang')
    return `
    <table>
     <caption>${getT("Кромки")}: </caption>
  <thead>
    <tr>
      <th>#</th>
      <th>${getT("Название")}</th>
      <th>${getT("Ширина")}</th>
      <th>${getT("Толщина")}</th>
      
    </tr>
  </thead>
  <tbody>
  ${bands?.map((item, indx)=>{
       let name = item?.translate?.[lang] ?? item?.name;
        return `
       <tr>
          <td>${indx+1}</td>
          <td>${name}</td>
          <td>${item?.y}</td>
          <td>${item?.z}</td>
          
    </tr>
      `
    })?.join('')}
  </tbody>
</table>
    `
}