import React, {useEffect} from 'react';
import Modal from "../MyModal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getEquipmentWorkerGroup, getShiftWorkerGroupModal} from "../../../store/selectors/salary_project_selector";
import {toggleShiftWorkerGroupModalAC} from "../../../store/actions/SalaryProject";
import {INITIAL_SHIFT_WORKER_GROUP_FORM} from "../../../store/reducers/SalaryProjectReducer";
import {useFieldArray, useForm} from "react-hook-form";
import Button from "../../ui/Button";
import {createUpdateWorkerGroupThunk} from "../../../store/thunks/salary_project/worker_group_thunk";
import {LS_WORKER_ID} from "../../../utils/constants";


const WorkerGroupModal = ({getT, default_plane}) => {
    const dispatch = useDispatch();
    let worker_system_id = Number(localStorage.getItem(LS_WORKER_ID))
    const modal_state = useSelector(getShiftWorkerGroupModal);
    const eq_worker_group_state = useSelector(getEquipmentWorkerGroup)
    const handlerClose = () => dispatch(toggleShiftWorkerGroupModalAC({
        isOpen: false,
        form_values: INITIAL_SHIFT_WORKER_GROUP_FORM
    }));
    const {register, control, handleSubmit, formState: {errors}, setError, reset} = useForm({
        defaultValues: {
            error_percentage: null,
            // Set your initial values for the array here
            workers: modal_state?.form_values?.fields,
        },
    });
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'workers', // name of the field array
    });
    useEffect(() => {
        reset({workers: modal_state?.form_values?.fields})
    }, [modal_state?.form_values?.fields])

    const onSubmit = (data) => {
        let workers = data?.workers?.map(item => ({id: item._id, percentage: item.percentage}));
        let percentage_total = workers?.reduce((accumulator, expense) => {
            return Number(accumulator) + Number(expense.percentage);
        }, 0);
        if (percentage_total > 100 || percentage_total < 100) {
            setError("error_percentage", {
                type: "focus",
                message: getT("Сумма процентов членов бригады всегда должна быть равна 100%.")
            }, {shouldFocus: true});
            return
        }
        dispatch(createUpdateWorkerGroupThunk({
            shift: modal_state.form_values.shift,
            workers: workers
        }, () => {
            reset()
        }))
    }
    let eq_worker_group_state_filtered = eq_worker_group_state?.data?.some(item=> fields?.some(worker=> Number(worker?._id) === Number(item?.equipment_worker_id)))
    return (
        <Modal open={modal_state.isOpen} size_modal_class={"modal-md"} onClose={handlerClose}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-2 text-right">

                </div>
                {fields.map((field, index) => {
                    let is_display_remove = (!!worker_system_id ? worker_system_id !== Number(field._id) : fields?.length !== 1)
                    return <div key={field.id} className="row align-items-center">
                        <div className="col-8">
                            <div className={"form-group"}>
                                <label className="form-check-label" htmlFor="inlineCheckbox1">{getT("Работник")}</label>
                                <select className={"form-control"}  {...register(`workers.${index}._id`)}
                                        defaultValue={field._id} required={true}>
                                    {eq_worker_group_state?.data?.filter(item=> Number(item?.is_working) === 1)?.map(item => {
                                        let service_available = default_plane
                                            ?.filter(plane =>
                                                item.sevice_type_availible?.find(service => Number(plane?.goods_service_type_id) === Number(service.service_type))
                                            )
                                            ?.map(plane => plane?.name)?.join(', ')?.slice(0, 100)
                                        return <option disabled={!!fields?.find(worker=> Number(worker?._id) === Number(item?.equipment_worker_id))}
                                            value={item?.equipment_worker_id}>{item?.name} ({service_available})</option>
                                    })}
                                </select>

                            </div>
                        </div>
                        <div className="col-3">
                            <div className={"form-group"}>
                                <label className="form-check-label" htmlFor="inlineCheckbox1">%</label>
                                <input className={"form-control"} step={1}
                                       {...register(`workers.${index}.percentage`)}
                                       defaultValue={field.percentage} required={true}
                                />
                            </div>
                        </div>
                        <div className="col-1 text-center">

                            {/*{!!worker_system_id && (worker_system_id === Number(field._id) ?*/}
                            {/*    <></> :*/}
                                {is_display_remove &&
                                    <i className={'fa fa-trash text-danger cursor-pointer'}
                                       onClick={() => remove(index)}></i>
                            }
                        </div>
                    </div>
                })}
                <div className="">
                    <input className={'d-none'} type="text" {...register("error_percentage")}/>
                    {errors.error_percentage?.message &&
                        <p className={"text-danger"}>{errors.error_percentage.message}</p>}
                </div>
                <div className=" mt-3 d-flex justify-content-center">
                    <Button type={"button"} className={"btn-outline-success mx-2"}
                            onClick={() => append({_id: null, percentage: null})}>{getT("Добавить")}</Button>
                    <Button className={"btn-primary mx-2"}>{getT("Создать")}</Button>
                </div>

            </form>
        </Modal>
    );
};

export default WorkerGroupModal;