import React, {useState} from 'react';
import IconArrow from "../../../Icons/IconArrow";
import Button from "../../ui/Button";

const styleTextarea = {
    border: `1px solid #cdccd7`,
    width: `100%`
}

const Comment = ({handlerSubmit, getT}) => {
    const [comment, setComment] = useState('');
    const onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handlerSubmit(comment, () => setComment(''))
    }
    const handlerChange = (e) => setComment(e.target.value);
    const [isOpen, setIsOpen] = useState(false)
    return (
        <form onSubmit={onSubmit} className={'mt-2'}>
            <label onClick={() => setIsOpen(!isOpen)} className={"comment-accordion-toggle"}>{getT('Комментарий')}
                <IconArrow className={`${!isOpen ? 'arrow-down' : ' arrow-top '} ml-2`} /></label>
            {isOpen && <>
                <div className="user-data" style={{marginTop: "6px"}}>
                    <div className={'field form-group'}>
                        <textarea onChange={handlerChange} className={'form-control'} style={styleTextarea}
                                  rows={4}>{comment}</textarea>
                    </div>
                </div>
                <div className="d-flex justify-content-center">
                    <Button className={"btn btn-primary"} type={'submit'}>{getT('Отправить')}</Button>
                </div>
            </>}
        </form>
    );
};

export default Comment;