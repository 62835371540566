import getT from "../getT";

export default function ({parts}){
    const total_counts = parts?.length;

    return `
    <table>
      <caption>${getT("Остатки")}:   </caption>
        <thead>
    <tr>
      <th >#</th>
      <th>${getT("Длина")}</th>
        <th>${getT("Ширина")}</th>
      <th >${getT("Кол-во")}</th>
    </tr>
  </thead>
        <tbody>
  ${parts?.map((part, index) => {
        return `
            <tr>
                <td>${index+1}</td>
                <td>${part?.length}</td>
                <td>${part?.width}</td>
                <td>1</td>
            </tr>
        `
    })?.join('')}
  <tr>
  <th colspan="2" class="b-left-hide b-bottom-hide"></th>
    <th colspan="1">${getT("Всего")}</th>
    <th colspan="1"> ${total_counts}</th>
</tr>
     </table>
    `
}