import React, {useMemo} from 'react';
import {useSelector} from "react-redux";
import {getDefaultPlane} from "../../../../../store/selectors/salary_project_selector";
import {useForm} from "react-hook-form";
import {LS_USER_ID} from "../../../../../utils/constants";
import Button from "../../../../ui/Button";

const ShiftPlaneFormCu = ({getT, onSubmitCallback, form_values = null}) => {
    const default_plane_state = useSelector(getDefaultPlane);
    const default_plane_actual = useMemo(() => {
        if (!default_plane_state?.data?.length) {
            return []
        }
        return default_plane_state?.data?.filter(item => !item?.actual_plan?.error)
    }, [default_plane_state])
    const {register, handleSubmit} = useForm({
        values: {
            shift: form_values?.shift,
            service_type: form_values?.service_type ?? null,
            count_plan: form_values?.count_plan ?? null,
            comment: form_values?.comment ?? null,
            salary_shift_plan_id: form_values?.salary_shift_plan_id ?? null,

        }
    });
    let isUpdate = form_values.service_type && form_values.count_plan
    return (
        <form onSubmit={handleSubmit((data) => {
            onSubmitCallback(data)
        })}>
            <input {...register("shift")} type={"hidden"}/>
            <input {...register("salary_shift_plan_id")} type={"hidden"}/>
            <input {...register("user")} type={"hidden"} value={localStorage.getItem(LS_USER_ID)}/>
            <div className="form-group">
                <label htmlFor="exampleInputEmail1">{getT("Плановое количество")}</label>
                <input {...register("count_plan")} className="form-control" type={"number"} required={true} min={0}/>
            </div>
            {/*если тип плана  приходит то нужно его скрывать так как это поле не редактируеться*/}
            {form_values.service_type ?
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{getT("План")}: {default_plane_actual?.find(plane=> +plane?.goods_service_type_id == +form_values.service_type )?.name}</label>
                </div>
                :<div className="form-group">
                <label htmlFor="exampleInputEmail1">{getT("Выберете план")}</label>
                <select className="form-control" {...register("service_type", {})}>
                    {default_plane_actual?.map(plane => <option
                        value={plane?.goods_service_type_id}>{plane?.name}</option>)}
                </select>
            </div>}
            <div className="form-group">
                <label htmlFor="exampleInputEmail1">{getT("Комментарий")}</label>
                <textarea  {...register("comment", {})} cols="10" rows="3" className={"form-control"}></textarea>
            </div>
            <div className="text-center">
                <Button type={"submit"}><>{isUpdate ? getT("Обновить") : getT("Создать")}</>
                </Button>
            </div>
        </form>
    );
};

export default ShiftPlaneFormCu;