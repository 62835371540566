import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';

/// Admin LTE Plugins
import './plugins/fontawesome-free/css/all.min.css';
import './plugins/fontawesome-free/css/v4-shims.min.css';
import './plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css';
import './plugins/icheck-bootstrap/icheck-bootstrap.min.css';
import './plugins/jqvmap/jqvmap.min.css';
import './css/adminlte.min.css';
import './plugins/overlayScrollbars/css/OverlayScrollbars.min.css';
import './plugins/daterangepicker/daterangepicker.css';
import './plugins/summernote/summernote-bs4.min.css';
///

import AppContainer from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import { BrowserRouter as Router } from "react-router-dom";
import {Provider} from "react-redux";
import ReactGA from "react-ga4";
ReactGA.initialize('G-GLCJ9VM9HZ', {
    gaOptions: {
        debug_mode: true,
    },
    gtagOptions: {
        debug_mode: true,
    },
})
ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Provider store={store} >
                <AppContainer />
            </Provider>
        </Router>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
