import getT from "../getT";
import bot_tg from "../../image/bot_tg.jpg"
import bot_vb from "../../image/bot_vb.jpg"

export default function ({order, date = ''}) {
    return `
    <hr/>
    <p> [${order.order}] ${getT("Заказ составил(а)")} _______________:</p>
          <hr/>  
          <p>${getT("Заказ принят верно. С деталировкой и обработками деталей согласен. Претензий не имею")} ________________.
          <br/>
            [${date ?? ''}]
    </p>
      <hr/>  
       <table>
       <thead>
    <tr>
      <th scope="col">Telegram bot</th>
      <th scope="col">Viber bot</th>
    </tr>
  </thead>
  <tbody style="margin-bottom: 50px">
    <tr>
      <th><img src="${bot_tg}"/></th>
      <td><img src="${bot_vb}"/></td>
      </tr>
      </tbody>
</table>
    `
}