import React from 'react';
import {getAllIdsChildrenByRootFolder} from "../helper-material-selected";
import FolderItem from "./FolderItem";

const FolderWrap = ({tree, handlerChange,formik_values }) => {

    return ( <>


                <div className={'folder-wrap'}>
                    {tree?.map(item => {
                        let is_has_children = item?.children?.length;
                        let child_value_ids = getAllIdsChildrenByRootFolder(item)
                        let find_current_id = formik_values?.some(id => Number(id) === Number(item.material_type_id))
                        let filtered_children_ids = formik_values?.filter(id => child_value_ids.some(child_id => Number(child_id) === Number(id)));
                        let checked = is_has_children ?
                            filtered_children_ids.length === child_value_ids.length :
                            find_current_id
                        return <div className={'folder-root'}>
                            <div className="field field-root" key={item.material_type_id}>
                                <label className={'form-input-checkbox-label'} style={{justifyContent: "flex-start"}}
                                    // htmlFor={`band_${item.material_type_id}`}
                                >
                                    <input type="checkbox"
                                        // id={`tree${item.material_type_id}`}
                                           className={"form-input-checkbox"}
                                           onChange={(e) => {
                                               let checked = e.target.checked;
                                               let child_ids = getAllIdsChildrenByRootFolder(item)
                                               if (child_ids?.length) {
                                                   child_ids.push(Number(item.material_type_id))
                                                   handlerChange(child_ids, checked)

                                               } else {
                                                   handlerChange([Number(item.material_type_id)], checked)
                                               }
                                           }}
                                           checked={checked}
                                           value={item.id}
                                        // onChange={()=>handlerToggleBands(item)}
                                        // disabled={!!is_disabled}
                                    />
                                    <span>{item?.name}</span>
                                </label>
                            </div>
                            <FolderItem item={item} formik_values={formik_values} handlerChange={handlerChange}/>
                        </div>
                    })}
                </div>

        </>
    );
};

export default FolderWrap;