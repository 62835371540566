import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {useForm} from "react-hook-form";
import {LS_USER_ID} from "../../../utils/constants";
import Button from "../../ui/Button";
import {useDispatch} from "react-redux";
import Modal from "../MyModal/Modal";
import {closeShiftThunk} from "../../../store/thunks/salary_project/shifh-thunk";

const SELECT_FINISH_STATUS_PLANE = [
    {id: 'bad', value: 'bad', title: "не выполнено"},
    {id: 'good', value: 'good', title: "нормально"},
    {id: 'super', value: 'super', title: "перевыполнено"},
]

const SalaryCloseShiftModal = forwardRef(({shift, getT, refModal}) => {
    const [isOpenModal, setToggleModal] = useState(false);
    const [is_rerender, setIsRerender] = useState(false)
    const dispatch = useDispatch()
    let day = new Date()
    let today = day.toISOString().split('T')[0];
    let time = `${day.getHours() >= 10 ? day.getHours() : `0${day.getHours()}`}:${day.getMinutes() >= 10 ? day.getMinutes() : `0${day.getMinutes()}`}`;

    const {register, handleSubmit, reset, setValue, watch, getValues} = useForm();
    let defaultValues = {
        date: today,
        time: time,
        koef_plan_from_good_to_super: null,
        plans: shift?.plans?.map(item => {
            return {
                id: item?.salary_shift_plan_id,
                initial_status_for_payment: item?.real_status_by_numbers,
                status_for_payment: item?.real_status_by_numbers,
                comment: item?.comment ?? null,
                is_comment: 0

            }
        })
    }

    useImperativeHandle(refModal, () => {
        return {
            open() {
                setToggleModal(true)
                // inputRef.current.focus();
            }
        };
    }, []);
    const handlerSendCloseSift = (data) => {
        let {date, time, plans = [], ...reset} = data

        let _api_data = {
            user: localStorage.getItem(LS_USER_ID),
            shift: shift.salary_shift_id,
            date_when_must_be_payed: `${date} ${time}:00`,
            plans: plans?.filter(item=> item?.id)?.map(item => {
                let pay = {
                    id: item.id,
                    status_for_payment: item?.status_for_payment
                }
                if (item?.is_comment && item?.comment) {
                    return {...pay, comment: item?.comment}
                }
                return pay
            }),
            ...reset
        }
        dispatch(closeShiftThunk(_api_data, handlerCloseModal))
    }
    // Function to update status and comment for a specific plan
    const updatePlanStatus = (index) => (e) => {
        let status = e.target.value;
        const initial_plane = defaultValues.plans?.[index] ?? null
        if (status || initial_plane) {

            setValue(`plans[${index}].status_for_payment`, status);
            if (initial_plane?.initial_status_for_payment === status) {
                setValue(`plans[${index}].is_comment`, 0);
                setValue(`plans[${index}].comment`, null);
            } else {
                setValue(`plans[${index}].is_comment`, 1)
                setValue(`plans[${index}].comment`, initial_plane?.comment)
            }
            setIsRerender(!is_rerender)
        }
    };

    const handlerCloseModal = () => {
        setToggleModal(false)
        reset();
    }
    return (
        <Modal title={getT("Закрыть смену")} onClose={handlerCloseModal} open={isOpenModal}>
            <form onSubmit={handleSubmit(handlerSendCloseSift)}>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{getT("Дата начисление оплаты работникам")}</label>
                    <input {...register("date", {value: defaultValues?.date})} className="form-control" type={"date"}
                           required={true}

                    />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{getT("Время начисление оплаты работникам")}</label>
                    <input {...register("time", {value: defaultValues?.time})} className="form-control" type={"time"}
                           min="00:00" max="23:59" pattern="[0-2][0-9]:[0-5][0-9]"
                           required={true}/>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{getT("Коэффициент перевыполнения")}</label>
                    <input {...register("koef_plan_from_good_to_super", {value: defaultValues?.koef_plan_from_good_to_super})}
                           className="form-control" type={"number"} step="0.1" required={true}
                    />
                </div>

                {shift?.plans?.map((plane, index) => {
                    let def_value_plane = defaultValues?.plans?.find(e => Number(e.id) == Number(plane?.salary_shift_plan_id));
                    let is_comment = getValues(`plans[${index}].is_comment`);
                    return (<div className="form-group">
                        <input {...register(`plans[${index}].id`)} type={"hidden"} value={def_value_plane.id}/>
                        <input {...register(`plans[${index}].is_comment`, {value: def_value_plane.is_comment})} type={"hidden"}/>
                        <label htmlFor="exampleInputEmail1">{plane?.name_service_type}</label>
                        <select className="form-control"
                                {...register(`plans[${index}].status_for_payment`, {value: def_value_plane?.status_for_payment})} // Assuming you want to register the status_for_payment field
                                defaultValue={def_value_plane?.status_for_payment} // Set default value from defaultValues
                                id={`plans[${index}].status_for_payment`}
                                onChange={updatePlanStatus(index)}
                        >
                            {SELECT_FINISH_STATUS_PLANE?.map(item => {
                                return <option value={item?.value}>{getT(item?.title)}</option>
                            })}
                        </select>

                        {!!Number(is_comment) && <div className="mt-2">
                            <label htmlFor="exampleInputEmail1">{getT("Комментарий")}</label>
                            <textarea
                                className="form-control" {...register(`plans[${index}].comment`, {value: def_value_plane?.comment})}
                                required={true}
                                cols="30"
                                rows="3">

                        </textarea>
                        </div>
                        }
                    </div>)
                })}

                <div className="text-center">
                    <Button type={"submit"}><> {getT("Отправить")}</>
                    </Button>
                </div>
            </form>
        </Modal>
    );
});

export default SalaryCloseShiftModal;