import React from 'react';
import ItemServiceType from "./ServiceReadyList/ItemServiceType";

const ShiftServiceReadyList = ({getT, service_ready_entry = []}) => {
    console.log(service_ready_entry, 'service_ready_entry')
    return (
        <div className={'card'}>
            <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="card-title font-weight-bold">{getT("Выполненные услуги")}</h5>
                </div>
            </div>
            <div className="card-body">
                <ul className={"list-group-shift-plane "}>
                    {service_ready_entry?.map(item => {
                        return <ItemServiceType getT={getT} item={item}/>
                    })}
                </ul>
            </div>
        </div>
    );
};

export default ShiftServiceReadyList;