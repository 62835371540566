import React, {useState} from 'react';
import useClickOutside from "../../hook/useClickOutside";

const OrderComment = ({ comment, onSend, getT}) => {
    const {isVisible, setVisible, ref} = useClickOutside();
    const onSubmit = (value)=>{
        onSend(value, ()=> setVisible(false));
      setVisible(false);
    }

    return (
        <div ref={ref}>
            {isVisible ? <CommentForm comment={comment} onSend={onSubmit} getT={getT}/>
                :
                <div className={'order-comment-block d-flex align-items-center'}>
                    <p className={'order-comment mb-0'}>{!!comment ? comment :  '---'}</p>
                     <span className={'cursor-pointer ml-2'} onClick={()=> setVisible(true)}><i className="fas fa-edit" aria-hidden="true"></i></span>
                </div>
            }

        </div>
    );
};

const CommentForm = ({comment, onSend, getT}) =>{
    const [value, setValue] = useState(comment)
    return <form className={'order-comment-form'} onSubmit={(e)=>{
    e.preventDefault();
        onSend(value);
    }
    }>
        <input type="text" value={value} onChange={(e)=> setValue(e.target.value)} required={true}/>
       <button type={"submit"}>{getT('Сохранить')}</button>
    </form>
}

export default OrderComment;