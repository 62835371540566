import getT from "../getT";

export default function ({packing, isVisiblePosition}){
    const tables =  packing?.map((packing, root_index) => {
        return`<div class='section-table-part section-gap' style="    page-break-inside: auto;">
            <h2>${getT('Детали')}</h2>
            <table >
<!--            <table style="page-break-inside: auto;">-->
                <tr>
                    <th rowspan='2'>id</th>
                    <th rowspan='2'>${getT('Название')}</th>
                    <th colspan='3'>${getT('Размер')}</th>
                    <th rowspan='2'>${getT('Материал')}</th>
                </tr>
                <tr>
                    <th>${getT('Высота')}</th>
                    <th>${getT('Ширина')}</th>
                    <th>${getT('Глубина')}</th>
                </tr>
                ${packing?.packs?.map((pack, index_pack) => {
            return pack?.bins?.map((bind, index_bind) => {
                return bind?.parts?.map((part, index_part) => {
                    return `
                            ${Part({part})}
                            ${isVisiblePosition && Position({index_bind, index_pack, part})}
                            `
                })?.join('')
            })?.join('')
        })?.join('')}
            </table>
        </div>`
    })?.join('')

    return `${tables}`
}
const Part = ({part})=>{
    let {product_id, name , h, w, id_ifurn, z} = part;
    return `
      <tr class='tr-bg-pack'>
            <td>${id_ifurn ?? '-'}</td>
            <td>${name}</td>
            <td>${h?? '-'}</td>
            <td>${w??'-'}</td>
            <td>${z??'-'}</td>
            <td>${product_id??'-'}</td>
        </tr>
    `
}

const Position =({index_bind, part, index_pack})=>{
    let {x=0, y=0, color, } = part
    return`
      <tr class='tr-collapse'>
                <th colspan="9"  style="text-align:left">${getT('Расположение')}
               
                </th>
            </tr>
                <tr>
                    <th></th>
                    <th>${getT('Коробка')}</th>
                    <th>${getT('Слой')}</th>
                    <th>${getT('Цвет')}</th>
                    <th>x</th>
                    <th>y</th>
                </tr>
                <tr>
                    <td></td>
                    <td>${index_pack +1}</td>
                    <td>${index_bind + 1}</td>
                    <td>
                        <div class="" style="background: ${color}; width: 100%; height: 25px"></div>
                    </td>
                    <td>${x}</td>
                    <td>${y}</td>
                </tr>

    
    `
}