import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {dispatcherErrorThunk} from "../common_thunk";
import {StatisticAPI} from "../../../api/Statistic/StatisticAPI";
import {
    setStatisticXncOperations,
    setStatisticXncOperationsMaterials,
    statisticsIsLoadingAC,
    statisticXncOperationMaterialBandConnectionCreateModalAC,
    toggleStatisticXncOperationMaterialBandConnectionAC
} from "../../actions/StatisticXncOperationsAction";
import {MaterialBandConnectionAPI} from "../../../api/Salary/MaterialBandConnectionAPI";
import {createFormData} from "../../../utils/helpers";
import {SearchMaterialAPI} from "../../../api/SearchMaterial/SearchMaterialAPI";
import {
    INITIAL_STATE_STATISTICS_XNC_OPERATION_MATERIAL_BAND_CONNECTED_CREATE_MODAL
} from "../../reducers/StatisticReducer";

/**
 * .
 *
 * @param {object |null}  params -
 * @param {}  -
 * @returns  {}  -
 */
export const getStatisticXncOperations = (params = null) => async (dispatch) => {
    try {

        dispatch(statisticsIsLoadingAC(true));
        const response = await StatisticAPI.getXncOperation(params)
        if (!!response?.error) {
            dispatch(statisticsIsLoadingAC(false));
            dispatch(dispatcherErrorThunk(response))
            return
        }
        dispatch(setStatisticXncOperations(response?.data))
        dispatch(statisticsIsLoadingAC(false));
    } catch (e) {
        dispatch(statisticsIsLoadingAC(false));
        dispatch(dispatcherErrorThunk(e))
    }
}

export const getStatisticXncOperationsMaterials = (params = null) => async (dispatch) => {
    try {

        dispatch(statisticsIsLoadingAC(true));
        const response = await StatisticAPI.getXncOperationMaterial(params)

        if (!!response?.error) {
            dispatch(statisticsIsLoadingAC(false));
            dispatch(dispatcherErrorThunk(response))
            return
        }
        dispatch(setStatisticXncOperationsMaterials(response?.data))
        dispatch(statisticsIsLoadingAC(false));
    } catch (e) {
        dispatch(statisticsIsLoadingAC(false));
        dispatch(dispatcherErrorThunk(e))
    }
}

async function dispatchedXncOperationMaterialBandConnected(params, dispatch) {
    const response = await StatisticAPI.getXncOperationsMaterialBandConnect(params)
    if (!!response?.error) {
        dispatch(statisticsIsLoadingAC(false));
        dispatch(dispatcherErrorThunk(response))
        return
    }
    let initial_val = {connected: [], unconnected: []};
    let create_entry = Array.isArray(response?.data) ? response?.data?.reduce((acc, item) => {
        let is_connected = item?.type === "connected";
        if (is_connected) {
            return {...acc, connected: [...acc?.connected ?? [], item]}
        }
        return {...acc, unconnected: [...acc?.unconnected ?? [], item]}
    }, initial_val) : initial_val
    dispatch(toggleStatisticXncOperationMaterialBandConnectionAC(create_entry))
}

export const getStatisticXncOperationsMaterialBandConnectThunk = (params = null) => async (dispatch) => {
    try {
        dispatch(statisticsIsLoadingAC(true));
        await dispatchedXncOperationMaterialBandConnected(params, dispatch)
        dispatch(statisticsIsLoadingAC(false));
    } catch (e) {
        dispatch(statisticsIsLoadingAC(false));
        dispatch(dispatcherErrorThunk(e))
    }
}
export const createStatisticXncOperationsMaterialBandConnectThunk = (item, params) => async (dispatch) => {
    try {
        dispatch(statisticsIsLoadingAC(true));
        const response = await MaterialBandConnectionAPI.set({
            connections: [{
                material: item?.material_id,
                band: item?.band_id
            }]
        })
        if (!!response?.error) {
            dispatch(statisticsIsLoadingAC(false));
            dispatch(dispatcherErrorThunk(response))
            return
        }
        await dispatchedXncOperationMaterialBandConnected(params, dispatch)
        dispatch(statisticsIsLoadingAC(false));
    } catch (e) {
        dispatch(statisticsIsLoadingAC(false));
        dispatch(dispatcherErrorThunk(e))
    }
}

export const deleteStatisticXncOperationsMaterialBandConnectThunk = (item, params) => async (dispatch) => {
    try {
        dispatch(statisticsIsLoadingAC(true));
        let data = {material: item?.material_id, band: item?.band_id}
        let form_data = createFormData(data)
        const response = await MaterialBandConnectionAPI.delete(form_data)
        if (!!response?.error) {
            dispatch(statisticsIsLoadingAC(false));
            dispatch(dispatcherErrorThunk(response))
            return
        }
        await dispatchedXncOperationMaterialBandConnected(params, dispatch)
        dispatch(statisticsIsLoadingAC(false));
    } catch (e) {
        dispatch(statisticsIsLoadingAC(false));
        dispatch(dispatcherErrorThunk(e))
    }
}


export const fetchingSearchMaterialBandThunk =  (data) => async (dispatch) => {
    try {
        const response = await SearchMaterialAPI.search(data)
        if (!!response?.error) {
            dispatch(dispatcherErrorThunk(response))
            return
        }
        let key_type = data?.type === 'part' ? 'material' : "band"
        let payload = {
            [key_type]: response?.data
        }
        dispatch(statisticXncOperationMaterialBandConnectionCreateModalAC(payload))
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

export const createStatisticXncOperationsMaterialBandConnectCreateThunk = (item, params) => async (dispatch) => {
    try {

        dispatch(statisticsIsLoadingAC(true));
        const response = await MaterialBandConnectionAPI.set({
            connections: [{
                material: item?.material_id,
                band: item?.band_id
            }]
        })
        if (!!response?.error) {
            dispatch(statisticsIsLoadingAC(false));
            dispatch(dispatcherErrorThunk(response))
            return
        }
        await dispatchedXncOperationMaterialBandConnected(params, dispatch)
        dispatch(statisticXncOperationMaterialBandConnectionCreateModalAC(INITIAL_STATE_STATISTICS_XNC_OPERATION_MATERIAL_BAND_CONNECTED_CREATE_MODAL))
        dispatch(statisticsIsLoadingAC(false));
    } catch (e) {
        dispatch(statisticsIsLoadingAC(false));
        dispatch(dispatcherErrorThunk(e))
    }
}