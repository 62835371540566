import {instances, URI_API_EXTTUNELL} from "./setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const GET_MATERIALS_BAND_CONNECTION = "get_materials_band_connection"
const SET_MATERIALS_BAND_CONNECTION = "materials_band_connection"
const DELETE_MATERIALS_BAND_CONNECTION = "delete_materials_band_connection"


export const MaterialBandConnectionAPI = {
    get() {
        return instances.post(URI_API_EXTTUNELL, null, {
            headers: {
                action: GET_MATERIALS_BAND_CONNECTION
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    set(data) {
        return instances.post(URI_API_EXTTUNELL, data, {
            headers: {
                action: SET_MATERIALS_BAND_CONNECTION
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    delete(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: DELETE_MATERIALS_BAND_CONNECTION
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },

}