import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {CodeOneC} from "../../../Orders/CodeOneC";

const TaskItem = ({ getT, task, deleteTask, getStatus, orders_check_enable}) => {

    const [openChangeDateProduction, changeProductionTaskDateOrder] = useState(false);
    return (
        <tr>
            <td data-label={getT("Код 1С")}><CodeOneC order={task} orders_check_enable={orders_check_enable} type={true}/></td>
            {/*<td data-label={getT("Код 1С")}>{task.code1c}</td>*/}
            <td data-label={getT("ТЕХ ID")}>{task.id}</td>
            <td data-label={getT("Название")}>{task.name}</td>

            <td data-label={getT("Статус")}>
                <div className="order_status_container">
                                <span
                                    className={
                                        "badge bg-" + getStatus(task.status).color
                                    }
                                >
                                  {" "}
                                    {getT(getStatus(task.status).name)}
                                </span>
                </div>
            </td>

            <td data-label={getT("Дата")}>{task.date}</td>
            <td data-label={getT("Дата производства")}>
                {Number(openChangeDateProduction) ===
                Number(task.id) ? (
                    <>
                        <input
                            type={"date"}
                            defaultValue={task.plan_of_production.substr(
                                0,
                                10
                            )}
                            onBlur={(event) => {
                                // changeProductionTaskDateOrder(
                                //     task.id,
                                //     event.target.value
                                // );
                                // changeDateProductionOpen(null);
                            }}
                        />
                    </>
                ) : (
                    <>
                                  <span
                                      onDoubleClick={() => {
                                      }
                                          // changeDateProductionOpen(task.id)
                                      }
                                  >
                                    {task.plan_of_production}
                                  </span>
                    </>
                )}
            </td>

            <td>
                <div>
                    {task.childs.length > 0 ? (
                        <Link to={"/orders/group/details/" + task.id}>
                                    <span
                                        className={
                                            "d-flex align-center mr-1 production_task_item_open justify-content-center"
                                        }
                                    >
                                      <i className="far fa-eye"> </i>
                                      <small>{getT("Просмотр")}</small>
                                    </span>
                        </Link>
                    ) : (
                        <small>{getT("Нет подчиненных заказов")}</small>
                    )}
                    <span
                        onClick={() => {deleteTask(task.id)}
                            // dispatch(deleteProductionTaskThunk(task.id))
                        }
                        className={
                            "d-flex align-center mr-1 production_task_item_open justify-content-center  text-danger cursor-pointer"
                        }
                    >
                                  <i className="fas fa-ban "></i>

                                  <small>{getT("Расформировать задание")}</small>
                                </span>
                </div>
            </td>
        </tr>
    );
};

export default TaskItem;