import {instances, URI_API_EXTTUNELL} from "./setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const GET_DEFAULT = 'get_default_plan';
const ADD_DEFAULT = 'add_default_plan';

/**
 * Планы по умолчанию (PlanDefault)
 * При создании плана на смену по типу услуг, нужен предустановленный план по умолчанию.
 * @method get  - получить все планы по всем услугам
 * @method add  - type form_data создать план на тип услуги
 */
export const PlaneDefaultAPI = {
    get() {
        return instances.post(URI_API_EXTTUNELL, null,{
            headers: {
                action: GET_DEFAULT
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    add(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: ADD_DEFAULT
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }
}