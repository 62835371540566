import React, {useEffect} from 'react';
import PageTitle from "../PageTitle/PageTitle";
import ArchiveFilter from "./ArchiveFilter";
import {useDispatch, useSelector} from "react-redux";
import {
    archiveDownloadPdf,
    archiveProjectEntries,
    archiveProjectFilter,
    initialArchiveProject,
    loadArchiveProjectFilter, resetFilterArchiveProject
} from "../../store/reducers/ArchiveProjectReducer";
import {withLang} from "../../hoc/withLang";
import ArchiveList from "./ArchiveList";
import {gaEvents} from "../../gaEvents";

const ArchiveProjectPage = ({getT}) => {
    const dispatch = useDispatch();
    const filter = useSelector(archiveProjectFilter);
    const archiveEntries = useSelector(archiveProjectEntries)
    const onChangeHandlerFilter = ({key, value}) => {
        dispatch(loadArchiveProjectFilter(filter, {key, value}))
        // dispatch(actionsArchiveProject.updateFilter({key, value}));
    }
    const statusOption = useSelector(state => state.main.orderStatus);
    const managerOption = useSelector(state => state.main.managers);
    const clientsOption = useSelector(state => state.main.clients);
    const onDownloadPdf = (code) => {
        if (!code) return
        dispatch(archiveDownloadPdf(code))
    }
    const onResetForm = () => {
        dispatch(resetFilterArchiveProject())
    }
    useEffect(() => {
        dispatch(initialArchiveProject())
    }, [])
    return (
        <div className="content-wrapper">
            <PageTitle title={"archive"}/>

            <section className="content">
                <div className="container-fluid">
                    <ArchiveFilter onResetForm={onResetForm} onHandlerChange={onChangeHandlerFilter} getT={getT}
                                   formData={filter} statusOptions={statusOption} managerOption={managerOption}
                                   clientsOption={clientsOption}/>
                    <ArchiveList statusOptions={statusOption} getT={getT} entries={archiveEntries}
                                 onDownloadPdf={onDownloadPdf}/>
                </div>
            </section>
        </div>
    );
};

export default withLang(ArchiveProjectPage);