import {WholesaleOrdersGoodsAPI} from "../../../../api/Wholesale/WholesaleOrdersGoodsAPI";
import {dispatcherErrorThunk} from "../../common_thunk";
import {
    wsGetGoodsInformationModalAC,
    wsGetOrdersGoodsAction,
    wsOrderGoodsSetModalAC
} from "../../../actions/WholesaleOrdersGoodsActions";
import {toggleLoaderStatusAC} from "../../../reducers/MainReducer";
import {createFormData} from "../../../../utils/helpers";
import {WholesaleOrdersSupplyAPI} from "../../../../api/Wholesale/WholesaleOrdersSupplyAPI";
import {
    orderDetailWholeSaleGoodsAC,
    orderDetailWholeSaleGoodsInformationByGoodsIdAC
} from "../../../actions/MainActions";

const dispatchedGetOrdersGoods = async (api_data, dispatch) => {
    const formData = createFormData(api_data)
    const response = await WholesaleOrdersGoodsAPI.getOrdersGoods(formData);
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    dispatch(wsGetOrdersGoodsAction(response?.data))
}

export const wsGetOrdersGoodsThunk = (api_data) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await dispatchedGetOrdersGoods(api_data, dispatch);
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}
/**
 * wsDeleteOrderGoodsByIdAndGetOrdersThunk. Удалить потребность и запросить новый список
 *
 * @param {number}  ws_orders_goods_id-  id потребности
 * @param {object}  filter_data- текущий предустановленный фильтр
 */
export const wsDeleteOrderGoodsByIdAndGetOrdersThunk = (ws_orders_goods_id, filter_data) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const formData = createFormData({ws_orders_goods_id: ws_orders_goods_id});
        const response = await WholesaleOrdersGoodsAPI.deleteOrderGood(formData);
        if (response.hasOwnProperty("error") && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        await dispatchedGetOrdersGoods(filter_data, dispatch);
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));

    }
}

/**
 * Создать/обновить потребность и обновить список существующих  потребностей
 *
 * @param {object} set_api_data - форма для создания обновление потребности
 * @param {object|| null} filter_get_orders_goods - текущий предустановленный фильтр
 */
export const wsCreateOrUpdateNeedOrderGoodsModalThunk = ({
                                                             set_api_data,
                                                             filter_get_orders_goods = null
                                                         }) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const formData = createFormData(set_api_data);
        const response = await WholesaleOrdersGoodsAPI.setOrderGood(formData);
        if (response.hasOwnProperty("error") && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        let filterForGetOrderGoods = filter_get_orders_goods ? filter_get_orders_goods : {status: 1, offset: 0}
        await dispatchedGetOrdersGoods(filterForGetOrderGoods, dispatch);
        dispatch(wsOrderGoodsSetModalAC({isOpen: false, currentGoods: null}))
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

/**
 * .
 *
 * @param {array} goods_ids  - id материалов по которым нужно получить инфо
 */
export const wsGetGoodsInformationByMaterialsIdsThunk = (goods_ids) => async (dispatch) => {

    try {
        dispatch(toggleLoaderStatusAC(true));
        if (!Array.isArray(goods_ids)) {
            throw  new Error("Ожидается массив с идентификаторами  материалов и товаров")
        }
        const req_map = goods_ids?.map(id => {
            let form_data = createFormData({id: id})
            return WholesaleOrdersGoodsAPI.getGoodsInformationById(form_data);
        })
        const response_all = await Promise.allSettled(req_map);
        let result = response_all?.reduce((acc, item) => {
            if (item.status === 'fulfilled') {
                let data = item.value;
                let isError = (data.hasOwnProperty('error') && !!data?.error);
                let isHasWholeSale = (Array.isArray(data?.data?.WS_GoodsStockSourceRetail?.whole_sale?.data) && !!data?.data?.WS_GoodsStockSourceRetail?.whole_sale?.data?.length);
                if (isHasWholeSale) {
                    return {...acc, ok: [...acc.ok, data?.data]}
                }
                if (isError) {
                    return {...acc, error: [...acc.error, data?.error]}

                }
            } else {
                return {...acc, error: [...acc.error, item.reason]}
            }
            return acc;
        }, {ok: [], error: []});

        if (result?.ok?.length) {
            dispatch(orderDetailWholeSaleGoodsInformationByGoodsIdAC(result.ok));
        }
        // if (result.error?.length) {
        //     let error_msg = result?.error?.join('. ');
        //     dispatch(dispatcherErrorThunk({error: error_msg}));
        //     return
        // }

        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e))

    }
}
/**
 * wsGetOrderGoodsExistByOrderId. Получить существующие потребности по id открытого заказа.
 *
 * @param {number || string}  orderId -id заказа
 */
export const wsGetOrderGoodsExistByOrderId = (orderId) => async (dispatch) => {
    //::TODO оптовая компания
    let isDevelopment = !!Number(process.env.REACT_APP_DEVELOPMENT)
    if (!isDevelopment) { return }
    try {
        dispatch(toggleLoaderStatusAC(true));
        let formData = createFormData({
            order: orderId,
            offset: 0,
            status: 1
        })
        const response = await WholesaleOrdersGoodsAPI.getOrdersGoods(formData);
        if (response.hasOwnProperty("error") && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        let exist = Array.isArray(response?.data?.exist_ws_orders_goods)
            ? response?.data?.exist_ws_orders_goods : null;
        let need = Array.isArray(response?.data?.need_to_be_added) ?
            response?.data?.need_to_be_added?.filter(e => Number(e?.order) === Number(orderId))
            : null;
        dispatch(orderDetailWholeSaleGoodsAC({exist: exist, need: need}))
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
/**
 * Создать/обновить потребность и обновить список существующих  потребностей в открытом заказе
 *
 * @param {object} set_api_data - форма для создания обновление потребности
 * @param {string || number} orderId - id открытого заказа
 */
export const wsCreateOrUpdateExistOrderGoodsInformationThunk = ({set_api_data, orderId}) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const formData = createFormData(set_api_data);
        const response = await WholesaleOrdersGoodsAPI.setOrderGood(formData);
        if (response.hasOwnProperty("error") && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        dispatch(wsGetOrderGoodsExistByOrderId(orderId))
        dispatch(wsGetGoodsInformationModalAC({isOpen: false, currentGoods: null, availabilityInfo: null, exist: null}))
        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}