import React, {useEffect, useMemo, useState} from 'react';
import {gaEvents} from "../../gaEvents";
import ReadinessTable from "./component/ReadinessTable";

const LeftToMake = ({sale, getT, lang, order_detailing}) => {
    const [filters, setFilters] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState(filters);
    const [colapseOverdue, setColapseOverdue] = useState(false);
    const toggleColapseOverdue = () => setColapseOverdue(!colapseOverdue);
    const [colapseOrders, setColapseOrders] = useState(false);
    const toggleColapseOrders = () => setColapseOrders(!colapseOrders);

    useEffect(() => {
        if (!sale) return
        const unique = sale?.data?.filter(
            (
                (set) => (f) =>
                    !set.has(f.data.serv_type) && set.add(f.data.serv_type)
            )(new Set())
        );
        setFilters(
            unique?.map((el) => {
                return {id: el.service_type, name: el.data.serv_type_info[lang]};
            })
        );
    }, [sale]);


    let orderInTimeSumm = useMemo(() => {
        return sale?.data?.filter(
            (el) => el.data.serv_type === selectedFilter && el.type === "in_time_order"
        )?.reduce(
            (n, {data}) => n + Number(data.count),
            0
        )
            .toFixed(2)
    }, [sale, selectedFilter])
    let outOfDateSumm = useMemo(() => {
        return sale?.data?.filter(
            (el) => el.data.serv_type === selectedFilter && el.type === "out_of_date"
        )?.reduce(
            (n, {data}) => n + Number(data.count),
            0
        )
            .toFixed(2)
    }, [sale, selectedFilter])

    return (
        <section className="content">
            <div className="form-group">
                <label>{getT("Типы услуг")}:</label>
                <select
                    className="form-control"
                    onChange={(e) => {
                        gaEvents.customEvent('form_order_reports_leftMake', {
                            field_focus: 'Типы услуг'
                        })
                        setSelectedFilter(e.target.value)
                    }}

                >
                    <option value="">{getT("Выберите необходимую услугу")}</option>
                    {filters &&
                        filters.map((el) => (
                            <option key={el.id} value={el.id}>
                                {el.name}
                            </option>
                        ))}
                </select>
            </div>
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">
                        {getT(`Заказов`)}:   &nbsp;  {sale.count_orders}
                    </h3>
                </div>
            </div>
            <div className="container-fluid">
                <div className="card">
                    <div className="accordion" id="accordionExample">
                        <div className="card">
                            <div className="card-header" id="headingOne">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseOne"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                        onClick={() => {
                                            toggleColapseOverdue();
                                        }}
                                    >
                                        {getT("Просроченные заказы")}
                                    </button>
                                </h2>
                            </div>

                            <div
                                id="collapseOne"
                                className={colapseOverdue ? "collapse show" : "collapse hide"}
                                aria-labelledby="headingOne"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">
                                    <ReadinessTable getT={getT}
                                                    orders={sale?.data?.filter(
                                                        (el) =>
                                                            el.type === "out_of_date" &&
                                                            el.data.serv_type === selectedFilter
                                                    )} outOfDateSumm={outOfDateSumm}
                                                    order_detailing={order_detailing}
                                                    selectedFilter={selectedFilter}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" id="headingTwo">
                                <h2 className="mb-0">
                                    <button
                                        className="btn btn-link collapsed"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                        onClick={() => toggleColapseOrders()}
                                    >
                                        {getT("Заказы в работе")}
                                    </button>
                                </h2>
                            </div>

                            <div
                                id="collapseTwo"
                                className={colapseOrders ? "collapse show" : "collapse hide"}
                                aria-labelledby="headingTwo"
                                data-parent="#accordionExample"
                            >
                                <div className="card-body">
                                    <ReadinessTable getT={getT} orders={
                                        sale?.data?.filter(
                                            (el) =>
                                                el.type === "in_time_order" &&
                                                el.data.serv_type === selectedFilter
                                        )} outOfDateSumm={orderInTimeSumm}
                                                    order_detailing={order_detailing}
                                                    selectedFilter={selectedFilter}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LeftToMake;