import React from 'react';

const IconArrow = (props) => {
    let {className = '', ...reset} = props;
    const cName = `icon-arrow cursor-pointer ${className}`;
    return (
        <svg className={cName} {...reset} width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1.5L8 8.5L15 1.5" stroke="#050038" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default IconArrow;