import React, {useState} from 'react';
import Modal from "../MyModal/Modal";

/**
 * .
 *
 * @param {number}  - current_status
 * @param {boolean}  - isOpen
 * @param  {function}  - onClose
 * @param  {function}  - onChangeHandler
 * @param  {function}  - getT
 * @returns  {jsx}  - modal
 */

const STATUS_OPTIONS = [
    {
        id: 2, name: 'Отменить готовность'
    },
    {
        id: 3, name: 'Доступны для производства'
    }, {
        id: 4, name: 'В производство'
    }, {
        id: 5, name: 'Выполнено'
    }, {
        id: 6, name: 'Готовы к отгрузке'
    }, {
        id: 7, name: 'Отгружен'
    },
]

const GroupOrderTransfer = ({current_status, isOpen, onClose, onChangeHandler, getT}) => {
    const [value, setValue] = useState('')
    const un_print_status = [1, 8, 9, current_status];
    const onSend = ()=> onChangeHandler(value)
    return (
        <Modal open={isOpen} onClose={onClose} title={getT("Групповое перемещение")}>
            <div style={{padding: '2rem 0px'}}>
                {/*<h4 className={'h4 mb-4'}>{getT("Групповое перемещение")}</h4>*/}
                <div className="form-group">
                    <select name="" id="" onChange={(e)=>setValue(e.target.value)} className={'form-control'}>
                        <option disabled={true} selected={true}>-------</option>
                        {STATUS_OPTIONS?.filter(item => !un_print_status.includes(item.id)).map((item) => {
                            return <option value={item.id}>{getT(item.name)}</option>
                        })}
                    </select>
                </div>
                <div className="form-group text-center mb-0">
                    <button className={'btn btn-primary'} onClick={onSend}>{getT('Отправить')}</button>
                </div>
            </div>
        </Modal>
    );
};

export default GroupOrderTransfer;