import React from 'react';

const THeader = ({getT}) => {
    return (
        <thead>
        <tr>
            <th scope="col">#</th>
            <th scope="col">{getT("Дата начала смены")}</th>
            <th scope="col">{getT("Дата окончания смены")} </th>
            <th scope="col">{getT("Коэффициент расчёта смены")}</th>
            <th scope="col">{getT("Действия")}</th>
        </tr>
        </thead>
    );
};

export default THeader;