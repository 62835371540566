import {instances, URI_API_EXTTUNELL} from "./setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const ADD_PRODUCTION_MARK_TO_DETAIL = 'add_production_mark_to_detail'
const REMOVE_PRODUCTION_MARK_TO_DETAIL = 'remove_production_mark_to_detail'

const QR_GET_FOR_ORDER = 'qr_get_for_order'
const GET_INDEX_OF_PART_FOR_ORDER_FOR_PRODUCTION_MARKS = 'get_index_of_part_for_order_for_production_marks'
const ADD_PACKING_MARK_TO_DETAIL_BY_ID = 'add_packing_mark_to_detail_by_id'
const REMOVE_PACKING_MARK_TO_DETAIL_BY_ID = 'remove_packing_mark_to_detail_by_id'

const ADD_PACKING_MARK_TO_DETAIL_LINK = 'add_packing_mark_to_detail'
const REMOVE_PACKING_MARK_TO_DETAIL_LINK = 'remove_packing_mark_from_detail'
const REMOVE_ALL_PACKING_MARK_FROM_ORDER = 'remove_all_packing_mark_from_order'

const GET_INFO_ABOUT_ORDER_BEFORE_MARK_PUT = 'get_info_about_order_before_mark_put'

/**
 * добавиление отметок
 * @method getList  - список заказов или задание на производство
 */
export const PutProdMarkDetailAPI = {
    addMarkToDetail(api_data) {
        return instances.post(URI_API_EXTTUNELL, api_data, {
            headers: {
                action: ADD_PRODUCTION_MARK_TO_DETAIL
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    deleteMarkToDetail(api_data) {
        return instances.post(URI_API_EXTTUNELL, api_data, {
            headers: {
                action: REMOVE_PRODUCTION_MARK_TO_DETAIL
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getPartForOrderMarks(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: GET_INDEX_OF_PART_FOR_ORDER_FOR_PRODUCTION_MARKS,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    qrGetForOrder(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: QR_GET_FOR_ORDER,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    addPackingMarkToDetailById(api_data) {
        return instances.post(URI_API_EXTTUNELL, api_data, {
            headers: {
                action: ADD_PACKING_MARK_TO_DETAIL_BY_ID,
            },
        }).then(res => {
            return resolveTransform(res)
        })
            .catch(error => rejectTransform(error))
    },
    removePackingMarkToDetailById(api_data) {
        return instances.post(URI_API_EXTTUNELL, api_data, {
            headers: {
                action: REMOVE_PACKING_MARK_TO_DETAIL_BY_ID,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
 addPackingMarkToDetailLink(api_data) {
        return instances.post(URI_API_EXTTUNELL, api_data, {
            headers: {
                action: ADD_PACKING_MARK_TO_DETAIL_LINK
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    removePackingMarkToDetailLink(api_data) {
        return instances.post(URI_API_EXTTUNELL, api_data, {
            headers: {
                action: REMOVE_PACKING_MARK_TO_DETAIL_LINK,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
 removeALLPackingMarkFromOrder(api_data) {
        return instances.post(URI_API_EXTTUNELL, api_data, {
            headers: {
                action: REMOVE_ALL_PACKING_MARK_FROM_ORDER,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getInfoAboutOrderBeforeMarkPut(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: GET_INFO_ABOUT_ORDER_BEFORE_MARK_PUT,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },


}