import React, {useEffect} from "react";
import PageTitle from "../PageTitle/PageTitle.jsx";
import InputMask from "react-input-mask";
import {withLang} from "../../hoc/withLang";
import {useSelector} from "react-redux";
import {maskPhoneCountry} from "../../constants";
import SelectPhone from "../SelectPhone";
import {gaEvents} from "../../gaEvents";

const ClientsAdd = (props) => {
    const setting_rusHide = props.settings?.['production.rus_hide'] || ''
    return (
        <div className="content-wrapper">
            <PageTitle title={"clients_add"}/>
            <section className="content">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">{props.getT("Основная информация")}</h3>
                        <div className="card-tools">
                            <button
                                type="button"
                                className="btn btn-tool"
                                data-card-widget="collapse"
                                title="Collapse"
                            >
                                <i className="fas fa-minus"> </i>
                            </button>
                        </div>
                    </div>
                    <form
                        className={"" + (props.form.validated ? "was-validated" : "")}
                        onSubmit={(event) => {
                            gaEvents.customEvent('klik_form', {
                                form_submit_add_client: "Добавление нового клиента"
                            });
                            gaEvents.customEvent('form_submit', {

                                form_name: "add new  client",
                                form_submit_text: "success"
                            })
                            event.preventDefault();
                            props.submitForm(props.form);
                        }}
                    >
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>{props.getT("Ф.И.О")}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder=""
                                            value={props.form.name}
                                            onChange={(event) =>
                                                props.changeValue("name", event.target.value)
                                            }
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div>
                                        <label>{props.getT("Телефон")}</label>
                                    </div>
                                    <div className={"d-flex"}>
                                        <div className="form-group">
                                            {props.settings && Number(props.settings['react.phone_code_one']) !== 9999
                                                ?
                                                <input style={{maxWidth: '80px'}} className={'form-control'} type="text"
                                                       value={`+${props.settings['react.phone_code_one']}`}
                                                       disabled={true}/>
                                                :
                                                // <select
                                                //     className="form-control"
                                                //     value={props.form.phone_code}
                                                //     defaultValue={props.form.phone_code}
                                                //     onChange={(event) =>
                                                //         props.changeValue("phone_code", event.target.value)
                                                //     }
                                                // >
                                                //   <option value={"+38"}>(UA) +38</option>
                                                //   <option value={"+972"}>(HE) +972</option>
                                                //   <option value={"+373"}>(MD) +373</option>
                                                //   <option value={"+40"}>(RO) +40</option>
                                                //   <option value={"+40"}>(PL) +48</option>
                                                //   <option value={"+36"}>(HU) +36</option>
                                                //   <option value="+49"> (DE) +49</option>
                                                //   <option value="+421">(SK) +421 </option>
                                                //   <option value="+371">(LV) +371</option>
                                                //   <option value="+370">(LT) +370</option>
                                                //   <option value="+251">(ET) +251 </option>
                                                //   {Number(setting_rusHide) !== 1 &&  <option value={"+7"}>(RU) +7</option>}
                                                // </select>
                                                <SelectPhone rusHide={!Number(setting_rusHide)}
                                                             value={props.form.phone_code}
                                                             setValue={(code) => props.changeValue("phone_code", code)}/>
                                            }
                                        </div>

                                        <div className="form-group">
                                            {/*<label></label>*/}
                                            <InputMask
                                                mask={maskPhoneCountry[`${props.form.phone_code} `]}
                                                type="tel"
                                                className="form-control"
                                                placeholder=""
                                                value={props.form.phone_number}
                                                onChange={(event) =>
                                                    props.changeValue("phone_number", event.target.value)
                                                }
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>{props.getT("Email")}</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            placeholder=""
                                            value={props.form.email}
                                            onChange={(event) =>
                                                props.changeValue("email", event.target.value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    {/*<div className="form-group">*/}
                                    {/*<label>Группа клиента</label>*/}
                                    {/*<select className="form-control"*/}
                                    {/*value={props.form.group}*/}
                                    {/*defaultValue={props.form.group}*/}
                                    {/*onChange={(event) => props.changeValue('group', event.target.value)}*/}
                                    {/*>*/}
                                    {/*<option value={''}>нет</option>*/}
                                    {/*</select>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label>{props.getT("Адрес")}</label>
                                        <textarea
                                            className="form-control"
                                            rows="3"
                                            placeholder=""
                                            value={props.form.adress}
                                            onChange={(event) =>
                                                props.changeValue("adress", event.target.value)
                                            }
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <button type="submit" className="btn btn-primary float-right">
                                {props.getT("Добавить")}
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default withLang(ClientsAdd);

/*

    <div className="row">
                            <div className="col-sm-6">

                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Textarea Disabled</label>
                                    <textarea className="form-control" rows="3" placeholder="Enter ..."
                                              disabled></textarea>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="inputSuccess"><i
                                className="fas fa-check"></i> Input with
                                success</label>
                            <input type="text" className="form-control is-valid" id="inputSuccess"
                                   placeholder="Enter ..."/>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="inputWarning"><i
                                className="far fa-bell"></i> Input with
                                warning</label>
                            <input type="text" className="form-control is-warning" id="inputWarning"
                                   placeholder="Enter ..."/>
                        </div>
                        <div className="form-group">
                            <label className="col-form-label" htmlFor="inputError"><i
                                className="far fa-times-circle"></i> Input with
                                error</label>
                            <input type="text" className="form-control is-invalid" id="inputError"
                                   placeholder="Enter ..."/>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"/>
                                            <label className="form-check-label">Checkbox</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked/>
                                            <label className="form-check-label">Checkbox checked</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" disabled/>
                                            <label className="form-check-label">Checkbox disabled</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="radio1"/>
                                            <label className="form-check-label">Radio</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="radio1" checked/>
                                            <label className="form-check-label">Radio checked</label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" disabled/>
                                            <label className="form-check-label">Radio disabled</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Select Disabled</label>
                                    <select className="form-control" disabled>
                                        <option>option 1</option>
                                        <option>option 2</option>
                                        <option>option 3</option>
                                        <option>option 4</option>
                                        <option>option 5</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Select Multiple</label>
                                    <select multiple className="form-control">
                                        <option>option 1</option>
                                        <option>option 2</option>
                                        <option>option 3</option>
                                        <option>option 4</option>
                                        <option>option 5</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label>Select Multiple Disabled</label>
                                    <select multiple className="form-control" disabled>
                                        <option>option 1</option>
                                        <option>option 2</option>
                                        <option>option 3</option>
                                        <option>option 4</option>
                                        <option>option 5</option>
                                    </select>
                                </div>
                            </div>
                        </div>

 */
