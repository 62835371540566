import React from 'react';

const ShiftData = ({shift, getT}) => {
    return (
        <div className={'card'}>
            <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="card-title font-weight-bold">{getT("Смена")} #{shift?.salary_shift_id}</h5>
                    {!!Number(shift?.open_for_edit) ? <span className="badge badge-success">{getT("Открытая")}</span> :
                        <span className="badge badge-danger">{getT("Закрытая")}</span>}
                </div>
            </div>
            <div className="card-body">
                <ul className="list-group">
                    <li key={'shift_data_1'} className="list-group-item d-flex justify-content-between align-items-center flex-row border-top-0 border-left-0 border-right-0 px-0 pt-0">
                        <span>{getT("Дата начала смены")}</span> <span
                        className={"text-right"}>{shift?.date_start}</span></li>
                    <li  key={'shift_data_2'} className="list-group-item d-flex justify-content-between align-items-center flex-row border-top-0 border-left-0 border-right-0 px-0 ">
                        <span>{getT("Дата окончания смены")}</span> <span
                        className={"text-right"}>{shift?.date_finalized}</span></li>
                    <li key={'shift_data_3'} className="list-group-item d-flex justify-content-between align-items-center flex-row border-0 px-0 pb-0">
                        <span>{getT("Дата изменения данных")}</span> <span
                        className={"text-right"}>{shift?.d_update}</span></li>

                    {/*<li key={'shift_data_4'} className="list-group-item d-flex justify-content-between align-items-center flex-row border-0 px-0 pb-0">*/}
                    {/*    <span>{getT("Список готовых услуг")}</span> <span className={"text-right"}>modal</span></li>*/}
                    {/*<li key={'shift_data_4'} className="list-group-item d-flex justify-content-between align-items-center flex-row border-0 px-0 pb-0">*/}
                    {/*    <span>{getT("Список готовых услуг")}</span> <span className={"text-right"}>modal</span></li>*/}
                </ul>
            </div>
        </div>
    );
};

export default ShiftData;