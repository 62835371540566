import React from 'react';

/**
 * Компонент селекта для выбора статуса заказа
 *
 * @param {function} getT - Функция для перевода строк
 * @param {number|null} value - Текущее выбранное значение статуса
 * @param {function} onChange - Функция для изменения статуса заказа
 * @param {array|null} orders_statuses - Список доступных статусов заказов
 * @param {string|null} className - Дополнительный CSS класс (опционально)
 * @returns {JSX.Element} JSX разметка компонента
 */

const GeneralStatusesSelector = ({getT, value, onChange, orders_statuses, className=""}) => {
    if(!Array.isArray(orders_statuses) || !onChange) return  null;
    return (
        <div className={`form-group ${className}`}>
            <label htmlFor="">{getT("Статус заказа")}</label>
            <select className="form-control" value={value} onChange={onChange}>
                {orders_statuses?.map(status => {
                    return <option value={status.orders_status_id}>{status?.name}</option>
                })}
            </select>
        </div>
    );
};

export default GeneralStatusesSelector;