import React from 'react';
import Button from "../../../../components/ui/Button";
import ItemWorker from "./ItemWorker";
import {useDispatch, useSelector} from "react-redux";
import {
    readyMarToggleAllAC,
    scannerReadMarkToggleAllAC,
    toggleShiftWorkerGroupModalAC
} from "../../../../store/actions/SalaryProject";
import {LS_WORKER_ID} from "../../../../utils/constants";
import {readyMarkBySubmitChoiceShiftThunk} from "../../../../store/thunks/salary_project/ready_mark_thunk";
import {getReadyMark} from "../../../../store/selectors/salary_project_selector";
import {useHistory} from "react-router-dom";

/**
 * .
 *
 * @param {}  -
 * @param {}  -
 * @returns  {}  -
 */
const WorkerGroup = ({getT, shift, default_plane, equipment_worker_group, is_available_access_role, is_open_shift}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const ready_mark_state = useSelector(getReadyMark);
    let worker_in_system_id = Number(localStorage.getItem(LS_WORKER_ID))
    const handlerCreateWorker = () => {
        // let worker_in_system_id =worker_in_system_id
        dispatch(toggleShiftWorkerGroupModalAC({
            isOpen: true, form_values: {
                shift: shift?.salary_shift_id,
                fields: [{
                    _id: !!worker_in_system_id ? worker_in_system_id : null,
                    percentage: !!worker_in_system_id ? 100 : null
                }]
            }
        }))
    }
    const handlerUpdateWorker = (workers) => () => {
        let fields = workers?.map(item => ({_id: item.worker, percentage: item?.percentage}));
        let form_values = {
            shift: shift?.salary_shift_id,
            fields: fields
        }
        dispatch(toggleShiftWorkerGroupModalAC({
            isOpen: true, form_values: form_values
        }))
    }
    const handlerOpenReadMarkPage = (working_group_id) => () => {
        dispatch(readyMarToggleAllAC({
            shift_form_values: {shift: shift, worker_group: working_group_id}
        }));
        let filter = {...ready_mark_state?.filter, working_group: working_group_id};
        // let equipment_workers_group = data?.shift?.worker_group?.find(item => Number(item?.salary_shift_worker_group_id) === Number(data?.worker_group));
        dispatch(readyMarkBySubmitChoiceShiftThunk(filter, working_group_id));
        return history.push('/salary-project/ready-mark')
    }
    const handlerOpenScannerReadMarkPage = (working_group_id) => () => {
        dispatch(scannerReadMarkToggleAllAC({
            shift_form_values: {shift: shift, worker_group: working_group_id}
        }));
        // let filter = {...ready_mark_state?.filter, working_group: working_group_id};
        // let equipment_workers_group = data?.shift?.worker_group?.find(item => Number(item?.salary_shift_worker_group_id) === Number(data?.worker_group));
        // dispatch(readyMarkBySubmitChoiceShiftThunk(filter, working_group_id));
        return history.push('/salary-project/scanner-ready-mark')
    }
    return (
        <div className={"card"}>
            <div className="card-header">
                <div className="d-flex justify-content-between align-items-center flex-row">
                    <h5 className={"card-title font-weight-bold"}>{getT("Бригады")}</h5>
                    {is_open_shift &&
                        <Button className={"btn-success"} onClick={handlerCreateWorker}>{getT("Создать")}</Button>}
                </div>
            </div>
            <div className="card-body">
                {shift?.worker_group?.length ?
                    <ul className={'list-group-shift-plane'}>
                        {shift?.worker_group?.filter(worker_group => {
                            if (!worker_in_system_id) return worker_group?.workers?.every(item => !!Number(item.is_working))
                            return worker_group?.workers?.some(item => (Number(item.worker) === worker_in_system_id) && !!Number(item.is_working))
                        })?.map(worker_group => {
                            return <ItemWorker key={`worker_group_${worker_group?.salary_shift_worker_group_id}`}
                                               is_available_access_role={is_available_access_role && is_open_shift}
                                               default_plane={default_plane?.data}
                                               equipment_worker_group={equipment_worker_group?.data}
                                               worker_group={worker_group} getT={getT}
                                               handlerUpdateWorker={handlerUpdateWorker(worker_group?.workers)}
                                               is_open_shift={!!Number(shift?.open_for_edit)}
                                               handlerOpenReadMarkPage={handlerOpenReadMarkPage(worker_group?.salary_shift_worker_group_id)}
                                               handlerOpenScannerReadMarkPage={handlerOpenScannerReadMarkPage(worker_group?.salary_shift_worker_group_id)}

                            />
                        })}
                    </ul>
                    : <p className="">{getT("")}</p>
                }
            </div>
        </div>
    );
};

export default WorkerGroup;