import React from 'react';
import useClickOutside from "../../../hook/useClickOutside";
import {availableStatus, getStatus} from "./helpers";

const StatusProjectList = ({
                               statusList,
                               getT,
                               projectStatus,
                               projectId,
                               onUpdateStatus,
                               rules,
                               onDeleteProductionTaskThunk,
                               userRoleEntry
                           }) => {
    const {isVisible, setVisible, ref} = useClickOutside();

    const renderStatus = statusList?.filter(status => rules?.[projectStatus]?.includes(status?.id) && availableStatus({
        userRoleEntry, statusRoles: status?.roles
    }));
    return (<div className="order_status_container">
            <span className={"badge bg-" + getStatus({
                status: projectStatus, statusEntry: statusList
            }).color}>{getT(getStatus({status: projectStatus, statusEntry: statusList}).name)}</span>
        {!!renderStatus?.length ?
            <i className="fas fa-edit" onClick={() => setVisible(true)}></i>
            : ''}

            <div ref={ref} className={"orders_status_change_container" + (isVisible ? " active" : "")}>
                <small>{getT("Установить новый статус")}:</small>
                <hr/>
                {Number(projectStatus) === 8 ? (<div
                        className={"change_status_variant"}
                        onClick={() => onUpdateStatus(projectId, {status: "1"})}>
                        <span>
                            <span className={"badge bg-" + "primary"}>
                                {getT("В редактирование")}
                            </span>
                        </span>
                    </div>) : (<>
                        {rules?.[projectStatus]?.includes(-1) || Number(projectId) === 1 ? <div key={-1} className={"change_status_variant"}
                                                                     onClick={() => onDeleteProductionTaskThunk(projectId)}
                        >
                        <span>
                        <span className={"badge bg-danger"}>  {getT('Расформировать задание')} </span>
                        </span>
                        </div>: ''}
                        {!!renderStatus?.length ? renderStatus?.map((s) => {
                            return (<div key={s.id} className={"change_status_variant"}
                                         onClick={() => {
                                             onUpdateStatus(projectId, {status: s.id});
                                             setVisible(false);
                                         }
                                         }
                                >
                        <span>
                        <span className={"badge bg-" + s.color}>  {getT(s.name)} </span>
                        </span>
                                </div>);

                        }) : ''}
                    </>

                )}
            </div>
        </div>);
};


export default StatusProjectList;