import React from 'react';
import {useForm} from "react-hook-form";
import {LS_FIRM} from "../../../../../utils/constants";

/**
 * OrderSupplyForm. Форма для создания, редактирования "Заказа для поставщика"
 *
 * @param {array || null} wholesaleFirms  - список подтвержденных оптовиков
 * @param {null || object}  initial_values - текущий заказ потставщику
 * @param {function}  onSend - ф-ция для отпарвки данных на верх
 * @param {function}  getT -
 * @returns  {jsx}  -
 */
const OrderSupplyForm = ({getT, onSend, initial_values, wholesaleFirms}) => {
    let day = initial_values?.date ? new Date(initial_values?.date) : new Date()
    let today = day.toISOString().split('T')[0];
    let time = `${day.getHours() >= 10 ? day.getHours() : `0${day.getHours()}`}:${day.getMinutes() >= 10 ? day.getMinutes() : `0${day.getMinutes()}`}`

    const {register, handleSubmit, formState: {errors}} = useForm({
        values: {
            firm_main: initial_values?.firm_main?.id ?? '',
            name: initial_values?.name ?? '',
            today: today,
            time: time,
            ws_orders_supply_id: initial_values?.ws_orders_supply_id ?? null,
            status: initial_values?.status?.id ?? null,
            firm_partner: Number(localStorage.getItem(LS_FIRM))
        }
    });
    const onSubmit = (data) => {
        let {time, today, ...reset} = data;
        onSend({...reset, date: `${today} ${time}:00`});
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group row align-items-end" style={{rowGap: "15px"}}>
                <div className="col-12 col-md-6">
                    <label htmlFor="currency">{getT("Название заказа")}</label>
                    <input type="text" className="form-control" id="" required={true}
                           {...register('name')} />

                    {errors.name && <span className="text-danger">{errors.name.message}</span>}
                </div>

                <div className="col-12 col-md-6">
                    <label htmlFor="currency">{getT("Поставщик")}</label>
                    <select id="firm_partner" className="form-control"
                            {...register('firm_main', {
                                setValueAs: (v) => Number(v)
                            })}>
                        <option value={''}>---------</option>
                        {wholesaleFirms?.map(e => {
                            return <option value={e?.main_firm}>{e?.firm_main_name}</option>
                        })}
                    </select>
                    {errors.firm_main && <span className="text-danger">{errors.firm_main.message}</span>}
                </div>
                <div className="col-12 col-md-6">
                    <label htmlFor="exampleInputEmail1">{getT("Дата")}</label>
                    <input {...register("today", {})} className="form-control" type={"date"} required={true}
                           min={today.split('T')[0]}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <label htmlFor="exampleInputEmail1">{getT("Время")}</label>
                    <input {...register("time", {})} className="form-control" type={"time"} min="00:00" max="23:59"
                           pattern="[0-2][0-9]:[0-5][0-9]"
                           required={true}/>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <button className={"btn btn-primary"}>{getT("Отправить")}</button>
            </div>
        </form>
    );
};

export default OrderSupplyForm;