import React from "react";
import Header from "./Header.jsx";
import {connect} from "react-redux";
import "./Header.css";
import {
    setNotifyDataThunk,
    toggleLangMenuAC,
    toggleNotifyOpenStatusAC,
    toggleSideBarTypeAC,
    updateNotifyDataThunk
} from "../../store/reducers/MainReducer";
import {changeLangApp, onSendCollection} from "../../store/reducers/TranslationReducer";
import {LS_USER_ID} from "../../utils/constants";

class HeaderContainer extends React.Component {

    componentDidMount() {
        const is_user_authorized = !!Number(localStorage.getItem(LS_USER_ID));
        if (is_user_authorized) {
            this.props.setNotifyData();
            setInterval(() => {
                this.props.setNotifyData();
            }, 300000);
        }
    }

    render() {
        return (
            <Header
                sidebarCollapse={this.props.sidebarCollapse}
                toggleSidebar={this.props.toggleSidebar}
                lang={this.props.lang}
                toggleNotify={this.props.toggleNotify}
                updateNotify={this.props.updateNotify}
                notifyOpen={this.props.notifyOpen}
                notify={this.props.notify}
                toggleLangMenu={this.props.toggleLangMenu}
                localLang={this.props.localLang}
                onChangeLang={this.props.onChangeLang}
                onSendCollectionWords={this.props.onSendCollectionWords}
            />
        );
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        toggleSidebar: (view) => dispatch(toggleSideBarTypeAC(view)),
        toggleLangMenu: (status) => dispatch(toggleLangMenuAC(status)),
        setNotifyData: () => dispatch(setNotifyDataThunk()),
        updateNotify: (id, link) => dispatch(updateNotifyDataThunk(id, link)),
        toggleNotify: (status) => dispatch(toggleNotifyOpenStatusAC(status)),
        onChangeLang: (lang) => dispatch(changeLangApp(lang)),
        onSendCollectionWords: () => dispatch(onSendCollection())
    };
};

let mapStateToProps = (state) => {
    return {
        sidebarCollapse: state.main.sidebarCollapse,
        lang: state.main.lang,
        notifyOpen: state.main.notifyOpen,
        notify: state.main.notify,
        localLang: state.translation.local
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
