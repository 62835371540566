import React from 'react';
import Modal from "../../../../components/Modals/MyModal/Modal";
import Select from "react-select";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {
    getWSCompanyClientList,
    getWSConnectionRequestCompanyClientModal
} from "../../../../store/selectors/wholesalary_selector";

const WsConnectionClientModal = ({getT,  handlerCreateConnection, handlerClose}) => {
    const modal_connection = useSelector(getWSConnectionRequestCompanyClientModal)
    const company_client_list = useSelector(getWSCompanyClientList);


    const {watch, setValue, handleSubmit} = useForm({
        values: {
            client: {
                label: '', value: ''
            },
            active: 1,
            main_firm: modal_connection?.data?.main_firm,
            partner_firm: modal_connection?.data?.partner_firm
        }
    })

    if (!modal_connection.isOpen || !company_client_list) return null
    return (
        <Modal open={modal_connection?.isOpen} onClose={handlerClose} title={getT("Подтвердить заявку")}>
            <form action="" onSubmit={handleSubmit((data) => {
                let {client, ...reset} = data;
                handlerCreateConnection({...reset, client: client?.value})
            })}>
                <div className="form-group">
                    <label>{getT("Клиент")}</label>
                    <Select
                        placeholder={getT("Поиск")}
                        defaultValue={watch("client")}
                        options={company_client_list?.map(item => {
                            return {
                                value: item?.id,
                                label: `${item?.name} \n ${item?.email} \n ${item?.phone}`
                            }
                        })}
                        onChange={(selectOptions) => {
                            setValue("client", selectOptions)
                        }}
                    />

                </div>
                <div className="mt-2 text-center">
                    <button className={"btn btn-primary"} type={"submit"}>{getT("Отправить")}</button>
                </div>
            </form>
        </Modal>
    );
};

export default WsConnectionClientModal;