import getT from "../getT";
import {PROCESSING_EDGE_KEY_ASSOCIATION} from "../../utils/constants";

export default function ({bands, parts}) {
    const total_counts = parts?.reduce((acc, item) => Number(item?.count) + Number(acc), 0);
    return `
    <table>
      <caption>${getT("Детали")}:   </caption>
        <thead>
    <tr>
      <th rowspan="2">#</th>
      <th rowspan="1" colspan="2">${getT("Пильный размер")}</th>
      <th rowspan="1" colspan="2">${getT("Размер с кромкой")}</th>
      <th  rowspan="2">${getT("Кол-во")}</th>
      <th  rowspan="2">${getT("Верх")}</th>
      <th  rowspan="2">${getT("Низ")}</th>
      <th  rowspan="2">${getT("Лево")}</th>
      <th  rowspan="2">${getT("Право")}</th>
      <th  rowspan="2">${getT("Текстура")}</th>
      <th  rowspan="2">${getT("Тип кромкования")}</th>
      <th  rowspan="2">${getT("Описание")}</th>
    </tr>
    <tr>
    <th>${getT("Длина")}</th>
    <th>${getT("Ширина")}</th>
    <th>${getT("Длина")}</th>
    <th>${getT("Ширина")}</th>
</tr>
  </thead>
        <tbody>
  ${parts?.map((part, index) => {
        let edge_edit_side = Object.entries(part?.edge_edit)?.reduce((acc, [key, value]) => {
            let association_keys = PROCESSING_EDGE_KEY_ASSOCIATION?.[key] ?? null;
            if (!value) return acc

            for (const [key_side, edge_value] of Object.entries(value)) {
                acc = {
                    ...acc,
                    [key_side]: {
                        ...edge_value,
                        title: getT(association_keys.title),
                        chart_key: association_keys?.chart,
                        type_key_edge: key,
                        name_edge_edit: association_keys?.chart == "C" && !edge_value?.hasOwnProperty(association_keys.edge_id_key) ? getT(association_keys?.type_adding?.custom?.title) : edge_value?.name,

                    }
                }
            }
            return acc
        }, {})
        let find_band = (band_id) => (item) => Number(item?.id) === Number(band_id);
        let band_t = bands?.findIndex(find_band(part.edge.t?.db_id)) >= 0 ? bands?.findIndex(find_band(part.edge.t?.db_id)) + 1 : '';
        let band_b = bands?.findIndex(find_band(part.edge.b?.db_id)) >= 0 ? bands?.findIndex(find_band(part.edge.b?.db_id)) + 1 : '';
        let band_l = bands?.findIndex(find_band(part.edge.l?.db_id)) >= 0 ? bands?.findIndex(find_band(part.edge.l?.db_id)) + 1 : '';
        let band_r = bands?.findIndex(find_band(part.edge.r?.db_id)) >= 0 ? bands?.findIndex(find_band(part.edge.r?.db_id)) + 1 : '';
        let display_total = (parts?.length - 1) === index
        let cut_to = part?.operations?.cut_to?.length ? part?.operations?.cut_to?.[0] : null
        let x_value = cut_to?.l || cut_to?.r ? !!(cut_to?.l && cut_to?.r) ? Math.min(cut_to?.l, cut_to?.r) : (cut_to?.l || cut_to?.r) : part?.x1;
        let y_value = cut_to?.t || cut_to?.b ? !!(cut_to?.t && cut_to?.b) ? Math.min(cut_to?.t, cut_to?.b) : (cut_to?.t || cut_to?.b) : part?.y1;
        let add_to_detail =Array.isArray(part?.plane_operations) ? Math?.max(part?.plane_operations?.map(e => e?.add_to_detail)) : 0
        // console.log('add_to_detail', add_to_detail)
        let is_has_hem = !!part?.operations?.hem?.length;
        let x = add_to_detail ?  Number(part?.x) + Number(add_to_detail) : part?.x
        let y = add_to_detail ? Number(part?.x) + Number(add_to_detail) : part?.y
        return `
            <tr>
                <td>${part?.id}</td>
                <td>${x}</td>
                <td>${y}</td>
                 <td>${x_value}</td>
                <td>${y_value}</td>
                <td>${part?.count}</td>
                <td>${band_t} ${edge_edit_side?.t?.name_edge_edit ? ` | ${edge_edit_side?.t?.name_edge_edit}` : ''}</td>
                <td>${band_b} ${edge_edit_side?.b?.name_edge_edit ? ` | ${edge_edit_side?.b?.name_edge_edit}` : ''}</td>
                <td>${band_l} ${edge_edit_side?.l?.name_edge_edit ? ` | ${edge_edit_side?.l?.name_edge_edit}` : ''}</td>
                <td>${band_r} ${edge_edit_side?.r?.name_edge_edit ? ` | ${edge_edit_side?.r?.name_edge_edit}` : ''}</td>
                <td>${!!Number(part?.texture) ? '+' : ''}</td>
                <td>${part?.edge_type_part}</td>
                   <td> ${is_has_hem ? getT("Сшивка") : " "} <br/>
${part?.info_user}</td>
            </tr>
        `
    })?.join('')}
  <tr>
    <th colspan="4" class="b-left-hide b-bottom-hide"></th>
    <th colspan="1">${getT("Всего")}</th>
    <th colspan="1"> ${total_counts}</th>
    <th colspan="7"  class="b-right-hide b-bottom-hide"></th>
</tr>
     </table>
    `
}
