import React from 'react';
import Modal from "../MyModal/Modal";
import Form from "./Form";
import {packagingPrintingHtml} from "../../../store/reducers/MainReducer";
import {useDispatch} from "react-redux";

import "./main.css"

const PackagingPrintingModal = ({isOpen, onClose, getT, order}) => {
    const dispatch = useDispatch();
    const onSendPrint = (form_data)=>{

        dispatch(packagingPrintingHtml({settings_for_print: form_data, projectOrder: order}))
    }
    return (
        <Modal  onClose={onClose} open={isOpen} key={'packaging-print-modal'} title={getT("Печать")}>
                <Form getT={getT} onSendPrint={onSendPrint}/>
        </Modal>
    );
};

export default PackagingPrintingModal;