import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {createFieldsForListChecked, updateServiceType} from "../read_mark_util";

/**
 * Услуги заказа.
 *
 * @param {object} order_service - услуги
 * @param {object} shift_form_values - данные о смене
 * @param {number}  order_id- айди заказа
 * @param {function} getT - translate
 * @returns  {jsx}  -
 */
const OrderService = ({order_service, getT}) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [fields, setFields] = useState({});

    useEffect(() => {
        let result_fields = {};
        if (!order_service || !isOpen) {
            setFields(result_fields);
            return;
        }
        result_fields = createFieldsForListChecked(order_service);
        setFields(result_fields)
    }, [order_service, isOpen]);


    //** обновление типа услуги. вкл.выкл*/
    const handlerChangeServiceType = (services_item) => (e) => {
        let checked = e.target.checked;
        let key = `service_${services_item.serv_type}`
        let result_service_type = updateServiceType(fields[key], checked);
        setFields((prevState) => ({
            ...prevState,
            [key]: {
                ...result_service_type
            }
        }))
    }
    //** обновление  услуги. вкл.выкл*/

    return (<div className={'my-2  py-2 px-0 border-top'}>
        <div className="card-header border-0 px-0 py-0" onClick={() => setIsOpen(!isOpen)}>
            <strong className="">{ getT("Услуги") }</strong>
            <i className={`ml-2 fas fa-angle-${isOpen ? 'up' : 'down'}`}></i>
        </div>
        {isOpen && <div className={''}>
            <ul className="list-group mt-2">
                {order_service?.map(service => {
                    let field_service = fields[`service_${service?.serv_type}`];
                    return <li className="list-group-item py-1 px-0 fs-14 border-0" key={`service_root_${service?.serv_type}`}>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" style={{pointerEvents: "none"}}
                                   checked={field_service?.isChecked}
                                   id={`service_root_${service?.serv_type}`}

                            />
                            <label className="form-check-label" htmlFor={`service_root_${service?.serv_type}`}>
                                {service?.name_service_type}
                                <i className='fas fa-info-circle cursor-pointer  text-primary ml-1'
                                   title={service?.description_service_type}></i>
                            </label>
                        </div>
                        {service?.children?.map(child => {
                            let field_current = field_service?.children?.find(({id}) => Number(id) === Number(child?.services))
                            return <div className="form-check ml-2" key={`service_child_${child?.services}`}>
                                <input className="form-check-input" type="checkbox"
                                       checked={field_current?.isChecked}
                                       id={`service_child_${child?.services}`}
                                       style={{pointerEvents: "none"}}
                                />
                                <label className="form-check-label" htmlFor={`service_child_${child?.services}`}>
                                    {child?.name_service} <i
                                    className='fas fa-info-circle cursor-pointer text-primary ml-1'
                                    title={child?.description_service}></i>
                                </label>
                            </div>
                        })}

                    </li>
                })}
            </ul>

        </div>
        }
    </div>);
};

export default OrderService;