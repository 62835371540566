import React, {useEffect, useMemo, useState} from "react";
import "./ResponsiveTable.css";
import PageTitle from "../PageTitle/PageTitle";

import {v4 as uuid} from "uuid";
import {DebounceInput} from "react-debounce-input";
import {useDispatch, useSelector} from "react-redux";
import {setMaterialGoods1CSystem} from "../../store/reducers/MainReducer";
import ApiService from "../../api/api";
import {withLang} from "../../hoc/withLang";
import OrderComment from "./OrderComment";
import CuttingCard from "../Modals/CuttingCard";
import useClickOutside from "../../hook/useClickOutside";
import LabelsPrintCount from "../Modals/LabelsPrintCount";
import {gaEvents} from "../../gaEvents";
import PackagingPrintingModal from "../Modals/PackagingPrintingModal";
import {
    getOrderDetailWholesaleGoods,
    getOrderDetailWholesaleGoodsInformation,
    getVatAddPriceIfMoreThan
} from "../../store/selectors/main_selector";
import {getCalculateAmountAndVat, getCalculateVat} from "../../utils/helpers";
import ShortFormOrderConfirmModal from "../Modals/ShortFormOrderConfirmModal";
import {sheetDataPrintConfirmModalAC} from "../../store/actions/MainActions";
import OrderControlWrapper from "./components/OrderControlWrapper";
import {updateSettingsInOrderThunk} from "../../store/thunks/order_detail_thunk";
import WsGoodsInformationModal from "../Modals/WholeSale/WsGoodsInformationModal";
import {wsGetGoodsInformationModalAC} from "../../store/actions/WholesaleOrdersGoodsActions";

const OrderDetail = (props) => {
    const production_vat = useSelector(getVatAddPriceIfMoreThan)
    const dispatch = useDispatch();
    const {setVisible: setOpenPrint, ref, isVisible: openPrint} = useClickOutside()
    const [isModalCuttingCard, modalCuttingCard] = useState(false);
    const [isModalPackagingPrinting, modalPackagingPrinting] = useState(false);
    //** wsOrderGoods. список возможных потребностей по текущему заказу
    // @var {exist} array || null -
    // @var {need} array || null -
    // */
    const wsOrderGoods = useSelector(getOrderDetailWholesaleGoods);
    /**  Список подробной информации о товаре и его поставщиках.  wsGoodsInformation
     *  @return {null || array} -wsGoodsInformation
     */
    const wsGoodsInformation = useSelector(getOrderDetailWholesaleGoodsInformation)


    const handlerUpdateSettingsInOrder = (order_id) => {
        dispatch(updateSettingsInOrderThunk(order_id))
    }
    // const on
    const confirmedProductionBtn = props?.status?.find(status => Number(status?.id) === 3);
    const [modal, setModal] = useState({
        isOpen: false,
        action: '',
        grid: 1
    });
    const onChangeValueModal = (values) => {
        setModal(prevState => ({
            ...prevState,
            ...values
        }))
    }
    const onSubmitLabelsPrint = () => {
        props.getLabelsPrintHtml({action: modal.action, project: props.order.project_data, grid: modal.grid});

    }

    const getDiscount = (id, type) => {
        if (props.discounts.material && props.discounts.service) {
            if (type === "material") {
                return props.discounts.material.filter(
                    (e) => Number(e.id) === Number(id)
                )?.[0]?.price
                    ? props.discounts.material.filter(
                        (e) => Number(e.id) === Number(id)
                    )?.[0]?.price
                    : 0;
            }
            if (type === "service") {
                return props.discounts.service.filter(
                    (e) => Number(e.id) === Number(id)
                )?.[0]
                    ? props.discounts.service.filter(
                        (e) => Number(e.id) === Number(id)
                    )?.[0]?.price
                    : 0;
            }
        }
    };
    const openService = () => {
        let link = ''
        if (props.order?.hasOwnProperty("production_constants") && props.order?.production_constants?.["production.old_interface_on"] === "1") {
            link = `${new ApiService()._mainLink}/project/${props.order?.code}/${props.order?.last_project}/${props.order?.client?.id}/${localStorage.getItem("i_furn_user_firm")}`
        } else {
            link = `${new ApiService()._baseUrlProject}${props.order?.code}/${props.order?.last_project}/${props.order?.client?.id}/${localStorage.getItem("i_furn_user_firm")}/${localStorage.getItem("i_furn_user_id")}`;
        }
        window.open(link);
    };
    const checkServiceReady = (id) => {
        let service =
            props.order &&
            props.order.services_ready &&
            props.order.services_ready.filter(
                (e) => Number(e.services) === Number(id)
            )[0];

        if (service != undefined && service.d_update != undefined) {
            let date = new Date(service.d_update);
            return service && Number(service.service_ready)
                ? date.toLocaleString("ru", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                    timezone: "UTC"
                })
                : false;
        } else {
            return false;
        }

    };

    const getTotal = (calculate) => {
        if (calculate && calculate.materials) {
            let total = Number(0);
            total += calculate.materials.reduce(function (accumulator, currentValue) {
                return (
                    accumulator +
                    currentValue.count *
                    (getDiscount(currentValue.goods, "material")
                        ? getDiscount(currentValue.goods, "material")
                        : currentValue.price)
                );
            }, 0);
            total += calculate.services.reduce(function (accumulator, currentValue) {
                return (
                    accumulator +
                    currentValue.count *
                    (getDiscount(currentValue.services, "service")
                        ? getDiscount(currentValue.services, "service")
                        : currentValue.price)
                );
            }, 0);
            total += calculate.extra.reduce(function (accumulator, currentValue) {
                return accumulator + currentValue.count * currentValue.price;
            }, 0);
            return total.toFixed(2);
        } else {
            return 0;
        }
    };
    const getStatus = (status) => {
        let status_data = props.status.filter((e) => e.name === status)[0];
        if (status_data) {
            return status_data;
        } else {
            status_data = props.status.filter(
                (e) => Number(e.id) === Number(status)
            )[0];
            if (status_data) {
                return status_data;
            } else {
                return {
                    color: "danger",
                    name: props.getT("Не указано")
                };
            }
        }
    };

    const checkStatus = (user_roles, status_roles) => {
        let status = false;
        user_roles.forEach((e) => {
            if (status_roles.includes(Number(e.id))) {
                status = true;
            }
        });
        return status;
    };
    const handlerShortFormOrder = ({order, full}) => {
        let logo = ''
        let company_name = ''
        dispatch(sheetDataPrintConfirmModalAC({isOpen: true, order: order, full: full}))

    }
    const total_vat = useMemo(() => {
        return getCalculateVat(props.order.confirm.cost, production_vat)
    }, [props.order.confirm.cost, production_vat]);
    const amount_total_vat = useMemo(() => {
        return getCalculateAmountAndVat(props.order.confirm.cost, total_vat)
    }, [props.order.confirm.cost, getTotal])

    /**
     *  открыть модальное окно информация по товару, и создать потребность, или обновить существующую
     * @param {object || null} goods  - товар
     * @param {object || null} exist  - существующея потребность товара
     * @param {object || null} informationCurrentGoods  -Список подробной информации о товаре и его поставщиках.
     */
    const onOpenGoodsInfoModal = (goods, exist = null, informationCurrentGoods) => {
        let goodsId = Number(goods?.goods);
        let currentGoods = {
            orders_goods_id: goods?.orders_goods_id,
            ws_orders_goods_id: goods?.ws_orders_goods_id ?? null,
            title: goods?.name,
            id: goodsId
        }
        dispatch(wsGetGoodsInformationModalAC({
            availabilityInfo: informationCurrentGoods,
            currentGoods: currentGoods,
            isOpen: true,
            orderId: props?.order?.id,
            exist: exist ?? null
        }))
        // dispatch(wsOpenGoodsInformationModalForOrderDetailThunk(currentGoods, props?.order?.id, exist, informationCurrentGoods))
    }

    return (<>
            <div className="content-wrapper">
                <PageTitle title={"orders_detail"}/>
                <section className="content">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">{props.getT("Основная информация")}</h3>
                            <div className="card-tools">
                                <button
                                    type="button"
                                    className="btn btn-tool"
                                    data-card-widget="collapse"
                                    title="Collapse"
                                    onClick={() =>
                                        props.editOrder(!props.isEdit, props.orderID, props.order)
                                    }
                                >
                                    <i className="fas fa-edit"> </i>
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-tool"
                                    data-card-widget="collapse"
                                    title="Collapse"
                                >
                                    <i className="fas fa-minus"> </i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 order-2 order-md-1">
                                    <div className="row">
                                        <div className="col-12 col-sm-3">
                                            <div className="info-box bg-light">
                                                <div className="info-box-content">
                        <span className="info-box-text text-center text-muted">
                         {props.getT(" Сумма заказа")}
                        </span>
                                                    <span className="info-box-number text-center text-muted mb-0">
                          {props.order.confirm.cost} {!!production_vat && <>+{production_vat}% {props.getT("ндс")} = {amount_total_vat}</>} {props.order.currency.name}
                        </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <div className="info-box bg-light">
                                                <div className="info-box-content">
                        <span className="info-box-text text-center text-muted">
                          {props.getT("Дата заказа")}
                        </span>
                                                    <span className="info-box-number text-center text-muted mb-0">
                          {props.order.date}
                        </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <div className="info-box bg-light">
                                                <div className="info-box-content">
                        <span className="info-box-text text-center text-muted">
                          {props.getT("Клиент")}
                        </span>
                                                    <span className="info-box-number text-center text-muted mb-0">
                          <a href={"/clients/detail/" + props.order.client.id}>
                            {props.order.client.name}
                          </a>
                        </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-3">
                                            <div className="info-box bg-light">
                                                <div className="info-box-content">
                        <span className="info-box-text text-center text-muted">
                          {props.getT("Количество деталей")}
                        </span>
                                                    <span className="info-box-number text-center text-muted mb-0">
                                                        {props.order?.count_detail ?? 0}
                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-md-6 col-lg-6 order-1 order-md-2">
                                    {props.isEdit ? (
                                        <div className="form-group">
                                            <input
                                                className="form-control"
                                                type={"email"}
                                                value={props.order.name}
                                                onChange={(event) =>
                                                    props.changeValue("name", event.target.value)
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <h3 className="text-primary">
                                            <i className="far fa-folder"> </i> {props.order.name}
                                        </h3>
                                    )}
                                    <div className="text-muted">
                                        <p className="text-sm mb-0">
                                            {props.getT("Комментарий")}: </p>
                                        <OrderComment onSend={(value, cb) => props.onUpdateComment({
                                            orderId: props.order.id,
                                            comment: value,
                                            cb
                                        })} comment={props.order.comment} getT={props.getT}/>

                                    </div>
                                    <br/>
                                    <div className="text-muted">
                                        <p className="text-sm">
                                            {props.getT("Номер заказа")}:
                                            <b className="d-block">
                                                {props.order.code_reg} ({props.order.code} /{" "}
                                                {props.order.id})
                                            </b>
                                        </p>
                                        <p className="text-sm">
                                            {props.getT("Номер заказа 1C")}:
                                            <b className="d-block">{props.order.code1c}</b>
                                        </p>
                                        <p className="text-sm">
                                            {props.getT('Валюта заказа')}:
                                            <b className="d-block">{props.order.currency.name}</b>
                                        </p>
                                        <p className="text-sm">
                                            {props.getT("Наличие ЧПУ операций")}:
                                            <b className="d-block">
                                                {Number(props?.order?.xnc_operations) === 1
                                                    ? props.getT("присутствуют")
                                                    : props.getT("отсутствуют")}
                                            </b>
                                        </p>
                                        <p className="text-sm">
                                            {props.getT("Менеджер")}:
                                            <b className="d-block">
                                                {props.order.manager.name ? (
                                                    props.order.manager.name
                                                ) : (
                                                    <>
                                                        <span>{props.getT("Не указано")}</span>
                                                        <button
                                                            className={"btn btn-success d-flex"}
                                                            onClick={() =>
                                                                props.updateOrder(props.order.id, {
                                                                    user: localStorage.getItem("i_furn_user_id")
                                                                })
                                                            }
                                                        >
                                                            {props.getT("Взять в работу")}
                                                        </button>
                                                    </>
                                                )}
                                            </b>
                                        </p>
                                        <p className="text-sm">
                                            <div>{props.getT("Статус")}:</div>

                                            <div className="order_status_container">
                                              <span
                                                  className={
                                                      "badge bg-" + getStatus(props.order.status).color
                                                  }
                                              >

                                                {props.getT(getStatus(props.order.status).name)}
                                              </span>


                                                <div
                                                    className={
                                                        "orders_status_change_container" +
                                                        (Number(props.order.id) ===
                                                        Number(props.changedStatus)
                                                            ? " active"
                                                            : "")
                                                    }
                                                >
                                                    <small> {props.getT("Установить новый статус")}:</small>
                                                    <hr/>
                                                    {props.order.status === "8" ? (
                                                        <div
                                                            className={"change_status_variant"}
                                                            onClick={() => {
                                                                gaEvents.customEvent('klik_form', {
                                                                    order_change_status: 'change status'
                                                                })
                                                                props.updateOrder(props.order.id, {
                                                                    status: "1"
                                                                })
                                                            }
                                                            }
                                                        >
                            <span>
                              <span
                                  onClick={() => {

                                      props.toggleChangeStatus(
                                          Number(props.order.id) ===
                                          Number(props.changedStatus)
                                              ? null
                                              : props.order.id
                                      )
                                  }
                                  }
                                  className={"badge bg-" + "primary"}
                              >
                                {props.getT("В редактирование")}
                              </span>
                            </span>
                                                        </div>
                                                    ) : (
                                                        props.status.map((s) => {
                                                            if (checkStatus(props.userRoles, s.roles)) {
                                                                return (
                                                                    <div
                                                                        key={uuid()}
                                                                        className={"change_status_variant"}
                                                                        onClick={() => {
                                                                            gaEvents.customEvent('klik_form', {
                                                                                order_change_status: 'change status'
                                                                            })
                                                                            props.updateOrder(props.order.id, {
                                                                                status: s.id
                                                                            })
                                                                        }}
                                                                    >
                                  <span>
                                    <span
                                        onClick={() =>
                                            props.toggleChangeStatus(
                                                Number(props.order.id) ===
                                                Number(props.changedStatus)
                                                    ? null
                                                    : props.order.id
                                            )
                                        }
                                        className={"badge bg-" + s.color}
                                    >
                                      {s.name}
                                    </span>
                                  </span>
                                                                    </div>
                                                                );
                                                            }
                                                        })
                                                    )}
                                                </div>
                                            </div>
                                        </p>
                                        <p>
                                            {(!(props?.user?.role?.filter(role => confirmedProductionBtn.roles.include(Number(role?.id)))?.length) && Number(props?.order?.status) === 2) ?
                                                <div>
                                                    {/*<div>{props.getT("Статус")}:</div>*/}
                                                    <button
                                                        onClick={() => {
                                                            gaEvents.customEvent('klik_form', {
                                                                order_change_status: 'change status'
                                                            })
                                                            props?.updateOrder(props?.orderID, {
                                                                status: 1,
                                                                user: props?.userId
                                                            }, '/orders/actual');

                                                            // return <Redirect to={'/orders/actual'}/>

                                                        }}
                                                        className={`btn  btn-animate_bg badge bg-primary `}>{props?.getT('Вернуть в редактирование')}</button>
                                                </div>
                                                : null}
                                        </p>
                                        <p>
                                            {!(props?.user?.role?.filter(role => confirmedProductionBtn.roles.include(Number(role?.id)))?.length)
                                            && Number(props?.order?.status) === 2 ?
                                                <div
                                                    title={`${(!(props?.user?.role?.filter(role => confirmedProductionBtn.roles.include(Number(role?.id)))?.length) && Number(props?.order?.status) === 2) ?
                                                        '' : props.getT('Доступно только для менеджера или seo')}`}>

                                                    <button

                                                        onClick={() => props?.updateOrder(props?.orderID, {
                                                            status: confirmedProductionBtn?.id,
                                                            user: props?.userId
                                                        })}
                                                        className={`btn 
                                                            ${(!(props?.user?.role?.filter(role => confirmedProductionBtn.roles.include(Number(role?.id)))?.length) && Number(props?.order?.status) === 2) ? ('btn-animate_bg badge bg-' + getStatus(props.order.status).color) : 'btn-secondary disabled'}
                                                            `}>{props?.getT('Подтвердить в производство')}</button>
                                                </div>
                                                : null}

                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="col-12 col-sm-6 col-md-6 col-lg-6 order-1 order-md-2 order-detail-pdf-container">
                                    <OrderControlWrapper order={props.order} getPDF={props.getPDF} getT={props.getT}
                                                         key={`order_control_wrapper`}
                                                         onChangeValueModal={onChangeValueModal}
                                                         getGiblabDataThunk={props.getGiblabDataThunk}
                                                         onSetFileManager={props.onSetFileManager}
                                                         handlerShortFormOrder={handlerShortFormOrder}
                                                         modalCuttingCard={modalCuttingCard}
                                                         modalPackagingPrinting={modalPackagingPrinting}
                                                         openService={openService}
                                                         handlerUpdateSettingsInOrder={handlerUpdateSettingsInOrder}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">{props.getT("Контактная информация")}</h3>
                            <div className="card-tools">
                                <button
                                    type="button"
                                    className="btn btn-tool"
                                    data-card-widget="collapse"
                                    title="Collapse"
                                    onClick={() => {
                                        if (!props.isEdit) {
                                            gaEvents.customEvent('klik_form', {
                                                btn_edit_contact_data: 'Edit Контактная информация',
                                            })
                                        }
                                        props.editOrder(!props.isEdit, props.orderID, props.order)
                                    }}
                                >
                                    <i className="fas fa-edit"> </i>
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-tool"
                                    data-card-widget="collapse"
                                    title="Collapse"
                                >
                                    <i className="fas fa-minus"> </i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-4 col-md-4 col-lg-4 order-1 order-md-2">
                                    <div className="text-muted">
                                        <p className="text-sm">
                                            {props.getT("E-mail")}:
                                            <b className="d-block">{props.order.confirm.email}</b>
                                        </p>
                                        <p className="text-sm">
                                            {props.getT("Основной телефон")}:
                                            {props.isEdit ? (
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type={"email"}
                                                        value={props.order.client.phone}
                                                        onChange={(event) => {
                                                            gaEvents.customEvent('klik_form', {
                                                                form_order_detail_contact_data: 'Основной телефон',
                                                            })
                                                            props.changeValue(
                                                                "client_phone_main",
                                                                event.target.value
                                                            )
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <b className="d-block">{props.order.client.phone}</b>
                                            )}
                                        </p>
                                        <p className="text-sm">
                                            {props.getT("Телефон для вопросов по заказу")}:
                                            {props.isEdit ? (
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type={"email"}
                                                        value={props.order.client.phone_questions}
                                                        onChange={(event) => {
                                                            gaEvents.customEvent('klik_form', {
                                                                form_order_detail_contact_data: 'Телефон для вопросов по заказу',
                                                            })
                                                            props.changeValue(
                                                                "client_phone_questions",
                                                                event.target.value
                                                            )
                                                        }

                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <b className="d-block">
                                                    {props.order.client.phone_questions}
                                                </b>
                                            )}
                                        </p>
                                        <p className="text-sm">
                                            {props.getT("Телефон для SMS")}:
                                            {props.isEdit ? (
                                                <div className="form-group">
                                                    <input
                                                        className="form-control"
                                                        type={"email"}
                                                        value={props.order.client.phone_sms}
                                                        onChange={(event) => {
                                                            gaEvents.customEvent('klik_form', {
                                                                form_order_detail_contact_data: 'Телефон для SMS',
                                                            })
                                                            props.changeValue(
                                                                "client_phone_sms",
                                                                event.target.value
                                                            )
                                                        }
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <b className="d-block">{props.order.client.phone_sms}</b>
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">{props.getT("Адреса")}</h3>
                            <div className="card-tools">
                                <button
                                    type="button"
                                    className="btn btn-tool"
                                    data-card-widget="collapse"
                                    title="Collapse"
                                >
                                    <i className="fas fa-minus"> </i>
                                </button>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                                    <div className="text-muted">
                                        <p className="text-sm">
                                            {props.getT("Точка продажи")}:
                                            <b className="d-block">{props.order.adress.sale}</b>
                                        </p>
                                        <p className="text-sm">
                                            {props.getT("Точка производства")}:
                                            <b className="d-block">{props.order.adress.production}</b>
                                        </p>
                                        <p className="text-sm">
                                            {props.getT("Способ доставки")}:
                                            <b className="d-block">
                                                {props.order.adress.delivery_method}
                                            </b>
                                        </p>
                                        <p className="text-sm">
                                            {props.getT(" Адрес доставки (самовывоза)")}:
                                            <b className="d-block">{props.order.adress.store}</b>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.editDiscount ? (
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{props.getT("Установка скидок")}</h3>
                                <div className="card-tools">
                                    <button
                                        type="button"
                                        className="btn btn-tool"
                                        data-card-widget="collapse"
                                        title="Collapse"
                                        onClick={() =>
                                            props.discountEdit(
                                                !props.editDiscount,
                                                props.order.project_data.calculate,
                                                props.order.currency,
                                                props.order.project_data,
                                                props.discounts,
                                                props.order.last_project,
                                                getTotal(props.order.calculate),
                                                props.orderID
                                            )
                                        }
                                    >
                                        <i className="fas fa-edit"> </i>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-tool"
                                        data-card-widget="collapse"
                                        title="Collapse"
                                    >
                                        <i className="fas fa-minus"> </i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                                        <div className="table-responsive">
                                            <table className="table m-0 table-hover">
                                                <thead>
                                                <tr>
                                                    {/* <th scope="col">Код</th> */}

                                                    <th scope="col">{props.getT("Название")}</th>
                                                    <th></th>
                                                    <th scope="col">{props.getT("Количество")}</th>
                                                    <th scope="col">{props.getT("Тип рассчета")}</th>
                                                    <th scope="col">{props.getT("Цена")}</th>
                                                    <th scope="col">{props.getT("Цена (со скидкой)")}</th>
                                                    <th scope="col">{props.getT("Сумма")}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr className={"bg-lightgray"}>
                                                    <td colSpan="7" className={"text-center"}>
                                                        <b>{props.getT("Материалы и товары")}</b>
                                                    </td>
                                                </tr>
                                                {props.order.calculate.materials.map((e) => (
                                                    <tr key={uuid()}>
                                                        {/* <td>{e.goods}</td> */}
                                                        <td>[{e.goods}] {e.name}</td>
                                                        <td></td>
                                                        <td>{Number(e.count).toFixed(3)}</td>
                                                        <td>
                                                            {props.order.calculate_types &&
                                                            props.order.project_data.calculate?.material?.filter(
                                                                (cls) => Number(cls.id) === Number(e.goods)
                                                            )?.[0]?.calc_types ? (
                                                                <div className="form-group">
                                                                    <select
                                                                        className={"custom-select"}
                                                                        value={
                                                                            props.order.calculate_types.filter(
                                                                                (t) => Number(t.id) === Number(e.goods)
                                                                            )[0]?.calc_type
                                                                        }
                                                                        defaultValue={
                                                                            props.order.calculate_types.filter(
                                                                                (t) => Number(t.id) === Number(e.goods)
                                                                            )[0]?.calc_type
                                                                        }
                                                                        onChange={(event) =>
                                                                            props.changeCalcType(
                                                                                event.target.value,
                                                                                e.goods
                                                                            )
                                                                        }
                                                                    >
                                                                        {props.order.project_data.calculate?.material
                                                                            ?.filter(
                                                                                (cls) =>
                                                                                    Number(cls.id) === Number(e.goods)
                                                                            )?.[0]
                                                                            ?.calc_types?.map((s) => (
                                                                                <option key={uuid()} value={s.type}>
                                                                                    {s.name}
                                                                                </option>
                                                                            ))}
                                                                    </select>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </td>
                                                        <td>
                                                            {Number(e.price).toFixed(2)}{" "}
                                                            {props.order.currency.name}
                                                        </td>
                                                        <td>
                                                            <DebounceInput
                                                                debounceTimeout={2000}
                                                                type="number"
                                                                value={getDiscount(e.goods, "material")}
                                                                onChange={(event) => {
                                                                    gaEvents.customEvent('klik_form', {
                                                                        input_set_discount: 'discount material',
                                                                    })
                                                                    props.setDiscount(
                                                                        e.goods,
                                                                        "material",
                                                                        event.target.value
                                                                    )
                                                                }
                                                                }
                                                            />
                                                        </td>
                                                        <td>
                                                            {getDiscount(e.goods, "material")
                                                                ? (
                                                                    getDiscount(e.goods, "material") *
                                                                    Number(e.count)
                                                                ).toFixed(2)
                                                                : (Number(e.price) * Number(e.count)).toFixed(
                                                                    2
                                                                )}{" "}
                                                            {props.order.currency.name}
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr className={"bg-lightgray"}>
                                                    <td colSpan="7" className={"text-center"}>
                                                        <b>{props.getT("Услуги")}</b>
                                                    </td>
                                                </tr>
                                                {props.order.calculate.services.map((e) => (
                                                    <tr key={uuid()}>
                                                        {/* <td>{e.services}</td> */}
                                                        <td>[{e.services}] {e.name}</td>
                                                        <td></td>
                                                        <td>{Number(e.count).toFixed(3)}</td>
                                                        <td></td>
                                                        <td>
                                                            {Number(e.price).toFixed(2)}{" "}
                                                            {props.order.currency.name}
                                                        </td>
                                                        <td>
                                                            <DebounceInput
                                                                type="number"
                                                                debounceTimeout={2000}
                                                                value={getDiscount(e.services, "service")}
                                                                onChange={(event) => {
                                                                    gaEvents.customEvent('klik_form', {
                                                                        input_set_discount: 'discount service',
                                                                    })
                                                                    props.setDiscount(
                                                                        e.services,
                                                                        "service",
                                                                        event.target.value
                                                                    )
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            {getDiscount(e.services, "service")
                                                                ? (
                                                                    getDiscount(e.services, "service") *
                                                                    Number(e.count)
                                                                ).toFixed(2)
                                                                : (Number(e.price) * Number(e.count)).toFixed(
                                                                    2
                                                                )}{" "}
                                                            {props.order.currency.name}
                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr className={"bg-lightgray"}>
                                                    <td colSpan="7" className={"text-center"}>
                                                        <b>{props.getT("Дополнительные услуги")}</b>
                                                    </td>
                                                </tr>
                                                {props.order.calculate.extra.map((e) => {

                                                    return (
                                                        <tr key={uuid()}>
                                                            <td>{e.orders_goods_id}</td>
                                                            <td>{e.extra}</td>
                                                            <td>{Number(e.count).toFixed(2)}</td>
                                                            <td></td>
                                                            <td>
                                                                {Number(e.price).toFixed(2)}{" "}
                                                                {props.order.currency.name}
                                                            </td>
                                                            <td>
                                                                {(Number(e.price) * Number(e.count)).toFixed(2)}{" "}
                                                                {props.order.currency.name}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr>
                                                    <td colSpan={4}></td>
                                                    <td>
                                                        <b>{props.getT("Итого")}:</b>
                                                    </td>
                                                    <td>
                                                        <h4 className={"text-primary"}>
                                                            {getTotal(props.order.calculate)}{" "}
                                                            {props.order.currency.name}
                                                        </h4>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{props.getT("Содержимое заказа")} </h3>
                                <div className="card-tools">
                                    <button
                                        type="button"
                                        className="btn btn-tool"
                                        data-card-widget="collapse"
                                        title="Collapse"
                                        onClick={() =>
                                            props.discountEdit(
                                                !props.editDiscount,
                                                props.order.project_data.calculate,
                                                props.order.currency,
                                                props.order.project_data,
                                                props.discounts,
                                                props.order.last_project,
                                                getTotal(props.order.calculate),
                                                props.orderID
                                            )
                                        }
                                    >
                                        <i className="fas fa-edit"> </i>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-tool"
                                        data-card-widget="collapse"
                                        title="Collapse"
                                    >
                                        <i className="fas fa-minus"> </i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                                        <div className="table-responsive">
                                            <table className="table m-0 table-hover">
                                                <thead>
                                                <tr>
                                                    {/* <th scope="col">Код</th> */}
                                                    <th scope="col">{props.getT("Название")}</th>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                    <th scope="col">{props.getT("Количество")}</th>
                                                    <th scope="col">{props.getT("Цена")}</th>
                                                    <th scope="col">{props.getT("Сумма")}</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr className={"bg-lightgray"}>
                                                    <td colSpan="7" className={"text-center"}>
                                                        <b>{props.getT("Материалы и товары")}</b>
                                                    </td>

                                                </tr>
                                                {/*<ContainerMaterials order={props.order}/>*/}
                                                {props.order.calculate.materials.map((e) => {
                                                    let existGoods = wsOrderGoods?.exist?.find(item => Number(item?.goods?.goods_id) === Number(e?.goods)) ?? null;
                                                    let informationGoods = wsGoodsInformation?.find(item => Number(item?.id) === Number(e?.goods)) ?? null;


                                                    return (
                                                        <tr key={uuid()}>
                                                            {/* <td data-label="КОД">{e.goods}</td> */}
                                                            <td data-label={props.getT("Название")}>
                                                                [{e.goods}] {e.name}
                                                                <p style={{color: "red"}}>{e.name_info_bad}</p>
                                                                <p style={{color: "green"}}>
                                                                    {e.name_info_good}
                                                                </p>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td data-label={props.getT("Количество")}>
                                                                {Number(e.count).toFixed(3)}
                                                            </td>
                                                            <td data-label={props.getT("Цена")}>
                                                                {Number(e.price).toFixed(2)}{" "}
                                                                {props.order.currency.name}
                                                            </td>
                                                            <td data-label={props.getT("Сумма")}>
                                                                {(Number(e.price) * Number(e.count)).toFixed(2)}{" "}
                                                                {props.order.currency.name}
                                                            </td>
                                                            <td>
                                                                {(informationGoods && (!!(Number(e?.goods) && Number(e?.orders_goods_id)))) &&
                                                                    <i className={"fa fa-asterisk cursor-pointer " + (existGoods ? " text-success " : " text-warning ")}
                                                                       aria-hidden="true"
                                                                       onClick={() => onOpenGoodsInfoModal(e, existGoods, informationGoods)}
                                                                       title={props.getT("Информация о товаре и доступность у поставщиков")}></i>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr className={"bg-lightgray"}>
                                                    <td colSpan="7" className={"text-center"}>
                                                        <b>{props.getT("Услуги")}</b>
                                                    </td>
                                                </tr>
                                                {props.order.calculate.services.map((e) => (
                                                    <tr key={uuid()}>
                                                        <td colSpan={checkServiceReady(e.services) ? 2 : 3}
                                                            data-label={props.getT("Название")}>[{e.services}] {e.name}</td>
                                                        {checkServiceReady(e.services) && (
                                                            <td className={"text-center"}>
                                                                <i className="fas fa-check text-success"></i>{" "}
                                                                <small>{checkServiceReady(e.services)}</small>
                                                            </td>
                                                        )}
                                                        <td data-label={props.getT("Количество")}>
                                                            {Number(e.count).toFixed(3)}
                                                        </td>
                                                        <td data-label={props.getT("Цена")}>
                                                            {Number(e.price).toFixed(2)}{" "}
                                                            {props.order.currency.name}
                                                        </td>
                                                        <td data-label={props.getT("Сумма")}>
                                                            {(Number(e.price) * Number(e.count)).toFixed(2)}{" "}
                                                            {props.order.currency.name}
                                                        </td>
                                                        <td>

                                                        </td>
                                                    </tr>
                                                ))}
                                                <tr className={"bg-lightgray"}>
                                                    <td colSpan="7" className={"text-center"}>
                                                        <b>{props.getT("Дополнительные услуги")}</b>
                                                    </td>
                                                </tr>
                                                {props.order.calculate.extra.map((e) => (
                                                    <tr key={uuid()} key={uuid()}>
                                                        <td colSpan={3}
                                                            data-label={props.getT("Название")}>[{e.orders_goods_id}] {e.extra}
                                                        </td>
                                                        <td data-label={props.getT("Количество")}>
                                                            {Number(e.count).toFixed(2)}
                                                        </td>
                                                        <td data-label={props.getT("Цена")}>
                                                            {Number(e.price).toFixed(2)}{" "}
                                                            {props.order.currency.name}
                                                        </td>
                                                        <td data-label={props.getT("Сумма")}>
                                                            {(Number(e.price) * Number(e.count)).toFixed(2)}{" "}
                                                            {props.order.currency.name}
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td colSpan={5}></td>
                                                    <td>
                                                        <b>{props.getT("Итого")}:</b>
                                                    </td>
                                                    <td>
                                                        <h4 className={"text-primary"}>
                                                            {getTotal(props.order.calculate)}{" "}
                                                            {props.order.currency.name}
                                                        </h4>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {props.order.messages && props.order.messages.length > 0 ? (
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">{props.getT("История сообщений по заказу")}</h3>
                                <div className="card-tools">
                                    <button
                                        type="button"
                                        className="btn btn-tool"
                                        data-card-widget="collapse"
                                        title="Collapse"
                                        onClick={() =>
                                            props.discountEdit(
                                                !props.editDiscount,
                                                props.order.project_data.calculate,
                                                props.order.currency,
                                                props.order.project_data,
                                                props.discounts,
                                                props.order.last_project,
                                                getTotal(props.order.calculate),
                                                props.orderID
                                            )
                                        }
                                    >
                                        <i className="fas fa-edit"> </i>
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-tool"
                                        data-card-widget="collapse"
                                        title="Collapse"
                                    >
                                        <i className="fas fa-minus"> </i>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 col-md-12 col-lg-12 order-1 order-md-2">
                                        <div className="table-responsive">
                                            <table className="table m-0 table-hover message_pop_up">
                                                <thead>
                                                <tr>
                                                    <th scope="col">{props.getT("ID")}</th>
                                                    <th scope="col">{props.getT("Дата")}</th>
                                                    <th scope="col">{props.getT("Сообщение")}</th>
                                                    <th scope="col">{props.getT("Таймлайн")}</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {props.order.messages.map((e) => {
                                                    return e.message ? (
                                                        <tr key={uuid()}>
                                                            <td>{e.id}</td>
                                                            <td>{e.date}</td>
                                                            <td>{e.message}</td>
                                                            <td>
                                                                <OrderTimeline timeline={e.timeline}/>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        ""
                                                    );
                                                })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </section>
            </div>
            {isModalCuttingCard ?
                <CuttingCard getT={props.getT} order={props.order} isOpen={isModalCuttingCard} onClose={() => {
                    modalCuttingCard(false)
                }}/> : ''}
            <LabelsPrintCount onChange={onChangeValueModal} getT={props.getT} isOpen={modal.isOpen}
                              key={'gird-before-print'} onClose={() => onChangeValueModal({isOpen: false})}
                              grid={modal.grid} onHandlerSubmit={onSubmitLabelsPrint}/>
            {isModalPackagingPrinting ? <PackagingPrintingModal getT={props.getT} order={props.order}
                                                                onClose={() => modalPackagingPrinting(false)}
                                                                isOpen={isModalPackagingPrinting}/> : ''}
            <ShortFormOrderConfirmModal getT={props.getT}/>
            <WsGoodsInformationModal/>
        </>

    );
};

export default withLang(OrderDetail);

class OrderTimeline extends React.Component {
    constructor(props) {
        //rst.ua/oldcars/mercedes/e-class/mercedes_e-class_12468267.html
        https: super(props);
        this.state = {
            isOpen: false
        };
    }

    render() {
        if (this.state.isOpen) {
            return (
                <div className={"order_detail_timeline_container"}>
                    <div className={"text-right"}>
            <span onClick={() => this.setState({isOpen: false})}>
              <i className="fas fa-times"> </i>
            </span>
                    </div>
                    <table className="table">
                        <tbody>
                        {this.props.timeline.map((t) => {
                            return (
                                <tr key={uuid()}>
                                    <td>{t.status}</td>
                                    <td> |</td>
                                    <td>{t.date}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return (
                <span onClick={() => this.setState({isOpen: true})}>
          <i className="far fa-eye"> </i>
        </span>
            );
        }
    }
}

//TODO:: create container component for materials and dynamic info 1c
const ContainerMaterials = ({order}) => {
    const [dataSystem, setDataSystem] = useState(false);
    const dispatch = useDispatch();
    const {id, code, code1c} = order,
        dataClient = {id, code, code1c};
    const materials = order.calculate.materials || [];

    useEffect(() => {
        if (!code || !code1c) {
            return false;
        }

        dispatch(setMaterialGoods1CSystem({}, (data) => {
            setDataSystem(data.data);
        }));
    }, []);
    return (


        <> {materials.map((e, index) => (
            <tr key={uuid()}>
                {/* <td data-label="КОД">{e.goods}</td> */}
                <td data-label="Название">
                    {e.name}
                    {dataSystem && <InfoMaterial1CSystem e={e} idGoods={e.goods} dataSystem={dataSystem}/>}
                </td>
                <td></td>
                <td></td>
                <td data-label="Количество">
                    {Number(e.count).toFixed(3)}
                </td>
                <td data-label="Цена">
                    {Number(e.price).toFixed(2)}{" "}
                    {order.currency.name}
                </td>
                <td data-label="СУММА">
                    {(Number(e.price) * Number(e.count)).toFixed(2)}{" "}
                    {order.currency.name}
                </td>
            </tr>
        ))}

        </>
    )
}

const InfoMaterial1CSystem = ({e, idGoods, dataSystem, summ = 0}) => {
    let info = {
        bad: null,
        info: null
    }
    let data = false;
    data = dataSystem.filter(item => {
        if (Number(item["id_goods"]) == Number(idGoods)) return item
    });
    if (data.length > 0) {
        let {count, price, name} = e;
        let text = `${name} нужно ${count},
      резерв, ${data[0].Reserve},
       заказано у поставщика ${data[0].Ordered}, 
       свободный остаток ${data[0].Free_Balance},
      зарезервировано на производстве  ${summ},
      недостаточно ${count - data[0].Reserve + data[0].Shipped - summ}
       `;

        if (data["Remaining_to_ship"] == 0) {
            info.good = 'товар отгружен';
        }
        let rounds = Math.round(data[0].Reserve + data[0].Shipped + summ);

        if (rounds < Math.round(count) && rounds >= 0) {
            info.bad = text;
        } else {
            info.good = text;
        }
    }
    return (<>
        {data.length > 0 && <>
            <p style={{color: "red"}}>{info.bad}</p>
            <p style={{color: "green"}}>
                {info.good}
            </p>
        </>}
    </>)
}

