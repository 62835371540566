import React from 'react';
import OrderGoods from "./OrderGoods";
import OrderService from "./OrderService";
import {groupedOrderServiceReadMark} from "../../../utils/helpers";

/**
 * Карточка заказ.
 *
 * @param {object} order  -заказ
 * @param {function} getT - перевод
 * @param {object} shift_form_values - смена и id бригады
 * @param {object} shift_form_values.shift - смена
 * @param {object} shift_form_values.worker_group -id бригады
 * @param {object} filter - активный фильтр
 * @param {function} handlerGetPartOfOrderProductionMark - получить данные по детали и открыть модальное окно
 * @returns  {jsx}  -
 */
const OrderItem = ({order, getT}) => {

    return (
        <div key={`order_item_${order?.id}`}>
            {!!(order?.goods?.length) && <OrderGoods getT={getT} order_goods={order?.goods}/>}
            {!!(order?.service?.length) && <OrderService getT={getT}
                                                         order_service={groupedOrderServiceReadMark(order?.service)}

                                                         order_id={order?.id}/>}
        </div>
    );
};

export default OrderItem;