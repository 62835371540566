import React from 'react';

const THeader = ({getT, children=null}) => {
    return (
        <thead>
        <tr>
            <th scope="col">{getT("Тех. ID")}</th>
            <th scope="col">{getT("Название")}</th>
            <th scope="col">{getT("Точка производства")}</th>
            <th scope="col">{getT("Подтверждения")}</th>
            <th scope="col">{getT("Производство")}</th>
            {children}
            <th scope="col">{getT("Клиент")}</th>
            <th scope="col">{getT('Действия')}</th>
        </tr>
        </thead>
    );
};

export default THeader;