import {
    INITIAL_DEFAULT_PLANE_MODAL_CREATE_FORM,
    INITIAL_RATES_MODAL_CREATE_FORM,
    INITIAL_SHIFT_MODAL_UPDATE_FORM,
    INITIAL_SHIFT_PLANE,
    INITIAL_SHIFT_WORKER_GROUP_FORM
} from "../reducers/SalaryProjectReducer";

/** DEFAULT PLANE*/
export const SALARY_PROJECT_SET_DEFAULT_PLANE = "SALARY_PROJECT/SET_DEFAULT_PLANE"
export const SALARY_PROJECT_SET_DEFAULT_PLANE_CREATE_MODAL = "SALARY_PROJECT/SET_DEFAULT_PLANE_CREATE_MODAL"
/** RATES*/
export const SALARY_PROJECT_SET_RATES = "SALARY_PROJECT/SET_RATES"
export const SALARY_PROJECT_SET_RATES_CREATE_MODAL = "SALARY_PROJECT/SET_RATES_CREATE_MODAL"
/** GENERAL*/
export const SALARY_PROJECT_SET_GENERAL_CURRENCY = "SALARY_PROJECT/SET_GENERAL_CURRENCY";
export const SALARY_PROJECT_SET_GENERAL_GOODS_SERVICE = "SALARY_PROJECT/SET_GOODS_SERVICE";
export const SALARY_PROJECT_SET_GENERAL_ORDERS_STATUSES = "SALARY_PROJECT/SET_GENERAL_ORDERS_STATUSES";
export const SALARY_PROJECT_SET_GENERAL_GOODS_SERVICE_TYPE = "SALARY_PROJECT/SET_GOODS_SERVICE_TYPE";
/** SHIFT*/
export const SALARY_PROJECT_SET_SHIFT = "SALARY_PROJECT/SET_SHIFT"
export const SALARY_PROJECT_CREATE_SHIFT_MODAL = "SALARY_PROJECT/CREATE_SHIFT_MODAL"
export const SALARY_PROJECT_UPDATE_SHIFT_MODAL = "SALARY_PROJECT/UPDATE_SHIFT_MODAL"
export const SALARY_PROJECT_FILTER_SHIFT = "SALARY_PROJECT/FILTER_SHIFT"
/** SHIFT Open*/
export const SALARY_PROJECT_SET_SHIFT_OPEN = "SALARY_PROJECT/SET_SHIFT_OPEN";

/** SHIFT PLANE */
export const SALARY_PROJECT_SET_SHIFT_PLANE = "SALARY_PROJECT/SET_SHIFT_PLANE";
export const SALARY_PROJECT_SHIFT_PLANE_CREATE_MODAL = "SALARY_PROJECT/SHIFT_PLANE_CREATE_MODAL";
export const SALARY_PROJECT_SHIFT_PLANE_UPDATE_MODAL = "SALARY_PROJECT/SHIFT_PLANE_UPDATE_MODAL";

/** WORKER GROUP */
export const SALARY_PROJECT_SET_EQUIPMENT_WORKER_GROUP = "SALARY_PROJECT/SET_EQUIPMENT_WORKER_GROUP";

/** SHIFT WORKER GROUP */
export const SALARY_PROJECT_SHIFT_WORKER_GROUP_MODAL = "SALARY_PROJECT/SHIFT_WORKER_GROUP_MODAL";
//READY MARK
export const SALARY_PROJECT_READY_MARK_TOGGLE_ALL = "SALARY_PROJECT/READY_MARK_TOGGLE_ALL";
export const SALARY_PROJECT_READY_MARK_GOODS_SERVICE_MODAL = "SALARY_PROJECT/READY_MARK_GOODS_SERVICE_MODAL";
export const SALARY_PROJECT_READY_MARK_PART_FOR_ORDER_MODAL = "SALARY_PROJECT/READY_MARK_PART_FOR_ORDER_MODAL";
export const SALARY_PROJECT_READY_MARK_PART_FOR_ORDER_CHOICE_SERVICE_MODAL = "SALARY_PROJECT/READY_MARK_PART_FOR_ORDER_CHOICE_SERVICE_MODAL";
export const SALARY_PROJECT_READY_MARK_PACKING_PART_FOR_ORDER_MODAL = "SALARY_PROJECT/READY_MARK_PACKING_PART_FOR_ORDER_MODAL";
//** Users*/
export const SALARY_PROJECT_SET_USERS = "SALARY_PROJECT/SET_USER";
//READINESS REPORT
export const SALARY_PROJECT_READINESS_REPORT = "SALARY_PROJECT/READINESS_REPORT";
export const SALARY_PROJECT_READINESS_REPORT_GET_ORDER_DETAILING = "SALARY_PROJECT/READINESS_REPORT_GET_ORDER_DETAILING";
//ADDITIONAL DOCUMENT AND READY MARK  BY SHIFT OPEN
export const SALARY_PROJECT_ADDITIONAL_DOCUMENT_READY_MARK_BY_SHIFT_OPEN = "SALARY_PROJECT/ADDITIONAL_DOCUMENT_READY_MARK_BY_SHIFT_OPEN"
export const SALARY_PROJECT_ADDITIONAL_DOCUMENT_BONUSES_FINES_CREATE_MODAL = "SALARY_PROJECT/_ADDITIONAL_DOCUMENT_BONUSES_FINES_CREATE_MODAL"
export const SALARY_PROJECT_ADDITIONAL_DOCUMENT_BONUS_FINE_UPDATE_MODAL = "SALARY_PROJECT/ADDITIONAL_DOCUMENT_BONUS_FINE_UPDATE_MODAL"
//SCANNER READ MARK
export const SALARY_PROJECT_SCANNER_READ_MARK_TOGGLE_ALL = "SALARY_PROJECT/SCANNER_READ_MARK_TOGGLE_ALL"
//SALARY REPORT
export const SALARY_PROJECT_REPORT_PAGE = "SALARY_PROJECT/REPORT_PAGE"
//SEARCH ORDERS PAGE
export const SALARY_PROJECT_SEARCH_ORDERS_TOGGLE_ALL = "SALARY_PROJECT/SEARCH_ORDERS_TOGGLE_ALL";
export const setDefaultPlaneAC = (payload) => {
    return {
        type: SALARY_PROJECT_SET_DEFAULT_PLANE,
        payload: payload
    }
}
export const toggleDefaultPlaneCreateModalAC = ({
                                                    isOpen = false,
                                                    form_values = INITIAL_DEFAULT_PLANE_MODAL_CREATE_FORM
                                                }) => {
    return {
        type: SALARY_PROJECT_SET_DEFAULT_PLANE_CREATE_MODAL,
        payload: {isOpen: isOpen, form_values: form_values}
    }
}

export const setRatesAC = (payload) => {
    return {
        type: SALARY_PROJECT_SET_RATES,
        payload: payload
    }
}
export const toggleRatesCreateModalAC = ({isOpen = false, form_values = INITIAL_RATES_MODAL_CREATE_FORM}) => {
    return {
        type: SALARY_PROJECT_SET_RATES_CREATE_MODAL,
        payload: {isOpen: isOpen, form_values: form_values}
    }
}

export const setGeneralCurrenciesAC = (payload) => {
    return {
        type: SALARY_PROJECT_SET_GENERAL_CURRENCY,
        payload: payload
    }
}
export const setGeneralGoodsServiceAC = (payload) => {
    return {
        type: SALARY_PROJECT_SET_GENERAL_GOODS_SERVICE,
        payload: payload
    }
}
export const setGeneralOrdersStatusesAC = (payload) => {
    return {
        type: SALARY_PROJECT_SET_GENERAL_ORDERS_STATUSES,
        payload: payload
    }
}
export const setGeneralGoodsServiceTypeAC = (payload) => {
    return {
        type: SALARY_PROJECT_SET_GENERAL_GOODS_SERVICE_TYPE,
        payload: payload
    }
}
export const setShiftsAC = (payload) => {
    return {
        type: SALARY_PROJECT_SET_SHIFT,
        payload: payload
    }
}
export const setShiftCreateModalAC = ({isOpen = false}) => {

    return {
        type: SALARY_PROJECT_CREATE_SHIFT_MODAL,
        payload: {isOpen: isOpen}
    }
}
export const setShiftUpdateModalAC = ({isOpen = false, form_values = INITIAL_SHIFT_MODAL_UPDATE_FORM}) => {

    return {
        type: SALARY_PROJECT_UPDATE_SHIFT_MODAL,
        payload: {isOpen: isOpen, form_values: form_values}
    }
}
/**
 * setShiftFilterAC фильтр и пагинация.
 *
 * @param {object}  payload- payload for filter
 * @param {number}  payload.offset - number page start 0
 * @param {number} payload.open_for_edit - open/close shift 0/1
 */
export const setShiftFilterAC = (payload) => {
    return {
        type: SALARY_PROJECT_FILTER_SHIFT,
        payload: payload
    }
}

export const setShiftPlaneAC = (payload) => {
    return {
        type: SALARY_PROJECT_SET_SHIFT_PLANE,
        payload: payload
    }
}
export const setShiftOpenAC = (payload) => {
    return {
        type: SALARY_PROJECT_SET_SHIFT_OPEN,
        payload: payload
    }
}

export const setShiftPlaneCreateModalAC = ({isOpen = false, form_values = INITIAL_SHIFT_PLANE}) => {
    return {
        type: SALARY_PROJECT_SHIFT_PLANE_CREATE_MODAL,
        payload: {isOpen: isOpen, form_values: form_values}
    }
}
export const setShiftPlaneUpdateModalAC = ({isOpen = false, form_values = INITIAL_SHIFT_PLANE}) => {
    return {
        type: SALARY_PROJECT_SHIFT_PLANE_UPDATE_MODAL,
        payload: {isOpen: isOpen, form_values: form_values}
    }
}
export const setEquipmentWorkerGroupAC = (payload) => {
    return {
        type: SALARY_PROJECT_SET_EQUIPMENT_WORKER_GROUP,
        payload: payload
    }
}

export const toggleShiftWorkerGroupModalAC = ({isOpen = false, form_values = INITIAL_SHIFT_WORKER_GROUP_FORM}) => {
    return {
        type: SALARY_PROJECT_SHIFT_WORKER_GROUP_MODAL,
        payload: {isOpen: isOpen, form_values: form_values}
    }
}
/**
 * readyMarToggleAllAC. отметка готовности
 *
 * @param {object}  payload-
 * @param {object}  payload.orders- список заказов
 *
 * @param {object} payload.filter - Фильтр для заказов
 * @param {Data} payload.filter.data_from -
 * @param {Data} payload.filter.date_to -
 * @param {number} payload.filter.status - 3,4,5
 * @param {number} payload.filter.position -
 * @param {number} payload.filter.d_update -
 * @param {number | null} payload.filter.client -
 * @param {number} payload.filter.production_task -
 *
 *
 * @param  {object} payload.shift_form_values - Выбор смены, бригады
 * @param  {object} payload.shift -  id смены
 * @param  {number} payload.worker_group - id бригады
 // * @param  {object|null} payload.choice_shift - смена
 *
 * @param  {array} payload.selected_orders - массив id заказов
 */
export const readyMarToggleAllAC = (payload) => {
    return {
        type: SALARY_PROJECT_READY_MARK_TOGGLE_ALL,
        payload: payload
    }
}
export const readyMarGoodsServiceModalAC = ({isOpen = false}) => {
    return {
        type: SALARY_PROJECT_READY_MARK_GOODS_SERVICE_MODAL,
        payload: {isOpen: isOpen}
    }
}
export const readyMarkPartForOrderModalModalAC = ({isOpen = false, data = null, ...reset}) => {
    return {
        type: SALARY_PROJECT_READY_MARK_PART_FOR_ORDER_MODAL,
        payload: {isOpen: isOpen, data: data, ...reset}
    }
}
export const readyMarkPartForOrderChoiceServiceModalAC = ({service_type_add = null, service_id = null}) => {
    return {
        type: SALARY_PROJECT_READY_MARK_PART_FOR_ORDER_CHOICE_SERVICE_MODAL,
        payload: {choice_service: {service_type_add: service_type_add, service_id: service_id}}
    }
}
export const readyMarkPackingPartForOrderModalAC = ({isOpen = false, data = null}) => {
    return {
        type: SALARY_PROJECT_READY_MARK_PACKING_PART_FOR_ORDER_MODAL,
        payload: {isOpen: isOpen, data: data}
    }
}

export const setUsersAC = (payload) => {
    return {
        type: SALARY_PROJECT_SET_USERS,
        payload: payload
    }
}

/**
 * setReadinessReportAC. Отчёт готовности
 * @param {object} payload -
 * @param {array|null} payload.orders -
 * @param {object} payload.filter -
 * @param {number} payload.production_task -
 * @param {number} payload.order_id -
 * @param {array} payload.service_type -
 * @param {array} payload.service -
 * @returns  {object}  -
 */
export const setReadinessReportAC = (payload) => {
    return {
        type: SALARY_PROJECT_READINESS_REPORT,
        payload: payload
    }
}
export const setReadinessReportOrderDetailingAC = (payload) => {
    return {
        type: SALARY_PROJECT_READINESS_REPORT_GET_ORDER_DETAILING,
        payload: payload
    }
}
export const setAdditionDocumentReadyMarkByShiftOpenAC = (payload) => {
    return {
        type: SALARY_PROJECT_ADDITIONAL_DOCUMENT_READY_MARK_BY_SHIFT_OPEN,
        payload: payload
    }
}
export const setAdditionDocumentAdditionalDocumentBonusesFinesCreateModalAC = ({
                                                                                   isOpen = false,
                                                                                   type_action = null,
                                                                                   additional_document = null,
                                                                                   shift_id = null
                                                                               }) => {
    return {
        type: SALARY_PROJECT_ADDITIONAL_DOCUMENT_BONUSES_FINES_CREATE_MODAL,
        payload: {
            isOpen: isOpen,
            type_action: type_action,
            additional_document: additional_document,
            shift_id: shift_id
        }
    }
}
export const setAdditionDocumentAdditionalDocumentBonusFineUpdateModalAC = ({
                                                                                isOpen = false,
                                                                                type_action = null,
                                                                                data = null,
                                                                                shift_id = null
                                                                            }) => {
    return {
        type: SALARY_PROJECT_ADDITIONAL_DOCUMENT_BONUS_FINE_UPDATE_MODAL,
        payload: {isOpen: isOpen, type_action: type_action, data: data, shift_id: shift_id}
    }
}
/**
 * scannerReadMarkToggleAllAC.
 *
 * @param {object}  payload -
 *
 * @param {object?}   payload.shift_form_values -
 * @param {object}   payload.shift_form_values.shift -
 * @param {number}   payload.shift_form_values.worker_group-
 *
 * @param {object?}   payload.filter -
 * @param {number}   payload.filter.type_order_or_part -
 * @param {number}   payload.filter.type_add_or_delete_mark -
 * @param {number}   payload.filter.type_package_or_service -
 * @param {array}   payload.filter.service_type -
 * @param {number}   payload.filter.type_need_confirm -
 *
 * @param {object?}   payload.confirm_modal -
 * @param {boolean}   payload.confirm_modal.isOpen -
 * @param {null || object}   payload.confirm_modal.information -
 *
 * @param {null || array}   payload.equipment_worker_group_by_shift -

 *
 * @returns  {object}  -
 */

export const scannerReadMarkToggleAllAC = (payload) => {
    return {
        type: SALARY_PROJECT_SCANNER_READ_MARK_TOGGLE_ALL,
        payload: payload
    }
}
export const salaryReportAC = (payload) => {
    return {
        type: SALARY_PROJECT_REPORT_PAGE,
        payload: payload
    }
}

export const searchOrdersToggleAllAC = (payload) => {
    return {
        type: SALARY_PROJECT_SEARCH_ORDERS_TOGGLE_ALL,
        payload: payload
    }
}