import React from 'react';
import Modal from "../MyModal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getReadyMarkPackingPartForOrderModal} from "../../../store/selectors/salary_project_selector";
import {readyMarkPackingPartForOrderModalAC} from "../../../store/actions/SalaryProject";
import PackingPartForm from "./components/PackingPartForOrderProductionMark/PackingPartForm";
import {togglePackingMarkToDetailByIdOrderThunk} from "../../../store/thunks/salary_project/ready_mark_thunk";


const SalaryReadeMakPackingPartForOrderForProductionModal = ({getT, callbackAfterCloseModal = null, filter}) => {
    const dispatch = useDispatch();
    const modal_state = useSelector(getReadyMarkPackingPartForOrderModal);


    if (!modal_state.isOpen) return null;
    const handlerClose = () => {
        dispatch(readyMarkPackingPartForOrderModalAC({isOpen: false, data: null}));
        if (callbackAfterCloseModal) callbackAfterCloseModal();
    }

    const handlerSubmitForm = (data) => {
        dispatch(togglePackingMarkToDetailByIdOrderThunk({...data, filter: filter, order_id: modal_state?.data?.order}))
    }

    return (
        <Modal title={getT("Групповая отметка упаковки в заказе")} open={modal_state.isOpen} onClose={handlerClose}>
            <div className="">

                <PackingPartForm getT={getT} order={modal_state.data} handlerSubmitForm={handlerSubmitForm}/>

            </div>
        </Modal>
    );
};

export default SalaryReadeMakPackingPartForOrderForProductionModal;