import React from 'react';
import Card from "../../../components/ui/Card";
import OrderItem from "./OrderItem";
import Button from "../../../components/ui/Button";
import {LS_WORKER_ID} from "../../../utils/constants";

/**
 * Компонет отметки готовности улсг.
 * Включает отображение бриагады, фильтр, список заказов или заданый на производство
 * @param {object} ready_mark -
 * @param {function} getT -
 * @param {function} handlerSheetForm - sheet form
 * @returns  {jsx}  -
 */
const SearchOrders = ({search_orders, getT, handlerSheetForm}) => {
    const worker_in_system_id = JSON.parse(localStorage.getItem(LS_WORKER_ID));
    //** услуги которые доступны для отметки бригады
    //::TODO дописать проверку на работника для отображения только его услуг


    return (<div className={"mt-3"}>
            <div className="card p-1 p-md-2 pd-lg-3">
                <div className="row read-mark-list-orders ">
                    {search_orders?.orders?.map(order => {
                        return <div className="col-12 col-lg-6" key={`order_item_${order?.id}`}>
                            <Card title={<div className={"d-flex justify-content-between align-items-center "}>
                                    <span> {getT("Заказ")} #{order?.id}</span>
                                {!!Number(worker_in_system_id) ? <></> :
                                    <a target={'_blank'} href={`/orders/detail/${order?.id}`} className={"fs-14"}><i
                                        className={'far fa-eye'}/></a>}
                            </div>}
                                  isCollapse={true} bodyChildren={<OrderItem order={order} getT={getT}
                                                                             filter={search_orders?.filter}
                            />}
                                  footerChildren={
                                      !!order?.id &&
                                      <div className={"d-flex justify-content-end flex-wrap"} style={{gap: '10px'}}>
                                          <Button className={"btn-primary fs-14"}
                                                  onClick={handlerSheetForm(order.id)}>{getT("Спецификация")}</Button>

                                      </div>
                                  }
                            />

                        </div>
                    })}
                </div>
            </div>
        </div>
    );
};

export default SearchOrders;