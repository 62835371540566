import React, {useEffect, useMemo, useRef} from 'react';
import Modal from "../MyModal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getDefaultPlaneCreateModal} from "../../../store/selectors/salary_project_selector";
import {toggleDefaultPlaneCreateModalAC} from "../../../store/actions/SalaryProject";
import {useForm} from "react-hook-form";
import Button from "../../ui/Button";
import {withLang} from "../../../hoc/withLang";
import {LS_USER_ID} from "../../../utils/constants";
import {addDefaultPlaneThunk} from "../../../store/thunks/salary_project/default_plane_thunk";
import {INITIAL_DEFAULT_PLANE_MODAL_CREATE_FORM} from "../../../store/reducers/SalaryProjectReducer";

const DefaultPlaneModal = ({getT}) => {
    const dispatch = useDispatch();
    const default_plane_create_modal = useSelector(getDefaultPlaneCreateModal);
    const {register, handleSubmit, watch, reset, formState: {errors}} = useForm();
    const handlerClose = () => {
        dispatch(toggleDefaultPlaneCreateModalAC({
            isOpen: false,
            form_values: INITIAL_DEFAULT_PLANE_MODAL_CREATE_FORM
        }));
        reset({
            ...INITIAL_DEFAULT_PLANE_MODAL_CREATE_FORM
        });
    }
    const handlerCreate = (data) => {
        dispatch(addDefaultPlaneThunk(data, reset))
    }

    useEffect(() => {
        reset({...default_plane_create_modal?.form_values})
    }, [default_plane_create_modal?.form_values])

    const isHasActualPlane = useMemo(() => {
        return default_plane_create_modal?.form_values?.count_bad && default_plane_create_modal?.form_values?.count_normal && default_plane_create_modal?.form_values?.count_super;

    }, [default_plane_create_modal]);

    if (!default_plane_create_modal.isOpen) {
        return null;
    }
    return (
        <Modal open={default_plane_create_modal.isOpen} onClose={handlerClose} size_modal_class={'modal-md'}
               key={`default_plane_modal_${default_plane_create_modal?.form_values?.service_type}`}
               title={isHasActualPlane ? getT("Обновить") : getT("Создать")}>
            <form onSubmit={handleSubmit((data) => {
                handlerCreate(data)
            })}>
                <input {...register("service_type", {
                    value: default_plane_create_modal?.form_values?.service_type
                })} type={"hidden"} key={`service_type_${default_plane_create_modal?.form_values?.service_type}`}/>
                <input {...register("user")} type={"hidden"} value={localStorage.getItem(LS_USER_ID)}/>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{getT("Показатель не выполненного плана")}</label>
                    <input {...register("count_bad", {
                        min: 1,
                        value: default_plane_create_modal?.form_values?.count_bad
                    })} className="form-control" type={"number"} required={true}/>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{getT("Показатель нормального плана")}</label>
                    <input {...register("count_normal", {
                        value: default_plane_create_modal?.form_values?.count_normal
                    })} className="form-control" type={"number"}
                           min={Number(watch("count_bad")) + 1} required={true}/>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{getT("Показатель перевыполненного плана")}</label>
                    <input {...register("count_super", {
                        required: 'Empty',
                        value: default_plane_create_modal?.form_values?.count_super
                    })} className="form-control" type={"number"} min={Number(watch("count_normal")) + 1}
                           required={true}/>
                </div>
                <div className="text-center">
                    <Button type={"submit"}>
                        {getT("Отправить")}
                    </Button>
                </div>
            </form>
        </Modal>
    );
};

export default withLang(DefaultPlaneModal)