import React from 'react';
import Modal from "../MyModal/Modal";
import BonusesFinesCreateForm from "./components/BonusesFines/BonusesFinesCreateForm";
import {useDispatch, useSelector} from "react-redux";
import {
    getAdditionalDocumentBonusesFinesCreateModal,
    getGeneralCurrencies
} from "../../../store/selectors/salary_project_selector";
import {SALARY_PROJECT_BONUSES_FINES_ACTION} from "../../../utils/constants";
import {setAdditionDocumentAdditionalDocumentBonusesFinesCreateModalAC} from "../../../store/actions/SalaryProject";
import {putAdditionalDocumentBonusesFinesThunk} from "../../../store/thunks/salary_project/addition_document_thunk";


const SalaryCreateBonusesFinesToWorkerByDocumentModal = ({getT, read_mark}) => {
    const dispatch = useDispatch();
    const currencies = useSelector(getGeneralCurrencies)
    const bonuses_fines_create = useSelector(getAdditionalDocumentBonusesFinesCreateModal);
    if (!bonuses_fines_create?.isOpen) return null;
    const handlerSubmitForm = (values) => {
        let additional_document = bonuses_fines_create?.additional_document;
        let shift_id = bonuses_fines_create?.shift_id;
        let reverse_key = bonuses_fines_create.type_action === SALARY_PROJECT_BONUSES_FINES_ACTION.bonuses ? SALARY_PROJECT_BONUSES_FINES_ACTION.fines: SALARY_PROJECT_BONUSES_FINES_ACTION.bonuses
        let api_data = {
            additional_document: additional_document,
            [bonuses_fines_create.type_action]: values,
            [reverse_key]: []

        }
        dispatch(putAdditionalDocumentBonusesFinesThunk(api_data, shift_id))


    }
    const handlerCloseModal = () => {
        dispatch(setAdditionDocumentAdditionalDocumentBonusesFinesCreateModalAC({
            isOpen: false,
            additional_document: null,
            type_action: null, shift_id: null
        }))
    }
    let title = bonuses_fines_create?.type_action === SALARY_PROJECT_BONUSES_FINES_ACTION.bonuses ? getT("Создать бонус") : getT("Создать штраф")
    return (
        <Modal open={bonuses_fines_create.isOpen} onClose={handlerCloseModal} title={title}>
            <BonusesFinesCreateForm getT={getT} currency={currencies?.data} read_mark={read_mark}
                                    handlerSubmitForm={handlerSubmitForm}/>
        </Modal>
    );
};

export default SalaryCreateBonusesFinesToWorkerByDocumentModal;