/**
 * Группировка деталей по материалу суммирование кол-во и проверка выполнена ли деталь. Для карты кроя подетально
 * @param {array}  cutting - заказ
 * @param {array}  material - заказ
 * @param {array}  order_part - заказ
 * @param {array}  part_ready_services_user_info - заказ
 * @param {object}  choice_service - выбранная услуга и тип услуги
 * @param {number | null}  choice_service.service_type_add -  тип услуги
 * @param {number | null}  choice_service.service_id -   услуга
 * @returns  {object}   object [material_${id}]: {material:object, parts: object}
 */

export const getGroupCuttingForCheckedDetailing = ({
                                                       cutting,
                                                       material,
                                                       part_ready_services_user_info,
                                                       choice_service,
                                                       order_part
                                                   }) => {
    let copy_part_ready_services_user_info_for_child =Array.isArray(part_ready_services_user_info) ?  [...part_ready_services_user_info] : []
    return cutting?.reduce((acc, cutting) => {

        let find_material = material?.find(e => {
            let goods_include_pb = Number.isFinite(Number(cutting?.goods_id))
            if (goods_include_pb) return Number(e?.id) === Number(cutting?.goods_id);
            let part_id = cutting.parts?.[0]?.part_id;
            if (!part_id) return false;
            let find_material_of_part_id = order_part?.find(item => Number(item.id) === Number(part_id))?.material;
            return Number(e.id) === Number(find_material_of_part_id)
        })
        let filtered_parts = [...cutting.parts ?? []]?.filter(item => order_part?.find(part => {
            let is_part_has_service = part.services_in_detail.find(serv => Number(serv.service_id) === Number(choice_service.service_id))
            return (Number(part.id) === Number(item?.part_id)) && is_part_has_service
        }))

        if (!find_material) return acc
        let material_key = `material_${find_material?.id}`;
        let material_parts = acc?.[material_key]?.parts;
        let parts = filtered_parts?.reduce((acc_part, part) => {
            let find_part_ready = part_ready_services_user_info
                ?.filter(item => {
                    return Number(item.service_id) === Number(choice_service.service_id) && Number(part?.part_id) === Number(item?.part_id)
                }) || null;


            let key_part = `part_${part.part_id}`;
            let find_material_part_by_key = material_parts?.[key_part];
            let find_index = copy_part_ready_services_user_info_for_child
                ?.findIndex(item => {
                    return (Number(item.service_id) === Number(choice_service.service_id)) &&
                        (Number(part?.part_id) === Number(item?.part_id))
                        && (Number(item?.count) === Number(part?.count))
                }) ;
            let find_part_ready_by_serv_count = find_index >= 0 ? copy_part_ready_services_user_info_for_child.splice(find_index, 1)?.[0] : null;
            let part_dt_child = {
                part_uuid: part?.part_uuid,
                length: part?.length,
                width: part?.width,
                part_id: part.part_id,
                count: Number(part?.count),
                is_initial_checked: !!find_part_ready_by_serv_count,
                is_checked: !!find_part_ready_by_serv_count,
                record_id: find_part_ready_by_serv_count,
                ready_services: find_part_ready_by_serv_count,
            }

            if (find_material_part_by_key) {
                acc_part[key_part] = {
                    ...find_material_part_by_key,
                    count: Number(find_material_part_by_key?.count) + Number(part?.count),
                }
            } else {
                acc_part[key_part] = {
                    ...part,
                    record_id: find_part_ready,
                    service_add: Number(choice_service.service_id),
                    count: Number(part?.count),
                    ready_services: find_part_ready,
                }
            }
            let child_part_prev = acc_part[key_part]?.child_part ? acc_part[key_part]?.child_part : []
            let child_parts = [...child_part_prev, part_dt_child]?.map((e, index)=> ({...e, index: index}));
            let is_checked = !!((Array.isArray(find_part_ready) && find_part_ready?.length) && (find_part_ready?.length === child_parts?.length));
            acc_part[key_part] = {
              ...acc_part[key_part],
                is_checked: is_checked,
                is_initial_checked: is_checked,
                child_part: child_parts
            }

            return acc_part;
        }, {});
        if (Object.keys(parts)?.length) {
            acc = {
                ...acc,
                [material_key]: {
                    material: find_material,
                    parts: parts
                }
            }
        }

        return acc

    }, {})
}


/**
 * Группировка деталей по материалу для отметки карт кроя
 * @param {array}  cutting - заказ
 * @param {array}  material - заказ
 * @param {array}  order_part - заказ
 * @param {array}  part_ready_services_user_info - заказ
 * @param {object}  choice_service - выбранная услуга и тип услуги
 * @param {number | null}  choice_service.service_type_add -  тип услуги
 * @param {number | null}  choice_service.service_id -   услуга
 * @returns  {object}  -
 */
export const getGroupCuttingOfMaterialForCard = ({
                                                     choice_service,
                                                     cutting,
                                                     material,
                                                     part_ready_services_user_info, order_part
                                                 }) => {
    return !choice_service?.service_id || !choice_service?.service_type_add ? [] : cutting?.reduce((acc, item) => {
        let find_material = material?.find(e => {
            let goods_include_pb = Number.isFinite(Number(item?.goods_id))
            if (goods_include_pb) return Number(e?.id) === Number(item?.goods_id);
            let part_id = item.parts?.[0]?.part_id;
            if (!part_id) return false;
            let find_material_of_part_id = order_part?.find(item => Number(item.id) === Number(part_id))?.material;
            return Number(e.id) === Number(find_material_of_part_id)
        });
        let cutting_key = `cutting_${item.card_id}`;
        let filtered_parts = item?.parts?.filter(item => order_part?.find(part => {
            let is_part_has_service = part.services_in_detail.find(serv => Number(serv.service_id) === Number(choice_service.service_id))
            return (Number(part.id) === Number(item?.part_id)) && is_part_has_service
        }))
        let parts = filtered_parts?.map(part => {
            let find_part_ready = part_ready_services_user_info?.find(item => Number(item.service_id) === Number(choice_service.service_id) && Number(part?.part_id) === Number(item?.part_id));
            if (find_part_ready) {
                return {
                    ...part,
                    ...find_part_ready,
                    is_checked: true,
                    record_id: find_part_ready?.MySQL_record_id,
                    service_add: Number(choice_service.service_id),
                    is_initial_checked: true

                }
            }
            return {
                ...part, is_checked: false, record_id: null, service_add: Number(choice_service.service_id),
                is_initial_checked: false

            }
        })
        if (parts?.length) {
            acc = {
                ...acc,
                [cutting_key]: {
                    material: find_material,
                    ...item,
                    parts: parts,
                    cutting_checked: parts.every(part => part?.is_checked),
                    is_initial_cutting_checked: parts.every(part => part?.is_checked)
                }
            }
        }
        return acc
    }, {});


}