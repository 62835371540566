import React from 'react';
import Select from "react-select";

const FormFilterMaterialBand = ({
                                    getT,
                                    handlerChangeSelectField,
                                    entry_select,
                                    date_field,
                                    handlerChangeDateField,
                                    material_band_field
                                }) => {
    return (
        <div className="card mt-4 px-3 py-2">
            <div className="form-row  mb-3">
                <div className="form-group col-md-6 col-lg-4 col-xl-2">
                    <label htmlFor="exampleInputEmail1">{getT("Месяц")} / {getT("Год")}</label>
                    <input type="month" id="month-year" name="month-year" value={date_field}
                           className="form-control" onChange={handlerChangeDateField}/>
                </div>
                <div className="form-group col-md-6 col-lg-4 col-xl-3">
                    <label htmlFor="exampleInputEmail1">{getT("Материал")}</label>
                    <Select
                        options={entry_select?.material?.map(e => {
                            return {value: e?.material_id, label: e?.material_name}
                        })}
                        onChange={(item) => {
                            handlerChangeSelectField('material', item)
                        }}
                        value={material_band_field?.material}
                    />
                </div>
                <div className="form-group col-md-6 col-lg-4 col-xl-3">
                    <label htmlFor="exampleInputEmail1">{getT("Кромка")}</label>
                    <Select
                        options={entry_select?.band?.map(e => {
                            return {value: e?.band_id, label: e?.band_name}
                        })}
                        onChange={(item) => {
                            handlerChangeSelectField('band', item)
                        }}
                        value={material_band_field?.band}
                    />
                </div>
            </div>
        </div>
    );
};

export default FormFilterMaterialBand;