import React, {useState} from 'react';
import {useDetectClickOutside} from "react-detect-click-outside";
/**
 * ItemWorker. Бригада
 *
 * @param {boolean} is_open_shift  - смена открыта/закрыта
 * @param {object} worker_group  -  работники смены
 * @param {function} handlerUpdateWorker  - обновить
 * @param {array} equipment_worker_group  - все работники
 * @param {array} default_plane  - план по умолчанию
 * @param {boolean} is_available_access_role  - права доступа к crud worker_group
 * @param {function}  handlerOpenReadMarkPage - открыть страницу отметки пред выбранной сменной и бригадой
 * @param {function}  getT-
 * @returns  {jsx}  -
 */
const ItemWorker = ({is_open_shift,worker_group, getT, handlerUpdateWorker, equipment_worker_group, default_plane, is_available_access_role, handlerOpenReadMarkPage, handlerOpenScannerReadMarkPage}) => {
    const [isShow, setIsShow] = useState(false);
    const ref = useDetectClickOutside({onTriggered: () => setIsShow(false)})

    return (
        <>
            <li className={`list-item ${isShow && 'border-0 '}`} ref={ref}>
                <div className={'d-flex align-items-center justify-content-between'}>
                    <span className="title"> [{worker_group?.salary_shift_worker_group_id}] {worker_group?.name}</span>
                    {is_open_shift && <span className="shift-action">
                   <button className={"btn btn-sm btn-primary py-0"} onClick={handlerOpenReadMarkPage}>
                        {getT("Отметка")}
                    </button>
                         <button className={"btn btn-sm btn-primary py-0"} onClick={handlerOpenScannerReadMarkPage}>
                        {getT("QR")}
                    </button>
                        <i className={`cursor-pointer fas fa-angle-${isShow ? 'up' : 'down'}`}
                           onClick={() => setIsShow(!isShow)}></i>
                        {is_available_access_role && <i className="fas fa-edit text-success cursor-pointer" onClick={handlerUpdateWorker}></i>}
                    {/*    <i className="fa fa-trash text-danger cursor-pointer" onClick={handlerDelete}></i>*/}
                    </span>}
                </div>

                {isShow &&
                    <ul className="list-group mt-2">
                        {worker_group?.workers?.map(item => {
                            let worker_data = equipment_worker_group?.find(worker => Number(worker.equipment_worker_id) === Number(item?.worker))
                            let service_available = default_plane
                                ?.filter(plane =>
                                    worker_data.sevice_type_availible?.find(service=> Number(plane?.goods_service_type_id) === Number(service.service_type))
                                )
                                ?.map(plane=> plane?.name)?.join(', ');
                            return (
                                <li className="list-group-item px-2 py-1">
                                    <p className={"fs-14 d-flex justify-content-between flex-row text-secondary m-0"}>
                                        <span>{worker_data?.name}:</span>
                                        <span className={''}>{item?.percentage}%</span>
                                    </p>
                                    <p className={"fs-14 d-flex justify-content-between flex-row text-secondary m-0"}>
                                        <span className="">{getT("Доступный тип услуги")}:</span>
                                    <span className={'text-right'}>{service_available}</span>
                                    </p>
                                </li>
                            )
                        })}

                    </ul>
                }
            </li>
        </>
    );
};

export default ItemWorker;