import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getProductionsStatusThunk, getProductionTasksFilteredDataThunk} from "../../../../store/reducers/MainReducer";
import {filterForm, orderStatus} from "../selectors";
import {isShallowEqual} from "../../../../utils/helpers";
import DateField from "./Filter/DateField";
import {formatDate} from "../../../Orders/OrderOutstanding";
import ClearData from "./Filter/ClearData";

const FilterTask = ({getT}) => {
    const lang = useSelector(state => state.translation.local);
    const dispatch = useDispatch();
    const form = useSelector(filterForm, (prev, next) => isShallowEqual(prev, next))
    const status = useSelector(orderStatus, (prev, next) => isShallowEqual(prev, next))
    // ------------

    const [openDate_productionPicker, setOpenDate_productionPicker] = useState(false);
    // ------------
    const fetchProductTask = (filters = false) => dispatch(getProductionsStatusThunk(filters))
    const changeFilterValue = (input, value, filters) => dispatch(getProductionTasksFilteredDataThunk(input, value, filters))


    useEffect(() => {
        fetchProductTask(false);
    }, []);


    return (
        <div className="col-12">
            <div className="row">
                <div className="col-sm-2">
                    <div className="form-group">
                        <label>{getT("ID заказа")}:</label>
                        <input
                            type="number"
                            className="form-control"
                            value={form.id}
                            placeholder=""
                            onChange={(event) =>
                                changeFilterValue("id", event.target.value, form)
                            }
                        />
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="form-group">
                        <label>{getT("Статус")}:</label>
                        <select
                            className="form-control"
                            value={form.status}
                            defaultValue={form.status}
                            onChange={(event) =>
                                changeFilterValue(
                                    "status",
                                    event.target.value,
                                    form
                                )
                            }
                        >
                            <option value="">{getT("Все")}</option>
                            {status &&
                                status.map((el) => {
                                    return (
                                        <option key={el.id} value={el.id}>
                                            {getT(el.name)}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        {form?.date_production?.from  || form?.date_production?.to ? <ClearData key={'clear_data_production'} getT={getT} clearData={()=>{
                            changeFilterValue("date_production", {
                                    from: "",
                                    to: "",
                                }, form
                            )
                        }
                        }/> : ''}
                        <label>{getT("Дата производства")}: </label>
                        <DateField key={'date_production'}
                                   valueInput={`${form?.date_production?.from || ""} ... ${form?.date_production?.to || ""}`}
                                   lang={lang}
                                   getT={getT}
                                   saveData={(data) => {
                                       changeFilterValue("date_production", {
                                               from: formatDate(data?.[0]?.startDate),
                                               to: formatDate(data?.[0]?.startDate),
                                           }, form
                                       )
                                   }}


                        />
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group">
                        {form?.date?.from  || form?.date?.to ? <ClearData key={'clear_date'} getT={getT} clearData={()=>{
                            changeFilterValue("date", {
                                    from: "",
                                    to: "",
                                }, form
                            )
                        }
                        }/> : ''}
                        <i
                            style={{
                                fontSize: "24px",
                                color: "red",
                                cursor: "pointer"
                            }}
                            className="fa fa-refresh fa-lg"
                        ></i>
                        <label>{getT("Дата")}: </label>
                        <DateField key={'date'}
                                   // date={data}
                                   valueInput={`${form?.date?.from || ""} ... ${form?.date?.to || ""}`}
                                   lang={lang}
                                   // onChangeDate={(date) => setDate(date)}
                                   getT={getT}
                                   saveData={(data) => {
                                       changeFilterValue("date", {
                                               from: formatDate(data?.[0]?.startDate),
                                               to: formatDate(data?.[0]?.startDate),
                                           }, form
                                       )
                                   }}


                        />
                    </div>
                    {/*    */}
                </div>

            </div>
        </div>
    );
};

export default FilterTask;