import React from "react";
import {withLang} from "../../../hoc/withLang";

const ProgrammMake = (props) => {
  return (
    <div className={"modal fade show"} id="modal-lg">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">
              {props.getT("Генерации программ по заказу")} {props.order[0].order}
            </h4>
            <button
              type="button"
              className="close view_programm_close_button"
              onClick={() => props.toggleViewProgramm(false, [])}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body overflow-auto view_programm_container">
            <div>
              {props.order &&
                props.order.map((e) => {
                  return (
                    <div>
                      <div className={"row view_programm_item"}>
                        <div className={"col-md-12"}>
                          {props.getT("Количество деталей")}: {e.count_detail}
                        </div>
                        <div className={"col-md-12"}>
                          {props.getT("Время генерации программ")}: {e.date_need} -{" "}
                          {e.date_done}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withLang(ProgrammMake);
