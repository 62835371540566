import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {LS_LANG} from "../../../utils/constants";
import {useDispatch} from "react-redux";
import {readinessReportGetMarkOfDetailingOrder} from "../../../store/thunks/salary_project/ready_mark_thunk";

const ReadinessTable = ({getT, orders, outOfDateSumm, order_detailing, selectedFilter}) => {
    const dispatch = useDispatch();
    const [is_open_service_ids, setToggleOpenServiceIds] = useState([])
    let lang = localStorage.getItem(LS_LANG);
    const handlerGetDetailMarkOfOrder = (order_id,service_id) => () => {
        dispatch(readinessReportGetMarkOfDetailingOrder(order_id));
        let key = `order_${order_id}_${service_id}`;
        if(is_open_service_ids.includes(key)){
            setToggleOpenServiceIds(prevState => prevState?.filter(e=> key !== e))
        }else{
            setToggleOpenServiceIds(prevState => [...prevState, key])
        }
    }
    function transformShowDetailing(order, service_id) {
        let service = order?.service?.find(service => Number(service?.service_type) === Number(selectedFilter));
        if(!service) return null
        // let service_id = Number(service?.service_id);
        return  order?.part?.reduce((part_acc, part) => {
            let find_service_part = part?.services_in_detail?.find(serv => Number(serv.service_id) === Number(service_id))
            if (find_service_part) {
                let part_count = order?.count_part?.find(e => Number(e.part_id) === Number(part?.id))
                let part_ready_count = order?.part_ready_services?.filter(e => Number(e.part_id) === Number(part?.id))
                    ?.reduce((acc_serv, part) => {
                        let serv_find = part?.service_ready?.find(serv => Number(serv?.service_id) === service_id);
                        acc_serv = Number(serv_find?.count) + Number(acc_serv)
                        return acc_serv
                    }, {})
                part_acc.push({
                    part_id: part.id,
                    part_count: part_count?.count,
                    count_service: find_service_part?.count,
                    count_ready_service: Number(part_ready_count)  >=0 ? part_ready_count: 0
                })

            }
            return part_acc
        }, []);
    }

    return (
        <table>
            <thead>
            <tr>
                <th scope="col">{getT("ID")}</th>
                <th scope="col">{getT("Услуга")}</th>
                <th scope="col">{getT("Клиент")}</th>
                <th scope="col">{getT("Количество услуги")}</th>
                {/*<th scope="col">{getT("Количество деталей")}</th>*/}
                <th scope="col">{getT("Дата готовности")}</th>
                <th scope="col">{getT("Действие")}</th>
            </tr>
            </thead>
            {orders
                ? orders
                    .map((e) => {
                        let order_key = `order_${e?.data.id}`
                        let is_show_detailing = order_detailing?.hasOwnProperty(order_key) &&
                            is_open_service_ids.includes(`order_${e?.data?.id}_${e.data?.services}`) &&
                            order_detailing?.[order_key]?.data
                        // let is_show_detailing = order_detailing?.hasOwnProperty(order_key) && order_detailing?.[order_key]?.isOpen
                        let detailing = is_show_detailing ? order_detailing?.[order_key]?.data : null;
                        let classNameIconShowDetailing = `fa-angle-${is_show_detailing && detailing ? 'up' : 'down'}`
                        return (
                            <tbody>
                            <tr>
                                <td data-label={getT("ID")}>{e.data.id}</td>
                                <td data-label={getT("Услуга")}>{e.data[lang]}</td>
                                <td data-label={getT("Клиент")}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <span>{e.data.client_name}</span>
                                        <span>{e.data.client_email}</span>
                                        <span>{e.data.client_phone_main}</span>
                                    </div>
                                </td>
                                <td data-label={getT("Количество услуги")}>
                                    {Number(e.data.count).toFixed(2)}
                                </td>
                                {/*<td data-label={getT("Количество деталей")}>*/}
                                {/*    {e.data.count_detail}*/}
                                {/*</td>*/}
                                <td data-label={getT("Дата готовности")}>
                                    {e.data.plan_of_production}
                                </td>
                                <td data-label={getT("Действие")}>
                                    <Link
                                        target="_blank"
                                        to={"/orders/detail/" + e.data.id}
                                    >
                                        <i className="far fa-eye"> </i>
                                    </Link>
                                    <i className={`${classNameIconShowDetailing} fas  text-primary mx-2`}
                                       onClick={handlerGetDetailMarkOfOrder(e?.data?.id, e.data?.services)}> </i>
                                </td>
                            </tr>
                            <tr>
                                {is_show_detailing && <td colSpan={6} className={'p-0 border-0'}>
                                    <table className=" border-0 table-white">
                                        <thead>
                                        <tr >
                                            <th scope="col">{getT("ID")}</th>
                                            <th scope="col">{getT("Кол-во детали")}:
                                                ({transformShowDetailing(detailing, e.data?.services)?.reduce((acc, part)=>{
                                                return Number(Number(acc) + Number(part?.part_count)).toFixed(2)
                                            }, 0)})</th>
                                            <th scope="col">{getT("Кол-во услуг")}
                                                ({Number(e.data.count).toFixed(2)})
                                            </th>
                                            <th scope="col">{getT("Кол-во выполненных услуг по детали")}
                                                ({transformShowDetailing(detailing, e.data?.services)?.reduce((acc, part)=>{
                                                    return Number(Number(acc) + Number(part?.count_ready_service))?.toFixed(2)
                                                }, 0)})
                                            </th>
                                        </tr>
                                        </thead>

                                    {transformShowDetailing(detailing, e.data?.services)?.map(part=>{

                                        return <tr>
                                            <td className={'p-1'} data-label={getT("ID")}>{part?.part_id}</td>
                                            <td className={'p-1'} data-label={getT("Кол-во детали")}>{part?.part_count}</td>
                                            <td className={'p-1'} data-label={getT("Кол-во услуг")}>{part?.count_service}</td>
                                            <td className={'p-1'} data-label={getT("Кол-во выполненных услуг по детали")}>{part?.count_ready_service}</td>
                                        </tr>
                                    })}
                                    </table>

                                </td>}
                            </tr>
                            </tbody>
                        );
                    })
                : []}

            <tfoot>
            <tr>
                <td colSpan="6">
                    {" "}
                    <h5 className="float-right">
                        {getT("Итого")} : &nbsp;
                        {outOfDateSumm && outOfDateSumm}
                    </h5>
                </td>
            </tr>
            </tfoot>
        </table>
    );
};

export default ReadinessTable;