import React from 'react';
import {useForm} from "react-hook-form";
import {LS_USER_ID} from "../../../../../utils/constants";
import Button from "../../../../ui/Button";
/**
 * .
 *
 * @param {function} getT -
 * @param {object || null}  initial_form_value-  если null - это создания документа
 * @param {function}  handlerSubmitForm-  если null - это создания документа
 * @returns  {}  -
 */
const AdditionalDocumentForm = ({getT, initial_form_value=null, handlerSubmitForm}) => {
    let day = initial_form_value?.data_start ? new Date(initial_form_value?.data_start) : new Date()
    let today = day.toISOString().split('T')[0];
    let time = `${day.getHours() >= 10 ? day.getHours() : `0${day.getHours()}`}:${day.getMinutes() >= 10 ? day.getMinutes() : `0${day.getMinutes()}`}`
    const {register, handleSubmit, reset} = useForm();
    let defaultValues = {
        date_for_apply_in_salary: today,
        comment: initial_form_value?.comment ?? null

    }
    return (
        <div>
            <form onSubmit={handleSubmit((data) => {
                handlerSubmitForm({...data,
                    date_for_apply_in_salary: `${data?.date_for_apply_in_salary} ${time}:00`
                })
            })}>
                <input {...register("user")} type={"hidden"} value={localStorage.getItem(LS_USER_ID)}/>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{getT("Дата применения бонусов/штрафов")}</label>
                    <input {...register("date_for_apply_in_salary", {
                        value: defaultValues.date_for_apply_in_salary
                    })} className="form-control" type={"date"} required={true}
                           min={today.split('T')[0]}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{getT("Комментарий")}</label>
                    <textarea className={"form-control"}
                              {...register("comment", {
                                  value: defaultValues.comment
                              })}  required={true}>

                    </textarea>
                </div>
                <div className="text-center">
                    <Button type={"submit"}>
                        {/*<>{initial_form_value ? getT("Обновить") : getT("Создать")}</>*/}
                        {getT("Отправить")}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default AdditionalDocumentForm;