/** STATISTIC XNC_OPERATIONS*/
export const STATISTIC_IS_LOADING = "STATISTIC/IS_LOADING"
export const STATISTIC_SET_XNC_OPERATIONS = "STATISTIC/SET_XNC_OPERATIONS"
export const STATISTIC_XNC_OPERATIONS_BY_KEY_FILTERED_BY_CLIENT_OR_MANAGER_ROLE = "STATISTIC/XNC_OPERATIONS_BY_KEY_FILTERED_BY_CLIENT_OR_MANAGER_ROLE"
export const STATISTIC_XNC_OPERATIONS_MATERIALS_BY_KEY_FILTERED__BY_MATERIAL = "STATISTIC/XNC_OPERATIONS_MATERIALS_BY_KEY_FILTERED_BY_MATERIAL"


export const STATISTIC_SET_XNC_OPERATIONS_MATERIALS = "STATISTIC/SET_XNC_OPERATIONS_MATERIALS"
export const STATISTIC_SET_XNC_OPERATIONS_MATERIALS_BANDS_CONNECTION = "STATISTIC/SET_XNC_OPERATIONS_MATERIALS_BANDS_CONNECTION"
export const STATISTIC_SET_XNC_OPERATIONS_MATERIALS_BANDS_CONNECTION_CREATE_MODAL = "STATISTIC/SET_XNC_OPERATIONS_MATERIALS_BANDS_CONNECTION_CREATE_MODAL"
/**
 * setStatisticXncOperations. Данные  отчёта за период
 *
 * @param {object || null}  payload-
 * @returns  {object}  -
 */
export const setStatisticXncOperations = (payload) => {
    return {
        type: STATISTIC_SET_XNC_OPERATIONS,
        payload: payload
    }
}
/**
 * setStatisticXncOperations. Данные  отчёта за период
 *
 * @param {object || null}  payload-
 * @returns  {object}  -
 */
export const setStatisticXncOperationsMaterials = (payload) => {
    return {
        type: STATISTIC_SET_XNC_OPERATIONS_MATERIALS,
        payload: payload
    }
}
/**
 * setStatisticXncOperations. Данные  отчёта за период
 *
 * @param {object || null}  payload-
 * @returns  {object}  -
 */
export const toggleStatisticXncOperationFilteredByKeyModalAC = ({isOpen = false, opt_key = null, add_opt}) => {
    return {
        type: STATISTIC_XNC_OPERATIONS_BY_KEY_FILTERED_BY_CLIENT_OR_MANAGER_ROLE,
        payload: {isOpen: isOpen, opt_key: opt_key, add_opt: add_opt}
    }
}

/**
 * setStatisticXncOperations. Данные  отчёта за период
 *
 * @param {object || null}  payload-
 * @returns  {object}  -
 */
export const toggleStatisticXncOperationMaterialFilteredByKeyModalAC = ({
                                                                            isOpen = false,
                                                                            key = '',
                                                                            type_material = 0
                                                                        }) => {
    return {
        type: STATISTIC_XNC_OPERATIONS_MATERIALS_BY_KEY_FILTERED__BY_MATERIAL,
        payload: {isOpen: isOpen, key, type_material}
    }
}
/**
 * setStatisticXncOperations. Данные  отчёта за период
 *
 * @param {object || null}  payload-
 * @returns  {object}  -
 */
export const toggleStatisticXncOperationMaterialBandConnectionAC = (payload) => {
    return {
        type: STATISTIC_SET_XNC_OPERATIONS_MATERIALS_BANDS_CONNECTION,
        payload: payload
    }
}
/**
 * setStatisticXncOperations. Интерактивная форма создание связи между материалом и кромкой - модальное окно
 *
 * @param {object}  payload-
 * @param {boolean?}  payload.isOpen-
 * @param {array? }  payload.material -
 * @param {array? }  payload.band -
 * @returns  {object}  -
 */
export const statisticXncOperationMaterialBandConnectionCreateModalAC = (payload) => {
    return {
        type: STATISTIC_SET_XNC_OPERATIONS_MATERIALS_BANDS_CONNECTION_CREATE_MODAL,
        payload: payload
    }
}

export const statisticsIsLoadingAC = (isLoading) => {
    return {
        type: STATISTIC_IS_LOADING,
        payload: isLoading
    }
}