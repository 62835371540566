import React, {useEffect, useState} from 'react';
import Select from "react-select";

const INITIAL_TYPES = {
    goods: {
        title: "Названию товара",
        key: "goods",
        option_key: {value: "goods_id", label: "goods_name"}

    },
    type_material: {
        title: "Категории товара",
        key: "material_type_id",
        option_key: {value: "material_type_id", label: "material_type_name"}
    },
    order: {
        title: "Заказу (ID, названию, коду)",
        key: "order",
        option_key: {value: "id", label: ""}
    }
    // order_id: {
    //     title: "ID заказа",
    //     key: "order",
    //     option_key: {value: "id", label: "id"}
    // },
    // order_name: {
    //     title: "Наименование заказа",
    //     key: "order",
    //     option_key: {value: "id", label: "name"}
    // },
    // order_code: {
    //     title: "Код заказа",
    //     key: "order",
    //     option_key: {value: "id", label: "code"}
    //
    // }
}

const FilterWrap = ({
                        onChangeFilter,
                        getT,
                        orderEntry,
                        goodsEntry
                    }) => {
    const [keyActive, setKeyActive] = useState(null);

    const [options, setOptions] = useState([]);
    const [valueSelect, setValueSelect] = useState({value: null, label: null})
    const onChangeTypeFilter = (key) => (e) => {
        onChangeFilter(keyActive, null)
        setKeyActive(key);
        setValueSelect({value: null, label: null});
        let findActive = INITIAL_TYPES[key];
        let optionsEntryByChoiceKey = []
        if (!findActive) {
            setOptions(optionsEntryByChoiceKey)
        }

        let keyValue = findActive?.option_key?.value;
        let keylable = findActive?.option_key?.label;
        if (["goods", "type_material"].includes(key)) {
            let entry = Array.isArray(goodsEntry) ? goodsEntry : []
            let actualEntryById = entry?.reduce((acc, item) => {
                let key = item?.[keyValue];
                if (!acc.hasOwnProperty(key)) {
                    acc = {
                        ...acc, [key]: {
                            value: item?.[keyValue],
                            label: item?.[keylable]
                        }
                    }
                }
                return acc
            }, {});
            optionsEntryByChoiceKey = Object.values(actualEntryById);

        }

        if (["order"].includes(key)) {
            // if (["order_id", "order_name", "order_code"].includes(keyActive)) {
            let entry = Array.isArray(orderEntry) ? orderEntry : []

            optionsEntryByChoiceKey = entry?.map(item => {
                return {
                    value: item?.id,
                    label: `${item?.id} | ${item?.name} | ${item?.code}`
                }
            })
        }
        setOptions(optionsEntryByChoiceKey)
    };

    const onChangeSelect = (selected) => {
        let value = selected ? selected?.value : null
        let findChangeSelect = INITIAL_TYPES[keyActive];
        onChangeFilter(findChangeSelect.key, value)
        setValueSelect(selected)
    }

    useEffect(() => {
        if (!keyActive && !options?.length && goodsEntry) {
            onChangeTypeFilter(INITIAL_TYPES.goods.key)()
        }
    }, [options, keyActive, onChangeTypeFilter, goodsEntry])
    return (
        <div className={"form-group "}>
            <label htmlFor="" className={"label  mr-2"}>{getT("Поиск по")}:</label>

            {Object.keys(INITIAL_TYPES)?.map(key => {
                let item = INITIAL_TYPES[key];
                let title = getT(item?.title);
                let checked = keyActive === key
                return <>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="type_filter" id={key}
                               checked={checked}
                               onChange={onChangeTypeFilter(key)}
                        />
                        <label className="form-check-label label" htmlFor={key}>{getT(title)}</label>
                    </div>
                </>
            })}


            <Select
                isClearable={true}
                options={options}
                onChange={onChangeSelect}
                value={valueSelect}

            />


        </div>
    );
};

export default FilterWrap;