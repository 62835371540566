import {dispatcherErrorThunk} from "../common_thunk";
import {
    setShiftCreateModalAC,
    setShiftOpenAC,
    setShiftPlaneAC,
    setShiftPlaneCreateModalAC,
    setShiftPlaneUpdateModalAC,
    setShiftsAC,
    setShiftUpdateModalAC
} from "../../actions/SalaryProject";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {ShiftAPI} from "../../../api/Salary/ShiftAPI";
import {createFormData} from "../../../utils/helpers";
import {ShiftPlaneAPI} from "../../../api/Salary/ShiftPlaneAPI";
import {getDefaultPlaneThunk} from "./default_plane_thunk";
import {getEquipmentWorkerGroupThunk} from "./worker_group_thunk";
import {LS_USER_ID} from "../../../utils/constants";

/**
 * Получить все смены
 * @param {object|null} api_data - offset=0/pages, open_for_edit=0/1
 * @param {function} dispatch
 */
async function dispatcherGetShift(api_data = null, dispatch) {
    let _data = !api_data ? {offset: 0, open_for_edit: 0} : api_data
    const form_data = createFormData(_data)
    const response = await ShiftAPI.get(form_data);
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    dispatch(setShiftsAC(response))
}

/**
 * Получить все смены
 */
export const getShiftThunk = () => async (dispatch, getState) => {
    try {
        const state = getState().salary_project;
        const shift_filter_state = state?.shift_filter;
        dispatch(toggleLoaderStatusAC(true));
        await dispatcherGetShift(shift_filter_state, dispatch);
        dispatch(toggleLoaderStatusAC(false));

    } catch (error) {
        dispatch(dispatcherErrorThunk(error))
    }
}
export const createShiftThunk = (form_data) => async (dispatch, getState) => {
    try {
        const state = getState().salary_project;
        const shift_filter_state = state?.shift_filter;
        dispatch(toggleLoaderStatusAC(true));
        const response = await ShiftAPI.add(form_data);
        if (response.hasOwnProperty('error') && response.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        await dispatcherGetShift(shift_filter_state, dispatch);
        dispatch(setShiftCreateModalAC({isOpen: false}));
        dispatch(toggleLoaderStatusAC(false));

    } catch (error) {
        dispatch(dispatcherErrorThunk(error))
    }
}

export const updateShiftThunk = (form_data) => async (dispatch, getState) => {
    try {
        const state = getState().salary_project;
        const shift_filter_state = state?.shift_filter;
        dispatch(toggleLoaderStatusAC(true));
        const response = await ShiftAPI.update(form_data);
        if (response.hasOwnProperty('error') && response.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        await dispatcherGetShift(shift_filter_state, dispatch);
        dispatch(setShiftUpdateModalAC({isOpen: false}))
        dispatch(toggleLoaderStatusAC(false));

    } catch (error) {
        dispatch(dispatcherErrorThunk(error))
    }
}
export const closeShiftThunk = (api_data, closeModalCB) => async (dispatch, getState) => {
    try {


        dispatch(toggleLoaderStatusAC(true));
        const response = await ShiftAPI.close(api_data);
        if (response.hasOwnProperty('error') && response.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        dispatch(openShiftThunk({shift_id: api_data?.shift}))
        if(closeModalCB) closeModalCB()

        dispatch(toggleLoaderStatusAC(false));

    } catch (error) {
        dispatch(dispatcherErrorThunk(error))
    }
}
export const deleteShiftThunk = (form_data) => async (dispatch, getState) => {
    try {
        const state = getState().salary_project;
        const shift_filter_state = state?.shift_filter;
        dispatch(toggleLoaderStatusAC(true));
        const response = await ShiftAPI.delete(form_data);
        if (response.hasOwnProperty('error') && response.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }
        await dispatcherGetShift(shift_filter_state, dispatch);
        dispatch(setShiftUpdateModalAC({isOpen: false}))
        dispatch(toggleLoaderStatusAC(false));

    } catch (error) {
        dispatch(dispatcherErrorThunk(error))
    }
}
/**
 * Открыть смену.
 *
 * @param {number} shift -
 */
export const openedShiftThunk = (shift) => async (dispatch, getState) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const state = getState().salary_project;
        const shift_filter_state = state?.shift_filter;
        const form_data =createFormData({
            shift: shift,
            user: localStorage.getItem(LS_USER_ID)
        })
        const response = await ShiftAPI.open(form_data)
        if (response.hasOwnProperty('error') && response.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }

        await dispatcherGetShift(shift_filter_state, dispatch);
        dispatch(toggleLoaderStatusAC(false));

    } catch (error) {
        dispatch(dispatcherErrorThunk(error))
    }
}
async function shiftByIdDispatched(shift_id, dispatch){
    const form_data_get_shift = createFormData({shift_id: shift_id})
    const response_shifts = await ShiftAPI.getById(form_data_get_shift)

    if (!!response_shifts?.error) {
        dispatch(dispatcherErrorThunk(response_shifts));
        return new Promise((_, reject) => reject(response_shifts))
    }
    let shift = response_shifts?.data?.shifts?.[0];
    dispatch(setShiftOpenAC(shift));
    return new Promise(resolve => resolve(response_shifts))
}

async function shiftPlaneDispatched(form_data, dispatch) {
    const shift_plane_response = await ShiftPlaneAPI.getByShift(form_data);
    if (!!shift_plane_response?.error) {
        dispatch(dispatcherErrorThunk(shift_plane_response));
        return new Promise((_, reject) => reject(shift_plane_response))
    }
    dispatch(setShiftPlaneAC(shift_plane_response))
    return new Promise(resolve => resolve(shift_plane_response))
}

/**
 * openShiftThunk Получить смену.
 *
 * @param {number} shift_id - id смены
 */
export const openShiftThunk = ({shift_id}) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));

        await shiftByIdDispatched(shift_id, dispatch)

        await dispatch(getDefaultPlaneThunk());
        await dispatch(getEquipmentWorkerGroupThunk())

        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

/**
 * shiftPlaneCreateThunk. Создать план к смени
 *
 * @param {object} api_data  -
 * @param {number}  api_data.shift - id shift
 * @param {number}  api_data.service_type - id service_type
 * @param {number}  api_data.count_plan -
 * @param {number}  api_data.user - user id
 * @param {string}  api_data.comment -
 */
export const shiftPlaneCreateThunk = (api_data) => async dispatch => {
    try {
        const form_data = createFormData(api_data)
        dispatch(toggleLoaderStatusAC(true));
        const shift_plane_created = await ShiftPlaneAPI.add(form_data);
        if (!!shift_plane_created?.error) {
            dispatch(dispatcherErrorThunk(shift_plane_created))
            return
        }

        const response = await shiftByIdDispatched(api_data.shift, dispatch)
        if (!response.error) {
            dispatch(setShiftPlaneCreateModalAC({isOpen: false}))
        }
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

/**
 * shiftPlaneUpdateThunk. Обновить план к смени
 *
 * @param {object} api_data  -
 * @param {number}  api_data.shift - id shift
 * @param {number}  api_data.service_type - id service_type
 * @param {number}  api_data.count_plan -
 * @param {number}  api_data.user - user id
 * @param {string}  api_data.comment -
 */
export const shiftPlaneUpdateThunk = (api_data) => async dispatch => {
    try {
        const form_data = createFormData(api_data)
        dispatch(toggleLoaderStatusAC(true));
        const shift_plane_created = await ShiftPlaneAPI.update(form_data);
        if (!!shift_plane_created?.error) {
            dispatch(dispatcherErrorThunk(shift_plane_created))
            return
        }

        const response = await shiftByIdDispatched(api_data.shift, dispatch)
        if (!response.error) {
            dispatch(setShiftPlaneUpdateModalAC({isOpen: false}))
        }
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

/**
 * shiftPlaneUpdateThunk. Обновить план к смени
 *
 * @param {object} api_data  -
 * @param {number}  api_data.shift - id shift
 * @param {number}  api_data.service_type - id service_type
 */
export const shiftPlaneDeleteThunk = (api_data) => async dispatch => {
    try {
        const form_data = createFormData(api_data)
        dispatch(toggleLoaderStatusAC(true));
        const shift_plane_created = await ShiftPlaneAPI.delete(form_data);
        if (!!shift_plane_created?.error) {
            dispatch(dispatcherErrorThunk(shift_plane_created))
            return
        }

        const response = await shiftByIdDispatched(api_data.shift, dispatch)
        if (!response.error) {
            dispatch(setShiftPlaneUpdateModalAC({isOpen: false}))
        }
        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}


/**
 * .
 *
 * @param {}  -
 * @param {}  -
 * @returns  {}  -
 */
