// default const for redirect
export const URL_REDIRECT_HOSTNAME = ['localhost', 'crm2.ifurn.pro', 'crm2.ifurn.pro', 'crm.ifurn.pro'];
export const PATHNAME_FOR_REDIRECT = ['/', '', '/login']
export const IFURN_PRO_URL = `${process.env.REACT_APP_IFURN_PRO_URL}#login`;


//NAMING LOCAL STORAGE
export const LS_FIRM = 'i_furn_user_firm';
export const LS_LANG = 'lang';
export const LS_USER_ID = 'i_furn_user_id';
export const LS_WORKER_ID = 'i_furn_worker_id';
export const LS_USER_TOKEN = 'ifurn_service_main_user_token';
export const LS_CLIENT_TOKEN = 'ifurn_service_main_client_token';
export const LS_IS_DISPLAY_MODULE_SALORY = 'i_furn_is_display_module_salory';

//SALARY PROJECT CONSTANTS
/**
 * SALARY_PROJECT_ACCESS_ROLE. права доступа к модулю
 * 3 - ceo, 4 - chief of production
 */
export const SALARY_PROJECT_ACCESS_ROLE = [3,4];
export const SETTING_CHANGE_ACCESS_ROLE = [3];

export const SALARY_PROJECT_BONUSES_FINES_ACTION ={
    bonuses: 'bonuses',
    fines: 'fines',
}


export const PROCESSING_EDGE_KEY_ASSOCIATION = {
    round: {
        chart: 'R',
        edge_id_key: 'edge_edit_round_id',
        title: "Скругление торцов"
    },
    hand: {
        chart: 'H',
        edge_id_key: 'edge_edit_handle_id',
        title: "Врезные ручки"
    },
    srez: {
        chart: 'C',
        edge_id_key: 'edge_edit_srez_id',
        title: "Cрез торца",
        type_adding: {
            custom: {
                title: "Произвольный", value: 0
            },
            standart: {
                title: "Стандартный", value: 1
            }
        }
    },
    faska: {
        chart: 'F',
        edge_id_key: 'edge_edit_faska_id',
        title: "Снятие фаски"
    },

}

export let CURRENCY_LIST = [
    {name: "usd", id: 1},
    {name: "uah", id: 2},
    {name: "eur", id: 3},
    {name: "nis", id: 4},
    {name: "pln", id: 5},
    {name: "mdl", id: 6}
];