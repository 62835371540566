import React, {useState} from 'react';
import Card from "../../../components/ui/Card";
import Button from "../../../components/ui/Button";
import {LS_USER_ID} from "../../../utils/constants";

const ItemRate = ({rate, getT, onHandlerAddRate, currency = null}) => {
    const is_user_in_system = !!Number(localStorage.getItem(LS_USER_ID));
    const [isOpenPlane, setTogglePlane] = useState(false);
    let {actual_rate} = rate;
    const isHasActualRate = !actual_rate?.error;
    const toggleModal = () => {
        let data = {service: rate.goods_service_id};
        if (isHasActualRate) {
            data = {
                ...data,
                price_bad: actual_rate?.price_bad,
                price_good: actual_rate?.price_good,
                price_super: actual_rate?.price_super,
                currency: actual_rate?.currency
            }
        }
        onHandlerAddRate(data)
    }
    const bodyChildren = () => {
        return <>
            <p className={"plane_description cursor-pointer"} title={rate?.description}>{rate?.description}</p>
            <div className="">
                {isHasActualRate && <a className="" onClick={() => setTogglePlane(!isOpenPlane)} role="button">
                    {getT("Тариф")} <i className={`fas fa-arrow-alt-circle-${isOpenPlane ? 'up' : 'down'}`}></i>
                </a>}

                {isHasActualRate ?
                    isOpenPlane &&
                    <div className="collapse show mt-2 py-2" id={`actual_plane_collapse_${rate.goods_service_id}`}>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                    <strong>{getT("Стоимость не выполненного плана")}:</strong>
                                    <strong className={'text-danger text-nowrap'}>{actual_rate?.price_bad}
                                        <span className={"text-dark"}> {currency?.name}</span>
                                    </strong>
                                </p>
                                <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                    <strong>{getT("Показатель нормального плана")}:</strong>
                                    <strong className={'text-warning text-nowrap'}> {actual_rate?.price_good}
                                        <span className={"text-dark"}> {currency?.name}</span>
                                    </strong>
                                </p>
                                <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                    <strong>{getT("Показатель перевыполненного плана")}:</strong>
                                    <strong className={'text-success text-nowrap'}>{actual_rate?.price_super}
                                        <span className={"text-dark"}> {currency?.name}</span>
                                    </strong>
                                </p>
                                <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                    <strong>{getT("Дата")}:</strong> {actual_rate?.date}</p>
                            </li>
                        </ul>
                    </div>
                    : <p className={'bg-secondary text-white m-0 text-center'}>{actual_rate?.error}</p>
                }
            </div>
        </>
    }
    return (
        <Card classNameCard={'card-rate'}
            title={<><small>[{rate?.goods_service_id}]</small> <span title={rate?.name}>{rate.name}</span></>} bodyChildren={bodyChildren()} key={`item_rate_${rate?.goods_service_id}`}
              footerChildren={is_user_in_system && <Button
                  className={`${isHasActualRate ? "btn-info" : "btn-outline-success"}`}
                  onClick={toggleModal}>
                  <>{isHasActualRate ? getT("Обновить") : getT("Создать")}</>
              </Button>
              }
        />
    );
};

export default ItemRate;