import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PageTitle from "../../components/PageTitle/PageTitle";
import ContainerContent from "../../components/ui/ContainerContent";
import {useDispatch, useSelector} from "react-redux";
import {
    wsDeleteOrderGoodsByIdAndGetOrdersThunk,
    wsGetOrdersGoodsThunk
} from "../../store/thunks/wholesale/orders_goods/ws_order_goods_thunk";
import {getWSOrdersGoods} from "../../store/selectors/wholesalary_orders_goods_selector";
import {getGeneralOrdersStatuses} from "../../store/selectors/salary_project_selector";
import {getGeneralOrdersStatusesThunk} from "../../store/thunks/salary_project/general_thunk";
import Pagination from "../../components/Pagination/Pagination";
import ExistOrdersGoodsTab from "./components/ExistOrders/ExistOrdersGoodsTab";
import NeededOrdersTab from "./components/NeededOrders/NeededOrdersTab";
import SwitcherTab from "./components/common/SwitcherTab";
import {wsOrderGoodsSetModalAC} from "../../store/actions/WholesaleOrdersGoodsActions";
import WSOrderGoodsSetModal from "./components/Modal/WSOrdersGoodsSet";
import GeneralStatusesSelector from "../../components/WholeSale/Forms/GeneralStatusesSelector";
import {createDataByFilter} from "./utils/createDataByFilter";
import FilterWrap from "./components/Filter/FilterWrap";

/**
 * @description {WsProductNeedPage} -  страница потребности товара
 * @param {function} getT  -
 * @returns {jsx} -
 */

const WsProductNeedPage = ({getT}) => {
    const dispatch = useDispatch();
    const ws_orders_goods = useSelector(getWSOrdersGoods);
    const orders_statuses = useSelector(getGeneralOrdersStatuses);
    const [pagination, setPagination] = useState({page: 1, offset: 50});

    const [filter, setFilter] = useState({
        status: 1,
        goods: null,
        material_type_id: null,
        order: null,
    })
    const [tab, setTab] = useState(true);
    const onChangeStatus = (e) => {
        let id = e.target.value;
        setFilter(prev => ({...prev, status: id}))
    }
    const onChangeFilterSelect = (key, value) => {
        setFilter((prevState) => {
            return {
                ...prevState,
                goods: null, material_type_id: null, order: null,
                [key]: value

            }
        })
    }
    const handlerChangePage = (page) => {
        setPagination(prev => ({...prev, page: page}))
    }
    useEffect(() => {
        dispatch(getGeneralOrdersStatusesThunk())
    }, [dispatch]);


    const getOrdersGoodsByFilter = useCallback(() => {
        let dataByFilter = createDataByFilter(filter, pagination);
        dispatch(wsGetOrdersGoodsThunk(dataByFilter));
    }, [filter, pagination]);

    useEffect(() => {
        getOrdersGoodsByFilter();
    }, [getOrdersGoodsByFilter]);


    const onDeleteWSByGoodsId = (id) => () => {
        let dataByFilter = createDataByFilter(filter, pagination)
        dispatch(wsDeleteOrderGoodsByIdAndGetOrdersThunk(id, dataByFilter))
    }

    const showOrderGoodsSetModal = (currentGoods = null) => {
        let dataByFilter = createDataByFilter(filter, pagination)
        dispatch(wsOrderGoodsSetModalAC({isOpen: true, currentGoods: currentGoods, filter: dataByFilter}))
    }

    return (
        <ContainerContent>
            <PageTitle title={"ws_product_needs"}/>

            <div className="container-fluid ">
                <div className="row">
                    <div className="col-12">
                        <div className="card mb-2 px-3 py-2">
                            <div className={"row  " + (!tab ? " disabled " : " ")}>
                                <div className="col-12 col-md-auto col-lg-auto">
                                    <FilterWrap onChangeFilter={onChangeFilterSelect} getT={getT}
                                                goodsEntry={ws_orders_goods?.goods?.data}
                                                orderEntry={ws_orders_goods?.orders?.data} />
                                </div>
                                <div className={`col-12 col-md-4 col-lg-3 ${!tab ? "disabled" : ""}`}>
                                    <GeneralStatusesSelector getT={getT} orders_statuses={orders_statuses}
                                                             value={filter.status}
                                                             onChange={onChangeStatus}/>
                                </div>
                            </div>
                        </div>
                        <SwitcherTab setTab={setTab} tab={tab} getT={getT}/>
                        <div className="card">

                            {tab && <ExistOrdersGoodsTab key={"exist-orders"} getT={getT}
                                                         orders_goods={ws_orders_goods?.exist_ws_orders_goods ?? []}
                                                         onDeleteWSByGoodsId={onDeleteWSByGoodsId}
                                                         openUpdateModalForWSGoodsId={showOrderGoodsSetModal}
                            />}
                            {!tab &&
                                <NeededOrdersTab key={"needed-orders"} getT={getT}
                                                 openCreateModalOrderGoodsId={showOrderGoodsSetModal}
                                                 needed_goods={ws_orders_goods?.need_to_be_added ?? []}/>}

                            <Pagination page={pagination.page} pageSize={50}
                                        count={ws_orders_goods?.count_exist_ws_orders_goods}
                                        changeHandler={handlerChangePage}/>
                        </div>

                    </div>
                </div>
            </div>
            <WSOrderGoodsSetModal/>
        </ContainerContent>
    );
};

export default WsProductNeedPage;