import getT from "../getT";

export default function ({order_mark}) {
    if (!Array.isArray(order_mark?.pack_information) || !order_mark?.pack_information?.length) return ''
    let render_add = order_mark?.pack_information
        ?.filter(item => Array.isArray(item?.marks) && item?.marks?.length)
        ?.sort((a, b) => Number(a?.part_id) - Number(b?.part_id))
        ?.map(part => {
            return TrItem(part?.marks)
        })?.join("")
    let render_remove = order_mark?.pack_information
        ?.filter(item => Array.isArray(item?.removed_marks) && item?.removed_marks?.length)
        ?.sort((a, b) => Number(a?.part_id) - Number(b?.part_id))
        ?.map(part => {

            return TrItem(part?.removed_marks)
        })?.join("");

    if (!render_add?.length) return ''

    return `
      <div style="font-size: 0;  page-break-inside: avoid;">
        <table>
          <caption>${getT("Упакованые детали")}</caption>
        <thead>
        <tr>
        <th>${getT("Деталь")}</th>
        <th>${getT("Уникальный номер")}</th>
        <th>${getT("Отметил")}</th>
        <th>${getT("Дата обновления")}</th>
<!--        <th>${getT("Упаковано")}</th>-->
</tr>
</thead>
${render_add}
</table>
</div>
    
    `

}


function TrItem(marks) {
    return marks?.map(item => {
        let user = item.user ? {name: item?.user_name, id: item?.user} : item?.worker ? {
            name: item?.worker_name,
            id: item?.worker
        } : {id: '-', name: '-'};
        return `
    <tr>
    <td>${item?.part_id}</td>
    <td>${item?.unique_num}</td>
    <td>${user.id} - ${user.name}</td>
    <td>${item?.d_update}</td>
</tr>
    
    `
    })?.join("")
}