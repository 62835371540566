export const MATERIAL_SEARCH_SET_DATA = 'MATERIAL_SEARCH/SET_DATA';
export const MATERIAL_SEARCH_TOGGLE_OPEN_PAGE = 'MATERIAL_SEARCH/TOGGLE_OPEN_PAGE';

export const MATERIAL_SEARCH_CHANGE_FILTER = 'MATERIAL_SEARCH/CHANGE_FILTER';

export const materialSearchSetDataAC = ({filter_data, goods, pagination})=>{
    return {
        type: MATERIAL_SEARCH_SET_DATA, payload: {filter_data, goods, pagination:pagination}
    }
}

export const materialSearchTogglePageAC = ({isOpen})=>{
    return {
        type: MATERIAL_SEARCH_TOGGLE_OPEN_PAGE, payload: {isOpen: isOpen}
    }
}
export const materialSearchChangeFilterAC = (payload)=>{
    return {
        type: MATERIAL_SEARCH_CHANGE_FILTER, payload:payload
    }
}
