import {spread} from "axios";

export const createParallelRequestEntryForGroupedProductionMrkToDetail = (api_data) => {
    let {services_entry = [], ...reset_api_data} = api_data
    let parallel_request_entry = [];
    services_entry.forEach(item => {
        if (item?.isChecked) {
            let data = {
                ...reset_api_data,
                service_type_add: item?.serv_type,
                service_add: null
            }
            let orders_map = item?.access_order?.map(id => ({
                ...data, id: id
            }))
            parallel_request_entry = [...parallel_request_entry, ...orders_map]
        } else {
            item?.children?.filter(child=> child.isChecked)?.forEach(child => {
                if (child.isChecked) {
                    let data = {
                        ...reset_api_data,
                        service_type_add: null,
                        service_add: child?.id
                    }
                    let orders_map = child?.access_order?.map(id => ({
                        ...data, id: id
                    }))
                    parallel_request_entry = [...parallel_request_entry, ...orders_map]
                }
            })
        }
    });

    return parallel_request_entry;
}

export const createParallelRequestEntryForProductionMarkToDetail = (api_data) => {
    let {services_entry, ...reset_api_data} = api_data
   return services_entry?.reduce((acc, item) => {
       console.log(item)
        let request = {...reset_api_data};
        if (item.isChecked) {
            acc.push({
                ...request,
                service_type_add: item?.serv_type,
                service_add: null
            })
        } else {
            item?.children?.forEach(child => {
                if(child.isChecked) {
                    acc.push({
                        ...request,
                        service_type_add: null,
                        service_add: child?.id,
                    })
                }
            })
        }
        return acc;
    }, []);
}

/**
 * .
 *
 * @param {}  -
 * @param {}  -
 * @returns  {}  -
 */

export const getErrorResponseSpread = (response)=>{
    let errors_finds = response && Array.isArray(response) && response?.filter(item => item?.hasOwnProperty('error') && !!item?.error)?.map(error => error?.error)
    let errors = [...new Set(errors_finds)]?.join('. ')
    return errors
}
