import React, {useMemo} from 'react';
import {
    getStaticsXncOperationsMaterialByKeModal,
    getStaticsXncOperationsMaterials
} from "../../../store/selectors/statistics_xnc_operations_selector";
import {useDispatch, useSelector} from "react-redux";
import Modal from "../MyModal/Modal";
import {
    toggleStatisticXncOperationMaterialFilteredByKeyModalAC
} from "../../../store/actions/StatisticXncOperationsAction";
import ItemMaterialXncFilling from "./ItemMaterialXncFilling";
import {
    FIELD_NAME_BY_KEY_CLIENT_STATISTICS_XNC_MATERIAL_OPERATION,
    FIELD_NAME_BY_KEY_MATERIAL_STATISTICS_XNC_MATERIAL_OPERATION
} from "./naming_const";



const StatisticsXncOperationsMaterialByKeyModal = ({getT}) => {
    const modal_state = useSelector(getStaticsXncOperationsMaterialByKeModal);
    const statistics_data_state = useSelector(getStaticsXncOperationsMaterials);
    const dispatch = useDispatch();

    const FIELD_NAME_BY_KEY = useMemo(() => {
        return modal_state?.type_material ? FIELD_NAME_BY_KEY_CLIENT_STATISTICS_XNC_MATERIAL_OPERATION : FIELD_NAME_BY_KEY_MATERIAL_STATISTICS_XNC_MATERIAL_OPERATION;
    }, [modal_state])

    const data_filter_by_key = useMemo(() => {
        if (!modal_state?.isOpen) return null
        let key_find = modal_state?.key;
        let key = modal_state?.type_material ? 'material_client_' : 'material';
        let data = statistics_data_state?.[key];
        return data
            ?.filter(item => {
                return item?.data?.hasOwnProperty(key_find)
            })
            ?.map(item => {
                let is_filling = key_find === "material_client_filling" || key_find === "filling";
                let filling_entry_by_orders = is_filling ? statistics_data_state?.['material_filing_data_by_orders']?.filter(e => Number(e?.goods_id) === Number(item?.goods_id))
                    ?.map(e=>{
                        let order_information = statistics_data_state?.['orders_client_user']?.find(order=> Number(order?.id) === Number(e?.order_id))
                        return {
                            ...e,
                            order_information: order_information
                        }
                    }): null;
                let result = {
                    name: item?.name,
                    goods_id: item?.goods_id,
                    count: item?.data?.[key_find]
                }
                if (is_filling) {
                    result = {
                        ...result, filling_entry: filling_entry_by_orders
                    }
                }
                return result
            })?.sort((a, b) => b?.count - a?.count)
    }, [statistics_data_state, modal_state]);
    const handlerClose = () => dispatch(toggleStatisticXncOperationMaterialFilteredByKeyModalAC({
        isOpen: false,
        key: null, type_material: null
    }));
    const titleModal = useMemo(() => {
        let item = FIELD_NAME_BY_KEY?.[modal_state?.key];
        return `${getT(item?.title)}`
    }, [modal_state])
    if (!modal_state?.isOpen) return null

    return (
        <Modal title={titleModal} onClose={handlerClose}
               open={modal_state.isOpen}>
            <ul className="list-group border-0">
                {data_filter_by_key?.map(item => {
                    let is_has_filling = item?.hasOwnProperty('filling_entry') && Array.isArray(item?.filling_entry)&& item?.filling_entry?.length;
                    if(is_has_filling){
                        return  <ItemMaterialXncFilling item={item} getT={getT} unit={getT(FIELD_NAME_BY_KEY?.[modal_state?.key]?.unit ?? '')}/>
                    }
                    return <li
                        className="list-group-item d-flex align-items-center justify-content-between border-top-0 border-left-0 border-right-0">
                        <span className="font-weight-normal"> {item?.name}</span>
                        <span
                            className="font-weight-bold ">  {item?.count}
                            {getT(FIELD_NAME_BY_KEY?.[modal_state?.key]?.unit ?? '')}
                        </span>


                    </li>
                })}
            </ul>
        </Modal>
    );
};

export default StatisticsXncOperationsMaterialByKeyModal;