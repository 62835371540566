import React, {useState} from 'react';

const ItemService = ({item, getT}) => {
    const [isShow, setToggleShow] = useState(false);
    return (
        <li className={"list-item false"}>
            <div className="d-flex align-items-center justify-content-between px-1">
                <span className=""> {item?.name}</span>
                <span className="shift-action"><i className="cursor-pointer fas fa-angle-down" onClick={() => {
                    setToggleShow(!isShow)
                }}></i></span>
            </div>
            {isShow && <ul className={"list-group mt-2"}>
                {item?.items?.map(part => {
                    return <li className={"list-group-item px-1"}>
                        <p className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}>
                            <strong>№ {getT("Деталь")}</strong>
                            <span className={"text-dark"}>{part?.part_id}</span>
                        </p>
                        <p className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}>
                            <strong>{getT("Кол-во")}</strong>
                            <span className={"text-dark"}>{part?.count_part}</span>
                        </p>
                        <p className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}>
                            <strong>{getT("Кол-во услуги")}</strong>
                            <span className={"text-dark"}>{part?.count}</span>
                        </p>
                        {/*<p className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}>*/}
                        {/*    <strong>{getT("Выполнено")}</strong>*/}
                        {/*    <span className={"text-dark"}>{!!Number(part?.finalized) ? getT("Да") : getT("Нет")}</span>*/}
                        {/*</p>*/}
                        <p className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}>
                            <strong>{getT("Бригада")}</strong>
                            <span className={"text-dark"}>{part?.worker_group?.name}</span>
                        </p>
                        {!!(part?.payment && Array.isArray(part?.payment) && part?.payment?.length) && <>
                            <h5 className={"text-success"}>{getT("Оплата")}</h5>
                            <div className="services_ready_list_by_shift_payment">
                                {part?.payment?.map(item => {
                                    return <>
                                    <ul className={"list-unstyled"}>
                                        <li
                                            className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}>
                                            <strong>{getT("Работник")}</strong>
                                            <span className={"text-dark"}>[{item?.worker_id}] {item?.worker}</span>

                                        </li>
                                        <li
                                            className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}>
                                            <strong>{getT("Дата начисления")}</strong>
                                            <span className={"text-dark"}>{item?.payed_date}</span>

                                        </li>
                                        <li
                                            className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}>
                                            <strong>{getT("Сумма")}</strong>
                                            <span className={"text-dark"}>{item?.summ}</span>

                                        </li>
                                        <li
                                            className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}>
                                            <strong>{getT("Подтвердил оплату")}</strong>
                                            <span className={"text-dark"}>{item?.who_confirmed_payment}</span>

                                        </li>
                                    </ul>
                                        </>
                                })}
                            </div>
                        </>
                        }
                    </li>
                })}
            </ul>
            }
        </li>
    )
        ;
};

export default ItemService;