import {dispatcherErrorThunk} from "../common_thunk";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {materialSearchChangeFilterAC, materialSearchSetDataAC} from "../../actions/MaterialSearchActions";
import {SearchMaterialAPI} from "../../../api/SearchMaterial/SearchMaterialAPI";

const transformPostFiltered = (data) => {
    return {
        filter_data: {
            text: data?.text || '',
            type: data?.filter_data?.type,
            producer: data?.filter_data?.producers,
            x: data?.filter_data?.x,
            y: data?.filter_data?.y,
            z: data?.filter_data?.z,
            folders: data?.filter_data?.tree
        },
        pagination: {
            from: data?.from,
            count: data?.count,
        },
        goods: data?.data
    }
}
export const dispatchedMaterialSearchFiltered = async (data={}, dispatch) => {
    let filter_data = {...data}
    let {from, ...reset_filter} = filter_data

    dispatch(materialSearchChangeFilterAC(reset_filter))
    const response = await SearchMaterialAPI.search(data);
    if (response?.hasOwnProperty('error') || !!response.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    let transform_data = transformPostFiltered({...response, text: data?.text})
    dispatch(materialSearchSetDataAC({
        ...transform_data
    }))
    return transform_data
}

export const filteredMaterialSearchThunk = (data={}) => async dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true));

        await dispatchedMaterialSearchFiltered(data, dispatch)

        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}