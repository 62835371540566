import React, {useState} from 'react';
import ItemService from "./ItemService";
import {useDetectClickOutside} from "react-detect-click-outside";

const ItemServiceType = ({item, getT}) => {
    const [isShow, setToggleShow] = useState(false);
    let ref = useDetectClickOutside({onTriggered: () => setToggleShow(false)})
    return (
        <li className={"list-item"} ref={ref}>
           <div className="d-flex align-items-center justify-content-between">
               <span className=""> {item?.name}</span>
               <span className="shift-action"><i className="cursor-pointer fas fa-angle-down" onClick={()=>{setToggleShow(!isShow)}}></i></span>
           </div>
            {isShow && <ul className={"list-group mt-2 list-group-shift-plane "} style={{background:"#f2f2f2"}}>
                {item?.service?.map(serv=>{
                    return <ItemService item={serv} getT={getT}/>
                })}
            </ul>}
        </li>
    );
};

export default ItemServiceType;