import React, {useState} from 'react';
import ApiService from "../../api/api";
import {gaEvents} from "../../gaEvents";

const ArchiveItem = ({item, onDownloadPdf, statusOptions, getT}) => {
    const api = new ApiService();
    const [isAccordion, toggleAccordion] = useState(false)
    if (!item || !statusOptions) return false
    const currentStatus = statusOptions?.filter(s => {
        return Number(s.id) === Number(item?.status?.status_id)
    });
    return (
        <>
            <tr>
                <td data-label={getT("ID")} colSpan={2}>{item?.id}</td>

                <td data-label={getT("Название")} colSpan={3}>{item?.name}</td>
                <td data-label={getT("Клиент")} colSpan={3}>{item?.client?.name}</td>
                <td data-label={getT("Менеджер")} colSpan={3}>{item?.user?.name}</td>
                <td data-label={getT("Статус")} colSpan={2}><span
                    className={"badge bg-" + currentStatus?.[0]?.color}>{item?.status?.name?.[localStorage.getItem('lang')]}</span>
                </td>

                <td data-label={getT("Действия")} colSpan={2}>
                    <>
                        <i
                            style={{
                                cursor: 'pointer',
                                marginLeft: '5px',
                                marginRight: '5px',
                                transform: isAccordion ? ' rotate(90deg)' : 'rotate(-90deg)'
                            }}
                            onClick={() => toggleAccordion(!isAccordion)}
                            className={`fas fa-angle-left ${isAccordion ? 'top' : 'bottom'}`}></i>
                    </>
                </td>
            </tr>
            {isAccordion && <tr>
                <td colSpan={15}>
                    <table width={'100%'}>
                        <thead>
                        <th>{getT("Код")}</th>
                        <th>{getT("Код")}1С</th>
                        <th>{getT("Дата создания заказа")}</th>
                        <th>{getT("Дата создания архива")}</th>
                        <th>{getT("Дата отмены заказа")}</th>
                        <th>{getT('Скачать проект')}</th>
                        </thead>
                        <tr>
                            <td data-label={getT("Код")}>{item?.code}</td>
                            <td data-label={getT("Код") + "1С"}>{item?.code1c}</td>
                            <td data-label={getT("Дата создания заказа")}>{item?.date}</td>
                            <td data-label={getT("Дата создания архива")}>{item?.date_archive}</td>
                            <td data-label={getT("Дата отмены заказа")}>{item?.date_to_cancel}</td>
                            <td data-label={getT('Скачать проект')}>
                                <a
                                onClick={() => {
                                    gaEvents.fileDownload({
                                        file_name: item?.code,
                                        file_extension: '.zip',
                                        link_url: `${api._baseUrl}/archiveproject/download/${item?.code}`,
                                        // link_text: 'archiveproject'
                                    })
                                }}
                                href={`${api._baseUrl}/archiveproject/download/${item?.code}`} download={true}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                                        stroke="#050038" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round"></path>
                                    <path d="M7 10L12 15L17 10" stroke="#050038" stroke-width="2" stroke-linecap="round"
                                          stroke-linejoin="round"></path>
                                    <path d="M12 15V3" stroke="#050038" stroke-width="2" stroke-linecap="round"
                                          stroke-linejoin="round"></path>
                                </svg>
                            </a>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            }
        </>
    );
};

export default ArchiveItem;