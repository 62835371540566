function Upload(props) {
    return (
        <svg id="Capa_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512"
             xmlns="http://www.w3.org/2000/svg">
            <g id="Cloud">
                <path
                    d="m512 256c0 22.08-8.961 42.24-23.359 56.639-14.401 14.4-34.561 23.361-56.641 23.361h-352c-44.16 0-80-35.84-80-80 0-22.08 8.961-42.24 23.359-56.641 10.881-10.879 25.281-18.879 41.602-22.08-.641-5.759-.961-11.519-.961-17.279 0-79.68 64.32-144 144-144 51.199 0 96 26.559 121.279 66.879 7.362-1.92 15.041-2.879 22.721-2.879 53.119 0 96 42.879 96 96v1.6c36.48 7.359 64 39.679 64 78.4z"
                    fill="#80eaff"/>
                <path d="m352 272h-64v64h-64v-64h-64l96-96z" fill="#37f"/>
                <path d="m352 400h-64v-64h-64v64h-64l96 96z" fill="#4db5ff"/>
            </g>
        </svg>
    );
}

export default Upload;
