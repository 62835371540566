import React, {useState} from 'react';

const ItemGoodsRetailPriceTable = ({

                                       getT,
                                       goods_retail_price,
                                       handlerDeleteGoodsRetailPrice,
                                   }) => {
    const [isOpen, setToggleOpen] = useState(false)
    const handlerToggleOpenMore = () => {
        setToggleOpen(!isOpen)
    }
    return (
        <div className="col-12">
            <div className="d-flex flex-wrap  justify-content-between" style={{gap: "20px"}}>
                {goods_retail_price?.length && <h5 onClick={handlerToggleOpenMore} className={"cursor-pointer"}>{getT("Партнёрская таблица цен")}
                    <button onClick={handlerToggleOpenMore} className={"btn "}>
                        <i className={"fas fa-angle-" + (isOpen ? "up" : "down")}></i>
                    </button>
                </h5>}
            </div>
            {isOpen &&
                <table className="table table-sm  table-hover bg-white">
                    <thead>
                    <tr>
                        <th scope="col">{getT("Партнёр")}</th>
                        <th scope="col">{getT("Розничная цена")}</th>
                        <th scope="col">{getT("Цена продажи")}</th>
                        <th scope="col">{getT("Процент прибыли")}</th>
                        <th scope="col">{getT("Минимальное количество для заказа")}</th>
                        <th scope="col">{getT("Валюта")}</th>
                        <th scope="col">{getT("Кол-во дней доставки")}</th>
                        <th scope="col">{getT("Действие")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {goods_retail_price?.map(item => {
                        return (
                            <tr key={`goods_retail_price_tr_${item?.ws_goods_retail_price_id}`}>
                                <td data-label={getT("Партнёр")}>
                                    {item?.firm_partner_name}
                                </td>

                                <td data-label={getT("Розничная цена")}>
                                    {item?.recommended_retail_price}
                                </td>
                                <td data-label={getT("Цена продажи")}>
                                    {item?.income_price}
                                </td>
                                <td data-label={getT("Процент прибыли")}>
                                    {item?.recommended_profit_percentage}
                                </td>
                                <td data-label={getT("Минимальное количество для заказа")}>
                                    {item?.minimal_quantity_for_order}
                                </td>

                                <td data-label={getT("Валюта")}>
                                    {item?.currency_name}
                                </td>
                                <td data-label={getT("Кол-во дней доставки")}>
                                    {item?.default_delivery_days}
                                </td>

                                <td data-label={getT("Действие")}>
                                    <button className={"btn btn-danger btn-sm"}
                                            onClick={handlerDeleteGoodsRetailPrice(item?.ws_goods_retail_price_id)}>{getT("Удалить")}</button>
                                </td>
                            </tr>

                        )
                    })}

                    </tbody>
                </table>
            }

        </div>

    );
};

export default ItemGoodsRetailPriceTable;