import ApiService from "../../api/api";
import {toggleLoaderStatusAC} from "./MainReducer";

export const languagesList = ['ru', 'en', 'ua', 'pl','ro', 'he',  "de", "sk", "lv", "lt", "et"]
const APP_CHANGE_LANGUAGES = 'APP/CHANGE_LANGUAGES';
const APP_SET_WORD = "APP/SET_WORD"

const api = new ApiService();
let initial = {
    local: localStorage.getItem('lang') || 'ua',
    collectionWord: []
}
export const TranslationReducer = (state = initial, action) => {
    switch (action.type) {
        case APP_CHANGE_LANGUAGES: {
            return {
                ...state,
                local: action.payload
            }
        }
        case APP_SET_WORD:{
            let words =[...state.collectionWord];
            words.push(action.payload);
            return{
                ...state,
                collectionWord: [... new Set(words)]
            }
        }
        default: {
            return {...state}
        }
    }
}
export const actions = {
    changeLang: (lang) => ({type: APP_CHANGE_LANGUAGES, payload: lang}),
    addWord:(word)=>({type:APP_SET_WORD, payload:word})
}

export const changeLangApp = (lang) => dispatch => {
    try {
        dispatch(toggleLoaderStatusAC(true))
        api.changeLanguage(lang)
            .then(res => {
                if (res.result) {
                    localStorage.setItem('lang', res.lang);
                    dispatch(actions.changeLang(res.lang));
                    window.location.reload();
                } else {
                    throw new Error(res)
                }
            }).catch(e=>{
            dispatch(toggleLoaderStatusAC(false));
        })
    } catch (e) {
        dispatch(toggleLoaderStatusAC(false));
        console.error(e.message)
    }
}

export const onSendCollection = ()=> (dispatch, getState )=>{
    try{
        const words = getState().translation.collectionWord;
    api.sendPullAllDT(words).then(res => {
        if(res.result){
            alert('OK');
        }else {
            alert('error');
        }
    })
    }catch (e){
        console.log(e.message)
    }
}