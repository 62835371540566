import {
    WHOLESALE_CONNECTION_CLIENTS,
    WHOLESALE_GOODS_COMBINE_RETAIL_PRICE_FROM_PARTNER_SOURCE_SET,
    WHOLESALE_GOODS_PARTNER_PROPERTIES_ADD_MODAL,
    WHOLESALE_GOODS_PARTNER_PROPERTIES_SET_DATA,
    WHOLESALE_GOODS_PARTNER_SETUP_DATA,
    WHOLESALE_GOODS_PARTNER_SETUP_MODAL,
    WHOLESALE_GOODS_RETAIL_PRICE_ADD_MODAL,
    WHOLESALE_GOODS_RETAIL_PRICE_READ_MODAL,
    WHOLESALE_GOODS_RETAIL_PRICE_SET,
    WHOLESALE_GOODS_STOCK_RETAIL_OF_FIRM_ADD_MODAL,
    WHOLESALE_GOODS_STOCK_RETAIL_OF_FIRM_DATA, WHOLESALE_GOODS_STOCK_RETAIL_PRICE_READ_MODAL,
    WHOLESALE_INITIAL_VALUES_FOR_CONNECTION_REQUEST_COMPANY_PAGE,
    WHOLESALE_OPERATIONS_WITH_PRODUCT_GROUP_FIRM,
    WHOLESALE_SET_COMPANY_CLIENT_LIST,
    WHOLESALE_SET_PARTNER_FIRM_LIST,
    WHOLESALE_TOGGLE_CONNECTION_REQUEST_COMPANY_CLIENT_MODAL
} from "../actions/WholesaleActions";
import {
    WS_ORDERS_GOODS_GET,
    WS_ORDERS_GOODS_GET_INFORMATION_MODAL,
    WS_ORDERS_GOODS_SET_MODAL
} from "../actions/WholesaleOrdersGoodsActions";
import {WS_ORDERS_SUPPLY_GET, WS_ORDERS_SUPPLY_SET_MODAL} from "../actions/WholesaleOrdersSupplyActions";

const initialState = {
    connection_clients: null, // партнёры - покупатели
    company_client_list: null, // список клиентов фирмы
    partner_firm_list: null, // список всех партнёров,
    connection_request_company_client_modal: {
        isOpen: false,
        data: null
    },
    //Операции с группами товаров фирмы
    operations_with_product_groups_firm: {
        material_type_list: null,
        exceptions: null,

    },
    goods_stock_retail_of_firm: null,
    goods_stock_retail_of_firm_add_modal: {
        isOpen: false,
        goods: null,
    },
    goods_stock_retail_price_read_modal: {
        isOpen: false,
        goods: null
    },
    goods_partner_setup: null,
    goods_partner_setup_modal: {
        isOpen: false,
        goods: null,
        firm_main: null
    },
    combined_retail_price_from_partner_source: {
        partner: null,
        source: null
    },
    goods_retail_price: null,
    goods_retail_price_read_modal: {
        isOpen: false,
        goods: null,
        retail_price: null
    },
    goods_retail_price_add_modal: {
        isOpen: false,
        goods: null,
        goods_retail_price: null
    },
    partner_properties: null,
    partner_properties_add_modal: {
        isOpen: false,
        property: null,
        firm_main: null
    },
    orders_goods: null,
    order_goods_set_modal: {
        isOpen: false,
        currentGoods: null,
        filter: null
    },
    goods_information_modal: {
        isOpen: false,
        availabilityInfo: null,
        currentGoods: null,
        orderId: null,
        exist: null
    },
    orders_supply: {
        data: null,
        orders_for_current_firm_as_partner: null,
        orders_for_current_firm_as_main_firm: null,
        filter: {
            total: 0,
            offset: 0,
            limit: 50,
            status: 1,
            search_by_name: null,
            supplier: null,
            date_from: null,
            date_to: null,
            main_firm_request: 0

        }
    },
    order_supply_dinamic_find: {},
    order_supply_set_modal: {

        isOpen: false,
        orderCurrent: null,

    }


}


export const WholesaleReducer = (state = initialState, action) => {
    switch (action.type) {
        case WS_ORDERS_GOODS_SET_MODAL: {
            return {
                ...state, order_goods_set_modal: {
                    ...state.order_goods_set_modal,
                    ...action.payload
                }
            }
        }
        case WS_ORDERS_SUPPLY_SET_MODAL: {
            return {
                ...state,
                order_supply_set_modal: action.payload
            }
        }
        case WS_ORDERS_SUPPLY_GET: {
            return {
                ...state,
                orders_supply: {
                    ...state.orders_supply,
                    ...action.payload
                }
            }
        }
        case WS_ORDERS_GOODS_GET_INFORMATION_MODAL: {
            return {
                ...state, goods_information_modal: {
                    ...action.payload
                }
            }
        }
        case WS_ORDERS_GOODS_GET: {
            return {
                ...state, orders_goods: action.payload
            }
        }
        case WHOLESALE_GOODS_PARTNER_PROPERTIES_SET_DATA: {
            return {
                ...state,
                partner_properties: action.payload

            }
        }
        case WHOLESALE_GOODS_PARTNER_PROPERTIES_ADD_MODAL: {
            return {
                ...state,
                partner_properties_add_modal: {
                    ...state?.partner_properties_add_modal,
                    ...action.payload
                }
            }
        }
        case WHOLESALE_GOODS_COMBINE_RETAIL_PRICE_FROM_PARTNER_SOURCE_SET: {
            return {
                ...state,

                combined_retail_price_from_partner_source: {
                    ...state.combined_retail_price_from_partner_source,
                    ...action.payload
                }
            }
        }
        case WHOLESALE_GOODS_RETAIL_PRICE_ADD_MODAL: {
            return {
                ...state, goods_retail_price_add_modal: {
                    ...state.goods_retail_price_add_modal,
                    ...action.payload
                }
            }
        }
        case WHOLESALE_GOODS_RETAIL_PRICE_READ_MODAL: {
            return {
                ...state, goods_retail_price_read_modal: {
                    ...state.goods_retail_price_read_modal,
                    ...action.payload
                }
            }
        }
        case WHOLESALE_GOODS_RETAIL_PRICE_SET: {
            return {
                ...state, goods_retail_price: action.payload
            }
        }
        case WHOLESALE_GOODS_STOCK_RETAIL_PRICE_READ_MODAL: {
            return {
                ...state,
                goods_stock_retail_price_read_modal: {
                    ...state.goods_stock_retail_price_read_modal,
                    ...action.payload
                }
            }
        }
        case WHOLESALE_GOODS_STOCK_RETAIL_OF_FIRM_ADD_MODAL: {
            return {
                ...state, goods_stock_retail_of_firm_add_modal: {
                    ...state?.goods_stock_retail_of_firm_add_modal,
                    ...action.payload
                }

            }
        }
        case WHOLESALE_GOODS_PARTNER_SETUP_MODAL: {
            return {
                ...state,
                goods_partner_setup_modal: {
                    ...state?.goods_partner_setup_modal,
                    ...action.payload
                }
            }
        }
        case WHOLESALE_GOODS_PARTNER_SETUP_DATA: {
            return {
                ...state,
                goods_partner_setup: action.payload
            }
        }
        case WHOLESALE_GOODS_STOCK_RETAIL_OF_FIRM_DATA: {
            return {
                ...state,
                goods_stock_retail_of_firm: action.payload
            }
        }
        case WHOLESALE_OPERATIONS_WITH_PRODUCT_GROUP_FIRM: {
            return {
                ...state,
                operations_with_product_groups_firm: {
                    ...state?.operations_with_product_groups_firm,
                    ...action.payload
                }
            }
        }
        case WHOLESALE_CONNECTION_CLIENTS: {
            return {
                ...state, connection_clients: action.payload
            }
        }
        case WHOLESALE_SET_COMPANY_CLIENT_LIST: {
            return {
                ...state, company_client_list: action.payload
            }
        }
        case WHOLESALE_SET_PARTNER_FIRM_LIST: {
            return {
                ...state, partner_firm_list: action.payload
            }
        }
        case WHOLESALE_TOGGLE_CONNECTION_REQUEST_COMPANY_CLIENT_MODAL: {
            return {
                ...state, connection_request_company_client_modal: {
                    ...state?.connection_request_company_client_modal,
                    ...action.payload,

                }
            }
        }
        case WHOLESALE_INITIAL_VALUES_FOR_CONNECTION_REQUEST_COMPANY_PAGE: {
            let connection_clients =
                action?.payload?.hasOwnProperty('connection_clients') ?
                    action?.payload?.connection_clients :
                    state?.connection_clients;

            let company_client_list =
                action?.payload?.hasOwnProperty('company_client_list') ?
                    action?.payload?.company_client_list :
                    state?.company_client_list;
            let partner_firm_list =
                action?.payload?.hasOwnProperty('partner_firm_list') ?
                    action?.payload?.partner_firm_list :
                    state?.partner_firm_list;

            return {
                ...state,
                connection_clients: connection_clients,
                company_client_list: company_client_list,
                partner_firm_list: partner_firm_list
            }
        }
        default: {
            return state
        }
    }
}