import React, {useEffect, useState} from 'react';
import Button from "../../../components/ui/Button";
import {LS_USER_ID, LS_WORKER_ID} from "../../../utils/constants";
import {useDispatch} from "react-redux";
import {
    deleteProductionMarkToDetailThunk,
    putProductionMrkToDetailThunk
} from "../../../store/thunks/salary_project/ready_mark_thunk";
import {
    createEntryPutAndDeleteService,
    createFieldsForListChecked,
    updateItemServiceType,
    updateServiceType
} from "../read_mark_util";

/**
 * Услуги заказа.
 *
 * @param {object} order_service - услуги
 * @param {object} shift_form_values - данные о смене
 * @param {number}  order_id- айди заказа
 * @param {function} getT - translate
 * @returns  {jsx}  -
 */
const OrderService = ({order_service, getT, shift_form_values, order_id}) => {
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const [fields, setFields] = useState({});

    useEffect(() => {
        let result_fields = {};
        if (!order_service || !isOpen) {
            setFields(result_fields);
            return;
        }
        result_fields = createFieldsForListChecked(order_service);
        setFields(result_fields)
    }, [order_service, isOpen]);


    //** обновление типа услуги. вкл.выкл*/
    const handlerChangeServiceType = (services_item) => (e) => {
        let checked = e.target.checked;
        let key = `service_${services_item.serv_type}`
        let result_service_type = updateServiceType(fields[key], checked);
        setFields((prevState) => ({
            ...prevState,
            [key]: {
                ...result_service_type
            }
        }))
    }
    //** обновление  услуги. вкл.выкл*/
    const handlerChangeItemServiceType = (services_item, children_serv_type) => (e) => {
        let checked = e.target.checked;
        let key = `service_${services_item.serv_type}`;
        let result_service_type = updateItemServiceType(fields[key], children_serv_type, checked)
        setFields((prevState) => ({
            ...prevState,
            [key]: {
                ...result_service_type
            }
        }))
    }


    const handlerSendServiceMark = () => {
        let {put, remove} = createEntryPutAndDeleteService(fields);
        //::TODO дописать логику работник или пользователь
        let api = {
            id: order_id,
            shift: shift_form_values?.shift?.salary_shift_id,
            worker_group: shift_form_values?.worker_group,
            user: localStorage.getItem(LS_USER_ID),
            equipment_worker: localStorage.getItem(LS_WORKER_ID),
            part_for_service_add: [],
            services_entry: []
        }

        if (Array.isArray(put) && put?.length) {
            dispatch(putProductionMrkToDetailThunk({...api, services_entry: put}))
        }
        if (Array.isArray(remove) && remove?.length) {
            dispatch(deleteProductionMarkToDetailThunk({...api, services_entry: remove}))
        }
    }
    return (<div className={'my-2  py-2 px-0 border-top'}>
        <div className="card-header border-0 px-0 py-0" onClick={() => setIsOpen(!isOpen)}>
            <strong className="">{ getT("Услуги") }</strong>
            <i className={`ml-2 fas fa-angle-${isOpen ? 'up' : 'down'}`}></i>
        </div>
        {isOpen && <div className={''}>
            <ul className="list-group mt-2">
                {order_service?.map(service => {
                    let field_service = fields[`service_${service?.serv_type}`];
                    return <li className="list-group-item py-1 px-0 fs-14 border-0" key={`service_root_${service?.serv_type}`}>

                        <div className="form-check">
                            <input className="form-check-input" type="checkbox"
                                   checked={field_service?.isChecked}
                                   id={`service_root_${service?.serv_type}`}
                                   onChange={handlerChangeServiceType(service)}
                            />
                            <label className="form-check-label" htmlFor={`service_root_${service?.serv_type}`}>
                                {service?.name_service_type}
                                <i className='fas fa-info-circle cursor-pointer  text-primary ml-1'
                                   title={service?.description_service_type}></i>
                            </label>
                        </div>
                        {service?.children?.map(child => {
                            let field_current = field_service?.children?.find(({id}) => Number(id) === Number(child?.services))
                            return <div className="form-check ml-2" key={`service_child_${child?.services}`}>
                                <input className="form-check-input" type="checkbox"
                                       checked={field_current?.isChecked}
                                       id={`service_child_${child?.services}`}
                                       onChange={handlerChangeItemServiceType(service, Number(child?.services))}/>
                                <label className="form-check-label" htmlFor={`service_child_${child?.services}`}>
                                    {child?.name_service} <i
                                    className='fas fa-info-circle cursor-pointer text-primary ml-1'
                                    title={child?.description_service}></i>
                                </label>
                            </div>
                        })}

                    </li>
                })}
            </ul>
            <div className="text-center mt-2">
                <Button onClick={handlerSendServiceMark}>{getT("Отправить")}</Button>
            </div>
        </div>
        }
    </div>);
};

export default OrderService;