import React from 'react';
import {useForm} from "react-hook-form";

const TYPES_OF_SALE = {
    "price_by_retail": {
        title: "Продажа по розничной цене Оптовика",
        key: "price_by_retail"
    },
    "price_by_income": {
        title: "Продажа с наценкой от входящей цены",
        key: "price_by_income"
    },
    "price_0": {
        title: "Продажа по 0.01 товара",
        key: "price_0"
    },

}


const GoodsPartnerSetupForm = ({handlerSend, getT}) => {
    const {watch, setValue, handleSubmit, register, getValues} = useForm({
        defaultValues: {
            minimal_quantity_restriction: true,
            price_by_retail: 0,
            price_by_income: 0,
            price_0: 0,
            days_delivery_add: 1,
            price_by_income_percentage: 1
        }
    });
    const handleCheckboxChange = (key) => (e) => {
        let value = Number(e.target.checked);
        // Устанавливаем выбранный чекбокс в 1, а остальные в 0
        setValue(TYPES_OF_SALE.price_by_retail.key, key === TYPES_OF_SALE.price_by_retail.key ? value : 0);
        setValue(TYPES_OF_SALE.price_by_income.key, key === TYPES_OF_SALE.price_by_income.key ? value : 0);
        setValue(TYPES_OF_SALE.price_0.key, key === TYPES_OF_SALE.price_0.key ? value : 0);

        if (key !== TYPES_OF_SALE.price_by_retail.key || (key === TYPES_OF_SALE.price_by_retail.key && !value)) {
            setValue("price_by_income_percentage", 1);
        }
    };
    const priceByIncome = watch('price_by_income');

    return (
        <form action="" onSubmit={handleSubmit(handlerSend)}>
            <div className="form-group row" style={{rowGap: "15px"}}>
                <div className="col-12">
                    <label>{getT("Тип продажи")}</label>
                    {Object.keys(TYPES_OF_SALE)?.map((key) => (
                        <div className="form-check" key={key}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id={`id_${key}`}
                                {...register(key, {
                                    onChange: handleCheckboxChange(key),
                                })}
                                checked={!!Number(watch(key))}
                            />
                            <label className="form-check-label" htmlFor={`id_${key}`}>
                                {getT(TYPES_OF_SALE[key].title)}
                            </label>
                        </div>
                    ))}
                </div>
                {!!Number(priceByIncome) && <div className="col-12 col-md-6">
                    <label htmlFor="formGroupExampleInput">{getT("Процент от продажи")}</label>
                    <input type="number"
                           className="form-control"   {...register("price_by_income_percentage", {type: "number"})}
                           step={0.01}/>
                </div>}
                <div className="col-12 col-md-6">
                    <label htmlFor="formGroupExampleInput">{getT("Кол-во дней к доставке")}</label>
                    <input type="number" className="form-control"   {...register("days_delivery_add", {type: "number"})}
                           min={1}/>
                </div>
                <div className="col-12">
                    <div className="form-check ">
                        <input className="form-check-input" type="checkbox" id={`id_${'minimal_quantity_restriction'}`}
                               {...register("minimal_quantity_restriction")}
                        />
                        <label className="form-check-label" htmlFor={`id_${'minimal_quantity_restriction'}`}>
                            {getT("Использовать ограничение по минимальному количеству при заказе")}
                        </label>
                    </div>
                </div>
            </div>
            <div className="mt-2 text-center">
                <button className={"btn btn-primary"} type={"submit"}>{getT("Отправить")}</button>
            </div>
        </form>
    );
};

export default GoodsPartnerSetupForm;