import React from 'react';
import LoaderImage from '../../image/loader.svg'

const Loader = (props) => {
    return(
        <div className={'lodaerContainerMain'}>
            <div className="loaderInner">
                <img src={LoaderImage} alt=""/>
            </div>
            <div>
                <h3>Loading all data...</h3>
            </div>
        </div>
    )
}

export default Loader;