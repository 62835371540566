import {dispatcherErrorThunk} from "../common_thunk";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {wholesaleGoodsPartnerSetupDataAC} from "../../actions/WholesaleActions";
import {WholesaleGoodsPartnerSetupAPI} from "../../../api/Wholesale/WholesaleGoodsPartnerSetupAPI";
import {createFormData} from "../../../utils/helpers";

//***настройки остатков для розничной и оптовой продажи  */
async function dispatchedGoodsPartnerSetup(main_firm, dispatch) {
    let formData = createFormData({main_firm: main_firm})
    const response = await WholesaleGoodsPartnerSetupAPI.getGoodsPartnerSetUp(formData);
    if (response.hasOwnProperty('error') && response?.error) {
        dispatch(dispatcherErrorThunk(response));
        return
    }
    dispatch(wholesaleGoodsPartnerSetupDataAC(response?.data))
}

export const getWSGoodsPartnerSetupThunk = (main_firm) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        await dispatchedGoodsPartnerSetup(main_firm, dispatch)

        dispatch(toggleLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}

export const setWSGoodsPartnerSetupThunk = (data) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await WholesaleGoodsPartnerSetupAPI.setGoodsPartnerSetUp([data]);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }

        await dispatchedGoodsPartnerSetup(data?.firm_main, dispatch)

        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
export const deleteWSGoodsPartnerSetupThunk = ({id, firm_main}) => async (dispatch) => {
    try {
        dispatch(toggleLoaderStatusAC(true));
        const response = await WholesaleGoodsPartnerSetupAPI.deleteGoodsPartnerSetUp([{id: id}]);
        if (response.hasOwnProperty('error') && response?.error) {
            dispatch(dispatcherErrorThunk(response));
            return
        }

        await dispatchedGoodsPartnerSetup(firm_main, dispatch)

        dispatch(toggleLoaderStatusAC(false));
    } catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}
