import React, {useMemo, useState} from 'react';
import Modal from "../MyModal/Modal";
import {useDispatch, useSelector} from "react-redux";
import {getReadyMarkPartForOrderModal} from "../../../store/selectors/salary_project_selector";
import {
    readyMarkPartForOrderChoiceServiceModalAC,
    readyMarkPartForOrderModalModalAC
} from "../../../store/actions/SalaryProject";
import SelectTypeService from "./components/PartForOrderProductionMarks/SelectTypeService";
import CuttingContainer from "./components/PartForOrderProductionMarks/Cutting/CuttingContainer";
import EdgingContainer from "./components/PartForOrderProductionMarks/EdgingContainer";
import OthersContainer from "./components/PartForOrderProductionMarks/OthersContainer";

const INITIAL_SELECT_TYPE_SERVICE = {
    [`serv_type_7`]: false,
    [`serv_type_10`]: false,
    [`serv_type_13`]: false,
    [`serv_type`]: false,
}

const SalaryReadeMakPartForOrderForProductionMarksModal = ({getT, callbackAfterCloseModal=null}) => {
    const dispatch = useDispatch();
    const modal_state = useSelector(getReadyMarkPartForOrderModal);

    const tab_service_type = useMemo(() => {
        let service_type = modal_state?.choice_service?.service_type_add;
        let service_id = modal_state?.choice_service?.service_id;
        if (!service_type || !service_id) return INITIAL_SELECT_TYPE_SERVICE;
        let key = `serv_type_${service_type}`;

        let has_key_in_object = INITIAL_SELECT_TYPE_SERVICE.hasOwnProperty(key)
        if (has_key_in_object) return {...INITIAL_SELECT_TYPE_SERVICE, [key]: true}
        return {...INITIAL_SELECT_TYPE_SERVICE, serv_type: true}
    }, [modal_state?.choice_service])

    if (!modal_state.isOpen) return null;
    const handlerClose = () => {
        dispatch(readyMarkPartForOrderModalModalAC({isOpen: false, data: null}))
        dispatch(readyMarkPartForOrderChoiceServiceModalAC({service_type_add: null, service_id: null}));
        if(callbackAfterCloseModal) callbackAfterCloseModal();
    }


    return (
        <Modal title={getT("Групповая отметка услуг в заказе")} open={modal_state.isOpen} onClose={handlerClose}>
            <div className="">
                <SelectTypeService service_type={modal_state?.data?.service_type}
                                   service={modal_state?.data?.service}
                                   service_finalized={modal_state?.data?.service_finalized}
                                   form_value={modal_state.choice_service}
                                   getT={getT}
                />
                {tab_service_type['serv_type_7'] &&
                    <CuttingContainer order={modal_state.data}
                                      getT={getT}
                                      choice_service={modal_state.choice_service}

                    />}
                {(tab_service_type['serv_type_10'] || tab_service_type['serv_type_13']) &&
                    <EdgingContainer order={modal_state.data}
                                     getT={getT}
                                     choice_service={modal_state.choice_service}/>}
                {tab_service_type['serv_type'] &&
                <OthersContainer
                    order={modal_state.data}
                    getT={getT}
                    choice_service={modal_state.choice_service}/>
                }

            </div>
        </Modal>
    );
};

export default SalaryReadeMakPartForOrderForProductionMarksModal;