import {instances, URI_API_EXTTUNELL} from "./setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const GET_SALORY_SHIFT = 'get_salory_shift';
const DELETE_SALORY_SHIFT = 'delete_salory_shift';
const CLOSE_SALORY_SHIFT = 'close_salory_shift';
const ADD_PLANE_BY_SHIFT = 'add_plan_by_shift';
const ADD_SALARY_SHIFT = 'add_salory_shift';
const OPEN_SHIFT = 'open_salory_shift';
const UPDATE_SALORY_SHIFT = 'update_salory_shift';

/**
 * Смены.
 * @method get  - Список смен
 * @param {formData}  form_data - offset (number page), open_for_edit (number) - открытая/закрытая смены
 *
 * @method getById  - получить смену
 * @param {formData}  form_data - shift_id (number)
 *
 * @method delete  -  Удаление смены, если не было никаких документов по смене
 * @param {formData}  form_data - [{"key":"shift","value":"1","type":"id shift"},{"key":"user","value":"177","type":"user id"}]
 *
 * @method close  - Закрытие смены. После проведения, вносить изменения нельзя
 * @param {object} api_data-  {"user": "177", "shift": "1","koef_plan_from_good_to_super": "30",  "date_when_must_be_payed":"2024-01-01 23:23:23",  "plans": < {"id": "1","status_for_payment": "bad"}>
 *
 * @method add  - Добавление смены
 * @param {formData}  form_data -  [{"key":"date_start","value":"2024-01-01 01:23:23","type":"text"},{"key":"user","value":"266","type":"text"},{"key":"koef","value":"1.5","type":"text"}]
 *
 * @method open  - Открытие смены. Проведение отменяет все платежи по смене, включая дополнительные документы (бонусы, штрафы).
 * @param {formData}  form_data - [{"key":"shift","value":"1","type":"id shift"},{"key":"user","value":"177","type":"user id"}]
 *
 * @method update  - Обновление данных по смене
 * @param {formData}  form_data -[{"key":"date_start","value":"2024-01-01 01:23:23","type":"text"},{"key":"user","value":"177","type":"text"},{"key":"shift","value":"1","type":"text"},{"key":"koef","value":"1.5","type":"text"}]
 */
export const ShiftAPI = {
    get(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: GET_SALORY_SHIFT
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    getById(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: GET_SALORY_SHIFT
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    delete(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: DELETE_SALORY_SHIFT
            }

        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    close(api_data) {
        return instances.post(URI_API_EXTTUNELL, api_data, {
            headers: {
                action: CLOSE_SALORY_SHIFT
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    add(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: ADD_SALARY_SHIFT
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    open(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: OPEN_SHIFT
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    update(form_data) {
        return instances.post(URI_API_EXTTUNELL, form_data, {
            headers: {
                action: UPDATE_SALORY_SHIFT
            }
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }
}