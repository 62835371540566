import {useEffect, useRef, useState} from "react";

export default function (){
    const [isVisible, setVisible] = useState(false);
    const ref = useRef(null);

    useEffect(()=>{
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setVisible(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);}
    }, [ref])

    return{
        ref, isVisible, setVisible
    }
}