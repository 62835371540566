import md5 from "md5";
import TranslateApi from "../languages/translationApi"

export const getTranslateMessageAPI = (key) => {
    const lang = localStorage.getItem('lang') || 'ua';
    const keyMD = md5(key);
    if (TranslateApi[0][keyMD]) {
        if (TranslateApi[0][keyMD][lang]) {
            return TranslateApi[0][keyMD][lang]
        } else {
            console.info('не найденно слова', key)
            return key;
        }
    }

}

export const isShallowEqual = (prev, next) => JSON.stringify(prev) === JSON.stringify(next);

export const isCheckObject = (value) => {
    return (typeof value === "object" && value !== null && !Array.isArray(value));
}
export const createFormData = (data) => {
    let formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
        if (value || value === '' || value === 0) {
            formData.append(key, value)
        }
    }
    return (Object.keys(formData).length) ? false : formData
}

export const createFormDataArrayObj = (data) => {
    let formData = new FormData();
    for (let item = 0; item < data.length; item++) {
        for (const [key, value] of Object.entries(data[item])) {
            if (value || value === '') {
                formData.append(key, value)
            }
        }
    }
    return (Object.keys(formData).length) ? false : formData
}

function formatDateTime(dateTime) {
    const year = dateTime.getFullYear();
    const month = (dateTime.getMonth() + 1).toString().padStart(2, '0');
    const day = dateTime.getDate().toString().padStart(2, '0');
    const hours = dateTime.getHours().toString().padStart(2, '0');
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    const seconds = dateTime.getSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const getRangeDateCurrentAndPrevMonth = (date = null) => {
    // Get current date
    const currentDate = date ? new Date(date) : new Date();

// Get previous month's date
    const prevMonthDate = date ? new Date(date) : new Date();
    prevMonthDate.setMonth(currentDate.getMonth() - 1);
    prevMonthDate.setDate(currentDate.getDate() + 1);

    const formattedCurrentDateTime = formatDateTime(currentDate);
    const formattedPrevMonthDateTime = formatDateTime(prevMonthDate);
    return {
        date_to: formattedCurrentDateTime, date_from: formattedPrevMonthDateTime
    }
}
export const convertRangeToIsoDateMonth = (date) => {
    let date_transform = getRangeDateCurrentAndPrevMonth(date);
    return {
        date_to: new Date(date_transform?.date_to)?.toISOString().split('T')[0],
        date_from: new Date(date_transform?.date_from)?.toISOString().split('T')[0],
    }
}

export const checkBetweenSecondDate = (from, to) => {
    let date1 = new Date(from);
    let date2 = new Date(to);
    let daysLag = Math.ceil(Math.abs(date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
    return daysLag
}

/**
 * groupedOrderReadMark. группировка улсуг и товаров по id заказу
 *
 * @param {array} orders_data -
 * @returns  {object}  -
 */
export const groupedOrderReadMark = (orders_data) => {
    const groupedArray = orders_data.reduce((acc, obj) => {
        const key = `order_${obj?.id}`;
        if (!acc[key]) {
            acc[key] = {
                id: obj?.id, parent: obj?.parent, goods: [], service: [],
            };
        }
        let type = obj?.type;
        acc[key][type].push(obj?.data)

        return acc;
    }, {});

    return groupedArray
}
export const groupedOrderServiceReadMark = (order_service) => {
    const groupedArray = order_service.reduce((acc, obj) => {
        const key = `service_${obj?.serv_type}`;
        if (!acc[key]) {
            acc[key] = {
                serv_type: obj?.serv_type,
                name_service_type: obj?.name_service_type,
                id: obj?.id,
                count: obj?.count,
                description_service_type: obj?.description_service_type,
                children: [],
            };

        }
        let is_has_serv_type = acc[key].children?.some(item_serv_type => {
            return Number(item_serv_type?.services) === Number(obj?.services)
        });
        if (!is_has_serv_type) {
            acc[key].children.push({
                services: obj?.services,
                name_service: obj?.name_service,
                description_service: obj?.description_service,
                service_ready: !!Number(obj?.service_ready),
            })
        }

        return acc;
    }, {});
    return Object.values(groupedArray)
}


export const getCalculateAmountAndVat = (total, total_vat) => {
    let values = `${Number(total) + Number(total_vat)}`
    let amount_vat = parseFloat(values.replace(',', '.'))
    return Number(amount_vat).toFixed(2)
}
export const getCalculateVat = (total, vat) => {
    let values = `${total / 100 * Number(vat)}`
    let amount_vat = parseFloat(values.replace(',', '.'))
    return Number(amount_vat).toFixed(2)
}

export function addSpacesToNumber(number) {
    let amount = ( Math.round(number * 100) / 100 )
    return amount.toString().replace(/\d(?=(\d{3})+\.)/g, '$& ').replace('.', '.');
}
export function formatNumbersThousandsNumber(value) {
    if (isNaN(value)) return 0;
    // if (value === 0) return value
    //::TODO Округление до 2-х знаков
    return Math.floor(Number(value) * 1000) / 1000;
}


export const filteredObjectEmptyValues = (obj)=>{
    return  Object.fromEntries(Object.entries(obj).filter(([_, v]) => {
        if(Array.isArray(v)){
            return  !!v?.length
        }
        return !!v
    }))
}