import React, {useState, useMemo} from 'react';
import {useForm, Controller} from 'react-hook-form';
import Select from 'react-select';
import {filteredObjectEmptyValues} from "../../../utils/helpers";
import FolderIcon from "../../../Icons/FolderIcon";
import CloseModal from "../../../Icons/CloseModal";
import FolderWrap from "../Folder/FolderWrap";

const RESET_FORM_VALUES = {
    text: '',
    type: 'part',
    folders: [],
    producer: [],
    x: [],
    y: [],
    z: [],
    already_ws_started: 0
};

const GoodsFilterWrap = ({
                             filter_values, material_types, filter_data, getT, handlerFilteredGoods,
                         }) => {
    const [isOpenFolder, setIsOpenFolder] = useState(false);
    const {control, handleSubmit, setValue, reset, watch} = useForm({
        defaultValues: {
            text: filter_values?.text || RESET_FORM_VALUES.text,
            type: filter_values?.type || RESET_FORM_VALUES.type,
            folders: filter_values?.folders || RESET_FORM_VALUES.folders,
            producer: filter_values?.producer || RESET_FORM_VALUES.producer,
            x: filter_values?.x || RESET_FORM_VALUES.x,
            y: filter_values?.y || RESET_FORM_VALUES.y,
            z: filter_values?.z || RESET_FORM_VALUES.z,
            already_ws_started: filter_values?.already_ws_started || RESET_FORM_VALUES.already_ws_started,
        }
    });

    const onSubmit = (values) => {
        handlerSubmitFilter(values);
    };

    const handlerSubmitFilter = (values) => {
        let {tree, ...reset} = values;
        let payload_data = {
            ...reset,
            producer: values?.producer?.map(item => Number(item?.value)),
        };
        let result = Object.fromEntries(Object.entries(payload_data).filter(([_, v]) => {
            if (Array.isArray(v)) {
                return !!v?.length;
            }
            return !!v;
        }));
        // console.log(result)
        handlerFilteredGoods(result);
    };

    const handlerRemoveActionSelect = (context, actual_value) => {
        if (context.action === 'remove-value') {
            let values_for_send = {...watch(), [context.name]: actual_value};
            handlerSubmitFilter(values_for_send);
        }
    };

    const handlerChangeTypeMaterial = (e) => {
        let type = e.target.value;
        let text = watch('text');
        let payload_formik = {
            ...RESET_FORM_VALUES,
            text: text,
            type: type,
        };
        reset(payload_formik);
        handlerSubmitFilter(payload_formik);
    };

    const is_change_filter = useMemo(() => {
        let form_values = filteredObjectEmptyValues(watch());
        let initial_val = filteredObjectEmptyValues(filter_values);
        return JSON.stringify(form_values) !== JSON.stringify(initial_val);
    }, [filter_values, watch()]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSubmit(onSubmit)();
        }
    };

    let option_z = filter_data.z?.map(item => ({value: item, label: `${item} ${getT("мм")}`}));
    let option_x = filter_data.x?.map(item => ({value: item, label: `${item} ${getT("мм")}`}));
    let option_y = filter_data.y?.map(item => ({value: item, label: `${item} ${getT("мм")}`}));
    let option_producers = filter_data.producer?.map(item => ({value: item.id, label: item?.name}));

    const handlerChangeTree = (values, isAdded) => {
        let form_values = [...watch('folders')];
        let result = [];
        if (isAdded) {
            result = [...new Set([...form_values, ...values])];
        } else {
            result = form_values.filter(id => !values.includes(id));
        }
        setValue('folders', result);
    };

    const handlerReset = () => {
        let type = watch('type');
        let payload_formik = {
            ...RESET_FORM_VALUES,
            type: type,
        };
        reset(payload_formik);
        handlerSubmitFilter(payload_formik);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} onKeyPress={handleKeyPress}>
            <div className="goods-filter-header">
                {/*<h2 className={'m-0'}>{getT("Материал подбор")}</h2>*/}
                <div className="folders stroke-black" title={getT("Папки")}>
                    {isOpenFolder ? <>
                            {/*<FolderIcon style={{ fill: `${watch('folders')?.length ? 'black' : 'white'}` }} width={30} />*/}
                            <CloseModal onClick={() => setIsOpenFolder(false)} style={{position: 'relative'}}/>
                        </> :

                        <FolderIcon onClick={() => setIsOpenFolder(true)}
                                    style={{fill: `${watch('folders')?.length ? 'black' : 'white'}`}}
                                    width={30}/>}
                </div>
            </div>
            <div className={'goods-filter-container'}>
                <div className="">
                    <div className={"material-selected-filter-tabs-wrap"}>
                        <div className={" d-flex  flex-wrap"}
                             style={{gap: '15px', margin: '10px 0'}}>
                            {material_types?.map(type => {
                                return <div className="form-check d-flex flex-nowrap" key={type?.id}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`type_material_nav`}
                                        id={`type_material_nav_${type?.id}`}
                                        value={type.id}
                                        checked={watch('type') === type?.id}
                                        onChange={handlerChangeTypeMaterial}
                                    />
                                    <label
                                        className="form-check-label cursor-pointer"
                                        style={{
                                            marginLeft: '3px',
                                            fontSize: '17px',
                                            fontWeight: '500',
                                            color: `${watch('type') === type?.id ? '#3586FF' : 'black'}`
                                        }}
                                        htmlFor={`type_material_nav_${type?.id}`}>
                                        {getT(type.name)}
                                    </label>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div className="goods-filter-row">
                    <div className="goods-filter-col search">
                        <label className={"label"}>{getT("Поиск")}</label>
                        <div className="form-field">
                            <Controller
                                name="text"
                                control={control}
                                render={({field}) => (
                                    <input type="text" className={'form-control'} {...field} />
                                )}
                            />
                        </div>
                    </div>

                    <div className="goods-filter-col type-producers">
                        <label className={"label"}>{getT("Производитель")}</label>
                        <Controller
                            name="producer"
                            control={control}
                            render={({field}) => (
                                <Select
                                    {...field}
                                    isMulti
                                    options={option_producers}
                                    onChange={(selectedOptions, context) => {
                                        let value_producer = selectedOptions.map(option => option);
                                        setValue('producer', value_producer);
                                        handlerRemoveActionSelect(context, value_producer);
                                    }}
                                    value={field.value?.map(option => ({value: option.value, label: option.label}))}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            )}
                        />
                    </div>
                    <div className="goods-filter-col value-x">
                        <label className={"label"}>{getT("Горизонталь материала")}</label>
                        <Controller
                            name="x"
                            control={control}
                            render={({field}) => (
                                <Select
                                    {...field}
                                    isMulti
                                    options={option_x}
                                    onChange={(selectedOptions, context) => {
                                        let value_x = selectedOptions.map(option => option.value);
                                        setValue('x', value_x);
                                        handlerRemoveActionSelect(context, value_x);
                                    }}
                                    value={field.value?.map(option => ({value: option, label: option}))}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            )}
                        />
                    </div>
                    <div className="goods-filter-col value-y">
                        <label className={"label"}>{getT("Вертикаль материала")}</label>
                        <Controller
                            name="y"
                            control={control}
                            render={({field}) => (
                                <Select
                                    {...field}
                                    isMulti
                                    options={option_y}
                                    onChange={(selectedOptions, context) => {
                                        let value = selectedOptions.map(option => option.value);
                                        setValue('y', value);
                                        handlerRemoveActionSelect(context, value);
                                    }}
                                    value={field.value?.map(option => ({value: option, label: option}))}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            )}
                        />
                    </div>
                    <div className="goods-filter-col value-z">
                        <label className={"label"}>{getT("Толщина материала")}</label>
                        <Controller
                            name="z"
                            control={control}
                            render={({field}) => (
                                <Select
                                    {...field}
                                    isMulti
                                    options={option_z}
                                    onChange={(selectedOptions, context) => {
                                        let value = selectedOptions.map(option => option.value);
                                        setValue('z', value);
                                        handlerRemoveActionSelect(context, value);
                                    }}
                                    value={field.value?.map(option => ({value: option, label: option}))}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                />
                            )}
                        />
                    </div>
                    <div className="goods-filter-col d-flex align-items-end">
                        <div className=" form-check">
                            <Controller name={'already_ws_started'} control={control} render={({field}) => (
                                <>
                                    <input type="checkbox" className="form-check-input"
                                           id="already_ws_started" {...field}
                                        checked={!!Number(field.value)}
                                           onChange={(e) => {
                                               let value = Number(e.target.checked)
                                               setValue('already_ws_started', value)
                                           }}/>
                                    <label className="label"
                                           htmlFor="already_ws_started">{getT("Уже в продаже")}</label></>
                            )}/>

                        </div>
                    </div>
                </div>
                {isOpenFolder && <FolderWrap tree={filter_data.folders} formik_values={watch('folders')}
                                             handlerChange={handlerChangeTree}/>}
                <div className="goods-filter-buttons">
                    <button type={"submit"}
                            className={`btn btn-primary ${is_change_filter ? 'blink' : ''}`}>{getT("Фильтровать")}</button>
                    <button type={"button"} className={"btn btn-danger"}
                            onClick={handlerReset}>{getT("Сбросить")}</button>
                </div>
            </div>
        </form>
    );
};

export default GoodsFilterWrap;
