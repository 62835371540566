import React, {useState} from 'react';
import Card from "../../../components/ui/Card";
import Button from "../../../components/ui/Button";
import {LS_USER_ID, LS_WORKER_ID} from "../../../utils/constants";

const ItemPlan = ({plane, getT, onHandlerAddPlane}) => {
    const is_user_in_system = !!Number(localStorage.getItem(LS_USER_ID));
    const [isOpenPlane, setTogglePlane] = useState(false);
    let {actual_plan} = plane;
    const isHasActualPlane = !actual_plan?.error;
    const handlerAddPlane = () => {
        console.log('add plane to modal', plane)
        let data = {
            service_type: plane?.goods_service_type_id,
        }
        if (isHasActualPlane) {
            data = {
                ...data,
                count_bad: actual_plan?.count_bad,
                count_normal: actual_plan?.count_normal,
                count_super: actual_plan?.count_super
            }
        }
        onHandlerAddPlane(data)

    }
    const bodyChildren = () => {
        return <>
            <p className={"plane_description cursor-pointer"} title={plane?.description}>{plane?.description}</p>
            <div className="">
                {isHasActualPlane && <a className="" onClick={() => setTogglePlane(!isOpenPlane)} role="button">
                    {getT("Актуальный план")} <i
                    className={`fas fa-arrow-alt-circle-${isOpenPlane ? 'up' : 'down'}`}></i>
                </a>}

                {isHasActualPlane ?
                    isOpenPlane && <div className="collapse show mt-2 py-2"
                                        id={`actual_plane_collapse_${plane.goods_service_type_id}`}>
                        <ul className="list-group">
                            <li className="list-group-item">
                                <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                    <strong>{getT("Показатель не выполненного плана")}:</strong> <strong
                                    className={'text-danger'}>{actual_plan?.count_bad}</strong>
                                </p>
                                <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                    <strong>{getT("Показатель нормального плана")}:</strong> <strong
                                    className={'text-warning'}>{actual_plan?.count_normal}</strong></p>
                                <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                    <strong>{getT("Показатель перевыполненного плана")}:</strong> <strong
                                    className={'text-success'}>{actual_plan?.count_super}</strong>
                                </p>
                                <p className={"fs-14 d-flex justify-content-between flex-row text-secondary"}>
                                    <strong>{getT("Дата")}:</strong> {actual_plan?.date}</p>
                            </li>
                        </ul>
                    </div>
                    : <p className={'bg-secondary text-white text-center m-0'}>{actual_plan?.error}</p>
                }
            </div>
        </>
    }
    return (
        <Card classNameCard={''} title={<>[{plane?.goods_service_type_id}] {plane.name}</>} bodyChildren={bodyChildren()}
              footerChildren={is_user_in_system && <Button className={isHasActualPlane ? "btn-info": "btn-outline-success"}
                                      onClick={handlerAddPlane}>
                  <>{isHasActualPlane ?getT("Обновить") :getT("Создать")}</>
        </Button>
        }/>
    );
};

export default ItemPlan;