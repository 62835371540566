export const getStaticsXncOperations = (state) => state?.statistic.statistic_xnc_operations;
export const getStaticsXncOperationsByKeModal = (state) => state?.statistic.statistic_xnc_operations_by_key_modal;
export const getStaticsXncOperationsMaterials = (state) => state?.statistic.statistic_xnc_operations_material;

export const getStaticsXncOperationsMaterialByKeModal = (state) => state?.statistic.statistic_xnc_operations_material_by_key_modal;
export const getStaticsXncOperationsMaterialBandConnection = (state) => state?.statistic.statistic_xnc_operation_material_band_connection;

export const getStaticsXncOperationsMaterialBandConnectionModal = (state) => state?.statistic.statistic_xnc_operation_material_band_connection_create_modal;

export const getStatisticsIsLoading = (state) => state?.statistic.isLoading;
