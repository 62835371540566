import {dispatcherErrorThunk} from "../common_thunk";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {wholesaleToggleConnectionRequestCompanyClientModal} from "../../actions/WholesaleActions";
import {getCompanyClientsByFirmThunk} from "./connection_client_thunk";


export const openConfirmRequestWsAndGetClientsByFirmModal = (request)=>async dispatch=>{
    try {
        dispatch(toggleLoaderStatusAC(true));
        await dispatch(getCompanyClientsByFirmThunk())
        dispatch(wholesaleToggleConnectionRequestCompanyClientModal({isOpen: true, data: request}))
        dispatch(toggleLoaderStatusAC(false));


    }
    catch (e) {
        dispatch(dispatcherErrorThunk(e))
    }
}