import React from 'react';
import {connect} from "react-redux";
import {
    changeOrdersFilterFormValueAC,
    createNewOrderThunk,
    getManagersDataThunk,
    getOrdersFilteredDataThunk,
    setClientsDataThunk,
    setOrdersDataThunk,
    toggleOrderChangeStatusAC,
    updateOrderDataThunk
} from "../../store/reducers/MainReducer";
import OrdersProblem from "./OrderProblem";

class OrdersProblemContainer extends React.Component {

    componentDidMount() {
        this.props.setOrders({limit: 1, status: 9});
        this.props.setClients(false);
        this.props.setManagers();
    }
    componentWillUnmount(){
        // this.props.checkAuntificationUser()
    }

    render() {
        return <OrdersProblem orders={this.props.archive ? this.props.orders_archive : this.props.orders_actual}
                              title={'orders_shipped'}
                              changeFilterValue={this.props.changeFilterValue}
                              toggleChangeStatus={this.props.toggleChangeStatus}
                              updateOrder={this.props.updateOrder}
                              form={this.props.filterForm}
                              status={this.props.orderStatus}
                              clients={this.props.clients}
                              changedStatus={this.props.changedStatus}
                              createOrder={this.props.createOrder}
                              userRoles={this.props.user.role}

        />
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setOrders: (filters = null) => dispatch(setOrdersDataThunk(filters)),
        changeFilterValue: (input, value, filters) => dispatch(getOrdersFilteredDataThunk(input, value, filters)),
        setClients: (withLoader) => dispatch(setClientsDataThunk(withLoader)),
        toggleChangeStatus: (id) => dispatch(toggleOrderChangeStatusAC(id)),
        setManagers: () => dispatch(getManagersDataThunk()),
        updateOrder: (order_id, data, status_update) => dispatch(updateOrderDataThunk(order_id, data, status_update)),
        createOrder: (client_id) => dispatch(createNewOrderThunk(client_id)),
        setNullFilter: (input, value) => dispatch(changeOrdersFilterFormValueAC(input, value)),
    }
}

let mapStateToProps = (state) => {
    return {
        orders_actual: state.main.orders.actual,
        orders_archive: state.main.orders.archive,
        filterForm: state.main.ordersForm,
        orderStatus: state.main.orderStatus,
        managers: state.main.managers,
        clients: state.main.clients,
        changedStatus: state.main.ordersChangeStatus,
        user: state.auth.user,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersProblemContainer);