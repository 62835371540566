import React, {useState} from 'react';
import useProductionCookies from "../../../hook/useProductionCookies";
import SettingsImg from "../../../image/Set.png";
import useClickOutside from "../../../hook/useClickOutside";

const SettingsCookies = ({getT}) => {
    const [settings, onChangeHandler] = useProductionCookies();
    const {isVisible, setVisible, ref} = useClickOutside();
    return (
        <div className={'production-cookies'} ref={ref}>
            <button className={'btn btn-sm btn-white pl-0 pr-0'} onClick={()=>setVisible(!isVisible)}><img src={SettingsImg} width={25}/></button>
            {isVisible && <div className=" position-absolute dwopdown">
                {settings?.map(item => {
                    return <div className="form-control d-flex justify-content-between align-items-center">
                        <label>{getT(item.title)}</label>
                        <input
                            onChange={onChangeHandler(item.id)}
                            type="checkbox"
                            id={`check_${item.id}`}
                            checked={item.check}

                        />
                    </div>
                })}

            </div>}

        </div>
    );
};

export default SettingsCookies;