import React from 'react';
import Login from "./Login";
import {connect} from "react-redux";
import ApiService from '../../api/api.js';
import {changeAuthFormValueAC, resetPasswordThunk, setUserAuthAC} from "../../store/reducers/AuthReducer";
import {changeLangApp} from "../../store/reducers/TranslationReducer";
import Header from "../Header/HeaderContainer";
import {gaEvents} from "../../gaEvents";
import AlertContainer from "../Alert/AlertContainer";
import Loader from "../Loader/Loader";

class LoginContainer extends React.Component {

    constructor(props) {
        super(props);
        this.getAuth = this.getAuth.bind(this);
    }

    apiService = new ApiService();

    getAuth() {
        this.apiService.getAuth(this.props.form.login, this.props.form.pass)
            .then(res => {
                if (res && res.role.length > 0) {
                    this.props.setUserAuth(res);
                    localStorage.setItem('i_furn_user_id', res.id);
                    localStorage.setItem('i_furn_user_firm', res.firm);
                    gaEvents.loginIn();
                } else {
                    this.props.changeAuthValue('isFailure', true);
                }
            })

    }

    render() {
        return <div className={"main_wrapper"}>
            <Login form={this.props.form} changeValue={this.props.changeAuthValue} getAuth={this.getAuth}
                   localLang={this.props.localLang}
                   onChangeLang={this.props.onChangeLang}
                   onResetPassword={this.props.onResetPassword}
            />
            <AlertContainer/>
            {this.props.isLoading && <Loader/> }
        </div>
    }
}

let mapStateToProps = (state) => {
    return {
        isAuth: state.auth.isAuth,
        user: state.auth.user,
        form: state.auth.authForm,
        localLang: state.translation.local,
        isLoading: state.main?.isLoading
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        getAuth: (order) => {
        },
        changeAuthValue: (input, value) => dispatch(changeAuthFormValueAC(input, value)),
        setUserAuth: (user) => dispatch(setUserAuthAC(user)),
        onChangeLang: (lang) => dispatch(changeLangApp(lang)),
        onResetPassword: (dt) => dispatch(resetPasswordThunk(dt))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);