import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getCurrency} from "../../../../store/selectors/main_selector";
import Modal from "../../../../components/Modals/MyModal/Modal";
import {getWSGoodsStockRetailOfFirmAddModal} from "../../../../store/selectors/wholesalary_selector";
import {wholesaleGoodsStockRetailOfFirmAddModalAC} from "../../../../store/actions/WholesaleActions";
import {useForm} from "react-hook-form";
import {LS_FIRM, LS_LANG} from "../../../../utils/constants";
import {addWSGoodsStockRetailThunk} from "../../../../store/thunks/wholesale/goods_stock_retail_of_firm_thunk";
import useGetMaterialById from "../../../../hook/useGetMaterialById";
import Loader from "../../../../components/Loader/Loader";
/**
 * Добавить товар в продажу и установки цен на свой товар для продажи в опт
 *
 * @returns  {jsx}  -
 */
const WSGoodsStockRetailAddModal = ({getT}) => {
    const dispatch = useDispatch();
    const currency = useSelector(getCurrency);
    const goods_stock_retail_modal = useSelector(getWSGoodsStockRetailOfFirmAddModal);
    const {data: material_data, isPending, error} = useGetMaterialById({
        goods_id: goods_stock_retail_modal?.goods?.id,
        firm: localStorage.getItem(LS_FIRM)
    })
    if (!goods_stock_retail_modal?.isOpen) return null;
    const handlerClose = (e) => {
        dispatch(wholesaleGoodsStockRetailOfFirmAddModalAC({isOpen: false, goods: null}))
    }
    const handlerSend = (data) => {
        dispatch(addWSGoodsStockRetailThunk([{...data, goods: goods_stock_retail_modal?.goods?.id}]))
    }
    return (<>
            <Modal open={goods_stock_retail_modal?.isOpen && !isPending} onClose={handlerClose}
                   title={<div style={{fontSize: "16px", fontWeight: "600"}}>{getT("Добавить в продажу")}:
                       [{goods_stock_retail_modal?.goods?.id}] {goods_stock_retail_modal?.goods?.name}</div>}>
                <GoodsAddForm getT={getT} currency={currency} handlerSend={handlerSend}
                              recommended_retail_price={material_data?.price}/>
            </Modal>
            {isPending && <Loader/>}
        </>
    );
};

const GoodsAddForm = ({getT, currency, handlerSend, recommended_retail_price}) => {
    const {register, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            retail_stock: 1,
            whole_sale_stock: 1,
            currency: 2,
            recommended_retail_price: recommended_retail_price ?? 1,
            default_delivery_days: 1,
            minimal_quantity: 1
        }, values: {}
    });

    const onSubmit = (data) => {
        handlerSend(data)
    };

    return (
        <div className="">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={"form-group row align-items-end"} style={{rowGap: "15px"}}>

                    {/* Остаток розничный */}
                    <div className="col-12 col-md-6">
                        <label htmlFor="retail_stock">{getT("Розничный остаток")}</label>
                        <input
                            id="retail_stock"
                            type="number"
                            step="0.01"
                            className="form-control"
                            required={true}
                            min={1}
                            {...register('retail_stock', {
                                setValueAs: (v) => Number(v)
                            })}
                        />
                        {errors.retail_stock && <span className="text-danger">{errors.retail_stock.message}</span>}
                    </div>

                    {/* Остаток оптовый */}
                    <div className="col-12 col-md-6">
                        <label htmlFor="whole_sale_stock">{getT("Оптовый остаток")}</label>
                        <input
                            id="whole_sale_stock"
                            type="number"
                            required={true}
                            step={1}
                            min={1}
                            className="form-control"
                            {...register('whole_sale_stock', {
                                setValueAs: (v) => Number(v)
                            })}
                        />
                        {errors.whole_sale_stock &&
                            <span className="text-danger">{errors.whole_sale_stock.message}</span>}
                    </div>

                    {/* Рекомендуемая розничная цена */}
                    <div className="col-12 col-md-6">
                        <label htmlFor="recommended_retail_price">{getT("Рекомендуемая розничная цена")}</label>
                        <input
                            id="recommended_retail_price"
                            type="number"
                            step={"0.01"}
                            min={1}
                            className="form-control"
                            required={true}

                            {...register('recommended_retail_price', {
                                setValueAs: (v) => Number(v)
                            })}
                        />
                        {errors.recommended_retail_price &&
                            <span className="text-danger">{errors.recommended_retail_price.message}</span>}
                    </div>

                    {/* Минимальное количество */}
                    <div className="col-12 col-md-6">
                        <label htmlFor="minimal_quantity">{getT("Минимальное количество для заказа")}</label>
                        <input
                            id="minimal_quantity"
                            type="number"
                            min={1}
                            step={1}
                            required={true}
                            className="form-control"
                            {...register('minimal_quantity', {
                                setValueAs: (v) => Number(v)
                            })}
                        />
                        {errors.minimal_quantity &&
                            <span className="text-danger">{errors.minimal_quantity.message}</span>}
                    </div>
                    {/* Валюта */}
                    <div className="col-12 col-md-6">
                        <label htmlFor="currency">{getT("Валюта")}</label>
                        <select id="currency" className="form-control"
                                required={true}
                                {...register('currency', {
                                    setValueAs: (v) => Number(v)
                                })}>
                            {currency?.map(e => {
                                return <option
                                    value={e?.currency_id}>{e?.name?.[localStorage.getItem(LS_LANG)]}</option>
                            })}
                        </select>
                        {errors.currency && <span className="text-danger">{errors.currency.message}</span>}
                    </div>

                    {/* Дней на доставку */}
                    <div className="col-12 col-md-6">
                        <label htmlFor="default_delivery_days">{getT("Срок доставки по умолчанию")}</label>
                        <input
                            id="default_delivery_days"
                            type="number"
                            required={true}
                            step={1}
                            min={1}
                            className="form-control"
                            {...register('default_delivery_days', {
                                setValueAs: (v) => Number(v)
                            })}
                        />
                        {errors.default_delivery_days &&
                            <span className="text-danger">{errors.default_delivery_days.message}</span>}
                    </div>

                </div>
                {/* Кнопка отправки */}
                <div className="d-flex mt-5">
                    <button type="submit" className="btn btn-primary mx-auto">{getT("Отправить")}</button>
                </div>
            </form>
        </div>
    );
}

export default WSGoodsStockRetailAddModal;