import React from 'react';

const SwitcherTab = ({getT, setTab, tab}) => {
    const onOpenExistTab = () =>{
        setTab(true)
}
const onOpenNeededTab = () =>{
    setTab(false)
}
return (
    <nav>
        <div className="nav nav-tabs justify-content-center">
            <button className={"nav-link " + (tab && "active")}
                    onClick={onOpenExistTab}>{getT("Существующие")}</button>
            <button className={"nav-link " + (!tab && "active")}
                    onClick={onOpenNeededTab}>{getT("Возможные")}</button>

        </div>
    </nav>
);
}
;

export default SwitcherTab;