import {
    MATERIAL_SEARCH_CHANGE_FILTER,
    MATERIAL_SEARCH_SET_DATA,
    MATERIAL_SEARCH_TOGGLE_OPEN_PAGE
} from "../actions/MaterialSearchActions";

const MATERIAL_TYPES = [
    {
        id: "part",
        name: "Плитный материал"
    },
    {
        id: "band",
        name: "Кромка"
    }, {
        id: "tovar",
        name: "Товар"
    }
]
export const MATERIAL_SELECTED_INITIAL_FILTER = {
    text: '',
    type: MATERIAL_TYPES?.[0]?.id ?? 'part',
    producer: [],
    folders: [],
    x: [], y: [], z: [],
    already_ws_started: 0,
}


const INITIAL_STATE ={
    material_types: MATERIAL_TYPES,
    filter_values: MATERIAL_SELECTED_INITIAL_FILTER,
    pagination: {from: 0, count: 0},
    filter_data: {
        type: MATERIAL_TYPES?.[0]?.id ?? 'part',
        producers: null,
        x: null, y: null, z: null,
        tree: null,
    },
    goods: null,
    isOpenPage: false
}


export const MaterialSearchReducer = (state = INITIAL_STATE, action)=>{
    switch (action.type){
        case MATERIAL_SEARCH_SET_DATA: {
            return {
                ...state,
                filter_data: action?.payload?.filter_data,
                pagination: action?.payload?.pagination,
                goods: action.payload.goods,
            }
        }
        case MATERIAL_SEARCH_TOGGLE_OPEN_PAGE: {
            return {
                ...state,
                isOpenPage: action.payload.isOpen
            }
        }
        case MATERIAL_SEARCH_CHANGE_FILTER: {
            return {
                ...state,
                filter_values: action.payload
            }
        }
        default: {
            return state
        }
    }
}