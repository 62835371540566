import React, {useCallback, useState} from 'react';
import GroupOrderTransfer from "../../Modals/GroupOrderTransfer";
import {useDispatch} from "react-redux";
import {groupOrdersTransfer} from "../../../store/reducers/MainReducer";

const GroupTransfer = ({data_group_transfer, getT, onChange, resetTransferOrder, forceOrders}) => {
    const [isOpen, setIsOpen] = useState(false);
    const onToggleModal = (value = false) => setIsOpen(value);
    const dispatch = useDispatch();
    const onChangeHandler = useCallback((value) => {
        let to_status = value;
        if (!to_status) return false
        let payload = {
            ...data_group_transfer,
            to_status
        }
        onToggleModal();
        forceOrders();
        dispatch(groupOrdersTransfer(payload)).then(() => {
            resetTransferOrder()
        }).catch((e) => resetTransferOrder())
    }, [dispatch, data_group_transfer]);
    if (!data_group_transfer?.ids?.length) return null;
    return (
        <>
            <button
                className=" btn btn-sm  btn-secondary"
                onClick={() => onToggleModal(true)}>{getT('Групповое перемещение')}</button>
            <GroupOrderTransfer current_status={data_group_transfer.from_status} onClose={() => onToggleModal()}
                                getT={getT} onChangeHandler={onChangeHandler} isOpen={isOpen}/>

        </>
    );
};

export default GroupTransfer;