import React from 'react';
import {v4 as uuid} from "uuid";
import {Link} from "react-router-dom";

const OrderItem = ({order, getT, selectMaterialCheck, materialCount}) => {
    return (
        <tr>
            <td>
                <input
                    type="checkbox"
                    name={order?.id}
                    checked={order?.isChecked}
                    onChange={(event) => {
                        selectMaterialCheck(
                            {id: order?.id, value: event.target.checked},

                        );
                    }}
                />
            </td>
            <td data-label={getT("Код 1С")}>{order?.code1c}</td>
            <td data-label={getT("ТЕХ ID")} > <span title={`${order?.id} [${order?.count_detail ?? 0}}]`} className={'cursor-pointer'}>{order?.id}</span></td>
            <td data-label={getT("Название")}>
                {(Number(order?.order_out_of_date) === 1 )
                    ? (
                    <div>
                        <span style={{color: "red"}}>{order?.name}<br/>{getT("Заказ просрочен")}</span>
                    </div>
                ) : (
                    <div>{order?.name}</div>
                )}
            </td>
            <td data-label={getT("Количество")}>{materialCount ? `${Number(materialCount.toFixed(2))} ${getT('м2')}` : ''}</td>
            <td data-label={getT("Дата подтверждения")}>
                {order?.confirmed_date}
            </td>
            <td data-label={getT("Дата производства")}>
                {order?.plan_of_production}
            </td>

            <td>
                <div className={ "production-task-action text-danger d-flex align-center mr-1 justify-content-center"}>
                    <Link to={"/orders/detail/" + order.id}
                        target={"_blank"}>
                        <span className={"d-flex align-center mr-1 production_task_item_open"}>
                            <i className="far fa-eye"> </i>{" "}<small>{getT("Просмотр")}</small>
                        </span>
                    </Link>
                </div>
            </td>
        </tr>
    );
};

export default OrderItem;