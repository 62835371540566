import {WHOLESALE_MATERIAL_TYPE_STATUS} from "../constants/whole_sale_constants";
import {LS_FIRM} from "./constants";

function getReverseStatus(type_operation) {
    let excluded = WHOLESALE_MATERIAL_TYPE_STATUS.excluded
    let included = WHOLESALE_MATERIAL_TYPE_STATUS.included
    return type_operation === excluded ? included : excluded
}


//Проверка на статус
export const getDisabledByMaterialTypeOrGoods = ({type_operation, material_type, goods = null}) => {
    let reverse_operation = getReverseStatus(type_operation)

    if (goods) {
        return !!Number(goods?.status[reverse_operation]) || !!Number(material_type?.status?.[reverse_operation])

    }


    // Проверяем статус текущего узла
    if (!!Number(material_type?.status[reverse_operation])) {
        return true;
    }

    if (material_type?.goods && material_type?.goods?.length && material_type?.goods?.some(goods => Number(goods?.status?.[reverse_operation]) === 1)) {
        return true
    }
    // Если у узла есть дети, проверяем их рекурсивно
    if (material_type.sub_materials && material_type.sub_materials.length > 0) {
        for (let child of material_type.sub_materials) {
            if (!findIsReverseStatusMaterialOrGoods(child, reverse_operation)) {
                return true;
            }
        }
    }
    return false
}


export const createActualData = ({
                                     value_checked,
                                     type_operation,
                                     current_material,
                                     goods = null,
                                     item_root,
                                 }) => {
    let reverse_operation = getReverseStatus(type_operation)
    let material_type = {...current_material}


    function updateStatus(node_, goods_id = null) {
        // Создаем копию узла
        let node = {...node_};

        // Обновляем статус товаров, если они есть
        if (node?.goods && node?.goods?.length) {
            node = {
                ...node,
                goods: node.goods.map(item => {
                    if (Number(item.goods_id) === Number(goods_id) && goods_id) {
                        return {
                            ...item,
                            is_checked_change: true,
                            is_delete_connection: Number(item.status[type_operation]) !== Number(value_checked),
                            status: {
                                ...item?.status,
                                [type_operation]: Number(value_checked)
                            },


                        }
                    }
                    if (Number(item.status?.[type_operation]) !== Number(value_checked) && !goods_id) {
                        return {
                            ...item,
                            is_checked_change: true,
                            is_delete_connection: Number(item.status[type_operation]) !== Number(value_checked),
                            status: {
                                ...item?.status,
                                [type_operation]: Number(value_checked)
                            }
                        };
                    }
                    return item;
                })
            };
        }

        // Рекурсивно обновляем статус дочерних материалов (sub_materials)
        if (node.sub_materials && node.sub_materials.length > 0 && !goods_id) {
            node = {
                ...node,
                sub_materials: node.sub_materials.map(child => updateStatus(child, goods_id))  // Сохраняем результат рекурсии
            };
        }

        let status_sub_material =
            node?.sub_materials?.length ? Number(node?.sub_materials?.every(e => Number(e?.status[type_operation]) === Number(value_checked))) : Number(value_checked);
        let status_goods = node?.goods?.length ? Number(node?.goods?.every(e => Number(e?.status[type_operation]) === Number(value_checked))) : Number(value_checked);
        let status = Number(status_sub_material) === Number(status_goods) ? Number(value_checked) : 0
        let is_not_has_children_disabled = node?.sub_materials?.length ? findIsReverseStatusMaterialOrGoods(node, reverse_operation) : true
        let is_change_status_material_type = Number(node?.status[type_operation]) !== Number(status) && is_not_has_children_disabled;

        // Обновляем статус текущего узла
        if (is_change_status_material_type) {
            node = {
                ...node,
                is_checked_change: true,
                status: {
                    ...node?.status,
                    [type_operation]: Number(status)
                }
            };
        }
        return node;
    }

    let is_not_has_children_disabled = findIsReverseStatusMaterialOrGoods(material_type, reverse_operation);
    // проверка на статусы всех вложенных материалов
    if (!is_not_has_children_disabled && !goods) {
        console.log('матириалы или товары имееют другой статус')
        return
    }

    // обновляем статусы
    let result_update = goods ? updateStatus(material_type, goods?.goods_id) : updateStatus(material_type)
    material_type = {...result_update};
    let update_item_root = updateDescendant(item_root, material_type?.material_type_id, material_type);

    return updateParentStatus({
        node: {...material_type},
        type_operation,
        reverse_operation,
        root: update_item_root,
        value_checked
    });
}


function updateParentStatus({node, root, value_checked, type_operation, reverse_operation}) {
    // let node = {..._node}

    const parent = findParent(root, node);
    if (!parent) return node;
    // проверка на статусы в под материалах
    const is_same_status_sub_material = parent.sub_materials.every(child =>
        Number(child.status[type_operation]) === Number(value_checked)
        && findIsReverseStatusMaterialOrGoods(child, reverse_operation)
    );
    // если статусы одинаковы, устанавливаем такой же иначе выключаем
    let status = is_same_status_sub_material ? Number(value_checked) : 0;
    let is_checked_change = Number(status) !== Number(parent.status[type_operation]) && (parent.status[reverse_operation] === 0)

    if (is_checked_change) {
        parent.status[type_operation] = status;
        parent['is_checked_change'] = is_checked_change
    }
    return  updateParentStatus({node: parent, root, value_checked, type_operation, reverse_operation});

    // return parent
}

export const findIsReverseStatusMaterialOrGoods = (node, reverse_status) => {
    if (!node) {
        return false
    }
    // Проверяем статус текущего узла
    if (!!Number(node?.status[reverse_status])) {
        return false;
    }

    if (node?.goods && node?.goods?.length && node?.goods?.some(goods => !!Number(goods?.status?.[reverse_status]))) {
        return false
    }
    // Если у узла есть дети, проверяем их рекурсивно
    if (node.sub_materials && node.sub_materials.length > 0) {
        for (let child of node.sub_materials) {
            if (!findIsReverseStatusMaterialOrGoods(child, reverse_status)) {
                return false;
            }
        }
    }

    // Если текущий узел и все его дети удовлетворяют условию
    return true;
}

// найти и обновить дерево
function updateDescendant(node, material_type_id, updatedNode) {
    // Если текущий узел — это тот, который мы хотим заменить, возвращаем обновленный узел
    if (node.material_type_id === material_type_id) {
        return {...updatedNode};
    }

    // Если у узла есть дети, проходимся по каждому и обновляем их рекурсивно
    if (node.sub_materials && node.sub_materials.length > 0) {
        node = {
            ...node,
            sub_materials: node.sub_materials.map(child => updateDescendant(child, material_type_id, updatedNode))
        };
    }

    // Возвращаем узел (возможно, с обновленными дочерними элементами)
    return {...node};
}


function findParent(node, child) {
    // Если нашли родителя по parent_folder (или parent_id)
    if (node.material_type_id === child.folder_parent) {
        return node
    }

    // Если есть подматериалы, рекурсивно ищем среди них
    if (node.sub_materials) {
        for (const sub of node.sub_materials) {
            const found = findParent(sub, child);
            if (found) return found
        }
    }
    return null;
}


export const createDataForSendingChangedStatusesOfMaterialsAndGoods = ({
                                                                           firm_main,
                                                                           root_tree,
                                                                           type_operation,
                                                                           exceptions_connect_list
                                                                       }) => {
    try {
        let delete_connect = [];
        let create_connect = [];
        const ACTION = type_operation === WHOLESALE_MATERIAL_TYPE_STATUS.included ? "include" : "exclude";
        const TYPE_ACTION = {
            material: "material",
            material_type: "material_type"
        }

        function traverseAndSeparate(node, new_connect = [], remove_connect = []) {

            // Проверяем статус текущего узла и добавляем его в соответствующий массив
            let isChange = node?.is_checked_change;
            let find_node_existing_relationship = exceptions_connect_list?.find(e => {
                return Number(e?.material_type) === Number(node?.material_type_id) && (ACTION.trim() === e.action?.trim())
            })


            if (isChange) {
                if (find_node_existing_relationship) {
                    remove_connect.push({
                        action: find_node_existing_relationship.action,
                        // id: node?.material_type_id,
                        id: find_node_existing_relationship.id,
                        type: find_node_existing_relationship.type,
                        firm_main: firm_main,
                        firm_partner: localStorage.getItem(LS_FIRM)
                    })
                } else {
                    new_connect.push({
                        action: ACTION,
                        type: TYPE_ACTION.material_type,
                        id: node?.material_type_id,
                        firm_main: firm_main,
                        firm_partner: localStorage.getItem(LS_FIRM)
                    })
                }
            }

            if (node?.goods && node?.goods?.length) {
                for (let goods of node.goods) {
                    let isChangeGoods = goods?.is_checked_change;
                    if (isChangeGoods) {
                        let find_existing_relationship = exceptions_connect_list?.find(e => {
                            return Number(e?.goods) === Number(goods?.goods_id) && (ACTION.trim() === e.action?.trim())
                        })
                        if (find_existing_relationship) {
                            remove_connect.push({
                                action: find_existing_relationship.action,
                                id: find_existing_relationship.id,
                                type: find_existing_relationship.type,
                                firm_main: firm_main,
                                firm_partner: localStorage.getItem(LS_FIRM)
                            })
                        } else {
                            new_connect.push({
                                action: ACTION,
                                type: TYPE_ACTION.material,
                                id: goods?.goods_id,
                                firm_main: firm_main,
                                firm_partner: localStorage.getItem(LS_FIRM)
                            })
                        }
                    }
                }
            }

            // Если у узла есть дети, рекурсивно обходим их
            if (node.sub_materials && node.sub_materials.length > 0) {
                for (let child of node.sub_materials) {
                    traverseAndSeparate(child, new_connect, remove_connect);
                }
            }

            // Возвращаем массивы с узлами
            return {new_connect, remove_connect};
        }

        return traverseAndSeparate(root_tree, create_connect, delete_connect)

    } catch (e) {
        console.log(e.message)
    }
}

