import React from 'react';
import {useSelector} from "react-redux";
import {getWorkerData} from "../../store/selectors/auth_selector";
import PageTitle from "../../components/PageTitle/PageTitle";
import ContainerContent from "../../components/ui/ContainerContent";

const EmployeePersonalDataPage = ({getT}) => {
    const worker_state = useSelector(getWorkerData);
    return (
        <ContainerContent>
            <PageTitle title={"personal_worker_data"}/>
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <ul>
                                    <li><span
                                        className="text-dark font-weight-bold">{getT("Имя")}:</span> {worker_state?.name}
                                    </li>
                                    <li><span
                                        className="text-dark font-weight-bold">{getT("Телефон")}: </span>{worker_state?.phone}
                                    </li>
                                    <li><span
                                        className="text-dark font-weight-bold">{getT("Код")} 1с: </span>{worker_state?.code1c}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContainerContent>
    );
};

export default EmployeePersonalDataPage;