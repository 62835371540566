import React, {useState} from 'react';

const OrderGoods = ({order_goods, getT}) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className={'my-2  px-0 shadow-none  '}>
            <div className="card-header border-0 px-0 py-0" onClick={()=>setIsOpen(!isOpen)}>
                <strong className="">{getT("Материалы")}</strong>
                <i className={`ml-2 fas fa-angle-${isOpen ? 'up' : 'down'}`}></i>
            </div>
            {isOpen &&  <ul className=" mt-2 pl-4">
                {order_goods?.map(goods => {
                    return <li className="  ">[{goods?.goods_id}] {goods?.name} {" "} ({goods?.x || goods?.x1}x{goods?.y}x{goods?.z})</li>
                })}
            </ul>}
        </div>
    );
};

export default OrderGoods;