import React from 'react';
import {connect} from "react-redux";
import Clients from "./Clients";
import './Clients.css';
import {changeClientPasswordThunk, setClientsDataThunk} from "../../store/reducers/MainReducer";

class ClientsContainer extends React.Component {

    componentDidMount() {
        this.props.setClients();
    }
    componentWillUnmount(){
        // this.props.checkAuntificationUser()
    }

    render() {
        return <Clients clients={this.props.clients} updateClient={this.props.updateClient}/>;
    }
}

let mapDispatchToProps = (dispatch) => {
    return {
        setClients: () => dispatch(setClientsDataThunk()),
        updateClient: (id, data) => dispatch(changeClientPasswordThunk(id, data))
    }
}

let mapStateToProps = (state) => {
    return {
        clients: state.main.clients
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientsContainer);