import React from 'react';
import {useSelector} from "react-redux";

const ListCuttingCut = ({cards, materials, onChangeOptionCard, getT, isAll = true, stateField}) => {
    const lang = useSelector(state => state.translation.local) || localStorage.getItem('lang')
    if (!cards || !Array.isArray(cards)) return false

    return (
        <div>
            {cards.map((card, ind) => {
                const material = materials.filter(m => Number(m.goods_id) === Number(card.goods_id))[0] || false;
                const fieldValue = stateField.filter(s => Number(s.card_id) === Number(card.card_id))[0] || false
                if (material && fieldValue) {
                    const {translate, name} = material;
                    return <div key={ind} className={'list'}>
                        <div className="item level_2">
                            <div className="checkbox-label__input">
                                <input
                                    id={'cut_card' + card.card_id}
                                    checked={Boolean(fieldValue.card)}
                                    type="checkbox"
                                    value={Boolean(fieldValue.card)}
                                    onChange={(e) => {
                                        onChangeOptionCard({
                                            key: 'card',
                                            value: Number(e.target.checked),
                                            cardId: card.card_id
                                        })
                                    }
                                    }/>
                                <label htmlFor={'cut_card' + card.card_id} className={' '}>
                                    {getT('Карта')} № {card.card_id} <br/>
                                    {translate?.hasOwnProperty(lang) ? translate[lang] : name}
                                    <br/>
                                    {getT('количество листов в карте')} {card.count}
                                </label>
                            </div>
                        </div>
                        <div className="item level_3">
                            <div className="checkbox-label__input">

                                <input checked={Boolean(fieldValue.parts_info)}
                                       value={Boolean(fieldValue.parts_info)}
                                       type="checkbox"
                                       id={'parts_info' + card.card_id}
                                       onChange={(e) => {
                                           onChangeOptionCard({
                                               key: 'parts_info',
                                               value: Number(e.target.checked),
                                               cardId: card.card_id
                                           })
                                       }
                                       }/>

                                <label htmlFor={'parts_info' + card.card_id} className={''}>

                                    {getT('Расшифровка деталей')}
                                </label>
                            </div>
                        </div>

                    </div>
                }
            })}
        </div>
    );
};

export default ListCuttingCut;