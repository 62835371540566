import {useCallback, useState} from "react";

export default function (){
    const [ids_orders, setIdsOrders] = useState([]);
    const [isManualMode, setIsManualMode] = useState(true); // краткий режим true

    const onToggleIdOrders = useCallback( (id)=>{
        const find_id = ids_orders.includes(Number(id));

        if(find_id){
            let update = ids_orders.filter(current => Number(current) !== Number(id));
            setIdsOrders(update);
            return
        }
        setIdsOrders((prev)=> [...prev, Number(id)]);
    }, [setIdsOrders, ids_orders]);

    const onToggleDisplayAll =useCallback((isCheck)=>{
       setIsManualMode(isCheck)
        if(isCheck){
            setIdsOrders([])
        }
    }, [setIdsOrders, ids_orders, setIsManualMode]);

    return {
        ids_orders, onToggleIdOrders, isManualMode, onToggleDisplayAll
    }
}