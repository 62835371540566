


export const getStatus = ({status, statusEntry})=>{
    let findStatus = statusEntry?.find(item => (item?.name === status) || (Number(item.id) === Number(status)));

    return findStatus ? findStatus : {
        color: "danger", name: "Не указано"
    }
}
export  const availableStatus = ({userRoleEntry, statusRoles})=>{
    return !!userRoleEntry?.find(item => statusRoles?.includes(Number(item?.id)))
}
