import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    ACProjectProductionSetMaterialOrders,
    fetchingProjectProductionAllMaterials,
    fetchingProjectProductionByIdMaterials
} from "../../../../store/reducers/MainReducer";
import {getAlProjectProductionMaterials} from "../selectors";
import {isShallowEqual, isCheckObject} from "../../../../utils/helpers";

const ProjectOrderHeader = ({getT, productionModalHandler}) => {
    const dispatch = useDispatch();
    const materialsAll = useSelector(getAlProjectProductionMaterials, (prev, next) => isShallowEqual(prev, next))
    const fetchMaterialsAll = () => dispatch(fetchingProjectProductionAllMaterials());
    const onChangeMaterialById = (e) =>{
        e.preventDefault();
        let id = e.target.value;
        dispatch(fetchingProjectProductionByIdMaterials({id}))
    }
    useEffect(() => {
        dispatch(ACProjectProductionSetMaterialOrders([]))
        fetchMaterialsAll();
    }, [dispatch]);

    return (
        <div className={'row'}>
            <div className="col-sm-6">
            {isCheckObject(materialsAll) ?
                <>
                    <select name="" id="material_production" className="form-control" onChange={onChangeMaterialById}>
                        <option value={''}>{getT('Выберите материал')}</option>
                        {Object.keys(materialsAll)?.sort()?.map(function (key, index) {
                            return <option value={key}>{`[${key}]`} {materialsAll[key]}</option>
                        })}
                    </select>
                    <button  style={{
                        marginTop: "20px",
                        width: "200px",
                        height: "40px"
                    }} className="open-modal" onClick={()=> productionModalHandler(true)}>  {getT("В производство")}</button>
                </>

                : getT('')}
            </div>

        </div>
    );
};

export default ProjectOrderHeader;