import React from "react";
import {Link} from "react-router-dom";
import {withLang} from "../../hoc/withLang";
import {HELP_ARTICLE_CONSTANTS_IDS} from "../../constants/help_article_constants";
import {helpArticleGetByIdToggleModalThunk} from "../../store/thunks/help-article-thunk";
import {useDispatch} from "react-redux";
import IconQuestion from "../../Icons/IconQuestion";

const PageTitle = (props) => {
    const dispatch = useDispatch();
    const handlerHelpArticle = (id) => (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(helpArticleGetByIdToggleModalThunk(id))
    }
    const getTree = (page, isTitle = false, help_article = false, is_prev_page = false) => {
        const tree = {
            dashboard: {
                name: props.getT("Главная"),
                link: "/dashboard"
            },
            orders_actual: {
                name: props.getT("Актуальные заказы"),
                link: "/orders/actual"
            },
            orders_search: {
                name: props.getT("Поиск заказов"),
                link: "/orders/search"
            },
            orders_archive: {
                name: props.getT("Архивные заказы"),
                link: "/orders/archive"
            },
            orders_problems: {
                name: props.getT("Проблемные заказы"),
                link: "/orders/problem"
            },
            orders_canceled: {
                name: props.getT("Отмененные заказы"),
                link: "/orders/canceled"
            },
            orders_shipped: {
                name: props.getT("Отгруженные заказы"),
                link: "/orders/shipped"
            },
            orders_outstanding: {
                name: props.getT("Продажа товаров"),
                link: "/orders/outstanding"
            },
            orders_production: {
                name: props.getT("Заказы в производстве"),
                link: "/orders/production"
            },
            orders_detail: {
                name: props.getT("Информация о заказе"),
                link: "/orders/detail",
                parent: ["orders_actual"]
            },
            order_add: {
                name: props.getT("Добавление нового заказа"),
                link: "/orders/add",
                parent: ["orders_actual"]
            },
            clients: {
                name: props.getT("Клиенты"),
                link: "/clients"
            },
            clients_detail: {
                name: props.getT("Информация о заказе"),
                link: "/clients/detail",
                parent: ["clients"]
            },
            reports_sale: {
                name: props.getT("Отчет о продажах"),
                link: "/reports/sales"
                // parent: ['clients']
            },
            reports_clients: {
                name: props.getT("Отчет по клиентам"),
                link: "/reports/clients"
                // parent: ['clients']
            },
            reports_totals: {
                name: props.getT("Сводный отчет"),
                link: "/reports/totals"
                // parent: ['clients']
            },
            reports_status: {
                name: props.getT("Статусы"),
                link: "/reports/totals"
                // parent: ['clients']
            },
            reports_leftMake: {
                name: props.getT("Осталось доделать"),
                link: "/reports/leftMake"
                // parent: ['clients']
            },

            clients_add: {
                name: props.getT("Добавление нового клиента"),
                link: "/clients/add"
                // parent: ["clients"]
            },
            archive: {
                name: props.getT("Архив заказов"),
                link: "/archive"
            },
            default_plane: {
                name: props.getT("План по умолчанию"),
                link: '/salary-project/default-plane',
                help_article_id: HELP_ARTICLE_CONSTANTS_IDS.salary_default_plane
            },
            salary_rates: {
                name: props.getT("Тарифы на оплату услуг"),
                link: '/salary-project/rates',
                help_article_id: HELP_ARTICLE_CONSTANTS_IDS.rates
            },
            shift: {
                name: props.getT("Смены"),
                link: '/salary-project/shift',
                help_article_id: HELP_ARTICLE_CONSTANTS_IDS.shifts
            },
            ready_mark: {
                name: props.getT("Отметка готовности"),
                link: '/salary-project/ready-mark',
                help_article_id: HELP_ARTICLE_CONSTANTS_IDS.ready_mark
            },
            scanner_ready_mark: {
                name: props.getT("Сканирование"),
                link: '/salary-project/scanner-ready-mark',
                help_article_id: HELP_ARTICLE_CONSTANTS_IDS.scanning
            },
            readiness_report: {
                name: props.getT("Отчёт готовности"),
                link: '/salary-project/readiness-report',
                help_article_id: HELP_ARTICLE_CONSTANTS_IDS.readiness_report
            },
            personal_worker_data: {
                name: props.getT("Личные данные"),
                link: '/salary-project/personal-data'
            }, salary_report: {
                name: props.getT("Отчёт по зарплате"),
                link: '/salary-project/salary-report',
                help_article_id: HELP_ARTICLE_CONSTANTS_IDS.salary_report
            },
            search_orders: {
                name: props.getT("Заказы"),
                link: '/salary-project/orders',
                help_article_id: HELP_ARTICLE_CONSTANTS_IDS.orders
            },
            statistic_xnc_operations: {
                name: props.getT("Статистика"),
                link: '/statistic/xnc-operations',
                help_article_id: null
            },
            statistic_xnc_operations_materials: {
                name: props.getT("Статистика материалов"),
                link: '/statistic/xnc-operations-materials',
                help_article_id: null
            },
            material_band_connected: {
                name: props.getT("Материал <-> Кромка"),
                link: '/material_band_connected',
                help_article_id: null
            },
            ws_connection_client: {
                name: props.getT("Заявка на подключение"),
                link: '/ws-company-connection-request',
                help_article_id: null
            },
            ws_companies: {
                name: props.getT("Оптовые фирмы"),
                link: '/ws-companies',
                help_article_id: null
            },
            operation_product_group_firm: {
                name: props.getT("Материалы/товары "),
                link: '/ws-products-company',
                help_article_id: null,
                prev_page: "ws_companies",
                parent: ["ws_companies"]
            },
            ws_goods_stock_retail_of_firm: {
                name: props.getT("Мои остатки товаров"),
                link: '/ws-goods-stock-retail',
                help_article_id: null,
            },
            ws_product_needs: {
                name: props.getT("Потребности"),
                link: '/ws-product-needs',
                help_article_id: null,
            },
            ws_orders_supply: {
                name: props.getT("Заказы"),
                link: '/ws-orders-supply',
                help_article_id: null,
            },
            settings_company: {
                name: props.getT("Настройки фирмы"),
                link: '/settings-company',
                help_article_id: null,
            },


        };
        if (help_article) {
            let is_has_current_page = tree[page].hasOwnProperty('help_article_id') && tree?.[page]?.help_article_id;
            if (is_has_current_page) {
                return <IconQuestion style={{top: '0px'}}
                                     onClick={handlerHelpArticle(tree?.[page]?.help_article_id)}
                />
            }
            return null
        }
        if (is_prev_page) {
            if (tree?.[page]?.prev_page && !!tree?.[page]?.prev_page) {
                let key_prev_page = tree?.[page]?.prev_page
                let prev_page = tree?.[key_prev_page];
                if (prev_page) {
                    return <Link to={prev_page?.link} className={"prev_link mr-2 d-flex align-items-center text-md"}
                                 style={{gap: '5px'}}><i className="fa fa-angle-left"></i> {props.getT(prev_page?.name)}
                    </Link>
                }
            }
            return null
        }
        if (isTitle) {
            return tree[page].name;
        }
        let breadcrumps =
            tree[page] && tree[page].parent
                ? tree[page].parent.map((e, idx) => {
                    return (
                        <li className="breadcrumb-item">
                            <Link to={tree[e].link}>{tree[e].name}</Link>
                        </li>
                    );
                })
                : [];

        return [
            ...breadcrumps,
            <li className="breadcrumb-item active">{tree[page].name}</li>
        ];
    };


    return (
        <div className="content-header" key={`page_title_${props?.title}`}>
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-sm-8 col-md-6">
                        {getTree(props.title, false, false, true)}
                        <h1 className="m-0 d-flex align-items-center">

                            {getTree(props.title, true)} {getTree(props.title, false, true)}

                            {props?.addintional_subtitle ? props.getT(props.addintional_subtitle) : null}
                        </h1>
                    </div>
                    <div className="col-sm-4 col-md-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item">
                                <Link to={"/dashboard"}>{props.getT("Главная")}</Link>
                            </li>
                            {getTree(props.title)}
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withLang(PageTitle);
