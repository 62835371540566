import React, {useEffect, useRef, useState} from 'react';
import {DateRange} from "react-date-range";
import * as rdrLocales from "react-date-range/dist/locale";

const DateField = ({getT, valueInput,  lang, saveData}) => {
    const [openDatePicker, toggleOpenDatePicker] = useState(false);
    const [date, onChangeDate] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection"
        }
    ]);
    const ref = useRef(null)
    useEffect(()=>{
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
               toggleOpenDatePicker(false)
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);}
    }, [ref])
    return (
        <div ref={ref}>
            <input
                className="form-control"
                type="text"
                value={valueInput}
                onClick={() => toggleOpenDatePicker(!openDatePicker)}
            />

            {openDatePicker && (
                <div style={{zIndex: 20, position: "absolute"}}>
                    <DateRange
                        locale={rdrLocales[lang]}
                        editableDateInputs={true}
                        onChange={(item) => onChangeDate([item.selection])}
                        moveRangeOnFirstSelection={false}
                        ranges={date}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background:'#fff',
                            padding:'12px',
                            borderTop:'1px solid #f2f2f2',
                            cursor:'pointer',
                        }}
                        className="btn-container"
                    onClick={()=> saveData(date)}
                    >
                        <i  style={{  color: 'green', fontSize: '14px'}}


                           className="fas fa-check-circle"
                        >
                            {getT("Применить")}
                        </i>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateField;