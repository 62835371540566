import React, {useEffect, useState} from "react";
import PageTitle from "../PageTitle/PageTitle";
import { OrderItem } from "./Orders";
import Select from "react-select";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as rdrLocales from "react-date-range/dist/locale";
import { v4 as uuid } from "uuid";
import {withLang} from "../../hoc/withLang";
import {useSelector} from "react-redux";
import {gaEvents} from "../../gaEvents";

export function formatDate(date) {
  if (!date) {
    return;
  }

  return [
    date.getFullYear(),
    String(date.getMonth() + 1).padStart(2, "0"),
    String(date.getDate()).padStart(2, "0"),
    String(date.getHours()).padStart(2, "0"),
    String(date.getMinutes()).padStart(2, "0"),
    String(date.getSeconds()).padStart(2, "0")
  ]
    .join("-")
    .slice(0, 10);
}

const OrdersOutstanding = (props) => {
  const lang = useSelector(state => state.translation.local);
  const [visibleClearDate, setVisibleClearDate] = useState(false);
  const [visibleClearDate_production, setVisibleClearDate_production] =
    useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [openDate_productionPicker, setOpenDate_productionPicker] =
    useState(false);

  const toggleOpenDatePicker = () => {
    setOpenDatePicker(!openDatePicker);
  };

  const toggleOpenDate_productionPicker = () => {
    setOpenDate_productionPicker(!openDate_productionPicker);
  };

  const [date, setDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  const [date_production, setDate_production] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ]);

  const saveData = () => {
    props.applyFiltersAndFetch(
      "date",
      {
        from: formatDate(date[0].startDate),
        to: formatDate(date[0].endDate)
      },
      props.form
    );
    setOpenDatePicker(false);
    setVisibleClearDate(true);
  };

  const clearData = () => {
    props.applyFiltersAndFetch(
      "date",
      {
        from: "",
        to: ""
      },
      props.form
    );
    setVisibleClearDate(false);
  };

  const saveData_production = () => {
    props.applyFiltersAndFetch(
      "date_production",
      {
        from: formatDate(date_production[0].startDate),
        to: formatDate(date_production[0].endDate)
      },
      props.form
    );
    setVisibleClearDate_production(true);
    setOpenDate_productionPicker(false);
  };

  const clearData_production = () => {
    props.applyFiltersAndFetch(
      "date_production",
      {
        from: "",
        to: ""
      },
      props.form
    );
    setVisibleClearDate_production(false);
  };
  const onResetForms = ()=>{
    props.onClear({firm: localStorage.getItem('i_furn_user_firm'),
      archive: 0,
      status: 1111,
      user: '',
      limit: 1})
  }
  const renderOrders = () => {
    if (props.orders.length > 0) {
      return props.orders.map((e) => {
        return (
          <OrderItem
            order={e}
            toggleChangeStatus={props.toggleChangeStatus}
            updateOrder={props.updateOrder}
            status={props.status}
            changedStatus={props.changedStatus}
            withStatus={true}
            withClient={true}
            roles={props.userRoles}
            test={1}
            getT={props.getT}
            clients={props.clients}
          />
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={9}>
            <p className={"text-center"}>{props.getT("Заказы еще не созданы")}</p>
          </td>
        </tr>
      );
    }
  };
  return (
    <div className="content-wrapper">
      <PageTitle title={props.title} />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <form>
              <div className="card-body">
                <div className="row">
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>{props.getT("Код заказа")}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={props.form.code}
                        onChange={(event) => {
                          props.applyFiltersAndFetch(
                            "code",
                            event.target.value,
                            props.form
                          );
                        }}
                        onFocus={()=>{
                          gaEvents.customEvent('form_order_outstanding', {
                            field_focus: 'Код заказа'
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>{props.getT("ID заказа")}:</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder=""
                        value={props.form.id}
                        onChange={(event) => {
                          props.applyFiltersAndFetch(
                            "id",
                            event.target.value,
                            props.form
                          );
                        }}
                        onFocus={()=>{
                          gaEvents.customEvent('form_order_outstanding', {
                            field_focus: 'ID заказа'
                          })
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>{props.getT("Название заказа")}:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        value={props.form.name}
                        onChange={(event) => {
                          props.applyFiltersAndFetch(
                            "name",
                            event.target.value,
                            props.form
                          );
                        }}
                        onFocus={()=>{
                          gaEvents.customEvent('form_order_outstanding', {
                            field_focus: 'Название заказа'
                          })
                        }}
                      />
                    </div>
                  </div>
                  {/* Формат даты по промежутку */}
                  <div className="col-sm-3">
                    <div className="form-group">
                      {visibleClearDate && (
                        <i
                          style={{ marginRight: "10px" }}
                          onClick={clearData}
                          class="fas fa-ban "
                        >
                          {props.getT("Сбросить")}
                        </i>
                      )}
                      <label>{props.getT("Дата")}: </label>
                      <input
                        className="form-control"
                        type="text"
                        value={`${props.form?.date?.from || ""} ... ${
                          props.form?.date?.to || ""
                        }`}
                        onClick={toggleOpenDatePicker}
                        onFocus={()=>{
                          gaEvents.customEvent('form_order_outstanding', {
                            field_focus: 'Дата'
                          })
                        }}
                      />

                      {openDatePicker && (
                        <div style={{ zIndex: 20, position: "absolute" }}>
                          <DateRange
                            locale={rdrLocales[lang]}
                            editableDateInputs={true}
                            onChange={(item) => setDate([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={date}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            className="btn-container"
                          >
                            <i
                              i
                              style={{ cursor: "pointer" }}
                              onClick={saveData}
                              class="fas fa-check-circle"
                            >
                              {props.getT("Применить")}
                            </i>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* Конец Формат даты по промежутку */}
                  {/* <div className="col-sm-2">
                    <div className="form-group">
                      <label>Дата:</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder=""
                        value={props.form.date}
                        onChange={(event) =>
                          props.applyFiltersAndFetch(
                            "date",
                            event.target.value,
                            props.form
                          )
                        }
                      />
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  {/* <div className="col-sm-2">
                    <div className="form-group">
                      <label>Дата готовности:</label>
                      <input
                        type="date"
                        className="form-control"
                        placeholder=""
                        value={props.form.date_production}
                        onChange={(event) =>
                          props.applyFiltersAndFetch(
                            "date_production",
                            event.target.value,
                            props.form
                          )
                        }
                      />
                    </div>
                  </div> */}
                  {/* Формат даты_готовности по промежутку */}
                  <div className="col-sm-3">
                    <div className="form-group">
                      {visibleClearDate_production && (
                        <i
                          style={{ marginRight: "10px", cursor: "pointer" }}
                          onClick={clearData_production}
                          class="fas fa-ban "
                        >
                          {props.getT("Сбросить")}
                        </i>
                      )}
                      <label>{props.getT("Дата производства")}: </label>
                      <input
                        className="form-control"
                        type="text"
                        value={`${
                          props.form?.date_production?.from || ""
                        } ... ${props.form?.date_production?.to || ""}`}
                        onClick={toggleOpenDate_productionPicker}
                        onFocus={()=>{
                          gaEvents.customEvent('form_order_outstanding', {
                            field_focus: 'Дата производства'
                          })
                        }}
                      ></input>
                      {openDate_productionPicker && (
                        <div style={{ zIndex: 20, position: "absolute" }}>
                          <DateRange
                            locale={rdrLocales[lang]}
                            // locale={registerLocale("ru")}
                            editableDateInputs={true}
                            onChange={(item) =>
                              setDate_production([item.selection])
                            }
                            moveRangeOnFirstSelection={false}
                            ranges={date_production}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            className="btn-container"
                          >
                            <i
                              style={{ cursor: "pointer" }}
                              onClick={saveData_production}
                              class="fas fa-check-circle"
                            >
                              {props.getT("Применить")}
                            </i>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Конец Формат даты_готовности по промежутку */}

                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>{props.getT("Менеджер")}</label>
                      <select
                        className="form-control"
                        value={props.form.manager}
                        defaultValue={props.form.manager}
                        onChange={(event) => {
                          gaEvents.customEvent('form_order_outstanding', {
                            field_focus: 'Менеджер'
                          })
                          props.applyFiltersAndFetch(
                            "manager",
                            event.target.value,
                            props.form
                          );
                        }}
                      >
                        <option value={""}>{props.getT("Все")}</option>
                        {props.managers.map((e) => (
                          <option key={e.id} value={e.id}>
                            {e.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="col-sm-3">
                                    <span className="btn btn-danger"
                                          onClick={()=>{
                                            onResetForms()
                                          }}
                                    >
                                      {props.getT("Очистить")}
                                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table m-0 table-striped table-hover  orders-table-main">
                    <thead>
                      <tr>
                        <th>{props.getT("Код заказа")}</th>
                        <th>{props.getT("Тех. ID")}</th>
                        <th>{props.getT("Название")}</th>
                        <th>{props.getT("Клиент")}</th>
                        <th>{props.getT("Менеджер")}</th>
                        {/*<th>Клиент <sup><small>(в заказе)</small></sup></th>*/}
                        <th>{props.getT("Статус")}</th>
                        <th>{props.getT("Дата изменения")}</th>
                        <th>{props.getT("Дата производства")}</th>
                        <th>{props.getT("Действия")}</th>
                      </tr>
                    </thead>

                    <tbody>{renderOrders()}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default withLang(OrdersOutstanding);
