import React, {useEffect, useMemo, useState} from 'react';
import ContainerContent from "../../components/ui/ContainerContent";
import PageTitle from "../../components/PageTitle/PageTitle";
import {useDispatch, useSelector} from "react-redux";
import {
    getStaticsXncOperationsMaterialBandConnection, getStaticsXncOperationsMaterialBandConnectionModal,
    getStatisticsIsLoading
} from "../../store/selectors/statistics_xnc_operations_selector";
import {
    createStatisticXncOperationsMaterialBandConnectThunk, deleteStatisticXncOperationsMaterialBandConnectThunk,
    getStatisticXncOperationsMaterialBandConnectThunk
} from "../../store/thunks/statistic/statistic_xnc_operations_thunk";
import useMonthAndYearDate from "../../hook/useMonthAndYearDate";
import Loader from "../../components/Loader/Loader";
import TableMaterialBand from "./components/TableMaterialBand";
import FormFilterMaterialBand from "./components/FormFilterMaterialBand";
import Button from "../../components/ui/Button";
import {
    statisticXncOperationMaterialBandConnectionCreateModalAC
} from "../../store/actions/StatisticXncOperationsAction";
import StatisticsXncOperationsMaterialBandConnectedCreateModal
    from "../../components/Modals/StatisticsXncOperationsMaterialBandConnectedCreateModal";
const INITIAL_FORM_FILTER_MATERIAL_BAND = {
    material: {value: null, label: null},
    band: {value: null, label: null}
}
const StatisticsInteractiveFormPage = ({getT}) => {
    const dispatch = useDispatch();
    const modal_create_state = useSelector(getStaticsXncOperationsMaterialBandConnectionModal);
    const material_band_connected_store = useSelector(getStaticsXncOperationsMaterialBandConnection)
    const isLoading = useSelector(getStatisticsIsLoading);
    const [date_filed, handlerChangeDateField, parsForParamsData] = useMonthAndYearDate();
    const [is_type_connected_nav, setTypeConnectedNav] = useState(true);
    const [form_filter_material_band, setFormFilterMaterialBand] = useState(INITIAL_FORM_FILTER_MATERIAL_BAND)


    useEffect(() => {
        let params = parsForParamsData()
        dispatch(getStatisticXncOperationsMaterialBandConnectThunk(params))
    }, [date_filed]);


    const handlerSetConnected = (item) => e => {
        let params = parsForParamsData()
        dispatch(createStatisticXncOperationsMaterialBandConnectThunk(item, params))
    }
    const handlerDeleteConnected = (item) => e => {
        let params = parsForParamsData()
        dispatch(deleteStatisticXncOperationsMaterialBandConnectThunk(item, params))
    }
    const material_band_connected_data = useMemo(() => {
        let key = is_type_connected_nav ? 'connected' : 'unconnected';

        return material_band_connected_store?.[key]?.filter(item => {
            if (!form_filter_material_band?.material?.value && !form_filter_material_band?.band?.value) return true;
            return Number(item?.material_id) === Number(form_filter_material_band?.material?.value) || Number(item?.band_id) === Number(form_filter_material_band?.band?.value)
        }) ?? []
    }, [material_band_connected_store, is_type_connected_nav, form_filter_material_band]);
    const material_band_connected_select = useMemo(() => {
        let key = is_type_connected_nav ? 'connected' : 'unconnected';
        return material_band_connected_store?.[key]?.reduce((acc, item) => {
            let is_has_material = acc?.material?.some(e => Number(e?.material_id) === Number(item?.material_id))
            let is_has_band = acc?.material?.some(e => Number(e?.band_id) === Number(item?.band_id))
            if (!is_has_material) {
                acc.material.push({material_name: item?.material_name, material_id: item?.material_id})
            }
            if (!is_has_band) {
                acc.band.push({band_name: item?.band_name, band_id: item?.band_id})
            }
            return acc
        }, {
            material: [{material_id:null, material_name: getT("Все")}],
            band: [{band_id: null, band_name: getT("Все")}]
        })
    }, [material_band_connected_store, is_type_connected_nav]);

    const handlerChangeSelectMaterialBand = (key, value) => {
        setFormFilterMaterialBand(prev => {
            return {
                ...prev, [key]: value
            }
        })
    }

    const handlerChangeTypeNav = (val)=>(e)=>{
        setTypeConnectedNav(val)
        setFormFilterMaterialBand(INITIAL_FORM_FILTER_MATERIAL_BAND)
    }
    const handlerCreateConnection = ()=>{
        let params = parsForParamsData(date_filed)
        dispatch(statisticXncOperationMaterialBandConnectionCreateModalAC({isOpen: true, params:params}))
    }
    return (
        <>
            {isLoading && <Loader/>}
            <ContainerContent>
                <PageTitle title={"material_band_connected"}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-lg-end">
                                <Button onClick={handlerCreateConnection}> {getT("Создать новую связь")}</Button>
                            </div>
                            <FormFilterMaterialBand getT={getT} entry_select={material_band_connected_select}
                                                    handlerChangeSelectField={handlerChangeSelectMaterialBand}
                                                    material_band_field={form_filter_material_band}
                                                    date_field={date_filed} handlerChangeDateField={handlerChangeDateField}
                            />
                        </div>
                        <div className="col-12">
                            <div className="mt-3" >
                                <ul className="nav justify-content-center nav-tabs">
                                    <li className="nav-item">
                                        <span className={`nav-link  cursor-pointer ${is_type_connected_nav ? 'active' : ''}`} onClick={handlerChangeTypeNav(true)}>{getT("Существующие связи")}</span>
                                    </li>
                                    <li className="nav-item">
                                        <span className={`nav-link  cursor-pointer ${!is_type_connected_nav ? 'active' : ''}`} onClick={handlerChangeTypeNav(false)}>{getT("Не подтверждённые связи")}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12">
                            <TableMaterialBand getT={getT} entry={material_band_connected_data}
                                               handlerDeleteConnected={handlerDeleteConnected}
                                               handlerSetConnected={handlerSetConnected}

                            />
                        </div>
                    </div>
                </div>
                {modal_create_state?.isOpen &&  <StatisticsXncOperationsMaterialBandConnectedCreateModal/>}
            </ContainerContent>

        </>
    );
};

export default StatisticsInteractiveFormPage;