import React from "react";
import PageTitle from "../PageTitle/PageTitle";
import {withLang} from "../../hoc/withLang";
import {gaEvents} from "../../gaEvents";

const ReportsTotals = (props) => {
    const getCurrencyName = () => {
        return props.currency && props.currency.length > 0
            ? props.currency.filter(
                (e) => Number(e.currency_id) === Number(props.form.currency)
            )[0]?.name?.[props.lang]
            : "";
    };
    let data =
        props.data.client && props.data.client.old
            ? props.data.client.old
            : props.data.client;
    return (
        <>
            <div className="content-wrapper">
                <PageTitle title={"reports_totals"}/>
                <section className="content">
                    <div className="container-fluid">
                        <div className="card">
                            <form>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-2">
                                            <div className="form-group">
                                                <label>{props.getT("Начиная с ")}:</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    placeholder=""
                                                    value={props.form.date_from}
                                                    onChange={(event) => {
                                                        props.changeValue(
                                                            "date_from",
                                                            event.target.value,
                                                            props.type,
                                                            props.form
                                                        )
                                                        const nexDate = props.getNextDate(event.target.value)
                                                        props.changeValue(
                                                            "date_to",
                                                            nexDate,
                                                            props.type,
                                                            props.form
                                                        )
                                                    }
                                                    }
                                                    max={props.currentDate}
                                                    onFocus={()=>{
                                                        gaEvents.customEvent('form_order_reports_total', {
                                                            field_focus: 'Дата Начиная с'
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <label>{props.getT("До")}:</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                placeholder=""
                                                value={props.form.date_to}
                                                onChange={(event) => {
                                                    props.changeValue(
                                                        "date_to",
                                                        event.target.value,
                                                        props.type,
                                                        props.form
                                                    )

                                                }}
                                                min={props.getNextDate(props.form.date_from)}
                                                onFocus={()=>{
                                                    gaEvents.customEvent('form_order_reports_total', {
                                                        field_focus: 'Дата до'
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className="col-sm-2">
                                            <label>{props.getT("Валюта")}:</label>
                                            <select
                                                className="custom-select"
                                                value={props.form.currency}
                                                onChange={(event) =>{
                                                    gaEvents.customEvent('form_order_reports_total', {
                                                        field_focus: 'Валюта'
                                                    })
                                                    props.changeValue(
                                                        "currency",
                                                        event.target.value,
                                                        props.type,
                                                        props.form
                                                    )
                                                }}
                                            >
                                                {props.currency &&
                                                    props.currency.map((e) => {
                                                        return (
                                                            <option key={e.id} value={e.currency_id}>
                                                                {e.name?.[props.lang]}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card"></div>
                        <div className="card">
                            <h3>{props.getT("Указанный период")}:</h3>
                            <table className="table table-striped">
                                <tbody>
                                {props.data.client && (
                                    <>
                                        {props.data.client.all_goods_summ && (
                                            <tr>
                                                <td>{props.getT("Всего материалов продано")}</td>
                                                <td>
                                                    {props.formatSum(props.data.client.all_goods_summ)}{" "}
                                                    {getCurrencyName()}
                                                </td>
                                            </tr>
                                        )}
                                        {props.data.client.all_service_summ && (
                                            <tr>
                                                <td>{props.getT("Всего услуг продано")}</td>
                                                <td>
                                                    {props.formatSum(
                                                        props.data.client.all_service_summ
                                                    )}{" "}
                                                    {getCurrencyName()}
                                                </td>
                                            </tr>
                                        )}
                                        {props.data.client.all_summ && (
                                            <tr>
                                                <td>{props.getT("Всего за период")}</td>
                                                <td className={"text-right"}>
                                                    <h3>
                                                        {props.data.client.all_summ &&
                                                            props.formatSum(
                                                                props.data.client.all_summ
                                                            )}{" "}
                                                        {getCurrencyName()}
                                                    </h3>
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                )}
                                </tbody>
                            </table>
                            <h3>{props.getT("Предидущий период")}:</h3>
                            <table className="table table-striped">
                                <tbody>
                                {props.data.client && props.data.client.old && (
                                    <>
                                        {props.data.client.old && (
                                            <tr>
                                                <td>{props.getT("Всего материалов продано")}</td>
                                                <td>
                                                    {props.formatSum(
                                                        props.data.client.old.all_goods_summ
                                                    )}{" "}
                                                    {getCurrencyName()}
                                                </td>
                                            </tr>
                                        )}
                                        {props.data.client.old.all_service_summ && (
                                            <tr>
                                                <td>{props.getT("Всего услуг продано")}</td>
                                                <td>
                                                    {props.formatSum(
                                                        props.data.client.old.all_service_summ
                                                    )}{" "}
                                                    {getCurrencyName()}
                                                </td>
                                            </tr>
                                        )}
                                        {props.data.client.old.all_summ && (
                                            <tr>
                                                <td>{props.getT("Всего за период")}</td>
                                                <td className={"text-right"}>
                                                    <h3>
                                                        {props.data.client.old.all_summ &&
                                                            props.formatSum(
                                                                props.data.client.old.all_summ
                                                            )}{" "}
                                                        {getCurrencyName()}
                                                    </h3>
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default withLang(ReportsTotals);
