import {instances, URI_API_EXTTUNELL} from "../Salary/setting_api";
import {rejectTransform, resolveTransform} from "../utils_errors";

const ws_goods_stock_soutce_retail_get = 'ws_goods_stock_soutce_retail_get'
const ws_goods_stock_soutce_retail_set = 'ws_goods_stock_soutce_retail_set'
const ws_goods_stock_soutce_retail_delete = 'ws_goods_stock_soutce_retail_delete'

export const WholesaleGoodsStockRetailAPI={
    getStockRetail(data) {
        return instances.post(URI_API_EXTTUNELL + '/', data, {
            headers: {
                action: ws_goods_stock_soutce_retail_get,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    setStockRetail(data){
        return instances.post(URI_API_EXTTUNELL + '/', data, {
            headers: {
                action: ws_goods_stock_soutce_retail_set,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    } ,
    deleteStockRetail(data){
        return instances.post(URI_API_EXTTUNELL + '/', data, {
            headers: {
                action: ws_goods_stock_soutce_retail_delete,
            },
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }
}