import React, {useState} from 'react';

const ItemMaterialXncFilling = ({item, getT, unit }) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <li
            className="list-group-item border-top-0 border-left-0 border-right-0">
            <div className=" d-flex align-items-start justify-content-between" style={{gap: '25px'}}>
                <span className="font-weight-normal"> {item?.name}</span>
                <span className="font-weight-bold " style={{whiteSpace: "nowrap"}}>  <span>{item?.count} {unit}</span>
                <i  className="fas fa-angle-down cursor-pointer ml-2" style={{fontSize: "22px"}} onClick={()=>setIsOpen(!isOpen)}></i>
                </span>
            </div>
            {isOpen && <ul className={"list-group my-2"}>
                {item?.filling_entry
                    ?.map(e=>{
                    return <li className={" d-flex align-items-center justify-content-between list-group-item"} title={`${getT("Клиент")}: ${e?.order_information?.client}. \n ${getT("Менеджер")}: ${e?.order_information?.user}. \n ${getT("Дата")}: ${e?.order_information?.date}.`}>
                        <a href={`/orders/detail/${e?.order_id}`} target={"_blank"} ><i className="far fa-eye"> </i> №{e?.order_id} </a>
                        <span className={"font-weight-bold"}>{e?.filling} {unit}</span>
                    </li>
                })}
            </ul>}


        </li>
    );
};

export default ItemMaterialXncFilling;