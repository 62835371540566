import React from 'react';
import Button from "../../../components/ui/Button";

const TableMaterialBand = ({getT, entry, handlerSetConnected, handlerDeleteConnected}) => {
    return (
        <div className={""}>
            <table>
                <thead>
                <tr>
                    <th className="text-center">{getT("Материал")}</th>
                    <th className="text-center">{getT("Кромка")}</th>
                    <th className="text-center">{getT("Действие")}</th>
                </tr>
                </thead>
                {entry?.map(item => {
                    let is_connected = item?.type === "connected"
                    return <tr>
                        <td data-label={getT("Материал")}>[{item?.material_id}] {item?.material_name}</td>
                        <td data-label={getT("Кромка")}>[{item?.band_id}] {item?.band_name}</td>
                        <td data-label={getT("Действие")}>{is_connected ?
                            <Button className={"btn btn-warning"} onClick={handlerDeleteConnected(item)}>{getT("Удалить связь")}</Button>
                            :
                            <Button className={"btn btn-success"} onClick={handlerSetConnected(item)}>{getT("Добавить связь")}</Button>}
                        </td>
                    </tr>
                })}
            </table>
        </div>
    );
};

export default TableMaterialBand;