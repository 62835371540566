import React, {useEffect, useState} from 'react';
import Button from "../../../../../ui/Button";

/**
 * Тип услуги Порезка. Отметка по картам кроя
 *
 * @param {function}  getT -  перевод
 * @param {function}  handlerSubmit - запрос на убрать добавить отметки
 * @param {object}  cutting - группировка карт кроя по материалу
 * @returns  {jsx}  -
 */
const CuttingCard = ({cutting, getT, handlerSubmit, readOnly=false}) => {
    const [values, setValues] = useState(cutting);
    useEffect(() => {
        let is_change_detailing = JSON.stringify(cutting) !== JSON.stringify(values)
        if (is_change_detailing) {
            setValues(cutting)
        }
    }, [cutting]);
    const handlerValues = (item) => (e) => {
        let checked = e.target.checked;
        let copy_val = {...values};
        let key = `cutting_${item.card_id}`
        copy_val = {
            ...copy_val,
            [key]: {
                ...copy_val[key],
                cutting_checked: checked
            }
        }
        setValues(copy_val)
    }
    const handlerSubmitMark = () => {
        if (!values) return
        let data = Object.values(values);
        let put_data = data?.reduce((acc, item) => {
            if ((item?.is_initial_cutting_checked !== item?.cutting_checked) && item.cutting_checked) {
                let parts_for_put = item?.parts?.filter(part => !!(!part.ready_services?.length && !part.is_checked) )?.map(part => {
                    return {part_id: part?.part_id, count: part?.count, service_add: part.service_add,
                        // links: []
                    }
                })
                acc = [...acc, ...parts_for_put]
            }
            return acc
        }, [])
        let delete_data = data?.reduce((acc, item) => {
            console.log('item', item)
            if (((item?.is_initial_cutting_checked !== item?.cutting_checked) && !item.cutting_checked)) {
                let parts_for_delete = item?.parts?.filter(part => (part?.MySQL_record_id && part.is_checked))?.map(part => {
                    return part?.MySQL_record_id
                })
                acc = [...acc, ...parts_for_delete]
            }
            return acc
        }, [])

        let payload = {}
        if (put_data?.length) {
            payload['put'] = {entry: put_data}
        }
        if (delete_data?.length) {
            payload['remove'] = {entry: delete_data}
        }

        handlerSubmit(payload)
    }
    return (
        <div className="cutting-wrap">
            {Object.entries(values)?.map(([key, item]) => {
                let checked = item?.cutting_checked;
                return <div className={'cutting-item'} key={`mat_${item.goods_id}_cut_${item?.card_id}`}>
                    <div className="material "> [{item?.material?.id}] {item?.material?.name}</div>
                    <div className="cutting">
                        <div className="form-group form-check">
                            <input type="checkbox" className="form-check-input" id={`check_${item?.card_id}`}
                                   checked={checked} onChange={handlerValues(item)} readOnly={readOnly}
                            />
                            <label className="form-check-label" htmlFor={`check_${item?.card_id}`}>
                                <strong className={"text-secondary"}>{getT("Карта кроя")} id={item?.card_id}.
                                    x={item?.x}, y={item?.y}</strong> <br/>
                            </label>
                        </div>
                        <p> {item?.text} </p>
                    </div>

                </div>
            })}
            {readOnly ? <></> : !!values && <div className="d-flex justify-content-center">
                <Button onClick={handlerSubmitMark}>{getT("Отправить")}</Button>
            </div>
            }

        </div>

    );
};

export default CuttingCard;