import getT from "../getT";

export default function ({order_id, order_production_blank, logo, qr = '', date = '', title = '', client_name = ''}) {
    // const { qr, link_order } = order.cutting_card;
    return `
        <div class="form-qr-container">
         
            <div class="qr-info">
               <img src="${qr}" alt="" >
                <div style="">
                    <strong>${getT("Заказ")} № : </strong> ${order_id} "${title}" <br/>
                    <strong>${getT("Дата заказа")} : </strong>${date} <br/>
   <strong>${getT("Клиент")}:  </strong>${client_name}
                </div>
                
            </div>
            <div class="right" style="text-align:right;">
            ${logo ? `<img src="${logo}" alt="" >` : null}
            <div>
                    <strong>${getT("Производственный бланк заказа")}:</strong>
                    <br/>
                    <a href="${order_production_blank}" target={"_blank"}>${order_production_blank}</a>
                </div>
</div>
        </div>
        <style>
.form-qr-container {
    display: grid;
  grid-template-columns: 1fr auto;
    align-items: center;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto 25px auto;
}

.qr-info {
    display: flex;
    align-items: center;
    gap: 10px;
}
.form-qr-container div {
    line-height: 20px;
}

.form-qr-container strong {
    color: #605d81;
   ont-weight: 400;
}

</style>
  `
}