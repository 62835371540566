import React from 'react';
import StatisticsFull from "./Statistics/StatisticsFull";
import {useDispatch} from "react-redux";
import {toggleStatisticXncOperationFilteredByKeyModalAC} from "../../../store/actions/StatisticXncOperationsAction";
import StatisticsXncOperationsByKeyModal from "../../../components/Modals/StatisticsXncOperationsByKeyModal";
import StatisticsOperations from "./Statistics/StatisticsOperations";


/**
 * StatisticsMainData. Общие данные за период
 *
 * @param {object}  -
 * @param {}  -
 * @returns  {}  -
 */
const StatisticsMainData = ({data, getT, date_data=null}) => {
    const dispatch = useDispatch();
    const handlerOpenModalSeeMoreStaticsByKey= (key, add_opt=null) => (e) => {
        e.preventDefault()
        e.stopPropagation()
        dispatch(toggleStatisticXncOperationFilteredByKeyModalAC({isOpen: true, opt_key: key, add_opt:add_opt}))
    }

    return (
        <div>
            <ul className="list-group">
                {date_data && <li className="list-group-item list-group-item-warning d-flex justify-content-between px-1 py-2"><span>{getT("Количество рабочих дней")}:</span> <span className={"font-weight-bold"}>{date_data?.working_days}</span></li>}
                <StatisticsFull getT={getT} data={data} handlerOpenModalSeeMoreStaticsByKey={handlerOpenModalSeeMoreStaticsByKey}/>
                <StatisticsOperations getT={getT} data={data} handlerOpenModalSeeMoreStaticsByKey={handlerOpenModalSeeMoreStaticsByKey} />
            </ul>
            <StatisticsXncOperationsByKeyModal/>
        </div>
    );
};

export default StatisticsMainData;