import React, {useCallback, useEffect, useMemo} from 'react';
import ContainerContent from "../../components/ui/ContainerContent";
import PageTitle from "../../components/PageTitle/PageTitle";
import {useDispatch, useSelector} from "react-redux";
import {getReadyMark, getReadyMarkGoodsServiceModal, getShift} from "../../store/selectors/salary_project_selector";
import {
    getPackingPartsInformationForProductionMarksByOrder,
    getPartsInformationForProductionMarksByOrder,
    readyMarkBySubmitChoiceShiftThunk,
    readyMarkGetOrdersByFilterThunk, removeALLPackingMarkFromOrderThunk,
} from "../../store/thunks/salary_project/ready_mark_thunk";
import {getShiftThunk} from "../../store/thunks/salary_project/shifh-thunk";
import ChoiceShift from "./component/ChoiceShift";
import {readyMarGoodsServiceModalAC, readyMarToggleAllAC, setShiftFilterAC} from "../../store/actions/SalaryProject";
import Orders from "./component/Orders";
import Button from "../../components/ui/Button";
import {getGeneralGoodsServiceTypeThunk} from "../../store/thunks/salary_project/general_thunk";
import SalaryReadMarkGoodsServiceModal from "../../components/Modals/SalaryProject/SalaryReadMarkGoodsServiceModal";
import "./main.css"
import SalaryReadeMakPartForOrderForProductionMarksModal
    from "../../components/Modals/SalaryProject/SalaryReadeMakPartForOrderForProductionMarksModal";
import SalaryReadeMakPackingPartForOrderForProductionModal
    from "../../components/Modals/SalaryProject/SalaryReadeMakPackingPartForOrderForProductionModal";
import {LS_USER_ID, LS_WORKER_ID} from "../../utils/constants";

//** страница отметки улсуг по смене*/
const SalaryProjectReadyMark = ({getT,}) => {
    const dispatch = useDispatch();
    const ready_mark_state = useSelector(getReadyMark);
    const shift_state = useSelector(getShift);
    const goods_service_modal_state = useSelector(getReadyMarkGoodsServiceModal);

    useEffect(() => {
        dispatch(setShiftFilterAC({offset: 0, open_for_edit: 1}));
        dispatch(getShiftThunk());
        dispatch(getGeneralGoodsServiceTypeThunk());
    }, [dispatch]);

    //** проверка выбрана ли смена и бригада для отображения фильтра и список заказов*/
    const is_has_selected_orders = useMemo(() => {
        return (ready_mark_state?.selected_orders?.length > 1)
    }, [ready_mark_state]);

    //** открыть модалку для групповых отметок услуг*/
    const handlerGoodsServiceModal = useCallback((isOpen = false) => () => {
        dispatch(readyMarGoodsServiceModalAC({isOpen: isOpen}))
    }, [dispatch])

    //** получить заказы по фильтру*/
    const handlerGetOrders = useCallback((filter, working_group = null) => {
        let worker_group = working_group ? working_group : ready_mark_state?.shift_form_values?.worker_group
        dispatch(readyMarkGetOrdersByFilterThunk({...filter, working_group: worker_group}))
    }, [dispatch, ready_mark_state]);

    //** получить заказы по выбранной смене и бригаде*/
    const handlerSubmitShift = useCallback((data) => {
        dispatch(readyMarToggleAllAC({
            shift_form_values: {...data}
        }));
        let filter = {...ready_mark_state?.filter, working_group: data?.worker_group};
        let equipment_workers_group = data?.shift?.worker_group?.find(item => Number(item?.salary_shift_worker_group_id) === Number(data?.worker_group));
        dispatch(readyMarkBySubmitChoiceShiftThunk(filter, equipment_workers_group?.workers));
    }, [ready_mark_state]);

    //** добавить убрать заказ для групповой отметки услуг*/
    const handlerToggleSelectOrders = useCallback((data) => {
        dispatch(readyMarToggleAllAC({
            selected_orders: data
        }));
    }, [dispatch]);

    //**  убрать все выбранные заказы для групповой отметки услуг*/
    const handlerResetSelectedOrders = useCallback(() => {
        dispatch(readyMarToggleAllAC({
            selected_orders: []
        }));
    }, [dispatch]);
    const filter_actual = useMemo(()=>{
        let filter = null;
        if (ready_mark_state?.shift_form_values?.worker_group && ready_mark_state?.shift_form_values?.shift?.salary_shift_id) {
            filter = {
                shift: ready_mark_state?.shift_form_values?.shift?.salary_shift_id,
                working_group: ready_mark_state?.shift_form_values?.worker_group
            }
        }
        return filter
    }, [ready_mark_state])
    //open modal checked service
    const handlerGetPartForOrderProduction = (order_id) => (event) => {
       let filter = filter_actual
        dispatch(getPartsInformationForProductionMarksByOrder(order_id, filter))
    }
    //open modal checked packing
    const handlerGetPackingPartForOrderProduction = (order_id) => (event) => {
        let filter = filter_actual
        dispatch(getPackingPartsInformationForProductionMarksByOrder(order_id, filter))
    }
    const handlerDeletePackingOfOrder = (order_id) => (event) => {
        let filter = filter_actual
        dispatch(removeALLPackingMarkFromOrderThunk({
            id:order_id,
            user: localStorage.getItem(LS_USER_ID),
            equipment_worker: localStorage.getItem(LS_WORKER_ID)
        }))
    }
    //** проверка выбрана ли смена и бригада*/
    const isChoiceShift = useMemo(() => {
        return ready_mark_state?.shift_form_values?.shift && ready_mark_state?.shift_form_values.worker_group;
    }, [ready_mark_state]);


    return (
        <ContainerContent>
            <PageTitle title={"ready_mark"}/>
            <div className="container-fluid ">
                <div className="row">

                    <div className={` ${is_has_selected_orders ? 'col-12 col-md-8' : 'col-12'}`}>
                        <ChoiceShift handlerSubmitShift={handlerSubmitShift}
                                     getT={getT} shifts_data={shift_state?.data}
                                     shift_form_values={ready_mark_state?.shift_form_values} key={'choice-filter'}/>
                    </div>
                    {is_has_selected_orders && <div className="col-12 col-md-4 mt-2 mt-md-0">
                        <div className="d-flex align-items-center justify-content-md-end h-100">
                            <Button className="btn-info fs-14"
                                    onClick={handlerGoodsServiceModal(true)}>{getT("Групповая отметка услуг")}</Button>
                            <Button className="btn-danger ml-2 fs-14"
                                    onClick={handlerResetSelectedOrders}>{getT("Снять выделение заказов")}</Button>
                        </div>

                    </div>}
                    {isChoiceShift && <>
                        <div className="col-12">

                        </div>
                        <div className="col-12 col-xl-12">
                            <Orders getT={getT} handlerGetPartForOrderProduction={handlerGetPartForOrderProduction}
                                    handlerGetPackingPartForOrderProduction={handlerGetPackingPartForOrderProduction}
                                    ready_mark={ready_mark_state} handlerGetOrders={handlerGetOrders}
                                    handlerToggleSelectOrders={handlerToggleSelectOrders} handlerDeletePackingOfOrder={handlerDeletePackingOfOrder}/>
                        </div>
                    </>
                    }
                </div>
            </div>
            {goods_service_modal_state?.isOpen &&
                <SalaryReadMarkGoodsServiceModal selected_orders={ready_mark_state?.selected_orders} getT={getT}
                                                 is_ready_order={ready_mark_state?.filter?.ready}
                                                 shift_form_values={ready_mark_state?.shift_form_values}
                                                 onClose={handlerGoodsServiceModal(false)}/>}

            <SalaryReadeMakPackingPartForOrderForProductionModal getT={getT} callbackAfterCloseModal={()=>handlerGetOrders(ready_mark_state?.filter)} key={'sal_proj_packing_part_of_order'} filter={ready_mark_state?.filter}/>

            <SalaryReadeMakPartForOrderForProductionMarksModal getT={getT} key={'sal_proj_part_of_order'} callbackAfterCloseModal={()=>handlerGetOrders(ready_mark_state?.filter)} />
        </ContainerContent>
    );
};

export default SalaryProjectReadyMark;