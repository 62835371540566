import {helpArticleGetByIdToggleModalAC, helpArticleSendCommentMailAC} from "../actions/HelperArticleAction";
import {dispatcherErrorThunk} from "./common_thunk";
import {HelperArticleAPI} from "../../api/HelperArticleAPI";
import toast from 'react-hot-toast';

export const helpArticleGetByIdToggleModalThunk = (id) => async dispatch => {
    try {
        dispatch(helpArticleGetByIdToggleModalAC({isLoading: true}));
        const content_modal = await HelperArticleAPI.get(id);
        dispatch(helpArticleGetByIdToggleModalAC({
            isLoading: false,
            is_open: true,
            error: null,
            isError: null,
            content: content_modal,

        }));
    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(helpArticleGetByIdToggleModalAC({
            isLoading: false,
            is_open: false,
            error: msg,
            isError: true,
            content: null
        }));
        dispatch(dispatcherErrorThunk(e))
    }
}

export const helpArticleSendCommentMailThunk = (text) => async dispatch => {
    try {
        dispatch(helpArticleSendCommentMailAC({isLoading: true}));
        const res = await HelperArticleAPI.sendLetter({text});
        dispatch(helpArticleSendCommentMailAC({isLoading: false, error: null, isError: null}));
        console.log('res', res)
        // dispatch(changeAlertStatusAC(true, `${getTranslateMessageAPI('Успешно')}`, true));
        toast.success(res?.data?.response?.response)

        return Promise.resolve(true)

    } catch (e) {
        let msg = e.hasOwnProperty('error') ? e.error : e.message;
        dispatch(helpArticleGetByIdToggleModalAC({
            isLoading: false,
            is_open: false,
            error: msg,
            isError: true,
            content: null
        }));
        dispatch(dispatcherErrorThunk(e))
        return Promise.resolve(false)
    }
}

