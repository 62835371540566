import React from 'react';

const IconPaid = () => {
    return (
        <svg width={'25px'}
             xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1"
             x="0px" y="0px" viewBox="0 0 392.533 392.533"
        >
            <path style={{
                fill: "#FFFFFF"
            }}
                  d="M300.089,153.571H159.741l-10.343,19.329c-9.762,18.295-33.293,26.246-53.075,14.61  c-12.283-7.37-18.941-20.493-18.36-33.939H33.358c-6.4,0-11.572,5.172-11.572,11.572v166.659c0,6.4,5.172,11.572,11.572,11.572  h266.731c6.4,0,11.572-5.172,11.572-11.572V165.143C311.725,158.808,306.554,153.571,300.089,153.571z"/>
            <path style={{
                fill: "#56ACE0"
            }}
                  d="M264.21,85.628c-3.232,2.909-7.887,3.62-11.895,1.875l-79.644-36.331  c-11.184-5.107-24.63-0.259-30.061,10.925l-41.374,85.851c-3.62,7.564-1.034,16.614,6.271,20.945  c8.857,4.913,19.006,1.099,22.626-6.271l27.152-50.554c9.503-17.002,30.707-19.329,44.154-6.594l26.376,26.246h72.469  c17.002,0,30.966,12.735,33.099,29.22l33.099-33.552l-70.659-70.465L264.21,85.628z"/>
            <path style={{fill: "#FFC10D"}}
                  d="M65.422,175.293c0,12.024-9.762,21.786-21.786,21.786v102.594c12.024,0,21.786,9.762,21.786,21.786  h202.667c0-12.024,9.762-21.786,21.786-21.786V197.079c-12.024,0-21.786-9.762-21.786-21.786"/>
            <g>
                <path style={{fill: "#194F82"}}
                      d="M160.517,220.868h31.677c6.012,0,10.925-4.848,10.925-10.925c0-6.012-4.848-10.925-10.925-10.925   h-9.762v-3.62c0-6.012-4.848-10.925-10.925-10.925c-6.012,0-10.925,4.848-10.925,10.925v3.62h-0.129   c-16.614,0-30.061,13.511-30.061,30.061c0,16.614,13.511,30.061,30.061,30.061h12.412c4.59,0,8.275,3.685,8.275,8.275   c0,4.59-3.685,8.275-8.275,8.275h-31.677c-6.012,0-10.925,4.849-10.925,10.925s4.848,10.925,10.925,10.925h19.329v3.685   c0,6.012,4.848,10.925,10.925,10.925c6.012,0,10.925-4.848,10.925-10.925v-5.236c11.96-3.943,20.622-15.192,20.622-28.509   c0-16.614-13.511-30.061-30.061-30.061h-12.412c-4.59,0-8.275-3.685-8.275-8.275C152.242,224.553,155.927,220.868,160.517,220.868z   "/>
                <path style={{fill: "#194F82"}}
                      d="M262.659,229.531c-6.012,0-10.925,4.848-10.925,10.925v15.903c0,6.012,4.848,10.925,10.925,10.925   c6.012,0,10.925-4.848,10.925-10.925v-15.903C273.519,234.38,268.671,229.531,262.659,229.531z"/>
                <path style={{fill: "#194F82"}}
                      d="M70.853,229.531c-6.012,0-10.925,4.848-10.925,10.925v15.903c0,6.012,4.848,10.925,10.925,10.925   s10.925-4.848,10.925-10.925v-15.903C81.778,234.38,76.865,229.531,70.853,229.531z"/>
                <path style={{fill: "#194F82"}}
                      d="M389.366,119.632l-85.592-85.527c-4.073-4.073-10.731-4.267-15.063-0.388l-33.939,30.901   l-73.115-33.293c-21.915-9.956-48.291-0.453-58.699,21.269l-38.141,79.192H33.358C14.998,131.786,0,146.784,0,165.143v166.659   c0,18.36,14.998,33.358,33.358,33.358h266.731c18.36,0,33.358-14.998,33.358-33.358V191.713l55.855-56.695   C393.632,130.751,393.568,123.899,389.366,119.632z M311.725,331.866c0,6.4-5.172,11.572-11.572,11.572H33.358   c-6.4,0-11.572-5.172-11.572-11.572V165.143c0-6.4,5.172-11.572,11.572-11.572H77.77c-0.517,13.446,6.077,26.57,18.36,33.939   c19.782,11.636,43.378,3.62,53.075-14.61l10.343-19.329h140.477c6.4,0,11.572,5.172,11.572,11.572v166.723H311.725z    M171.378,131.786l5.107-9.438c2.65-3.62,5.818-4.073,9.503-1.422l10.925,10.796h-25.535V131.786z M333.188,161.006   c-2.069-16.485-16.097-29.22-33.099-29.22H227.62l-26.376-26.246c-13.446-12.735-34.521-10.537-44.154,6.594l-27.152,50.554   c-3.62,7.24-13.77,11.184-22.626,6.271c-7.176-4.331-9.891-13.382-6.271-20.945l41.438-85.851   c5.301-11.119,18.877-15.968,30.061-10.925l79.644,36.331c3.943,1.745,8.663,1.099,11.895-1.875l31.547-28.768l70.659,70.529   L333.188,161.006z"/>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
            <g>
            </g>
        </svg>
    );
};

export default IconPaid;