import React, {useEffect, useReducer, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ListCuttingCut from "./ListCuttingCut";
import Modal from "../MyModal/Modal";
import {updateDataCuttingForHtml} from "../../../store/reducers/MainReducer";
import "./cutting-modal.css"

const keyField = {
    general: "general",
    xnc: "xnc",
    mode: 'mode',
    modeDetailing: 'modeDetailing',

    // labels: 'labels',

};
const initialState = {
    general: 1,
    cards: [],
    xnc: 1,
    mode: 1,
    modeDetailing: 1


    // labels: 1
};
const actionType = {
    changeAddOption: "CHANGE_ADD_OPTIONS",
    changeValueCard: "CHANGE_CARD_VALUE",
    setAllCurdsValue: "SET_ALL_CARDS_VALUE",
    setAllDetailEncoding: "SET_ALL_DETAIL_ENCODING",
    changeMode: "CHANGE_MODE",
    changeModeDetailing: "CHANGE_MODE_DETAILING",

};
const action = {
    onChangeModeDetailing: (value) => {
        return {
            type: actionType.changeModeDetailing,
            payload: value
        }
    },
    onChangeMode: (value) => {
        return {
            type: actionType.changeMode,
            payload: value
        }
    },
    onChangeAddOptions: ({key, value}) => {
        return {
            type: actionType.changeAddOption,
            payload: {key, value}
        };
    },
    onChangeCard: ({cardId, key, value}) => {
        return {
            type: actionType.changeValueCard,
            payload: {key, value, cardId}
        };
    },
    onChangeALlCardsValue: ({isAdd = true}) => {
        return {
            type: actionType.setAllCurdsValue,
            payload: {isAdd}
        };
    },
    onChangeALlDetailEncoding: ({isAdd = true}) => {
        return {
            type: actionType.setAllDetailEncoding,
            payload: {isAdd}
        };
    }
};
const reducer = (state, action) => {
    switch (action.type) {
        case actionType.changeAddOption: {
            return {
                ...state,
                [action.payload.key]: action.payload.value
            };
        }
        case actionType.changeValueCard: {
            const cards = state.cards.map(card => {
                if (Number(card.card_id) === Number(action.payload.cardId)) {
                    return {
                        ...card,
                        [action.payload.key]: action.payload.value
                    };
                }
                return card;
            });
            return {
                ...state,
                cards: cards
            };
        }
        case actionType.setAllCurdsValue: {
            const cards = state?.cards?.map(card => {
                return {
                    ...card,
                    card: action.payload.isAdd ? 1 : 0,
                };

            });
            return {
                ...state,
                cards: cards
            };
        }
        case actionType.setAllDetailEncoding: {
            const cards = state.cards.map(card => {
                return {
                    ...card,
                    parts_info: action.payload.isAdd ? 1 : 0
                };

            });
            return {
                ...state,
                cards: cards
            };
        }
        case actionType.changeMode: {
            return {
                ...state,
                mode: action.payload
            }
        }
        case actionType.changeModeDetailing: {
            return {
                ...state,
                modeDetailing: action.payload
            }
        }
        default: {
            return state;
        }
    }
};

const CuttingCard = ({isOpen, onClose, getT, order}) => {
        const [isAdd, setIsAdd] = useState(true);
        const [isAddDetailEncoding, setIsAddDetailEncoding] = useState(true);
        const cuttingCard = order?.project_data?.cutting_card;
        const materials = order?.project_data?.material;
        const usedispatch = useDispatch();
        const [isDisplayList, setDisplayList] = useState(false);

        const [state, dispatch] = useReducer(reducer, {
            ...initialState, cards: cuttingCard?.cards ?
                cuttingCard?.cards?.map(c => ({
                    card_id: c.card_id,
                    card: 0,
                    parts_info: 0
                })) : []
        });
        const onChangeOptionCard = ({key, cardId, value}) => {
            setIsAdd(false);
            dispatch(action.onChangeCard({cardId, key, value}));
        };
        const sendPrint = () => {
            usedispatch(updateDataCuttingForHtml({...state}, order));
        };
        const onChangeAllCards = (value) => {
            dispatch(action.onChangeALlCardsValue({
                isAdd: value
            }));
        };
        const onChangeAllDetailEncoding = (value) => {
            dispatch(action.onChangeALlDetailEncoding({
                isAdd: value
            }));
        };
        useEffect(() => {
            if (cuttingCard?.cards) {
                onChangeAllCards(isAdd);
                onChangeAllDetailEncoding(isAddDetailEncoding)
            }
            const closeCommand = (evt) => {
                evt = evt || window.event;
                var isEscape = false;
                if ("key" in evt) {
                    isEscape = (evt.key === "Escape" || evt.key === "Esc");
                } else {
                    isEscape = (evt.keyCode === 27);
                }
                if (isEscape) {
                    onClose()
                }
            }
            document.addEventListener('keydown', closeCommand);
            return () => document.removeEventListener('keydown', closeCommand);
        }, []);


        return (
            <Modal onClose={onClose} open={isOpen} title={getT("Печать карт кроя")} size_modal_class={"modal-md"}>
                <>
                    {/*<h2>{getT("Печать карт кроя")}</h2>*/}
                    <div className="cutting-card-settings-print mb-3 mt-3">
                        <div className="form-field"
                             style={{marginBottom: '10px', borderBottom: '1px solid #f2f2f2', paddingBottom: '10px'}}>
                            <div className="d-flex items-center">
                                <div className="toggle-pill">
                                    <input type="checkbox" id={'mode'} checked={Boolean(state[keyField.mode])}
                                           value={Boolean(state[keyField.mode])} onChange={(event) => {
                                        dispatch(action.onChangeMode(event.target.checked))
                                    }}/>
                                    <label htmlFor="mode"></label>
                                </div>
                                <label style={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold'
                                }}>{getT('Только карты кроя')}</label>
                            </div>
                            {state?.mode ? <div className="" style={{marginTop: "20px"}}>
                                <div className="checkbox-label__input">
                                    <input type="checkbox"
                                           id={'mode_detailing'}
                                           checked={Boolean(state[keyField.modeDetailing])}
                                           value={Boolean(state[keyField.modeDetailing])}
                                           onChange={(event) => {
                                               dispatch(action.onChangeModeDetailing(event.target.checked))
                                           }}
                                    />
                                    <label htmlFor={"mode_detailing"} className={""}>
                                        {getT("Расшифровка деталей")}
                                    </label>
                                </div>
                            </div> : ''}
                        </div>
                        <div className={`d-flex mb-2 align-items-center cursor-pointer ${state.mode ? ' disabled' : ' '}`} style={{gap: "3px"}}
                        onClick={()=>setDisplayList(!isDisplayList)}
                        >
                            <strong>{getT("Опции печати")} </strong>
                            <i className={`fas fa-angle-${isDisplayList ? 'up': 'down'}`}></i>
                        </div>
                        {isDisplayList &&<div className={`${state.mode ? ' disabled' : ' '} cut-list-checkbox`}>

                            <div className="list">
                                <div className="item level_1">
                                    <div className="checkbox-label__input">
                                        <input
                                            checked={Boolean(state[keyField.general])}
                                            value={Boolean(state[keyField.general])}
                                            type="checkbox"
                                            id={"general"}
                                            onChange={(e) => {
                                                dispatch(action.onChangeAddOptions({
                                                    key: keyField.general,
                                                    value: Number(e.target.checked)
                                                }));
                                            }
                                            }/>
                                        <label htmlFor={"general"} className={""}>
                                            {getT("Информация о кромках, сводная информация о картах кроя")}
                                        </label>
                                    </div>
                                </div>
                                <div className="item level_1">
                                    <div className="checkbox-label__input">
                                        <input
                                            checked={Boolean(state[keyField.xnc])}
                                            value={Boolean(state[keyField.xnc])}
                                            type="checkbox"
                                            id={"xnc"}
                                            onChange={(e) => {
                                                dispatch(action.onChangeAddOptions({
                                                    key: keyField.xnc,
                                                    value: Number(e.target.checked)
                                                }));
                                            }
                                            }/>
                                        <label htmlFor={"xnc"} className={""}>
                                            {getT("ЧПУ операции (статистика)")}
                                        </label>
                                    </div>
                                </div>
                                <div className="" style={{marginBottom: "15px", display: "flex"}}>
                                    <div className="">
                                        <div className="checkbox-label__input">
                                            <input
                                                value={isAdd}
                                                checked={isAdd}
                                                type="checkbox"
                                                id={"cutting_all"}
                                                onChange={(e) => {
                                                    setIsAdd(Boolean(e.target.checked));
                                                    onChangeAllCards(Boolean(e.target.checked));
                                                }}/>
                                            <label htmlFor={"cutting_all"} className={""}>
                                                {getT("Карты кроя")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className="" style={{marginLeft: "15px"}}>
                                        <div className="checkbox-label__input">
                                            <input
                                                value={isAddDetailEncoding}
                                                checked={isAddDetailEncoding}
                                                type="checkbox"
                                                id={"detail_encode_all"}
                                                onChange={(e) => {
                                                    setIsAddDetailEncoding(Boolean(e.target.checked));
                                                    onChangeAllDetailEncoding(Boolean(e.target.checked));
                                                }}/>
                                            <label htmlFor={"detail_encode_all"} className={""}>
                                                {getT("Расшифровка деталей")}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <ListCuttingCut getT={getT} cards={cuttingCard?.cards} materials={materials}
                                                stateField={state.cards}
                                                onChangeOptionCard={onChangeOptionCard}/>
                            </div>
                        </div>}
                        <div className={'mt-3'}>
                            <button className={"btn-primary w-100 pt-2 pb-2"}
                                    onClick={sendPrint}>{getT("Отправить")}</button>
                        </div>

                    </div>
                </>
            </Modal>
        );
    }
;

export default CuttingCard;