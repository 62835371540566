import {spread} from "axios";
import {EquipmentWorkerAPI} from "../../../api/Salary/EquipmentWorkerAPI";
import {createFormData} from "../../../utils/helpers";

//** getWorkerInParallelPromise. Получить работников */
export const getWorkerInParallelPromise = async (equipment_worker=[])=>{
    let parallel_request_entry = equipment_worker?.map(item=> ({
        equipment_worker_id: item?.equipment_worker_id || item?.worker
    }))
    const response = await Promise.all(parallel_request_entry.map((api_data) => {
        let form_data = createFormData(api_data)
        return EquipmentWorkerAPI.getById(form_data)
    })).then(
        spread((...allData) => {
            return allData
        })
    );

    let errors_finds = response && response?.filter(item => item?.hasOwnProperty('error') && !!item?.error)?.map(error => error?.error)
    let errors = [...new Set(errors_finds)]?.join('. ')
    if (errors) {
        return new Promise((res) => res({error: errors}))
    }
    return new Promise((res) => res(response?.map(item=> item?.data)?.flat()))
}