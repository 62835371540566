import React, {useEffect} from 'react';
import PageTitle from "../../components/PageTitle/PageTitle";
import ContainerContent from "../../components/ui/ContainerContent";
import ReportFilter from "./component/ReportFilter";
import {useDispatch, useSelector} from "react-redux";
import {getEquipmentWorkerGroupThunk} from "../../store/thunks/salary_project/worker_group_thunk";
import {getGeneralCurrenciesThunk} from "../../store/thunks/salary_project/general_thunk";
import {
    getEquipmentWorkerGroup,
    getGeneralCurrencies,
    getSalaryReport
} from "../../store/selectors/salary_project_selector";
import {salaryReportThunk} from "../../store/thunks/salary_project/salary_report_thunk";

import PayWork from "./component/PayWork";
import "./main.css"
import {addSpacesToNumber} from "../../utils/helpers";

const SalaryReportPage = ({getT}) => {
    const dispatch = useDispatch();
    const workers = useSelector(getEquipmentWorkerGroup);
    const currency = useSelector(getGeneralCurrencies);
    const salary_report = useSelector(getSalaryReport)

    useEffect(() => {
        dispatch(getEquipmentWorkerGroupThunk());
        dispatch(getGeneralCurrenciesThunk());
    }, [dispatch]);
    const handlerFilterGetReportByWorker = (filter) => {
        let {year_month, worker, ...reset} = filter;
        let date = year_month?.split('-')
        dispatch(salaryReportThunk({...reset, worker: worker?.value, year: date?.[0], month: date?.[1]}))
    }

    return (
        <ContainerContent>
            <PageTitle title={"salary_report"}/>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <ReportFilter getT={getT} workers={workers?.data} currency={currency?.data}
                                      onSubmit={handlerFilterGetReportByWorker} amount={salary_report?.data?.summ} currency_name={salary_report?.data?.currency_name}/>
                    </div>
                    {!!(salary_report?.data?.pay_work?.length) &&  <PayWork pay_work={salary_report?.data?.pay_work || []} getT={getT}/>}
                    {!!(salary_report?.data?.pay_fine?.length) &&

                        <div className="col-12 col-md-6">
                            <div className={"card"}>
                                <div className="card-header px-2">
                                    <div className="d-flex justify-content-between align-items-center flex-row">
                                        <h5 className={"card-title font-weight-bold"}>{getT("Штрафы")}</h5>
                                    </div>
                                </div>
                                <div className="card-body services_ready_list_by_shift_payment px-0 py-0">

                                    {salary_report?.data?.pay_fine?.map(item => {
                                        return <ul className={"list-unstyled px-2 mb-0 py-2"}>
                                            <li className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}
                                                style={{gap: '10px'}}><strong>{getT("Заказ")}</strong>
                                                <span>{item?.order_id}</span></li>
                                            <li className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}
                                                style={{gap: '10px'}}><strong>{getT("Услуга")}</strong>
                                                <span>{item?.service_name}</span></li>
                                            <li className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}
                                                style={{gap: '10px'}}><strong>{getT("Штраф")}</strong>
                                                <span>{addSpacesToNumber(item?.fine_amount)}</span></li>
                                            <li className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}
                                                style={{gap: '10px'}}><strong>{getT("Валюта")}</strong>
                                                <span>{item?.currency_name}</span></li>
                                            <li className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}
                                                style={{gap: '10px'}}><strong>{getT("Дата начисления")}</strong>
                                                <span>{item?.event_date}</span></li>
                                            <li className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0 border-bottom pb-1 mb-1"}
                                                style={{gap: '10px'}}><strong>{getT("Комментарий бонуса")}</strong>
                                                <span>{item?.fine_comment}</span></li>
                                            <li className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}
                                                style={{gap: '10px'}}><strong>{getT("Комментарий документа")}</strong>
                                                <span>{item?.document_comment}</span></li>
                                        </ul>
                                    })}

                                </div>
                            </div>
                        </div>}
                    {!!(salary_report?.data?.pay_bonus?.length) && <div className="col-12 col-md-6">
                        <div className={"card"}>
                            <div className="card-header px-2">
                                <div className="d-flex justify-content-between align-items-center flex-row">
                                    <h5 className={"card-title font-weight-bold"}>{getT("Бонусы")}</h5>
                                </div>
                            </div>
                            <div className="card-body services_ready_list_by_shift_payment px-0 py-0">

                                {salary_report?.data?.pay_bonus?.map(item => {
                                    return <ul className={"list-unstyled px-2 mb-0 py-2"}>
                                        <li className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}
                                            style={{gap: '10px'}}><strong>{getT("Заказ")}</strong>
                                            <span>{item?.order_id}</span></li>
                                        <li className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}
                                            style={{gap: '10px'}}><strong>{getT("Услуга")}</strong>
                                            <span>{item?.service_name}</span></li>
                                        <li className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}
                                            style={{gap: '10px'}}><strong>{getT("Бонус")}</strong>
                                            <span>{addSpacesToNumber(item?.bonus_amount)}</span></li>
                                        <li className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}
                                            style={{gap: '10px'}}><strong>{getT("Валюта")}</strong>
                                            <span>{item?.currency_name}</span></li>
                                        <li className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}
                                            style={{gap: '10px'}}><strong>{getT("Дата начисления")}</strong>
                                            <span>{item?.event_date}</span></li>
                                        <li className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0 border-bottom pb-1 mb-1"}
                                            style={{gap: '10px'}}><strong>{getT("Комментарий бонуса")}</strong>
                                            <span>{item?.bonus_comment}</span></li>
                                        <li className={"fs-14 d-flex justify-content-between flex-row text-secondary my-0"}
                                            style={{gap: '10px'}}><strong>{getT("Комментарий документа")}</strong>
                                            <span>{item?.document_comment}</span></li>
                                    </ul>
                                })}

                            </div>
                        </div>
                    </div>}

                </div>
            </div>
        </ContainerContent>
    );
};

export default SalaryReportPage;