import React, {useEffect, useMemo, useRef} from 'react';
import ContainerContent from "../../components/ui/ContainerContent";
import {useDispatch, useSelector} from "react-redux";
import {openShiftThunk} from "../../store/thunks/salary_project/shifh-thunk";
import {
    getDefaultPlane,
    getEquipmentWorkerGroup,
    getShiftOpen,
    getShiftPlane
} from "../../store/selectors/salary_project_selector";
import ShiftData from "./component/ShiftData";
import ShiftPlane from "./component/ShiftPlane/ShiftPlane";
import SalaryShiftPlaneCreateModal from "../../components/Modals/SalaryProject/SalaryShiftPlaneCreateModal";
import SalaryShiftPlaneUpdateModal from "../../components/Modals/SalaryProject/SalaryShiftPlaneUpdateModal";
import "./main.css";
import {Link} from "react-router-dom";
import WorkerGroup from "./component/WorkerGroup/WorkerGroup";
import WorkerGroupModal from "../../components/Modals/SalaryProject/WorkerGroupModal";
import {getIsAccessAuthUserRoleById} from "../../store/selectors/auth_selector";
import {LS_USER_ID, SALARY_PROJECT_ACCESS_ROLE} from "../../utils/constants";
import Button from "../../components/ui/Button";
import SalaryCloseShiftModal from "../../components/Modals/SalaryProject/SalaryCloseShiftModal";
import {getAdditionDocumentByShift} from "../../store/thunks/salary_project/addition_document_thunk";
import AdditionalDocument from "./component/AdditionalDocument";
import ShiftServiceReadyList from "./component/ShiftServiceReadyList";

/**
 * SalaryShiftOpenPage. Открытая смена
 *
 * @param {function}  getT - translate
 * @param {object} match -  react router
 * @returns  {jsx}  -
 */
const SalaryShiftOpenPage = ({getT, match}) => {
    const dispatch = useDispatch();
    const is_user_in_system = !!Number(localStorage.getItem(LS_USER_ID));
    const shift_open_state = useSelector(getShiftOpen);
    const shift_plane_state = useSelector(getShiftPlane);
    const default_plane = useSelector(getDefaultPlane);
    const equipment_worker_group = useSelector(getEquipmentWorkerGroup);
    // права доступа для создания редактирования данных о смене
    const is_available_access_role = useSelector(getIsAccessAuthUserRoleById(SALARY_PROJECT_ACCESS_ROLE));
    const is_open_shift = useMemo(() => {
        return !!Number(shift_open_state?.open_for_edit)
    }, [shift_open_state]);
//Закрыть смену может только пользователь и если есть services_ready_list_by_shift
    const is_close_shift = is_user_in_system && is_open_shift && !!shift_open_state?.services_ready_list_by_shift?.length

    const refModal = useRef(null);
    useEffect(() => {
        let shift_id = match?.match?.params?.id;
        dispatch(openShiftThunk({shift_id: shift_id}))
    }, [dispatch]);
    useEffect(() => {
        if (!shift_open_state) return
        let is_close_shift = !Number(shift_open_state?.open_for_edit);
        if (is_close_shift && is_user_in_system) {
            let shift_id = match?.match?.params?.id;
            dispatch(getAdditionDocumentByShift(shift_id))

        }
    }, [shift_open_state, dispatch, is_user_in_system])

    const handlerOpenModalCloseShift = () => {
        refModal.current.open();
    }
    const service_ready_entry = useMemo(() => {
        if (!shift_open_state || !Array.isArray(shift_open_state.services_ready_list_by_shift)) return []
        let data = shift_open_state?.services_ready_list_by_shift?.reduce((acc, item) => {
            let service_type = item.service_type;
            let service = item.service;
            let key_serv_type = `service_type_${service_type.goods_service_type_id}`;
            let ker_serv = `service_${service.goods_service_id}`;
            let items = acc?.[key_serv_type]?.[ker_serv]?.items ?? [];
            let find_worker = shift_open_state?.worker_group?.find(e => Number(e?.salary_shift_worker_group_id) === Number(item?.worker_group));
            let result = {
                ...acc,
                [key_serv_type]: {
                    ...acc?.[key_serv_type],
                    ...service_type,
                    [ker_serv]: {
                        ...service,
                        items: [...items, {
                            count: item?.count,
                            count_part: item?.count_part,
                            finalized: item?.finalized,
                            orders_goods: item?.orders_goods,
                            part_id: item?.part_id,
                            worker_group: find_worker,
                            payment: item?.payment
                        }]
                    }

                }
            }
            return result;

        }, {});
        let result = Object.values(data)?.map(item => {
            let finish_item = {}
            let service = [];
            for (const [key, value] of Object.entries(item)) {
                let is_has_service_id = key.split('_')?.[0];
                if (is_has_service_id.includes('service')) {
                    service.push(value)
                } else {
                    finish_item = {
                        ...finish_item, [key]: value
                    }
                }

            }
            return {
                ...finish_item, service: service
            }
        })
        return result
    }, [shift_open_state])
    return (
        <ContainerContent>
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex ju"></div>
                        <Link to={'/salary-project/shift'} className={'my-3 d-flex align-items-center'}><span
                            className="h5">
                                <i className="fa fa-arrow-left mr-2"
                                   aria-hidden="true"></i>{getT("Все смены")}</span></Link>
                        <div className="d-flex justify-content-end flex-wrap" style={{gap: '10px'}}>
                            {/*{is_user_in_system && !is_open_shift && <Button className={"btn-warning"} onClick={handlerOpenShift}>{getT("Открыть смену")}</Button>}*/}
                            {is_close_shift && <Button className={"btn-danger"}
                                                       onClick={handlerOpenModalCloseShift}>{getT("Закрыть смену")}</Button>}
                        </div>
                    </div>

                    <div className="col-12 col-md-12 col-lg-10 mx-auto my-3">
                        <ShiftData shift={shift_open_state} getT={getT}
                                   is_available_access_role={is_available_access_role}/>
                    </div>
                    <div className="col-12 col-md-12 col-lg-10 mx-auto my-3">

                    <ShiftPlane shift={shift_open_state} shift_plane={shift_plane_state} getT={getT}
                                    is_available_access_role={is_available_access_role}
                                    default_plane={default_plane} is_user_in_system={is_user_in_system}
                                    is_open_shift={is_open_shift}/>
                    </div>
                    <div className="col-12 col-md-12 col-lg-10 mx-auto my-3">

                    <WorkerGroup shift={shift_open_state} shift_plane={shift_plane_state} getT={getT}
                                     is_open_shift={is_open_shift}
                                     is_available_access_role={is_available_access_role}
                                     default_plane={default_plane} equipment_worker_group={equipment_worker_group}/>
                    </div>
                    {is_user_in_system && !is_open_shift &&                     <div className="col-12 col-md-12 col-lg-10 mx-auto my-3">

                    <AdditionalDocument getT={getT} shift_id={shift_open_state?.salary_shift_id}/>
                    </div>}
                    <div className="col-12 col-md-12 col-lg-10 mx-auto my-3">

                    <ShiftServiceReadyList getT={getT} service_ready_entry={service_ready_entry}/>
                    </div>
                </div>

            </div>
            <SalaryShiftPlaneCreateModal getT={getT}/>
            <SalaryShiftPlaneUpdateModal getT={getT}/>
            <SalaryCloseShiftModal shift={shift_open_state} getT={getT} refModal={refModal}/>
            <WorkerGroupModal getT={getT} default_plane={default_plane?.data}/>
        </ContainerContent>
    );
};

export default SalaryShiftOpenPage;