import React, {useState} from 'react';
import ItemBore from "./ItemBore";
import ItemPaz from "./ItemPaz";
import ItemFrez from "./ItemFrez";

const StatisticsOperations = ({data, getT, handlerOpenModalSeeMoreStaticsByKey = null}) => {
    let bore = data?.bore ?? null; // Array {d, count, type}
    let cut_to = data?.cut_to ?? 0; // number
    let frez = data?.frez ?? null; //object { frez_template_usage - ? , import, template}
    let hem = data?.hem ?? 0; // number
    let paz = data?.paz ?? null; // Array
    let trough = data?.trough ?? null; // obj {strait: 27, curve: 3, circle: 5}
    // if (!bore && !cut_to && !frez && !hem && !paz && !trough) return null;
    let bore_in = bore?.filter(e => e.type == "in")?.sort((a, b) => a?.d - b?.d)
    let bore_through = bore?.filter(e => e.type == "through")?.sort((a, b) => a?.d - b?.d)

    let paz_plane = paz?.filter(e => e.type == "plane")?.sort((a, b) => a?.w_paz - b?.w_paz)
    let paz_edge = bore?.filter(e => e.type == "edge")?.sort((a, b) => a?.w_paz - b?.w_paz)

    let bore_all_count = data?.bore_all ?? '';
    let paz_all_count = data?.paz_all ?? '';

    return (
        <li className={"list-group-item px-1 py-2"}>
            {/*<div className="d-flex align-items-center justify-content-between" onClick={() => setOpenXnc(!isOpenXnc)}>*/}
            <span>{getT("Операции ЧПУ")}</span>
            {/*</div>*/}
            <ul>
                {(Array.isArray(bore) && bore?.length) ?

                    <ItemAccordionXnc title={getT("Отверстия")} count={bore_all_count}>
                        <>
                            <ItemBore getT={getT} data={bore_in} title={getT('Глухие')}
                                      handlerOpenModalSeeMoreStaticsByKey={handlerOpenModalSeeMoreStaticsByKey}/>
                            <ItemBore getT={getT} data={bore_through} title={getT('Сквозные')}
                                      handlerOpenModalSeeMoreStaticsByKey={handlerOpenModalSeeMoreStaticsByKey}/>
                        </>
                    </ItemAccordionXnc>
                    : <li className={"px-1 py-2"}>
                        <div className="d-flex align-items-center justify-content-between border-bottom ">
                            <span>{getT("Отверстия")}</span>
                            <span className="font-weight-bold">0</span>
                        </div>
                    </li>
                }
                {(Array.isArray(paz) && paz?.length) ?

                    <ItemAccordionXnc title={getT("Паз")} count={paz_all_count}>
                        <>
                            <ItemPaz title={getT("На плоскости")} data={paz_plane} getT={getT}
                                     handlerOpenModalSeeMoreStaticsByKey={handlerOpenModalSeeMoreStaticsByKey}/>
                            <ItemPaz title={getT("Торцевые")} data={paz_edge} getT={getT}
                                     handlerOpenModalSeeMoreStaticsByKey={handlerOpenModalSeeMoreStaticsByKey}/>
                        </>
                    </ItemAccordionXnc>
                    : <li className={"px-1 py-2"}>
                        <div className="d-flex align-items-center justify-content-between border-bottom ">
                            <span>{getT("Паз")}</span>
                            <span className="font-weight-bold">0</span>
                        </div>
                    </li>
                }

                <li className={"px-1 py-2"}>
                    <div className="d-flex align-items-center justify-content-between border-bottom ">
                        <span>{getT("Урезка")}</span> <span className="font-weight-bold">{cut_to}
                        {!!(cut_to && handlerOpenModalSeeMoreStaticsByKey) &&
                            <i className="far fa-eye text-primary ml-2 cursor-pointer"
                               onClick={handlerOpenModalSeeMoreStaticsByKey('cut_to')}> </i>}

                    </span>
                    </div>
                </li>

                <li className={"px-1 py-2"}>
                    <div className="d-flex align-items-center justify-content-between border-bottom ">
                        <span>{getT("Сшивки")}</span> <span className="font-weight-bold">{hem}
                        {!!(hem && handlerOpenModalSeeMoreStaticsByKey) &&
                            <i className="far fa-eye text-primary ml-2 cursor-pointer"
                               onClick={handlerOpenModalSeeMoreStaticsByKey('hem')}> </i>}</span>
                    </div>
                </li>

                <li className={"px-1 py-2"}>
                    <span>{getT("Выемки")}:</span> <span className="font-weight-bold"></span>
                    <ul className={"pl-0"}>
                        {/*{!!trough?.rectangle &&*/}

                        {/*}*/}
                        {/*{!!trough?.strait &&*/}
                        <li className={"list-group-item px-1 py-2 border-top-0 border-right-0 border-left-0  d-flex justify-content-between"}>
                            <span>{getT("Прямоугольник")}</span>
                            <span className="font-weight-bold">{trough?.strait ?? '0'}
                            {!!(trough?.strait && handlerOpenModalSeeMoreStaticsByKey) &&
                                <i className="far fa-eye text-primary ml-2 cursor-pointer"
                                   onClick={handlerOpenModalSeeMoreStaticsByKey('trough', {type: 'strait'})}> </i>}

                            </span>
                        </li>
                        <li className={"list-group-item  px-1 py-2 border-top-0 border-right-0 border-left-0 d-flex justify-content-between"}>
                            <span>{getT("Скругленный прямоугольник")}</span>

                            <span className="font-weight-bold">
                                {trough?.curve ?? '0'}
                                {!!(trough?.curve && handlerOpenModalSeeMoreStaticsByKey) &&
                                    <i className="far fa-eye text-primary ml-2 cursor-pointer"
                                       onClick={handlerOpenModalSeeMoreStaticsByKey('trough', {type: 'curve'})}> </i>}
                            </span>
                        </li>
                        {/*}*/}
                        {/*{!!trough?.circle &&*/}
                        <li className={"list-group-item  px-1 py-2 border-top-0 border-right-0 border-left-0 d-flex justify-content-between"}>
                            <span>{getT("Круг")}</span> <span className="font-weight-bold">{trough?.circle ?? '0'}
                            {!!(trough?.circle && handlerOpenModalSeeMoreStaticsByKey) &&
                                <i className="far fa-eye text-primary ml-2 cursor-pointer"
                                   onClick={handlerOpenModalSeeMoreStaticsByKey('trough', {type: 'circle'})}> </i>}

                            </span>
                        </li>
                        {/*}*/}
                    </ul>
                </li>
                <ItemFrez getT={getT} frez={frez}
                          handlerOpenModalSeeMoreStaticsByKey={handlerOpenModalSeeMoreStaticsByKey}/>
            </ul>
        </li>
    );
};


const ItemAccordionXnc = ({children, title, count = ''}) => {
    const [isOpen, setIsOpen] = useState(false)

    return <li className={"px-1 py-2"}>
        <div className="d-flex align-items-center justify-content-between border-bottom "
             onClick={() => setIsOpen(!isOpen)}>
            <span>{title}</span> <span> <span className={"font-weight-bold"}>{count}</span> <i
            className={`fas ml-2 fa-angle-${isOpen ? 'up' : 'down'}`}></i></span>
        </div>
        {isOpen && <div className="content my-2">{children}</div>}
    </li>
}

export default StatisticsOperations;