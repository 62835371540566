import React, {useMemo, useState} from 'react';
import CuttingDetailing from "./CuttingDetailing";
import CuttingCard from "./CuttingCard";
import "./style.css"
import {useDispatch} from "react-redux";
import {putProductionMarkToDetailThunk} from "../../../../../../store/thunks/salary_project/ready_mark_thunk";
import {getGroupCuttingForCheckedDetailing, getGroupCuttingOfMaterialForCard} from "./helper_cutting";

/**
 * Тип услуги Порезка. Отображение карты кроя для отметки
 *
 * @param {function}  getT -  перевод
 * @param {function}  submitPutMark - добавить отметки
 * @param {function}  submitDeleteMark - убрать отметки
 * @param {object}  order - заказ
 * @param {object}  choice_service - выбранная услуга и тип услуги
 * @param {number | null}  choice_service.service_type_add -  тип услуги
 * @param {number | null}  choice_service.service_id -   услуга
 * @param {boolean} readOnly  - для чтения
 * @returns  {jsx}  -
 */
const CuttingContainer = ({getT, order, submitPutMark, submitDeleteMark, choice_service, readOnly=false}) => {
    const dispatch = useDispatch()

    // let {material = [], cutting = [], part_ready_services_user_info = []} = order;
    const [type_display_case, setTypeDisplayCase] = useState(true);

    //Группировка деталей по материалу для отметки карт кроя
    const group_cutting_of_material = useMemo(()=>{
       return  getGroupCuttingOfMaterialForCard({
           cutting: order?.cutting, choice_service, material:order?.material, part_ready_services_user_info: order?.part_ready_services_user_info,
           order_part: order.part
       })
    }, [choice_service, order])


    //Карты кроя подетально
    // Группировка деталей по материалу суммирование кол-во и проверка выполнена ли деталь
    // return object [material_${id}]: {material:object, parts: object}
    const group_cutting_detailing = useMemo(()=>{
        return getGroupCuttingForCheckedDetailing({
            cutting: order?.cutting, choice_service, material:order?.material, part_ready_services_user_info: order?.part_ready_services_user_info,
            order_part: order.part
        })
    }, [order, choice_service])

    //::TODO стоит заглушка отправки qr links
    const dispatchedSubmitMark = ({put, remove}) => {
        dispatch(putProductionMarkToDetailThunk({
            order_id: order.order, put: put, remove: remove,
        }))
    }
    if(!order.cutting?.length || !Array.isArray(order.cutting)) {
        return <p>{getT("Карты кроя отсутствуют")}</p>
    }
    return (
        <div>
            <ul className="nav nav-tabs  justify-content-center">
                <li className="nav-item" onClick={() => setTypeDisplayCase(true)}>
                    <span
                        className={`cursor-pointer nav-link ${type_display_case ? 'active text-primary' : ''} `}>{getT("Подетально")}</span>
                </li>
                <li className="nav-item" onClick={() => setTypeDisplayCase(false)}>
                    <span
                        className={`cursor-pointer nav-link ${!type_display_case ? 'active text-primary' : ''}`}>{getT("Картами кроя")}</span>
                </li>

            </ul>
            {type_display_case ? <CuttingDetailing group_detailing_of_material={group_cutting_detailing} getT={getT}
                                                   handlerSubmit={dispatchedSubmitMark} readOnly={readOnly}
                /> :
                <CuttingCard cutting={group_cutting_of_material} getT={getT} handlerSubmit={dispatchedSubmitMark} readOnly={readOnly}
                />}
        </div>);
};

export default CuttingContainer;