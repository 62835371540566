import {dispatcherErrorThunk} from "../common_thunk";
import {toggleLoaderStatusAC} from "../../reducers/MainReducer";
import {createFormData} from "../../../utils/helpers";
import {SaloryReportAPI} from "../../../api/Salary/SaloryReportAPI";
import {salaryReportAC} from "../../actions/SalaryProject";

export const salaryReportThunk = (filter)=>async dispatch =>{
    try {
        dispatch(toggleLoaderStatusAC(true));
        let form_data = createFormData(filter);
        const response = await SaloryReportAPI.getReportWorkers(form_data);
        if(response?.error && !!response?.error){
            dispatch(dispatcherErrorThunk(response));
            return

        }
        dispatch(salaryReportAC({data: response?.data}))
        dispatch(toggleLoaderStatusAC(false));

    }catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}