import React, {useEffect, useMemo, useState} from 'react';
import PageTitle from "../../components/PageTitle/PageTitle";
import ContainerContent from "../../components/ui/ContainerContent";
import {useDispatch, useSelector} from "react-redux";
import {getSettingsCompanyEntryDataAC} from "../../store/selectors/settings_company_selector";
import {
    deleteSettingCompanyThunk,
    getSettingsCompanyThunk,
    updateSettingCompanyThunk
} from "../../store/thunks/settings_company_thunk";
import SettingsCompanyTable from "./components/SettingsCompanyTable";
import {getIsAccessAuthUserRoleById} from "../../store/selectors/auth_selector";
import {SETTING_CHANGE_ACCESS_ROLE} from "../../utils/constants";
import {SettingCompanyUpdateHtmlModalAC} from "../../store/actions/SettingsCompanyAction";
import SettingUpdateHtmlModal from "./components/Modal/SettingUpdateHtmlModal";

const SettingsCompanyPage = ({getT}) => {
    const dispatch = useDispatch();
    const settings_data = useSelector(getSettingsCompanyEntryDataAC);
    const is_available_access_role = useSelector(getIsAccessAuthUserRoleById(SETTING_CHANGE_ACCESS_ROLE));

    useEffect(() => {
        dispatch(getSettingsCompanyThunk())
    }, []);

    const [folderId, setFolderId] = useState('');
    const handlerChangeFolder = (e) => {
        let id = e.target.value;
        setFolderId(id)
    }

    const settings_data_direction = useMemo(() => {
        if (!settings_data || !Array.isArray(settings_data)) return {};
        return settings_data?.reduce((acc, item) => {
            let {setting_folder_id, setting_folder_name, ...reset_value} = item
            let key = setting_folder_id;
            let item_entry =  {...reset_value, setting_folder_name: setting_folder_name}
            let value = acc?.[key] ? [...acc?.[key]?.entry, item_entry] : [item_entry];
            let folder = acc?.[key]?.folder ? acc?.[key]?.folder : {setting_folder_id, setting_folder_name}
            return {
                ...acc, [key]: {
                    folder: folder,
                    entry: value
                }
            }
        }, {})
    }, [settings_data]);

    const settings_entry = useMemo(() => {
        if (!folderId) return Object?.values(settings_data_direction)?.reduce((acc, item) => {
            return [...acc, ...item.entry]
        }, []);
        return settings_data_direction?.[folderId]?.entry;
    }, [folderId, settings_data_direction])

    const handlerChange = (setting) => value => {

        let data_api = {
            settings: setting?.setting_id,
            type: setting?.setting_type,
            value: value
        }
        dispatch(updateSettingCompanyThunk(data_api))
    }
    const handlerDelete = (setting_id) => e => {
        dispatch(deleteSettingCompanyThunk(setting_id))
    }
    const handlerOpenModalEditHtml = (setting) => e => {
        dispatch(SettingCompanyUpdateHtmlModalAC({isOpen: true, setting: setting}))
    }
    return (
        <ContainerContent>
            <PageTitle title={"settings_company"}/>
            <div className="container-fluid ">
                <div className="row">
                    <div className="col-12 col-md-6 col-xl-4">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlSelect1">{getT("Папки параметров")}</label>
                            <select name="" id="" className={"form-control"} onChange={handlerChangeFolder}
                                    value={folderId}>
                                <option value="">{getT("Все")}</option>
                                {Object?.values(settings_data_direction)?.map(item => {
                                    return <option value={item?.folder?.setting_folder_id}>
                                        {item?.folder?.setting_folder_name}
                                    </option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="col-12">
                        <SettingsCompanyTable getT={getT}
                                              settings={settings_entry} handlerChange={handlerChange}
                                              is_access_change={!is_available_access_role}
                                              handlerDelete={handlerDelete}
                                              handlerOpenModalEditHtml={handlerOpenModalEditHtml}
                        />
                    </div>
                </div>
            </div>
            <SettingUpdateHtmlModal key={'SettingUpdateHtmlModal'}/>
        </ContainerContent>
    );
};

export default SettingsCompanyPage;