import React, {useEffect, useMemo} from 'react';
import ContainerContent from "../../components/ui/ContainerContent";
import PageTitle from "../../components/PageTitle/PageTitle";
import TableShifts from "./component/TableShifts/TableShifts";
import {useDispatch, useSelector} from "react-redux";
import {getShift, getShiftFilter} from "../../store/selectors/salary_project_selector";
import {getShiftThunk} from "../../store/thunks/salary_project/shifh-thunk";
import ShiftCreateModal from "../../components/Modals/SalaryProject/ShiftCreateModal";
import {setShiftCreateModalAC, setShiftFilterAC} from "../../store/actions/SalaryProject";
import Button from "../../components/ui/Button";
import Pagination from "../../components/Pagination/Pagination";
import "./main.css"
import ShiftUpdateModal from "../../components/Modals/SalaryProject/ShiftUpdateModal";
import ShiftFilter from "./component/ShiftFilter";
import {LS_USER_ID} from "../../utils/constants";

const SalaryProjectShiftPage = ({getT}) => {
    const dispatch = useDispatch();
    const is_user_in_system = !!Number(localStorage.getItem(LS_USER_ID));
    const shift_state = useSelector(getShift);
    const shift_filter = useSelector(getShiftFilter);
    const isHasShifts = useMemo(() => {
        return shift_state && shift_state?.data?.shifts
    }, [shift_state])
    useEffect(() => {
        dispatch(getShiftThunk())
    }, [dispatch]);
    const handlerToggleModalCreateShift = () => {
        dispatch(setShiftCreateModalAC({isOpen: true}))
    }

    const handlerChangePage = (page) => {
        dispatch(setShiftFilterAC({offset: page - 1}));
        getShiftThunk()
    }
    const handlerFilterChange = (filter_data) => {
        dispatch(setShiftFilterAC(filter_data));
        dispatch(getShiftThunk())
    }
    return (
        <>
            <ContainerContent>
                <PageTitle title={"shift"}/>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 card mb-3">
                            <div className="d-flex align-items-center flex-wrap py-4 justify-content-between">
                                <ShiftFilter getT={getT} handlerFilterChange={handlerFilterChange}
                                             shift_filter={shift_filter}/>
                                {is_user_in_system &&  <Button onClick={handlerToggleModalCreateShift}
                                        className={"btn-success"}><>{getT("Создать смену")}</>
                                </Button>}
                            </div>

                        </div>
                        {isHasShifts ?

                            <div className="col-12 card pt-4">
                                <TableShifts getT={getT} shift_entry={shift_state?.data?.shifts} is_user_in_system={is_user_in_system}/>
                                <div className="mt-4">
                                    <Pagination pageSize={30}
                                                page={shift_filter.offset + 1}
                                                count={shift_state?.data?.count_all}
                                                changeHandler={handlerChangePage}/>
                                </div>
                            </div>
                            : <> <p className={"text-center"}>Not shift's</p>
                            </>
                        }
                    </div>
                </div>
                <ShiftCreateModal getT={getT}/>
                <ShiftUpdateModal getT={getT}/>
            </ContainerContent>
        </>
    );
};

export default SalaryProjectShiftPage;