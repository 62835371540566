import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getSettingCompanyUpdateHtmlModal} from "../../../../../store/selectors/settings_company_selector";
import FormUpdateHtml from "./FormUpdateHtml";
import Modal from "../../../../../components/Modals/MyModal/Modal";
import {SettingCompanyUpdateHtmlModalAC} from "../../../../../store/actions/SettingsCompanyAction";
import {updateSettingCompanyThunk} from "../../../../../store/thunks/settings_company_thunk";

const SettingUpdateHtmlModal = ({getT}) => {
    const dispatch = useDispatch();
    const setting_update_html_modal = useSelector(getSettingCompanyUpdateHtmlModal);
    if (!setting_update_html_modal?.isOpen) return null
    const handlerSend = (data) => {
        let setting_current = setting_update_html_modal.setting;
        let payload = {
            ...data,
            type: "html",
            html_text_id_for_translation: Number(setting_current?.html_text_id_for_translation),
            settings: Number(setting_current?.setting_id),
        }
        dispatch(updateSettingCompanyThunk(payload))
    }
    const handlerClose = () => {
        dispatch(SettingCompanyUpdateHtmlModalAC({isOpen: false, setting: null}))
    }
    return (
        <Modal open={setting_update_html_modal?.isOpen} onClose={handlerClose} size_modal_class={"modal-xl"}>
            <FormUpdateHtml getT={getT} handlerSend={handlerSend}
                            initial_values={setting_update_html_modal?.setting?.translate}/>

        </Modal>
    );
};

export default SettingUpdateHtmlModal;