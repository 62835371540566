import * as React from "react";

function CloseModal(props) {
  return (
    <svg
        className={"close-modal"}
      width={28}
      height={28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
        style={{zIndex: '2'}}
      {...props}

    >
      <path
        d="M21 7L7 21M7 7l14 14"
        stroke={ '#000'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CloseModal;
