import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from "react-redux";
import "./file-manager.css"
import Modal from "../MyModal/Modal";
import {deleteItemFileManagerDT, onChangeFileManagerModalDT} from "../../../store/reducers/MainReducer";
import {withLang} from "../../../hoc/withLang";
import AddFiles from "./AddFiles";

const FileManager = ({getT}) => {

    const dispatch = useDispatch();

    const {isOpen, listFiles, addedFiles, isAddedFiles} = useSelector(state => state.main.fileManager);
    const close = () => dispatch(onChangeFileManagerModalDT({isOpen: false, isAddedFiles: false, addedFiles:{}}));
    const [isImport, setIsImport] = useState(false);
    const onDeleteFile = (url) => dispatch(deleteItemFileManagerDT(url))
    useEffect(() => {

        if(isAddedFiles){
            setIsImport(false);
            setTimeout(()=>{
                dispatch(onChangeFileManagerModalDT({isAddedFiles: false}));
            }, 5000)

        }
    }, [isAddedFiles])
    return <>
        <Modal open={isOpen} onClose={()=> close()}>
            <div className={"file-manager__container"}>

                {isImport && <div className={"file-manager-add-file"}>
                    <div className="file-manager-header"><span className=""
                                                               onClick={() => setIsImport(false)}>
                        X
                        {/*<CloseModal/>*/}
                    </span>
                    </div>
                    <AddFiles getT={getT}/>
                </div>}
                {isAddedFiles && <div className={"added-files-name"}><p>Файл(ы) успешно отправлен(ы)</p>
                    <div className="wrap">
                        {/*<NewFiles addedFiles={addedFiles}/>*/}
                    </div>
                </div>}
                {!isImport && <button className={"file-manager-btn__add add-to-card btn btn-primary float-right "}
                                      onClick={() => setIsImport(true)}>{getT("Добавить файл")}</button>}

                <div className={"list-files"}>
                    {Array.isArray(listFiles) && listFiles.length != 0 && listFiles.map((list, index) =>
                        <ListItem {...list} index={index + 1} getT={getT}
                                  onDeleteFile={onDeleteFile}/>)}
                </div>
            </div>
        </Modal>
    </>
}



const ListItem = ({file_real, link, link_delete, index, onDeleteFile, getT}) => {
    return <div>
        <span className="count">{index}.</span>
        <a href={link} className="" target="_blank" download={true} title={getT("Скачать")}> {file_real}</a>
        <span onClick={() => onDeleteFile(link_delete)} className="" style={{color: "red", cursor:"pointer"}}>
            {/*<CloseModal isBlack={true}/>*/} X
        </span>

    </div>
}
export default withLang(FileManager);